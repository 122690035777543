import React, { useState,useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MdDone from "@mui/icons-material/Done";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Block from "@mui/icons-material/Block";
import { withStyles } from "tss-react/mui";
import { Autocomplete } from '@material-ui/lab'
// import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import { height } from '@mui/system';
import { Select } from '@mui/material';
import './style.css';

const defaultToolbarSelectStyles = {
  iconContainer: {
    display: "flex",
    marginRight: "3rem"
  },
  statusArea : {
    width: "15rem",
    marginRight: "3rem",
    height: "1.5rem"
  }
};


class CustomToolbarSelect extends React.Component {
    
render() {
    const { classes } = this.props;
    return (
      <>
        <div className='boxGeralToolbarMenu'>

      
            <div className='boxUpdateStatusOffer'>
                <Tooltip>
                    <span className="tooltipSelect">
                        <a>Status Ordens: </a>
                        <select
                            className="tooltipSelectOptions"
                            defaultValue={parseInt(this.props.optionsStatusOrder)}
                            onChange={(e) => {
                                this.props.setStatusOrderSelected(parseInt(e.target.value))
                            }}
                        >
                            <option selected value={-1}>
                                NÃO ATUALIZAR
                            </option>
                            {this.props.optionsStatusOrder.map((op) => {
                                return <option value={op.id}>{op.description}</option>
                            })}
                        </select>
                    </span>
                </Tooltip>
                <Tooltip>
                    <span className="tooltipSelect">
                        <button className="tooltipButton" onClick={(e) => { this.props.sendUpdate(this.props.selectedRows) }}>
                            ENVIAR
                        </button>
                    </span>
                </Tooltip>
            </div>
            <div className='boxDeleteOffer'>
                <button className="tooltipButtonDelete" onClick={() => { 
                    this.props.delete(this.props.selectedRows)
                    }}>
                    EXCLUIR
                </button>
            </div>
        </div>
      </>

    );
  }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });
