import React from 'react';
import { useModal } from '../../contexts/useModal';
import './confirmationBox.css'

export function ConfirmationBox(props) {

    const func = () => props.msgData.value.func !== undefined ? props?.msgData?.value?.func(props?.msgData?.value?.dataForFunc) : '';
    const { setAnswer, setType, setShow } = useModal();

    return (
        <div className='confirmationBoxContainer'>
            <div className='confirmationBoxMsgContainer'>
                {props.msgData.type === 'information' ? (<></>) : (<h2>Atenção!</h2>)}
                <p id={props.msgData.type === 'information' ? 'info' : 'normalMsg'}>{props.msgData.value.message}</p>
            </div>
            <div className='confirmationBoxButtons'>
                {props.msgData.type === 'information' ?
                    (<button className='buttonWhiteBg' onClick={()=> {setShow(false);func();}}>Fechar</button>) :
                    (
                        <>
                            <button className='buttonWhiteBg' onClick={() => { setAnswer(true); func(); setType(props.msgData.value) }}>Sim</button>
                            <button className='buttonWhiteBg' onClick={() => { setAnswer(false); setShow(false) }}>Não</button>
                        </>
                    )
                }
            </div>
        </div>
    )
}