import React, { useState, useEffect } from 'react';
import { api } from '../../../services/api';
import { useModal } from '../../../contexts/useModal';
import { useAuth } from '../../../contexts/useAuth';
import './dashboard.css';
import { datetimeToDatePtBR } from '../../../utils/formatDate/dateTimeToPTBR';
import { toBRL } from '../../../utils/formatNumberAndText/toBRLCurrency';
import { toPercent } from '../../../utils/formatNumberAndText/formatNumberPercent';



export default function ListCampainsBaterBaseModal(props) {
    const [campains, setCampains] = useState([]);
    const [loading, setLoading] = useState(false);


    const { show, setShow ,setType, edited } = useModal()
    const { user, tokenKey } = useAuth();
    
    
    
    return (
        <div className={'paperBaterBase'} style={{width:'40vw'}}>
            <h2>Oportunidades abertas</h2>
            <table style={{textAlign:'center'}}>
                <thead>
                    <tr style={{ color: '#C3980D'}}>
                        <th style={{fontWeight: 'bold',}}>Oportunidade</th>
                        <th style={{fontWeight: 'bold',}}>Volume planejado</th>
                        <th style={{fontWeight: 'bold',}}>Fee</th>
                        <th style={{fontWeight: 'bold',}}>Data Fim</th>
                    </tr>
                </thead>
                <tbody>
                    {props.prop.products.map((campain, index) => (
                        <>
                            <tr key={index} style={{display: props.prop.campanhas['c' + campain.id + '__operation_id'] != null ? 'table-row' : 'none'}}>
                                <td>{campain.description}</td>
                                <td>{toBRL(props.prop.campanhas['c' + campain.id + '__objective'])}</td>
                                <td>{campain.fee ? toPercent(campain.fee * 100) : ' - '}</td>
                                <td>{datetimeToDatePtBR(campain.finish)}</td>
                            </tr>
                        </>
                    ))}
                </tbody>
                </table>
            <button
                style={{background:'#C39D14',color:"white", padding:'10px',cursor:"pointer",borderRadius:'0.5rem'}}
                onClick={() => {
                    // props.prop.setSelectedCampanha(campain);
                    setShow(false);
                }}
            >
                Voltar</button>
        </div>
    )
}