import React, { useState, Component, useEffect } from "react"
import { api } from '../../../../services/api';
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Link, useLocation, useHistory } from "react-router-dom";

import { debounceSearchRender } from "mui-datatables"
import TableCell from "@mui/material/TableCell"
import { Tooltip, IconButton } from "@material-ui/core"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"



export function MyFormsStructureList(){
  const [filtroTipoPortfolio, setFiltroTipoPortfolio] = useState("0")
  const [loading, setLoading] = useState(false)
  const {setType, setShow} = useModal()
  const { tokenKey, userType, user } = useAuth()
  let history = useHistory();

  const [jsonMyForms, setJsonMyForms] = useState([])
  const [formatForms, setFormatForms] = useState([])
  const [jsonFormsReplyDetails, setJsonFormsReplyDetails] = useState()

  const [formTypeFilter, setFormTypeFilter] = useState("0")
  const [formProgressFilter, setFormProgressFilter] = useState("0")
  const [formNameFilter, setFormNameFilter] = useState("")

  const [atualizacao, setAtualizacao] = useState(0)
  
  useEffect(() => {
    getForms()
    sessionStorage.removeItem('idPortfolio')
    sessionStorage.removeItem('idRespostas')
  }, [tokenKey])

  async function getForms() {
    setLoading(true)
    try {
      const formsTemp = await api.get(`/api/v1/forms/myForms`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      // console.log(formsTemp)
      setJsonMyForms(formsTemp?.data?.data)
      // console.log(formsTemp?.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os portfólios' } })
      setShow(true)
    }
  }

  function formateTable() {
    let formsTable = [];

    jsonMyForms.map((x) => {
        formsTable.push({
            description: x.description,
            owner_name: x.owner_name,
            updated_at: x.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}):(\d{2})\.\d+Z$/, "$3/$2/$1 $4h"),
            is_open: x.is_open ? "Em Andamento" : "Finalizado",
            form_title: x.form_title,
            form_profile: x.form_profile
        });
    });

    setFormatForms(formsTable);
    setLoading(false);
}

  useEffect(() => {
        setLoading(true)
        getForms()
}, [])

    useEffect(() => {
        if (jsonMyForms.length > 0) {
            formateTable()
        }
    }, [jsonMyForms])


function copyToClipboard() {
    const output = []
    const titles = ["Cliente", "Assessor", "Última Atualização", "Status", "Portfólio Utilizado", "Perfil"]
    output.push(titles.join("\t"))
    const keys = ["description", "owner_name", "updated_at", "is_open", "form_title", "form_profile"]
    formatForms.forEach((x) => {
        const tmp = keys.map((y) => {
            return x[y]
        })
        output.push(tmp.join("\t"))
    })
    const table = output.join("\n")
    navigator.clipboard.writeText(table)
    alert("Conteúdo da tabela copiado para a área de transferência.")
}

async function tableResponseExport() {
    setLoading(true)
    try {
        const myExport = await jsonMyForms(tokenKey, true)
        const responseURL = URL.createObjectURL(new Blob([myExport]))
        const link = document.createElement("a")
        link.href = responseURL
        link.setAttribute("download", "b2c_formulários_histórico.xlsx")
        document.body.appendChild(link)
        link.click()
        setLoading(false)
    } catch (error) {
        setType({
            name: "alertMsg",
            type: "information",
            value: {
                message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
            },
        })
        setShow(true)
        setLoading(false)
    }
}

const columns = [
    {
        name: "description",
        label: "Cliente",
        options: {
            filter: false,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{(value)}</span>,
        },
    },
    {
        name: "owner_name",
        label: "Assessor",
        options: {
            filter: true,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{(value)}</span>,
        },
    },
    {
        name: "updated_at",
        label: "Última Atualização",
        options: {
            filter: false,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{value}</span>
        },
    },
    {
        name: "is_open",
        label: "Status",
        options: {
            filter: true,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{value}</span>
        },
    },
    {
        name: "form_title",
        label: "Portfólio Utilizado",
        options: {
            filter: true,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{(value)}</span>,
        },
    },
    {
        name: "form_profile",
        label: "Perfil",
        options: {
            filter: true,
            sort: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                },
            }),
            customBodyRender: (value) => <span>{(value)}</span>,
        },
    },
]

const AddButton = () => (
    <Tooltip disableFocusListener title="Copiar para a área de transferência">
        <IconButton onClick={copyToClipboard}>
            <ContentCopyIcon />
        </IconButton>
    </Tooltip>
)

const options = {
    filterType: "multiselect",
    filter: true,
    download: false,
    print: false,
    responsive: "standard",
    customSearchRender: debounceSearchRender(500),
    tableBodyHeight: "70vh",
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    toolButton: {
        justifyContent: "center",
    },
    textLabels: {
        body: {
            noMatch: loading ? <ArrowLoader /> : "Não há informações a apresentar no momento!",
            toolTip: "Ordenar",
            columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
        },
        pagination: {
            next: "Próxima",
            previous: "Anterior",
            rowsPerPage: "Linhas por página:",
            displayRows: "de",
        },
        toolbar: {
            search: "Pesquisar",
            downloadCsv: "Download XLSX",
            print: "Imprimir",
            viewColumns: "Ver colunas",
            filterTable: "Filtrar",
        },
        filter: {
            all: "Todos",
            title: "Filtros",
            reset: "Limpar",
        },
        viewColumns: {
            title: "Mostrar Colunas",
            titleAria: "Mostrar/Ocultar colunas",
        },
        selectedRows: {
            text: "linha(s) selecionadas",
            delete: "Excluir",
            deleteAria: "Excluir linhas Selecionadas",
        },
    },
    downloadOptions: {
        filename: "previa_receita.csv",
        separator: ";",
    },
    onDownload: () => {
        tableResponseExport()
        return false
    },
    sortOrder: {
        name: 'updated_at',
        direction: 'desc'
    },
    setTableProps: () => {
        return {
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            // padding: '0px 1rem 0px',
            size: "small",
        }
    },
    rowsPerPage: 100,
    rowsPerPageOptions: [30, 100, 1000],
    customToolbar: AddButton,
    onRowClick: (rowData, rowMeta) => {
        history.push({pathname: "/formReply", state: {id: jsonMyForms[rowMeta.dataIndex].form_id, pending_id: jsonMyForms[rowMeta.dataIndex].id}})
    },
}

const getMuiTheme = () =>
    createTheme({
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        justifyContent: "center",
                        whiteSpace: "",
                        textAlign: "center",
                    },
                },
            },
            MUIDataTableHeadCell: {
                styleOverrides: {
                    contentWrapper: {
                        justifyContent: "center",
                        whiteSpace: "pre-line",
                    },
                    fixedHeader: {
                        justifyContent: "center",
                        whiteSpace: "pre-line",
                        maxWidth: "4rem",
                        padding: "0px 0.6rem 0px 0.6rem",
                    },
                    toolButton: {
                        margin: "0px",
                    },
                },
            },
            MUIDataTableFilter: {
                styleOverrides: {
                    root: {
                        minWidth: "40vw",
                        maxWidth: "50vw",
                    },
                },
            },
            MUIDataTableBodyRow: {
                styleOverrides: {
                    root: {
                        "&:nth-child(odd)": {
                            backgroundColor: "#dbdbdb",
                        },
                        // "&:hover ": {
                        //     backgroundColor: "#e7e7e7 !important",
                        // },
                        // "&: hover td": {
                        //     backgroundColor: "#e7e7e7 !important",
                        // },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "none",
                        borderRadius: "0",
                    },
                },
            },
        },
    })

  return (
    <div>
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable data={formatForms} columns={columns} options={options} className="tableUnsuitedStructured"></MUIDataTable>
        </ThemeProvider>
    </div>
  )
}