//libraries
import React, { useState, useEffect } from "react";
import AntSwitch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TableCell from "@mui/material/TableCell";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
//services
import { api } from "../../../../services/api";
import { getBranches } from "../../../../services/asyncFunctions";
import { getBrokerLeaders } from "../../../../services/asyncFunctions";
import { getBrokerProfiles } from "../../../../services/asyncFunctions";
import { getUsers } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/users.editBox.css";
import { styleClientsInput } from "../../../../components/styles/autocompletField";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

export function BrokerLeaderMirroringsEdit(props) {
    const brokerName = props.userData.data.data[0];
    const brokerId = props.userData.data.data[1];
    const leaderName = props.userData.data.data[3];
    const leaderId = props.userData.data.data[4];

    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const classes = styleClientsInput();
    const [leadersList, setLeadersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [mirrorList, setMirrorList] = useState([]);
    const [selectedLeader, setSelectedLeader] = useState("");

    const [loading, setLoading] = useState(true);
    const [leaderEdited, setLeaderEdited] = useState(false);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey, setTokenKey } = useAuth();

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: "center",
                            whiteSpace: "",
                            textAlign: "center",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                        },
                        fixedHeader: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                            maxWidth: "4rem",
                            padding: "0px 0.6rem 0px 0.6rem",
                        },
                        toolButton: {
                            margin: "0px",
                        },
                    },
                },
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                            "&:nth-child(odd)": {
                                backgroundColor: "#dbdbdb",
                            },
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none",
                            borderRadius: "0",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            // '&: hover div': {
                            //     backgroundColor: '#e7e7e7 !important'
                            // }
                        },
                    },
                },
            },
    })

    var columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
                sortDescFirst: false,
            },
        },
        {
            name: "leader_id",
            label: "Código",

            options: {
                filter: true,
                sort: true,
                sortDescFirst: true,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
            },
        },
        {
            name: "leader_name",
            label: "Nome",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    position: "sticky",
                                    left: "12rem",
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
            },
        },
        {
            name: "delete",
            label: "Remover",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton onClick={() => handleDelete(tableMeta.rowData[0])}>
                            <DeleteIcon />
                        </IconButton>
                    );
                },
                customHeadRender: () => {
                    return (
                        <TableCell
                            className="TableCell"
                            style={{
                                minWidth: "9rem",
                                whiteSpace: "nowrap",
                                position: "sticky",
                                left: 0,
                                zIndex: 121,
                                backgroundColor: "white",
                            }}
                        >
                            Delete
                        </TableCell>
                    );
                },
            },
        },
    ]

    const options = {
        filterType: "multiselect",
        filter: false,
        download: false,
        print: false,
        responsive: "standard",
        // tableBodyHeight: "20vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center",
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações a apresentar no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "broker_mirroring.csv",
            separator: ";",
        },
        // onDownload: () => {
        //     dueStructuredExport()
        //     return false
        // },
        setTableProps: () => {
            return {
                padding: "0px 1rem 0px",
                size: "small",
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        // customToolbar: AddButton,
    }

    useEffect(() => {
        async function getData() {
            const leadersGet = await getBrokerLeaders(tokenKey);
            if (leadersGet?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: leadersGet?.error,
                    },
                });
                setShow(true);
            } else {

                setLeadersList(leadersGet);
            }
        }

        async function getBrokerMirrorings() {

            try {
                const mirrorResult = await api.get(`/api/v1/users/brokerLeader?broker_id=${brokerId}&is_leader=0`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                if (mirrorResult?.isError) {
                    setType({
                        name: "alertMsg",
                        type: "information",
                        value: {
                            message: mirrorResult?.error,
                        },
                    });
                    setShow(true);
                    // setLoading(false);
                } else {
                    const formattedMirrorList = mirrorResult.data.data.map((mirror) => ({
                        id: mirror.id,
                        leader_id: mirror.leader_id,
                        leader_name: mirror.leader_name,
                    }));
                    setMirrorList(formattedMirrorList);
                }
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        }

        async function getUsersList() {
            const usersGet = await getUsers(1, tokenKey);
            if (usersGet?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: usersGet?.error,
                    },
                });
                setShow(true);
                // setLoading(false);
            } else {
                setUsersList(usersGet);
            }
        }

        getData();
        getUsersList();
        getBrokerMirrorings();
        setLeaderEdited(false);
        setLoading(false);
    }, [show]);

    async function handleDelete(data) {
        setLoading(true);
        try {
            const userChangeResult = await api.delete(
                `/api/v1/mirroring/delete/${data.id}`,
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setLoading(false);
            setType({ name: "alertMsg", type: "information", value: { message: "Sucesso ao remover espelhamento do broker." } });
            setEdited(!edited);
            setLoading(false);
        } catch (error) {
            console.log('err', error)
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data.message === undefined
                            ? "Erro ao realizar requisição"
                            : error?.response?.data.message,
                },
            });
            setShow(true);
        }
    }

    async function handleSubmit() {
        setLoading(true);

        try {
            const userChangeResult = await api.put(
                `/api/v1/users/brokerLeaderUpdate`,
                {
                    brokerId: brokerId,
                    leaderId: selectedLeader === null ? leaderId : selectedLeader.split(" ")[0],
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setLoading(false);

            setType({ name: "alertMsg", type: "information", value: { message: "Sucesso ao alterar espelhamento do broker" } });
            setEdited(!edited);
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data.message === undefined
                            ? "Erro ao realizar requisição"
                            : error?.response?.data.message,
                },
            });
            setShow(true);
        }
    }

    return (
        <div
            className="userEditBox"
            style={{
                width: "60vw",
                height: "80vh",
            }}
        >
            {loading ? (
                <div className="userBoxArrow">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="headerBoxUser">
                        <div>
                            <h1>Broker: </h1>
                            <h1>
                                {titleize(brokerName)} - {brokerId}
                            </h1>
                        </div>
                    </div>
                    <div className="formBoxUser ">
                        <div className="selectBrokers">
                            <br />
                            <Autocomplete
                                options={usersList.map((x) => String(x.external_id) + " - " + x.full_name)}
                                value={selectedLeader}
                                onChange={(event, newValue) => {
                                    setSelectedLeader(newValue);
                                    setLeaderEdited(true);
                                }}
                                id="homeBroker"
                                renderInput={(params) => (
                                    <TextField
                                        label="Adicionar Espelhamento"
                                        className={classes.autocompleteEditUser}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault();
                                        }}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            {leaderEdited && (
                                <button
                                    style={{ margin: "2rem" }}
                                    onClick={() => handleSubmit()}
                                    id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                                >
                                    Confirmar
                                </button>
                            )}
                            <button
                                style={{ margin: "2rem", inlineSize: "fit-content" }}
                                onClick={() => 
                                    setType({ name: 'brokerLeaderEdit', data: props.userData.data.data})
                                }
                                id={"userBoxButtonShow"}
                            >
                                Liderança
                            </button>
                            <button
                                style={{ margin: "2rem" }}
                                onClick={() => setShow(false)}
                                id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                    <div>
                        <ThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                title={
                                    <>
                                        <div>
                                            <div style={{ fontSize: "1.6rem", fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>Espelhamentos Ativos</div>
                                        </div>
                                    </>
                                }
                                data={mirrorList}
                                columns={columns}
                                options={options}
                                className="tableUnsuitedStructured"
                            ></MUIDataTable>
                        </ThemeProvider>
                    </div>
                </>
            )}
        </div>
    );
}
