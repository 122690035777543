import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal'
import './showAssetCard.css'
/*Utils*/
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { api } from '../../../../services/api'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
import { ShowDocumentFile } from '../../../../utils/pdf/showDocumentFile'
/*Services*/
import { getAssetsDetails } from '../../../../services/asyncFunctions'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'

export function ShowAssetCard() {

    const [loading, setLoading] = useState(false)
    const [offersDetails, setOfferDetails] = useState([])
    const { show, setShow, type, setType } = useModal()
    const [ showMsg, setShowMsg] = useState(false)
    const [inEditText, setInEditText] = useState(false)
    const [fileChanged, setFileChanged] = useState(false)
    const { tokenKey, user } = useAuth()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    )
    const nowDate = new Date()
    const { screenY, screenX } = ModalSizeFitToScreen()
    const [previewText, setPreviewText] = useState('')
    const [pdfFile, setPdfFile] = useState(0)
    const [selectedFile, setSelectedFile] = useState(null)

    async function handleSubmitNewText() {

        let newText = previewText

        while (newText.indexOf(`"`) > -1) {

            newText = newText.replace(/"/, '!!!!')

        }

        setLoading(true)
        if (previewText === '<p></p>\n' || previewText === '&nbsp') {
            alert('Texto está vazio')
            setLoading(false)
            return
        }

        try {
            const newAssetText = await api.post(`/api/v1//booksAssetsDetails`, {
                books_id: type.bookId,
                asset: type.asset,
                details: newText
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto enviado com sucesso!' } })
            setShow(true)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao enviar o texto' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    async function getOffersDetailsByAPI() {

        const assetDetailsGet = await getAssetsDetails(tokenKey, type.asset, type.bookId)
        if (assetDetailsGet?.isError && !assetDetailsGet?.notExist) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: assetDetailsGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {

            if (!assetDetailsGet?.notExist) {

                let newDetails = assetDetailsGet

                while (newDetails.indexOf(`!!!!`) > -1) {
                    newDetails = newDetails.replace(`!!!!`, `"`)
                }

                while (newDetails.indexOf(`<p></p>`) > -1) {
                    newDetails = newDetails.replace(`<p></p>`, `<br>`)
                }
                setOfferDetails(newDetails)
            } else {
                setOfferDetails('')
            }
            setLoading(false)
        }
    }

    async function alertMsg(){

        await new Promise(resolve => setTimeout(()=> {
            setShowMsg(false)
            resolve()
        },5000))

        
    }

    useEffect(() => {

        setLoading(true)
        setSelectedFile(null)
        getCard()
        if(fileChanged){

            setShowMsg(true)
            setFileChanged(false)
            alertMsg()
        }

    }, [show, fileChanged])


    useEffect(() => {
        setPreviewText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])


    async function getCard() {

        //Chamar API para buscar o PDF
        try {
            const responseFile = await api.get(`/api/v1/booksAssetsDetailsFile/books/${type.bookId}/asset/${type.asset}/reference/${type.reference}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const file = URL.createObjectURL(responseFile.data)
            setPdfFile(file)
            setLoading(false)
        } catch (error) {

            if (error?.response?.status === 404) {
                setPdfFile(null)
            } else {
                setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar arquivo' } })
            }
            setLoading(false)
        }

    }

    async function handleSubmit() {
        //chamada da API para enviar o arquivo
        setLoading(true)
        try {

            const formData = new FormData()
            formData.append('file', selectedFile[0])

            await api.post(`/api/v1/booksAssetsDetailsFile/books/${type.bookId}/asset/${type.asset}/reference/${type.reference}`, formData
                , {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            setFileChanged(true)
            setSelectedFile(null)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    function generatePDF(id) {

        const htmlToPDfData = document.getElementById(id).innerHTML
        const pdfWindow = window.open('', '', 'width=1920, height= 1080')
        pdfWindow.document.write('<html><head>')
        pdfWindow.document.write(`<tile><h4>Detalhes: ${type.asset} | Data: ${nowDate.toLocaleDateString()}</h4></title></head>`)
        pdfWindow.document.write('<body>')
        pdfWindow.document.write(htmlToPDfData)
        pdfWindow.document.write('</body></html>')
        pdfWindow.document.close()
        pdfWindow.print()

    }

    function handleNewFile() {

        document.getElementById('assetCardNewFileButton').click()
    }

    function handleChangeFile(file) {
        if(file[0].type !== 'application/pdf'){
            return alert('Tipo de arquivo não permitido!')   
        }
        setSelectedFile(file)
    }

    return (

        <div id='showAssetCardContainer' style={{ minWidth: screenX -470, minHeight: screenY + 30 }}>
            {
                loading ?
                    <div className='textEditorLoadingAssetCard' >
                        <ArrowLoader />
                    </div>
                    :
                    <>
                        <div id='showAssetCardHeader'>
                            <p>Detalhes da operação</p>
                            <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                        </div>
                        <div id='showAssetCardBody'>
                            {
                                inEditText ?
                                    (
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            onEditorStateChange={setEditorState}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'emoji', 'list', 'textAlign',
                                                    'remove', 'history'],
                                            }}
                                        />
                                    ) :
                                    offersDetails.length > 0 ?
                                        (
                                            <div id='textToPdf' dangerouslySetInnerHTML={{ __html: offersDetails }}></div>
                                        ) :
                                        selectedFile?.length > 0 ?
                                            <div id='assetCardPDF'>
                                                <ShowDocumentFile file={URL.createObjectURL(selectedFile[0])} />
                                                <div id='assetCardPdfButtons'>
                                                    <input id='assetCardNewFileButton' type='file' accept='application/pdf' onChange={(e) => { setSelectedFile(null); handleChangeFile(e.target.files) }}></input>
                                                    <button onClick={() => handleNewFile()}>Novo arquivo</button>
                                                    <div>
                                                        <a>Selecionado: {selectedFile[0].name}</a>
                                                        <button onClick={() => handleSubmit()}>Enviar este arquivo</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : pdfFile?.length > 0 ?
                                                <div id='assetCardPDF'>
                                                    <ShowDocumentFile file={pdfFile} />
                                                    {showMsg &&
                                                        <a id='assetCardNewFileReceveidMsg'>Arquivo enviado com sucesso!</a>
                                                    }

                                                    <div id='assetCardPdfButtons'>

                                                        <input id='assetCardNewFileButton' type='file' accept='application/pdf' onChange={(e) => { setSelectedFile(null); handleChangeFile(e.target.files) }}></input>
                                                        {
                                                            user.type < 3 &&
                                                            <button onClick={() => handleNewFile()}>Novo arquivo</button>

                                                        }
                                                    </div>
                                                </div>
                                                :
                                                (
                                                    <div id='showAssetCardEmpty' style={{ minWidth: screenX -470, minHeight: screenY }}>
                                                        <a>Sem dados cadastrados</a>
                                                        <div id='assetCardPdfButtonsEmpty'>
                                                            <input id='assetCardNewFileButton' type='file' accept='application/pdf' onChange={(e) => { setSelectedFile(null); handleChangeFile(e.target.files) }}></input>
                                                            {
                                                                user.type < 3 &&
                                                                <button onClick={() => handleNewFile()}>Novo arquivo</button>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                            }
                        </div>

                    </>
            }
        </div>
    )
}