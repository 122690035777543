/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

import React, { useState, useEffect } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
/*Utils*/
import XLSX from 'xlsx';
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency';
/*Components*/
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Services*/
import { api } from '../../../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
}

export function AssetsImportModal() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
    const [dataExcelAPI, setDataExcelAPI] = useState([]);
    const [excelRows, setExcelRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setEdited } = useModal();
    const { tokenKey } = useAuth();
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showFileButton, setShowFileButton] = useState(true);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState(new Date());
    const { screenX, screenY } = ModalSizeFitToScreen();

    useEffect(() => {
        setShowFileButton(true);
        setShowErrors(false);
        setActiveStep(0);
        setExcelRows([]);
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);
        setDataExcelAPI([]);

    }, [show])

    const handleNext = async (propsNext) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 1) {
            importBook(dataExcelAPI)
        }
    }

    async function importBook(dataFields) {
        setLoading(true);
        try {
            const importAPI = new Promise(async (resolve, reject) => {
                try {
                    const importResult = await api.post(`/api/v1/assetsImport`, {
                        assets: dataExcelAPI
                    }, {
                        headers: {
                            authorization: `Bearer ${tokenKey}`
                        }
                    })
                    resolve(importResult)
                } catch (error) {
                    reject(error)
                }
            })

            let inProcessLoading = true;
            let timeCount = 0

            do {
                setHideButtons(true);
                if (timeCount == 5) {
                    setImportPlainMessage(`A planilha está em processo de importação.`)
                } else if (timeCount < 10) {
                    setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                } else if (timeCount < 15) {
                    setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                }

                importAPI.then((value) => {
                    inProcessLoading = false;
                    setHideButtons(false);
                    setLoading(false);
                    setSuccessImport(true);
                    if (value?.data?.errors_details?.length > 0) {
                        setErrorsImport(true);
                        setErrorsList(value?.data?.errors_details);
                    }
                    setEdited(true)
                }).catch((error) => {
                    inProcessLoading = false;
                    if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                        setErrorsList([{ error_message: error?.response?.data?.message }]);
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsImport(true);
                    } else {
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsList(error?.response?.data?.errors_details);
                        setErrorsImport(true);
                    }
                })

                await new Promise(resolve => {
                    setTimeout(() => {
                        timeCount += 1
                        resolve()
                        if (timeCount == 20) timeCount = 0
                    }, 1000)
                })
            } while (inProcessLoading == true);

        } catch (error) {
            alert('Erro inesperado.')
            setLoading(false);
        }
    }

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false })

                const schemaToValidate = ['codigo_ativo', 'valor'];
                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                    if (fieldToValidate !== schemaToValidate[index]) {
                        return fieldToValidate
                    }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['asset', 'value'],
                        cellDates: false
                    });
                    resolve(data);
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        const data = await promise
        data.splice(0, 1)
        setExcelRows(data);
        //Ajusta o valor para pontuação
        let formattedData = []
        data.map((data)=>{
            
            let newValue = data.value
            
            if(typeof(data.value) === 'string'){
               newValue = parseFloat(data.value.replace(',','.'))
            }
            formattedData.push({
                asset: data.asset,
                value: newValue
            })
        })
        setDataExcelAPI(formattedData);
        return setLoading(false);
    }

    const handleBack = () => {

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSuccessImport(false);
        setActiveStep(0);
    };

    function handleFileCheck() {
        if (excelRows.length > 0) {
            handleNext()
            setShowFileButton(false);
        }
        else {
            alert('Carregue a planilha antes')
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function formatLineToApi(value, type) {

        switch (type) {
            case 'number':
                return parseFloat(value)
            case 'date':
                try {
                    return SerialDateToJSDate(value, -3)
                } catch (error) {
                    return 'Não foi possível ler campo data.'
                }
            case 'boolean':
                return value == 1 ? true : false
            default:
                return value
        }
    }

    function tableForImportReccords() {

        if (dataExcelAPI?.length > 0) {
            return (
                <table>
                    <thead>
                        <tr>
                            <th>Ativo</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataExcelAPI.map((assetToImport) => {
                                return (
                                    <tr>
                                        <td>{assetToImport?.asset}</td>
                                        <td>{toBRL(assetToImport?.value)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            )
        }

    }

    function errorsTable() {

        if (errorList?.length > 0 && errorList.error_message !== undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error_message}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

        const errorLines = Object.keys(errorList);
        if (errorLines?.length > 0) {
            return (
                <table>
                    <thead>
                        <tr>
                            <th>Erro</th>
                            <th>Ativo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            errorList.map((error) => {
                                return (
                                    <tr>
                                        <td>{error?.message}</td>
                                        <td>{error?.asset}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            )
        }
    }
     
    return (
        <div style={{
            width: screenX,
            height: screenY,
            background: 'white',
            padding: '1rem',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                {showFileButton ?
                                    (
                                        <>
                                            <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                            <a>{fileName.substring(12, fileName.length)}</a>
                                        </>
                                    ) :
                                    (<></>)}

                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}
                </div>
            </div>
            <div className='fileInput'>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                    successImport && !errorsImport ?
                        (
                            <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                <a>Sucesso ao importar atualização de Ativos</a>
                            </div>
                        ) :

                        activeStep === 1 ? (

                            <div id='customScroll' className='tableForImportSection'>
                                {tableForImportReccords()}
                            </div>

                        ) :
                            successImport && errorsImport ? (
                                <div id='flexRowDiv' className='stepperImportWithErrors'>
                                    <a>Sucesso ao atualizar valores!</a>
                                    <a>Mas, ocorreram erros na importação</a>
                                    <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                </div>
                            ) : !successImport && errorsImport ?
                                (
                                    <div id='flexRowDiv' className='stepperImportWithErrors'>
                                        <a>ocorreram erros na importação</a>
                                        <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                    </div>
                                ) :
                                showErrors ?
                                    (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                    :
                                    (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)
                }
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='flexRowDiv'>
                                <Typography className={classes.instructions}></Typography>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='flexRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='flexRowDiv'>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}