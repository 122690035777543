import React, { useEffect, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import '../styles/assets.classes.table.css'
/*Contexts*/
import { useModal } from '../../../../../contexts/useModal'
import { useAuth } from '../../../../../contexts/useAuth'
/*Services*/
import { api } from '../../../../../services/api'
import { getAssetClass } from '../../../../../services/asyncFunctions'
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader'
/*Utils*/
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'

export function AssetsClassesTable() {

    const [loading, setLoading] = useState(false);
    const { setShow, setType, edited, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const [assetClassList, setAssetClassList] = useState()

    useEffect(() => {

        async function getData() {

            setLoading(true);
            const assetsClassGet = await getAssetClass(tokenKey)
            if (assetsClassGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: assetsClassGet?.error
                    }
                })
                setShow(true);
                setLoading(false)
            } else {
                setAssetClassList(assetsClassGet)
            }
            setLoading(false)
        }
        if (edited || assetClassList?.length === undefined || assetClassList?.length === 0) {
            getData()
            setEdited(false)
        }
    }, [edited])

    const handleDeleteClass = async (thisAsset) => {

        setLoading(true);
        try {
            const deleteClass = await api.delete(`/api/v1/assetClass/${thisAsset?.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({name: 'alertMsg', type:'information', value: {message: 'Classe excluída com sucesso!'}})
            setShow(true)
            setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao remover classe de ativo' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function confirmDeleteClass(thisAsset) {

        setType({name: 'alertMsg', value: {message: 'Deseja realmente excluir a classe?', func: handleDeleteClass, dataForFunc: thisAsset}})
        setShow(true)
    }

    return (
        <div className="assetClassesTable">
            <table>
                <thead>
                    <tr>
                        <th colSpan='3'>
                            <div id='assetClassesTableTheadDiv'>
                                <p>Lista de classes para Ativos</p>
                                <label>
                                    <input className='tableAllInputThead' placeholder='Filtrar'></input>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            setType({ name: 'assetClassesCadastration' })
                                            setShow(true)
                                        }}
                                    >Cadastrar
                                    </button>
                                </label>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Classe</th>
                        <th>Mercado Fracionário</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            (
                                <td id='assetClassTableLoader' colSpan='3'>
                                    <ArrowLoader />
                                </td>
                            ) :
                            assetClassList?.length > 0 ?
                                (
                                    assetClassList?.map((assetClass) => {
                                        return <tr>
                                            <td title='Clique para editar'  onClick={() => { setType({ name: 'handleEditAssetClass', value: { assetClass } }); setShow(true) }}>{titleize(assetClass?.description)}</td>
                                            <td title='Clique para editar' onClick={() => { setType({ name: 'handleEditAssetClass', value: { assetClass } }); setShow(true) }}>{assetClass?.type === 'L' ? 'Lote' : 'Fração'}</td>
                                            <td onClick={() => confirmDeleteClass(assetClass)}><FiTrash2 /></td>
                                        </tr>
                                    })
                                ) :
                                (
                                    <tr>
                                        <td colSpan='2'>--Lista Vazia--</td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    )
}