/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//services
import { api } from "../../../../services/api";
import { getAllClients } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { Alert } from "../../../../components/alert/alert";
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { debounceSearchRender } from "mui-datatables";
import { TableCell } from "@material-ui/core";
import CustomToolbarSelectVBA from "./CustomToolbarSelectVBA";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
import TableViewCol from "../../../../utils/muiDatatables/TableViewCol";

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./styles/clientsVBA.table.css";
import { MdTexture } from "react-icons/md";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR";
import { BiDownload } from "react-icons/bi";

export function ClientsVBATable() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [filtered, setFiltered] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("Código");
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [listFilteredClients, setListFilteredClients] = useState([]);
    const [listSearchedClients, setListSearchedClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filtersList, setFiltersList] = useState({});
    const [search, setSearch] = useState("");
    const [profilesList, setProfilesList] = useState([]);
    const [listExceptionClients, setListExceptionClients] = useState([]);
    const [listExceptionClientsIDs, setListExceptionClientsIDs] = useState([]);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [viewCheckbox, setViewCheckbox] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isloaded, setIsloaded] = useState(false);
    const token = localStorage.getItem("@auth:token");
    const currentUser = JSON.parse(localStorage.getItem("@auth:user"));
    const { screenX, screenY } = ModalSizeFitToScreen();
    const [columnsToDisplay, setColumnsToDisplay] = useState();
    const [okay, setOkay] = useState(false);
    const [visibleInteractionDaysType, setVisibleInteractionDaysType] = useState(0);
    const [showAlert, setShowAlert] = useState(true);
    const [sumNetTotal, setSumNetTotal] = useState(0);
    const [sumNetStock, setSumNetStock] = useState(0);
    const [sumNetFii, setSumNetFii] = useState(0);
    const [sumPosRV, setSumPosRV] = useState(0);
    const serviceModel = [
        { value: 1, description: 'Ultra High' },
        { value: 2, description: 'Top Tier'},
        { value: 3, description: 'Private'},
        { value: 4, description: 'Digital'},
        { value: 5, description: 'On Demand'},
    ]

    async function searchClients() {
        setLoading(true);
        try {
            const clientsResult = await api.get("/api/v1/vbaNew", {
                headers: {
                    authorization: `Bearer ${token}`,
                    download: false,
                    allclients: true,
                },
            });
            clientsResult.data.data.forEach((e) => {
                e.codigo = parseInt(e.codigo);
                e.codigo_broker = e.codigo_broker ? parseInt(e.codigo_broker) : null;
                e.codigo_lider_broker = e.codigo_lider_broker ? parseInt(e.codigo_lider_broker) : null;
                e.codigo_assessor = e.codigo_assessor ? parseInt(e.codigo_assessor) : null;
                e.codigo_lider_assessor = e.codigo_lider_assessor ? parseInt(e.codigo_lider_assessor) : null;
            });
            setListClients(clientsResult.data.data.slice(0, rowsPerPage + 1001));
            setListAllClients(clientsResult.data.data);
            setListFilteredClients(clientsResult.data.data);
            setListSearchedClients(clientsResult.data.data);
            setTotalClients(clientsResult?.data?.data?.length);

            const rows = [];
            const ids = [];
            clientsResult.data.data.forEach((element, index) => {
                if (element["performance_exception"] === true) {
                    element["performance_exception"] = "Sim";
                    rows.push(index);
                    ids.push(element["codigo"]);
                } else element["performance_exception"] = "Não";
            });
            setListExceptionClients(rows);
            setListExceptionClientsIDs(ids);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false);
        }
    }

    async function setColumns() {
        if (localStorage.getItem("columnsvba")) {
            const columnsToDisplayAux = JSON.parse(localStorage.getItem("columnsvba"));
            await setColumnsToDisplay(columnsToDisplayAux);
        } else {
            let viewColumns = [];
            columnsAux.map((e) => {
                viewColumns.push({ column: e.name, display: e.options.display });
            });
            localStorage.setItem("columnsvba", JSON.stringify(viewColumns));
            setColumns();
        }
        await setOkay(true);
    }

    async function setFilters() {
        if (localStorage.getItem("filtersvba")) {
            const filters = JSON.parse(localStorage.getItem("filtersvba"));
            await setFiltersList(filters);
        } else {
            let filters = {};
            columnsAux.map((e) => {
                filters[e.name] = [];
            });
            localStorage.setItem("filtersvba", JSON.stringify(filters));
            setFilters();
        }
        await setOkay(true);
    }

    function changeInteractionDaysType(value) {
        setVisibleInteractionDaysType(value);
    }

    

    const style = {
        first: {
            position: "sticky",
            left: 0,
            zIndex: 100,
            backgroundColor: "white",
        },
        second: {
            textAlign: "center",
            position: "sticky",
            left: document?.getElementById("codigoCliente")?.getBoundingClientRect()?.width,
            zIndex: 100,
            backgroundColor: "white",
            whiteSpace: "nowrap",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
        },
    };

    const colors = { green: "#69e055", orange: "#f5b75f", red: "#ff8282" };

    useEffect(() => {
        async function getProfiles() {
            const resultProfile = await api.get("api/v1/profiles", {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            });
            setProfilesList(resultProfile.data.data);
        }

        if ((type.name === "showImportClients" && type.value === "successImport") || type.name === "successClientCadastration" || type.name === "successUpdateClient" || type.name === "successClientEdit" || edited === true) {
            setLoading(true);
            searchClients();
            getProfiles();
            setEdited(false);
        } else if (!isloaded) {
            getProfiles();
            searchClients();
            setIsloaded(true);
        }

        setColumns();
        setFilters();
    }, [show, edited]);

    useEffect(() => {
        var sumNetTotalAux = 0;
        var sumNetStockAux = 0;
        var sumNetFiiAux = 0;
        listFilteredClients.forEach((e) => {
            sumNetTotalAux += e.net_total;
            sumNetStockAux += e.net_stock;
            sumNetFiiAux += e.net_fii;
        });
        var sumPosRvAux = sumNetStockAux + sumNetFiiAux;
        setSumNetTotal(sumNetTotalAux);
        setSumNetStock(sumNetStockAux);
        setSumNetFii(sumNetFiiAux);
        setSumPosRV(sumPosRvAux);
    }, [listFilteredClients]);

    var columnsAux = null;

    columnsAux = [
        {
            name: "codigo",
            label: "CÓDIGO DO CLIENTE",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["codigo"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                //   customHeadRender: () => (
                //     <TableCell key="CÓDIGO DO CLIENTE" style={{ ...style.first, top: 0, zIndex: 101, fontWeight: 450, }} id="codigoCliente">
                //       CÓDIGO DO CLIENTE
                //     </TableCell>
                //   ),
                //   setCellProps: () => ({style: style.first}),
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "nome",
            label: "NOME",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["nome"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                // customHeadRender: () => (
                //     <TableCell key="NOME" style={{ ...style.second, top: 0, zIndex: 101, fontWeight: 450}}>
                //         NOME
                //     </TableCell>
                // ),
                // setCellHeaderProps: () => ({style: style.second, top: 0, zIndex: 101, fontWeight: 450}),
                // setCellProps: () => ({style: style.second}),
            },
        },
        {
            name: "email",
            label: "EMAIL",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[2]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[2]?.display : true,
                filterType: "custom",
                filterList: filtersList["email"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "perfil",
            label: "PERFIL",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[3]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[3]?.display : true,
                filterType: "custom",
                filterList: filtersList["perfil"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val || val === "" ? val : "-";
                },
            },
        },
        {
            name: "categoria",
            label: "SEGMENTO BROKER",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[4]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[4]?.display : true,
                filterType: "custom",
                filterList: filtersList["categoria"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        // {
        //     name: "dinamica_acoes",
        //     label: "CARTEIRA DINÂMICA AÇÕES",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         viewColumns: false,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["dinamica_acoes"],
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? toBRL(parseFloat(val)) : "-";
        //         },
        //     },
        // },
        // {
        //     name: "dinamica_fiis",
        //     label: "CARTEIRA DINÂMICA FII",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         viewColumns: false,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["dinamica_fiis"],
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? toBRL(parseFloat(val)) : "-";
        //         },
        //     },
        // },
        // {
        //     name: "ativo_dinamica",
        //     label: "ATIVO CARTEIRAS WISIR",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         viewColumns: false,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["ativo_dinamica"],
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val == 1 ? "Sim" : "Não";
        //         },
        //     },
        //     customBodyRender: (val) => {
        //         return val ? val : "-";
        //     },
        // },
        // {
        //     name: "inclusao_acoes",
        //     label: "AÇÕES DATA INCLUSÃO",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         display: columnsToDisplay ? columnsToDisplay[8]?.display : true,
        //         filterType: "custom",
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients
        //                     .map((x) => x[column.name])
        //                     .filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}
        //                         >
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        // {
        //     name: "alteracao_acoes",
        //     label: "AÇÕES DATA ALTERAÇÃO",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         display: columnsToDisplay ? columnsToDisplay[9]?.display : true,
        //         filterType: "custom",
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients
        //                     .map((x) => x[column.name])
        //                     .filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}
        //                         >
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        {
            name: "seg_assessoria",
            label: "SEG. ASSESSORIA",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[8]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[8]?.display : true,
                filterType: "custom",
                filterList: filtersList["seg_assessoria"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "adm_conta",
            label: "Apelido Administrador da Conta",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[9]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[9]?.display : true,
                filterType: "custom",
                filterList: filtersList["adm_conta"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "codigo_lider_assessor",
            label: "CÓDIGO LÍDER ASSESSOR",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[10]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[10]?.display : true,
                filterType: "custom",
                filterList: filtersList["codigo_lider_assessor"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "time_assessor",
            label: "TIME ASSESSOR",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[11]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[11]?.display : true,
                filterType: "custom",
                filterList: filtersList["time_assessor"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "codigo_assessor",
            label: "CÓDIGO ASSESSOR",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[12]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[12]?.display : true,
                filterType: "custom",
                filterList: filtersList["codigo_assessor"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "assessor",
            label: "ASSESSOR",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[13]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[13]?.display : true,
                filterType: "custom",
                filterList: filtersList["assessor"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "filial",
            label: "FILIAL",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[14]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[14]?.display : true,
                filterType: "custom",
                filterList: filtersList["filial"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: false,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["status"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        // {
        //     name: "opera_sozinho",
        //     label: "OPERA SOZINHO",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         viewColumns: false,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["opera_sozinho"],
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        // {
        //     name: "observacao",
        //     label: "OBSERVAÇÃO AÇÕES",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         viewColumns: false,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["observacao"],
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        {
            name: "codigo_lider_broker",
            label: "COD TIME",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[18]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[18]?.display : true,
                filterType: "custom",
                filterList: filtersList["codigo_lider_broker"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "time_broker",
            label: "TIME",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[19]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[19]?.display : true,
                filterType: "custom",
                filterList: filtersList["time_broker"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        // {
        //     name: "codigo_lider_broker",
        //     label: "COD LÍDER",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         display: columnsToDisplay ? columnsToDisplay[22]?.display : true,
        //         filterType: "custom",
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients
        //                     .map((x) => x[column.name])
        //                     .filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}
        //                         >
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        // {
        //     name: "lider_broker",
        //     label: "LÍDER BROKER",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         display: columnsToDisplay ? columnsToDisplay[23]?.display : true,
        //         filterType: "custom",
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients
        //                     .map((x) => x[column.name])
        //                     .filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}
        //                         >
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 paddingLeft: "0.5rem",
        //                 paddingRight: "0.5rem",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return val ? val : "-";
        //         },
        //     },
        // },
        {
            name: "codigo_broker",
            label: "COD BROKER",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[20]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[20]?.display : true,
                filterType: "custom",
                filterList: filtersList["codigo_broker"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "broker",
            label: "NOME BROKER",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[21]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[21]?.display : true,
                filterType: "custom",
                filterList: filtersList["broker"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
            },
        },
        {
            name: "net_total",
            label: "NET TOTAL",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[22]?.display : true,
                filterType: "custom",
                filterList: filtersList["net_total"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? toBRL(parseFloat(val)) : "-";
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(sumNetTotal)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "net_stock",
            label: "NET AÇÕES",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[23]?.display : true,
                filterType: "custom",
                filterList: filtersList["net_stock"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? toBRL(parseFloat(val)) : "-";
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            // changeSortGeral();
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* {column.label} {sortGeral == 0 ? "" : sortGeral == 1 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />} */}
                                {column.label}
                                {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(sumNetStock)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "percent_net_stock",
            label: "% NET AÇÕES",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? false : false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["percent_net_stock"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    return String(val.toFixed(2)) + "%";
                },
            },
        },
        {
            name: "net_fii",
            label: "NET FII",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[25]?.display : true,
                filterType: "custom",
                filterList: filtersList["net_fii"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? toBRL(parseFloat(val)) : toBRL(parseFloat(0));
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(sumNetFii)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "percent_net_fii",
            label: "% NET FII",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? false : false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["percent_net_fii"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    return String(val.toFixed(2)) + "%";
                },
            },
        },
        {
            name: "pos_rv",
            label: "POS RV",
            options: {
                filter: false,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["pos_rv"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    return toBRL(val);
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(sumPosRV)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "percent_rv",
            label: "% RV",
            options: {
                filter: false,
                sort: true,
                display: columnsToDisplay ? false : false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["percent_rv"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val, tableMeta) => {
                    return String(val.toFixed(2)) + "%";
                },
            },
        },
        {
            name: "performance_exception",
            label: "Cliente Top Tier",
            options: {
                filter: columnsToDisplay ? false : false,
                sort: true,
                display: columnsToDisplay ? false : false,
                filterType: "custom",
                filterList: filtersList["performance_exception"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        // {
        //     name: "lastInteration",
        //     label: "Última Interação",
        //     options: {
        //         viewColumns: false,
        //         filter: false,
        //         sort: true,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["lastInteration"],
        //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
        //             <TableCell
        //                 className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
        //                 onClick={() => {
        //                     sortColumn(index);
        //                 }}>
        //                 <div className="textLastInteractionDays">ÚLTIMA INTERAÇÃO (DIAS) {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}</div>
        //                 <FormControl>
        //                     <Select
        //                         value={0}
        //                         className="selectLastInteractionType bgSelectInterationGERAL"
        //                         onChange={(e) => {
        //                             changeInteractionDaysType(e.target.value);
        //                         }}>
        //                         <MenuItem value={0}>Geral</MenuItem>
        //                         <MenuItem value={1}>Ligação</MenuItem>
        //                         <MenuItem value={2}>Whatsapp</MenuItem>
        //                         <MenuItem value={3}>Reunião</MenuItem>
        //                         <MenuItem value={4}>E-mail</MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </TableCell>
        //         ),
        //         customBodyRender: (val) => {
        //             return val == "Pendente" ? "Sem interação" : parseInt(val);
        //         },
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //     },
        // },
        {
            name: "lastCommentLigacao",
            label: "ligacao",
            options: {
                viewColumns: false,
                filter: false,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["lastCommentLigacao"],
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            // changeSortLigacao();
                            sortColumn(index);
                        }}>
                        <div className="textLastInteractionDays">ÚLTIMA INTERAÇÃO (DIAS) {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}</div>
                        <FormControl>
                            <Select
                                value={1}
                                className="selectLastInteractionType bgSelectInterationLIGACAO"
                                onChange={(e) => {
                                    changeInteractionDaysType(e.target.value);
                                }}>
                                <MenuItem value={0}>Geral</MenuItem>
                                <MenuItem value={1}>Ligação</MenuItem>
                                <MenuItem value={2}>Whatsapp</MenuItem>
                                <MenuItem value={3}>Reunião</MenuItem>
                                <MenuItem value={4}>E-mail</MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, index) => {
                    var backgroundColor = "";
                    if (tableMeta.currentTableData[tableMeta.rowIndex].data[36] != "Ligação") {
                        return value < 0 || value == null ? "Sem interação" : parseInt(value);
                    }
                    if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Semanal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 7 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 7 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Quinzenal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 15 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 15 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Mensal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 30 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 30 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Bimestral") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 60 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[31] > 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 60 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[31] <= 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[31] >= 0 ? colors.green : "white";
                    }
                    return (
                        <p style={{ backgroundColor: backgroundColor }}>
                            {value < 0 || value == null ? "Sem interação" : parseInt(value)}
                            {/* {tableMeta} */}
                        </p>
                    );
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "lastCommentWhats",
            label: "whatsapp",
            options: {
                viewColumns: false,
                filter: false,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["lastCommentWhats"],
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            // changeSortWhatsapp();
                            sortColumn(index);
                        }}>
                        <div className="textLastInteractionDays">ÚLTIMA INTERAÇÃO (DIAS) {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}</div>
                        <FormControl>
                            <Select
                                value={2}
                                className="selectLastInteractionType bgSelectInterationWHATSAPP"
                                onChange={(e) => {
                                    changeInteractionDaysType(e.target.value);
                                }}>
                                <MenuItem value={0}>Geral</MenuItem>
                                <MenuItem value={1}>Ligação</MenuItem>
                                <MenuItem value={2}>Whatsapp</MenuItem>
                                <MenuItem value={3}>Reunião</MenuItem>
                                <MenuItem value={4}>E-mail</MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta, index) => {
                    var backgroundColor = "";
                    if (tableMeta.currentTableData[tableMeta.rowIndex].data[36] != "WhatsApp") {
                        return value < 0 || value == null ? "Sem interação" : parseInt(value);
                    }
                    if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Semanal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 7 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 7 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Quinzenal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 15 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 15 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Mensal") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 30 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 30 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] >= 0 ? colors.green : "white";
                    } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Bimestral") {
                        backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 60 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[32] > 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 60 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[32] <= 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[32] >= 0 ? colors.green : "white";
                    }
                    return (
                        <p style={{ backgroundColor: backgroundColor }}>
                            {value < 0 || value == null ? "Sem interação" : parseInt(value)}
                            {/* {tableMeta} */}
                        </p>
                    );
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        // {
        //     name: "lastCommentReuniao",
        //     label: "reuniao",
        //     options: {
        //         viewColumns: false,
        //         filter: false,
        //         sort: true,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["lastCommentReuniao"],
        //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
        //             <TableCell
        //                 className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
        //                 onClick={() => {
        //                     // changeSortReuniao();
        //                     sortColumn(index);
        //                 }}>
        //                 <div className="textLastInteractionDays">ÚLTIMA INTERAÇÃO (DIAS) {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}</div>
        //                 <FormControl>
        //                     <Select
        //                         value={3}
        //                         className="selectLastInteractionType bgSelectInterationREUNIAO"
        //                         onChange={(e) => {
        //                             changeInteractionDaysType(e.target.value);
        //                         }}>
        //                         <MenuItem value={0}>Geral</MenuItem>
        //                         <MenuItem value={1}>Ligação</MenuItem>
        //                         <MenuItem value={2}>Whatsapp</MenuItem>
        //                         <MenuItem value={3}>Reunião</MenuItem>
        //                         <MenuItem value={4}>E-mail</MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </TableCell>
        //         ),
        //         customBodyRender: (value, tableMeta, index) => {
        //             var backgroundColor = "";
        //             if (!tableMeta.currentTableData[tableMeta.rowIndex].data[36].includes("Reunião")) {
        //                 return value < 0 || value == null ? "Sem interação" : parseInt(value);
        //             }
        //             if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Semanal") {
        //                 backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 7 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 7 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 3 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] >= 0 ? colors.green : "white";
        //             } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Quinzenal") {
        //                 backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 15 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 15 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 7 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] >= 0 ? colors.green : "white";
        //             } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Mensal") {
        //                 backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 30 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 30 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 15 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] >= 0 ? colors.green : "white";
        //             } else if (tableMeta.currentTableData[tableMeta.rowIndex].data[38] == "Bimestral") {
        //                 backgroundColor = tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 60 ? colors.red : tableMeta.currentTableData[tableMeta.rowIndex].data[33] > 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 60 ? colors.orange : tableMeta.currentTableData[tableMeta.rowIndex].data[33] <= 30 && tableMeta.currentTableData[tableMeta.rowIndex].data[33] >= 0 ? colors.green : "white";
        //             }
        //             return (
        //                 <p style={{ backgroundColor: backgroundColor }}>
        //                     {value < 0 || value == null ? "Sem interação" : parseInt(value)}
        //                     {/* {tableMeta} */}
        //                 </p>
        //             );
        //         },
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //     },
        // },
        // {
        //     name: "lastCommentEmail",
        //     label: "email",
        //     options: {
        //         viewColumns: false,
        //         filter: false,
        //         sort: true,
        //         display: false,
        //         filterType: "custom",
        //         filterList: filtersList["lastCommentEmail"],
        //         customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
        //             <TableCell
        //                 className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
        //                 onClick={() => {
        //                     // changeSortEmail();
        //                     sortColumn(index);
        //                 }}>
        //                 <div className="textLastInteractionDays">ÚLTIMA INTERAÇÃO (DIAS) {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}</div>
        //                 <FormControl>
        //                     <Select
        //                         value={4}
        //                         className="selectLastInteractionType bgSelectInterationEMAIL"
        //                         onChange={(e) => {
        //                             changeInteractionDaysType(e.target.value);
        //                         }}>
        //                         <MenuItem value={0}>Geral</MenuItem>
        //                         <MenuItem value={1}>Ligação</MenuItem>
        //                         <MenuItem value={2}>Whatsapp</MenuItem>
        //                         <MenuItem value={3}>Reunião</MenuItem>
        //                         <MenuItem value={4}>E-mail</MenuItem>
        //                     </Select>
        //                 </FormControl>
        //             </TableCell>
        //         ),
        //         customBodyRender: (value, tableMeta, index) => {
        //             return value < 0 || value == null ? "Sem interação" : parseInt(value);
        //         },
        //         customFilterListOptions: {
        //             render: (v) => v,
        //             update: (filterList, filterPos, index) => {
        //                 filterList[index].splice(filterPos, 1);
        //                 return filterList;
        //             },
        //         },
        //         filterOptions: {
        //             logic: (location, filters, row) => {
        //                 if (filters.length) return !filters.includes(location);
        //                 return false;
        //             },
        //             display: (filterList, onChange, index, column) => {
        //                 const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

        //                 return (
        //                     <FormControl>
        //                         <InputLabel htmlFor="select-multiple-chip" variant="standard">
        //                             {column.label}
        //                         </InputLabel>
        //                         <Select
        //                             multiple
        //                             variant="standard"
        //                             value={filterList[index]}
        //                             renderValue={(selected) => selected.join(", ")}
        //                             onChange={(event) => {
        //                                 filterList[index] = event.target.value;
        //                                 onChange(filterList[index], index, column);
        //                             }}>
        //                             {optionValues.map((item) => (
        //                                 <MenuItem key={item} value={item}>
        //                                     <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
        //                                     <ListItemText primary={item} />
        //                                 </MenuItem>
        //                             ))}
        //                         </Select>
        //                     </FormControl>
        //                 );
        //             },
        //         },
        //     },
        // },
        {
            name: "preferred_contact",
            label: "id Preferencia",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "preferred_contact_description",
            label: "Contato preferencial",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[36]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[36]?.display : true,
                filterType: "custom",
                filterList: filtersList["preferred_contact_description"],
                customBodyRender: (val) => {
                    return val == null ? "Pendente" : val;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "frequency_contact",
            label: "id Frequencia",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "frequency_contact_description",
            label: "Frequência de contato",
            options: {
                filter: false,
                sort: true,
                display: false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["frequency_contact_description"],
                customBodyRender: (val) => {
                    return val == null ? "Pendente" : val;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "hb_service",
            label: "Serviço HB?",
            options: {
                filter: false,
                sort: true,
                viewColumns: false,
                display: false,
                filterType: "custom",
                filterList: filtersList["hb_service"],
                customBodyRender: (val) => {
                    return val ? (val.toLowerCase() == "sim" ? "Sim" : "Não") : "Não";
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "inclusao_acoes",
            label: "Data de Indicação",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[40]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[40]?.display : true,
                filterType: "custom",
                filterList: filtersList["inclusao_acoes"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                customBodyRender: (val) => {
                    return val ? datetimeToDatePtBR(val) : "-";
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "advisor_profile",
            label: "perfil assessor",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "broker_relationship",
            label: "Status Relacionamento",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[42]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[42]?.display : true,
                filterType: "custom",
                filterList: filtersList["broker_relationship"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },
        },
        {
            name: "dinamica_renda",
            label: "CARTEIRA DINÂMICA RENDA IMOBILIÁRIA",
            options: {
                filter: false,
                sort: true,
                display: false,
                viewColumns: false,
                filterType: "custom",
                filterList: filtersList["dinamica_renda"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? toBRL(parseFloat(val)) : "-";
                },
            },
        },
        {
            name: "def_broker_id",
            label: "CÓDIGO BROKER REFERÊNCIA",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[44]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[44]?.display : true,
                filterType: "custom",
                filterList: filtersList["def_broker_id"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        {
            name: "def_broker",
            label: "BROKER REFERÊNCIA",
            options: {
                filter: columnsToDisplay ? columnsToDisplay[45]?.display : true,
                sort: true,
                display: columnsToDisplay ? columnsToDisplay[45]?.display : true,
                filterType: "custom",
                filterList: filtersList["def_broker"],
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                    },
                }),
                customBodyRender: (val) => {
                    return val ? val : "-";
                },
            },
        },
        
        {
            name: "seg_client",
            label: "Segmento Cliente",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["seg_client"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == false ? 'Digital' : 'Private';
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "serviceModel",
            label: "MODELO DE ATENDIMENTO",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["serviceModel"],
                customBodyRender: (val) => { 
                    return val == null ? "-" : val == "1" ? 'Ultra High' : val == "2" ? 'Top Tier' : val == "3" ? 'Private' : val == "4" ? 'Digital' : val == "5" ? 'On Demand' : '-';
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        
        {
            name: "evasionReason",
            label: "EVASÃO",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["evasionReason"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == "1" ? 'Assessor solicitou retirada da mesa' : val == '2' ? 'Cliente zerou posição de RV' : val == "3" ? 'Cliente evadiu do escritório' : val == "4" ? 'Cliente solicitou cancelamento do serviço da mesa' : val == "5" ? 'Outro' : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "contact",
            label: "CONTATAR?",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["contact"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == true ? "Sim" : "Não"; 
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "assetPreference",
            label: "PREFERÊNCIA DE ATIVOS",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["assetPreference"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "assetRestriction",
            label: "Restrição de Ativos",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["assetRestriction"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "meetLanguage",
            label: "LINGUAGEM DESEJADA",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["meetLanguage"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val == "0" ? "Informal" : "Formal";
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "generalObservations",
            label: "NOTAS GERAIS",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterType: "custom",
                filterList: filtersList["generalObservations"],
                customBodyRender: (val) => {
                    return val == null ? "-" : val ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        {
            name: "gap_receita",
            label: "Gap Receita",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["gap_receita"],
                customBodyRender: (val) => {
                    return val == null ?  toBRL(0) : val < 0 ? "Ativo" : toBRL(val) ;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
        
        {
            name: "statusReceita",
            label: "status Receita",
            options: {
                filter: true,
                sort: true,
                display: true,
                filterType: "custom",
                filterList: filtersList["statusReceita"],
                customBodyRender: (val) => {
                    return val == '-' ? 'Ativo' : val;
                },
                customFilterListOptions: {
                    render: (v) => v,
                    update: (filterList, filterPos, index) => {
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    },
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (filterList, onChange, index, column) => {
                        const optionValues = listSearchedClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v));

                        return (
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip" variant="standard">
                                    {column.label}
                                </InputLabel>
                                <Select
                                    multiple
                                    variant="standard"
                                    value={filterList[index]}
                                    renderValue={(selected) => selected.join(", ")}
                                    onChange={(event) => {
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}>
                                    {optionValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Checkbox color="primary" checked={filterList[index].indexOf(item) > -1} />
                                            <ListItemText primary={item} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
            },

        },
    ];
    async function clientsExportOld() {
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/vba/`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    download: true,
                    allClients: false,
                },
                responseType: "blob",
            });

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "lista_de_clientes.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    async function clientsExport() {
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/vbaNew/`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    download: true,
                    allClients: true,
                },
                responseType: "blob",
            });

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "lista_de_clientes.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    async function exportCRM(initialDate, finalDate) {
        const justToday = initialDate != undefined && finalDate != undefined ? "false" : "true";
        //format: mm/dd/yyyy
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/vba/crm/${justToday}/${initialDate}/${finalDate}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    download: true,
                },
                responseType: "blob",
            });

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "crm.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }


    function copyToClipboard() {
        const output = [];
        const titles = []
        columnsAux.map((e) => {
            if (e?.options?.display) {
                titles.push(e.label);
            }
        });

        output.push(titles.join("\t"));
        const keys = []
        columnsAux.map((e) => {
            if (e?.options?.display) {
                keys.push(e.name); 
            }
        });
        listAllClients.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "dinamica_acoes" || y === "dinamica_fiis" || y === "net_total" || y === "net_stock" || y === "pos_rv" || y === "dinamica_renda"
                    ? x[y]
                        ? String(x[y]).replace(".", ",")
                        : 0
                    : y === "net_fii"
                    ? x["net_fii"]
                        ? x["net_fii"]
                        : 0
                    : y === "percent_net_fii" || y === "percent_rv" || y === "percent_net_stock"
                    ? String(x[y].toFixed(2)).replace(".", ",") + "%"
                    : y === "lastInteration" || y === "lastCommentLigacao" || y === "lastCommentWhats" || y === "lastCommentReuniao" || y === "lastCommentEmail"
                    ? isNaN(parseInt(x[y])) || x[y] < 0
                        ? "Sem interação"
                        : parseInt(x[y])
                    : y === "opera_sozinho"
                    ? x[y] == null || x[y] == "" || x[y] == " "
                        ? "Não"
                        : titleize(x[y])
                    : y === "inclusao_acoes"
                        ? x[y] ? datetimeToDatePtBR(x[y]) : '-'
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function showClientDetails(client) {
        const outros = listClients.find((e) => e.codigo == client[0]);
        setShow(true);
        setType({ name: "showVbaClientDetails", value: { client: client }, outros: outros });
    }

    async function showModalSetToBase() {
        setShow(true);
        setType({ name: "showVbaClientSetToBase" });
    }

    // function alterViewColumns(data) {
    //     //    setColumns(data)
    //     setShow(false);
    //     var viewColumns = [];
    //     data.map((e) => {
    //         const name = e.name;
    //         const display = e.options.display;
    //         viewColumns.push({ column: name, display: display });
    //     });
    //     localStorage.setItem("columnsvba", JSON.stringify(viewColumns));
    // }

    // async function showModalShowColumns() {
    //     setShow(true);
    //     setType({ name: "showSetColumnsToView", columns: columnsAux, alterViewColumns: alterViewColumns });
    // }

    async function showModalExportCRM() {
        setShow(true);
        setType({ name: "exportCRM", func: exportCRM });
    }

    async function showModalReguaPadrao() {
        setShow(true);
        setType({ name: "reguaPadrao" });
    }

    async function showModalImport() {
        setShow(true);
        setType({ name: "showVbaClientsImport" });
    }

    async function showModalImportStandBy() {
        setShow(true);
        setType({ name: "showVbaClientsImportStandBy" });
    }

    async function updateExceptionClients(clientsIds) {
        var idsC = [];
        clientsIds.map((e) => {
            return idsC.push(parseInt(e));
        });
        try {
            const clientsResult = await api.put(
                "/api/v1/vba/performanceException",
                {
                    clients: idsC,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            searchClients();
            setType({ name: "successClientEdit", type: "information", value: { message: `${clientsResult.data.message}` } });
            setShow(true);
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
        }
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            {user.type < 2 ? (
                <Tooltip disableFocusListener title="Régua Padrão">
                    <IconButton
                        onClick={() => {
                            showModalReguaPadrao();
                        }}>
                        <span style={{ fontSize: "1.4rem" }}>Régua padrão</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            {user.type < 3 ? (
                <Tooltip disableFocusListener title="Tombar">
                    <IconButton
                        onClick={() => {
                            showModalImport();
                        }}>
                        <FiPlus />
                        <span style={{ fontSize: "1.4rem" }}>Tombar</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            {user.type <= 3 ? (
                <Tooltip disableFocusListener title="Importar">
                    <IconButton
                        onClick={() => {
                            showModalImportStandBy();
                        }}>
                        <FiPlus />
                        <span style={{ fontSize: "1.4rem" }}>Stand-By</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            {/* <Tooltip disableFocusListener title="Mostrar Colunas">
                <IconButton onClick={() => { showModalShowColumns()}}>
                    <span style={{fontSize: "1.4rem"}}>Personalizar Colunas</span>
                </IconButton>
            </Tooltip> */}
            {user.type < 3 ? (
                <Tooltip disableFocusListener title="Exportar CRM">
                    <IconButton
                        onClick={() => {
                            showModalExportCRM();
                        }}>
                        <span style={{ fontSize: "1.4rem" }}>CRM</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            {user.type === 3 ? (
                <Tooltip disableFocusListener title="Clientes exceção">
                    <IconButton
                        onClick={() => {
                            setViewCheckbox(!viewCheckbox);
                        }}>
                        <span style={{ fontSize: "1.4rem" }}>Clientes exceção</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Download Dashboard Antiga">
                <IconButton onClick={clientsExportOld}>
                    <BiDownload />
                </IconButton>
            </Tooltip>
        </>
    );

    const columnsExceptionView = ["codigo", "nome", "frequency_contact", "preferred_contact", "lastCommentLigacao", "lastCommentWhats", "lastCommentEmail", "lastCommentReuniao", "advisor_profile"];
    const columnsOrder = [0, 1, 2, 3, 4, 5, 6, 7, 8,  9 , 10, 11,  46 , 47 ,12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45];
    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true,
        rowsSelected: user.type === 3 && viewCheckbox === true ? listExceptionClients : [],
        selectToolbarPlacement: "replace",
        selectableRows: "multiple",
        viewColumns: true,
        columnOrder: columnsOrder,
        onViewColumnsChange: (changedColumn, action) => {
            let viewColumns = [];

            //em caso de clicar em mostrar todas
            if (changedColumn == null && action == "update") {
                columnsAux.map((e) => {
                    if (e.name == "codigo" || e.name == "nome") {
                        viewColumns.push({ column: e.name, display: true });
                    } else if (columnsExceptionView.indexOf(e.name) != -1) {
                        viewColumns.push({ column: e.name, display: false });
                    } else {
                        viewColumns.push({ column: e.name, display: true });
                    }
                });
                localStorage.setItem("columnsvba", JSON.stringify(viewColumns));
                setColumns();
                return;
            }
            //codigo e nome, nao fazer nada
            if (changedColumn == "codigo" || changedColumn == "nome") return;

            columnsAux.forEach((e) => {
                if (e.name == changedColumn) {
                    e.options.display = action == "remove" ? false : true;
                }
            });
            columnsAux.map((e) => {
                viewColumns.push({ column: e.name, display: e.options.display });
            });
            localStorage.setItem("columnsvba", JSON.stringify(viewColumns));
            setColumns();
        },
        rowsPerPage: rowsPerPage,
        page: page,
        count: listFilteredClients.length,
        rowsPerPageOptions: [50,100,500,1000],
        // draggableColumns: {
        //     enabled: true,
        // },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "vba.csv",
            separator: ";",
        },
        onDownload: () => {
            clientsExport();
            return false;
        },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            if (!viewCheckbox) showClientDetails(rowData);
        },
        setTableProps: () => {
            return {
                padding: "none",
                size: "small",
            };
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => <CustomToolbarSelectVBA selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} setListExceptionClients={setListExceptionClients} listExceptionClients={listExceptionClients} setViewCheckbox={setViewCheckbox} viewCheckbox={viewCheckbox} updateExceptionClients={updateExceptionClients} listExceptionClientsIDs={listExceptionClientsIDs} team={"teamClients"} />,
        onChangePage: (atualPage) => {
            setPage(atualPage);
            if (atualPage > lastPage) {
                setListClients(listClients.concat(listFilteredClients.slice(listClients.length, listClients.length + rowsPerPage)));
                setLastPage(atualPage);
            }
        },
        onChangeRowsPerPage: (rows) => {
            setRowsPerPage(rows);
            setLastPage(0);
            setPage(0);
            setListClients(listFilteredClients.slice(0, rows + 1));
        },
        onSearchChange: (searchText) => {
            searchText = searchText === null ? "" : searchText;
            setSearch(searchText);
            var filterListTemp = filtersList;
            filterListTemp["search"] = searchText;
            setFiltersList(filterListTemp);
            var filteredClientsTemp = [];
            if (searchText != "" && searchText != undefined) {
                const chavesSelecionadas = Array.from(
                    new Set(
                        columnsToDisplay
                            .filter((objeto) => objeto.display === true) // filtra somente os objetos com display = true
                            .map((objeto) => objeto.column) // extrai somente os valores da coluna
                    )
                );
                //filtra apenas pelas colunas visiveis no momento
                filteredClientsTemp = listAllClients.filter(
                    (row) =>
                        Object.values(Object.fromEntries(Object.entries(row).filter(([chave, valor]) => chavesSelecionadas.includes(chave))))
                            .join("|")
                            .toLowerCase()
                            .indexOf(String(searchText)) > -1
                );
                setListSearchedClients(filteredClientsTemp);
                setLastPage(0);
                setPage(0);
            } else {
                setListSearchedClients(listAllClients);
                setLastPage(0);
                setPage(0);
                filteredClientsTemp = listAllClients;
            }
            var aux = filteredClientsTemp.filter((row) => {
                var exists = true;
                for (const property in filtersList) {
                    if (property !== "search") exists = filtersList[property].length === 0 ? exists : filtersList[property].includes(row[property]) ? exists : false;
                }
                return exists;
            });
            setListFilteredClients(aux);
            setListClients(aux.slice(0, rowsPerPage + 1001));
            setLastPage(0);
            setPage(0);

            const rows = [];
            const ids = [];
            aux.forEach((element, index) => {
                if (element["performance_exception"] === "Sim") {
                    rows.push(index);
                    ids.push(element["codigo"]);
                }
            });
            setListExceptionClients(rows);
            setListExceptionClientsIDs(ids);
        },
        onFilterChange: (column, list, e, i) => {
            var filterListTemp = filtersList;
            if (search !== "") filterListTemp["search"] = search;
            if (i !== null) {
                if (!filterListTemp.hasOwnProperty(column)) {
                    filterListTemp[column] = [];
                }
                filterListTemp[column] = list[i];
            } else {
                for (const property in filterListTemp) {
                    filterListTemp[property] = [];
                }
            }
            localStorage.setItem("filtersvba", JSON.stringify(filterListTemp));
            var aux = listSearchedClients.filter((row) => {
                var exists = true;
                for (const property in filterListTemp) {
                    exists = filterListTemp[property].length === 0 ? exists : property === "search" && filterListTemp["search"] !== "" ? (Object.values(row).join("|").toLowerCase().indexOf(String(filterListTemp[property])) > -1 ? exists : false) : filterListTemp[property].includes(row[property]) ? exists : false;
                }
                return exists;
            });
            setFiltersList(filterListTemp);
            setLastPage(0);
            setPage(0);
            setListFilteredClients(aux);
            setListClients(aux.slice(0, rowsPerPage + 1001));

            const rows = [];
            const ids = [];
            aux.forEach((element, index) => {
                if (element["performance_exception"] === "Sim") {
                    rows.push(index);
                    ids.push(element["codigo"]);
                }
            });
            setListExceptionClients(rows);
            setListExceptionClientsIDs(ids);
        },
        onColumnSortChange: (column, order) => {
            if (listFilteredClients.length > 1) {
                var orderedList = "";

                if (column == "inclusao_acoes") {
                    orderedList = listFilteredClients.sort((a, b) => {
                        const dataA = Date.parse(a.inclusao_acoes);
                        const dataB = Date.parse(b.inclusao_acoes);
                        return dataA - dataB;
                    });
                    if (order === "desc") {
                        orderedList.reverse();
                    }
                } else {
                    orderedList = listFilteredClients.sort((a, b) => {
                        // let fa = a[column] !== 'null' ? a[column] : '';
                        // let fb = b[column] !== 'null' ? b[column] : '';

                        let fa = a[column] == null ? "-" : a[column];
                        let fb = b[column] == null ? "-" : b[column];

                        if (typeof fa === "string" || typeof fb === "string") {
                            fa = String(fa).toLowerCase().trim();
                            fb = String(fb).toLowerCase().trim();
                        }

                        return order === "asc" ? (fa < fb ? -1 : fa > fb ? 1 : 0) : fa < fb ? 1 : fa > fb ? -1 : 0;
                    });
                }

                setListFilteredClients(orderedList);
                setLastPage(0);
                setPage(0);
                setListClients(orderedList.slice(0, rowsPerPage + 1001));

                const rows = [];
                const ids = [];
                orderedList.forEach((element, index) => {
                    if (element["performance_exception"] === "Sim") {
                        rows.push(index);
                        ids.push(element["codigo"]);
                    }
                });
                setListExceptionClients(rows);
                setListExceptionClientsIDs(ids);
            }
        },
    };

    const title = (
        <div>
            <div style={{ fontSize: "1.6rem", fontWeight: "400" }}>{user.type !== 3 ? "VBA" : "Clientes mesa"}</div>
            <div>Total {totalClients}</div>
        </div>
    );

    const CustomToView = (props) => {
        return <TableViewCol {...props} ocultColumnsToView={columnsExceptionView} orderColumns={columnsOrder} />;
    };

    function funcaoTeste() {
        setShowAlert(false);
    }

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return loading ? (
        <div className="divLoading">
            <ArrowLoader />
        </div>
    ) : okay ? (
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={title}
                data={listClients}
                columns={columnsAux}
                options={options}
                components={{
                    TableViewCol: CustomToView,
                }}></MUIDataTable>
        </ThemeProvider>
    ) : (
        <></>
    );
}
