/* Tela de consulta/criação de perfis de brokers
/* Desenvolvido por: Equipe Rafael Cezário
*/


import React, { useState, useEffect } from 'react';
/*Styles*/
import './brokerProfiles.css';
import { TextField } from '@material-ui/core';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { FiTrash } from 'react-icons/fi';
/*Services*/
import { api } from '../../../../services/api';
import { getBrokerProfiles } from '../../../../services/asyncFunctions';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Utils Components*/
import Modal from '../../../../components/modal/modal';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';

export function BrokerProfiles() {

    const [selectedDescription, setSelectedDescription] = useState('');
    const classess = styleClientsInput();
    const { show, setShow, type, setType, edited, setEdited } = useModal();
    const [loading, setLoading] = useState(true);
    const [successResponse, setSuccessResponse] = useState(true);
    const [successRegister, setSuccessRegister] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const { tokenKey } = useAuth();


    async function exec() {

        const brokerProfilesGet = await getBrokerProfiles(tokenKey)
        if (brokerProfilesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: brokerProfilesGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {
            setProfileList(brokerProfilesGet);
            setLoading(false)
        }
        setEdited(false)
    }

    useEffect(() => {

        if (edited || profileList.length === 0) {
            setLoading(true)
            exec()
        }
    }, [edited])

    async function handleSubmit(e) {

        e.preventDefault();
        setLoading(true);

        if(!selectedDescription.trim().length){

            alert("Campo obrigatório não preenchido")
            setLoading(false)
            return
        }
        try {
            const newProfileResult = await api.post(`api/v1/profiles`, {
                "description": selectedDescription
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: "Perfil cadastrado com sucesso!" } })
            setShow(true)
            setEdited(true)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setLoading(false);
        }

    }

    const handleDelete = async (data) => {

        setLoading(true);
        setShow(false)
        try {

            await api.delete(`api/v1/profiles/${data?.profile_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao excluir perfil' } });
            setShow(true)
            setEdited(true)
        }
        catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setEdited(true)
        }
    }

    function confirmationMsg(user) {

        setType({ name: 'alertMsg', type: 'normalMsg', value: { message: 'Deseja realmente excluir o perfil selecionado?', func: handleDelete, dataForFunc: user } })
        setShow(true);
    }

    return (
        <div className='brokerProfilesMainContainer' style={{ minWidth: !show ? '600px' : '0' }}>
            <Modal>
                <div className='brokerProfilesCadastration'>
                    {type.name === 'cadastrationModal' ?
                        loading ?
                            (
                                <div className='brokerProfilesCenterItens'>
                                    <ArrowLoader />
                                </div>) :
                            (
                                <>
                                    <div className='borkerProfilesCadastrationHeader'>
                                        <p>Efetue o cadastro abaixo</p>
                                        <button onClick={() => setShow(!show)}>X</button>
                                    </div>
                                    <div className='brokerProfileCadastrationBody'>
                                        <form onSubmit={(e) => handleSubmit(e)}>
                                            <label>
                                                <TextField
                                                    inputProps={{ maxLength: '50' }}
                                                    label='Descrição'
                                                    value={selectedDescription}
                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                    variant='outlined'
                                                    onChange={(e) => setSelectedDescription(e.target.value)}
                                                    className={classess.root}
                                                ></TextField>
                                            </label>
                                            <button type='submit' className='buttonWhiteBg' onClick={(e) => handleSubmit(e)}>Cadastrar</button>
                                        </form>
                                    </div>
                                </>
                            ) :
                        type.name === 'alertMsg' ?
                            (
                                <ConfirmationBox msgData={type} />
                            ) : (<></>)}
                </div>
            </Modal >
            <div className='brokerProfilesBody'>
                <div className='brokerProfilesTableContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th colSpan='3'>
                                    <div className='theadTitle'>
                                        <p>Perfis Broker</p>
                                        <button id='newBrokerProfile'
                                            onClick={() => {
                                                setShow(!show);
                                                setSelectedDescription('');
                                                setType({ name: 'cadastrationModal' })
                                            }}
                                        >+ Novo</button>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>Código</th>
                                <th>Descrição</th>
                                <th>Excluir</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                (
                                    <td colSpan='3' id='brokerProfilesLoader'>
                                        <ArrowLoader />
                                    </td>
                                ) :
                                (
                                    profileList.map((profile) => {
                                        return (
                                            <tr>
                                                <td>{profile?.profile_id}</td>
                                                <td>{titleize(profile?.profile_description)}</td>
                                                <td id='lastTd'>
                                                    <FiTrash
                                                        cursor='pointer'
                                                        onClick={() => confirmationMsg(profile)}
                                                    /></td>
                                            </tr>
                                        )
                                    })
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )

}