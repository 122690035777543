/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './styles/myStructuredOrdersStatusUpdate.css';
import { useModal } from '../../../../contexts/useModal';
import { api } from '../../../../services/api'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs'
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview'
import { validyFiles } from '../../../../utils/validyTypesOfFiles';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));

function getSteps(externalSteps) {
    return externalSteps;
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        case 1:
            return 'Os dados estão corretos? Se sim Clique em importar';
        case 2:
            return 'Clique em importar abaixo'
        default:
            return '';
    }
}

export function MyStructuredOrderStatusUpdate(props) {

    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)
    const [fileName, setFilename] = useState('')
    const steps = ['Selecione o arquivo', 'Confira os dados']
    const [rows, setRows] = useState([])
    const [errorList, setErrorsList] = useState([])
    const [successImport, setSuccessImport] = useState(false)
    const [hideButtons, setHideButtons] = useState(false)
    const [file, setFile] = useState([])
    const [loading, setLoading] = useState(false)
    const { show, setShow, setType, setEdited } = useModal()
    const token = localStorage.getItem('@auth:token')
    const [errorsImport, setErrorsImport] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [importPlainMessage, setImportPlainMessage] = useState('')
    const { screenX, screenY } = ModalSizeFitToScreen()
    const typeOfFields = [
        { field: 'ID', type: 'number' },
        { field: 'Status', type: 'string' }
    ]

    useEffect(() => {

        setActiveStep(0);
        setRows([]);
        setFilename('');
        setActiveStep(0)
        setShowErrors(false)
        setSuccessImport(false);
        setErrorsImport(false);

    }, [show])

    const handleNext = async (props) => {

        if (activeStep === steps.length - 1) {

            rows.forEach((key, indexRows) => {

                const columnInExcel = Object.keys(key);

                columnInExcel.map((columnName) => {
                    typeOfFields.map((typeFieldsToAPI) => {
                        if (columnName === typeFieldsToAPI.field) {
                            rows[indexRows][columnName] = formatLineToApi(rows[indexRows][columnName], typeFieldsToAPI.type);
                        }
                    })
                })
            })
            try {
                const importAPI = new Promise(async (resolve, reject) => {
                    try {
                        const importResult = await api.post('/api/v1/booksOrders/importChanges', {
                            "orders": rows
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            }
                        )
                        resolve(importResult)
                    } catch (error) {
                        reject(error)
                    }
                })

                let inProcessLoading = true;
                let timeCount = 0

                do {
                    setHideButtons(true);
                    if (timeCount == 5) {
                        setImportPlainMessage(`A planilha está em processo de importação.`)
                    } else if (timeCount < 10) {
                        setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                    } else if (timeCount < 15) {
                        setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                    }

                    importAPI.then((value) => {
                        inProcessLoading = false;
                        setHideButtons(false);
                        setLoading(false);
                        setSuccessImport(true);
                        setEdited(true)
                        setType({ name: 'updateOrdersStatus', value: 'successImport' })
                        if (value?.data?.errors_details?.length > 0) {
                            setErrorsImport(true);
                            setErrorsList(value?.data?.errors_details);
                        }
                    }).catch((error) => {
                        inProcessLoading = false;

                        if (error?.response?.data?.status === 500) {

                            setEdited(true)
                            setErrorsList([{ error_message: error?.response?.data?.message }]);
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsImport(true);
                        } else {
                            setEdited(true)
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsList(error?.response?.data?.errors_details);
                            setErrorsImport(true);
                        }
                    })

                    await new Promise(resolve => {
                        setTimeout(() => {
                            timeCount += 1
                            resolve()
                            if (timeCount == 20) timeCount = 0
                        }, 1000)
                    })

                } while (inProcessLoading == true);

            } catch (error) {
                alert('Erro inesperado.')
                setLoading(false);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                const schemaToValidate = [
                    { excelValue: 'ID', bdValue: 'NATUREZA' },
                    { excelValue: 'Status', bdValue: 'PAPEL' },
                ];
                let indexIgnored = 0;
                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                if(fieldToValidate != "ID" && fieldToValidate != "Status"){
                        indexIgnored++
                }
                else{
                        if (fieldToValidate !== schemaToValidate[index-indexIgnored]?.excelValue) {
                            return fieldToValidate
                        }
                }
                })
                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: fieldsInExcel[0],
                        cellDates: false
                    });
                    resolve(data)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        const data = await promise
        data.splice(0, 1)

        let newFormattedData = []
        
        data.map((data) => {

            let newTargetValue = data.target_value

            if (typeof (data.target_value) === 'string') {
                newTargetValue = parseFloat(data.target_value.replace(',', '.'))
            }
            
            newFormattedData.push({
                'ID': data.ID,
                'Status': data.Status
            })
        })
        console.log(newFormattedData)
        setRows(newFormattedData);
        return setLoading(false);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSuccessImport(false);
        setActiveStep(0);
    };

    function handleFileCheck() {
        if (rows.length > 0) {
            handleNext()
        }
        else {
            alert('Carregue a planilha antes')
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function formatLineToApi(value, type) {

        switch (type) {
            case 'number':
                return parseFloat(value)
            case 'date':
                if (typeof (value) === 'number') {
                    try {
                        return SerialDateToJSDate(value, -3)
                    } catch (error) {
                        return 'Não foi possível ler campo data verifique a planilha.'
                    }
                } else if (value?.indexOf('/') > -1 && value?.indexOf('/') === 2) {
                    const day = value?.slice(0, 2)
                    const month = value?.slice(3, 5)
                    const year = value?.slice(6, 10)
                    return `${year}-${month}-${day}`
                } else {
                    return 'Não foi possível ler campo data verifique a planilha.'
                }

            case 'boolean':
                return value == 1 ? true : false
            default:
                return value
        }
    }

    function tableForImportReccords() {

        return (
            rows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>ID</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {rows?.map((bodyRows, index) => {
                            const fieldKeys = Object.keys(bodyRows)
                            return (
                                <tr>
                                    {
                                        typeOfFields.map((typeField) => {
                                            if (fieldKeys.indexOf((typeField.field) > -1)) {
                                                return (
                                                    <td>{formatLineToTablePreview(bodyRows[typeField.field], typeField.type)}</td>
                                                )
                                            }
                                        })
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)
        )
    }

    function errorsTable() {

        if (errorList?.length > 0) {
            return (
                <table id='tableErrorsImport'>
                    <thead>
                        <tr>
                            <th>Erro</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }
    }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                <a>{fileName.substring(12, fileName.length)}</a>
                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) : successImport && !errorsImport ?
                    (
                        <div id='stepperRowDiv' className='stepperImportWithoutErrors'>
                            <a>Sucesso ao importar os clientes</a>
                        </div>
                    ) :
                    successImport && errorsImport ? (
                        <div id='stepperRowDiv' className='stepperImportWithErrors'>
                            <a>Sucesso ao importar arquivos!</a>
                            <a>Mas, ocorreram erros na importação</a>
                            <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                        </div>
                    ) : !successImport && errorsImport ?
                        (
                            <div id='stepperRowDiv' className='stepperImportWithErrors'>
                                <a>ocorreram erros na importação</a>
                                <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                            </div>
                        ) :
                        showErrors ?
                            (<div id='customScroll' className='tableErrorsImport'> {errorsTable()}</div>)
                            :
                            (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='stepperRowDiv'>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='stepperRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div >
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='stepperRowDiv' >
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext(props) : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}