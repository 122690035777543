import React, { useEffect, useState } from 'react';

import './performance.css'

import { useModal } from '../../../contexts/useModal';
import { useAuth } from '../../../contexts/useAuth';

import { ArrowLoader } from '../../../components/loaders/arrowLoader';
import { ArrowLoaderWOText } from '../../../components/loaders/loaderWithoutText';
import { ArrowLoaderWhiteWOText } from '../../../components/loaders/loaderWhitoutTextWhite';

import { api } from '../../../services/api';

import { titleize } from '../../../utils/formatNumberAndText/formatTextUpperFirstLetter';

import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";

import { HiSortAscending, HiSortDescending} from 'react-icons/hi'
import { FiArrowDown, FiCopy } from 'react-icons/fi';

export function PerformanceTabsTable(){
  const { user, tokenKey } = useAuth()

  const { show, setShow, edited, setEdited, type, setType } = useModal()

  const [loadingTable, setLoadingTable] = useState(false)
  const [loading, setLoading] = useState(false)

  const [activePerformanceTable, setActivePerformanceTable] = useState([])
  const [activePerformanceTab, setActivePerformanceTab] = useState("Filiais")

  const [filter, setFilter] = useState('')
  const [placeHolderPerformanceTable, setPlaceHolderPerformanceTable] = useState(0)

  const [orderingColumnName, setOrderingColumnName] = useState("")
  const [orderDescending, setOrderDescending] = useState(false)

  useEffect(() => {
    setActivePerformanceTab("Filiais")
    getPerformanceTabs_filiais()
  }, [tokenKey])

  // REQUISIÇÕES DE ABA DA TABELA ++++++++++++++++++++++++++++++++++++++++++++++

  async function performanceExport() {
    setLoading(true);
    try {
        const exportAssetsResult = await api.get(`/api/v1/BI`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        console.log(exportAssetsResult)

        const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
        const link = document.createElement('a');
        link.href = responseURL;
        link.setAttribute('download', 'relatorios_de_performance.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false)

    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
}

  async function getPerformanceTabs_filiais(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/filiais`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }
  
  async function getPerformanceTabs_times(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/times`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_time(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/time`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_detalhe_net(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/detalhe_net`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_captacao_liquida(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/captacao_liquida`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_roa_bruto(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/roa_bruto`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_nps(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/nps`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_ativacao_contas(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/ativacao_contas`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_captacao_liquida_detalhe(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/captacao_liquida/detalhe`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }

  async function getPerformanceTabs_roa_bruto_detalhe(){
    setLoading(true);
    try {
      const performanceTab = await api.get(`api/v1/BI/assessor/${user.external_id}/roa_bruto/detalhe`, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setActivePerformanceTable(performanceTab?.data?.data)
      if(performanceTab?.data?.data?.length != 0){
        setPlaceHolderPerformanceTable(Object.keys(performanceTab?.data?.data[0]).length)
      }
      else{
        setPlaceHolderPerformanceTable(0)
      }
    } catch (error) {
      setActivePerformanceTable([])
      setLoading(false);
      setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao carregar a tabela' : error?.response?.data?.message } })
      setShow(true);
    }
    setLoading(false);
    setLoadingTable(false);
  }
  // REQUISIÇÕES DE ABA DA TABELA ----------------------------------------------
  // FILTRO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function filteredTable() {
    if (filter != undefined && filter != '' && filter != null) {
        return activePerformanceTable.filter(row => {
          const rowKeys = Object.keys(row);
          if(rowKeys.filter(key => {
            return row?.[key]?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1
          }).length > 0){
            return row
          }
        })
    } else {
        return activePerformanceTable
    }
  }

  // FILTRO --------------------------------------------------------------------
  // ORDENAÇÃO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function orderedTable(filteredTable) {
    console.log(filteredTable)
    if(orderingColumnName != ""){
      if(!isNaN(parseFloat(filteredTable[0][orderingColumnName]))){
        if(orderDescending == false){
          filteredTable.sort((a,b) => (parseFloat(a[orderingColumnName]) > parseFloat(b[orderingColumnName])) ? 1 : ((parseFloat(b[orderingColumnName]) > parseFloat(a[orderingColumnName])) ? -1 : 0))
        }
        else{
          filteredTable.sort((a,b) => (parseFloat(b[orderingColumnName]) > parseFloat(a[orderingColumnName])) ? 1 : ((parseFloat(a[orderingColumnName]) > parseFloat(b[orderingColumnName])) ? -1 : 0))
        }
      }
      else{
        if(orderDescending == false){
          filteredTable.sort((a,b) => (a[orderingColumnName] > b[orderingColumnName]) ? 1 : ((b[orderingColumnName] > a[orderingColumnName]) ? -1 : 0))
        }
        else{
          filteredTable.sort((a,b) => (b[orderingColumnName] > a[orderingColumnName]) ? 1 : ((a[orderingColumnName] > b[orderingColumnName]) ? -1 : 0))
        }
      }
    }
    return filteredTable
  }
  // ORDENAÇÃO --------------------------------------------------------------------
  return(
    <div key='performanceTabsTable' className='performanceTabsTable'>
      {loadingTable ? 
        (
          <div key='performanceTabsTableLoader' id='performanceTabsTableLoader'>
            <ArrowLoader />
          </div>
        )
        :
        <>
          {/* ABAS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
          <div key='performanceTabsMenuHeader' id='performanceTabsMenuHeader'>
            <div key='performanceTabs' className='performanceTabs' >
              <> 
                <label key='tabsLabel_filiais'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Filiais"
                  className={activePerformanceTab == "Filiais" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Filiais")
                      getPerformanceTabs_filiais()
                    }
                  }>
                  <a>{"Filiais"}</a>
                </label>

                <label key='tabsLabel_times'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Times"
                  className={activePerformanceTab == "Times" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Times")
                      getPerformanceTabs_times()
                    }
                  }>
                  <a>{"Times"}</a>
                </label>

                <label key='tabsLabel_Meu_time'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Meu time"
                  className={activePerformanceTab == "Meu time" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Meu time")
                      getPerformanceTabs_time()
                    }
                  }>
                  <a>{"Meu time"}</a>
                </label>

                <label key='tabsLabel_detalhe_net'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Sua performance"
                  className={activePerformanceTab == "Sua performance" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Sua performance")
                      getPerformanceTabs_detalhe_net()
                    }
                  }>
                  <a>{"NET"}</a>
                </label>

                <label key='tabsLabel_captacao'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Captação líquida"
                  className={activePerformanceTab == "Captação líquida" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Captação líquida")
                      getPerformanceTabs_captacao_liquida()
                    }
                  }>
                  <a>{"Capt. Líq."}</a>
                </label>

                <label key='tabsLabel_roa'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="ROA bruto"
                  className={activePerformanceTab == "ROA bruto" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("ROA bruto")
                      getPerformanceTabs_roa_bruto()
                    }
                  }>
                  <a>{"ROA bruto"}</a>
                </label>

                <label key='tabsLabel_nps'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="NPS"
                  className={activePerformanceTab == "NPS" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("NPS")
                      getPerformanceTabs_nps()
                    }
                  }>
                  <a>{"NPS"}</a>
                </label>

                <label key='tabsLabel_ativacao_contas'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Ativação de contas"
                  className={activePerformanceTab == "Ativação de contas" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Ativação de contas")
                      getPerformanceTabs_ativacao_contas()
                    }
                  }>
                  <a>{"Ativ. de contas"}</a>
                </label>

                <label key='tabsLabel_captacao_liquida_detalhe'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="Captação líquida detalhada"
                  className={activePerformanceTab == "Captação líquida detalhada" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("Captação líquida detalhada")
                      getPerformanceTabs_captacao_liquida_detalhe()
                    }
                  }>
                  <a>{"Capt. Líq. Det."}</a>
                </label>

                <label key='tabsLabel_roa_bruto_detalhe'
                  style={{ cursor: 'pointer' }}
                  title='Clique para vizualizar essa tabela'
                  id="ROA bruto detalhado"
                  className={activePerformanceTab == "ROA bruto detalhado" ? 'performanceTabsLabelActive' : 'performanceTabsLabel'}
                  onClick={
                    () => {
                      setActivePerformanceTab("ROA bruto detalhado")
                      getPerformanceTabs_roa_bruto_detalhe()
                    }
                  }>
                  <a>{"ROA Brut. Det."}</a>
                </label>
              </>  
            </div>
          </div>
          {/* ABAS ----------------------------------------------------------- */}
          <div className='performanceTableContainer'>
            <div className='performanceTableContent'>
                <table>
                    <thead id='performanceTableTbody'>
                        <tr id='performanceTableBody'>
                            <th colSpan={placeHolderPerformanceTable}>
                                <div className='theadFirst'>
                                    <a>Relatórios de performance: {activePerformanceTab}</a>
                                    <div className='substitutoLabelDisplay'>
                                    <button
                                            className='buttonTableThead'
                                            onClick={() => {
                                              if (activePerformanceTable.length > 0){

                                                const output = [];
                                                const titles = Object.keys(activePerformanceTable[0])
                                                output.push(titles.join('\t'))
                                                activePerformanceTable.forEach((x) => {
                                                  const tmp = titles.map((y) => {
                                                    return y==='NPS'? 
                                                    parseFloat(x[y]).toFixed(2).replaceAll(".",",") 
                                                    : y === "% Atingimento" || y === "ROA (%)" || y === "Planejado (%)" || y === "ROA Médio Acumulado (%)" ? 
                                                    parseFloat(x[y]).toFixed(2)+"%"
                                                    : y==='NET (R$)' || y==='Captação Líquida (R$)' ? String(x[y]).replace('.',',')
                                                    : x[y]
                                                    
                                                    
                                                  });
                                                  output.push(tmp.join('\t'))
                                                })
                                                const table = output.join('\n')
                                                navigator.clipboard.writeText(table)
                                                alert('Conteúdo da tabela copiado para a área de transferência')
                                              }
                                              
                                            else {
                                              alert('Tabela vazia')
                                            }
                                          }
                                        }
                                        ><FiCopy />Copiar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            performanceExport();
                                    }}><FiArrowDown />Exportar</button>
                                      <input placeholder='Filtrar' onChange={(e) => setFilter(e.target.value)} type="text" />
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                          {activePerformanceTable.length > 0 ?
                            <>
                              {
                                Object.keys(activePerformanceTable[0]).map((column) =>  {
                                  if(column.replaceAll(" ","").replaceAll("&gt;",">").replaceAll("&lt;","<") == orderingColumnName.replaceAll(" ","").replaceAll("&gt;",">").replaceAll("&lt;","<")){
                                    if(orderDescending == 0){
                                      return <th onClick={(e) => {
                                        setOrderDescending(!orderDescending)
                                      }} style={{color: "#c49e17", cursor: "pointer"}}>{column} <HiSortDescending size={14}/></th>;
                                    }
                                    else{
                                      return <th onClick={(e) => {
                                        setOrderDescending(!orderDescending)
                                      }} style={{color: "#c49e17", cursor: "pointer"}}>{column} <HiSortAscending size={14}/></th>;
                                    }
                                  }
                                  else{
                                    return <th onClick={(e) => {
                                      setOrderDescending(false)
                                      if(e.target.nodeName == "svg"){
                                        setOrderingColumnName(e.target.parentNode.innerHTML.split("<")[0].replaceAll("&gt;",">").replaceAll("&lt;","<"))
                                      }
                                      else if(e.target.nodeName == "path"){
                                        setOrderingColumnName(e.target.parentNode.parentNode.innerHTML.split("<")[0].replaceAll("&gt;",">").replaceAll("&lt;","<"))
                                      }
                                      else{
                                        setOrderingColumnName(e.target.innerHTML.split("<")[0].replaceAll("&gt;",">").replaceAll("&lt;","<"))
                                      }
                                    }} style={{cursor: "pointer"}}>{column}</th>;
                                  }
                                })
                              }
                            </> 
                            : 
                            <th>Sem informações</th>
                          }
                        </tr>
                    </thead>
                    <tbody>
                      {loading ?
                        <tr>
                          <td id='reportLoadingTd' colSpan={placeHolderPerformanceTable}><ArrowLoader /></td>
                        </tr>
                        :
                        orderedTable(filteredTable())?.map(row => {
                          return (
                            <tr>
                              {
                              Object.keys(row).map(key => {
                                if(
                                  key === "NET (R$)" ||
                                  key === "Captação Líquida (R$)" ||
                                  key === "Planejado (R$)" ||
                                  key === "Entrada (R$)" ||
                                  key === "Resgate (R$)" ||
                                  key === "Líquida (R$)"
                                  ){
                                  return <td title={toBRL(parseFloat(row[key].replaceAll(".","").replaceAll(",",".")))}>{toBRL(parseFloat(row[key].replaceAll(".","").replaceAll(",",".")))}</td>  
                                }
                                else if(
                                  key === "NPS" 
                                ){
                                  return <td title={parseFloat(row[key]).toFixed(2).replaceAll(".",",")}>{parseFloat(row[key]).toFixed(2).replaceAll(".",",")}</td>
                                }
                                else if(
                                  key === "% Atingimento" ||
                                  key === "ROA (%)" ||
                                  key === "Planejado (%)" ||
                                  key === "ROA Médio Acumulado (%)"
                                ){
                                  return <td title={parseFloat(row[key]).toFixed(2)+"%"}>{parseFloat(row[key]).toFixed(2)+"%"}</td>
                                }
                                else{
                                  return <td title={row[key]}>{row[key]}</td>
                                }
                                
                              })
                              }
                            </tr>
                          )
                          
                        })
                      }
                    </tbody>
                </table>
            </div>
        </div>
        </>
      }
    </div>
  );
}