import React, { useEffect, useState, useRef, useReducer } from "react"
import MUIDataTable from "mui-datatables"
import { Tooltip, IconButton, Checkbox } from "@material-ui/core"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThemeProvider, createTheme } from "@mui/material/styles"
/*Styles*/
// import "../../myReservations/styles/myReservation.table.css";
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
import { FiPlus, FiMinus } from "react-icons/fi"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { getLongShortIndex } from "../../../../services/asyncFunctions"
import { getLongShortsHistory } from "../../../../services/asyncFunctions"
import { api } from "../../../../services/api"
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import TableViewCol from "./TableViewCol"
import { AiFillDollarCircle, AiFillFileExcel, AiFillMoneyCollect, AiFillWarning, AiOutlineWarning } from "react-icons/ai"
import { AiFillMessage } from "react-icons/ai"
import { TableCell } from "@material-ui/core"
import "../styles/tables.css"

export function LongShortTbl() {
    const [loading, setLoading] = useState(false)
    const [listLS, setlistLS] = useState([])
    const [listLSFormatted, setlistLSFormatted] = useState([])
    const { user, tokenKey } = useAuth()
    const { type, setType, show, setShow, edited, setEdited } = useModal()
    const selectedLinesOnTable = useRef([])
    const columns = useRef()
    const variavel = useRef({ grupo1: true, grupo2: true, grupo3: true, grupo4: true, grupo5: true })
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const [breakLineTitle, setBreaklineTitle] = useState("")
    const [visaoDetalhada, setVisaoDetalhada] = useState(false)
    const [viewSelectNewStatus, setViewSelectNewStatus] = useState(false)

    useEffect(() => {
        setBreaklineTitle(visaoDetalhada ? "nowrap" : "")
    }, [visaoDetalhada])

    async function getLongShortList() {
        const listLS = await getLongShortIndex(tokenKey, false)
        if (getLongShortIndex?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getLongShortIndex?.error,
                },
            })
            setShow(true)
            setlistLS([])
        } else {
            setlistLS(listLS)
            setLoading(false)
        }
    }

    const historyClientLongShort = async (id ,dataInicio, dataFim) => {
            setLoading(true);
            const listLS = await getLongShortsHistory(tokenKey, id.external_id, dataInicio, dataFim, false);
            if (getLongShortsHistory?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: getLongShortsHistory?.error,
                    },
                });
                setShow(true);
                setlistLS([]);
            } else {
                setlistLS(listLS.table);

            let listaClientes = selectedLinesOnTable.current
            let contOp = 0
            var wppText = `Segue o resumo de todas as operações Long Short encerradas:
`

            let dataEncerramentoWpp = ""
            let ativoLongWpp = ""
            let ativoShortWpp = ""
            let resultadoBrutoWpp = ""

            listLS.resume.forEach((x) => {
                contOp += 1
                dataEncerramentoWpp = x.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                ativoLongWpp = x.longshort
                if (x.ratio > 0) {
                    resultadoBrutoWpp = `+${x.ratio.toFixed(2)}%`
                } else {
                    resultadoBrutoWpp = `${x.ratio.toFixed(2)}%`
                }
                var linhaWpp = `\n${ativoLongWpp} : ${resultadoBrutoWpp} (${dataEncerramentoWpp})`
                wppText = wppText + linhaWpp
            })
            setLoading(false)
            if (contOp > 0) {
                navigator.clipboard.writeText(wppText)
                alert("Mensagem copiada para a área de transferência.")
            } else {
                alert("Não há operações encerradas para gerar o histórico.")
            }
        }
    }

    useEffect(() => {
        getLongShortList()
    }, [edited])

    function formateAssets() {
        let lsFormatted = []

        listLS.map((ls) => {
            lsFormatted.push({
                id: ls.id,
                long_short_id: ls.long_short_id,
                created_at: ls.created_at,
                description: ls.description,
                clients_external_id: ls.clients_external_id,
                name: ls.name,
                advisor_id: ls.advisor_id,
                advisor_name: ls.advisor_name,
                broker_id: ls.broker_id,
                broker_name: ls.broker_name,
                leader_name: ls.leader_name,
                research: ls.research,
                margin: ls.margin,
                b3guarantee: ls.b3guarantee,
                vol_limit: ls.vol_limit,
                asset_long: ls.asset_long,
                qtd_long: ls.qtd_long,
                value_long: ls.value_long,
                long_vol: ls.long_vol,
                asset_short: ls.asset_short,
                qtd_short: ls.qtd_short,
                value_short: ls.value_short,
                short_vol: ls.short_vol,
                operation_vol: ls.operation_vol,
                ratio_in: ls.ratio_in,
                updated_ratio: ls.updated_ratio,
                ratio_gain: ls.ratio_gain,
                ratio_loss: ls.ratio_loss,
                current_long: ls.current_long,
                current_short: ls.current_short,
                current_ratio: ls.current_ratio,
                var_ratio: ls.var_ratio,
                cost_corr_in: ls.cost_corr_in,
                cost_btc_in: ls.cost_btc_in,
                operation_costs_total_in: ls.operation_costs_total_in,
                cost_corr_out: ls.cost_corr_out,
                cost_btc_out: ls.cost_btc_out,
                operation_costs_out: ls.operation_costs_out,
                operation_stop: ls.operation_stop,
                liq_result_total: ls.liq_result_total,
                observation: ls.observation,
                allow_over_longshort: ls.allow_over_longshort,
                updated_at: ls.updated_at,
            })
        })
        setlistLSFormatted(lsFormatted)
        setLoading(false)
    }

    useEffect(() => {
        if (edited || listLS.length === 0) {
            setEdited(false)
            setLoading(true)
            getLongShortList()
        }
    }, [edited])

    useEffect(() => {
        if (listLS.length > 0) {
            formateAssets()
        }
    }, [listLS])

    function copyToClipboard() {
        const output = []
        const titles = [
            "VALIDAÇÃO BOLETA",
            "STATUS OPERAÇÃO",
            "DATA ENTRADA",
            "CÓDIGO CLIENTE",
            "NOME CLIENTE",
            "NOME ASSESSOR",
            "NOME BROKER",
            "TIME BROKER",
            "CASA DE ANÁLISE",
            "MARGEM EXIGIDA",
            "GARANTIAS DISPONÍVEIS",
            "LIMITE EXPOSIÇÃO",
            "ATIVO LONG",
            "QTD LONG",
            "PREÇO LONG",
            "VOLUME LONG",
            "ATIVO SHORT",
            "QTD SHORT",
            "PREÇO SHORT",
            "VOLUME SHORT",
            "CAIXA MONTAGEM",
            "RATIO ENTRADA",
            "RATIO GAIN",
            "RATIO LOSS",
            "PREÇO ATUAL LONG",
            "PREÇO ATUAL SHORT",
            "RATIO ATUAL",
            "VARIAÇÃO RATIO",
            "CUSTOS DE ENTRADA",
            "CUSTOS DE SAÍDA",
            "RESULTADO LÍQUIDO",
            "STATUS RESULTADO",
            "OBS",
        ]
        output.push(titles.join("\t"))
        const keys = [
            "allow_over_longshort",
            "description",
            "created_at",
            "clients_external_id",
            "name",
            "advisor_name",
            "broker_name",
            "leader_name",
            "research",
            "margin",
            "b3guarantee",
            "vol_limit",
            "asset_long",
            "qtd_long",
            "value_long",
            "long_vol",
            "asset_short",
            "qtd_short",
            "value_short",
            "short_vol",
            "operation_vol",
            "ratio_in",
            "ratio_gain",
            "ratio_loss",
            "current_long",
            "current_short",
            "current_ratio",
            "var_ratio",
            "operation_costs_total_in",
            "operation_costs_out",
            "liq_result_total",
            "operation_stop",
            "observation",
        ]
        listLS.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "margin" ||
                    y === "b3guarantee" ||
                    y === "vol_limit" ||
                    y === "value_long" ||
                    y === "value_short" ||
                    y === "long_vol" ||
                    y === "short_vol" ||
                    y === "operation_vol" ||
                    y === "operation_costs_total_in" ||
                    y === "operation_costs_out"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : x[y]
            })
            output.push(tmp.join("\t"))
        })
        const table = output.join("\n")
        navigator.clipboard.writeText(table)
        alert("Conteúdo da tabela copiado para a área de transferência")
    }

    async function lsExport(open, year) {
        setLoading(true)
        try {
            const myExport = await getLongShortIndex(tokenKey, true)
            const responseURL = URL.createObjectURL(new Blob([myExport]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "longShortAcompanhamento.xlsx")
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            })
            setShow(true)
            setLoading(false)
        }
    }

    function changeVisibleColumns(grupo) {
        columns.current.forEach((c) => {
            if (c.name == "advisor_name") {
                variavel.current[grupo] = !variavel.current[grupo]
            }
        })
        forceUpdate()
    }

    if (visaoDetalhada) {
        columns.current = [
            {
                name: "allow_over_longshort",
                label: "Validação Boleta",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    // customBodyRender: (value) => <span>OK</span>
                    customBodyRender: (val, table) => {
                        // console.log(listLS[table.rowIndex])
                        const { b3guarantee, margin, long_vol, vol_limit, clients_external_id } = listLS[table.rowIndex]
                        const total = listLS
                            .filter((l) => l.clients_external_id == clients_external_id && ["Em Andamento", "Basket Gerada"].includes(l.description))
                            .reduce((prev, curr) => prev + curr.long_vol, 0)
                        // console.log(vol_limit < long_vol, (b3guarantee - total)< margin)
                        if (vol_limit < long_vol || b3guarantee - total < margin) {
                            // PROBLEMA
                            if (val == true) {
                                // PODE TER PROBLEMA - VALIDADO!
                                return (
                                    <div>
                                        <div
                                            title="Validação Boleta"
                                            style={{ placeContent: "center" }}
                                            onClick={(e) => {
                                                const order = listLS[table.rowIndex].id
                                                setType({
                                                    name: "showValidationModal",
                                                    client_id: clients_external_id,
                                                    order: order,
                                                    limite: vol_limit < long_vol,
                                                    margem: b3guarantee - total < margin,
                                                    read: true,
                                                })
                                                setShow(true)
                                                e.stopPropagation()
                                            }}
                                        >
                                            <div className="btnValidationValidated"> Validado </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                // NÃO PODE TER PROBLEMA - VALIDE!
                                return (
                                    <div>
                                        <div
                                            title="Validação Boleta"
                                            style={{ placeContent: "center" }}
                                            onClick={(e) => {
                                                const order = listLS[table.rowIndex].id
                                                setType({
                                                    name: "showValidationModal",
                                                    client_id: clients_external_id,
                                                    order: order,
                                                    limite: vol_limit < long_vol,
                                                    margem: b3guarantee - total < margin,
                                                    read: false,
                                                })
                                                setShow(true)
                                                e.stopPropagation()
                                            }}
                                        >
                                            <div className="btnValidationValidate"> Validar </div>
                                        </div>
                                    </div>
                                )
                            }
                        } else {
                            // SEM PROBLEMA - OK!
                            return (
                                <div>
                                    <div title="Validação Boleta" style={{ placeContent: "center" }}>
                                        <div className="btnValidationOk"> OK </div>
                                    </div>
                                </div>
                            )
                        }
                        // return (
                        //     <div className="btnsBoxAbc">
                        //         <div title="Atualizar" className="mgnBtnsAbc"
                        //             onClick={() => {
                        //                 setType({ name: "update", product: val });
                        //                 setShow(true);
                        //             }}>
                        //             <div className="btnMngAbc">{vol_limit < long_vol || (b3guarantee - total) < margin ? val == true ? `Validado` : `Validar` : `OK`}</div>
                        //         </div>
                        //     </div>
                        // );
                    },
                },
                // Validação boleta: Condicional para aparecer botão em caso de retornar setado (1).
            },
            {
                name: "description",
                label: "Status Operação",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                },
            },
            {
                name: "created_at",
                label: "Data Entrada",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",

                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    customBodyRender: (value) => <span>{datetimeToDatePtBR(value)}</span>,
                },
            },
            {
                name: "clients_external_id",
                label: "Código Cliente",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",

                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                },
            },
            {
                name: "name",
                label: "NOME CLIENTE",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="columnExpandableLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label}
                            </span>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            <span
                                key={index}
                                className="expandleLsIcon"
                                title={variavel.current.grupo1 == true ? "Retrair Colunas" : "Expandir Colunas"}
                                onClick={() => changeVisibleColumns("grupo1")}
                            >
                                {variavel.current.grupo1 == true ? <FiMinus size={24} color="red" /> : <FiPlus size={24} color="green" />}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "advisor_name",
                label: "ASSESSOR",
                options: {
                    filter: true,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsLeft">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "broker_name",
                label: "BROKER",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "leader_name",
                label: "Time Broker",
                options: {
                    filter: true,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "research",
                label: "Casa de Análise",
                options: {
                    filter: true,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "margin",
                label: "Margem Exigida",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value, tableMeta, index) => {
                        // console.log(tableMeta.rowData);
                        if (tableMeta.rowData[20] < tableMeta.rowData[21]) {
                            return (
                                <div
                                    style={{ color: "red", backgroundColor: "#CCCC00", fontWeight: "bold", verticalAlign: "center", paddingBottom: "0.3rem" }}
                                    title={"Garantias insuficientes para a margem mínima da operação!"}
                                >
                                    {value ? toBRL(value) : toBRL(0)} <AiOutlineWarning size="16"></AiOutlineWarning>
                                </div>
                            )
                        } else {
                            return <span>{toBRL(value)}</span>
                        }
                    },
                },
                // Margem: Se for menor que a Garantia Exigida, pinta a célula de amarelo e deixa o valor em vermelho.
            },
            {
                name: "b3guarantee",
                label: "Garantias Disponíveis",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo1,

                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "vol_limit",
                label: "Limite Exposição",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo1,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsRight">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "asset_long",
                label: "Ativo Long",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="columnExpandableLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            <span
                                key={index}
                                className="expandleLsIcon"
                                title={variavel.current.grupo2 == true ? "Retrair Colunas" : "Expandir Colunas"}
                                onClick={() => changeVisibleColumns("grupo2")}
                            >
                                {variavel.current.grupo2 == true ? <FiMinus size={24} color="red" /> : <FiPlus size={24} color="green" />}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "qtd_long",
                label: "Qtd Long",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo2,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsLeft">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "value_long",
                label: "Preço Long",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo2,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "long_vol",
                label: "Volume Long",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo2,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsRight">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value, tableMeta, index) => {
                        if (tableMeta.rowData[11] < tableMeta.rowData[15]) {
                            return (
                                <div
                                    style={{ color: "red", backgroundColor: "#CCCC00", fontWeight: "bold", verticalAlign: "center", paddingBottom: "0.3rem" }}
                                    title={"Volume da operação acima do limite de exposição do cliente!"}
                                >
                                    {value ? toBRL(value) : toBRL(0)} <AiOutlineWarning size="16"></AiOutlineWarning>
                                </div>
                            )
                        } else {
                            return <span>{toBRL(value)}</span>
                        }
                    },
                },
                // Volume Long: Se for maior que o limite de exposição, pinta a célula de amarelo e deixa o valor em vermelho.
            },
            {
                name: "asset_short",
                label: "Ativo Short",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="columnExpandableLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            <span
                                key={index}
                                className="expandleLsIcon"
                                title={variavel.current.grupo3 == true ? "Retrair Colunas" : "Expandir Colunas"}
                                onClick={() => changeVisibleColumns("grupo3")}
                            >
                                {variavel.current.grupo3 == true ? <FiMinus size={24} color="red" /> : <FiPlus size={24} color="green" />}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "qtd_short",
                label: "Qtd Short",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo3,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsLeft">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "value_short",
                label: "Preço Short",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo3,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "short_vol",
                label: "Volume Short",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo3,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsRight">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "operation_vol",
                label: "Caixa Montagem",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="columnExpandableLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            <span
                                key={index}
                                className="expandleLsIcon"
                                title={variavel.current.grupo4 == true ? "Retrair Colunas" : "Expandir Colunas"}
                                onClick={() => changeVisibleColumns("grupo4")}
                            >
                                {variavel.current.grupo4 == true ? <FiMinus size={24} color="red" /> : <FiPlus size={24} color="green" />}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "ratio_in",
                label: "Ratio Entrada",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo4,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsLeft">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "ratio_gain",
                label: "Ratio Gain",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo4,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "ratio_loss",
                label: "Ratio Loss",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo4,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                },
            },
            {
                name: "current_long",
                label: "Preço Atual Long",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo4,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "current_short",
                label: "Preço Atual Short",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo4,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsRight">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "current_ratio",
                label: "Ratio Atual",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    // customBodyRender: (value) => <span>{toBRL(value)}</span>
                },
            },
            {
                name: "var_ratio",
                label: "Variação Ratio",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    customBodyRender: (value) => <span>{toPercent(value * 100)}</span>,
                },
            },
            {
                name: "operation_costs_total_in",
                label: "Custos Montagem",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="columnExpandableLs">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            <span
                                key={index}
                                className="expandleLsIcon"
                                title={variavel.current.grupo5 == true ? "Retrair Colunas" : "Expandir Colunas"}
                                onClick={() => changeVisibleColumns("grupo5")}
                            >
                                {variavel.current.grupo5 == true ? <FiMinus size={24} color="red" /> : <FiPlus size={24} color="green" />}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value, tableMeta) => {
                        const operation = listLS.find((l) => l.id == tableMeta.rowData[33])
                        return (
                            <button
                                className="buttonEditCostLs"
                                onClick={() => {
                                    setType({ name: "showLModalEditCosts", data: operation, tipo: "in" })
                                    setShow(true)
                                }}
                            >
                                {value ? toBRL(value) : toBRL(0)} <AiFillDollarCircle size={"18"} color={"#2D7200"} />
                            </button>
                        )
                    },
                },
            },
            {
                name: "operation_costs_out",
                label: "Custos Desmontagem",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo5,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsLeft">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value, tableMeta) => {
                        const operation = listLS.find((l) => l.id == tableMeta.rowData[33])
                        return (
                            <button
                                className="buttonEditCostLs"
                                onClick={() => {
                                    setType({ name: "showLModalEditCosts", data: operation, tipo: "out" })
                                    setShow(true)
                                }}
                            >
                                {value ? toBRL(value) : toBRL(0)} <AiFillDollarCircle size={"18"} color={"#2D7200"} />
                            </button>
                        )
                    },
                },
            },
            {
                name: "liq_result_total",
                label: "Resultado Líquido",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo5,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                        <TableCell key={index} className="expandedColumnLsRight">
                            <span onClick={() => sortColumn(index)} style={{ textAlign: "center", fontWeight: "450", fontSize: "0.8rem", paddingTop: "0.5rem" }}>
                                {label.toUpperCase()}
                            </span>
                        </TableCell>
                    ),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "operation_stop",
                label: "Status Resultado",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    customBodyRender: (value, tableMeta, index) => {
                        if (tableMeta.rowData[31] == null) {
                            return <span>Em Andamento</span>
                        }
                        if (tableMeta.rowData[31] == true) {
                            return <div style={{ backgroundColor: "green", fontWeight: "bold", verticalAlign: "center" }}>STOP GAIN</div>
                        }
                        if (tableMeta.rowData[31] == false) {
                            return <div style={{ backgroundColor: "#CC0000", fontWeight: "bold", verticalAlign: "center" }}>STOP LOSS</div>
                        }
                    },
                    // ),
                },
                // Status Resultado: Condicional para STOP LOSS (vermelho) e STOP GAIN (verde), quando não for nulo no banco.
            },
            {
                name: "observation",
                label: "OBS",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    customBodyRender: (val, tableMeta) => {
                        // console.log(tableMeta.rowData, val);
                        if (tableMeta.rowData[32] !== null) {
                            return (
                                <div>
                                    <div
                                        title="E-mail de resposta do cliente"
                                        style={{ placeContent: "center" }}
                                        onClick={() => {
                                            const order = tableMeta.rowData[32] != null ? tableMeta.rowData[32] : ""
                                            setType({ name: "showLongShortResponse", data: { order } })
                                            setShow(true)
                                        }}
                                    >
                                        <div className="btnMngAbc" style={{ backgroundColor: "#c49e17" }}>
                                            <AiFillMessage />
                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <div
                                        title="E-mail de resposta do cliente"
                                        style={{ placeContent: "center" }}
                                        onClick={() => {
                                            const order = tableMeta.rowData[32] != null ? tableMeta.rowData[32] : ""
                                            setType({ name: "showLongShortResponse", data: { order } })
                                            setShow(true)
                                        }}
                                    >
                                        <div className="btnMngAbc">
                                            <AiFillMessage />
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                },
                // Observação: Colocar o modal de observações editável do broker, mesmo formato de ofertas públicas (botão).
            },
            {
                name: "id",
                label: "Selecionar Cliente",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    customBodyRender: (value) => (
                        // <input type="checkbox" size={'small'}></input>
                        <Checkbox
                            color={"#cc00cc"}
                            onClick={() => {
                                changeselectedLinesOnTable(value)
                            }}
                        ></Checkbox>
                    ),
                },
                // Zeragem: Checkbox para selecionar clientes a serem zerados.
            },
            {
                name: "obs",
                label: "Gerar Texto",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return (
                            <div>
                                <div
                                    title="Texto da Operação WhatsApp"
                                    style={{ placeContent: "center" }}
                                    onClick={() => {
                                        let atualizacaoWpp = ""
                                        let dataEncerramentoWpp = ""
                                        let ativoLongWpp = tableMeta.rowData[12]
                                        let ativoShortWpp = tableMeta.rowData[16]
                                        let volumeWpp = toBRL(tableMeta.rowData[19])
                                        let resultadoBrutoWpp = tableMeta.rowData[27] * 100

                                        listLSFormatted.forEach((x) => {
                                            if (x.id === tableMeta.rowData[33]) {
                                                dataEncerramentoWpp = x.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                                                ativoLongWpp = tableMeta.rowData[12]
                                                ativoShortWpp = tableMeta.rowData[16]
                                                volumeWpp = toBRL(tableMeta.rowData[19])
                                                resultadoBrutoWpp = (tableMeta.rowData[27] * 100).toFixed(2)

                                                if (x.description === "Encerrado") {
                                                    atualizacaoWpp = "Encerrada"
                                                } else {
                                                    atualizacaoWpp = "em andamento"
                                                }
                                            }
                                        })

                                        var wppText = `Segue o resumo da operação Long Short ${atualizacaoWpp} no dia ${dataEncerramentoWpp}:

    Ativo Long: ${ativoLongWpp}
    Ativo Short: ${ativoShortWpp}
    Volume: ${toBRL(volumeWpp)}
    Resultado bruto: ${resultadoBrutoWpp}%`
                                        navigator.clipboard.writeText(wppText)
                                        alert("Mensagem copiada para a área de transferência.")
                                    }}
                                >
                                    <div className="btnMngAbc">
                                        <AiFillMessage />
                                    </div>
                                </div>
                            </div>
                        )
                    },
                },
                // Texto: Baseado no Status Resultado, configura texto padrão de acordo com a tabela [dbo].[long_short_speech]
            },
        ]
    } else {
        columns.current = [
            {
                name: "id",
                label: "Selecionar Cliente",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    customBodyRender: (value) => (
                        <Checkbox
                            color={"#cc00cc"}
                            onClick={(e) => {
                                changeselectedLinesOnTable(value)

                                e.stopPropagation()
                            }}
                        ></Checkbox>
                    ),
                },
            },
            {
                name: "allow_over_longshort",
                label: "Validação Boleta",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (val, table) => {
                        const { b3guarantee, margin, long_vol, vol_limit, clients_external_id } = listLS[table.rowIndex]
                        const total = listLS
                            .filter((l) => l.clients_external_id == clients_external_id && ["Em Andamento", "Basket Gerada"].includes(l.description))
                            .reduce((prev, curr) => prev + curr.long_vol, 0)
                        if (vol_limit < long_vol || b3guarantee - total < margin) {
                            if (val == true) {
                                return (
                                    <div>
                                        <div
                                            title="Validação Boleta"
                                            style={{ placeContent: "center" }}
                                            onClick={(e) => {
                                                const order = listLS[table.rowIndex].id
                                                setType({
                                                    name: "showValidationModal",
                                                    client_id: clients_external_id,
                                                    order: order,
                                                    limite: vol_limit < long_vol,
                                                    margem: b3guarantee - total < margin,
                                                    read: true,
                                                })
                                                setShow(true)
                                                e.stopPropagation()
                                            }}
                                        >
                                            <div className="btnValidationValidated"> Validado </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <div
                                            title="Validação Boleta"
                                            style={{ placeContent: "center" }}
                                            onClick={(e) => {
                                                const order = listLS[table.rowIndex].id
                                                setType({
                                                    name: "showValidationModal",
                                                    client_id: clients_external_id,
                                                    order: order,
                                                    limite: vol_limit < long_vol,
                                                    margem: b3guarantee - total < margin,
                                                    read: false,
                                                })
                                                setShow(true)
                                                e.stopPropagation()
                                            }}
                                        >
                                            <div className="btnValidationValidate"> Validar </div>
                                        </div>
                                    </div>
                                )
                            }
                        } else {
                            return (
                                <div>
                                    <div title="Validação Boleta" style={{ placeContent: "center" }}>
                                        <div className="btnValidationOk"> OK </div>
                                    </div>
                                </div>
                            )
                        }
                    },
                },
            },
            {
                name: "description",
                label: "Status Operação",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "operation_stop",
                label: "Status Resultado",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (value, tableMeta, index) => {
                        if (tableMeta.rowData[3] == null) {
                            return <span>Em Andamento</span>
                        }
                        if (tableMeta.rowData[3] == true) {
                            return <div style={{ backgroundColor: "green", fontWeight: "bold", verticalAlign: "center" }}>STOP GAIN</div>
                        }
                        if (tableMeta.rowData[3] == false) {
                            return <div style={{ backgroundColor: "#CC0000", fontWeight: "bold", verticalAlign: "center" }}>STOP LOSS</div>
                        }
                    },
                    // ),
                },
            },
            {
                name: "obs",
                label: "Gerar Texto",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (val, tableMeta) => {
                        return (
                            <div>
                                <div
                                    title="Texto da Operação WhatsApp"
                                    style={{ placeContent: "center" }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        let atualizacaoWpp = ""
                                        let dataEncerramentoWpp = ""
                                        let ativoLongWpp = tableMeta.rowData[8]
                                        let ativoShortWpp = tableMeta.rowData[9]
                                        let volumeWpp = toBRL(tableMeta.rowData[10])
                                        let resultadoBrutoWpp = tableMeta.rowData[11] * 100

                                        listLSFormatted.forEach((x) => {
                                            if (x.id === tableMeta.rowData[0]) {
                                                dataEncerramentoWpp = x.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                                                ativoLongWpp = tableMeta.rowData[8]
                                                ativoShortWpp = tableMeta.rowData[9]
                                                volumeWpp = toBRL(tableMeta.rowData[10])
                                                resultadoBrutoWpp = (tableMeta.rowData[11] * 100).toFixed(2)

                                                if (x.description === "Encerrado") {
                                                    atualizacaoWpp = "Encerrada"
                                                } else {
                                                    atualizacaoWpp = "em andamento"
                                                }
                                            }
                                        })
                                        var wppText = `Segue o resumo da operação Long Short ${atualizacaoWpp} no dia ${dataEncerramentoWpp}:

    Ativo Long: ${ativoLongWpp}
    Ativo Short: ${ativoShortWpp}
    Volume: ${toBRL(volumeWpp)}
    Resultado bruto: ${resultadoBrutoWpp}%`
                                        navigator.clipboard.writeText(wppText)
                                        alert("Mensagem copiada para a área de transferência.")
                                    }}
                                >
                                    <div className="btnMngAbc">
                                        <AiFillMessage />
                                    </div>
                                </div>
                            </div>
                        )
                    },
                },
            },
            {
                name: "created_at",
                label: "Data Operação",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",

                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                    customBodyRender: (value) => <span>{datetimeToDatePtBR(value)}</span>,
                },
            },
            {
                name: "clients_external_id",
                label: "Código Cliente",
                options: {
                    filter: true,
                    sort: true,
                    display: "true",

                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                    //         {label}
                    //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                    //     </TableCell>
                    // ),
                },
            },
            {
                name: "name",
                label: "NOME CLIENTE",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "asset_long",
                label: "Ativo Long",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "asset_short",
                label: "Ativo Short",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "short_vol",
                label: "NET OPERAÇÃO (Volume Short)",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo3,
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
            {
                name: "var_ratio",
                label: "Variação Ratio",
                options: {
                    filter: false,
                    sort: true,
                    display: "true",
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (value) => <span>{toPercent(value * 100)}</span>,
                },
            },
            {
                name: "liq_result_total",
                label: "Resultado Líquido",
                options: {
                    filter: false,
                    sort: true,
                    display: variavel.current.grupo5,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                    customBodyRender: (value) => <span>{value ? toBRL(value) : toBRL(0)}</span>,
                },
            },
        ]
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Histórico Geral">
                <IconButton
                    onClick={() => {
                        setType({
                            name: "showHistoricoGeralLongShort",
                            searchId: historyClientLongShort,
                        })
                        setShow(true)
                    }}
                >
                    {/* <FiPlus /> */}
                    <span style={{ fontSize: "1rem", fontWeight: "500" }}>Histórico</span>
                </IconButton>
            </Tooltip>

            <Tooltip disableFocusListener title={visaoDetalhada ? "Visão Resumida" : "Visão Detalhada"}>
                <IconButton
                    onClick={() => {
                        setVisaoDetalhada(!visaoDetalhada)
                    }}
                >
                    {/* <FiPlus /> */}
                    <span style={{ fontSize: "1rem", fontWeight: "500" }}>{visaoDetalhada ? "Versão Resumida" : "Versão Detalhada"}</span>
                </IconButton>
            </Tooltip>

            <Tooltip disableFocusListener title="Gerar Basket">
                <IconButton
                    onClick={() => {
                        gerarBasket()
                    }}
                >
                    {/* <FiPlus /> */}
                    <span style={{ fontSize: "1rem", fontWeight: "500" }}>Basket</span>
                </IconButton>
            </Tooltip>

            <Tooltip disableFocusListener title="Gerar CIO">
                <IconButton
                    onClick={() => {
                        gerarCIO()
                    }}
                >
                    {/* <FiPlus /> */}
                    <span style={{ fontSize: "1rem", fontWeight: "500" }}>CIO</span>
                </IconButton>
            </Tooltip>

            {user.type < 3 ? (
                <Tooltip disableFocusListener title="Cancelar Selecionados">
                    <IconButton
                        onClick={() => {
                            submitCancelOperations()
                        }}
                    >
                        <span style={{ fontSize: "1rem", fontWeight: "500" }}>Cancelar</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}

            {user.type < 3 ? (
                <Tooltip disableFocusListener title="Importar Modificações">
                    <IconButton
                        onClick={() => {
                            setType({
                                name: "showImportModifications",
                            })
                            setShow(true)
                        }}
                    >
                        {/* <FiPlus /> */}
                        <span style={{ fontSize: "1rem", fontWeight: "500" }}>Importar</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}

            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
        </>
    )

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        tableBodyHeight: "70vh",
        onRowClick: (data, b) => {
            if (visaoDetalhada) return
            // console.log(data, b);
            let dataInfos = []
            dataInfos.push(listLSFormatted[b.dataIndex])
            setType({ name: "showLongShortInfos", data: { dataInfos }, list: listLSFormatted })
            setShow(true)
        },
        draggableColumns: {
            enabled: false,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: true,
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações Long & Short executadas até o momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "longShortAcompanhamento.csv",
            separator: ";",
        },
        onDownload: () => {
            lsExport()
            return false
        },
        customToolbar: AddButton,
    }
    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: "center",
                            whiteSpace: breakLineTitle,
                            textAlign: "center",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: breakLineTitle,
                        },
                    },
                },
            },
        })

    function changeselectedLinesOnTable(id) {
        if (selectedLinesOnTable.current.includes(id)) {
            const aux = selectedLinesOnTable.current.filter((i) => i != id)
            selectedLinesOnTable.current = aux
        } else {
            selectedLinesOnTable.current = [...selectedLinesOnTable.current, id]
        }
    }

    async function gerarBasket() {
        if (selectedLinesOnTable.current.length == 0) {
            alert("Selecione ao menos uma operação")
            return
        }
        const orders = listLS.filter((l) => (selectedLinesOnTable.current.includes(l.id) ? true : false))

        var listOrders = []
        orders.forEach((o) => {
            // const orderShort = [o.asset_short, 'COMPRA', o.qtd_short, o.clients_external_id, parseFloat((o.value_short*1.01).toFixed(2))]

            const orderShort = [
                o.asset_short,
                "COMPRA",
                parseInt(o.qtd_short > 100 ? parseInt(o.qtd_short / 100) * 100 : o.qtd_short),
                o.clients_external_id,
                (o.value_short * 1.01).toFixed(2).replace(".", ","),
            ]
            const orderShortF = [o.asset_short + "F", "COMPRA", parseInt(o.qtd_short % 100), o.clients_external_id, (o.value_short * 1.01).toFixed(2).replace(".", ",")]
            const orderLong = [
                o.asset_long,
                "VENDA",
                parseInt(o.qtd_long > 100 ? parseInt(o.qtd_long / 100) * 100 : o.qtd_long),
                o.clients_external_id,
                (o.value_long * 0.99).toFixed(2).replace(".", ","),
            ]
            const orderLongF = [o.asset_long + "F", "VENDA", parseInt(o.qtd_long % 100), o.clients_external_id, (o.value_long * 0.99).toFixed(2).replace(".", ",")]
            if (o.qtd_short / 100 > 1 || o.current_short_class_id != 1) listOrders.push(orderShort)
            if (o.current_short_class_id == 1) listOrders.push(orderShortF)
            if (o.qtd_long / 100 > 1 || o.current_long_class_id != 1) listOrders.push(orderLong)
            if (o.current_long_class_id == 1) listOrders.push(orderLongF)
        })
        setLoading(true)
        try {
            const excelBasket = await api.put(
                `/api/v1/longShort/exportBasket`,
                {
                    data: listOrders,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                    responseType: "blob",
                }
            )
            const responseURL = URL.createObjectURL(new Blob([excelBasket.data]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "basketLongShort.xlsx")
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message },
            })
            setShow(true)
            setLoading(false)
        }
    }

    function gerarCIO() {
        // console.log(selectedLinesOnTable.length)
        if (selectedLinesOnTable.current.length == 0) {
            alert("Selecione ao menos uma operação")
            return
        }
        const orders = listLS.filter((l) => (selectedLinesOnTable.current.includes(l.id) ? true : false))
        // console.log(orders)
        setType({ name: "longShortCio", data: orders })
        setShow(true)
    }

    async function submitCancelOperations() {
        if (selectedLinesOnTable.current.length == 0) {
            alert("Selecione ao menos uma operação para cancelar")
            return
        }
        // console.log(selectedLinesOnTable.current)
        try {
            await api.put(
                `/api/v1/longShort/operationsCancel`,
                {
                    ids: selectedLinesOnTable.current,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: "Operações canceladas com sucesso" },
            })
            setShow(true)
            setEdited(true)
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao cancelar operações" : error?.response?.data?.message },
            })
            setShow(true)
            setLoading(false)
        }
    }

    const CustomToView = (props) => {
        return (
            <TableViewCol
                {...props}
                // visibleColumnsRef={visibleColumnsRef}
            />
        )
    }

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Long Short - Acompanhamento"}
                    data={listLS}
                    columns={columns.current}
                    options={options}
                    components={{
                        TableViewCol: CustomToView,
                    }}
                ></MUIDataTable>
            </ThemeProvider>
        </div>
    )
}
