/*Componente para efetuar cadastro de novos Books
/*Descrição: Efetua novos cadastros de books
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
/*Styles*/
import './styles/booksRegister.css';
import { FiPlus, FiTrash, FiArrowDown } from 'react-icons/fi';
/*Services*/
import { api } from '../../../../services/api';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Util Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

export function BooksRegister() {

    const [listTypesFields, setListTypesFields] = useState([]);
    const [newField, setNewField] = useState([1]);
    const { tokenKey } = useAuth();
    const { show, setShow, edited, setEdited, setType } = useModal();
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [loading, setLoading] = useState(false);
    /*Variaveis Selecionadas*/
    const [selectedBookName, setSelectedBookName] = useState('');
    const [selectedOperationAward, setSelectedOperationAward] = useState(false);
    const [selectedNewField, setSelectedNewField] = useState([]);
    const [selectedTypeField, setSelectedTypeField] = useState([]);
    const [selectedRequiredField, setSelectedRequiredField] = useState([]);
    const { screenX, screenY } = ModalSizeFitToScreen();
    const [getChild, setGetChild] = useState(false)


    useEffect(() => {

        setLoading(true);
        async function getTypesOfFields() {
            const fieldsResults = await api.get(`/api/v1/booksParamTypes`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setListTypesFields(fieldsResults?.data?.data)
            setLoading(false);
        }
        getTypesOfFields();
    }, [])

    useEffect(() => {
        setSelectedBookName('')
        setSelectedNewField([]);
        setSelectedTypeField([]);
        setSelectedRequiredField([]);
        setNewField([1])
        setShowPreviewTable(false)

    }, [edited, show])

    useEffect(() => {

        getLastChild()

    }, [newField])

    async function handleSubmit(dataFields) {

        if (selectedBookName === '') {
            return alert('Digite o nome do Book')
        }

        setLoading(true);
        try {
            const booksResult = await api.post(`/api/v1/books`, {
                name: selectedBookName,
                award: selectedOperationAward,
                fields: dataFields
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setEdited(true);
            setLoading(false);
            setType({ name: 'successBookCreation', type: 'information', value: { message: "Sucesso ao criar Book, baixe o layout de importação na tabela" } });
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setLoading(false);
        }
    }

    function getLastChild() {

        if (getChild) {

            const inputChild = document?.getElementById('bookRegisterTable')?.childNodes[1].childNodes.length - 2
            document?.getElementById('bookRegisterTable')?.childNodes[1]?.childNodes[inputChild]?.childNodes[1]?.childNodes[0].childNodes[0]?.focus()
            setGetChild(false)
        }
    }

    function deleteRow(thisIndex, thisField) {

        if (selectedNewField[newField.length] === undefined && thisIndex == newField?.length - 1) {

            newField.splice(newField.indexOf(newField.length), 1)
            newField?.length > 0 ? setNewField([...newField]) :
                setNewField([])
        } else {

            if (selectedNewField[newField.length] === undefined) {
                newField.splice(newField.indexOf(newField.length))
                newField?.length > 0 ? setNewField([...newField, newField[newField?.length - 1] + 1]) :
                    setNewField([1])
            }
            const totalKeys = Object.keys(selectedNewField).length
            let position = thisField

            Object.keys(selectedNewField).map((key) => {
                if (parseInt(key) > thisField) {
                    selectedTypeField[position] = selectedTypeField[key]
                    selectedNewField[position] = selectedNewField[key]
                    selectedRequiredField[position] = selectedRequiredField[key]
                    position += 1
                }
            })

            delete selectedNewField[totalKeys]
            delete selectedTypeField[totalKeys]
            delete selectedRequiredField[totalKeys]

            setSelectedTypeField(selectedTypeField)
            setSelectedNewField(selectedNewField)
            setSelectedRequiredField(selectedRequiredField)
            const fieldRemoved = newField.splice(newField.indexOf(thisIndex), 1);
            const newFieldsAfterDelete = []
            const fieldsToChange = newField.filter((fieldToChange) => {

                if (fieldToChange > fieldRemoved) {
                    return fieldToChange
                }

            })
            const fieldsToNotChange = newField.filter((fieldNotChange) => {
                if (fieldNotChange < fieldRemoved) {
                    return fieldNotChange
                }
            })

            fieldsToNotChange.map((fieldNotChangedToInsert) => {
                newFieldsAfterDelete.push(fieldNotChangedToInsert)
            })

            fieldsToChange.map((fieldToInsert) => {
                newFieldsAfterDelete.push(fieldToInsert - 1)
            })
            setNewField(newFieldsAfterDelete);
        }
    }

    return (
        <div className='booksRegisterTableContainer' style={{
            width: screenX,
            height: screenY,
            overflowY: 'hidden'
        }}>
            <div className='booksRegisterHead'>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            <div className='bookRegisterTableContent'>

                {

                    loading ?
                        (
                            <div id='bookRegisterLoaderDiv'>
                                <ArrowLoader />
                            </div>
                        ) :

                        showPreviewTable ?
                            (
                                <>
                                    <label>
                                        <p id='bookRegisterPreviewText'>Previsão da Tabela</p>
                                    </label>
                                    <div className='booksRegisterTablePreview'>
                                        <table id='booksRegisterTableToPreview'>
                                            <thead>
                                                <tr>
                                                    <th colSpan={selectedOperationAward ? newField.length + 6 : newField.length + 5}>
                                                        <div id='bookRegisterPreviewTableTitle'>
                                                            <p>{selectedBookName}</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>Ativo</th>
                                                    <th>Recomendado</th>
                                                    <th>Empresa</th>
                                                    <th>Vencimento</th>
                                                    {selectedOperationAward &&
                                                        (
                                                            <th>Prêmio</th>
                                                        )
                                                    }
                                                    {
                                                        newField.map((newTh) => {

                                                            if (selectedNewField[newTh] !== undefined) {
                                                                return (<th>{selectedNewField[newTh]}</th>)
                                                            }
                                                        })
                                                    }
                                                    <th>Fee %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Texto *</td>
                                                    <td>Verdadeiro/Falso *</td>
                                                    <td>Texto *</td>
                                                    <td>Data *</td>
                                                    {selectedOperationAward &&
                                                        (
                                                            <td>Numérico *</td>
                                                        )
                                                    }
                                                    {
                                                        newField.map((newTh) => {
                                                            if (selectedNewField[newTh] !== undefined) {
                                                                return (<td>{selectedRequiredField[newTh] == 'Sim' ? selectedTypeField[newTh] + ' * ' : selectedTypeField[newTh]}</td>)
                                                            }
                                                        })
                                                    }
                                                    <td>Numérico *</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <label id='bookRegisterLabelInstructions'>
                                        <input
                                            value={selectedBookName}
                                            id='bookRegisterInputName'
                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                            type="text"
                                            maxLength='30'
                                            placeholder='Digite o Nome do Book'
                                            onChange={(e) => setSelectedBookName(e.target.value)} />
                                        <div id='bookRegisterTypeOperationDiv' title='Selecione esta opção para criar um book que opera por prêmio'>
                                            <button
                                                id='bookRegisterTypeOperation'
                                                onClick={() => setSelectedOperationAward(!selectedOperationAward)}
                                                style={{
                                                    background: selectedOperationAward ? 'var(--goldColor)' : 'white',
                                                    border: '1px solid #D4D4D4'
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        opacity: selectedOperationAward ? '1' : '0'
                                                    }}>
                                                    <a>type</a>
                                                </span>
                                            </button>
                                            <a>Operação com Prêmio</a>
                                        </div>
                                        <p>Crie novas colunas utilizando o controle abaixo, as colunas obrigatórias já estão preenchidas</p>
                                    </label>
                                    <div id='bookRegisterNewFieldsTable'>
                                        <table id='bookRegisterTable'>
                                            <thead id='bookRegisterTableThead'>
                                                <td>Coluna</td>
                                                <td>Nome</td>
                                                <td>Tipo</td>
                                                <td>Obrigatório</td>
                                                <td>Remover</td>
                                                <td>Incluir</td>
                                            </thead>
                                            <tbody id='bookRegisterTableBody' style={{
                                                maxHeight: screenX / 6
                                            }}>
                                                {loading ?
                                                    (
                                                        <div className='bookRegisterLoader'>
                                                            <ArrowLoader />
                                                        </div>
                                                    ) :
                                                    (
                                                        <>
                                                            <tr className='booksRegisterFixedFields'>
                                                                <td>1</td>
                                                                <td>Ativo</td>
                                                                <td>Texto</td>
                                                                <td>Padrão</td>
                                                                <td>Obrigatória</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className='booksRegisterFixedFields'>
                                                                <td>2</td>
                                                                <td>Recomendado</td>
                                                                <td>Verdadeiro/Falso</td>
                                                                <td>Padrão</td>
                                                                <td>Obrigatória</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className='booksRegisterFixedFields'>
                                                                <td>3</td>
                                                                <td>Empresa</td>
                                                                <td>Texto</td>
                                                                <td>Padrão</td>
                                                                <td>Obrigatória</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className='booksRegisterFixedFields'>
                                                                <td>4</td>
                                                                <td>Vencimento</td>
                                                                <td>Data</td>
                                                                <td>Padrão</td>
                                                                <td>Obrigatória</td>
                                                                <td></td>
                                                            </tr>
                                                            {newField.map((field, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{index + 5}</td>
                                                                            <td>
                                                                                <div id='bookRegisterFixedFields'>
                                                                                    <input onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} maxLength={30} value={selectedNewField[index + 1]} type="text" placeholder='Digite o nome da coluna' onChange={(e) => {
                                                                                        if (newField.length > 1 || (newField?.length === 0 && selectedNewField[index] !== '')) {
                                                                                            setSelectedNewField({ ...selectedNewField, [newField[index]]: e.target.value.replace(/\s/g, '_').replace(/[\\]/g, '_').replace(/\/\//g, '') })

                                                                                        } else {
                                                                                            setSelectedNewField({ [newField]: e.target.value.replace(/\s/g, '_').replace(/[\\]/g, '_').replace(/\/\//g, '') });
                                                                                        }
                                                                                    }}>
                                                                                    </input>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div id='bookRegisterFixedFields'>
                                                                                    <div id="bookRegisterSelectDiv">
                                                                                        <FiArrowDown />
                                                                                        <select id="bookRegisterSelectType" value={
                                                                                            selectedTypeField[index + 1]}
                                                                                            onChange={(e) => {
                                                                                                if (newField.length > 1 || (newField?.length === 0 && selectedTypeField[field] !== '')) {
                                                                                                    setSelectedTypeField({ ...selectedTypeField, [newField[field - 1]]: e.target.value })
                                                                                                }
                                                                                                else {
                                                                                                    setSelectedTypeField({ [newField]: e.target.value });
                                                                                                }
                                                                                            }} >
                                                                                            <option selected disabled>Selecione o tipo</option>
                                                                                            {listTypesFields.map((TypeFieldOption) => {
                                                                                                return (
                                                                                                    <option>
                                                                                                        {
                                                                                                            TypeFieldOption.id === 1 ? 'Numérico' :
                                                                                                                TypeFieldOption.id === 2 ? 'Texto' :
                                                                                                                    TypeFieldOption.id === 3 ? 'Data' :
                                                                                                                        TypeFieldOption.id === 4 ? 'Verdadeiro/Falso' : 'Erro'
                                                                                                        }
                                                                                                    </option>)
                                                                                            })}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div id='bookRegisterFixedFields'>
                                                                                    <div id="bookRegisterSelectDiv">
                                                                                        <FiArrowDown />
                                                                                        <select id="bookRegisterSelectType" value={
                                                                                            selectedRequiredField[index + 1]}
                                                                                            onChange={(e) => {

                                                                                                if (newField.length > 1 || (newField?.length === 0 && selectedRequiredField[field] !== '')) {
                                                                                                    setSelectedRequiredField({ ...selectedRequiredField, [newField[field - 1]]: e.target.value })
                                                                                                }
                                                                                                else {
                                                                                                    setSelectedRequiredField({ [newField]: e.target.value });
                                                                                                }
                                                                                            }} >
                                                                                            <option selected disabled>Selecione</option>
                                                                                            <option>Sim</option>
                                                                                            <option>Não</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    tabIndex='0'
                                                                                    id='bookRegisterNewFieldDiv'
                                                                                    onClick={() => {
                                                                                        deleteRow(index, field)
                                                                                    }}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                            deleteRow(index, field)
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <FiTrash />
                                                                                </div>
                                                                            </td>
                                                                            {index === newField?.length - 1 ?

                                                                                (
                                                                                    <td
                                                                                        tabIndex='0'
                                                                                        onKeyPress={(e) => {
                                                                                            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                                if (selectedNewField[field]?.length > 0 && selectedTypeField[field]?.length > 0 && selectedRequiredField[field]?.length > 0) {
                                                                                                    newField?.length > 0 ? setNewField([...newField, newField[newField?.length - 1] + 1]) :
                                                                                                        setNewField([1]);
                                                                                                    setGetChild(true)
                                                                                                } else {
                                                                                                    alert("Preencha todos os campos desta linha")
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            if (selectedNewField[field]?.length > 0 && selectedTypeField[field]?.length > 0 && selectedRequiredField[field]?.length > 0) {
                                                                                                newField?.length > 0 ? setNewField([...newField, newField[newField?.length - 1] + 1]) :
                                                                                                    setNewField([1]);
                                                                                                setGetChild(true)
                                                                                            } else {
                                                                                                alert("Preencha todos os campos desta linha")
                                                                                            }
                                                                                        }}>
                                                                                        +
                                                                                    </td>
                                                                                ) :
                                                                                (<td></td>)
                                                                            }
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                            <tr className='booksRegisterFixedFields'>
                                                                <td>{newField.length + 5}</td>
                                                                <td>Fee %</td>
                                                                <td>Numérico</td>
                                                                <td>Padrão</td>
                                                                <td>Obrigatória</td>
                                                                {
                                                                    newField?.length === 0 ?
                                                                        (
                                                                            <td
                                                                                className='bookRegisterPlusTd'
                                                                                onClick={() => { setNewField([1]); setGetChild(true) }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                        setNewField([1])
                                                                                        setGetChild(true)
                                                                                    }
                                                                                }}
                                                                                tabIndex='0'
                                                                            >
                                                                                <FiPlus />
                                                                            </td>
                                                                        ) : (<td></td>)
                                                                }
                                                            </tr>
                                                        </>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
            </div>
            {showPreviewTable ?
                (
                    <div id='bookRegisterConfirmationBox'>
                        <a>os Campos com (*) são obrigatórios</a>
                        <a>Deseja criar esta tabela?</a>
                        <div id='bookRegisterConfirmationBoxButtons'>
                            <button className='buttonWhiteBg' onClick={() => {
                                const fields = []
                                Object.keys(selectedNewField).forEach(key => {

                                    fields.push({
                                        field: selectedNewField[key],
                                        books_param_types_id: selectedTypeField[key] === 'Numérico' ? 1 :
                                            selectedTypeField[key] === 'Texto' ? 2 :
                                                selectedTypeField[key] === 'Data' ? 3 :
                                                    selectedTypeField[key] === 'Verdadeiro/Falso' ? 4 : undefined,
                                        required: selectedRequiredField[key] === 'Sim' ? true : false
                                    });
                                })
                                handleSubmit(fields)
                            }
                            }>Confirmar</button>
                            <button className='buttonWhiteBg' onClick={() => setShowPreviewTable(false)}>Voltar</button>
                        </div>
                    </div>
                ) :
                (
                    <button id='bookRegisterButtonWhiteBg'
                        onClick={() => {

                            let errorsInTable = 0
                            const lines = Object.keys(selectedNewField)                            
                            if(lines?.length > 0){
                                lines.forEach((line) => {
                                    let thisLine = parseInt(line) + 4

                                    if(selectedNewField[line] === undefined){errorsInTable = 1; return alert(`Informe o nome do campo na linha ${thisLine}`)}
                                    if(selectedTypeField[line] === undefined) {errorsInTable = 1; return alert(`Informe o tipo do campo na inha ${thisLine}`)}
                                    if(selectedRequiredField[line] === undefined) {errorsInTable = 1; return alert(`Informe se o campo é obrigatório ou não na linha ${thisLine}`)}
                                })
                            }
                            errorsInTable === 0  ?
                            selectedBookName?.length > 0 ?
                                setShowPreviewTable(true) : alert('Digite o nome do Book') 
                                : console.log('')
                        }}
                    >Pre-visualizar tabela</button>
                )
            }

        </div >
    )
}
