/**
 * Desenvolvido por: equipe de Tiago Murilo Ochoôa da Luz
 * Lista de items do Menu
 **/

import {
  FiMap,
  FiGrid,
  FiBarChart,
  FiCheckCircle,
  FiCreditCard,
  FiTrendingUp,
  FiSettings,
  FiBarChart2,
  FiShoppingCart,
  FiAlignLeft,
  FiSmile,
  FiActivity,
  FiUserPlus,
  FiList,
  FiStar,
  FiEye
} from "react-icons/fi";

import { CgPerformance } from "react-icons/cg"

import { BsGraphUp } from "react-icons/bs"

import { VscGraph } from "react-icons/vsc"

import { AiOutlineProfile } from "react-icons/ai"

import Wisir from '../../../assets/icons/Wisir'
import AbcLogo from '../../../assets/icons/abcLogo'

const menuOptions = [
  {
    name: "Dashboard",
    type: "simple",
    to: "/",
    permission: [1, 2, 3, 4],
    icon: <FiActivity size="20" color="white" />,
  },
  {
    name: "Cubastar",
    type: "simple",
    to: "/cubastar",
    permission: [1, 2, 3, 4],
    icon: <FiStar size="20" color="white" />,
  },
  {
    name: "Investimentos",
    type: "list",
    icon: <FiTrendingUp size="20" color="white" />,
    trigger: "option13",
    permission: [1, 2, 4],
    list: [
      {
        to: "/investimentos/rendaFixa",
        // to: "/cubastar",
        name: "Renda Fixa",
        permission: [1, 2, 4],
      },
      {
        to: "/investimentos/fundos",
        name: "Fundos",
        permission: [1, 2, 4],
      },
      {
        to: "/investimentos/offshore",
        name: "Offshore",
        permission: [1, 2, 4],
      },
      // {
      //   to: "/investimentos/cambio",
      //   name: "Câmbio",
      //   permission: [1, 2, 4],
      // },
      // {
      //   to: "/investimentos/credito",
      //   name: "Crédito",
      //   permission: [1, 2, 4],
      // },
      // {
      //   to: "/investimentos/seguros",
      //   name: "Seguros",
      //   permission: [1, 2, 4],
      // },
    ],
  },
  
  // {
  //   name: "Visão de Assessor",
  //   type: "list",
  //   icon: <FiEye size="20" color="white" />,
  //   trigger: "option12",
  //   permission: [1, 2, 3],
  //   list: [
  //     {
  //       to: "/visaoAssessor/dashboard",
  //       name: "Dashboard do Assessor",
  //       permission: [1, 2, 3],
  //     },
  //     {
  //       to: "/cubastar",
  //       name: "Cubastar",
  //       permission: [1, 2],
  //     }
  //   ],
  // },
  {
    name: "Meus Clientes",
    type: "list",
    icon: <FiSmile size="20" color="white" />,
    trigger: "option4",
    permission: [3, 4],
    list: [
      {
        to: "/indicarClientes",
        name: "Clientes fora da Mesa",
        permission: [4]
      },
      {
        to: "/indicarClientes",
        name: "Indicar Clientes",
        permission: [4],
      },
      {
        to: "/clientesMesa",
        name: "Meus Clientes Mesa",
        permission: [3, 4],
      },
      {
        to: "/clientesNaoMesa",
        name: "Clientes fora da mesa",
        permission: [3],
      },
    ],
  },
  {
    name: "Meus Negócios",
    type: "list",
    icon: <FiAlignLeft size="20" color="white" />,
    trigger: "option5",
    permission: [4],
    list: [
      {
        to: "/minhasEstruturadas",
        name: "Minhas Estruturadas",
        permission: [4],
      },
      // {
      //     to: '/minhasPush',
      //     name: 'Minhas Ordens Push',
      //     permission: [4],
      // },
      {
        to: "/minhasReservas",
        name: "Minhas Reservas (Ofertas)",
        permission: [4],
      },
    ],
  },
  {
    name: "Estratégias Wisir",
    type: "list",
    icon: <Wisir size="20" color="white" />,
    trigger: "optionWisir",
    permission: [1, 2, 3, 4],
    list: [
      // {
      //   to: "/wisirAcoes",
      //   name: "Wisir Total Return",
      //   permission: [3,4],
      // },
      // {
      //   to: "/wisirFii",
      //   name: "Wisir Fundos Imobiliários",
      //   permission: [3,4],
      // },
      // {
      //   to: "/wisirRenda",
      //   name: "Wisir Renda Imobiliária",
      //   permission: [3,4],
      // },
      // {
      //   to: "/aprovarClientesWisir",
      //   name: "Aprovar Indicações Wisir",
      //   permission: [1, 2, 3],
      // },
      {
        to: '/wisirMapeamento',
        name: 'Mapeamento Estratégias',
        permission: [1, 2]
      },
      {
        to: "/wisirControle",
        name: "Controle wisir",
        permission: [1, 2],
      },
      {
        to: "/wisirAcompanhamento",
        name: "Acompanhamento wisir",
        permission: [1, 2, 3, 4],
      },
      // {
      //   to: "/wisirOperacional",
      //   name: "Operacional wisir",
      //   permission: [3, 4],
      // },
    ],
  },
  {
    name: "Solicitar Ordens",
    type: "list",
    icon: <FiShoppingCart size="20" color="white" />,
    trigger: "option3",
    permission: [1, 2, 3, 4],
    list: [
      // {
      //     to: '/ordensPush',
      //     name: 'Ordens Push',
      //     permission: [1, 2, 3, 4],
      // },
      {
        to: "/acoes",
        name: "Ofertas Públicas Ações",
        permission: [1, 2, 3, 4],
      },
      {
        to: "/fiis",
        name: "Ofertas Públicas FIIs",
        permission: [1, 2, 3, 4],
      },
      // {
      //   to: "/wisirIndicarAcoes",
      //   name: "Wisir Ações",
      //   permission: [4],
      // },
      // {
      //   to: "/wisirIndicarFii",
      //   name: "Wisir FIIs",
      //   permission: [4],
      // },
      // {
      //   to: "/wisirIndicarRenda",
      //   name: "Wisir Renda Imobiliária",
      //   permission: [4],
      // },
      {
        name: "Operações Estruturadas",
        type: "simple",
        to: "/books",
        permission: [1, 2, 3, 4],
        icon: <FiGrid size="20" color="white" />,
      },
      {
        to: "/termoCaixa",
        name: "Termo Caixa",
        permission: [1, 2, 3],
      },
      {
        to: "/longShort",
        name: "Long Short",
        permission: [1, 2, 3, 4],
      },
    ],
  },
  {
    name: "Recomendações",
    type: "simple",
    permission: [1, 2, 3],
    to: "/recomendacoes",
    icon: <FiCheckCircle size="20" color="white" />,
  },

  {
    name: "Relatórios",
    type: "simple",
    permission: [1, 2, 3, 4],
    to: "/relatorios",
    icon: <FiBarChart2 size="20" color="white" />,
  },

  {
    name: "FAQ/Educacional",
    type: "simple",
    permission: [1, 2, 3, 4],
    to: "/faq",
    icon: <FiMap size="20" color="white" />,
  },

  {
    name: "Cadastros",
    type: "list",
    permission: [1, 2],
    icon: <FiUserPlus size="20" color="white" />,
    trigger: "option6",
    list: [
      {
        to: "/clientes",
        name: "Clientes",
        permission: [1, 2],
      },
      {
        to: "/empresas",
        name: "Empresas",
        permission: [1, 2],
      },
      {
        to: "/perfisBroker",
        name: "Perfis de Broker",
        permission: [1, 2],
      },
      {
        to: "/usuarios",
        name: "Usuários",
        permission: [1, 2],
      },
    ],
  },
  {
    name: "Painel de Acompanhamento",
    type: "list",
    permission: [1, 2, 3, 4],
    icon: <FiList size="20" color="white" />,
    trigger: "option7",
    list: [
      // {
      //   to: "/indicarClientes",
      //   name: "Aprovar Indicações",
      //   permission: [1, 2, 3],
      // },
      {
        to: "/minhasEstruturadas",
        name: "Operações estruturadas",
        permission: [1, 2, 3, 4],
      },
      {
        to: "/monitorEstruturadas",
        name: "Monitor Estruturadas",
        permission: [1, 2, 3, 4],
      },
      {
        to: "/monitorGarantias",
        name: "Monitor Garantias B3",
        permission: [1, 2, 3, 4],
      },
      {
        to: "/monitorTermoCaixa",
        name: "Monitor Termo Caixa",
        permission: [1, 2],
      },
      // {
      //   to: "/minhasPush",
      //   name: "Ordens Push",
      //   permission: [1, 2, 3, 4],
      // },
      {
        to: "/minhasReservas",
        name: "Reservas (ofertas)",
        permission: [1, 2, 3, 4],
      },
      {
        to: "/longShortIndex",
        name: "Long Short",
        permission: [1, 2, 3, 4],
      },
    ],
  },
  {
    name: "Painel Broker",
    type: "simple",
    permission: [],
    icon: <FiBarChart size="20" color="white" />,
    to: "/painelBroker",
  },
  {
    name: "Painel Broker",
    type: "list",
    icon: <FiBarChart size="20" color="white" />,
    trigger: "option8",
    permission: [1, 3],
    list: [
      {
        to: "/roaDashboard",
        name: "ROA por Cliente",
        permission: [1, 2, 3],
      },
      {
        to: "/brokerPayroll",
        name: "Extrato Mensal - Prévia",
        permission: [1, 3],
      },
      {
        to: "/brokersAdvisors",
        name: "Brokers por Assessor",
        permission: [1, 2, 3],
      },
      {
        to: "/monitorCustodia",
        name: "Monitor custódia",
        permission: [1, 2, 3],
      },
    ],
  },
  {
    name: "Configuração",
    type: "list",
    permission: [1, 2],
    icon: <FiSettings size="20" color="white" />,
    trigger: "option9",
    list: [
      {
        to: "/ativos",
        name: "Ativos",
        permission: [1, 2],
      },
      {
        to: "/cadastroBooks",
        name: "Books",
        permission: [1, 2],
      },
      {
        to: "/wisirInsert",
        name: "Inserção Wisir",
        permission: [1, 2]
      },
      {
        to: "/payrollInsert",
        name: "Importar Extrato",
        permission: [1, 2]
      },
      {
        to: "/alocacaoConfig",
        name: "Arquivos Alocação",
        permission: [1, 2]
      }
    ],
  },
  {
    name: "B2C Intensidade",
    type: "list",
    permission: [1,4],
    profilePermission: [4],
    icon: <AiOutlineProfile size="20" color="white" />,
    trigger: "option10",
    list: [
      {
        to: "/createForm",
        name: "Criar portfólio",
        permission: [1,4],
        profilePermission: [4],
        forLeaders : true
      },
      {
        to: "/selectForm",
        name: "Escolher portfólio",
        permission: [1,4],
        profilePermission: [4],
      },
      {
        to: "/myForms",
        name: "Meus portfólios",
        permission: [1,4],
        profilePermission: [4],
      },
      {
        to: "/editCreatedForm",
        name: "Editar portfólios",
        permission: [1,4],
        profilePermission: [4],
      },

    ],
  },
  // {
  //   name: "Performance",
  //   type: "simple",
  //   to: "/performance",
  //   permission: [1, 2, 4],
  //   icon: <CgPerformance size="20" color="white" />,
  // },
  // {
  //   name: "Graph",
  //   type: "simple",
  //   to: "/graph",
  //   permission: [1],
  //   icon: <BsGraphUp size="20" color="white" />,
  // },
  // {
  //   name: "Painel Broker NOVO",
  //   type: "simple",
  //   permission: [3],
  //   icon: <VscGraph size="20" color="white" />,
  //   to: "/painelBroker2",
  // },
  // {
  //   name: "ABC",
  //   type: "list",
  //   permission: [1, 2, 3],
  //   icon: <AbcLogo />,
  //   trigger: "option11",
  //   list: [
  //     {
  //       to: "/abc",
  //       name: "Acompanhamento",
  //       permission: [1,2,3]
  //     },
  //     {
  //       to: "/abcDash",
  //       name: "Dashboard",
  //       permission: [1,2]
  //     },
  //     {
  //       to: "/abcManagement",
  //       name: "Gerenciamento",
  //       permission: [1, 2]
  //     }
  //   ]
  // }
];

export default menuOptions;
