import { useModal } from "../../../contexts/useModal";
import Modal from '../../../components/modal/modal';
import { SelectFormStructure } from "./structures/selectFormStructure";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import { FormToReply } from "./structures/formToReply";
import "./styles/global.css";

export function SelectForm(){
  const {type} = useModal()
  
  return (
    <div className="containerCreateForm">
      <Modal>{type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : <></>}</Modal>
      <SelectFormStructure />
    </div>
  )
}