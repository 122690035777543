import React from 'react';
/*Styles*/
import styles from './styles/termoCaixa.css';
/*Contexts*/
import { useAuth } from '../../../contexts/useAuth';
import { useModal } from '../../../contexts/useModal';
import Modal from '../../../components/modal/modal';
import { TermoCaixaStructure } from './structures/termoCaixaStructure';
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import { InformationsImportModal } from './cashForwardInformationsImportModal';

export function TermoCaixa(){

  const { tokenKey } = useAuth()
  const { show, setShow, type, setType } = useModal()

  return (<>
    <Modal>{
    type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : 
    type.name === "informationsImportModal" ? (<InformationsImportModal />) :
    <></>}</Modal>
    <TermoCaixaStructure />
  </>)
}