import React, { useEffect, useState } from 'react';
import { useModal } from '../../../contexts/useModal';
import { useAuth } from '../../../contexts/useAuth';
import Modal from '../../../components/modal/modal';
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox';

import {PerformanceTabsTable} from './performanceTabsTable'

export function Performance(){
  const { type, setType, show } = useModal();
  const { user } = useAuth();

  return (
    <div className="recomendationsContainer">
      <Modal>
        {type.name === "alertMsg" ? (
          <ConfirmationBox msgData={type} />
        ) : <></>}
      </Modal>
      <PerformanceTabsTable />
    </div>
  );
}