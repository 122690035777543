import React, { useState,useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MdDone from "@mui/icons-material/Done";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Block from "@mui/icons-material/Block";
import { withStyles } from "tss-react/mui";
import { Autocomplete } from '@material-ui/lab'
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import { height } from '@mui/system';

const defaultToolbarSelectStyles = {
  iconContainer: {
    display: "flex",
    marginRight: "3rem"
  },
  statusArea : {
    width: "15rem",
    marginRight: "3rem",
    height: "1.5rem"
  }
};

class CustomToolbarSelect extends React.Component {

  handleSelectedStatus = () => {
    const selectedsIds = [];
    const selectedRows = this.props.selectedRows.data
    // console.log('selectedRows',selectedRows)
    // console.log('this.props.displayData', this.props.displayData)
    console.log('selectedRows',selectedRows)
    console.log('this.props.displayData',this.props.displayData)
    // console.log('lastRowClicked',this.props?.lastRowClicked)
    return
    selectedRows.forEach((val) => {
    // console.log('this.props.displayData[val.index]', this.props.displayData[val.index])
    selectedsIds.push(this.props.displayData[val.index].data[0])
  })
  console.log('selectedsIds', selectedsIds)
  if (selectedsIds.length <= this.props.listaClients.length * 0.1){
    this.props.setSelectedRows([])
    this.props.updateExceptionClients(selectedsIds)
    this.props.setViewCheckbox(false)
  }
  else{
    alert("Quantia de clientes para exceção de performance excede o limite")
  }
};

render() {
    const { classes } = this.props;
    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Os clientes exceção não fazem parte dos cálculos de aderência ABC"}>
          <span className='itemDetail'>
              {this.props.team === 'myClients' ? <button className='botaoSolicitarExcecaoVBA' onClick={() => {this.handleSelectedStatus()}}>Aplicar exceção aos clientes selecionados</button> : <></>} 
          </span>
        </Tooltip>
        <Tooltip title={"Cancelar"}>
          <span className='itemDetail'>
              <button className='botaoSolicitarExcecaoCancelVBA' onClick={() => {this.props.setViewCheckbox(false)}}>{this.props.team === 'myClients' ? 'Cancelar' : 'Voltar'}</button> 
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });
