/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe TECH RV
 */

import React, { useState, useEffect } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './abcModal.css';
/*Utils*/
import XLSX from 'xlsx';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
/*Services*/
import { api } from '../../../../services/api';
import { validyFiles } from '../../../../utils/validyTypesOfFiles';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { FiArrowDown } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
root: {
    "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
        background: 'red'
    },
    fontFamily: 'Roboto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '400'
},

stepperItems: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
},

'& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
backButton: {
    marginRight: theme.spacing(1),
},
instructions: {
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto',
    fontSize: '0.9rem !important'
},
}));

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
}

 export function CubastarRentabilityImport(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
    const [dataExcelAPI, setDataExcelAPI] = useState([]);
    const [excelRows, setExcelRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType, setEdited } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showFileButton, setShowFileButton] = useState(true);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [dateExec, setDateExec] = useState('');
    const fixedDate = new Date();
    const { screenX, screenY } = ModalSizeFitToScreen();

    useEffect(() => {

        setShowFileButton(true);
        setShowErrors(false);
        setActiveStep(0);
        setExcelRows([]);
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);

    }, [show])

    const handleNext = async (propsNext) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 1) {
            importIndications(dataExcelAPI)
        }
    }

    async function importIndications(dataFields) {
        setLoading(true);
        try {
            const importAPI = new Promise(async (resolve, reject) => {
                try {
                    const importResults = await api.post(`/api/v1/alocacao/cubastar/carteiras/return_risk`, {
                        'data': dataFields
                    },
                    {
                        headers: {
                            authorization: `Bearer ${token}`
                        }
                    })
                    resolve(importResults)
                } catch (error) {
                    reject(error)
                }
            })

            let inProcessLoading = true;
            let timeCount = 0

            do {
                setHideButtons(true);
                if (timeCount == 5) {
                    setImportPlainMessage(`A planilha está em processo de importação...`)
                } else if (timeCount < 10) {
                    setImportPlainMessage(`Este processo pode levar alguns minutos....`)
                } else if (timeCount < 15) {
                    setImportPlainMessage(`Aguarde, o processo está quase acabando...`)
                }

                importAPI.then((value) => {
                    inProcessLoading = false;
                    setHideButtons(false);
                    setLoading(false);
                    setSuccessImport(true);
                    if (value?.data?.errors_details?.length > 0) {
                        setErrorsImport(true);
                        setErrorsList(value?.data?.errors_details);
                    } else {
                        setType({ name: 'alertMsg', type: 'information', value: { message: 'Tabelas de rentabilidade das Carteiras Cubastar importadas com sucesso!' } })
                    }
                    setEdited(true)
                }).catch((error) => {

                    inProcessLoading = false;
                    if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                        setErrorsList([{ error_message: error?.response?.data?.message }]);
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsImport(true);
                    } else {
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsList(error?.response?.data?.errors_details);
                        setErrorsImport(true);
                    }
                })
                await new Promise(resolve => {
                    setTimeout(() => {
                        timeCount += 1
                        resolve()
                        if (timeCount == 20) timeCount = 0
                    }, 1000)
                })
            } while (inProcessLoading == true);
        } catch (error) {
            alert('Erro inesperado.')
            setLoading(false);
        }
    }

    // FUNÇÕES PARA FORMATAR SERIAL NUMBER EXCEL EM DATA
    function excelDateToJSDate(excelDate) {
        var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
        return date;
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    function formatDate(date) {
        return [
            // padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                const schemaToValidate = [
                    // { excelValue: 'Código', bdValue: 'Codigo' },
                    // { excelValue: 'ID Categoria', bdValue: 'IdCategory' },
                    // { excelValue: 'Categoria', bdValue: 'Category' },
                    // { excelValue: 'Objetivo', bdValue: 'Objetivo' },
                    // { excelValue: 'Financeiro', bdValue: 'Financeiro' },
                    // { excelValue: 'Discurso', bdValue: 'Discurso' }
                    { excelValue: 'GRUPO', bdValue: 'carteira' },
                    { excelValue: 'CARTEIRA', bdValue: 'portfolio' },
                    { excelValue: 'EDIÇÃO CARTEIRA', bdValue: 'edicao_carteira' },
                    { excelValue: 'CARTEIRA 1D', bdValue: 'carteira_1d' },
                    { excelValue: 'CDI 1D', bdValue: 'cdi_1d' },
                    { excelValue: 'CARTEIRA 1M', bdValue: 'carteira_1m' },
                    { excelValue: 'CDI 1M', bdValue: 'cdi_1m' },
                    { excelValue: 'CARTEIRA 6M', bdValue: 'carteira_6m' },
                    { excelValue: 'CDI 6M', bdValue: 'cdi_6m' },
                    { excelValue: 'CARTEIRA 12M', bdValue: 'carteira_12m' },
                    { excelValue: 'CDI 12M', bdValue: 'cdi_12m' },
                    { excelValue: 'CARTEIRA 24M', bdValue: 'carteira_24m' },
                    { excelValue: 'CDI 24M', bdValue: 'cdi_24m' },
                    { excelValue: 'CARTEIRA 36M', bdValue: 'carteira_36m' },
                    { excelValue: 'CDI 36M', bdValue: 'cdi_36m' },
                    { excelValue: 'YTD CARTEIRA', bdValue: 'ytd_carteira' },
                    { excelValue: 'YTD CDI', bdValue: 'ytd_cdi' },
                    { excelValue: 'MTD CARTEIRA', bdValue: 'mtd_carteira' },
                    { excelValue: 'MTD CDI', bdValue: 'mtd_cdi' },
                    { excelValue: 'MÊS ATUAL CARTEIRA', bdValue: 'mes_atual_carteira' },
                    { excelValue: 'MÊS ATUAL CDI', bdValue: 'mes_atual_cdi' },
                    { excelValue: 'MÉD CARTEIRA', bdValue: 'med_carteira' },
                    { excelValue: 'MÉD CDI', bdValue: 'med_cdi' },
                    { excelValue: 'VOL 12M', bdValue: 'vol_12m' },
                    { excelValue: 'VOL 24M', bdValue: 'vol_24m' },
                    { excelValue: 'SHARPE 12M', bdValue: 'sharpe_12m' },
                    { excelValue: 'SHARPE 24M', bdValue: 'sharpe_24m' },
                    { excelValue: 'RMAX 12M', bdValue: 'rmax_12m' },
                    { excelValue: 'RMAX 24M', bdValue: 'rmax_24m' },
                    { excelValue: 'RMIN 12M', bdValue: 'rmin_12m' },
                    { excelValue: 'RMIN 24M', bdValue: 'rmin_24m' }
                ];
                let indexIgnored = 0;
                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                if(fieldToValidate != "" && fieldToValidate != ""){
                    if (fieldToValidate !== schemaToValidate[index-indexIgnored]?.excelValue) {
                        return fieldToValidate
                    }
                }
                else{
                    indexIgnored++
                }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['carteira', 'portfolio', 'edicao_carteira', 'carteira_1d', 'cdi_1d', 'carteira_1m', 'cdi_1m', 'carteira_6m', 'cdi_6m', 'carteira_12m', 'cdi_12m', 'carteira_24m', 'cdi_24m', 'carteira_36m', 'cdi_36m', 'ytd_carteira', 'ytd_cdi', 'mtd_carteira', 'mtd_cdi', 'mes_atual_carteira', 'mes_atual_cdi', 'med_carteira', 'med_cdi', 'vol_12m', 'vol_24m', 'sharpe_12m', 'sharpe_24m', 'rmax_12m', 'rmax_24m', 'rmin_12m', 'rmin_24m'],
                        cellDates: false
                    });
                    resolve(data)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        var data = await promise
        data.splice(0, 1)
        
        setExcelRows(data);

        let newFormattedData = []
        
        data.map((data) => {
            
            newFormattedData.push({
                // 'client_id': data.Codigo,
                // 'category_id': data.IdCategory,
                // 'objective': data.Objetivo,
                // 'value': data.Financeiro,
                // 'speech': data.Discurso != null || data.Discurso != undefined ? data.Discurso : null
                'carteira': data.carteira,
                'portfolio': data.portfolio,
                'edicao_carteira': formatDate(excelDateToJSDate(data.edicao_carteira)),
                'carteira_1d': data.carteira_1d,
                'cdi_1d': data.cdi_1d,
                'carteira_1m': data.carteira_1m,
                'cdi_1m': data.cdi_1m,
                'carteira_6m': data.carteira_6m,
                'cdi_6m': data.cdi_6m,
                'carteira_12m': data.carteira_12m,
                'cdi_12m': data.cdi_12m,
                'carteira_24m': data.carteira_24m,
                'cdi_24m': data.cdi_24m,
                'carteira_36m': data.carteira_36m,
                'cdi_36m': data.cdi_36m,
                'ytd_carteira': data.ytd_carteira,
                'ytd_cdi': data.ytd_cdi,
                'mtd_carteira': data.mtd_carteira,
                'mtd_cdi': data.mtd_cdi,
                'mes_atual_carteira': data.mes_atual_carteira,
                'mes_atual_cdi': data.mes_atual_cdi,
                'med_carteira': data.med_carteira,
                'med_cdi': data.med_cdi,
                'vol_12m': data.vol_12m,
                'vol_24m': data.vol_24m,
                'sharpe_12m': data.sharpe_12m,
                'sharpe_24m': data.sharpe_24m,
                'rmax_12m': data.rmax_12m,
                'rmax_24m': data.rmax_24m,
                'rmin_12m': data.rmin_12m,
                'rmin_24m': data.rmin_24m
            });
        });
        //  setExcelRows(newFormattedData);
        setDataExcelAPI(newFormattedData);
        // console.log(newFormattedData)
        return setLoading(false);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
    };

    const handleReset = () => {
        setActiveStep(0)
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
    };

    function handleFileCheck() {
        if (excelRows.length > 0) {
        // if(dateExec != ''){
            handleNext()
            setShowFileButton(false);
        // }
        // else{
        //     alert("Insira uma data de execução \n")
        // }
        }
        else {
        let msg = ''
        msg = msg + 'Carregue a planilha antes \n'
        // if(dateExec == ''){
        //     msg = msg + 'Insira uma data de execução \n'
        // }
            alert(msg)
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function tableForImportReccords() {
        return (
            excelRows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Grupo</th>
                            <th>Carteira</th>
                            <th>Edição Carteira</th>
                            <th>Carteira 1D</th>
                            <th>CDI 1D</th>
                            <th>Carteira 1M</th>
                            <th>CDI 1M</th>
                            <th>Carteira 6M</th>
                            <th>CDI 6M</th>
                            <th>Carteira 12M</th>
                            <th>CDI 12M</th>
                            <th>Carteira 24M</th>
                            <th>CDI 24M</th>
                            <th>Carteira 36M</th>
                            <th>CDI 36M</th>
                            <th>YTD Carteira</th>
                            <th>YTD CDI</th>
                            <th>MTD Carteira</th>
                            <th>MTD CDI</th>
                            <th>Mês Atual Carteira</th>
                            <th>Mês Atual CDI</th>
                            <th>MÉD Carteira</th>
                            <th>MÉD CDI</th>
                            <th>VOL 12M</th>
                            <th>VOL 24M</th>
                            <th>Sharpe 12M</th>
                            <th>Sharpe 24M</th>
                            <th>Retorno Máximo 12M</th>
                            <th>Retorno Máximo 24M</th>
                            <th>Retorno Mínimo 12M</th>
                            <th>Retorno Mínimo 24M</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {excelRows?.map((excelLine, indexLine) => {
                            const fieldKeys = Object.keys(excelLine)

                            return (
                                <tr>
                                    <td>{excelLine.carteira}</td>
                                    <td>{excelLine.portfolio}</td>
                                    <td>{formatDate(excelDateToJSDate(excelLine.edicao_carteira))}</td>
                                    <td>{excelLine.carteira_1d}</td>
                                    <td>{excelLine.cdi_1d}</td>
                                    <td>{excelLine.carteira_1m}</td>
                                    <td>{excelLine.cdi_1m}</td>
                                    <td>{excelLine.carteira_6m}</td>
                                    <td>{excelLine.cdi_6m}</td>
                                    <td>{excelLine.carteira_12m}</td>
                                    <td>{excelLine.cdi_12m}</td>
                                    <td>{excelLine.carteira_24m}</td>
                                    <td>{excelLine.cdi_24m}</td>
                                    <td>{excelLine.carteira_36m}</td>
                                    <td>{excelLine.cdi_36m}</td>
                                    <td>{excelLine.ytd_carteira}</td>
                                    <td>{excelLine.ytd_cdi}</td>
                                    <td>{excelLine.mtd_carteira}</td>
                                    <td>{excelLine.mtd_cdi}</td>
                                    <td>{excelLine.mes_atual_carteira}</td>
                                    <td>{excelLine.mes_atual_cdi}</td>
                                    <td>{excelLine.med_carteira}</td>
                                    <td>{excelLine.med_cdi}</td>
                                    <td>{excelLine.vol_12m}</td>
                                    <td>{excelLine.vol_24m}</td>
                                    <td>{excelLine.sharpe_12m}</td>
                                    <td>{excelLine.sharpe_24m}</td>
                                    <td>{excelLine.rmax_12m}</td>
                                    <td>{excelLine.rmax_24m}</td>
                                    <td>{excelLine.rmin_12m}</td>
                                    <td>{excelLine.rmin_24m}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }

    function errorsTable() {
        if (errorList?.length > 0 && errorList[0].error !== undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

        if (errorList?.length > 0 && errorList[0].error_message === undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }


    }

    async function getLayout() {

        try {
            const getLayouts = await api.get(`/api/v1/alocacao/cubastar/carteiras/return_risk/layout`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })
    
            const url = URL.createObjectURL(new Blob([getLayouts.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'layout_importação_tabelas_rentabilidade.xlsx')
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.log(error?.response)
            alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
        }
    }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                {showFileButton ?
                                    (
                                        <>
                                        <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                        <button className='customButton' onClick={() => getLayout()}>Arquivo Layout <FiArrowDown /> </button>
                                        <a>{fileName.substring(12, fileName.length)}</a>
                                        </>
                                    ) :
                                    (<></>)}

                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                <a>Importando clientes para o produto</a>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                    successImport && !errorsImport ?
                        (
                            <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                <a>Sucesso ao importar a lista</a>
                            </div>
                        ) :

                        activeStep === 1 ? (
                            (<>
                                <p id='recommendationsImportConfirm'>Confira os dados</p>
                                <div id='customScroll' className='tableForImportSection'>
                                    {
                                        tableForImportReccords()
                                    } </div>
                            </>)
                        ) :
                            successImport && errorsImport ? (
                                <div id='flexRowDiv' className='stepperImportWithErrors'>
                                    <a>Sucesso ao importar arquivos!</a>
                                    <a>Mas, ocorreram erros na importação</a>
                                    <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                </div>
                            ) : !successImport && errorsImport ?
                                (
                                    <div id='flexRowDiv' className='stepperImportWithErrors'>
                                        <a>ocorreram erros na importação</a>
                                        <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                    </div>
                                ) :
                                showErrors ?
                                    (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                    :
                                    (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='flexRowDiv'>
                                <Typography className={classes.instructions}>Importado com sucesso!</Typography>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='flexRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='flexRowDiv'>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
 }