/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Inserir, consultar e ver relatórios, tanto na web quanto em documentos.
 **/

import React from 'react'
//hooks
import { useModal } from '../../../contexts/useModal'
//components
import Modal from '../../../components/modal/modal'
import { ReportsTable } from './reports.table'
import { ReportsDetail } from './reportsDetail'
import { ReportCadastrations } from './reports.cadastration'
//styles
import styles from './styles/reports.module.css'

export function Reports(){

    const { show, setShow, type } = useModal()
    return(
        <div className={styles.divContainerReports} style={{minWidth: !show ? '1000px' : '0'}}>  
            <Modal>
                {type.name == "detalheRelatorio" ?
                    <ReportsDetail msgData={type}/>
                    : <div className={styles.modalContainerReports}>
                    <div className={styles.modalContainerReportsHeader}>
                        <p>Cadastro de Relatório</p>
                        <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    </div>
                    <div className={styles.modalFormReports}>
                        <ReportCadastrations />
                    </div>
                </div>
                }
            </Modal>
           <ReportsTable />
        </div>
    )
}