import React from 'react';
/*Styles*/
import styles from './styles/assetsOfferings.css';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Components*/
import Modal from '../../../../components/modal/modal';
import { AssetsOfferingsTable } from './tables/assetsOfferings.table';
import { AssetsOfferingsDetails } from './assetsOfferingsDetails';
import { AssetsOfferingsNewTable } from './tables/assetsOfferingsNew.table';
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox';
import { OfferingsRegistration } from '../offeringsRegistration/offeringsRegistration'
import { OfferingsRegistrationStocks } from '../offeringsRegistration/editOfferingsRegistrationStocks'

export function AssetsOfferings() {

    const { tokenKey } = useAuth()
    const { show, setShow, type, setType } = useModal()

    return (

        <div className='assetsOfferingsContainer' style={{minWidth: show ? '0px' : '1000px'}}>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :
                        type.name === 'assetOfferingsShowDetails' ? 
                        (
                            <AssetsOfferingsDetails assetData={type} />
                        ) :
                        type.name === 'offeringsRegistration' ? 
                        (
                            <OfferingsRegistration />
                        ) :
                        type.name === 'editOfferingsRegistrationStocks' ?
                        (
                            <OfferingsRegistrationStocks editStocksData={type} />
                        ) :
                        (
                            <></>
                        )
                }
            </Modal>
            <AssetsOfferingsNewTable />
        </div>
    )
}