/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Tech RV
 */

import React, { useState, useEffect, useRef } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../styles/import.css';
/*Utils*/
import XLSX from 'xlsx';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
/*Services*/
import { api } from '../../../../services/api';
import { validyFiles } from '../../../../utils/validyTypesOfFiles';
import { TextField, TextareaAutosize } from '@material-ui/core'
import { Autocomplete, Select } from '@mui/material';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency';
 
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));
 
 function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
 }
 
 export function Import(props) {
     console.log(props)
    const classes = useStyles();
    const activeStep = useRef(0)
    // const [activeStep, setActiveStep] = useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
    const [dataExcelAPI, setDataExcelAPI] = useState([]);
    const [data, setData] = useState([]);
    const [mapping, setMapping] = useState({});
    const [excelRows, setExcelRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType, setEdited } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showFileButton, setShowFileButton] = useState(true);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('');
    const [dateExec, setDateExec] = useState('');
    // const [carteiras, setCarteiras] = useState([])
    const [selectedCarteiras, setSelectedCarteiras] = useState([])
    const fixedDate = new Date();
    const { screenX, screenY } = ModalSizeFitToScreen();


    useEffect(() => {
        getMap();
        setShowFileButton(true);
        setShowErrors(false);
        activeStep.current = 0;
        setExcelRows([]);
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);

    }, [show])

    const handleNext = async (propsNext) => {
        // console.log('STEP', activeStep.current)
        activeStep.current = activeStep.current + 1;
        // if (activeStep.current === 2) {
        //     setLoading(true)
        //     const aux = dataExcelAPI.filter((x) => selectedCarteiras.length > 0 ? selectedCarteiras.includes(x.strategy) : true )
        //     setData(aux);
        //     setLoading(false)
        // }
        if (activeStep.current === 2) {
            importBook(dataExcelAPI)
        }
    }
    
    // function chooseImports(dataFields) {
    //     const aux = dataFields.map((x) => x.strategy).filter((v, i, a) => i == a.indexOf(v));
    //     console.log(aux)
    //     setCarteiras(aux);
    // }

    async function getMap() {
        try {
            const result = await api.get('api/v1/wisir2/map', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log(result)
            const resultFormatted = {

            }
            result?.data?.data.map((x) => {
                resultFormatted[x.entry] = x.wisir_strategy_id
            })
            setMapping(resultFormatted);
        } catch (error) {
            setLoading(false);
            setType({
              name: "alertMsg",
              type: "information",
              value: {
                message:
                  error?.response?.data?.message == undefined
                    ? "Erro interno ao carregar as abas"
                    : error?.response?.data?.message,
              },
            });
            setShow(true);
        }
    }

    async function importBook(dataFields) {
        console.log(dataFields)
        setLoading(true);
        try {
            const importAPI = new Promise(async (resolve, reject) => {
                try {
                    const importResult = await api.put(`/api/v1/wisir2`, {
                        'data' : dataFields
                    },
                        {
                            headers: {
                                authorization: `Bearer ${token}`
                            }
                        }
                    )
                    resolve(importResult);
                } catch (error) {
                    reject(error)
                }
            })

            let inProcessLoading = true;
            let timeCount = 0

            do {
                setHideButtons(true);
                if (timeCount == 5) {
                    setImportPlainMessage(`A planilha está em processo de importação.`)
                } else if (timeCount < 10) {
                    setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                } else if (timeCount < 15) {
                    setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                }

                importAPI.then((value) => {
                    inProcessLoading = false;
                    setHideButtons(false);
                    setLoading(false);
                    setSuccessImport(true);
                    if (value?.data?.errors_details?.length > 0) {
                        setErrorsImport(true);
                        setErrorsList(value?.data?.errors_details);
                    } else {
                        setType({ name: 'alertMsg', type: 'information', value: { message: 'Posição das Carteiras importada com sucesso!' } })
                    }
                    props.update(props.value)
                    setEdited(true)
                }).catch((error) => {
                    inProcessLoading = false;
                    if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                        setErrorsList([{ error_message: error?.response?.data?.message }]);
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsImport(true);
                    } else {
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsList(error?.response?.data?.errors_details);
                        setErrorsImport(true);
                    }
                })
                await new Promise(resolve => {
                    setTimeout(() => {
                        timeCount += 1
                        resolve()
                        if (timeCount == 20) timeCount = 0
                    }, 1000)
                })
            } while (inProcessLoading == true);
        } catch (error) {
            alert('Erro inesperado.')
            setLoading(false);
        }
    }
 
    async function readExcelFile(file) {
 
        setLoading(true);
        
 
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                const schemaToValidate = [
                    { excelValue: 'Cliente', bdValue: 'client_id' },
                    { excelValue: 'Cód. Assessor Cliente', bdValue: 'cod_assessor' },
                    { excelValue: 'Carteira', bdValue: 'strategy' },
                    { excelValue: 'Valor aportado', bdValue: 'start_value' },
                    { excelValue: 'Posição', bdValue: 'current_value' },
                    { excelValue: 'Rentabilidade', bdValue: 'rentability' },
                    { excelValue: 'Entrada', bdValue: 'entry' },
                    { excelValue: 'Última execução', bdValue: 'last_change' },
                    { excelValue: 'Próximo execução', bdValue: 'next_change' },
                    { excelValue: 'Status', bdValue: 'status' }
                ];
                let indexIgnored = 0;
                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                if(fieldToValidate != "" && fieldToValidate != ""){
                    if (fieldToValidate !== schemaToValidate[index-indexIgnored]?.excelValue) {
                        return fieldToValidate
                    }
                }
                else{
                    indexIgnored++
                }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                    header: ['client_id', 'cod_assessor', 'strategy', 'start_value', 'current_value', 'rentability', 'entry', 'last_change', 'next_change', 'status'],
                    cellDates: false
                    });
                    resolve(data)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })
 
        const data = await promise
        data.splice(0, 1)
        
        setExcelRows(data);

        let newFormattedData = []
        
        data.map((data) => {
            if (mapping.hasOwnProperty(data.strategy.toUpperCase())) {
                newFormattedData.push({
                    'client_id': parseInt(data.client_id),
                    'strategy_id': mapping[data.strategy.toUpperCase()],
                    'strategy': data.strategy,
                    'start_value': parseFloat(data.start_value),
                    'current_value': parseFloat(data.current_value),
                    'rentability': parseFloat(data.rentability),
                    'entry': data.entry,
                    'last_change': data.last_change,
                    'next_change': data.next_change,
                    'status': data.status
                })
            }
        })
        //  setExcelRows(newFormattedData);
        setDataExcelAPI(newFormattedData);
        return setLoading(false);
    }
 
    const handleBack = () => {
        activeStep.current = activeStep.current - 1;
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
        setDateExec('')
    };
 
    const handleReset = () => {
        activeStep.current = 0;
        // setCarteiras([])
        setSelectedCarteiras([])
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
        setDateExec('')
    };
 
    function handleFileCheck() {
        console.log('HANDLE')
        setLoading(true)
        if (excelRows.length > 0) {
        // if(dateExec != ''){
            handleNext()
            setShowFileButton(false);
        // }
        // else{
        //     alert("Insira uma data de execução \n")
        // }
        }
        else {
            let msg = ''
            msg = msg + 'Carregue a planilha antes \n'
            // if(dateExec == ''){
            //     msg = msg + 'Insira uma data de execução \n'
            // }
            alert(msg)
        }
        setLoading(false)
    }
 
    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }
 
    function formatLineToApi(value, type) {

        switch (type) {
            case 'number':
                return parseFloat(value)
            default:
                return value
        }
    }
 
    function tableForImportReccords() {
        return (
            excelRows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Cliente</th>
                            <th>Carteira</th>
                            <th>Valor aportado</th>
                            <th>Posição</th>
                            <th>Rentabilidade</th>
                            <th>Entrada</th>
                            <th>Última execução</th>
                            <th>Próximo execução</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {dataExcelAPI.map((excelLine, indexLine) => {
                            return (
                                <tr>
                                    <td>{excelLine.client_id}</td>
                                    <td>{excelLine.strategy}</td>
                                    <td>{toBRL(excelLine.start_value)}</td>
                                    <td>{toBRL(excelLine.current_value)}</td>
                                    <td>{excelLine.rentability.toLocaleString().replace('.',',') + ' %'}</td>
                                    <td>{excelLine.entry}</td>
                                    <td>{excelLine.last_change}</td>
                                    <td>{excelLine.next_change}</td>
                                    <td>{excelLine.status}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }
 
    function errorsTable() {
        if (errorList?.length > 0 && errorList[0].error !== undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

        if (errorList?.length > 0 && errorList[0].error_message === undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }


    }
 
    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep.current} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                {showFileButton ?
                                    (
                                        <>
                                        {/* <label className='offeringsDateLabel' for='bookbuilding'><div style={{textAlign: 'center'}}>Data de execução</div>
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' type='date' id='bookbuilding' value={dateExec} placeholder='Bookbuilding' onChange={(e) => setDateExec(e.target.value)} />
                                        </label>                                             */}
                                        <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                        <a>{fileName.substring(12, fileName.length)}</a>
                                        </>
                                    ) :
                                    (<></>)
                                }

                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                <a>Importando Posições das Carteiras</a>
                {
                    loading ? (
                        <div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>
                    ) :
                    successImport && !errorsImport ? (
                        <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                            <a>Sucesso ao importar o</a>
                        </div>
                    ) :
                    // activeStep.current === 1 ? (
                    //     (<>
                    //         <div className="carteirasSelect">
                    //             <Autocomplete
                    //                 multiple
                    //                 id="carteirasChoose"
                    //                 options={carteiras}
                    //                 getOptionLabel={(option) => option}
                    //                 onChange={(e, v) => {
                    //                     setSelectedCarteiras(v)
                    //                 }}
                    //                 renderInput={(params) => (
                    //                     <TextField 
                    //                         {...params}
                    //                         label="Carteiras para adicionar à estratégia atual"
                    //                         variant="standard"
                    //                         // placeholder="Carteiras"
                    //                     />
                    //                 )}
                    //             />
                    //         </div>
                    //     </>)
                    // ) :
                    activeStep.current === 1 ? (
                        (<>
                            <p id='recommendationsImportConfirm'>Confira os dados</p>
                            <div id='customScroll' className='tableForImportSection'>
                                {
                                    tableForImportReccords(true)
                                } </div>
                        </>)
                    ) :
                    successImport && errorsImport ? (
                        <div id='flexRowDiv' className='stepperImportWithErrors'>
                            <a>Sucesso ao importar arquivos!</a>
                            <a>Mas, ocorreram erros na importação</a>
                            <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                        </div>
                    ) : 
                    !successImport && errorsImport ? (
                        <div id='flexRowDiv' className='stepperImportWithErrors'>
                            <a>ocorreram erros na importação</a>
                            <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                        </div>
                    ) :
                    showErrors ? (
                        <div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>
                    )
                    :
                    (
                        <div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>
                    )
                }
                <div>
                    {activeStep.current === steps.length && successImport ?
                        (
                            <div id='flexRowDiv'>
                                <Typography className={classes.instructions}>Importado com sucesso!</Typography>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep.current === steps.length && !successImport ?
                            (
                                <div id='flexRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep.current)}</Typography>
                                        <div id='flexRowDiv'>
                                            <Button
                                                disabled={activeStep.current === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep.current === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                {activeStep.current === steps.length -1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}