import React, { useEffect, useState } from "react";
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
import "./styles/CalendarH.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Components*/

import Calendar from 'react-calendar';

export function MyReservationHistory(props) {
    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [value, onChange] = useState(new Date());
    const [dateUpdate, setDateUpdate] = useState(new Date());
    // console.log(type.value.data);
    // console.log(props);

    const handleMonthChange = (value) => {
        const monthValue = new Date(value);
        type.value.data(0, value.getFullYear(), value.getMonth() + 1)
        props.prop.setSelectedYear(value.getFullYear());
        props.prop.setSelectedMonth(value.getMonth() + 1);
        props.prop.setSelectedOpen(0);
        setShow(false);
      };

    return (
        <div>
            <Calendar 
            maxDetail="year"
            minDate={new Date('2022-01-01 00:00:00')}
            maxDate={new Date()}
            onClickMonth={handleMonthChange}
            value={value} />
        </div>
    );
}