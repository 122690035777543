/*Descrição: tela para efetuar indicação de novos clientes a serem atendidos
/*pela mesa RV
/*Desenvolvido por: Equipe Rafael Cezário
*/
import React, { useState, useEffect } from 'react';
/*Styles*/
import './styles/clientsIndicationBox.css';
import { TextField, Checkbox, FormControl, FormLabel, Radio, FormControlLabel } from '@material-ui/core';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { useHistory } from 'react-router-dom';
/*Services*/
import { api } from '../../../../services/api';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Utils Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
// import { validateEmail } from '../../../../utils/validatorSchemas/emailValidador';
// import { requeridFields } from '../../../../utils/validatorSchemas/requiredFieldsToAPI';
// import { phoneValidator } from '../../../../utils/validatorSchemas/phoneValidator';
// import { phoneTypeValidator } from '../../../../utils/validatorSchemas/phoneTypeValidator';
// import { phoneFormatMask } from '../../../../utils/formatNumberAndText/phoneMask';
import { getMyClientsIndication } from '../../../../services/asyncFunctions';
import { Autocomplete } from '@material-ui/lab';
import { HiPlus } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { styledForOfferings } from "../../../../components/styles/autocompletField";

export function ClientAcceptedBox(props) {
    const { tokenKey, user } = useAuth()

    const { setShow, show, type, setType } = useModal();
    const [loading, setLoading] = useState(true);
    const classes = styleClientsInput();
    const classes2 = styledForOfferings();
    const history = useHistory();

    return (
        <div className='clientsIndicationMainContainerBox'>
          <div className='clientAcceptMainContainerBox'>
            <div className=''>
                <div className='tituloContatoRealizado'>Contato realizado!</div>
                <div className='listaClientes'>
                  <div className='textoOpcaoSucesso'>Gostaria de conferir sua<br/>lista de clientes ativos?</div>
                  <button className='assetsOfferingsReservConfirmation' onClick={() => {
                    history.push('/clientesMesa')
                  }} style={{width: "60%", marginTop: "1rem"}}>Clique aqui</button>
                </div>
                <div style={{width: "100%", textAlign: "center"}}><button className='closeIndicationSuccess' onClick={() => setShow(false)}>Fechar</button></div>
            </div >
          </div >
        </div>
    )

}