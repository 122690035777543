/**
 * Autor: Tiago Murilo Ochôa da Luz
 * Descrição: Menu responsivo.
 */

import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FiChevronsLeft, FiChevronsRight, FiUser, FiChevronDown, FiBell, FiHelpCircle, FiMoreHorizontal } from "react-icons/fi";
import menuOptions from "./config/directories.menu";
import { useMenu } from "../../contexts/useMenu";
import { useAuth } from "../../contexts/useAuth";
import { useModal } from "../../contexts/useModal";
import { useSocket } from "../../contexts/useSocket";
import "./menu.css";
import logo from "../../assets/logo.png";
import { MyProfile } from "./myProfile/myProfile";
import { NotificationCenter } from "./notificationCenter/notificationCenter";
import { HelpCenter } from "./helpCenter/helpCenter";
import { ModalSizeFitToScreen } from "../../utils/responsiveFunctions/modalSizeFitToScreen";
import { StylesContext } from "@material-ui/styles";
import { CgAlignCenter } from "react-icons/cg";
import { api } from '../../services/api';
import { AiFillCloseCircle } from "react-icons/ai"; 

export default function Menu() {
    const {
        menuStatus,
        setMenuStatus,
        menuSelected,
        setMenuSelected,
        menuTitle,
        setMenuTitle,
        showUser,
        setShowUser,
        showNotificationCenter,
        setShowNotificationCenter,
        showHelpCenter,
        setShowHelpCenter,
        currentScreen,
    } = useMenu();
    const { tokenKey, userType, user } = useAuth()
    const location = useLocation();
    const [showProfileConfig, setShowProfileConfig] = useState(false);
    const { show, setShow,setType} = useModal();
    const { pendings, socketEmit } = useSocket();
    const history = useHistory();
    const [totalPedings, setTotalPedings] = useState(0);
    const [selectedHelpItem, setSelectedHelpItem] = useState("");
    const { screenY } = ModalSizeFitToScreen();
    const [isLeaderB2C, setIsLeaderB2C] = useState (false);
    const [messageTransfer, setMessageTransfer] = useState("")
    const [showMessageTransfer, setShowMessageTransfer] = useState( localStorage.getItem("showMessageTransfer") ? ( localStorage.getItem("showMessageTransfer") === "true" ? true : false) : localStorage.setItem("showMessageTransfer", "true") )


    
    let screenMaxHeight = 0;
    let optionRendered = [];
    let optionsInMenu = [];

    useEffect(() => {
        setTotalPedings(pendings?.total);
    }, [pendings]);

    useEffect(() => {
        getLeadsB2C()
    },[])

    function selectItemMenuClosed(option, type) {
        setMenuSelected(option);
    }

    function selectItemMenuOpen(option) {
        setMenuSelected(option);
    }

    async function getLeadsB2C(){
    try {
        const json = await api.get(`/api/v1/menuPermition`, 
        { headers: {
            authorization: `Bearer ${tokenKey}`
        }, 
       })
       // se existirem liderados, então é lider
    //    console.log('leds', json.data.data[0].leds )
        if (json?.data?.data[0]?.leds > 0) setIsLeaderB2C(true)
        // setStrutureJson(json?.data?.data)
    } catch (error) {
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os dados do menu' } })
      setShow(true)
    }
    }

    // console.log(isLeaderB2C)

    return (
        <>
            <div className="topBar">
            {/* {(showMessageTransfer && user.type == 4 ) && (
                    <div
                        style={{
                            position: 'absolute', 
                                width: '100vw', 
                                top:'0', 
                                padding:'1rem', 
                                left:'0', 
                                right:'0', 
                                margin:'auto', 
                                background:'#7f1d1d',                                                    
                                transition: 'all 0.5s ease',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Adicionando sombra à borda
                                zIndex: '99999',
                        }}
                    >
                        <div 
                            style={{
                                
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                display: user.type == 4 ? 'flex' : 'none',
                                
                            }}
                        >
                            <div style={{fontWeight:'bold',color:'white', fontSize:'1.2rem', }}>
                                <p >
                                    Prezado assessor, em breve esta página estará disponível em 
                                </p>
                                <p style={{color:"#0ea5e9", cursor:"pointer"}}> https://portal.manchesterinvest.com.br</p>
                            </div>
                            <div style={{display:'flex', alignItems: "center", gap:'1rem', paddingRight:'2rem'}}>
                                <p 
                                    onClick={() => {
                                        localStorage.setItem('showMessageTransfer', 'false')
                                        setShowMessageTransfer(false)
                                    }}
                                    style={{cursor:"pointer",color:'#fff', fontWeight:'bold', fontSize:'1.2rem', textDecoration:'underline'}}
                                >
                                    Não Mostrar Novamente
                                </p>
                                <AiFillCloseCircle onClick={() => {setShowMessageTransfer(false)}} size={'24'} color="black" fill="white" style={{cursor:'pointer'}} /> 
                            </div>
                        </div>
                    </div>
                )} */}
                <MyProfile isOpen={{ showProfileConfig, setShowProfileConfig }} />
                <NotificationCenter />
                <div className={menuStatus != 1 ? "" : "indentationImgRight"}>
                    <img onClick={() => history.push("/")} src={logo} width="100" />
                </div>
                <div className={menuStatus != 1 ? "indentationNormalTop" : "indentationRigthTop"}>
                    <h2>{currentScreen}</h2>
                </div>
                <div className="menuIconsOptions">
                    <div
                        id="menuNotificationBellArea"
                        onClick={() => {
                            setShowUser(false);
                            setShowProfileConfig(false);
                            if (showNotificationCenter) {
                                setShowNotificationCenter(false);
                                socketEmit('readNotifications', location.pathname);
                            }
                            if (!showNotificationCenter) {
                                setShowNotificationCenter(true);
                            }
                        }}
                    >
                        <FiBell color="fff" size="20" />
                        {totalPedings > 0 ? (
                            <label>
                                <a>{totalPedings}</a>
                            </label>
                        ) : (
                            <></>
                        )}
                    </div>
                    <FiUser
                        onClick={() => {
                            
                            document.getElementById("checkMenu").checked = false;
                            setMenuStatus(0);
                            setShowUser(!showUser);
                            setShowProfileConfig(false);
                            setShowNotificationCenter(false);
                        }}
                        color="fff"
                        size="20"
                        className="userIcon"
                    />
                </div>
            </div>
            <input
                type="checkbox"
                id="checkMenu"
                onClick={() => {
                    setMenuStatus(menuStatus == 0 ? 1 : 0);
                }}
                defaultChecked={menuStatus !== 1 ? false : true}
            />
            <div style={{zIndex:'100'}} className={menuStatus != 1 ? "barOverflow" : "bar"}>
                <label id="icon" htmlFor="checkMenu">
                    {menuStatus != 1 ? <FiChevronsRight size="30" /> : <FiChevronsLeft size="30" />}
                </label>
                {/* MENU FECHADO */}
                <div id="sideBarClose" className={menuStatus != 1 ? "setFlex" : "setNone"}>
                    
                    {menuOptions.map((option, index) => {
                        screenMaxHeight = screenMaxHeight + 25;
                        if (option.permission.indexOf(user.type) == -1 || (option.profilePermission !== undefined  && option.profilePermission.indexOf(user.profile_id) == -1 ) ) {
                            return;
                        }
                        optionRendered.push(index);
                        optionsInMenu.push(index);
                        if (screenY - screenMaxHeight > 150 || (screenY === "25rem" && 300 - screenMaxHeight > 150)) {
                            if (option.type === "list") {
                                if  (option.permission.indexOf(user.type) == -1 
                                    || (option.profilePermission !== undefined  && option.profilePermission.indexOf(user.profile_id) == -1)
                                    
                                    ) {
                                    return;
                                }
                                const selectedItemInMenu = option?.list?.filter((itemInMenuToSelect) => {
                                    if (itemInMenuToSelect?.to === location.pathname) return itemInMenuToSelect;
                                });
                                // console.log(option)
                                // console.log(user)
                                const permittedItem = option.list?.filter((isPermitted) => {
                                    
                                    if (isPermitted.permission.indexOf(user.type) >= 0 
                                    && ((isPermitted.profilePermission !== undefined  && isPermitted.profilePermission.indexOf(user.profile_id) !== -1) || isPermitted.profilePermission === undefined)
                                    && ((isLeaderB2C !== false && isPermitted.forLeaders === true) || (isPermitted.forLeaders === undefined))
                                    ) {
                                        return isPermitted;
                                    }
                                });
                                // pendings && pendings?.details.foreach(item =>
                                //     console.log('item', item)
                                // );
                                
                                var notificationsCount = 0;
                                option?.list?.map((menuItem) => {
                                    pendings?.details?.map((pendingsItem) => {
                                        if (
                                            menuItem.to === pendingsItem.frontend_path &&
                                            (pendingsItem.path_last_viewer < pendingsItem.created_at || pendingsItem.path_last_viewer == null)
                                            && (menuItem.name !== 'Indicar Clientes') // para nao aparecer contagem na indicar clientes (visao assessor)
                                            && (option.name !== 'Painel de Acompanhamento' || user.type !== 4)
                                            && (option.name !== 'Meus Clientes' || user.type !== 3)
                                        ) {
                                            notificationsCount++;
                                        }
                                    });
                                    return 0;
                                });
                                // console.log("opções", option.list);
                                // console.log("pendencias", pendings);

                                return (
                                    <>
                                        <div
                                            id="firstMenuDiv"
                                            key={option.name + " closed"}
                                            name={option.trigger}
                                            className={
                                                selectedItemInMenu[0]?.to === location.pathname ? `selectedItem` : "menuItem"
                                            }
                                        >
                                            
                                            {notificationsCount > 0 && (
                                                <div className="notchClosedMenu">{notificationsCount}</div>
                                            )}
                                            {option.icon}
                                            <div className="toolTipList">
                                                {permittedItem.map((item) => {
                                                    
                                                    return (
                                                        <>
                                                            <Link
                                                                to={item.to}
                                                                onClick={() => {
                                                                    
                                                                    selectItemMenuClosed(option.trigger);
                                                                    setMenuTitle(item.name);
                                                                    setShow(false);
                                                                    setShowUser(false);
                                                                    setShowProfileConfig(false);
                                                                    socketEmit("readNotifications", item.to);
                                                                    localStorage.setItem('lastVisitedPage', location.pathname);
                                                                }}
                                                            >
                                                                <div>{item.name}</div>
                                                            </Link>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </>
                                );
                            }

                            var notificationsCount1 = 0;
                            
                            pendings?.details?.map((pendingsItem) => {
                                if (
                                    option.to === pendingsItem.frontend_path &&
                                    (pendingsItem.path_last_viewer < pendingsItem.created_at || pendingsItem.path_last_viewer == null)
                                    && (option.name !== 'Meus Clientes' || user.type !== 3) // para nao aparecer a contagem no meus clientes na visão de broker
                                ) {
                                    notificationsCount1++;
                                }
                            });

                            return option.permission.indexOf(user.type) >= 0 ? (
                                <>
                                <div
                                    id="firstMenuDiv"
                                    className={
                                        menuSelected === option.to || location.pathname === option.to
                                        ? `selectedItem`
                                        : "menuItemNotList"
                                    }
                                    >
                                    {notificationsCount1 > 0 && <div className="notchClosedMenu">{notificationsCount1}</div>}
                                    <Link key={option.name + " closed"} className="linkMenu" to={option.to}>
                                        <span
                                            onClick={() => {
                                                setMenuTitle(option.name);
                                                socketEmit("readNotifications", option.to);
                                            }}
                                            >
                                            {option.icon}
                                        </span>
                                        <div onClick={() => {;setMenuTitle(option.name)}}>
                                            <div className="toolTipNormal">{option.name}</div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                            ) : (
                                <></>
                            );
                        } else {
                            optionRendered.splice(optionRendered.indexOf(index));
                        }
                    })}

                    {optionRendered.length < optionsInMenu.length && (
                        <div id="firstMenuDiv" className="multMenu">
                            <FiMoreHorizontal />
                            <div className="optionMultMenuItem">
                                {menuOptions.map((menuOptionNotRendered, index) => {
                                    if (menuOptionNotRendered.permission.indexOf(user.type) >= 0) {
                                        if (optionRendered.indexOf(index) === -1) {
                                            const permittedItemNotRendered = menuOptionNotRendered.list?.filter((isPermitted) => {
                                                if (isPermitted.permission.indexOf(user.type) >= 0
                                                && ((isPermitted.profilePermission !== undefined  && isPermitted.profilePermission.indexOf(user.profile_id) !== -1) || isPermitted.profilePermission === undefined)
                                                && ((isLeaderB2C !== false && isPermitted.forLeaders === true) || (isPermitted.forLeaders === undefined))) {
                                                    return isPermitted;
                                                }
                                            });

                                            if (menuOptionNotRendered.type === "list") {
                                                return (
                                                    <div className="multMenuFatherItems">
                                                        {menuOptionNotRendered.name}
                                                        <div className="mulMenuFatherItemsToolTip">
                                                            {permittedItemNotRendered.map((itemInListNotRendered) => {
                                                                return (
                                                                    <Link
                                                                        to={itemInListNotRendered.to}
                                                                        onClick={() => {
                                                                            selectItemMenuClosed(itemInListNotRendered.trigger);
                                                                            setMenuTitle(itemInListNotRendered.name);
                                                                            setShow(false);
                                                                            setShowUser(false);
                                                                            setShowProfileConfig(false);
                                                                            socketEmit("readNotifications", itemInListNotRendered.to);
                                                                            localStorage.setItem('lastVisitedPage', location.pathname);
                                                                        }}
                                                                    >
                                                                        <div>{itemInListNotRendered.name}</div>
                                                                    </Link>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                    }
                                })}
                            </div>
                        </div>
                    )}
                </div>

                {/* MENU ABERTO */}
                <nav id="sideBarOpen" className={menuStatus != 1 ? "setNone" : "setShow"}>
                    <div id="divLogoMenu">
                        <img src={logo} width="220" />
                    </div>
                    <br />
                    <div className="accordions">
                        {menuOptions.map((option) => {
                            if (option.permission.indexOf(user.type) == -1 || (option.profilePermission !== undefined  && option.profilePermission.indexOf(user.profile_id) == -1 && (isLeaderB2C === false)) ) {
                                return;
                            }
                            const permittedItem = option.list?.filter((isPermitted) => {
                                if (isPermitted.permission.indexOf(user.type) >= 0
                                    && ((isPermitted.profilePermission !== undefined  && isPermitted.profilePermission.indexOf(user.profile_id) !== -1) || isPermitted.profilePermission === undefined)
                                    && ((isLeaderB2C !== false && isPermitted.forLeaders === true) || (isPermitted.forLeaders === undefined))) {
                                    return isPermitted;
                                }
                            });
                            // console.log(option.name)
                            var notificationsCount = 0;
                            option?.list?.map((menuItem) => {
                                pendings?.details?.map((pendingsItem) => {
                                    // console.log(menuItem)
                                    if (
                                        menuItem.to === pendingsItem.frontend_path &&
                                        (pendingsItem.path_last_viewer < pendingsItem.created_at || pendingsItem.path_last_viewer == null) 
                                        && (option.name !== 'Meus Clientes' || user.type !== 3) // para nao aparecer a contagem no meus clientes na visão de broker
                                        && (menuItem.name !== 'Indicar Clientes') // para nao aparecer contagem na indicar clientes (visao assessor)
                                        && (option.name !== 'Painel de Acompanhamento' || user.type !== 4)
                                    ) {
                                        notificationsCount++;
                                    }
                                });
                                return 0;
                            });

                            if (option.type == "list") {
                                return (
                                    <>
                                        {notificationsCount > 0 && <div className="notch">{notificationsCount}</div>}
                                        <div className="accordion-item" key={option.name}>
                                            <input type="checkbox" name="accordion" id={option.trigger} />
                                            <label
                                                onClick={() => {;selectItemMenuOpen(option.trigger)}}
                                                className={
                                                    menuSelected == option.trigger ? "formatLabel selectedItem" : "formatLabel"
                                                }
                                                htmlFor={option.trigger}
                                            >
                                                <div> {option.icon} </div>
                                                <p>{option.name}</p>
                                                <FiChevronDown className="chevronIcon" />
                                            </label>
                                            {permittedItem.map((item) => {
                                                // console.log(permittedItem)
                                                var subNotificationsCount = 0;

                                                pendings?.details?.map((pendingsItem) => {
                                                    if (
                                                        item.to === pendingsItem.frontend_path &&
                                                        (pendingsItem.path_last_viewer < pendingsItem.created_at || pendingsItem.path_last_viewer == null)
                                                        && (item.name !== 'Indicar Clientes') // para nao aparecer contagem na indicar clientes (visao assessor)
                                                    ) {
                                                        subNotificationsCount++;
                                                    }
                                                });

                                                return (
                                                    <div
                                                        key={item.to}
                                                        className={
                                                            location.pathname == item.to
                                                                ? "accordion-content selectedItem"
                                                                : "accordion-content"
                                                        }
                                                    >
                                                        <Link
                                                            to={item.to}
                                                            onClick={() => {
                                                                
                                                                setMenuTitle(item.name);
                                                                setShow(false);
                                                                setShowUser(false);
                                                                setShowProfileConfig(false);
                                                                socketEmit("readNotifications", item.to)
                                                                localStorage.setItem('lastVisitedPage', location.pathname);
                                                            }}
                                                        >
                                                            <div className="containerItemMenu">
                                                                {item.name}
                                                                {subNotificationsCount > 0 && (
                                                                    <div className="subNotch">{subNotificationsCount}</div>
                                                                )}
                                                            </div>
                                                            {/* <div className='containerItemMenu'>{item.name}</div> */}
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                );
                            }
                            var notificationsCount1 = 0;
                            
                            pendings?.details?.map((pendingsItem) => {
                                if (
                                    option.to === pendingsItem.frontend_path &&
                                    (pendingsItem.path_last_viewer < pendingsItem.created_at || pendingsItem.path_last_viewer == null)
                                ) {
                                    notificationsCount1++;
                                }
                            });
                            
                            return option.permission.indexOf(user.type) >= 0 ? (
                                <>
                                    {notificationsCount1 > 0 && <div className="notch">{notificationsCount1}</div>}
                                    <div
                                        key={option.name}
                                        className={
                                            menuSelected === option.to || location.pathname === option.to
                                                ? `simple-item selectedItem`
                                                : "simple-item"
                                        }
                                        onClick={() => {
                                            
                                            selectItemMenuOpen(option.to);
                                            setMenuTitle(option.name);
                                            socketEmit("readNotifications", option.to)
                                            localStorage.setItem('lastVisitedPage', location.pathname);
                                        }}
                                    >
                                        <Link to={option.to}>
                                            <div>
                                                {option.icon}
                                                <p>{option.name}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <></>
                            );
                        })}
                    </div>  
                    <hr style={{ marginTop: "10%" }}></hr>
                    <div className="emailFeedback" style={{ textAlign: "center", marginTop: "10%" }}>
                        <div style={{ textAlignVertical: "bottom" }}>Dúvidas, problemas ou sugestões?</div>
                        <a
                            style={{ textAlignVertical: "bottom", color: "gold", textDecorationLine: "underline" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://app.pipefy.com/public/form/AeFrBJj1"
                        >
                            Clique Aqui!
                        </a>
                    </div>
                </nav>
            </div>
        </>
    );
}
