/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

import React, { useState, useEffect } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './styles/recommendationsImport.css';
/*Utils*/
import XLSX from 'xlsx';
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../utils/formatNumberAndText/formatExcelBooksToPreview';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../contexts/useModal';
/*Services*/
import { api } from '../../../services/api';
import { validyFiles } from '../../../utils/validyTypesOfFiles';
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import { FiArrowDown } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
}

export function LongShortModificationsImport() {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
    const [dataExcelAPI, setDataExcelAPI] = useState([]);
    const [excelRows, setExcelRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType, setEdited } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showFileButton, setShowFileButton] = useState(true);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('');
    const fixedDate = new Date();
    const { screenX, screenY } = ModalSizeFitToScreen();

    useEffect(() => {
        setShowFileButton(true);
        setShowErrors(false);
        setActiveStep(0);
        setExcelRows([]);
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);
    }, [show])

    const handleNext = async (propsNext) => {

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 1) {
           importBook(dataExcelAPI)
        }
    }

    async function importBook(dataFields) {

        setLoading(true);
        try {
            const importAPI = new Promise(async (resolve, reject) => {
                
                try {
                    const importResult = await api.post(`/api/v1/longShort/importOperationsListUpdate`, {
                        'data': 
                            dataFields.map((dataField) => {
                                let newDate = (dataField.created_at.split("/")[2]+"-"+dataField.created_at.split("/")[1]+"-"+dataField.created_at.split("/")[0]);
                                let newDate2 = (dataField.operation_date.split("/")[2]+"-"+dataField.operation_date.split("/")[1]+"-"+dataField.operation_date.split("/")[0]);
                                
                                return {
                                   'created_at': newDate,
                                   'asset_long': dataField.asset_long,
                                   'asset_short': dataField.asset_short,
                                   'operation_date': newDate2,
                                   'clients_external_id': parseInt(dataField.clients_external_id),
                                   'qtd_long': dataField.qtd_long,
                                   'value_long': dataField.value_long,
                                   'qtd_short': dataField.qtd_short,
                                   'value_short': dataField.value_short,
                                   'id': dataField.id
                                   }
                               })
                    },
                        {
                            headers: {
                                authorization: `Bearer ${token}`
                            }
                        }
                    )
                    console.log(importResult);
                    resolve(importResult);
                } catch (error) {
                   console.log(error);
                    reject(error)
                }
            })

            let inProcessLoading = true;
            let timeCount = 0

            do {
                setHideButtons(true);
                if (timeCount == 5) {
                    setImportPlainMessage(`A planilha está em processo de importação.`)
                } else if (timeCount < 10) {
                    setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                } else if (timeCount < 15) {
                    setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                }
                importAPI.then((value) => {
                    inProcessLoading = false;
                    setHideButtons(false);
                    setLoading(false);
                    setSuccessImport(true);
                    if (value?.data?.errors_details?.length > 0) {
                        setErrorsImport(true);
                        setErrorsList(value?.data?.errors_details);
                    } else {
                        setType({ name: 'alertMsg', type: 'information', value: { message: 'Registros importados com sucesso!' } })
                    }
                    setEdited(true)
                }).catch((error) => {
                    inProcessLoading = false;
                    if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                        setErrorsList([{ error_message: error?.response?.data?.message }]);
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsImport(true);
                    } else {
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsList(error?.response?.data?.errors_details);
                        setErrorsImport(true);
                    }
                })
                await new Promise(resolve => {
                    setTimeout(() => {
                        timeCount += 1
                        resolve()
                        if (timeCount == 20) timeCount = 0
                    }, 1000)
                })
            } while (inProcessLoading == true);
        } catch (error) {
            alert('Erro inesperado.')
            setLoading(false);
        }
    }

   // FUNÇÕES PARA FORMATAR SERIAL NUMBER EXCEL EM DATA
   function excelDateToJSDate(excelDate) {
       var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
       return date;
   }
   function padTo2Digits(num) {
       return num.toString().padStart(2, '0');
   }
   function formatDate(date) {
       return [
           padTo2Digits(date.getDate()),
           padTo2Digits(date.getMonth() + 1),
           date.getFullYear(),
       ].join('/');
   }
   // ==================================================

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                const schemaToValidate = [
                   { excelValue: 'ID OPERAÇÃO', bdValue: 'id' },
                   { excelValue: 'DATA LONG SHORT', bdValue: 'operation_date' },
                   { excelValue: 'ATIVO LONG', bdValue: 'asset_long' },
                   { excelValue: 'ATIVO SHORT', bdValue: 'asset_short' },
                   { excelValue: 'DATA OPERAÇÃO', bdValue: 'created_at' },
                   { excelValue: 'CÓDIGO CLIENTE', bdValue: 'clients_external_id' },
                   { excelValue: 'QTD LONG', bdValue: 'qtd_long' },
                   { excelValue: 'PREÇO LONG', bdValue: 'value_long' },
                   { excelValue: 'QTD SHORT', bdValue: 'qtd_short' },
                   { excelValue: 'PREÇO SHORT', bdValue: 'value_short' }
                ];

                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {

                    if (fieldToValidate !== schemaToValidate[index]?.excelValue) {
                        return fieldToValidate
                    }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setFilename("")
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['id','operation_date','asset_long','asset_short','created_at','clients_external_id','qtd_long','value_long','qtd_short','value_short'],
                        cellDates: false
                    });
                    resolve(data)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })
        
        const data = await promise
        data.splice(0, 1)

        let newFormattedData = []
        data.map((data) => {

            let newTargetValue = data.target_value

            if (typeof (data.target_value) === 'string') {
                newTargetValue = parseFloat(data.target_value.replace(',', '.'))
            }

            if(typeof(data.operation_date) != 'string'){
               data.operation_date = formatDate(excelDateToJSDate(data.operation_date))
            }

            if(typeof(data.created_at) != 'string'){
                data.created_at = formatDate(excelDateToJSDate(data.created_at))
             }

            newFormattedData.push({
                'id' : data.id,
                'operation_date' : data.operation_date,
                'asset_long' : data.asset_long,
                'asset_short' : data.asset_short,
                'created_at' : data.created_at,
                'clients_external_id' : data.clients_external_id,
                'qtd_long' : data.qtd_long,
                'value_long' : data.value_long,
                'qtd_short' : data.qtd_short,
                'value_short' : data.value_short
                
            })
        })
        // até aqui tudo certo
        console.log(newFormattedData);
        setExcelRows(newFormattedData);
        setDataExcelAPI(newFormattedData);
        return setLoading(false);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSuccessImport(false);
        setActiveStep(0);
    };

    function handleFileCheck() {
        if (excelRows.length > 0) {
            handleNext()
            setShowFileButton(false);
        }
        else {
            alert('Carregue a planilha antes')
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function formatLineToApi(value, type) {

        switch (type) {
            case 'number':
                return parseFloat(value)
            default:
                return value
        }
    }

    function tableForImportReccords() {
        return (
            excelRows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                       <tr>
                       <th>ID OPERAÇÃO</th>
                       <th>DATA LONG SHORT</th>
                       <th>ATIVO LONG</th>
                       <th>ATIVO SHORT</th>
                       <th>DATA OPERAÇÃO</th>
                       <th>CÓDIGO CLIENTE</th>
                       <th>QTD LONG</th>
                       <th>PREÇO LONG</th>
                       <th>QTD SHORT</th>
                       <th>PREÇO SHORT</th>
                       </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {excelRows?.map((excelLine, indexLine) => {
                            const fieldKeys = Object.keys(excelLine)
                            return (
                                <tr>
                                   <td>{excelLine.id}</td>
                                   <td>{excelLine.operation_date}</td>
                                   <td>{excelLine.asset_long}</td>
                                   <td>{excelLine.asset_short}</td>
                                   <td>{excelLine.created_at}</td>
                                   <td>{excelLine.clients_external_id}</td>
                                   <td>{excelLine.qtd_long}</td>
                                   <td>{excelLine.value_long}</td>
                                   <td>{excelLine.qtd_short}</td>
                                   <td>{excelLine.value_short}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)
        )
    }

    function errorsTable() {
        if (errorList?.length > 0 && errorList[0].error_message !== undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error_message}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

        if (errorList?.length > 0 && errorList[0].error_message === undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    {errorDetail["error"]}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }


    }

    async function getLayout() {

       try {
           const getLayouts = await api.get('/api/v1/longShort/layoutSpreadsheetUpdate', {
               headers: {
                   authorization: `Bearer ${token}`
               },
               responseType: 'blob'
           })

           const url = URL.createObjectURL(new Blob([getLayouts.data]))
           const link = document.createElement('a')
           link.href = url
           link.setAttribute('download', 'Layout Acompanhamento Long Short.xls')
           document.body.appendChild(link)
           link.click()
       } catch (error) {
           console.log(error?.response)
           alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
       }
   }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    {/* <button className='modalCloseButton' onClick={() => setShow(false)}>X</button> */}
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                {showFileButton ?
                                    (
                                        <>
                                            <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                            <button className='customButton' onClick={() => getLayout()}>Baixar layout <FiArrowDown /> </button>
                                            <a>{fileName.substring(12, fileName.length)}</a>            
                                        </>
                                    ) :
                                    (<></>)}
                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                       //  Verifica se o arquivo é um excel válido
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                <a>Importando Atualizações Long Short</a>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                    successImport && !errorsImport ?
                        (
                            <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                <a>Sucesso ao Importar as Atualizações!</a>
                            </div>
                        ) :

                        activeStep === 1 ? (
                            (<>
                                <p id='recommendationsImportConfirm'>Confira os dados antes de importar.</p>
                                <div id='customScroll' className='tableForImportSection'>
                                    {
                                        tableForImportReccords()
                                    } </div>
                            </>)
                        ) :
                            successImport && errorsImport ? (
                                <div id='flexRowDiv' className='stepperImportWithErrors'>
                                    <a>Sucesso ao importar arquivos,</a>
                                    <a>mas ocorreram erros durante a importação.</a>
                                    <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                </div>
                            ) : !successImport && errorsImport ?
                                (
                                    <div id='flexRowDiv' className='stepperImportWithErrors'>
                                        <a>Ocorreram Erros na Importação</a>
                                        <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                    </div>
                                ) :
                                showErrors ?
                                    (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                    :
                                    (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='flexRowDiv'>
                                <Typography className={classes.instructions}>Importado Com Sucesso! Você já pode sair desta página.</Typography>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='flexRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='flexRowDiv'>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}