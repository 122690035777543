import React, { useEffect, useState } from 'react';
/*Styles*/
import '../styles/recomendations.table.css';
import {FiArrowDown, FiCopy } from 'react-icons/fi';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal'
/*Services*/
import { api } from '../../../../services/api'
import { getAssetsList } from '../../../../services/asyncFunctions'
import { getAssetsRecommendations } from '../../../../services/asyncFunctions'
/*Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency'

export function RecomendationsTable() {

    const [loading, setLoading] = useState(false)
    const [assetsRecommendationsList, setAssetsRecommendationsList] = useState([])
    const [assetsList, setAssetsList] = useState([])
    const [assetsRecommendationsFormatted, setAssetsRecommendationsFormatted] = useState([])
    const { tokenKey, user } = useAuth()
    const { type, setType, show, setShow, edited, setEdited } = useModal()
    const { screenX, screenY } = ModalSizeFitToScreen()

    async function getRecommendations() {

        const listRecommendations = await getAssetsRecommendations(tokenKey)
        if (getRecommendations?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: getRecommendations?.error
                }
            })
            setShow(true);
        } else {
            setAssetsRecommendationsList(listRecommendations);
            setLoading(false)
        }
    }

    async function getAssets() {

        const listAssets = await getAssetsList(tokenKey)
        if (getAssetsList?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: getAssetsList?.error
                }
            })
            setShow(true);
        } else {

            setAssetsList(listAssets)
        }
    }

    async function recomendationsExport() {
        setLoading(true);
        try {
            const exportAssetsResult = await api.get(`/api/v1/assetsRecommendations/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
            console.log(exportAssetsResult)
    
            const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_recomendações.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)
    
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function formateAssets() {

        let recommendationsListFormatted = []

        assetsRecommendationsList.map((recommendation) => {
            assetsList.filter((asset) => {
                if (asset.asset === recommendation.asset) {
                    recommendationsListFormatted.push({
                        asset: recommendation.asset,
                        company_name: asset.company_name,
                        asset_sector: asset.asset_sector_description,
                        value: asset.value,
                        operation_type_recommendation: recommendation.operation_type_recommendation,
                        target_value: recommendation.target_value,
                        upside: recommendation.upside,
                        analyze: recommendation.analyze
                    })
                }
            })
        })

        setAssetsRecommendationsFormatted(recommendationsListFormatted)
        setLoading(false)
    }

    useEffect(() => {

        if (edited || assetsRecommendationsList.length === 0) {
            setEdited(false)
            setLoading(true)
            getRecommendations()
            getAssets()
        }

    }, [edited])

    useEffect(() => {

        if (assetsList.length > 0 && assetsRecommendationsList) {
            formateAssets()
        }
    }, [assetsList])
    console.log(assetsRecommendationsFormatted)
    return (
        <div className="recomendationsTable">
            <div className='recomendationsTableArea' style={{
                maxHeight: screenY
            }}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='8'>
                                <div>
                                    <p>Recomendações</p>
                                    <div className="recomendationsHeaderRight">
                                    <button
                                            className='buttonTableThead'
                                            onClick={() => {
                                                const output = [];
                                                const titles = ['Ativo','Nome', 'Setor', 'Cotação', 'Recomendação', 'Preço Alvo', 'Upside', 'Análise']
                                                output.push(titles.join('\t'))
                                                const keys = ['asset', 'company_name','asset_sector', 'value', 'operation_type_recommendation', 'target_value','upside', 'analyze'];
                                                assetsRecommendationsFormatted.forEach((x) => {
                                                    const tmp = keys.map((y) => {return y==='value' || y==='target_value' ? String(x[y]).replace('.',',') : x[y]});
                                                    output.push(tmp.join('\t'))
                                                })
                                                const table = output.join('\n')
                                                navigator.clipboard.writeText(table)
                                                alert('Conteúdo da tabela copiado para a área de transferência')
                                            }}
                                        ><FiCopy />Copiar</button>
                                            <button 
                                                className='buttonTableThead'
                                                onClick={() => {
                                                recomendationsExport(tokenKey);
                                            }}><FiArrowDown />Exportar</button>
                                        {
                                            user?.type < 3 &&
                                            <button className='buttonTableThead' onClick={() => {
                                                setType({ name: 'importRecommendations' })
                                                setShow(true)
                                            }}>+ Importar</button>
                                        }

                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>Ativo</th>
                            <th>Nome</th>
                            <th>Setor</th>
                            <th>Cotação</th>
                            <th>Recomendação</th>
                            <th>Preço Alvo</th>
                            <th>Upside</th>
                            <th>Análise</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                (
                                    <tr className='recommendationsTableLoader'>
                                        <td colSpan='8'><ArrowLoader /></td>
                                    </tr>
                                ) :
                                assetsRecommendationsFormatted.length > 0 ?
                                    (
                                        assetsRecommendationsFormatted.map((recommendation) => {
                                            return <tr>
                                                <td>{recommendation.asset}</td>
                                                <td>{recommendation.company_name}</td>
                                                <td>{recommendation.asset_sector}</td>
                                                <td>{toBRL(recommendation.value)}</td>
                                                <td>{recommendation.operation_type_recommendation}</td>
                                                <td>{toBRL(recommendation.target_value)}</td>
                                                <td>{recommendation.upside}</td>
                                                <td>{recommendation.analyze}</td>
                                            </tr>
                                        })
                                    )
                                    :
                                    (
                                        <tr>
                                            <td colSpan='9'>--Lista Vazia--</td>
                                        </tr>
                                    )
                        }
                    </tbody>
                </table>
            </div>

        </div>
    )
}