import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
import { useSocket } from "../../../../contexts/useSocket";
/*Services*/
import { api } from '../../../../services/api';
import { getEstructuredOperationsAll } from '../../../../services/asyncFunctions';
import { getOperationsStatus,updateMyStruturedsStatus } from '../../../../services/asyncFunctions';
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { TableCell } from "@material-ui/core";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import CustomToolbarSelect from "./toolbar/customSelectedToolbarMyReservation"; 

export function MyStructuredTableMUI() {
    const { reloadBookOrders, setReloadBookOrders, emitForEveryOne, setEmitForEveryOne } = useSocket()
    const now = new Date()
    const [loading, setLoading] = useState(true);
    const { tokenKey, userType, user } = useAuth()
    const [showMessage, setShowMessage] = useState(false)
    const [hideMessage, setHideMessage] = useState(false)
    const [listStructured, setlistStructured] = useState([]);
    const [listDeletion, setListDeletion] = useState([]);
    const [listStructuredFormatted, setlistStructuredFormatted] = useState([]);
    const { setType, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [arrayKeys, setArrayKeys] = useState([]);
    
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOperations, setSelectedOperations] = useState([])
    const [operations, setOperations] = useState(0);
    const [netSum, setNetSum] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [isOperationsCurrentDay, setIsOperationsCurrentDay] = useState(true)
    const [operationsStatus, setOperationsStatus] = useState([]);
    const [statusOrderSelected,setStatusOrderSelected] = useState(-1)

    // const [monthInit, setMonthInit] = useState(new Date().getMonth() + 1);

    async function getStatusoperations() {
        const operationsStatusAux = await getOperationsStatus(tokenKey)
        setOperationsStatus(operationsStatusAux?.data)
        setLoading(false);
    }

    const getStructuredOp = async (month, year, today = 0) => {
        
        setLoading(true);
        try {
            const listStructured = await getEstructuredOperationsAll(user.external_id, tokenKey, month, year, today)
            if (getEstructuredOperationsAll?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: getEstructuredOperationsAll?.error,
                    },
                });
                setShow(true);
            } else {
                setlistStructured(listStructured?.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    function formateAssets() {
        let structuredFormatted = [];
        listStructured.map((structured) => {
            structuredFormatted.push({
                user_full_name: structured.user_full_name,
                clients_external_id: structured.clients_external_id,
                clients_name: structured.clients_name,
                book_name: structured.book_name,
                operations_types_description: structured.operations_types_description,
                asset: structured.asset,
                quantity: structured.quantity,
                net: structured.quantity * structured.value,
                comission: structured.comission,
                created_at: structured.created_at,
                expiration: structured.expiration,
                operations_status_id: structured.operations_status_id[1],
                book_order_id: structured.book_order_id,
                user_external_id: structured.user_external_id,
                'Ganho_máximo_(%)': structured['Ganho_máximo_(%)'],
                'Prejuízo_máximo_(%)': structured['Prejuízo_máximo_(%)'],
                Empresa: structured.Empresa,
                'Recomendado': structured['Recomendado'],
                'Breakeven_(%)': structured['Breakeven_(%)'],
                'FEE': structured['FEE']
            });
        });

        setlistStructuredFormatted(structuredFormatted);
        setLoading(false);
    }

    const HandleDeleteRow = (e) => {
        const listDeletion = [];
        e.data.forEach(x => {
            listDeletion.push(parseInt(listStructuredFormatted[x.dataIndex].book_order_id))
        });
        setListDeletion(listDeletion);
        setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir todas as ordens selecionadas?', func: handleDelete, dataForFunc: listDeletion } })
        setShow(true);
}

    const updateSumHeader = (e) => {
        let newSumNet = 0;
        let newSumComission = 0;
        e.forEach(x => {
            newSumNet += parseFloat(listStructuredFormatted[x.dataIndex].net)
            newSumComission += parseFloat(listStructuredFormatted[x.dataIndex].comission)
        });
        setNetSum(newSumNet);
        setRevenue(newSumComission);
    }

    const handleDelete = async (data) => {
        setLoading(true)
        try {
            const deleteOrders = await api.delete(`/api/v1/booksOrders`, {

                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                data: {
                    "orders": data
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Ordens excluidas com sucesso!' } })
            setShow(true)
            setEdited(true)
            setSelectedOperations([])
        } catch (error) {

            if (error?.response?.data?.details?.length > 0) {
                setType({
                    name: 'showMyStructuredTableErrors', value: {
                        errors: error?.response?.data?.details
                    }
                })
            } else {
                setType({
                    name: 'alertMsg', type: 'information', value: {
                        message: error?.response?.data?.message === undefined ? 'Erro ao remover Ordens' : error?.response?.data?.message
                    }
                })
            }
            setShow(true)
            setSelectedOperations([])
            setLoading(false)
            setShowOptions(false)
            setShow(false) // remover depois
        }
    }

    useEffect(() => {
        if (edited || listStructured?.length === 0) {
            setEdited(false);
            setLoading(true);
            getStatusoperations();
            getStructuredOp(new Date().getMonth() + 1, new Date().getFullYear(),1);
        }
    }, [edited]);

    useEffect(() => {
        formateAssets();
    }, [listStructured]);

    useEffect(() => {
        var netSumHead = 0;
        var revHead = 0;
        listStructuredFormatted.forEach((e) => {
            netSumHead += parseFloat(e.net);
            revHead += parseFloat(e.comission);
        })

        setNetSum(netSumHead.toFixed(2));
        setRevenue(revHead.toFixed(2));
    }, [listStructuredFormatted]);
    
    
    function copyToClipboard() {
        const output = [];
        const titles = [
            "ID",
            "Operação",
            "Quantidade",
            "Financeiro",
            "Comissão",
            "Data Operação",
            "Ação",
            "Operado por",
            "Cód Cliente",
            "Cliente",
            "Status",
            "Ativo",
            "Recomendado",
            "Empresa",
            "Vencimento",
            "Ganho_máximo_(%)",
            "Prejuízo_máximo_(%)",
            "Breakeven_(%)",
            "FEE"
        ];
        output.push(titles.join("\t"));
        
        let arrKeys = (Object.keys(listStructured[0]).toString().split(','));

        const keys = [
            "book_order_id",
            "book_name",
            "quantity",
            "net",
            "comission",
            "created_at",
            "operations_types_description",
            "user_full_name",
            "clients_external_id",
            "clients_name",
            "operations_status_id",
            "asset",
            "Recomendado",
            "Empresa",
            "expiration",
            "Ganho_máximo_(%)",
            "Prejuízo_máximo_(%)",
            "Breakeven_(%)",
            "FEE"
        ];
        listStructuredFormatted.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net" | y === "comission" | y === "Ganho_máximo_(%)" | y === "Prejuízo_máximo_(%)" | y === "Breakeven_(%)" | y === "FEE"
                    ? String(x[y]).replace(".", ",") : y === "created_at" | y === "expiration"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") : x[y]
            });
            output.push(tmp.join("\t"));

        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);

        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function assetStructuredExport() {
        setLoading(true);
        try {
            const myExport = await getEstructuredOperationsAll(user.external_id, tokenKey, new Date().getMonth() + 1, new Date().getFullYear(), 0, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "Operações Estruturadas.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    async function notifyUser(all) {
        setShowMessage(all ? { notify: true, allUsers: true } : { notify: true, allUsers: false })
        await new Promise(resolve => setTimeout(resolve, 300))
        setHideMessage(false)
    }

    async function hideNotification() {

        setHideMessage(true)
        await new Promise(resolve => setTimeout(resolve, 3000))
        setShowMessage(false)
    }

    async function updateExcel() {
        setType({ name: 'updateOrdersStatus' })
        setShow(true)
    }

    async function handleOrderStatusUpdate(e) {
        if(statusOrderSelected != -1){

            const listUpdateStatus = [];
            e.data.forEach(x => {
                listUpdateStatus.push(parseInt(listStructuredFormatted[x.dataIndex].book_order_id))
            });

            try {
                const res = await updateMyStruturedsStatus(tokenKey, listUpdateStatus, statusOrderSelected);

                setType({ name: 'alertMsg', type: 'information', value: { message: 'Status atualizado(s) com sucesso' }})
                setShow(true)
                setEdited(true)
            } catch (error) {
                setLoading(false);
                setType({ name: "alertMsg", type: "information", value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status da ordem" : error?.response?.data?.message } });
                setShow(true);
            }
        }
        setStatusOrderSelected(-1)
        setSelectedOperations([])
    }

    useEffect(() => {

        const month = now.getMonth()
        const year = now.getFullYear()
        if (emitForEveryOne && reloadBookOrders) {
            notifyUser(true)
            setReloadBookOrders(false)
            setEmitForEveryOne(false)
        }
        else if (!emitForEveryOne && reloadBookOrders) {
            notifyUser(false)
            getStructuredOp(new Date().getMonth() + 1, new Date().getFullYear(), 1)
            setReloadBookOrders(false)
        }
    }, [reloadBookOrders])

    const columns = [
        {
            name: "user_full_name",
            label: "Operado Por",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "clients_external_id",
            label: "Código Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "clients_name",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "book_name",
            label: "Operação",
            options: {
                filter: true,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "operations_types_description",
            label: "Ação",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "asset",
            label: "Ativo",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "quantity",
            label: "Quantidade",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "net",
            label: "FINANCEIRO",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => {
                    return (
                      <span>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </span>
                    );
                },
            customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                <TableCell
                    className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                    onClick={() => {
                        sortColumn(index);
                    }}>
                    <div className="textSumCell" style={{ marginTop : 0 }}>
                        <div>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                        </div>
                        <p>{toBRL(parseFloat(netSum))}</p>
                    </div>
                </TableCell>
            ),
        }
        },
        {
            name: "comission",
            label: "COMISSÃO",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => {
                    return (
                      <p>
                        {toBRL(value)}
                        {/* {tableMeta} */}
                      </p>
                    );
                },
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell" style={{ marginTop : 0 }}>
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(parseFloat(revenue))}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "created_at",
            label: "Data Operação",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "expiration",
            label: "Vencimento",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "operations_status_id",
            label: "Status",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                })
            },
        }
    ];

    async function showModalStructuredHistory() {
        setShow(true);
        setType({ 
        name: "showStructuredHistory",
        value: {
            data: getStructuredOp }
        }
        );
    }

    async function showModalStructuredDaily() {
        setType({ 
        name: "showStructuredDaily",
        value: {
            data: getStructuredOp(new Date().getMonth() + 1, new Date().getFullYear()) }
        }
        );
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            {/* <Tooltip disableFocusListener title="Mostrar Colunas">
                <IconButton onClick={() => { showModalShowColumns()}}>
                    <span style={{fontSize: "1.4rem"}}>Personalizar Colunas</span>
                </IconButton>
            </Tooltip> */}

            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Selecione o período de operações desejado.">
                <IconButton
                    onClick={() => {
                        showModalStructuredHistory();
                        setIsOperationsCurrentDay(false)
                    }}>
                    <span style={{ fontSize: "1.4rem"}}>Buscar Histórico</span>
                </IconButton>
            </Tooltip>
            <Tooltip disableFocusListener title="Visualizar as operações solicitadas hoje.">
                <IconButton
                    onClick={() => {
                        getStructuredOp(new Date().getMonth() + 1, new Date().getFullYear(), 1)
                        setIsOperationsCurrentDay(true)
                    }}>
                    <span style={{ fontSize: "1.4rem" }}>Operações do Dia</span>
                </IconButton>
            </Tooltip>
            { user.type < 3 ?
            <Tooltip disableFocusListener title="Atualize em massa os status das operações.">
                <IconButton
                    onClick={() => {
                        updateExcel();
                    }}>
                    <span style={{ fontSize: "1.4rem" }}>Atualizar via Excel</span>
                </IconButton>
            </Tooltip> : null
            }
        </>
    );

    const options = {
        filterType: "multiselect",
        selectableRows: "multiple",
        selectableRowsOnClick: true,
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "60vh",
        onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            setSelectedOperations(rowsSelected);
        },
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: false,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações no histórico.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_estruturadas.csv",
            separator: ";",
        },
        onDownload: () => {
            assetStructuredExport();
            return false;
        },
        onFilterChange: (a,b,c,d,e) => {
        updateSumHeader(e);
        },

        sortOrder: {
            name: 'broker_name',
            direction: 'asc'
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelect
                selectedRows={selectedRows}
                optionsStatusOrder={operationsStatus}
                setStatusOrderSelected={setStatusOrderSelected}
                sendUpdate={handleOrderStatusUpdate}
                delete={HandleDeleteRow}
            />
          ),
        customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        onRowsDelete: (e) => HandleDeleteRow(e),
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [30, 100, 1000],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                    // textAlign: 'center'
                }
            }
        },
      },
    })

    return loading ? (
        <div style={{ width : '100%' }}>
            <div className="divLoading">
                <ArrowLoader />
            </div>
        </div>
    ) : (
        <div>
            {
                    showMessage?.allUsers && showMessage?.notify ?
                        <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                            <p>Ocorreram atualizações em massa efetuadas pelo backoffice</p>
                            <button onClick={() => hideNotification()}>X</button>
                        </div>
                        :
                        !showMessage?.allUsers && showMessage?.notify &&
                        <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                            <p>Alterações realizadas</p>
                            <button onClick={() => hideNotification()}>X</button>
                        </div>

                }
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={`Operações Estruturadas ${isOperationsCurrentDay == true ? ' - Operações do dia' : ' - Histórico'}`}
                    data={listStructuredFormatted}
                    columns={columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

