/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

 import React, { useState, useEffect } from 'react';
 /*Styles*/
 import { makeStyles } from '@material-ui/core/styles';
 import Step from '@material-ui/core/Step';
 import StepLabel from '@material-ui/core/StepLabel';
 import Button from '@material-ui/core/Button';
 import Typography from '@material-ui/core/Typography';
 import './styles/recommendationsImport.css';
 /*Utils*/
 import XLSX from 'xlsx';
 import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
 import { SerialDateToJSDate } from '../../../utils/formatDate/dateSerialToISO_EnUs';
 import { formatLineToTablePreview } from '../../../utils/formatNumberAndText/formatExcelBooksToPreview';
 /*Components*/
 import DatePicker from 'react-date-picker';
 import Stepper from '@material-ui/core/Stepper';
 import { ArrowLoader } from '../../../components/loaders/arrowLoader';
 /*Contexts*/
 import { useModal } from '../../../contexts/useModal';
 /*Services*/
 import { api } from '../../../services/api';
 import { validyFiles } from '../../../utils/validyTypesOfFiles';
 import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
 import { FiArrowDown } from 'react-icons/fi';

 const useStyles = makeStyles((theme) => ({
     root: {
         "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
             background: 'red'
         },
         fontFamily: 'Roboto',
         width: '100%',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         fontWeight: '400'
     },
 
     stepperItems: {
         display: 'flex',
         padding: '0.5rem',
         flexDirection: 'row',
         alignItems: 'center',
         justifyContent: 'center',
         width: '100%',
     },
 
     '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center'
     },
     backButton: {
         marginRight: theme.spacing(1),
     },
     instructions: {
         // marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1),
         fontFamily: 'Roboto',
         fontSize: '0.9rem !important'
     },
 }));
 
 function getStepContent(stepIndex) {
     switch (stepIndex) {
         case 0:
             return 'Selecione a planilha no botão acima';
         default:
             return '';
     }
 }
 
 export function PayrollImport() {
 
     const classes = useStyles();
     const [activeStep, setActiveStep] = React.useState(0);
     const [fileName, setFilename] = useState('');
     const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
     const [dataExcelAPI, setDataExcelAPI] = useState([]);
     const [excelRows, setExcelRows] = useState([]);
     const [errorList, setErrorsList] = useState([]);
     const [successImport, setSuccessImport] = useState(false);
     const [hideButtons, setHideButtons] = useState(false);
     const [loading, setLoading] = useState(false);
     const { show, setShow, setType, setEdited } = useModal();
     const token = localStorage.getItem('@auth:token');
     const [errorsImport, setErrorsImport] = useState(false);
     const [showErrors, setShowErrors] = useState(false);
     const [showFileButton, setShowFileButton] = useState(true);
     const [importPlainMessage, setImportPlainMessage] = useState('');
     const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('');
     const fixedDate = new Date();
     const { screenX, screenY } = ModalSizeFitToScreen();
 
     useEffect(() => {
         setShowFileButton(true);
         setShowErrors(false);
         setActiveStep(0);
         setExcelRows([]);
         setFilename('');
         setSuccessImport(false);
         setErrorsImport(false);
     }, [show])
 
     const handleNext = async (propsNext) => {
 
         setActiveStep((prevActiveStep) => prevActiveStep + 1);
         if (activeStep === 1) {
            importBook(dataExcelAPI)
         }
     }
 
     async function importBook(dataFields) {
 
         setLoading(true);
         try {
             const importAPI = new Promise(async (resolve, reject) => {
                 try {
                     const importResult = await api.post(`/api/v1/payroll/import`, {
                         'data': 
                             dataFields.map((dataField) => {
                                 return {
                                    'payroll_date': dataField.payroll_date.split("/")[2]+"-"+dataField.payroll_date.split("/")[1]+"-"+dataField.payroll_date.split("/")[0],
                                    'client_id': dataField.client_id,
                                    'advisor_id': dataField.advisor_id,
                                    'broker_id': dataField.broker_id,
                                    'category': dataField.category,
                                    'level_1': dataField.level_1,
                                    'level_2': dataField.level_2,
                                    'level_3': dataField.level_3,
                                    'stock_product': dataField.stock_product,
                                    'payout': dataField.payout,
                                    'stock_category': dataField.stock_category,
                                    'stock_access': dataField.stock_access,
                                    'tax_rate': dataField.tax_rate,
                                    'liquid_income': dataField.liquid_income,
                                    'safe_reservation': dataField.safe_reservation,
                                    'broker_funding': dataField.broker_funding,
                                    'broker_liquid': dataField.broker_liquid
                                    }
                                })
                     },
                         {
                             headers: {
                                 authorization: `Bearer ${token}`
                             }
                         }
                     )
                    //  console.log(importResult);
                     resolve(importResult);
                 } catch (error) {
                    console.log(error);
                     reject(error)
                 }
             })
 
             let inProcessLoading = true;
             let timeCount = 0
 
             do {
                 setHideButtons(true);
                 if (timeCount == 5) {
                     setImportPlainMessage(`A planilha está em processo de importação.`)
                 } else if (timeCount < 10) {
                     setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                 } else if (timeCount < 15) {
                     setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                 }
                 importAPI.then((value) => {
                     inProcessLoading = false;
                     setHideButtons(false);
                     setLoading(false);
                     setSuccessImport(true);
                     if (value?.data?.errors_details?.length > 0) {
                         setErrorsImport(true);
                         setErrorsList(value?.data?.errors_details);
                     } else {
                         setType({ name: 'alertMsg', type: 'information', value: { message: 'Registros importados com sucesso!' } })
                     }
                     setEdited(true)
                 }).catch((error) => {
                     inProcessLoading = false;
                     if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                         setErrorsList([{ error_message: error?.response?.data?.message }]);
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsImport(true);
                     } else {
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsList(error?.response?.data?.errors_details);
                         setErrorsImport(true);
                     }
                 })
                 await new Promise(resolve => {
                     setTimeout(() => {
                         timeCount += 1
                         resolve()
                         if (timeCount == 20) timeCount = 0
                     }, 1000)
                 })
             } while (inProcessLoading == true);
         } catch (error) {
             alert('Erro inesperado.')
             setLoading(false);
         }
     }

    // FUNÇÕES PARA FORMATAR SERIAL NUMBER EXCEL EM DATA
    function excelDateToJSDate(excelDate) {
        var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
        return date;
    }
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    function formatDate(date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }
    // ==================================================

     async function readExcelFile(file) {
 
         setLoading(true);

         const promise = new Promise((resolve, reject) => {
             const fileReader = new FileReader();
             fileReader.readAsArrayBuffer(file);
 
             fileReader.onload = (e) => {
                 const bufferArray = e.target.result;
                 const wb = XLSX.read(bufferArray, { type: 'buffer' });
                 const wsname = wb.SheetNames[0];
                 const ws = wb.Sheets[wsname];
                 const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                 const schemaToValidate = [
                    { excelValue: 'Data', bdValue: 'payroll_date' },
                    { excelValue: 'CÓDIGO CLIENTE', bdValue: 'client_id' },
                    { excelValue: 'CÓDIGO ASSESSOR', bdValue: 'advisor_id' },
                    { excelValue: 'CÓDIGO BROKER', bdValue: 'broker_id' },
                    { excelValue: 'Produto/Categoria', bdValue: 'category' },
                    { excelValue: 'Nível 1', bdValue: 'level_1' },
                    { excelValue: 'Nível 2', bdValue: 'level_2' },
                    { excelValue: 'Nível 3', bdValue: 'level_3' },
                    { excelValue: 'Produto Mesa RV', bdValue: 'stock_product' },
                    { excelValue: 'Comissão (R$) Escritório', bdValue: 'payout' },
                    { excelValue: 'CATEGORIA MESA RV', bdValue: 'stock_category' },
                    { excelValue: 'CANAL MESA', bdValue: 'stock_access' },
                    { excelValue: 'ALÍQUOTA IR', bdValue: 'tax_rate' },
                    { excelValue: 'Receita Total - LIQ', bdValue: 'liquid_income' },
                    { excelValue: 'RESERVA CONTA ERRO 1,5%', bdValue: 'safe_reservation' },
                    { excelValue: 'REPASSE MESA LIQ', bdValue: 'broker_funding' },
                    { excelValue: 'REPASSE BROKER LIQ', bdValue: 'broker_liquid' }
                 ];
 
                 const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
 
                     if (fieldToValidate !== schemaToValidate[index]?.excelValue) {
                         return fieldToValidate
                     }
                 })
 
                 if (errors?.length > 0) {
                     alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                     setFilename("")
                     setLoading(false);
                     return
                 }
                 else {
                     const data = XLSX.utils.sheet_to_json(ws, {
                         header: ['payroll_date','client_id','advisor_id','broker_id','category','level_1','level_2','level_3','stock_product','payout','stock_category','stock_access','tax_rate','liquid_income','safe_reservation','broker_funding','broker_liquid'],
                         cellDates: false
                     });
                     resolve(data)
                 }
             }
             fileReader.onerror = ((error) => {
                 reject(error);
             })
         })
         
         const data = await promise
         data.splice(0, 1)
 
         let newFormattedData = []
         data.map((data) => {
 
             let newTargetValue = data.target_value
 
             if (typeof (data.target_value) === 'string') {
                 newTargetValue = parseFloat(data.target_value.replace(',', '.'))
             }

             if(typeof(data.created_at) != 'string'){
                data.payroll_date = formatDate(excelDateToJSDate(data.payroll_date))
             }

             newFormattedData.push({
                'payroll_date': data.payroll_date,
                'client_id': data.client_id,
                'advisor_id': data.advisor_id,
                'broker_id': data.broker_id,
                'category': data.category,
                'level_1': data.level_1,
                'level_2': data.level_2,
                'level_3': data.level_3,
                'stock_product': data.stock_product,
                'payout': data.payout,
                'stock_category': data.stock_category,
                'stock_access': data.stock_access,
                'tax_rate': data.tax_rate,
                'liquid_income': data.liquid_income,
                'safe_reservation': data.safe_reservation,
                'broker_funding': data.broker_funding,
                'broker_liquid': data.broker_liquid
                 
             })
         })
         // até aqui tudo certo
         setExcelRows(newFormattedData);
         setDataExcelAPI(newFormattedData);
         return setLoading(false);
     }

     const handleBack = () => {
         setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };
 
     const handleReset = () => {
         setSuccessImport(false);
         setActiveStep(0);
     };
 
     function handleFileCheck() {
         if (excelRows.length > 0) {
             handleNext()
             setShowFileButton(false);
         }
         else {
             alert('Carregue a planilha antes')
         }
     }
 
     function fileClick() {
 
         document.getElementById('getFile').value = '';
         document.getElementById('getFile').click()
         setSuccessImport(false);
         setErrorsImport(false);
         setShowErrors(false)
     }
 
     function formatLineToApi(value, type) {
 
         switch (type) {
             case 'number':
                 return parseFloat(value)
             default:
                 return value
         }
     }
 
     function tableForImportReccords() {
         return (
             excelRows?.length > 0 ?
                 (<table id='tableForImportReccords'>
                     <thead id='bodyImport'>
                        <tr>
                        <th>Data</th>
                        <th>CÓDIGO CLIENTE</th>
                        <th>CÓDIGO ASSESSOR</th>
                        <th>CÓDIGO BROKER</th>
                        <th>Produto/Categoria</th>
                        <th>Nível 1</th>
                        <th>Nível 2</th>
                        <th>Nível 3</th>
                        <th>Produto Mesa RV</th>
                        <th>Comissão (R$) Escritório</th>
                        <th>CATEGORIA MESA RV</th>
                        <th>CANAL MESA</th>
                        <th>ALÍQUOTA IR</th>
                        <th>Receita Total - LIQ</th>
                        <th>RESERVA CONTA ERRO 1,5%</th>
                        <th>REPASSE MESA LIQ</th>
                        <th>REPASSE BROKER LIQ</th>
                        </tr>
                     </thead>
                     <tbody id='bodyImport'>
                         {excelRows?.map((excelLine, indexLine) => {
                             const fieldKeys = Object.keys(excelLine)
                             return (
                                 <tr>
                                    <td>{excelLine.payroll_date}</td>
                                    <td>{excelLine.client_id}</td>
                                    <td>{excelLine.advisor_id}</td>
                                    <td>{excelLine.broker_id}</td>
                                    <td>{excelLine.category}</td>
                                    <td>{excelLine.level_1}</td>
                                    <td>{excelLine.level_2}</td>
                                    <td>{excelLine.level_3}</td>
                                    <td>{excelLine.stock_product}</td>
                                    <td>{toBRL(excelLine.payout)}</td>
                                    <td>{excelLine.stock_category}</td>
                                    <td>{excelLine.stock_access}</td>
                                    <td>{excelLine.tax_rate}</td>
                                    <td>{toBRL(excelLine.liquid_income)}</td>
                                    <td>{toBRL(excelLine.safe_reservation)}</td>
                                    <td>{toBRL(excelLine.broker_funding)}</td>
                                    <td>{toBRL(excelLine.broker_liquid)}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>) :
                 (<></>)
         )
     }
 
     function errorsTable() {
         if (errorList?.length > 0 && errorList[0].error_message !== undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     <td>{errorDetail.error_message}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
         if (errorList?.length > 0 && errorList[0].error_message === undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     {errorDetail["error"]}
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
 
     }

     async function getLayout() {

        try {
            const getLayouts = await api.get('/api/v1/payroll/layoutSpreadsheet', {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })

            const url = URL.createObjectURL(new Blob([getLayouts.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Layout_Importar_Extrato.xls')
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.log(error?.response)
            alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
        }
    }
 
     return (
         <div className='stepMainContainer' style={{
             width: screenX,
             height: screenY
         }}>
             <div className={classes.root}>
                 <div className='stepperDiv'>
                     {/* <button className='modalCloseButton' onClick={() => setShow(false)}>X</button> */}
                     <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                         {steps.map((label) => (
                             <Step key={label}>
                                 <StepLabel>{label}</StepLabel>
                             </Step>
                         ))}
                     </Stepper>
                 </div>
                 <div className='fileInput'>
                     {successImport || hideButtons ?
                         (<></>) :
                         (
                             <>
                                 {showFileButton ?
                                     (
                                         <>
                                             <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                             <button className='customButton' onClick={() => getLayout()}>Baixar layout <FiArrowDown /> </button>
                                             <a>{fileName.substring(12, fileName.length)}</a>            
                                         </>
                                     ) :
                                     (<></>)}
                                 <input
                                     type='file'
                                     accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                     id="getFile"
                                     onChange={(e) => {
                                        //  Verifica se o arquivo é um excel válido
                                         if (validyFiles(e.target.files[0], 'excel')) {
                                             return
                                         }
                                         const fileToRead = e.target.files[0];
                                         readExcelFile(fileToRead);
                                         setFilename(e.target.value)
                                     }}
                                 >
                                 </input>
                             </>
                         )}
 
                 </div>
             </div>
             <div className='fileInput'>
                 <a>Importando Extrato Mensal</a>
                 {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                     successImport && !errorsImport ?
                         (
                             <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                 <a>Sucesso ao Importar o Extrato!</a>
                             </div>
                         ) :
 
                         activeStep === 1 ? (
                             (<>
                                 <p id='recommendationsImportConfirm'>Confira os dados antes de importar.</p>
                                 <div id='customScroll' className='tableForImportSection'>
                                     {
                                         tableForImportReccords()
                                     } </div>
                             </>)
                         ) :
                             successImport && errorsImport ? (
                                 <div id='flexRowDiv' className='stepperImportWithErrors'>
                                     <a>Sucesso ao importar arquivos,</a>
                                     <a>mas ocorreram erros durante a importação.</a>
                                     <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                 </div>
                             ) : !successImport && errorsImport ?
                                 (
                                     <div id='flexRowDiv' className='stepperImportWithErrors'>
                                         <a>Ocorreram Erros na Importação</a>
                                         <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                     </div>
                                 ) :
                                 showErrors ?
                                     (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                     :
                                     (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                 <div>
                     {activeStep === steps.length && successImport ?
                         (
                             <div id='flexRowDiv'>
                                 <Typography className={classes.instructions}>Importado Com Sucesso! Você já pode sair desta página.</Typography>
                             </div>
                         ) :
                         activeStep === steps.length && !successImport ?
                             (
                                 <div id='flexRowDiv'>
                                     <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                     <Button onClick={() => setShow(false)}>Fechar</Button>
                                 </div>
                             ) :
 
                             hideButtons ? (<></>) :
                                 (
                                     <div>
                                         <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                         <div id='flexRowDiv'>
                                             <Button
                                                 disabled={activeStep === 0}
                                                 onClick={handleBack}
                                                 className={classes.backButton}
                                             >
                                                 Voltar
                                             </Button>
                                             <Button
                                                 id='buttonImport'
                                                 variant="contained"
                                                 className='customButton'
                                                 onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                 {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                             </Button>
                                         </div>
                                     </div>
                                 )}
                 </div>
             </div>
         </div>
     );
 }