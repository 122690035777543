import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/assetSectorCadastration.css'
import { styleClientsInput } from '../../../../components/styles/autocompletField'
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal'
import { TextField } from '@material-ui/core'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
/*Services*/
import { api } from '../../../../services/api'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'

export function AssetSectorCadastration() {

    const [loading, setLoading] = useState(false)
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const classes = styleClientsInput()
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [selectedAssetSector, setSelectedAssetSector] = useState('')

    useEffect(() => {

        setSelectedAssetSector('')

    }, [show])

    async function handleSubmit(e) {

        e.preventDefault()
        setLoading(true)

        if(!selectedAssetSector.trim().length){
            alert('Campo setor está vazio')
            setLoading(false)
            return
        }

        try {
            const assetSectorSend = await api.post(`/api/v1/assetSector`, {
                'description': selectedAssetSector
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setLoading(false)
            setEdited(true)
            setType({name: 'alertMsg', type: 'information', value: {message: 'Cadastro realizado com sucesso!'}})
        } catch (error) {
            
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao cadastrar setor' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    return (
        <div className="assetSectorCadastration" style={{
            width: screenX - 300,
            height: screenY - 100,
            background: '#FFF'
        }}>
            <div className="assetSectorCadastrationHeader">
                <p>Cadastro de setores para Ativos</p>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            <form onSubmit={(e)=> handleSubmit(e)} className="assetSectorCadastrationBody">
                {loading ?
                    (
                        <ArrowLoader />
                    ) :
                    (
                        <>
                            <p>Digite o setor abaixo, que será usado no cadastro de ativos</p>
                            <label>
                                <TextField
                                    onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                    inputProps={{maxLength: 30}}
                                    variant='outlined'
                                    placeholder='Descrição do setor'
                                    className={classes.styleForClients}
                                    value={selectedAssetSector}
                                    onChange={(e) => setSelectedAssetSector(e.target.value)}
                                >
                                </TextField>
                            </label>
                        </>
                    )
                }
            </form>
            <button type='submit' className='buttonWhiteBg' onClick={(e) => handleSubmit(e)}>Cadastrar</button>
        </div>
    )
}