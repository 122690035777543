import React, { useEffect, useState, useReducer } from "react"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { api } from "../../../../services/api"
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox, MdSearch } from "react-icons/md"
import { getABCAvailableOperations } from "../../../../services/asyncFunctions"
import { getAssetsList } from "../../../../services/asyncFunctions"
import { FiStar, FiArrowLeft } from "react-icons/fi"
import { FaStar } from "react-icons/fa"
import { RiArrowRightDownFill, RiArrowRightUpFill } from "react-icons/ri"
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { styled } from "@mui/material/styles"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import { styleClientsInput } from "../../../../components/styles/autocompletField"
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { getOffers } from "../../../../services/asyncFunctions"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { FaCheck } from "react-icons/fa"
import { MdContentCopy } from "react-icons/md"

import "../styles/modalCio.css"

export function LongShortCioModal(props) {
    // console.log('props', props)
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(false)
    const [operations, setOperations] = useState([])
    const [product, setProduct] = useState([])
    const [client, setClient] = useState()
    const [assetsList, setAssetsList] = useState([])
    const [assetsVariationList, setAssetsVariationList] = useState([])
    const [booksList, setBooksList] = useState([])
    const [activeBook, setActiveBook] = useState([])
    const [bookFields, setBookFields] = useState([])
    const [thisBookIsWithAward, setThisBookIsWithAward] = useState(false)
    const [operation, setOperation] = useState({})
    const [typeOperation, setTypeOperation] = useState(2)
    const [selectedQtd, setSelectedQtd] = useState(0)
    const [selectedValue, setSelectedValue] = useState("")
    const classes = styleClientsInput()
    const [qtdIsFilled, setQtdIsFilled] = useState(false)
    const [valueIsFilled, setValueIsFilled] = useState(false)
    const [sendStatus, setSendStatus] = useState({ status: false, type: "" })
    const [showCalculatedValue, setShowCalculatedValue] = useState(false)
    const [showAlert, setShowAlert] = useState("")
    const [searchText, setSearchText] = useState("")
    const [selectedOrder, setSelectedOrder] = useState()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)
    const [alertMessage, setAlertMessage] = useState("")
    const [ordersLs, setOrdersLs] = useState()
    const [copyingAll, setCopyingAll] = useState(false)
    const [copyingLink, setCopyingLink] = useState([])

    useEffect(() => {
        forceUpdate()
    }, [selectedOrder])

    useEffect(() => {
        // console.log(props?.prop.data)
        setOrdersLs(props?.prop.data)
        setOperation(props?.prop?.data[0])

        var aux = []
        props?.prop.data?.forEach(() => {
            aux.push(false)
        })
        setCopyingLink(aux)
    }, [show])

    function disableCopying() {
        setCopyingAll(false)
        var aux = copyingLink.map((l) => false)
        setCopyingLink(aux)
    }

    function copyOrder(orderLong, orderShort, orderLongF, orderShortF, current_long_class_id, current_short_class_id) {
        const lines = []
        orderLong[4] = orderLong[4].toFixed(2).replace(".", ",")
        orderShort[4] = orderShort[4].toFixed(2).replace(".", ",")
        orderShortF[4] = orderShortF[4].toFixed(2).replace(".", ",")
        orderLongF[4] = orderLongF[4].toFixed(2).replace(".", ",")
        const titles = ['Estratégia','Cliente', 'Ativo', 'C/V','Preço', 'Qtd. Total']
        lines.push(titles.join('\t'));
        if (orderLong[5] / 100 >= 1 || current_long_class_id != 1) lines.push(orderLong.join("\t"))
        if (current_long_class_id == 1) lines.push(orderLongF.join("\t"))
        if (orderShort[5] / 100 >= 1) lines.push(orderShort.join("\t"))
        if (current_short_class_id == 1) lines.push(orderShortF.join("\t"))

        const table = lines.join("\n")
        navigator.clipboard.writeText(table)
    }

    function copyAllOrders() {
        var listOrders = []
        const titles = ['Estratégia','Cliente', 'Ativo', 'C/V','Preço', 'Qtd. Total']
        listOrders.push(titles.join('\t'))
        ordersLs.forEach((o) => {
            const orderShort = [
                "Simples",
                o.clients_external_id,
                o.asset_short,
                "C",
                (o.value_short * 1.01).toFixed(2).replace(".", ","),
                parseInt(o.qtd_short >= 100 ? parseInt(o.qtd_short / 100) * 100 : o.qtd_short),
            ]
            const orderShortF = [
                "Simples",
                o.clients_external_id,
                o.asset_short + "F",
                "C",
                (o.value_short * 1.01).toFixed(2).replace(".", ","),
                parseInt(o.qtd_short % 100),
            ]
            const orderLong = [
                "Simples",
                o.clients_external_id,
                o.asset_long,
                "V",
                (o.value_long * 0.99).toFixed(2).replace(".", ","),
                parseInt(o.qtd_long >= 100 ? parseInt(o.qtd_long / 100) * 100 : o.qtd_long),
            ]
            const orderLongF = [
                "Simples",
                o.clients_external_id,
                o.asset_long + "F",
                "V",
                (o.value_long * 0.99).toFixed(2).replace(".", ","),
                parseInt(o.qtd_long % 100),
            ]
            if (o.qtd_short / 100 >= 1 || o.current_short_class_id != 1) listOrders.push(orderShort.join("\t"))
            if (o.current_short_class_id == 1) listOrders.push(orderShortF.join("\t"))
            if (o.qtd_long / 100 >= 1 || o.current_long_class_id != 1) listOrders.push(orderLong.join("\t"))
            if (o.current_long_class_id == 1) listOrders.push(orderLongF.join("\t"))
        })
        const table = listOrders.join("\n")
        navigator.clipboard.writeText(table)
    }

    function getDataSextaFeiraSemanaAtual() {
        var data = new Date()
        var diaSemana = data.getDay()
        var diferenca = 5 - diaSemana // 5 é o índice correspondente à sexta-feira

        data.setDate(data.getDate() + diferenca)

        return data
    }

    function formatarData(data) {
        var dia = data.getDate().toString().padStart(2, "0")
        var mes = (data.getMonth() + 1).toString().padStart(2, "0")
        var ano = data.getFullYear().toString()

        return dia + "/" + mes + "/" + ano
    }

    function converterTabelaParaTextoMailto(tabelaHTML) {
        var dataSextaFeira = getDataSextaFeiraSemanaAtual()
        var dataFormatada = formatarData(dataSextaFeira)
        var tabelaTemporaria = document.createElement("table")
        tabelaTemporaria.innerHTML = tabelaHTML

        var linhas = tabelaTemporaria.getElementsByTagName("tr")
        var texto = `Prezado(a) ${operation?.name},

Você autoriza as operações abaixo?

Validade: ${dataFormatada}

ORDENS A PREÇO DE MERCADO

`

        for (var i = 0; i < linhas.length; i++) {
            var colunas = linhas[i].getElementsByTagName("td")
            for (var j = 0; j < colunas.length; j++) {
                var conteudo = colunas[j].innerText.replace(/\n/g, " ")
                texto += conteudo + "\t\t" // Conteúdo da célula
            }
            texto += "\n"
        }
        texto += "\n\n"

        return encodeURIComponent(texto)
    }

    function gerarAuditoria() {
        var table = `
    <table>
      <tr>
          <td>ATIVO</td>
          <td>C/V</td>
          <td>QTD</td>
          <td>CONTA</td>
      </tr>
      <tr>
          <td>${operation?.asset_short}</td>
          <td>C</td>
          <td>${operation?.qtd_short}</td>
          <td>${operation?.clients_external_id}</td>
      </tr>
      <tr>
          <td>${operation?.asset_long}</td>
          <td>V</td>
          <td>${operation?.qtd_long}</td>
          <td>${operation?.clients_external_id}</td>
      </tr>
      </table>`

        const bodyMailTo = converterTabelaParaTextoMailto(table)

        window.location = `mailto:${operation?.email}?cc=${operation?.broker_email};${operation?.advisor_email}&subject=Auditoria%20para%20Execu%C3%A7%C3%A3o%20de%20Ordens&body=${bodyMailTo}`
    }

    return (
        <div className="containerModalAbcDetails">
            <div className="clientControlHeader">
                <div className="editBrancheBoxHeader">
                    {/* <button style={{marginLeft: '1rem'}} title="Voltar" onClick={() => {
                      const pageToreturn = props.prop.props.returnTo
                      setType({ name: pageToreturn, product: props.prop.props.product, client: props.prop.props.client, clients: props.prop.props.clients });
                      }} className="modalCloseButton">
                        <FiArrowLeft/>
                    </button> */}
                    <div className="containerProductName">
                        <p>Long Short: CIO </p>
                    </div>
                    <button onClick={() => setShow(false)} className="modalCloseButton">
                        X
                    </button>
                </div>
            </div>
            {loading ? (
                <div className="clientsCadastrationLoading">
                    <ArrowLoader />
                </div>
            ) : alertMessage != "" ? (
                <div className="clientsCadastrationLoading">
                    <h1>{alertMessage} </h1>
                </div>
            ) : (
                <div className="containerBoletarAbc">
                    <div className="containerListproductsAbc">
                        <div className="toolbarCioLs">
                            <button
                                className="buttonOperateSubmitCioLs"
                                onClick={async () => {
                                    await setCopyingAll(true)
                                    copyAllOrders()
                                    setTimeout(disableCopying, 1000)
                                }}
                            >
                                {!copyingAll ? "Copiar tudo" : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                            </button>
                        </div>
                        <div className="listOperationProductsAbc">
                            {ordersLs?.map((b, index) => {
                                const orderShort = [
                                    "Simples",
                                    b.clients_external_id,
                                    b.asset_short,
                                    "C",
                                    parseFloat((b.value_short * 1.01).toFixed(2)),
                                    parseInt(b.qtd_short > 100 ? parseInt(b.qtd_short / 100) * 100 : 0),
                                ]
                                const orderShortF = [
                                    "Simples",
                                    b.clients_external_id,
                                    b.asset_short + "F",
                                    "C",
                                    parseFloat((b.value_short * 1.01).toFixed(2)),
                                    parseInt(b.qtd_short % 100),
                                ]
                                const orderLong = [
                                    "Simples",
                                    b.clients_external_id,
                                    b.asset_long,
                                    "V",
                                    parseFloat((b.value_long * 0.99).toFixed(2)),
                                    parseInt(b.qtd_long > 100 ? parseInt(b.qtd_long / 100) * 100 : 0),
                                ]
                                const orderLongF = [
                                    "Simples",
                                    b.clients_external_id,
                                    b.asset_long + "F",
                                    "V",
                                    parseFloat((b.value_long * 0.99).toFixed(2)),
                                    parseInt(b.qtd_long % 100),
                                ]

                                return (
                                    <div
                                        className={operation == b ? "productCardActiveAbc" : "productCardAbc"}
                                        onClick={() => {
                                            setOperation(b)
                                        }}
                                    >
                                        <div className="productCardLSHeader">
                                            <p>Estratégia</p>
                                            <p>Cliente</p>
                                            <p>Ativo</p>
                                            <p>C/V</p>
                                            <p>Preço</p>
                                            <p>Qtd. Total</p>
                                            <div
                                                className="containerIconCopyContentLsCio"
                                                onClick={async () => {
                                                    let aux = copyingLink.map((v, i, a) => (i == index ? true : v))
                                                    await setCopyingLink(aux)
                                                    copyOrder(
                                                        orderLong,
                                                        orderShort,
                                                        orderLongF,
                                                        orderShortF,
                                                        b.current_long_class_id,
                                                        b.current_short_class_id
                                                    )
                                                    setTimeout(disableCopying, 1000)
                                                }}
                                            >
                                                <div className="linkMenuModalBoxAbc">
                                                    {!copyingLink[index] ? (
                                                        <MdContentCopy size={20} />
                                                    ) : (
                                                        <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divisionHeaderCardAbc"></div>
                                        <div className="productCardLsInfos">
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderLong[0]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderLong[1]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderLong[2]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderLong[3]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>
                                                    {orderLong[4] ? toBRL(orderLong[4]) : toBRL(0)}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{b.qtd_long}</span>
                                            </div>
                                            <div></div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderShort[0]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderShort[1]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderShort[2]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{orderShort[3]}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>
                                                    {orderShort[4] ? toBRL(orderShort[4]) : toBRL(0)}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: "300", fontSize: "1.1rem" }}>{b.qtd_short}</span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="divisionNameProductSubcategory"></div>
                    <div className="containerOperateProductLs">
                        <div className="headerOperateProductAbc">
                            <p>{/* Titulo */}</p>
                        </div>

                        <div className="bodyOperateProductAbc">
                            <div className="itemBodyOperateProductAbc">{/* Um item */}</div>

                            <div className="contentBodyOperateProductAbc">
                                <div className="itemBodyOperateProductAbc fieldsOperateAbc">Prezado(a) {operation?.name},</div>
                                <div className="itemBodyOperateProductAbc fieldsOperateAbc">Você autoriza as operações abaixo?</div>
                                <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                                    <b>ORDENS A PREÇO DE MERCADO</b>

                                    <table className="tblLs">
                                        <tr>
                                            <th>ATIVO</th>
                                            <th>C/V</th>
                                            <th>QTD</th>
                                            <th>CONTA</th>
                                        </tr>
                                        <tr>
                                            <td>{operation?.asset_short}</td>
                                            <td>C</td>
                                            <td>{operation?.qtd_short}</td>
                                            <td>{operation?.clients_external_id}</td>
                                        </tr>
                                        <tr>
                                            <td>{operation?.asset_long}</td>
                                            <td>V</td>
                                            <td>{operation?.qtd_long}</td>
                                            <td>{operation?.clients_external_id}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="boxSubmitOperateProductAbc">
                            <button className="buttonOperateSubmitABC" onClick={() => gerarAuditoria()}>
                                Gerar Auditoria
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
