/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Tech RV
 */

import React, { useState, useEffect, useRef } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../../../../contexts/useAuth";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../styles/details.css';
/*Utils*/
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import XLSX from 'xlsx';
import { styleClientsInput } from '../../../../components/styles/autocompletField';

import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
/*Services*/
import { api } from '../../../../services/api';
import { validyFiles } from '../../../../utils/validyTypesOfFiles';
import { TextField, TextareaAutosize } from '@material-ui/core'
import { Autocomplete, Select } from '@mui/material';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency';
import { getMyClients } from '../../../../services/asyncFunctions'
 
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },
    
    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    
    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));
 
 export function DetalhesMapeamento(props) {
    // console.log(props)
    const { user, tokenKey } = useAuth();
    const { show, setShow, edited, setEdited, type, setType } = useModal();
    const classes = styleClientsInput()
    const { screenY } = ModalSizeFitToScreen()

    const [ selectedKey, setSelectedKey ] = useState( null)
    const [ selectedStrategy, setSelectedStrategy ] = useState(null)

    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listClients, setListClients] = useState([])
    const [successEdit, setSuccessEdit] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    const requiredFields = [
        { field: 'selectedMapKey', value: selectedKey },
        { field: 'selectedMapStrategy', value: selectedStrategy }
    ]
    const [errorFields, setErrorFields] = useState({
        selectedMapKey: false,
        selectedMapStrategy: false
    })
 
    function clear() {
        setSelectedKey(null)
        setSelectedStrategy(null)

        setSuccessEdit(false);
        setShowConfirmButton(false);
        setShow(false);
    }
 
    async function handleSubmit(e) {
        console.log(selectedKey, selectedStrategy)
        console.log(user.external_id)

        let errors = false;
        const aux = errorFields

        requiredFields.map((required) => {
            if (required.value == null | (typeof (required.value) !== 'object' && !required?.value?.toString()?.trim()?.length)) {
                aux[required.field] = true
                errors = true
            } else {
                aux[required.field] = false
            }
        })
        setErrorFields(aux);

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            alert('Campos obrigatórios não preenchidos')
            setLoading(false)
            return
        }

        e.preventDefault();

        if (selectedKey === '' || selectedStrategy.description === '') {
            alert('Preencha todos os campos')
            return
        }

        try {
            setLoading(true)
            await api.put(`/api/v1/wisir2/map`, {
                    id: props.prop.id != undefined && props.prop.id != null ? parseInt(props.prop.id) : undefined,
                    key: selectedKey,
                    wisir_strategy_id: selectedStrategy.id
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            clear()
            setLoading(false);
            props.prop.update()
            setType({ name: 'alertMsg', type: 'information', value: { message: `Sucesso ao atualizar o mapeamento` } })
        } catch (error) {
            setLoading(false)
            console.log(error.response)
            alert(error?.response?.data?.message)
            setShow(false)
        }


        // if (selectedClient) {
        //     setLoading(true);
        //     try {
        //         const clientUpdate = await api.post(
        //             `/api/v1/wisir2/${props.value}/add`, {
        //                 client_id: selectedClient.client_id,
        //                 value: selectedValue
        //             },
        //             {
        //                 headers: {
        //                     authorization: `Bearer ${tokenKey}`,
        //                 },
        //             }
        //         );
        //         setType({
        //             name: "alertMsg",
        //             type: "information",
        //             value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(selectedClient.client)}` },
        //         });
        //         props.reload()
        //         setLoading(false);
        //     } catch (error) {
        //         alert(error?.response?.data?.message);
        //         setLoading(false);
        //     }
        // }
    }

    useEffect(() => {
        if (props.prop.id != undefined && props.prop.id != null) {
            setSelectedKey(props.prop.info[0].entry)
            setSelectedStrategy(props.prop.strats.filter((s) => s.id == props.prop.info[0].wisir_strategy_id)[0])
        }
    }, [props.prop])
 
    return (
    <div className="mappingContainer" style={{
        maxHeight: screenY
    }}>
        {loading ? (
            <div id="clientDetailsLoader">
                <ArrowLoader />
            </div>
        ) : successEdit ? (
            <div className="clientDetailsSuccessEdit">
                <a>Sucesso ao Atualizar o Mapeamento</a>
                <button
                    onClick={() => {
                        setSuccessEdit(false);
                        setType({ name: "successUpdate" });
                        setShow(false);
                    }}
                >
                    Ok
                </button>
            </div>
        ) : (
            <>
                <div className="clientsDetailsHeader">
                    <div>
                        <p>Adicionar/Editar Mapeamento</p>
                    </div>
                    <button onClick={() => clear()}>x</button>
                </div>

                <div className="mappingContainer">
                    <div className="mappingContainerUpperGrid">
                        <label>
                            <TextField
                                label='Chave'
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                value={selectedKey}
                                className={errorFields['selectedMapKey'] && !selectedKey?.trim().length ? classes.styleForClientsWithError : classes.styleForClients}
                                onBlur={(e) => { setSelectedKey(e.target.value.toUpperCase()) }}
                                onFocus={(e) => {
                                    setSelectedKey(null);
                                }}
                                variant="outlined" 
                            />

                        </label>
                    </div>
                    <div className="mappingContainerUpperGrid">
                        <label>
                            <Autocomplete
                                options={props.prop.strats}
                                value={selectedStrategy}
                                onChange={(event, newValue) => {
                                    console.log('new', newValue)
                                    setSelectedStrategy(newValue);
                                }}
                                getOptionLabel={(option) => option?.description}
                                getOptionSelected={(option) => option?.description}
                                id='productType'
                                renderInput={(params) => <TextField
                                    label='Estratégia'
                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                    // className="productCadastrationContainerRowItemAbc"
                                    className={errorFields['selectedMapStrategy'] && !selectedStrategy?.id ? classes.styleForClientsWithError : classes.styleForClients}

                                    {...params} variant="outlined" />}
                            />
                        </label>
                    </div>
                    <div className='mappingSubmitBtn'>
                        <button 
                            className='buttonWhiteBg' 
                            onClick={handleSubmit}
                        >Atualizar</button>
                    </div>
                </div>
                                    
            </>
        )}
        </div>
    );
 }