/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import XLSX from 'xlsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './objectivesImport.css';
import { useModal } from '../../../contexts/useModal';
import { api } from '../../../services/api'
import { ArrowLoader } from '../../../components/loaders/arrowLoader';
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { validyFiles } from '../../../utils/validyTypesOfFiles';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
            background: 'red'
        },
        fontFamily: 'Roboto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400'
    },

    stepperItems: {
        display: 'flex',
        padding: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },

    '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontFamily: 'Roboto',
        fontSize: '0.9rem !important'
    },
}));

function getSteps(externalSteps) {
    return externalSteps;
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
}

export default function ObjectivesImport(props) {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const steps = ['Selecione o arquivo', 'Escolha o ano dos objetivos', 'Efetue a importação']
    const [rows, setRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const { screenX, screenY } = ModalSizeFitToScreen();
    const [excelErrors, setExcelErrors] = useState([])
    const [errorInExcel, setErrorInExcel] = useState(false)
    const [showErrorsExcel, setShowErrorsExcel] = useState(false)
    const [showBlank, setShowBlank] = useState(false)

    useEffect(() => {

        setActiveStep(0);
        setRows([]);
        setShowErrors(false)
        setShowErrorsExcel(false)
        setErrorInExcel(false)
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);
        setShowBlank(false)
        setSelectedYear(0)
    }, [show])

    const handleNext = async (props) => {
        

        if(activeStep===1 && selectedYear === 0){
            return alert("Selecione o ano que será efetuada a importação")
        }
        if (props) {

            setLoading(true);
            try {
                const importClientAPI = new Promise(async (resolve, reject) => {
                    try {
                        const importResult = await api.post('/api/v1/brokerPanelGoals/import', {
                            "year": parseInt(selectedYear),
                            "goals": rows
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            }
                        )
                        resolve(importResult)
                    } catch (error) {
                        reject(error)
                    }
                })

                let inProcessLoading = true;
                let timeCount = 0

                do {
                    setHideButtons(true);
                    if (timeCount == 5) {
                        setImportPlainMessage(`A planilha está em processo de importação.`)
                    } else if (timeCount < 10) {
                        setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                    } else if (timeCount < 15) {
                        setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                    }

                    importClientAPI.then((value) => {
                        inProcessLoading = false;
                        setHideButtons(false);
                        setLoading(false);
                        setSuccessImport(true);
                        // setType({ name: 'alertMsg', type:'information', value: {message: 'Registros importados com sucesso'} })
                        if (value?.data?.errors_details?.length > 0) {
                            setErrorsImport(true);
                            setErrorsList(value?.data?.errors_details);
                        }
                    }).catch((error) => {
                        console.log(error?.response)
                        inProcessLoading = false;

                        if (error?.response?.data?.status === 500) {
                            setErrorsList([{ error_message: error?.response?.data?.message }]);
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsImport(true);
                        } else {
                            setHideButtons(false);
                            setLoading(false);
                            setErrorsList(error?.response?.data?.details);
                            setErrorsImport(true);
                        }
                    })

                    await new Promise(resolve => {
                        setTimeout(() => {
                            timeCount += 1
                            resolve()
                            if (timeCount == 20) timeCount = 0
                        }, 1000)
                    })

                } while (inProcessLoading == true);

            } catch (error) {
                alert('Erro inesperado.')
                setLoading(false);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const validateData = []
                validateData?.push(ws?.A1?.v)
                validateData?.push(ws?.B1?.v)
                const schemaToValidate = ['Codigo', 'Objetivo anual']
                const errors = validateData.filter((fieldToValidate, index) => {

                    if (fieldToValidate !== schemaToValidate[index]) {
                        return fieldToValidate
                    }
                })

                if (errors?.length > 0) {
                    document.getElementById('getFile').value = '';
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setErrorInExcel(false)
                    setShowErrorsExcel(false)
                    setShowBlank(true)
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['users_external_id', 'goal'],
                        blankRows: false
                    });

                    let errorRows = []
                    const newData = data.filter((dataToVerify, index) => {
                        //preenche variavel com os dados que estão corretos
                        if (
                            (index > 0 && (typeof (dataToVerify.users_external_id) === 'number' && dataToVerify?.users_external_id?.toString()?.trim()?.length))
                            &&
                            (index > 0 && (typeof (dataToVerify.goal) === 'number' && dataToVerify?.goal?.toString()?.trim()?.length))
                        ) {
                            return (dataToVerify)
                        }
                        //cria o array com as linhas preenchidas indevidamente que não serão importadas
                        if (index > 0 && (typeof (dataToVerify.users_external_id) !== 'number' || !dataToVerify?.users_external_id?.toString()?.trim()?.length)) {
                            errorRows.push({ row: index + 1, error: 'Tipo de dado não está em formato número ou não foi preenchido', column: 'Código', value: dataToVerify.users_external_id })
                            setErrorInExcel(true)

                        }

                        if (index > 0 && (typeof (dataToVerify.goal) !== 'number' || !dataToVerify?.goal?.toString()?.trim()?.length)) {
                            errorRows.push({ row: index + 1, error: 'Tipo de dado não está em formato número ou não foi preenchido', column: 'Objetivo anual', value: dataToVerify.goal })
                            setErrorInExcel(true)
                        }
                    })
                    setExcelErrors(errorRows)
                    resolve(newData);
                }
            }

            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        promise.then((data) => {
            setRows(data);
            setLoading(false);
        })
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setSuccessImport(false);
        setActiveStep(0);
    };

    function handleFileCheck() {
        if (rows.length > 0) {
            handleNext()
        }
        else {
            alert('Carregue a planilha antes')
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }

    function tableForImportReccords() {

        return (
            rows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Codigo</th>
                            <th>Objetivo</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {rows?.map((bodyRows, index) => {

                            return (
                                <tr>
                                    <td>{bodyRows.users_external_id}</td>
                                    <td>{bodyRows.goal}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }

    function errorsTable() {

        if (errorList?.length > 0 && errorList[0].error_message === undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

    }

    function errorsExcelReccords() {

        return (
            <table className='brokerPanelErrorsExcelTable'>
                <thead>
                    <tr>
                        <th>Linha</th>
                        <th>Erro</th>
                        <th>Coluna</th>
                        <th>Valor da planilha</th>
                    </tr>
                </thead>
                <tbody>
                    {excelErrors?.map((errorDetail) => {
                        return (
                            <tr>
                                <td>{errorDetail.row}</td>
                                <td>{errorDetail.error}</td>
                                <td>{errorDetail.column}</td>
                                <td>{errorDetail.value}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>

                    {
                        successImport || hideButtons ?
                            (<></>) :
                            (
                                <>
                                    <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                    <a>{fileName.substring(12, fileName.length)}</a>
                                    <input
                                        type='file'
                                        accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                        id="getFile"
                                        onChange={(e) => {
                                            if (validyFiles(e.target.files[0], 'excel')) {
                                                return
                                            }
                                            const fileToRead = e.target.files[0];
                                            readExcelFile(fileToRead);
                                            setFilename(e.target.value)
                                        }}
                                    >
                                    </input>
                                </>
                            )}

                </div>
            </div>
            <div className='fileInput'>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                    errorInExcel ?
                        (
                            <div id='stepperRowDiv' className='stepperImportWithErrors'>
                                <a>Existem erros na planilha que impedem a importação dos arquivos!</a>
                                <button onClick={() => { setShowErrorsExcel(true); setErrorInExcel(false) }}>Verificar erros</button>
                            </div>
                        ) :
                        showErrorsExcel ?
                            <div className='tableErrorsImport'>{errorsExcelReccords()}</div>
                            :
                            successImport && !errorsImport ?
                                (
                                    <div id='stepperRowDiv' className='stepperImportWithoutErrors'>
                                        <a>Sucesso ao importar os valores</a>
                                    </div>
                                ) :
                                successImport && errorsImport ? (
                                    <div id='stepperRowDiv' className='stepperImportWithErrors'>
                                        <a>Sucesso ao importar valores!</a>
                                        <a>Mas, ocorreram erros na importação</a>
                                        <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                    </div>
                                ) : !successImport && errorsImport ?
                                    (
                                        <div id='stepperRowDiv' className='stepperImportWithErrors'>
                                            <a>ocorreram erros na importação</a>
                                            <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                        </div>
                                    ) :
                                    showErrors ?
                                        (<div id='customScroll' className='tableErrorsImport'> {errorsTable()}</div>)
                                        :
                                        activeStep === 1 ?
                                            (<div id='stepperRowDiv' className='stepperImportWithoutErrors'>
                                                <p style={{fontSize: '1rem', fontWeight: '400', marginRight: '5px'}}>Digite o ano e clique em próximo</p>
                                                <input id='yearImport' type='number' value={selectedYear} onKeyPress={(e)=> {if(e.code==='Backquote')e.preventDefault()}} onChange={(e)=> {if(e.target.value.length <=4){setSelectedYear(e.target.value)}else{e.preventDefault()}}}></input>
                                            </div>)
                                            :
                                            (<div id='customScroll' className='tableForImportSection'>{showBlank ? '' : tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='stepperRowDiv'>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='stepperRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div >
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='stepperRowDiv' >
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext(activeStep === steps.length - 1) : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
}