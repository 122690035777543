import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
//components
import Body from "../components/body/body";
import Menu from "../components/menu/menu";
//hooks
import { MenuProvider } from "../contexts/useMenu";
import { SocketProvider } from "../contexts/useSocket";
//pages

import { DashboardAdvisorConfig } from "../pages/app/dashboard/dashboardAdvisorProfileConfig";
import { Dashboard } from "../pages/app/dashboard/dashboard";
import { Dash } from "../pages/app/dashboard/dash";
import { Dash2 } from "../pages/app/dashboard/dash2";

import { AssetsOfferings } from "../pages/app/publicOfferings/stocks/assetsOfferings";
import { FiisOfferings } from "../pages/app/publicOfferings/fiis/fiisOfferings";
import { Clients } from "../pages/app/registrations/clients/clients";
import { MyStructured } from "../pages/app/monitoringPanel/myStructured/myStructured";
import { UnsuitedStructured } from "../pages/app/monitoringPanel/unsuitedStructured/unsuitedStructured";
import { B3Guarantees } from "../pages/app/monitoringPanel/b3Guarantees/b3Guarantees";
import { MyReservations } from "../pages/app/monitoringPanel/myReservations/myReservations";
import { MyPushOrders } from "../pages/app/monitoringPanel/myPushOrders/myPushOrders";
import { ModalProvider } from "../contexts/useModal";
import { Users } from "../pages/app/configuration/users/users";
import { Companies } from "../pages/app/registrations/branches/branches";
import { useAuth } from "../contexts/useAuth";
import { FaqEducation } from "../pages/app/faqEducation/faqEducation";
import { Reports } from "../pages/app/reports/reports";
import { Books } from "../pages/app/structuredOperations/books/books";
import { PushOrders } from "../pages/app/cashMarket/pushOrders";
import { ClientsIndication } from "../pages/app/myClients//indications/clientsIndication";
// import { ClientsWisirIndication } from "../pages/app/myClients//indications/wisirIndication/clientsWisirIndication";
import { MyClients } from "../pages/app/myClients/myClients/myClients";
import { MyAdvisorsClients } from "../pages/app/myClients/myClients/myAdvisorsClients";
import { BrokerProfiles } from "../pages/app/configuration/brokerProfiles/brokerProfiles";
import { AvatarEdit } from "../components/menu/myProfile/avatarEdit";
import { BooksConfiguration } from "../pages/app/configuration/booksConfiguration/booksConfiguration";
import { Assets } from "../pages/app/configuration/assets/assets";
import { AssetsClasses } from "../pages/app/configuration/assetClasses/assets";
import { Recomendations } from "../pages/app/recomendations/recomendations";
// import { WisirAcoes } from "../pages/app/carteirasWisir/wisirAcoes";
// import { WisirFii } from "../pages/app/carteirasWisir/wisirFii";
// import { WisirRenda } from "../pages/app/carteirasWisir/wisirRenda";
// import { WisirIndicarAcoes } from "../pages/app/carteirasWisir/wisirIndicarAcoes";
// import { WisirIndicarFii } from "../pages/app/carteirasWisir/wisirIndicarFii";
// import { WisirIndicarRenda } from "../pages/app/carteirasWisir/wisirIndicarRenda";
import { WisirControle } from "../pages/app/carteirasWisir/wisirControle";
// import { WisirTracking } from "../pages/app/carteirasWisir/wisirTracking";
// import { WisirOperational } from "../pages/app/carteirasWisir/wisirOperational";
import { Wisir } from '../pages/app/wisir/wisir'
import { WisirMapping } from '../pages/app/wisir/mapping'
import AssetSector from "../pages/app/configuration/assetSectors/assetSector";
import { BrokerPanel } from "../pages/app/brokerPanel/brokerPanel";
import { BrokerPayroll } from "../pages/app/brokerPanel/brokerPayroll";
import { PayrollImport } from "../pages/app/brokerPanel/brokerPayrollImport";
import { BrokerPanel2 } from "../pages/app/brokerPanel/brokerPanel2";
import { BrokersAdvisors } from "../pages/app/brokerPanel/brokersAdvisors.table";
// import { WisirInsert } from "../pages/app/carteirasWisir/wisirInsert";
import { Performance } from "../pages/app/performance/performance.js";
import { Graph } from "../pages/app/graphTest/graph.js";
import { RoaDashboard } from "../pages/app/brokerPanel/roaDashboard.js";
import { CreateForm } from "../pages/app/B2Cintensidade/createForm";
import { SelectForm } from "../pages/app/B2Cintensidade/selectForm";
import { FormReply } from "../pages/app/B2Cintensidade/formReply";
import { MyForms } from "../pages/app/B2Cintensidade/myForms";
import { EditCreatedForm } from "../pages/app/B2Cintensidade/editCreatedForm";
import { TermoCaixa } from "../pages/app/simulation/termoCaixa";
import { MonitorTermoCaixa } from "../pages/app/monitoringPanel/myCashForward/termoCaixaMonitor";
import { Abc }  from "../pages/app/abc/abc";
import { Management } from "../pages/app/abc/management"
import { AbcDashboard } from "../pages/app/abc/abcDashboard"
import { LongShort } from "../pages/app/longShort/longShort"
import { LongShortTbls } from "../pages/app/longShort/tables/longShort.tableIndex";
import { MonitorCustodia} from "../pages/app/brokerPanel/monitorCustodia"
import { Cubastar} from "../pages/app/cubastar/cubastar"
import { FixedIncomeInvestments } from "../pages/app/investments/fixedIncome";
import { FundsInvestments } from "../pages/app/investments/funds";
import { OffshoreInvestments } from "../pages/app/investments/offshore";
import { CambioInvestments } from "../pages/app/investments/cambio";
import { AlocacaoConfig } from "../pages/app/configuration/alocacaoConfig/alocacaoConfig.main";
import { CreditInvestments } from "../pages/app/investments/credit";
import { SegurosInvestments } from "../pages/app/investments/seguros";
import { DashBaterBaseCampanhas } from "../pages/app/dashboard/dashBaterBaseCampanhas.js";


export default function AppRoutes() {
  const { user, signed } = useAuth();
  // console.log('user.type',user.type)

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
      {...rest}
      render={(props) =>
          (user.type == 1 || user.type == 2) && signed === true ? (
            <Component {...props} />
            ) : (
              <Redirect
              to={{ pathname: "/404", state: { from: props.location } }}
              />
          )
        }
        />
        );
      };
  return (
    <HashRouter>
      <SocketProvider>
        <MenuProvider>
          <ModalProvider>
            <Menu />
            <Body>
              <Switch>
                <Route path="/" exact component={Dash} />
                {/* 
                <Route path="/visaoAssessor/dashboard" exact component={Dash2} />
                <Route path="/visaoAssessor/dashboard/config" exact component={DashboardAdvisorConfig} /> */
                }
                <Route path="/baterBase" exact component={DashBaterBaseCampanhas} />
                <Route path="/cubastar" exact component={Cubastar} />
                <Route path="/investimentos/rendaFixa" exact component={FixedIncomeInvestments} />
                <Route path="/investimentos/fundos/:codCliente?/:tab?" exact component={FundsInvestments} />
                <Route path="/investimentos/offshore" exact component={OffshoreInvestments} />
                {/* <Route path="/investimentos/cambio" exact component={CambioInvestments} />
                
                <Route path="/investimentos/credito" exact component={CreditInvestments} />
                <Route path="/investimentos/seguros" exact component={SegurosInvestments} /> */}
                <Route path="/acoes" exact component={AssetsOfferings} />
                <Route path="/fiis" exact component={FiisOfferings} />
                {/* <Route path="/wisirAcoes" exact component={WisirAcoes} />
                <Route path="/wisirFii" exact component={WisirFii} />
                <Route path="/wisirRenda" exact component={WisirRenda} />
                <Route path="/wisirIndicarAcoes" exact component={WisirIndicarAcoes} />
                <Route path="/wisirIndicarFii" exact component={WisirIndicarFii} />
                <Route path="/wisirIndicarRenda" exact component={WisirIndicarRenda} />
                <Route path="/wisirInsert" exact component={WisirInsert} /> */}
                <Route path="/wisirControle" exact component={WisirControle} />
                <Route path="/wisirAcompanhamento" exact component={Wisir} />
                <Route path="/wisirMapeamento" exat component={WisirMapping} />
                {/* <Route path="/wisirOperacional" exact component={WisirOperational} /> */}
                <Route path="/wisirOperacional" exact component={Wisir} />
                <Route path="/payrollInsert" exact component={PayrollImport} />
                <Route path="/monitorEstruturadas" exact component={UnsuitedStructured} />
                <Route path="/monitorGarantias" exact component={B3Guarantees} />
                <Route path="/monitorTermoCaixa" exact component={MonitorTermoCaixa} />
                <Route path="/clientes" exact component={user.type === 4 ? MyClients : Clients} />
                {/* <Route path="/clientes" exact component={MyClients} /> */}
                <Route
                  path="/minhasEstruturadas"
                  exact
                  component={MyStructured}
                />
                <Route
                  path="/minhasReservas"
                  exact
                  component={MyReservations}
                />
                <Route path="/minhasPush" exact component={MyPushOrders} />
                <Route path="/faq" exact component={FaqEducation} />
                <Route path="/relatorios" exact component={Reports} />
                <Route path="/indicarClientes" exact component={ClientsIndication}/>
                {/* <Route path="/aprovarClientesWisir" exact component={ClientsWisirIndication}/> */}
                <Route path="/clientesMesa" exact component={user.type === 4 ? MyClients : Clients} />
                {/* <Route path="/clientesMesa" exact component={MyClients} /> */}
                <Route path="/clientesNaoMesa" exact component={MyAdvisorsClients} />
                <Route path="/perfisBroker" exact component={BrokerProfiles} />
                <Route path="/editarAvatar" exact component={AvatarEdit} />
                <Route path="/ordensPush" exact component={PushOrders} />
                <Route path="/recomendacoes" exact component={Recomendations} />
                <Route path="/books" exact component={Books} />
                <Route path="/painelBroker" exact component={BrokerPanel} />
                <Route path="/painelBroker2" exact component={BrokerPanel2} />
                <Route path="/brokerPayroll" exact component={BrokerPayroll} />
                <Route path="/brokersAdvisors" exact component={BrokersAdvisors} />
                <Route path="/performance" exact component={Performance} />
                <Route path="/graph" exact component={Graph} />
                <Route path="/roaDashboard" exact component={RoaDashboard} />
                <Route path="/monitorCustodia" exact component={MonitorCustodia} />
                <Route path="/createForm" exact component={CreateForm} />
                <Route path="/selectForm" exact component={SelectForm} />
                <Route path="/formReply" exact component={FormReply} />
                <Route path="/myForms" exact component={MyForms} />
                <Route path="/editCreatedForm" exact component={EditCreatedForm} />
                <Route path="/termoCaixa" exact component={TermoCaixa} />
                {
                /* <Route path="/abc" exact component={Abc} />
                <Route path="/abcManagement" exact component={Management} />
                <Route path="/abcDash" exact component={AbcDashboard} /> */
                }
                <Route path="/longShort" exact component={LongShort} />
                <Route path="/longShortIndex" exact component={LongShortTbls} />
                <Route path="/alocacaoConfig" exact component={AlocacaoConfig} />
                <PrivateRoute
                  path="/cadastroBooks"
                  exact
                  component={BooksConfiguration}
                />
                <PrivateRoute path="/ativos" exact component={Assets} />
                <PrivateRoute
                  path="/classesAtivos"
                  exact
                  component={AssetsClasses}
                />
                <PrivateRoute
                  path="/setoresAtivos"
                  exact
                  component={AssetSector}
                />
                <PrivateRoute path="/empresas" exact component={Companies} />
                <PrivateRoute path="/usuarios" exact component={Users} />
              </Switch>
            </Body>
          </ModalProvider>
        </MenuProvider>
      </SocketProvider>
    </HashRouter>
  );
}
