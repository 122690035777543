import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/clientsIndication.table.pendings.css';
import { FiTrash, FiSearch, FiRewind, FiCheck, FiPlusSquare,FiCopy,FiArrowDown } from 'react-icons/fi';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Services*/
import { api } from '../../../../services/api';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";


export function ToIndicate() {
    const { show, setShow, type, setType, edited } = useModal();
    const { tokenKey, user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [filtered, setFiltered] = useState('');
    const [myIndicationsList, setMyIndicationsList] = useState([]);
    const [myBrokerList, setMyBrokerList] = useState([]);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(20);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [selectedFiter, setSelectedFilter] = useState('Código');

    useEffect(() => {
        async function getMyIndications() {
            setLoading(true)
            try {
                const myIndicationsResult = await api.get(`/api/v1/clientsIndications/toIndicate`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setMyIndicationsList(myIndicationsResult?.data?.data?.indicate)
                // setMyBrokerList(myIndicationsResult?.data?.data?.brokers)
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false)
            }
            if(type.name==='reloadIndicationTable'){
                setType('')
            }
        }
        getMyIndications()
    }, [type, edited]);

    useEffect(() => {

        if (bottomScroll != false) {

            setScrollTableLines(scrollTableLines + 50)
            setBottomScroll(false);

        } else {
            setBottomScroll(false);
        }

    }, [bottomScroll])

    function myClientsToApprovalListFilter() {
        console.log('LISTONA', myIndicationsList)
        if (filtered === null || filtered === undefined || filtered === '') {
            return myIndicationsList;
        } else {
            return myIndicationsList.filter((filterRow) => {
                if (filterRow?.client_id?.indexOf(filtered) > -1 && selectedFiter === 'Código' ||
                    filterRow?.client_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Cliente' ||
                    filterRow?.advisor_id?.indexOf(filtered) > -1 && selectedFiter === 'Indicado por' ||
                    filterRow?.advisor_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Indicado por' ||
                    (filterRow?.status === 1 ? 'pendente'.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Status' :
                        filterRow?.status === 2 ? 'aprovado'.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Status' :
                            filterRow?.status === 3 ? 'negado'.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Status' : '') ||
                    filterRow?.created_at?.indexOf(filtered) > -1 && selectedFiter?.toLowerCase() === 'indicado em') {
                    return filterRow;
                }
            })
        }
    }

    async function indicationsExport() {
        setLoading(true);
        try {
            const exportIndicationsExport = await api.get(`/api/v1/clientsIndications/toIndicate/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
    
            const responseURL = URL.createObjectURL(new Blob([exportIndicationsExport.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'meus_clientes_fora_da_mesa.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)
    
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function myIndicationToApproval() {
        return (
            <div className='clientsIndicationPendingsTableContainer' id='clientsIndicaitonPendingsTableContainer' onScrollCapture={() => {

                setScrollTable(document.getElementById('clientsIndicaitonPendingsTableContainer'))
                if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                    setBottomScroll(true);
                }
            }}>
                <table>
                    <thead>
                        <tr key='pendingstr1'>
                            <th colSpan='7'>
                                <div className='clientsIndicationPendingsTableFirstThead'>
                                    <p>Indicações para aprovar</p>
                                    <label>
                                        <select onChange={(e) => setSelectedFilter(e.target.value)} className='selectFilterTable'>
                                            <option defaultValue>Código</option>
                                            <option>Cliente</option>
                                            <option>Indicado por</option>
                                            <option>Status</option>
                                            <option>Indicado em</option>
                                            <option>Ação</option>
                                        </select>
                                        <input placeholder='Filtrar' id='myClientsPendingsInputFilter' onChange={(e) => setFiltered(e.target.value)}></input>
                                    </label>
                                </div>
                            </th>
                        </tr>
                        <tr key='pendingstr2'>
                            <th>Código</th>
                            <th>Cliente</th>
                            <th>Observação</th>
                            <th>Indicado por</th>
                            <th>Status</th>
                            <th>Indicado em</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <td colSpan='7' id='myIndicationsPendingsHomeLoader'>
                                <ArrowLoader />
                            </td>
                        ) :
                            myClientsToApprovalListFilter()?.length > 0 ?
                                (
                                    myClientsToApprovalListFilter()?.map((indication, index) => {

                                        if (index < scrollTableLines && bottomScroll && index + 1 === scrollTableLines) {
                                            return (
                                                <tr key={index} id={bottomScroll ? 'clientsShowLoadingScroll' : 'clientsHideLoadingScroll'}>
                                                    <td colspan='10'>
                                                        <div class='ldsellipsis'><div></div><div></div><div></div><div></div></div>
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        else if (index < scrollTableLines) {
                                            return (
                                                <tr id='myPendingsApprovalList' key={index + 'Body'}>
                                                    <td>{indication?.client_id}</td>
                                                    <td>{titleize(indication?.client_name)}</td>
                                                    <td>{indication?.observation}</td>
                                                    <td>{indication?.advisor_id + ' - ' + titleize(indication?.advisor_name)}</td>
                                                    <td>{indication?.status === 1 ?
                                                        'Pendente' : indication?.status === 2 ? 'Aprovado' :
                                                            indication?.status === 3 ? 'Negado' : 'Erro'}
                                                    </td>
                                                    <td>{indication?.created_at.replace(/(\d{2}:\d{2}:\d{2})/, '')}</td>
                                                    <td><FiCheck cursor='pointer' size='18' onClick={() => {
                                                        setType({
                                                            name: 'alertMsg',
                                                            type: 'normalMsg', value:
                                                            {
                                                                message: 'Deseja realmente aceitar a indicação selecionada?',
                                                                func: handleApprove,
                                                                dataForFunc: indication
                                                            }
                                                        });
                                                        setShow(true);
                                                    }}/>  <FiTrash cursor='pointer' size='18' onClick={() => {
                                                        setType({
                                                            name: 'alertMsg',
                                                            type: 'normalMsg', value:
                                                            {
                                                                message: 'Deseja realmente rejeitar a indicação selecionada?',
                                                                func: handleReprove,
                                                                dataForFunc: indication
                                                            }
                                                        });
                                                        setShow(true);
                                                    }} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                ) :
                                (
                                    <tr key='EmptyTable'>
                                        <td colSpan='7'>-- Não há indicações --</td>
                                    </tr>
                                )}
                    </tbody>
                </table>
            </div>
        )
    }
    // console.log(myIndicationsList)
    /*Funções e tabela para Assessores*/
    function myClientsIndicatedListFilter() {
        if (filtered === null || filtered === undefined || filtered === '') {
            return myIndicationsList;
        } else {
            return myIndicationsList.filter((filterRow) => {
                if (filterRow?.client_external_id?.indexOf(filtered) > -1 && selectedFiter === 'Código' || 
                    filterRow?.client_name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1 && selectedFiter === 'Cliente') {
                    return filterRow;
                }
            })
        }
    }

    const handleDelete = async (indication) => {

        setLoading(true);
        try {
            await api.delete(`api/v1/clientsIndications/${indication?.id}/delete`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao excluir indicação' } });
        } catch (error) {
            alert(error?.response?.data?.message)
            setLoading(false);
        }
    }

    const handleApprove = async (indication) => {
        setLoading(true);

        try {
            await api.put(`api/v1/clientsIndications/${indication?.id}/approve`, {}, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao aceitar indicação' } });
        } catch (error) {
            alert(error?.response?.data?.message)
            setLoading(false);
        }
    }

    const handleReprove = async (indication) => {
        setLoading(true);
        try {
            await api.put(`api/v1/clientsIndications/${indication?.id}/reprove`, {
                "observation": undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao rejeitar indicação' } });
        } catch (error) {
            alert(error?.response?.data?.message)
            setLoading(false);
        }
    }

    function myClientsIndicatedReccords() {
        return (
            <div className='clientsIndicationPendingsTableContainer' id='clientsIndicationAdvisorsPendingsTableContainer' onScrollCapture={() => {

                setScrollTable(document.getElementById('clientsIndicationAdvisorsPendingsTableContainer'))
                if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                    setBottomScroll(true);
                }
            }}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan='6'>
                                <div className='clientsIndicationPendingsTableFirstThead'>
                                    <p>Meus Clientes fora da Mesa</p>
                                    <div className='substitutoLabelDisplay'>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            const output = [];
                                            const titles = ['Código', 'Cliente', 'Perfil', 'Status', 'NET RV']
                                            output.push(titles.join('\t'))
                                            const keys = ['client_external_id', 'client_name','client_profile','status', 'net_stock'];
                                            myIndicationsList.forEach((x) => {
                                                const tmp = keys.map((y) =>{return y==='net_stock' ? String(x[y]).replace('.',',') : x[y]});
                                                output.push(tmp.join('\t'))
                                            })
                                            const table = output.join('\n')
                                            navigator.clipboard.writeText(table)
                                            alert('Conteúdo da tabela copiado para a área de transferência')
                                        }}
                                    ><FiCopy />Copiar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            indicationsExport();
                                    }}><FiArrowDown />Exportar</button>
                                    <select onChange={(e) => setSelectedFilter(e.target.value)} className='selectFilterTable'>
                                        <option defaultValue>Código</option>
                                        <option>Cliente</option>
                                    </select>
                                    <input placeholder='Filtrar' onChange={(e) => setFiltered(e.target.value)}></input>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>Código</th>
                            <th>Cliente</th>
                            <th>Perfil</th>
                            <th>Status</th>
                            <th>NET RV</th>
                            <th>Indicação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <td colSpan='6' id='myIndicationsPendingsHomeLoader'>
                                <ArrowLoader />
                            </td>
                        ) :
                            myClientsIndicatedListFilter()?.length > 0 ?
                                (
                                    myClientsIndicatedListFilter()?.map((indication, index) => {

                                        if (index < scrollTableLines && bottomScroll && index + 1 === scrollTableLines) {
                                            return (
                                                <tr key={index} id={bottomScroll ? 'clientsShowLoadingScroll' : 'clientsHideLoadingScroll'}>
                                                    <td colspan='10'>
                                                        <div class='ldsellipsis'><div></div><div></div><div></div><div></div></div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        else if (index < scrollTableLines) {
                                            return (
                                                <tr>
                                                    <td>{indication?.client_external_id}</td>
                                                    <td>{titleize(indication?.client_name)}</td>
                                                    <td>{indication?.client_profile}</td>
                                                    <td>{indication?.status}</td>
                                                    <td>{toBRL(indication?.net_stock)}</td>
                                                    <td>
                                                        <FiPlusSquare cursor='pointer' color="#BEA032" size='18' onClick={() => {
                                                            setType({ name: 'clientIndicationBox', client_external_id: indication?.client_external_id, client_name: indication?.client_name });
                                                            setShow(true);
                                                        }}/>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                ) :
                                (
                                    <tr>
                                        <td colSpan='6'>-- Não há indicações --</td>
                                    </tr>
                                )}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        // (user?.type === 2 || user?.type === 1 || user?.type === 3) ?
        //     (
        //         myIndicationToApproval()
        //     ) :
        //     (
        //         myClientsIndicatedReccords()
        //     )
        myClientsIndicatedReccords()
    )
}
