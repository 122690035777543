import React from 'react';
/*Components*/
import { MyPushOrdersTable } from './myPushOrders.table'
import Modal from '../../../../components/modal/modal'
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
import { MyPushOrderErrorsTable } from './myPushOrderErrorTable';
/*Styles*/
import './styles/myPushOrders.css'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'

export function MyPushOrders() {

    const { type, setType, show, setShow } = useModal()

    return (
        <div className='myPushOrdersContainer' style={{minWidth: !show ? '1315px' : '0'}}>
            <Modal>
                {
                    type?.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :

                        type.name === 'showMyOrdersPushErrors' ? 
                        (
                         <MyPushOrderErrorsTable data={type?.value?.errors} />
                        ) :
                        (<></>)
                }
            </Modal>
            <MyPushOrdersTable />
        </div>
    )
}
