// FUNCAO TRAVA TELA (HORARIO DEVERÁ VIR DO BD) //

export  const endData = {
    'endHour': '23:00'
}

export function validTime() {

    const date = new Date();
    const todayHours = parseInt(date.getUTCHours() - 3);
    const todayMinutes = parseInt(date.getUTCMinutes());
    const [hours, minutes] = endData.endHour.split(':');

    if ((todayHours < parseInt(hours)) || (todayHours == parseInt(hours) && todayMinutes <= parseInt(minutes))) {
        return true
    }
}



