/**
 * Component boleta referente a operação BookRubi
 * Desenvolvido por: Rafael Cezário
 */

import React, { useEffect, useState, useReducer } from 'react';
/*Styles*/
import './styles/booksOrder.css';
import { FiCheck, FiTrash2 } from 'react-icons/fi';
import { BsPlusCircle } from 'react-icons/bs';
/*Contexts*/
import { useAuth } from '../../../../../contexts/useAuth';
import { useModal } from '../../../../../contexts/useModal';
import { useBooks } from '../../../../../contexts/useBooks';
/*Services*/
import { api } from '../../../../../services/api';
/*Components*/
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader';
/*Utils*/
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';

export default function OrderTable(props) {
    const isToCleanCheckBox = props?.cleanCheckBoxes;
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const { user, tokenKey } = useAuth();
    const { type, setType, edited, setEdited, setShow, show } = useModal();
    const { loadOrders, setLoadOrders } = useBooks();
    const [myBookOrdersTemp, setMyBookOrdersTemp] = useState([]);
    const [ordersItens, setOrdersItens] = useState([]);
    const [totalFinancialEstimated, setTotalFinancialEstimated] = useState(0);
    const [selectedOrders, setSelectedOrders] = useState([]);

    const [, forceUpdate] = useReducer(x => x + 1, 0);


    useEffect(() => {
        async function getTempOrders() {
            setLoading(true);
            try {
                const tempOrdersResult = await api.get(`api/v1/booksOrdersTemp/users/${user?.external_id}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setMyBookOrdersTemp(tempOrdersResult?.data?.data);
                valueReccordOpenOrders(tempOrdersResult?.data?.data);
                setLoading(false);
            } catch (error) {
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
                setLoading(false);
            }
        }

        function valueReccordOpenOrders(newOrdersValue) {
            if (newOrdersValue?.length > 0) {
                const calculatedFinancial = newOrdersValue?.map((myOrder) => {
                    return myOrder?.value * myOrder?.quantity
                }).reduce((total, currentElement) => total + currentElement)
                setTotalFinancialEstimated(calculatedFinancial);
            } else {
                setTotalFinancialEstimated(0);
            }
        }

        if (loadOrders || myBookOrdersTemp?.length === 0) {
            getTempOrders();
            setLoadOrders(false);
        }
    }, [loadOrders])

    useEffect(() => {
        setTimeout(() => {
            setShowSuccess(false);
        }, 3000)
    }, [showSuccess])

    useEffect(() => {
        checkAllThisClient(false);
        checkAll(false);
    }, [isToCleanCheckBox])

    useEffect(() => {
        setLoadOrders(true)
    }, [show]);

    async function handleOrdersSubmit() {

        if (selectedOrders?.length === 0) {
            alert('Selecione uma ou mais ordens para enviar');
            return
        }

        if (window.confirm('Deseja enviar todas as ordens selecionadas?')) {

            setLoading(true);
            try {
                await api.post(`api/v1/booksOrdersTemp/sendToEffective/booksOrders`, {
                    'orders': selectedOrders
                }, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setEdited(!edited);
                setLoadOrders(true);
                setShowSuccess(true);
                setSelectedOrders([]);
                checkAll(false);
            } catch (error) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value:
                    {
                        message:
                            error?.response?.data?.message == undefined ?
                                'Erro ao relizar requisiçaõ' :
                                error?.response?.data?.message
                    }
                })
                checkAll(false);
                setSelectedOrders([]);
                setShow(true);
            }
        }
    }

    async function handleDelete() {
        if (selectedOrders?.length === 0) {
            return alert('Nenhuma Ordem foi selecionada!');
        }
        setLoading(true);
        try {
            await api.delete(`/api/v1/booksOrdersTemp`, {

                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                data: {
                    "id": selectedOrders
                }
            })
            checkAll(false);
            setSelectedOrders([]);
            setLoadOrders(true);
            setEdited(!edited);
        } catch (error) {
            setSelectedOrders([]);
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
            setShow(true);
        }

    }

    function toBRL(value) {
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })
    }

    function checkAll(check) {
        const checkBoxes = document.querySelectorAll('input[name="ordersClientCheckBox"]');
        const checkBoxesSpecificClient = document.querySelectorAll('input[name="checkBoxSpecificClient"]');
        setChecked(check);
        let listToInsert = [];

        myBookOrdersTemp?.map((myOrder) => {
            listToInsert.push(parseInt(myOrder?.id));
        })
        if (check === true) {
            setSelectedOrders(listToInsert)
        }
        else {
            setSelectedOrders([]);
        }
        checkBoxes.forEach((checkBox) => {
            checkBox.checked = check;
        })
        checkBoxesSpecificClient.forEach((checkBox) => {
            checkBox.checked = check
        })
    }

    function checkAllThisClient(clients_external_id, checked) {

        const listToFind = [];
        const newListToInsert = [];

        const allInputsThisClient = document.querySelectorAll(`input[id="${clients_external_id}"]`);
        allInputsThisClient.forEach((input) => {
            listToFind.push(parseInt(input.getAttribute('itemid')))
            input.checked = checked
        });
        if (checked) {
            listToFind.map((itemToInsert) => {
                let itemToInsertExistInselectedOrders = selectedOrders.indexOf(itemToInsert);
                if (itemToInsertExistInselectedOrders === -1) {
                    newListToInsert.push(itemToInsert)
                }
            })
            setSelectedOrders([...selectedOrders, ...newListToInsert])
        }

        if (!checked) {
            listToFind.map((itemToRemove) => {
                let itemToRemoveExistInSelectedOrders = selectedOrders.indexOf(itemToRemove);
                if (itemToRemoveExistInSelectedOrders > -1) {
                    selectedOrders.splice(itemToRemoveExistInSelectedOrders, 1)
                }
            })

            setSelectedOrders(selectedOrders)
        }
    }

    function ordersReccords() {

        if (myBookOrdersTemp !== null && myBookOrdersTemp.length > 0) {

            const totalAcumulated = [];

            return myBookOrdersTemp.map((myOrder, index) => {

                const totalThisOrder = (myOrder?.value * myOrder?.quantity);
                const commissionThisOrder = myOrder?.comission;
                const totalAcumulatedThisClient = totalAcumulated.map((position) => {
                    return position.clients_external_id
                }).indexOf(myOrder?.clients_external_id)

                if (totalAcumulatedThisClient === -1) {
                    totalAcumulated.push(
                        {
                            clients_external_id: myOrder?.clients_external_id,
                            value: totalThisOrder,
                            commission: commissionThisOrder
                        })
                } else {
                    totalAcumulated[totalAcumulatedThisClient]['value'] =
                        totalAcumulated[totalAcumulatedThisClient]['value'] + totalThisOrder

                    totalAcumulated[totalAcumulatedThisClient]['commission'] =
                        totalAcumulated[totalAcumulatedThisClient]['commission'] + commissionThisOrder
                }

                return (
                    <>
                        <tr key={myOrder?.id} id='bookOrdersTr'>
                            <td>
                                <input
                                    name='ordersClientCheckBox'
                                    id={myOrder?.clients_external_id}
                                    type='checkbox'
                                    itemID={myOrder?.id}
                                    onChange={
                                        (e) => {
                                            const allCheckBoxesThisClient = document.querySelectorAll(`input[id="${myOrder.clients_external_id}"]`)
                                            const allCheckBoxes = document.querySelectorAll('input[id="selectAllCheckBox"]');
                                            const allInputCheckBoxes = document.querySelectorAll('input[name="ordersClientCheckBox"]');
                                            const inputAllForThisClient = document.querySelectorAll(`input[id="${myOrder?.clients_external_id}CheckButton"]`)
                                            const checkedBoxesThisClientChecked = [];
                                            const checkedBoxesChecked = [];
                                            const uncheckedBoxesChecked = [];

                                            allCheckBoxesThisClient.forEach((checkBoxThisCliente) => {
                                                if (checkBoxThisCliente.checked) {
                                                    checkedBoxesThisClientChecked.push('1')
                                                }
                                            })

                                            allInputCheckBoxes.forEach((thisInput) => {
                                                if (thisInput.checked === false) {
                                                    checkedBoxesChecked.push('1');
                                                } else {
                                                    uncheckedBoxesChecked.push('1');
                                                }
                                            })
                                            if (e.target.checked) {
                                                if (allInputCheckBoxes.length - 1 === uncheckedBoxesChecked.length) {
                                                    allCheckBoxes[0].checked = true;
                                                }
                                                if (allCheckBoxesThisClient?.length === checkedBoxesThisClientChecked?.length) {
                                                    inputAllForThisClient.forEach((thisInput) => {
                                                        thisInput.checked = e.target.checked
                                                    })
                                                }
                                                setSelectedOrders([...selectedOrders, parseInt(myOrder?.id)])
                                            } else {
                                                if (allInputCheckBoxes.length - 1 >= checkedBoxesChecked.length) {
                                                    allCheckBoxes[0].checked = false;
                                                }
                                                if (allCheckBoxesThisClient > checkedBoxesThisClientChecked) {
                                                    inputAllForThisClient.forEach((thisInput) => {
                                                        thisInput.checked = e.target.checked
                                                    })
                                                }
                                                selectedOrders.splice(selectedOrders.indexOf(parseInt(myOrder?.id)), 1)
                                                setSelectedOrders(selectedOrders)
                                            }
                                        }
                                    }
                                >
                                </input>
                            </td>
                            <td title={myOrder.clients_name}>{titleize(myOrder.clients_name)}</td>
                            <td>{myOrder.asset}</td>
                            <td>{myOrder.quantity}</td>
                            <td>{myOrder.operations_types_id === 2 ? 'Compra' : (myOrder.operations_types_id === 3 ? 'Sob Custódia' : 'Prêmio')}</td>
                            <td >{toBRL(totalThisOrder)}</td>
                            <td>{toBRL(commissionThisOrder)}</td>
                            <td>{myOrder.book_name}</td>
                            <td className={myOrder.status === 1 ? '' : 'sendOrdersHide'}>
                                <a>Enviado</a>
                            </td>
                        </tr>
                        {
                            (
                                (
                                    myBookOrdersTemp[index + 1]?.['clients_external_id'] === undefined
                                    || myOrder?.clients_external_id !== myBookOrdersTemp[index + 1]?.['clients_external_id']
                                ) &&
                                (index !== 0
                                    ||
                                    (
                                        index === 0 &&
                                        myBookOrdersTemp[index + 1]?.['clients_external_id'] === undefined
                                        || myOrder?.clients_external_id !== myBookOrdersTemp[index + 1]?.['clients_external_id']
                                    )
                                )
                            ) ?
                                (<tr id='bookOrdersTotalTr'>
                                    <td colSpan='1'>
                                        <input
                                            id={myOrder?.clients_external_id + 'CheckButton'}
                                            name='checkBoxSpecificClient'
                                            type='checkbox'
                                            onChange={
                                                (e) => {
                                                    checkAllThisClient(myOrder.clients_external_id, e.target.checked);
                                                }
                                            }
                                        >
                                        </input>
                                    </td>
                                    <td colSpan='4'>
                                        <label id='bookOrdersTotalLabel'>
                                            <a>
                                                Total:
                                            </a>
                                        </label>
                                    </td>
                                    <td colSpan='1'> {toBRL(totalAcumulated[totalAcumulated.map((position) => {
                                        return position.clients_external_id
                                    }).indexOf(myOrder?.clients_external_id)]['value'])}
                                    </td>
                                    <td colSpan='1'>
                                        {toBRL(totalAcumulated[totalAcumulated.map((position) => {
                                            return position.clients_external_id
                                        }).indexOf(myOrder?.clients_external_id)]['commission'])}
                                    </td>
                                    <td colSpan='1'></td>
                                </tr>) :
                                (<></>)
                        }
                    </>
                )
            }
            )
        } else {

            return (
                <tr>
                    <th colSpan='8'>
                        <a>vazia</a>
                    </th>
                </tr>
            )
        }
    }

    return (
        <div id='rubiOrderContainer' >
            <table>
                <thead id='rubiOrder'>
                    <tr>
                        <th colSpan='8' className='rubiOrderHeader'>
                            <div>
                                <section>
                                    <h1>Boleta</h1>
                                </section>
                                <section>
                                    <p>Financeiro Estimado:</p><p>{toBRL(totalFinancialEstimated)}</p>
                                </section>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan='8'>
                            <label id='bookOrdersButtons'>
                                <div className='rubiOrderButton'
                                    title='Limpar operações selecionadas'
                                    onClick={() => {
                                        handleDelete()
                                    }}>
                                    <FiTrash2 />
                                    <a> Limpar</a>
                                </div>
                                {user.type < 3 ? (
                                    <div className='rubiOrderButton'
                                    title='Importar operações'
                                    onClick={() => {
                                        setType({ name: 'booksOrdersImport', forceUpdate: forceUpdate });
                                        setShow(true);
                                    }}>
                                    <BsPlusCircle />
                                    <a> Importar</a>
                                    </div>
                                ) : <></>

                                }
                               
                                <div className='rubiOrderButton'
                                    title='Enviar para Backoffice as operações selecionadas'
                                    onClick={() =>

                                        handleOrdersSubmit()
                                    }>
                                    <FiCheck />
                                    <a>Enviar</a>
                                </div>
                                <div id='bookOrdersMsgSendOrDelete'>
                                    <a id={showSuccess ? 'bookOrdersMsgShowSuccess' : 'bookOrdersMsgHideMsg'}>Orden(s) enviadas!</a>
                                </div>
                            </label>
                        </th>
                    </tr>
                    <tr className='headerTh'>
                        <th>
                            <input
                                type='checkbox'
                                id='selectAllCheckBox'
                                name='ordersClientCheckBox'
                                onClick={() => {
                                    checkAll(!checked)
                                }}
                            >
                            </input>
                        </th>
                        <th>Cliente</th>
                        <th>Ativo</th>
                        <th>Qtde</th>
                        <th>Modalidade</th>
                        <th>Financeiro</th>
                        <th>Comissão estimada</th>
                        <th>Book</th>
                    </tr>
                </thead>
                <tbody id='rubiOrder'>
                    {loading ? (
                        <tr>
                            <td colSpan='8' id='bookOrdersLoadingTr'>
                                <ArrowLoader />
                            </td>
                        </tr>
                    ) :
                        (
                            ordersReccords()
                        )}
                </tbody>
            </table>
        </div>
    )
}