import React, { useEffect, useState, useReducer } from 'react'
import '../../monitoringPanel/myReservations/styles/myReservations.details.css'

import { useAuth } from "../../../../contexts/useAuth";
import { api } from "../../../../services/api";
import { useModal } from '../../../../contexts/useModal'

import TextField from '@material-ui/core/TextField';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';

export function OffshoreEducationalModal(props) {
    const { tokenKey, user } = useAuth();
    const { type, show, setShow, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(false)
    const classes = styleClientsInput()
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    useEffect(() => {
        forceUpdate()
        document.addEventListener('keydown', (e) => {
            e.key === 'Escape' && setType({})
        })
        return () => document.removeEventListener('keydown', (e) => e)
    }, [show])

    let canalOrigem = props?.data?.value?.data[1]
    let descricao = props?.data?.value?.data[2]
    let link = props?.data?.value?.data[3]

    async function handleSubmit() {
        if (props?.data?.name === 'editOffshore') {
            try {
                const updateMaterial = await api.put(`/api/v1/alocacao/products/offshore/${props?.data?.value?.data[0]}`,
                    {
                        source: (canalOrigem !== null | canalOrigem !== undefined) ? canalOrigem : props?.data?.value?.data[1],
                        description: (descricao !== null | descricao !== undefined) ? descricao : props?.data?.value?.data[2],
                        link: (link !== null | link !== undefined) ? link : props?.data?.value?.data[3]
                    }, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setType({ name: 'alertMsg', type: 'information', value: { message: 'Material de offshore atualizado com sucesso!' } })
            } catch (error) {
                console.log(error?.response)
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar material: ' : error.response.data.message } })
                setShow(true)
                setLoading(false)
            }
        } else {
            try {
                const createMaterial = await api.post('/api/v1/alocacao/products/offshore',
                    {
                        source: (canalOrigem !== null | canalOrigem !== undefined) ? canalOrigem : props?.data?.value?.data[1],
                        description: (descricao !== null | descricao !== undefined) ? descricao : props?.data?.value?.data[2],
                        link: (link !== null | link !== undefined) ? link : props?.data?.value?.data[3]
                    }, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                setType({ name: 'alertMsg', type: 'information', value: { message: 'Material de offshore criado com sucesso!' } })
            } catch (error) {
                console.log(error?.response)
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao criar material: ' : error.response.data.message } })
                setShow(true)
                setLoading(false)
            }
        }
    }

    return (
        <div className='myReservationDetails'>
            {!loading ?
                <>
                    <div className='myReservationsHeader'>
                        <p>{props?.data?.name === 'editOffshore' ? 'Edição ' : 'Criação '}de Material</p>
                        <button
                            onClick={() => {
                                setShow(false)
                                setType([])
                            }}
                            className='modalCloseButton'>
                            x
                        </button>
                    </div>
                    <div className='myReservationEmptyBody'>
                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <TextField
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                label={'Canal de Origem'}
                                type={'text'}
                                variant='outlined'
                                defaultValue={props?.data?.value?.data[1] ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.styleForClients}
                                onChange={(e) => {
                                    canalOrigem = e.target.value
                                }}
                            />
                        </div>
                        <div className="itemBodyOperateProductAbc fieldsOperateAbc" style={{ width: '600px' }}>
                            <TextField
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                label={'Descrição'}
                                type={'text'}
                                variant='outlined'
                                defaultValue={props?.data?.value?.data[2] ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.styleForClients}
                                onChange={(e) => {
                                    descricao = e.target.value
                                }}
                            />
                        </div>
                        <div className="itemBodyOperateProductAbc fieldsOperateAbc" style={{ width: '600px' }}>
                            <TextField
                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                label={'Link'}
                                type={'text'}
                                variant='outlined'
                                defaultValue={props?.data?.value?.data[3] ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.styleForClients}
                                onChange={(e) => {
                                    link = e.target.value
                                }}
                            />
                        </div>
                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <button className="buttonOperateSubmitABC" onClick={() => handleSubmit()}>Enviar</button>
                        </div>
                    </div>
                </> : <ArrowLoader />
            }
        </div>
    )

}