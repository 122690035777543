import React, {useEffect, useState} from 'react';

export function ModalSizeFitToScreen(){

    const [screenX, setScreenX] = useState(0);
    const [screenY, setScreenY] = useState(0);
    const [screenW, setScreenW] = useState(0);
    const [screenH, setScreenH] = useState(0);

    window.addEventListener('resize', function(){
            fitModalToScreen();
    });

    useEffect(()=> {
        fitModalToScreen();
    },[])

    function fitModalToScreen(){

        setScreenX(window.innerWidth - 100);
        setScreenY(window.innerHeight - 120);
        
        if(window.innerHeight > 450 && window.innerWidth > 700){
            setScreenW(window.innerWidth - 100);
            setScreenH(window.innerHeight - 120);
        }else {
            setScreenW('50rem');
            setScreenH('25rem');
        }
    }

    return { screenX: screenX, screenY: screenY, screenW: screenW, screenH: screenH}
}