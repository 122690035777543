import React, { useState, useEffect } from 'react'
import './dashboardTextEditor.css'

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { useModal } from '../../../contexts/useModal';
import { api } from '../../../services/api';
import { useAuth } from '../../../contexts/useAuth';

/*Components*/
import { ArrowLoader } from '../../../components/loaders/arrowLoader'

export function DashboardTextEditor() {

    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [textContent, setTextContent] = useState('')
    const [previewText, setPreviewText] = useState('')
    const [previewTextShow, setPreviewTextShow] = useState(false)
    const [inEdit, setInEdt] = useState(false)
    const [editedText, setEditedText] = useState(false)
    const [selectedTitle, setSelectedTitle] = useState('')
    const [oldTitle, setOldTitle] = useState('')

    const { screenX, screenY } = ModalSizeFitToScreen()
    const { show, setShow, type, setType, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const requireFields = [
        { field: 'selectedTitle', value: selectedTitle },
        { field: 'previewText', value: previewText }
    ]
    const [errorFields, setErrorFields] = useState({
        "oldTitle": false,
        "textContent": false
    })

    async function handleTextSubmit() {

        let errors = false
        setLoading(true)
        requireFields.map((required) => {
            if (required?.value === '' || required?.value === '<p></p>\n' || required?.value?.indexOf(' ') === 0 || required.value.indexOf('&nbsp')===3) {
                errors = true
                errorFields[required.field] = true
            } else {
                errorFields[required.field] = false
            }
        })

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            if (errorFields['selectedTitle'] && errorFields['previewText']) {
                alert('Título e texto estão vazios, ou começam com um espaço em branco, não permitido, verifique')
            }
            else if (errorFields['selectedTitle'] && !errorFields['previewText']) {
                alert('Título está vazio, ou inicia com espaço, verifique')
            } else if (!errorFields['selectedTitle'] && errorFields['previewText']) {
                alert('Texto vazio, ou inicia com espaço, não permitido, verifique')
            }
            setErrorFields(errorFields)
            setLoading(false)
            return
        }

        if (!editedText && type.type === 'edit') {
            return alert('Não houve alterações no texto')
        }
        try {
            await api.post(`/api/v1/dashboard`, {
                title: selectedTitle,
                details: previewText
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto registrado com sucesso!' } })
            setShow(true)
            setEdited(true)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao enviar texto' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    async function handleEditText() {


        let errors = false
        setLoading(true)
        requireFields.map((required) => {
            if (required?.value === '' || required?.value === '<p></p>\n' || required?.value?.indexOf(' ') === 0 || required.value.indexOf('&nbsp')===3) {
                errors = true
                errorFields[required.field] = true
            } else {
                errorFields[required.field] = false
            }
        })

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            if (errorFields['selectedTitle'] && errorFields['previewText']) {
                alert('Título e texto estão vazios, ou começam com um espaço em branco, não permitido, verifique')
            }
            else if (errorFields['selectedTitle'] && !errorFields['previewText']) {
                alert('Título está vazio, ou inicia com espaço, verifique')
            } else if (!errorFields['selectedTitle'] && errorFields['previewText']) {
                alert('Texto vazio, ou inicia com espaço, não permitido, verifique')
            }
            setErrorFields(errorFields)
            setLoading(false)
            return
        }


        setLoading(true)
        try {
            await api.put(`/api/v1/dashboard/${type?.idText}`, {
                title: selectedTitle,
                details: previewText
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto atualizado com sucesso!' } })
            setShow(true)
            setEdited(true)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao editar este texto, tente novamente' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }
    useEffect(() => {

        setEditedText(false)
        //Coleta o texto do contexto para exibir no editor
        if (show && type.type === 'edit') {

            const sampleMarkup = type?.state?.length > 0 ? type.state : '<a></a>'
            const blocksFromHTML = convertFromHTML(sampleMarkup);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(() => EditorState.createWithContent(state))
            setOldTitle(type?.title)
            setSelectedTitle(type?.title)
        } else {
            setEditorState(() => EditorState.createEmpty())
            setSelectedTitle('')
        }
    }, [show])

    useEffect(() => {

        if (!editedText) {
            setEditedText(true)
        }
        if ((draftToHtml(convertToRaw(editorState.getCurrentContent()))).length < 81) {
            setPreviewText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }
    }, [editorState])

    return (
        <div id='dashboardTextEditorMainContainer' style={{ width: screenX - 50, minWidth: 800, height: screenY, minHeight: 520 }}>
            {
                loading ?
                    <div className='textEditorLoading'>
                        <ArrowLoader />
                    </div>
                    :
                    <>
                        <div id='dashboardTextEditorHeader'>
                            {
                                type?.type === 'edit' ?
                                    <p>Edite o texto abaixo</p>
                                    :
                                    <p>Cadastre um novo texto para listar no dashboard</p>
                            }
                            <button className='modalCloseButton' onClick={() => setShow(false)}>x</button>
                        </div>
                        <div className='dashboardTextTitleDiv'>
                            <a>Título:</a>
                            <input maxLength={40} style={{ border: errorFields['oldTitle'] && oldTitle === '' ? '1px solid #eb6157' : '', borderRadius: '4px' }} id='dashboardTextTitleEditor' placeholder={type.type === 'edit' ? type.title : ''} value={selectedTitle} onChange={(e) => setSelectedTitle(e.target.value)}></input>
                        </div>


                        <div className="dashboardTextDetailsBody" style={{ width: screenX - 200, minWidth: 600, height: screenY - 200, maxHeight: screenY - 200, minHeight: 320 }}>
                            <div id='dashboardTextEditor'>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={setEditorState}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'emoji', 'list', 'textAlign',
                                            'remove', 'history'],
                                    }}
                                />
                            </div>
                        </div>

                        {
                            type?.type === 'edit' ?
                                <button id='dashboardTextEditorButton' onClick={() => handleEditText()}>Confirmar Edição</button>
                                :
                                <button id='dashboardTextEditorButton' onClick={() => handleTextSubmit()}>Confirmar</button>
                        }
                    </>
            }
        </div>
    )
}