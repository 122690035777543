export default function formatCurrency(idRows) {

  var z = document.getElementById(idRows);

  let v = z.value;
  v = v.replace(/\D/g, "") // permite digitar apenas numero

  v = v.replace(/(\d{1})(\d{23})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{20})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{17})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{14})$/, "$1.$2") // coloca ponto antes dos ultimos digitos
  v = v.replace(/(\d{1})(\d{11})$/, "$1.$2") // coloca ponto antes dos ultimos 11 digitos
  v = v.replace(/(\d{1})(\d{8})$/, "$1.$2") // coloca ponto antes dos ultimos 8 digitos
  v = v.replace(/(\d{1})(\d{5})$/, "$1.$2") // coloca ponto antes dos ultimos 5 digitos
  v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2") // coloca virgula antes dos ultimos 2 digitos
  z.value = v;
}