/*Componente detalhe de clientes
/*Descrição: Exibe os detalhes e permite alteração do cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

//libraries
import React, { useState, useEffect } from 'react';
import AntSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { useModal } from '../../../../contexts/useModal';
//services
import { api } from '../../../../services/api';
import { myAdvisorsClients } from '../../../../services/asyncFunctions';
//styles
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import './styles/clients.columnsToView.css';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';

export function VbaColumnsToView(props) {

    console.log('props',props.data)

    const { user, tokenKey } = useAuth();
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const [statusThisClient, setStatusThisClient] = useState('');
    const [statusList, setStatusList] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [listBrokers, setListBrokers] = useState([]);
    const [listAdvisor, setListAdvisor] = useState([]);
    const [advisorList, setAdvisorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inEditProfileBroker, setInEditProfileBroker] = useState([]);
    const [successEdit, setSuccessEdit] = useState(false);
    const [statusUpdatedToActive, setStatusUpdatedToActive] = useState(false);
    const [brokerRemoved, setBrokerRemoved] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [profileList, setProfileList] = useState([]);
    const [clientIsUpdated, setClientIsUpdated] = useState(false);
    const [clientStatusUpdated, setClientStatusUpdated] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [brokerList, setBrokerList] = useState([]);
    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const [selectedClientName, setSelectedClientName] = useState('');
    const [selectedClientProfile, setSelectedClientProfile] = useState('');
    const [selectedOperatesAlone, setSelectedClientOperatesAlone] = useState('');
    const [selectedNetStock, setSelectedNetStock] = useState('');
    const [selectedNetTotal, setSelectedNetTotal] = useState('');
    const [selectedBrokers, setSelectedBrokers] = useState([]);
    const [selectedAdvisor, setSelectedAdvisor] = useState([]);
    const [data, setData] = useState([]);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    const [selectedClient, setSelectedClient] = useState(null);
    
    function clear() {
        setSuccessEdit(false);
        setSelectedClient(null)
        setShowConfirmButton(false)
        setShow(false)
    }

    async function handleChange() {
        props.data.alterViewColumns(props.data.columns)
    }



    return (
        <div className='clientDetailsContainer' >
            {loading ?
                (<div id='clientDetailsLoader'>
                    <ArrowLoader />
                </div>
                ) :
                (<>
                    <div className='clientsDetailsHeader'>
                        <div>
                            <p>Colunas visíveis</p>
                        </div>
                        <button onClick={() => clear()}>x</button>
                    </div>
                    <div className='parent'>
                    {props.data.columns.map((column,index) => {
                        return (
                                <div className='child'>
                                    <input type="checkbox" id={column.name} name={column.name} className='checkbox' disabled={(column.name === 'codigo' || column.name === 'nome') ?? 'disabled'} defaultChecked={column.options.display ?? 'checked'} onChange={(e) => {
                                        props.data.columns[index].options.display = e.target.checked
                                    }}></input>
                                    {titleize(column.label)}
                                </div>
                                )
                            })}
                    </div>

                
                    <span className='itemButton'>
                            <button className='buttonSetToBaseConfirm' onClick={() => handleChange()}>Aplicar</button>
                            {/* <button className='buttonSetToBaseConfirm' onClick={() => setShowConfirmButton(false)}>Cancelar</button> */}
                    </span>       
                </>
                
                )
                
            }
        </div>
    )
}