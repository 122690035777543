import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
/*Styles*/
import './styles/myPushOrders.table.css'
import { styleForDate } from '../../../../components/styles/autocompletField'
import { api } from '../../../../services/api'
import { FiTrash2,FiArrowDown } from 'react-icons/fi'
import { BsDownload } from 'react-icons/bs'
/*Utils*/
import { getPushOrdersOperationsAll } from '../../../../services/asyncFunctions';
import { getPushOrdersOperationsPeriods } from '../../../../services/asyncFunctions';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency'
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { recalculateFooterData } from '../../../../utils/recalculateFooterDataOffers'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal'
import { useSocket } from '../../../../contexts/useSocket'
/*Components*/
import { ArrowLoaderWOText } from '../../../../components/loaders/loaderWithoutText'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'

export function MyPushOrdersTable(props) {

    const now = new Date()
    const { reloadPushOrders, setReloadPushOrders, emitForEveryOne, setEmitForEveryOne } = useSocket(false)
    const [showMessage, setShowMessage] = useState(false)
    const [hideMessage, setHideMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingPeriods, setLoadingPeriods] = useState(true)
    const [downloadTable, setDownloadTable] = useState(false)
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [selectedDate, setSelectedDate] = useState('');
    const classes = styleForDate();
    const [pushOrdersOperationPeriodsList, setPushOrdersOperationList] = useState([])
    const [pushOrdersList, setPushOrdersList] = useState([])
    const [selectedDateMonth, setSelectedDateMonth] = useState();
    const [selectedDateYear, setSelectedDateYear] = useState();
    const [selectedPeriod, setSelectedPeriod] = useState({ month: now.getMonth() + 1, year: now.getFullYear() })
    const [selectedOrdersToDelete, setSelectedToDelete] = useState([])
    const { user, tokenKey } = useAuth()
    const { setShow, type, setType, edited, setEdited } = useModal()
    const [totalValueThisPeriod, setTotalValueThisPeriod] = useState([])
    const [totalComissionThisPeriod, setTotalComissionThisPeriod] = useState([])
    const [totalClientsOperatedThisPeriod, setTotalClientsOperatedThisPeriod] = useState([])
    const [totalOperatedThisPeriod, setTotalOperationsThisPeriod] = useState([])
    const [operationStatusList, setOperationStatusList] = useState([])
    const sumValue = (previousValue, currentValue) => previousValue + currentValue
    const listMonths = [
        { month: 'Janeiro', value: 1 },
        { month: 'Fevereiro', value: 2 },
        { month: 'Março', value: 3 },
        { month: 'Abril', value: 4 },
        { month: 'Maio', value: 5 },
        { month: 'Junho', value: 6 },
        { month: 'Julho', value: 7 },
        { month: 'Agosto', value: 8 },
        { month: 'Setembro', value: 9 },
        { month: 'Outubro', value: 10 },
        { month: 'Novembro', value: 11 },
        { month: 'Dezembro', value: 12 },
    ]
    const [filtered, setFiltered] = useState({
        clients_name: [],
        asset: [],
        operations_types_description: [],
        vac: [],
        users_fullname: [],
        operation_status_description: [],
        created_at: [],
    })
    const [checked, setChecked] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [selectedOperations, setSelectedOperations] = useState([])
    const [selectedNewStatus, setSelectedNewStatus] = useState('')

    async function getOrdersReccords(month, year, load = true) {

        if (load) { setLoading(true) }
        const pushOrdersOperations = await getPushOrdersOperationsAll(user?.type > 2 ? user.external_id : undefined, tokenKey, month, year)
        if (pushOrdersOperations?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: pushOrdersOperations?.error
                }
            })
            setShow(true)
            setPushOrdersList([])
        } else {
            setPushOrdersList(pushOrdersOperations);
            setSelectedPeriod({ month: month, year: year })
            const clientsOperated = []
            const thisTotalClientsOperated = []
            const thisTotalValue = []
            const thisTotalComission = []
            const thisTotalOperated = []

            pushOrdersOperations.forEach((order) => {
                if (clientsOperated?.indexOf(order.clients_external_id) === -1) {
                    clientsOperated?.push(order.clients_external_id)
                    thisTotalClientsOperated?.push(1)
                }
                thisTotalValue?.push(order.quantity * order.value)
                thisTotalComission?.push(((order.quantity * order.value) / 100) * order.fee)
                thisTotalOperated?.push(1)
            })
            setTotalValueThisPeriod(thisTotalValue.reduce(sumValue));
            setTotalComissionThisPeriod(thisTotalComission.reduce(sumValue))
            setTotalOperationsThisPeriod(thisTotalOperated.reduce(sumValue))
            setTotalClientsOperatedThisPeriod(thisTotalClientsOperated.reduce(sumValue))
        }
        setLoading(false);
    }

    async function getOrdersPeriods() {

        const pushOrdersOperations = await getPushOrdersOperationsPeriods(user?.type > 2 ? user.external_id : undefined, tokenKey)
        if (pushOrdersOperations?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: pushOrdersOperations?.error
                }
            })
            setShow(true)
            setPushOrdersList([])
        } else {
            setPushOrdersOperationList(pushOrdersOperations)
            setLoadingPeriods(false)
        }
    }

    async function handleUpdateStatus() {

        try {

            setLoading(true)
            const updateOrdersStatus = await api.put(`/api/v1/ordersPush`, {
                'status': selectedNewStatus.id,
                'orders': selectedOperations
            },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Status atualizado com sucesso!' } })
            setShow(true)
            setEdited(true)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Ocorreu um erro ao atualizar o status' : error?.response?.data?.message } })
            setShow(true)
        }
        setLoading(false)
    }

    async function getOperationStatus() {
        try {
            const operationStatus = await api.get(`/api/v1/operationsStatus`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setOperationStatusList(operationStatus?.data?.data.filter((opertationStatusReccord) => {
                if (opertationStatusReccord.id === 0 || opertationStatusReccord.id === 2
                    || opertationStatusReccord.id === 7 || opertationStatusReccord.id === 8) {
                    return opertationStatusReccord
                }
            }))
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os status' } })
            setShow(true)
        }
    }

    async function notifyUser(all) {
        setShowMessage(all ? { notify: true, allUsers: true } : { notify: true, allUsers: false })
        await new Promise(resolve => setTimeout(resolve, 300))
        setHideMessage(false)
    }

    const handleDelete = async (data) => {

        setLoading(true)
        try {
            const deleteOrders = await api.delete(`/api/v1/ordersPush`, {

                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                data: {
                    "orders": selectedOperations
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Ordens excluidas com sucesso!' } })
            setShow(true)
            setEdited(true)
            setSelectedOperations([])
        } catch (error) {

            if (error?.response?.data?.details?.length > 0) {
                setType({
                    name: 'showMyOrdersPushErrors', value: {
                        errors: error?.response?.data?.details
                    }
                })
            } else {
                setType({
                    name: 'alertMsg', type: 'information', value: {
                        message: error?.response?.data?.message === undefined ? 'Erro ao remover Ordens' : error?.response?.data?.message
                    }
                })
            }
            setSelectedOperations([])
            setShow(true)
            setLoading(false)
        }
    }

    useEffect(() => {

        const month = now.getMonth()
        const year = now.getFullYear()
        setShowMessage(false)

        if (edited || pushOrdersList.length === 0) {

            setSelectedOperations([])
            getOrdersReccords(selectedDateMonth !== undefined ? selectedDateMonth.value : month + 1, selectedDateYear !== undefined ? selectedDateYear.year : year)
            getOrdersPeriods()
            getOperationStatus()
            setEdited(false)
        }

    }, [edited])

    useEffect(() => {

        if (listFilter().length > 0) {

            const data = recalculateFooterData(listFilter(), 'sumWithQuantity')
            setTotalValueThisPeriod(data.totalThisValue)
            setTotalOperationsThisPeriod(data.totalOperationsThisPeriod)
            setTotalClientsOperatedThisPeriod(data.totalClientsOperatedThisPeriod)
        }
    })

    useEffect(() => {

        const month = now.getMonth()
        const year = now.getFullYear()
        if (emitForEveryOne && reloadPushOrders) {
            notifyUser(true)
            setReloadPushOrders(false)
            setEmitForEveryOne(false)
        }
        else if (!emitForEveryOne && reloadPushOrders) {
            notifyUser(false)
            getOrdersReccords((selectedDateMonth?.value !== undefined && selectedDateMonth?.value !== null) ? selectedDateMonth?.value : month + 1, (selectedDateYear !== undefined && selectedDateYear !== null) ? selectedDateYear?.year : year, false)
            setReloadPushOrders(false)
        }
    }, [reloadPushOrders])

    function filterHead(filterToReturn) {
        const filterFind = [...new Map(pushOrdersList.map(item => [item[filterToReturn], item])).values()]
        return filterFind

    }

    function verifyIsFiltered(field, filterRow, column) {

        return field.length > 0 ?
            field.filter((filter) => {

                if (typeof (filterRow[column]) !== 'boolean' ? filterRow[column].toLowerCase() === filter.toLowerCase() :
                    filterRow[column] === filter) {
                    return filterRow;
                }
            }).length > 0
            :
            field.filter((filter) => {
                if (typeof (filterRow[column]) !== 'boolean' ? filterRow[column].toLowerCase() === filter.toLowerCase() :
                    filterRow[column] === filter) {
                    return filterRow;
                }
            }).length === 0
    }

    function handleChooseOption(element, fieldToFilter) {

        let filteredAux = {

            clients_name: [...filtered['clients_name']],
            asset: [...filtered['asset']],
            operations_types_description: [...filtered['operations_types_description']],
            vac: [...filtered['vac']],
            users_fullname: [...filtered['users_fullname']],
            operation_status_description: [...filtered['operation_status_description']],
            created_at: [...filtered['created_at']]
        }

        if (fieldToFilter === 'vac') {
            let newValue = element.target.value === 'Sim' ? true : false
            filteredAux[fieldToFilter] = [newValue]
        } else {
            filteredAux[fieldToFilter] = [element.target.value]
        }
        setFiltered({})
        setFiltered(filteredAux)
    }

    function handleClearOption(fieldToFilter, defaultValue) {

        let filteredAux = {
            clients_name: [...filtered['clients_name']],
            asset: [...filtered['asset']],
            operations_types_description: [...filtered['operations_types_description']],
            vac: [...filtered['vac']],
            users_fullname: [...filtered['users_fullname']],
            operation_status_description: [...filtered['operation_status_description']],
            created_at: [...filtered['created_at']]
        }
        document.getElementById(fieldToFilter).value = defaultValue
        filteredAux[fieldToFilter] = []
        setFiltered({})
        setFiltered(filteredAux)
    }

    function listFilter() {

        if (filtered.clients_name.length === 0 && filtered.asset.length === 0
            && filtered.vac.length === 0 && filtered.created_at.length === 0
            && filtered.operation_status_description.length === 0 && filtered.operations_types_description.length === 0
            && filtered.users_fullname.length === 0) {
            return pushOrdersList
        } else {

            return pushOrdersList.filter((filterRow) => {
                const clientFiltered = verifyIsFiltered(filtered.clients_name, filterRow, 'clients_name')
                const assetFiltered = verifyIsFiltered(filtered.asset, filterRow, 'asset')
                const operationTypeFiltered = verifyIsFiltered(filtered.operations_types_description, filterRow, 'operations_types_description')
                const vacFiltered = verifyIsFiltered(filtered.vac, filterRow, 'vac')
                const userFiltered = verifyIsFiltered(filtered.users_fullname, filterRow, 'users_fullname')
                const operationStatusFiltered = verifyIsFiltered(filtered.operation_status_description, filterRow, 'operation_status_description')
                const createdAtFiltered = verifyIsFiltered(filtered.created_at, filterRow, 'created_at')
                if (assetFiltered && clientFiltered && userFiltered && vacFiltered && createdAtFiltered
                    && operationTypeFiltered && operationStatusFiltered) {
                    return filterRow
                }
            })
        }
    }

    function isFilteredOption() {

        const filters = Object.keys(filtered)
        const existFilters = []

        filters.forEach((filter) => {
            if (filtered[filter].length > 0) {
                existFilters.push(filtered[filter])
            }
        })

        if (existFilters.length > 0) {
            return true
        }
        return false
    }

    function handleConfirmDelete() {

        setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir todas as ordens selecionadas?', func: handleDelete, dataForFunc: selectedOrdersToDelete } })
        setShow(true);
    }

    function ordersList() {
        return (
            listFilter()?.length > 0 ?
                listFilter().map((order) => {
                    return (
                        <tr>
                            <td><input
                                type='checkbox'
                                className='tableCheckBox'
                                name='tableCheckBox'
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setShowOptions(true)
                                        setSelectedOperations([...selectedOperations, parseInt(order?.id)])
                                    } else {
                                        selectedOperations.splice(selectedOperations.indexOf(parseInt(order?.id)), 1)
                                        if (selectedOperations.length === 0) {

                                            let newSelected = []
                                            setSelectedNewStatus('')
                                            setShowOptions(false)
                                            setSelectedOperations(newSelected)
                                        } else {

                                            let newSelected = []
                                            selectedOperations.forEach((option) => {
                                                newSelected.push(option)
                                                setSelectedOperations(newSelected)
                                            })
                                        }
                                    }
                                }}>
                            </input>
                            </td>
                            {
                                user?.type < 3 && <td>{order?.users_external_id + ' - ' + order?.users_fullname}</td>
                            }
                            <td title={order?.clients_external_id + ' - ' + titleize(order?.clients_name)}>{order?.clients_external_id + ' - ' + titleize(order?.clients_name)}</td>
                            <td title={order?.asset}>{order?.asset}</td>
                            <td title={order?.operations_types_description}>{order?.operations_types_description}</td>
                            <td title={toBRL(order?.value)}>{toBRL(order?.value)}</td>
                            <td title={order?.quantity}>{order?.quantity}</td>
                            <td title={order.vac ? 'Sim' : 'Não'}>{order.vac ? 'Sim' : 'Não'}</td>
                            <td title={toBRL(order?.value * order?.quantity)}>{toBRL(order?.value * order?.quantity)}</td>
                            <td title={order?.operation_status_description}>{order?.operation_status_description}</td>
                        </tr>)
                }) :
                (
                    <tr>
                        <td colSpan='10'>--Lista vazia--</td>
                    </tr>
                )
        )
    }

    function ordersListToExport() {
        return (
            listFilter()?.length > 0 ?
                listFilter().map((order) => {
                    return (
                        <tr>
                            {
                                user?.type < 3 && <td>{order?.users_external_id + ' - ' + order?.users_fullname}</td>
                            }
                            <td title={order?.clients_external_id + ' - ' + titleize(order?.clients_name)}>{order?.clients_external_id + ' - ' + titleize(order?.clients_name)}</td>
                            <td title={order?.asset}>{order?.asset}</td>
                            <td title={order?.operations_types_description}>{order?.operations_types_description}</td>
                            <td title={toBRL(order?.value)}>{toBRL(order?.value)}</td>
                            <td title={order?.quantity}>{order?.quantity}</td>
                            <td title={order.vac ? 'Sim' : 'Não'}>{order.vac ? 'Sim' : 'Não'}</td>
                            <td title={toBRL(order?.value * order?.quantity)}>{toBRL(order?.value * order?.quantity)}</td>
                            <td title={order?.operation_status_description}>{order?.operation_status_description}</td>
                        </tr>)
                }) :
                (
                    <tr>
                        <td colSpan='10'>--Lista vazia--</td>
                    </tr>
                )
        )
    }

    function checkAll(check) {

        const checkBoxes = document.querySelectorAll('input[name="tableCheckBox"]');

        setChecked(check);
        let listToInsert = [];

        listFilter().map((order) => {
            listToInsert.push(parseInt(order?.id))
        })
        checkBoxes.forEach((checkBox) => {
            checkBox.checked = check;
        })

        if (check === true) {
            setShowOptions(true)
            setSelectedOperations(listToInsert)
        }
        else {
            setShowOptions(false)
            setSelectedOperations([]);
            setSelectedNewStatus('')
        }
    }

    async function hideNotification() {

        setHideMessage(true)
        await new Promise(resolve => setTimeout(resolve, 3000))
        setShowMessage(false)
    }

    return (
        <div className='myPushOrdersTableMainContainer'>
            {
                showMessage?.allUsers && showMessage?.notify ?
                    <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                        <p>Ocorreram atualizações em massa efetuadas pelo backoffice</p>
                        <button onClick={() => hideNotification()}>X</button>
                    </div>
                    :
                    !showMessage?.allUsers && showMessage?.notify &&
                    <div id='myStructuredNotifyBox' style={{ opacity: hideMessage ? '0' : '1' }}>
                        <p>Alterações realizadas</p>
                        <button onClick={() => hideNotification()}>X</button>
                    </div>

            }
            <div className='mainPushOrdersTableContainerOverflow' style={{ maxHeight: screenY - 100 }}>

                <table className='mainPushOrdersTableContainer'>
                    <thead>
                        <tr>
                            <th colSpan='12'>
                                <div className='myPushOrdersTheadDiv'>
                                    <div className='monitoringTableNameDiv'>
                                        <p>Minhas Ordens Push</p>
                                        {/* <span className='monitoringTableDowloadButton'>
                                            <BsDownload onClick={() => document.getElementById('test-table-xls-button').click()} />
                                            <a id="link-to-download" href="!#">{ }</a>
                                            <div className='monitoringTableDownloadTitle'>
                                                <div className='monitoringTableDownloadTitleAbsolute'>
                                                    <div className='monitoringTableExcelButton' >
                                                        <ReactHTMLTableToExcel
                                                            id="test-table-xls-button"
                                                            className="download-table-xls-button btn btn-success mb-3"
                                                            table="TableToExport"
                                                            filename="tablexls"
                                                            sheet="tablexls"
                                                            buttonText="Exportar Tabela" />
                                                    </div>
                                                    <a>Exportar tabela</a>
                                                </div>
                                            </div>
                                        </span> */}
                                    </div>

                                    <div className='myPushOrdersTheadRightGroup'>
                                        {
                                            loadingPeriods ?
                                                (
                                                    <div className='myPushOrdersLoadingPeriods'>
                                                        <ArrowLoaderWOText />
                                                    </div>
                                                ) :
                                                showOptions && selectedOperations.length > 0 ?
                                                    (
                                                        <div className='myPushOrdersMultipleActionsDiv'>
                                                            <p>Selecionado: {selectedOperations.length} |</p>
                                                            <p>Ações | </p>
                                                            <label id='myPushOrdersMultipleActions'>
                                                                <div onClick={() => handleConfirmDelete()}>
                                                                    <FiTrash2 />
                                                                    <a>Excluir selecionados</a>
                                                                </div>
                                                            </label>
                                                            {
                                                                user?.type < 3 &&
                                                                (
                                                                    <>
                                                                        <label>
                                                                            <Autocomplete
                                                                                options={operationStatusList}
                                                                                value={selectedNewStatus}
                                                                                getOptionLabel={(x) => x.description}
                                                                                onChange={(event, newValue) => {
                                                                                    setSelectedNewStatus(newValue)
                                                                                }}
                                                                                id='newStatusSelection'
                                                                                renderInput={(params) =>
                                                                                    <TextField
                                                                                        label='Alterar Status'
                                                                                        className={classes.textField}
                                                                                        {...params}
                                                                                        variant="outlined"
                                                                                    />
                                                                                } />
                                                                        </label>
                                                                        <label>
                                                                            {
                                                                                selectedNewStatus !== '' && selectedNewStatus !== undefined && selectedNewStatus !== null ?
                                                                                    (
                                                                                        <button
                                                                                            className='buttonTableTheadMyStructured'
                                                                                            onClick={() => handleUpdateStatus()}
                                                                                        >
                                                                                            Confirmar
                                                                                        </button>
                                                                                    ) :
                                                                                    (
                                                                                        <div id='blankSpace'></div>
                                                                                    )
                                                                            }
                                                                        </label>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    ) :
                                                    (
                                                        <div className='substitutoLabelDisplay' id='myPushOrdersTheadDateSelection'>
                                                            <span className="buttonTableThead"><FiArrowDown />Exportar
                                                                <div className='monitoringTableExcelButton' style={{ display: 'none' }}>
                                                                    <ReactHTMLTableToExcel
                                                                        style={{ display: 'none' }}
                                                                        id="test-table-xls-button"
                                                                        table="TableToExport"
                                                                        filename="minhasOrdensPush"
                                                                        sheet="minhasOrdensPush"
                                                                        buttonText="Exportar" />
                                                                </div>
                                                            </span>
                                                            <Autocomplete
                                                                options={listMonths}
                                                                value={selectedDateMonth}
                                                                getOptionLabel={(x) => x.month}
                                                                getOptionSelected={(option, val) => option.value == val.value}
                                                                onChange={(event, newValue) => {
                                                                    setSelectedDateMonth(newValue)
                                                                }}
                                                                id='company'
                                                                renderInput={(params) => <TextField
                                                                    label='Selecione o mês'
                                                                    className={classes.textField}
                                                                    {...params} variant="outlined" />}
                                                            />
                                                            <Autocomplete
                                                                options={pushOrdersOperationPeriodsList}
                                                                value={selectedDateYear}
                                                                getOptionLabel={(x) => x.year.toString()}
                                                                getOptionSelected={(option, val) => option.value == val.value}
                                                                onChange={(event, newValue) => {
                                                                    setSelectedDateYear(newValue)
                                                                }}
                                                                id='company'
                                                                renderInput={(params) => <TextField
                                                                    label='Selecione o ano'
                                                                    className={classes.textField}
                                                                    {...params} variant="outlined" />}
                                                            />
                                                            <button
                                                                className='buttonTableTheadMyStructured'
                                                                onClick={
                                                                    () => {
                                                                        if (selectedDateMonth !== undefined && selectedDateYear !== undefined) {
                                                                            getOrdersReccords(selectedDateMonth.value, selectedDateYear.year)
                                                                        }
                                                                        else {
                                                                            alert('Selecione Mes e Ano')
                                                                        }
                                                                    }
                                                                }>Consultar
                                                            </button>
                                                        </div>
                                                    )
                                        }
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <div className='myPushOrdersSelectionAll'>
                                    <input type='checkbox' onClick={() => checkAll(!checked)}></input>
                                </div>
                            </th>
                            {
                                user?.type < 3 &&
                                <th>
                                    <select title={filtered['users_fullname']} id='users_fullname' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                        handleChooseOption(e, 'users_fullname')
                                    }}>
                                        <option selected disabled>Operado por</option>
                                        {
                                            filterHead('users_fullname').map((option) => {
                                                return (<option>{titleize(option.users_fullname)}</option>)
                                            })
                                        }
                                    </select>
                                    {
                                        <button
                                            onClick={() => {
                                                handleClearOption('users_fullname', 'Operado por')
                                            }}
                                            title='Remover filtro'
                                            className={
                                                filtered['users_fullname'].length > 0 ?
                                                    'showClearOptionButton' : 'hideClearOptionButton'
                                            }
                                        >x</button>
                                    }
                                </th>
                            }
                            <th>
                                <select title={filtered['clients_name']} id='clients_name' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                    handleChooseOption(e, 'clients_name')
                                }}>
                                    <option selected disabled>Cliente</option>
                                    {
                                        filterHead('clients_name').map((option) => {
                                            return (<option>{titleize(option.clients_name)}</option>)
                                        })
                                    }
                                </select>
                                {
                                    <button
                                        onClick={() => {
                                            handleClearOption('clients_name', 'Cliente')
                                        }}
                                        title='Remover filtro'
                                        className={
                                            filtered['clients_name'].length > 0 ?
                                                'showClearOptionButton' : 'hideClearOptionButton'
                                        }
                                    >x</button>
                                }
                            </th>
                            <th>
                                <select title={filtered['asset']} id='asset' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                    handleChooseOption(e, 'asset')
                                }}>
                                    <option selected disabled>Ativo</option>
                                    {
                                        filterHead('asset').map((option) => {
                                            return (<option>{titleize(option.asset)}</option>)
                                        })
                                    }
                                </select>
                                {
                                    <button
                                        onClick={() => {
                                            handleClearOption('asset', 'Ativo')
                                        }}
                                        title='Remover filtro'
                                        className={
                                            filtered['asset'].length > 0 ?
                                                'showClearOptionButton' : 'hideClearOptionButton'
                                        }
                                    >x</button>
                                }
                            </th>
                            <th>
                                <select title={filtered['operations_types_description']} id='operations_types_description' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                    handleChooseOption(e, 'operations_types_description')
                                }}>
                                    <option selected disabled>C/V</option>
                                    {
                                        filterHead('operations_types_description').map((option) => {
                                            return (<option>{titleize(option.operations_types_description)}</option>)
                                        })
                                    }
                                </select>
                                {
                                    <button
                                        onClick={() => {
                                            handleClearOption('operations_types_description', 'C/V')
                                        }}
                                        title='Remover filtro'
                                        className={
                                            filtered['operations_types_description'].length > 0 ?
                                                'showClearOptionButton' : 'hideClearOptionButton'
                                        }
                                    >x</button>
                                }
                            </th>
                            <th>Preço</th>
                            <th>Qtde</th>
                            <th>
                                <select title={filtered['vac']} id='vac' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                    handleChooseOption(e, 'vac')
                                }}>
                                    <option selected disabled>VAC</option>
                                    {
                                        filterHead('vac').map((option) => {
                                            return (<option>{option.vac ? 'Sim' : 'Não'}</option>)
                                        })
                                    }
                                </select>
                                {
                                    <button
                                        onClick={() => {
                                            handleClearOption('vac', 'VAC')
                                        }}
                                        title='Remover filtro'
                                        className={
                                            filtered['vac'].length > 0 ?
                                                'showClearOptionButton' : 'hideClearOptionButton'
                                        }
                                    >x</button>
                                }
                            </th>
                            <th>Financeiro Estimado</th>
                            <th>
                                <select title={filtered['operation_status_description']} id='operation_status_description' className='myPushOrdersSelectionFilter' onChange={(e) => {
                                    handleChooseOption(e, 'operation_status_description')
                                }}>
                                    <option selected disabled>Status</option>
                                    {
                                        filterHead('operation_status_description').map((option) => {
                                            return (<option>{titleize(option.operation_status_description)}</option>)
                                        })
                                    }
                                </select>
                                {
                                    <button
                                        onClick={() => {
                                            handleClearOption('operation_status_description', 'Status')
                                        }}
                                        title='Remover filtro'
                                        className={
                                            filtered['operation_status_description']?.length > 0 ?
                                                'showClearOptionButton' : 'hideClearOptionButton'
                                        }
                                    >x</button>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ?
                                (
                                    <tr>
                                        <td id='myStructuredLoadingTd' colSpan='10'><ArrowLoader /></td>
                                    </tr>
                                ) :
                                ordersList()
                        }
                    </tbody>
                </table >
                {
                    !downloadTable &&
                    <html>
                        <head>
                            <meta charset="UTF-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1" />
                            <meta name="theme-color" content="#000000" />
                            {/* <title>Portal de Renda Variável - Manchester</title> */}
                        </head>
                        <body>
                            <table lang='pt-BR' className='mainPushOrdersTableContainer' style={{ display: 'none' }} id='TableToExport'>
                                <thead>
                                    <tr>

                                        {
                                            user?.type < 3 &&
                                            <th>
                                                Operado por
                                            </th>
                                        }
                                        <th>Cliente</th>
                                        <th>Ativo</th>
                                        <th>C/V</th>
                                        <th>Preço</th>
                                        <th>Qtde</th>
                                        <th>VAC</th>
                                        <th>Financeiro Estimado</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        loading ?
                                            (
                                                <tr>
                                                    <td id='myStructuredLoadingTd' colSpan='10'><ArrowLoader /></td>
                                                </tr>
                                            ) :
                                            ordersListToExport()
                                    }
                                </tbody>
                            </table >
                        </body>
                    </html>
                }
            </div>
            <footer className='myPushOrderDetails'>
                {
                    isFilteredOption() ?
                        <label>
                            <a>Filtrado</a>
                        </label>
                        :
                        <label>
                            <a>Período:</a>
                            <a>{selectedPeriod.month !== '' ? selectedPeriod?.month + '/' + selectedPeriod?.year : ''}</a>
                        </label>

                }
                <label>
                    <a>Qtd de operações:</a>
                    <a>{totalOperatedThisPeriod > 0 ? totalOperatedThisPeriod : '0'}</a>
                </label>
                <label>
                    <a>Clientes operados:</a>
                    <a>{totalClientsOperatedThisPeriod > 0 ? totalClientsOperatedThisPeriod : '0'}</a>
                </label>
                <label>
                    <a>Financeiro Total:</a>
                    <a>{totalValueThisPeriod > 0 ? toBRL(totalValueThisPeriod) : toBRL(0)}</a>
                </label>
            </footer>
        </div>

    )
}