import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { FiArrowRight, FiPlus, FiTrash2 } from 'react-icons/fi'
import { BsArrowLeftRight, BsArrowRight } from 'react-icons/bs'
import { BsBoxArrowLeft, BsBoxArrowRight } from 'react-icons/bs'
import { BiMessageAltAdd } from 'react-icons/bi'
import { BiEdit } from 'react-icons/bi'
import { BiSave } from 'react-icons/bi'
/*Styles*/
import './styles/brokerPanel.css'
import { api } from '../../../services/api'
/*Contexts*/
import { useAuth } from '../../../contexts/useAuth'
import { useModal } from '../../../contexts/useModal'
/*Components*/
import Modal from '../../../components/modal/modal'
import { TradeIdeasText } from './tradeIadeasText'
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
import { TileLoader } from '../../../components/loaders/tileLoader';
import ObjectivesImport from './objectivesImport'
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox'
import { BrokerPanelLinkRegister } from './brokerPanelLinkRegister'
/*Services*/
import { getTradeIdeas, getBrokerPanelLeftLinks, getBrokerPanelGoals, getBrokerPanelResults } from '../../../services/asyncFunctions';
/*Utils*/
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { toBRL } from '../../../utils/formatNumberAndText/toBRLCurrency'
import GoalsImport from './goalsImport'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

export function BrokerPanel2() {

    const token = localStorage.getItem('@auth:token')
    const [loading, setLoading] = useState(false)
    const { type, setType, show, setShow, edited, setEdited } = useModal()
    const { user, tokenKey } = useAuth()
    const history = useHistory()
    const { screenX } = ModalSizeFitToScreen()
    const now = new Date
    const [recadosGraficos, setRecadosGraficos] = useState(false)
    const [convertedText, setConvertedText] = useState("");
    const [editTitle, setEditTitle] = useState(false)

    const modules = {
      toolbar: [
        [{ 'size': ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline','strike', 'blockquote', {'background': []}, {'color': []}],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
      ],
    }
  
    const formats = ['size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video', 'background', 'color']

    return(
      <>
        <div className='containerTopButtons'>
          <button className={recadosGraficos ? "buttonRecadosGraficos" : "buttonRecadosGraficosACTIVE"} onClick={(e) => setRecadosGraficos(false)}>Recados</button>
          {/* {recadosGraficos ? 
          <BsBoxArrowRight size={30} className="switchButtonsRecadosGraficos" onClick={(e) => setRecadosGraficos(!recadosGraficos)} color="#ffc800"/> 
          : 
          <BsBoxArrowLeft size={30} className="switchButtonsRecadosGraficos" onClick={(e) => setRecadosGraficos(!recadosGraficos)} color="#ffc800"/>
          } */}
          <button className={recadosGraficos ? "buttonRecadosGraficosACTIVE" : "buttonRecadosGraficos"} onClick={(e) => setRecadosGraficos(true)}>Gráficos</button>
        </div>
        {recadosGraficos ?
          <></>
          :
          <div className='conatinerRecadosTrades'>
            <div className='containerRecados'>
              <div className='tituloRecadosTrades'>RECADOS</div>
              <button className='itemRecados'>RECADOS ETERNOS</button>
              <button className='itemRecados'>#ClienteEspecialista</button>
              <button className='itemRecados'>AUDITORIA DE VENDAS/CENAS</button>
              <button className='itemPlus'><BiMessageAltAdd size={30} color="#2c2c2c"/></button>
            </div>
            <div className='containerTextoRecadosTrades'>
              {editTitle ?
                <div className='containerTituloEditTrue'>
                  <div></div>
                  <input type='text' className='inputEditTitle'></input>
                  <button className='editTitleButton' onClick={(e) => {
                    setEditTitle(!editTitle)
                    console.log(e.target)
                  }}><BiSave size={30}/></button>
                </div>
                :
                <div className='containerTituloEditFalse'>
                  <div></div>
                  <div className='tituloRecadoTrades'>TITULO GENÉRICO</div>
                  <button className='editTitleButton' onClick={(e) => setEditTitle(!editTitle)}><BiEdit size={30}/></button>
                </div>
              }
              <ReactQuill
                theme='snow'
                modules={modules}
                formats={formats}
                value={convertedText}
                onChange={setConvertedText}
                style={{minHeight: '300px'}}
              />
            </div>
            <div className='containerTrades'>
              <div className='tituloRecadosTrades'>TRADES</div>
              <button className='itemTrades'>25/05 - LONG SHORT</button>
              <button className='itemPlus'><BiMessageAltAdd size={30} color="#2c2c2c"/></button>
            </div>
          </div>
        }
      </>
    )
}