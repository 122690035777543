import '../styles/pushOrders.table.css';
import { styleForSelecion } from '../../../../components/styles/autocompletField'
import { styledForPushOrders } from '../../../../components/styles/autocompletField';
import React, { useState, useEffect } from 'react';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Utils*/
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Paper from "@material-ui/core/Paper";
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency'
import { v4 as uuidv4 } from 'uuid';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
/*Services*/
import { getMyClients } from '../../../../services/asyncFunctions';
import { getAllClients } from '../../../../services/asyncFunctions';
import { getAssetsList } from '../../../../services/asyncFunctions';
import { getAssetClass } from '../../../../services/asyncFunctions';
import { getOrdersPushFormat } from '../../../../services/asyncFunctions';
import { getOrdersPushPrice } from '../../../../services/asyncFunctions';
/*Contexts*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
import { api } from '../../../../services/api';
/*Components*/
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export function PushOrdersTable() {

    const { user, tokenKey } = useAuth();
    const { type, setType, show, setShow, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState('')
    const classes = styledForPushOrders()
    const classesSelection = styleForSelecion()
    const [operationsClass, setOperationsClass] = useState(null);
    const [operationsFormat, setOperationsFormat] = useState(1)
    const [operationsPrice, setOperationsPrice] = useState(1)
    const [tableColumnsLenght, setTableColumnsLenght] = useState(10)
    const [clientsList, setClientsList] = useState([]);
    const [assetsList, setAssetsList] = useState([])
    const [assetClassList, setAssetClassList] = useState([])
    const [ordersPushPriceList, setOrdersPushPriceList] = useState([])
    const [ordersPushFormatList, setOrdersPushFormatList] = useState([])
    //Variaveis selecionadas
    const [state, setState] = useState({
        [uuidv4()]: {
            client_external_id: {
                client_external_id: '',
                client_name: ''
            },
            asset: '',
            asset_class: '',
            operations_type_id: 2,
            value: '',
            quantity: '',
            vac: false
        }
    })
    const [errorsRows, setErrorsRows] = useState({
        [uuidv4()]: {
            client_external_id: '1',
            asset: '',
            asset_class: '',
            operations_type_id: 2,
            value: '',
            quantity: '',
            vac: false,
        }
    })
    const [errorsFields, setErrorsFields] = useState({
        operationsPrice: false,
        operationsFormat: false
    })
    const [keysUuid, setKeysUuid] = useState(Object.keys(state))
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState([]);
    const [filteredAssets, setFilteredAssets] = useState([{ id: 0, asset: 'Selecione uma classe' }]);
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [getChild, setGetChild] = useState(false)
    const handleChangeFormat = e => {
        setOperationsFormat(e.target.value)
    }

    const handleChangePrice = e => {
        setOperationsPrice(e.target.value)
    }

    function getLastChild() {

        if (getChild) {

            const inputChild = document?.getElementById('pushOrdersTable')?.childNodes[1].childNodes.length - 1
            document?.getElementById('pushOrdersTable')?.childNodes[1]?.childNodes[inputChild]?.childNodes[1]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.childNodes[0]?.focus()
            setGetChild(false)
        }
    }

    useEffect(() => {
        getLastChild()
    }, [keysUuid])

    useEffect(() => {

        async function exec() {

            if (clientsList?.length === 0) {
                const myClientsGet = user.type >= 3 ? await getMyClients(user.external_id, tokenKey,) :
                    await getAllClients(tokenKey, 1, 4)
                if (myClientsGet?.isError) {
                    setType({
                        name: 'alertMsg',
                        type: 'information',
                        value: {
                            message: myClientsGet?.error
                        }
                    })
                    setShow(true);
                } else {
                    const formattedClients = myClientsGet.map((client) => {
                        return {
                            client_name: user?.type >= 3 ? client.client_name : client.name,
                            client_external_id: user?.type >= 3 ? client.client_external_id : client.external_id
                        }
                    })
                    setClientsList(formattedClients);
                }
            }

            const assetsGet = await getAssetsList(tokenKey)
            if (assetsGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: assetsGet?.error
                    }
                })
                setShow(true);
            } else {
                setAssetsList(assetsGet)
            }

            const classAssetsGet = await getAssetClass(tokenKey)
            if (classAssetsGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: classAssetsGet?.error
                    }
                })
                setShow(true);
            } else {
                setAssetClassList(classAssetsGet)
            }

            const pushOrdersPriceGet = await getOrdersPushPrice(tokenKey)
            if (pushOrdersPriceGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: pushOrdersPriceGet?.error
                    }
                })
                setShow(true);
            } else {
                setOrdersPushPriceList(pushOrdersPriceGet)
            }

            const pushOrdersFormatGet = await getOrdersPushFormat(tokenKey)
            if (pushOrdersFormatGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: pushOrdersFormatGet?.error
                    }
                })
                setShow(true);
                setLoading(false);
            } else {
                setOrdersPushFormatList(pushOrdersFormatGet)
                setLoading(false);
            }
        }

        exec()

    }, [edited])

    function newLine(thisId, row) {

        checkDividedAssets(thisId)

        setFilteredAssets([])

        if (operationsPrice === null || operationsPrice === null) {
            return setNotification('Verifique os campos classe/formato/preço')
        }

        const fieldsToSearch = Object.keys(state[thisId])

        const fieldsWithErrors = fieldsToSearch.filter((fieldWithError) => {
            if ((operationsPrice === 2 &&
                (state[thisId][fieldWithError] === ''
                    && fieldWithError !== 'client_external_id'
                    && fieldWithError !== 'operations_type_id')
                || selectedClient === ''
            )

                ||

                (operationsPrice === 1 &&
                    (state[thisId][fieldWithError] === ''
                        && fieldWithError !== 'client_external_id'
                        && fieldWithError !== 'operations_type_id'
                        && fieldWithError !== 'value')
                    || selectedClient === ''
                )) {
                return fieldWithError
            }
        })
        if (fieldsWithErrors.length > 0) {
            errorsRows[thisId] = {
                fieldsWithErrors
            }
            setErrorsRows(errorsRows)
            setKeysUuid(Object.keys(state))
            return
        } else {
            setNotification('')
        }

        state[uuidv4()] = {
            client_external_id: {
                client_external_id: '',
                client_name: ''
            },
            asset: '',
            asset_class: '',
            operations_type_id: 2,
            value: '',
            quantity: '',
            vac: false
        }
        setState(state)
        setKeysUuid(Object.keys(state))
    }

    function newLineFraction(quantity, asset, asset_class) {

        try {

            const fractionAsset = assetsList.filter((assetInList) => {
                if (assetInList.asset === asset.asset + 'F') {
                    return assetInList
                }
            })

            setFilteredAssets([])

            if (fractionAsset.length > 0) {
                state[uuidv4()] = {
                    client_external_id: {
                        client_external_id: '',
                        client_name: ''
                    },
                    asset: fractionAsset[0],
                    asset_class: { id: fractionAsset[0].asset_class_id, description: fractionAsset[0].asset_class_description, type: 'F' },
                    operations_type_id: 2,
                    value: fractionAsset[0].value,
                    quantity: quantity,
                    vac: false
                }
                setState(state)
                setKeysUuid(Object.keys(state))
            } else {
                throw new Error('Ativo fracionado não existe, permitido apenas lote de 100')
            }


        } catch (error) {
            return setNotification(error.message)
        }
    }

    function makeThisLineFraction(quantity, asset, asset_class, thisLineUuid) {

        try {

            const fractionAsset = assetsList.filter((assetInList) => {
                if (assetInList.asset === asset.asset + 'F') {
                    return assetInList
                }
            })

            setFilteredAssets([])

            if (fractionAsset.length > 0) {

                state[thisLineUuid] = {
                    client_external_id: {
                        client_external_id: state[thisLineUuid].client_external_id.client_external_id,
                        client_name: state[thisLineUuid].client_external_id.client_name
                    },
                    asset: fractionAsset[0],
                    asset_class: { id: fractionAsset[0].asset_class_id, description: fractionAsset[0].asset_class_description, type: 'F' },
                    operations_type_id: state[thisLineUuid].operations_type_id,
                    value: fractionAsset[0]?.value,
                    quantity: state[thisLineUuid].quantity,
                    vac: state[thisLineUuid].vac
                }
                setState(state)
                setKeysUuid(Object.keys(state))
            } else {
                if (quantity < 100) {
                    state[thisLineUuid].quantity = 100
                } else {
                    let restQuantity = quantity % 100
                    let newQuantity = quantity - restQuantity
                    state[thisLineUuid].quantity = newQuantity
                }
                setState(state)
                setKeysUuid(Object.keys(state))
                throw new Error('Ativo fracionado não existe, permitido apenas lote de 100')
            }
        } catch (error) {
            setNotification(error.message)
        }
    }

    function isValidAllRows() {

        const idsToCheck = Object.keys(state)

        let errorRows = []

        idsToCheck.forEach((eachId, thisRow) => {

            if (operationsPrice === null) {

                errorsFields['operationsPrice'] = true
                setErrorsFields(errorsFields)
            } else {
                errorsFields['operationsPrice'] = false
                setErrorsFields(errorsFields)
            }
            if (operationsFormat === null) {

                errorsFields['operationsFormat'] = true
                setErrorsFields(errorsFields)
            } else {
                errorsFields['operationsFormat'] = false
                setErrorsFields(errorsFields)
            }

            const rowTocheck = Object.keys(state[eachId])
            if (state[eachId]?.asset_class?.type === 'L' && (state[eachId]?.quantity % 2 > 0)) {

                let restQtd = state[eachId].quantity % 2
                let newQtd = state[eachId].quantity - restQtd

                state[eachId].quantity = newQtd
                state[eachId].value = state[eachId].value * newQtd

                setState(state)
                setKeysUuid(Object.keys(state))
            }

            const fieldsWithErrors = rowTocheck.filter((thisFieldRow) => {
                if (
                    (operationsPrice === 1 && ((state[eachId][thisFieldRow] === '' || state[eachId][thisFieldRow] === null || state[eachId][thisFieldRow] === undefined) && thisFieldRow !== 'value'
                        ||
                        (thisRow === 0 && (thisFieldRow === 'client_external_id' && state[eachId]['client_external_id']?.client_external_id === ''))))
                    ||
                    (operationsPrice === 2 && ((state[eachId][thisFieldRow] === '' || state[eachId][thisFieldRow] === null || state[eachId][thisFieldRow] === undefined)
                        ||
                        (thisRow === 0 && (thisFieldRow === 'client_external_id' && state[eachId]['client_external_id']?.client_external_id === ''))))
                ) {
                    return thisFieldRow
                }
            })

            if (fieldsWithErrors?.length > 0) {

                errorRows.push(eachId)
                setNotification('Verifique os campos em vermelho')
                errorsRows[eachId] = {
                    fieldsWithErrors
                }
                return
            }

        })

        if (errorRows?.length > 0 || errorsFields['operationsFormat'] || errorsFields['operationsPrice']) {
            setNotification('Verifique os campos em vermelho')
        } else {

            setNotification('')
            handleSubmit()
            const ordertoRemove = Object.keys(errorsRows)
            ordertoRemove.map((order) => {
                delete state[order]
            })

            errorsRows[uuidv4()] = {
                client_external_id: {
                    client_external_id: '',
                    client_name: ''
                },
                asset: '',
                asset_class: '',
                operations_type_id: 2,
                value: '',
                quantity: 0,
                vac: false
            }
            setErrorsRows(errorsRows)
        }
        setErrorsRows(errorsRows);
        setKeysUuid(Object.keys(state));
    }

    function filterAssets(classID) {

        const newAssetList = assetsList.filter((assetFiltered) => {
            if (assetFiltered?.asset_class_id === classID?.id) {
                return assetFiltered
            }
        })
        newAssetList.length > 0 ? setFilteredAssets(newAssetList) : setFilteredAssets([])
    }

    function checkDividedAssets(thisUuid) {

        if (state[thisUuid]?.asset_class?.type === 'L') {

            let selectedQtd = state[thisUuid]?.quantity
            let restQtd = selectedQtd % 100

            if (restQtd > 0 && selectedQtd > 100) {

                selectedQtd = selectedQtd - restQtd
                state[thisUuid].quantity = selectedQtd
                setState(state)
                setKeysUuid(Object.keys(state))
                newLineFraction(restQtd, state[thisUuid].asset, state[thisUuid].asset_class)
            } else if (selectedQtd < 100) {
                makeThisLineFraction(selectedQtd, state[thisUuid].asset, state[thisUuid.asset_class], thisUuid)
            }
        } else if (state[thisUuid]?.asset_class?.type === 'F' && state[thisUuid]?.asset_class?.fraction_type !== 'F') {

            state[thisUuid].quantity = parseInt(state[thisUuid]?.quantity)
            setState(state)
            setKeysUuid(Object.keys(state))

            if (state[thisUuid].quantity < 1) {
                state[thisUuid].quantity = ''
                setState(state)
                setKeysUuid(Object.keys(state))
                errorsFields['quantityFraction'] = true
                setErrorsFields(errorsFields)
                setNotification('Quantidade não pode ser zero')
            }
        }
    }

    function clear() {

        const ordertoRemove = Object.keys(state)
        ordertoRemove.map((order) => {
            delete state[order]
        })

        state[uuidv4()] = {
            client_external_id: {
                client_external_id: '',
                client_name: ''
            },
            asset: '',
            asset_class: '',
            operations_type_id: 2,
            value: '',
            quantity: 0,
            vac: false
        }
        setState(state)
        setKeysUuid(Object.keys(state))
        setEdited(!edited)
    }

    async function handleSubmit() {

        const ordersRows = (Object.keys(state))
        const orders = ordersRows.map((thisOrder) => {
            return {
                asset: state[thisOrder].asset.asset,
                quantity: parseInt(state[thisOrder].quantity),
                value: state[thisOrder].value,
                vac: state[thisOrder].vac,
                operations_types_id: state[thisOrder].operations_type_id
            }
        })

        setLoading(true)
        try {
            const sendBasket = await api.post(`/api/v1/ordersPush`, {
                clients_external_id: parseInt(selectedClient.client_external_id),
                orders_push_format_id: operationsFormat,
                orders_push_price_id: operationsPrice,
                orders
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Basket enviada com sucesso!' } })
            setShow(true)
            clear()

        } catch (error) {

            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao enviar a basket, tente novamente' : error?.response?.data?.message } })
            setShow(true)
            setLoading(false)
        }
    }

    return (

        loading ?
            <div className='pushOrdersTableLoader' >
                <ArrowLoader />
            </div>
            :
            <>
                <div className="pushOrdersTableContainer" style={{
                    maxHeight: screenY
                }}>
                    <table id='pushOrdersTable' className='pushOrdersTable'>
                        <thead>
                            <tr>
                                <th colSpan={tableColumnsLenght}>
                                    <div className="pushOrdersBasketTr">
                                        <div className="pushOrdersBasketTable">
                                            <div className="pushOrdersBasketLabel">
                                                <div className="pushOrdersBasketLabel" title='Escolha formato e Preço campos obrigatórios'>
                                                    <label>
                                                        <a className={operationsFormat === null && errorsFields['operationsFormat'] ? 'pushOrdersSelecionWithErrors' : ''}>Formato:</a>
                                                    </label>
                                                    <label>
                                                        <FormControl className={classes.formControl}>
                                                            <Select
                                                                className={classesSelection.select}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={operationsFormat}
                                                                onChange={handleChangeFormat}
                                                            >
                                                                {
                                                                    ordersPushFormatList?.map((format, index) => {
                                                                        return <MenuItem value={format.id}>{format.description}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="pushOrdersBasketLabel">
                                                <div className="pushOrdersBasketLabel" title='Escolha formato e Preço campos obrigatórios'>
                                                    <label>
                                                        <a className={errorsFields['operationsPrice'] && operationsPrice === null ? 'pushOrdersSelecionWithErrors' : ''}>Preço:</a>
                                                    </label>
                                                    <label>
                                                        <FormControl className={classes.formControl}>
                                                            {/* <InputLabel id="demo-simple-select-label">Formato</InputLabel> */}
                                                            <Select
                                                                className={classesSelection.select}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={operationsPrice}
                                                                onChange={handleChangePrice}
                                                            >
                                                                {
                                                                    ordersPushPriceList?.map((price, index) => {
                                                                        return <MenuItem value={price.id}>{price.description}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            tabindex='1'
                                            className="pushOrdersBasketButton"
                                            onClick={() => {
                                                isValidAllRows()
                                            }}
                                        >Enviar Basket</button>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th>Cliente</th>
                                <th>Classe</th>
                                <th>Ativo</th>
                                <th>C/V</th>
                                {
                                    operationsPrice === 2 && <th>Preço</th>
                                }
                                <th>Qtde</th>
                                <th>VAC</th>
                                <th style={{
                                    width: '11rem',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    maxWidth: '11rem'
                                }}>Financeiro estimado</th>
                                <th>Incluir</th>
                                <th>Remover</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                keysUuid.length > 0 &&
                                keysUuid?.map((uuidShow, indexRow) => {
                                    const errorsInthisRow = errorsRows;
                                    return (
                                        <tr id={uuidShow}>
                                            <td className={errorsInthisRow[uuidShow]?.fieldsWithErrors?.indexOf('client_external_id') > -1 ? 'errorThisRow' : ''}>
                                                <label >
                                                    {
                                                        indexRow === 0 ?
                                                            (
                                                                <Autocomplete
                                                                    id='clientsSeleciont'
                                                                    options={clientsList}
                                                                    value={state[uuidShow].client_external_id}
                                                                    getOptionLabel={(option) => option.client_external_id + " - " + titleize(option.client_name)}
                                                                    className={classes.autocomplete}
                                                                    onChange={(event, newValue) => {
                                                                        if (errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('client_external_id') > -1) {
                                                                            errorsRows[uuidShow]?.fieldsWithErrors?.splice(errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('client_external_id'), 1)
                                                                            setErrorsRows(errorsRows[uuidShow])
                                                                        }
                                                                        state[uuidShow].client_external_id = newValue
                                                                        setState(state);
                                                                        setSelectedClient(newValue)
                                                                        setKeysUuid(Object.keys(state));
                                                                    }}
                                                                    PaperComponent={({ children }) => (
                                                                        <Paper
                                                                            style={{
                                                                                background: "white",
                                                                                fontSize: '1rem'
                                                                            }}>{children}
                                                                        </Paper>
                                                                    )}
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                            className={classes.currency}
                                                                            {...params}
                                                                        />}
                                                                />
                                                            ) :
                                                            (
                                                                <a>{state[keysUuid[0]]['client_external_id']?.client_external_id + ' - ' + titleize(state[keysUuid[0]]['client_external_id'].client_name)}</a>
                                                            )
                                                    }
                                                </label>
                                            </td>
                                            <td>
                                                <label>
                                                    <Autocomplete
                                                        id='classOperation'
                                                        value={state[uuidShow]?.asset_class}
                                                        options={assetClassList}
                                                        className={classes.autocomplete}
                                                        getOptionLabel={(option) => titleize(option.description)}
                                                        onChange={(event, newValue) => {
                                                            setOperationsClass(newValue)
                                                            state[uuidShow].asset_class = newValue
                                                            state[uuidShow].asset = ''
                                                            state[uuidShow].quantity = ''
                                                            filterAssets(newValue)
                                                        }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper
                                                                style={{
                                                                    background: "white",
                                                                    fontSize: '1rem'
                                                                }}>{children}
                                                            </Paper>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('asset_class') > -1 ? 'errorThisRow' : classes.currency}
                                                                {...params}
                                                            />}
                                                    />
                                                </label>
                                            </td>
                                            <td className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('asset') > -1 ? 'errorThisRow' : ''}>
                                                <label>
                                                    <Autocomplete
                                                        id='assetSelection'
                                                        value={state[uuidShow]?.asset}
                                                        options={filteredAssets}
                                                        getOptionLabel={(option) => option.asset}
                                                        className={classes.autocomplete}
                                                        onChange={(event, newValue) => {
                                                            if (errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('asset') > -1) {
                                                                errorsRows[uuidShow]?.fieldsWithErrors?.splice(errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('asset'), 1)
                                                                setErrorsRows(errorsRows[uuidShow])
                                                            }
                                                            setSelectedAsset(newValue)
                                                            state[uuidShow].value = newValue?.value
                                                            state[uuidShow].asset = newValue
                                                            setState(state);
                                                            setKeysUuid(Object.keys(state));
                                                        }}
                                                        PaperComponent={({ children }) => (
                                                            <Paper
                                                                style={{
                                                                    background: "white",
                                                                    fontSize: '1rem'
                                                                }}>{children}
                                                            </Paper>
                                                        )}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                className={classes.currency}
                                                                {...params}
                                                            />}
                                                    />
                                                </label>
                                            </td>
                                            <td className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('operations_type_id') > -1 ? 'errorThisRow' : ''}>
                                                <label id='pushOrdersCV'>
                                                    <a
                                                        tabIndex='0'
                                                        onKeyDown={(e) => {
                                                            if (e.code === 'NumpadEnter' || e.code === 'Enter' || e.code === 'Space') {
                                                                state[uuidShow].operations_type_id = 2;
                                                                setState(state);
                                                                setKeysUuid(Object.keys((state)));
                                                            }
                                                        }}
                                                        className={state[uuidShow]?.operations_type_id == 2 ? 'pushOrdersOperationTypeBuySelected' : 'pushOrdersOperationTypeBuy'}
                                                        onClick={() => {
                                                            state[uuidShow].operations_type_id = 2;
                                                            setState(state)
                                                            setKeysUuid(Object.keys(state))
                                                        }}
                                                    >Compra
                                                    </a>
                                                    <a
                                                        tabIndex='0'
                                                        onKeyDown={(e) => {
                                                            if (e.code === 'NumpadEnter' || e.code === 'Enter' || e.code === 'Space') {
                                                                state[uuidShow].operations_type_id = 1;
                                                                setState(state);
                                                                setKeysUuid(Object.keys((state)));
                                                            }
                                                        }}
                                                        className={state[uuidShow]?.operations_type_id == 1 ? 'pushOrdersOperationTypeSellSelected' : 'pushOrdersOperationTypeSell'}
                                                        onClick={() => {
                                                            state[uuidShow].operations_type_id = 1
                                                            setState(state)
                                                            setKeysUuid(Object.keys(state))
                                                        }}
                                                    >Venda</a>
                                                </label>
                                            </td>
                                            {
                                                operationsPrice === 2 &&
                                                <td className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('value') > -1 ? 'errorThisRow' : ''}>
                                                    <label>
                                                        <CurrencyTextField
                                                            onKeyDown={(e) => {
                                                                if (e.code === 'Minus') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            value={state[uuidShow]['value']}
                                                            variant='standard'
                                                            currencySymbol='R$'
                                                            className={classes.currency}
                                                            decimalCharacter=','
                                                            digitGroupSeparator='.'
                                                            onChange={
                                                                ((event, newValue) => {
                                                                    if (newValue > 0) {
                                                                        if (errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('value') > -1) {
                                                                            errorsRows[uuidShow]?.fieldsWithErrors?.splice(errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('value'), 1)
                                                                            setErrorsRows({
                                                                                [uuidShow]: {
                                                                                    errorsRows
                                                                                }
                                                                            })
                                                                        }
                                                                        state[uuidShow].value = newValue;
                                                                        setState(state);
                                                                        setKeysUuid(Object.keys(state));
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </label>
                                                </td>
                                            }
                                            <td className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('quantity') > -1 ? 'errorThisRow' : ''}>
                                                <label>
                                                    <input
                                                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                        value={state[uuidShow]['quantity']}
                                                        type='number'
                                                        onKeyDown={(e) => {
                                                            if (e.code === 'Minus' || e.key === 'e') {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        min='0'
                                                        id='quantity'
                                                        onChange={(newValue) => {

                                                            if (newValue.target.value >= 0 || newValue.target.value === ''
                                                                || typeof (newValue.target.value) === 'number') {
                                                                if (errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('quantity') > -1) {
                                                                    errorsRows[uuidShow]?.fieldsWithErrors?.splice(errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('quantity'), 1)
                                                                    setErrorsRows(errorsRows[uuidShow])
                                                                }
                                                                state[uuidShow].quantity = newValue.target.value;
                                                                setState(state);
                                                                setKeysUuid(Object.keys(state));
                                                            }
                                                        }}
                                                        onBlur={() => checkDividedAssets(uuidShow)}
                                                        className='pushOrdersSimpleInput'
                                                        tabIndex='0'
                                                    ></input>
                                                </label>
                                            </td>
                                            <td className={errorsRows[uuidShow]?.fieldsWithErrors?.indexOf('vac') > -1 ? 'errorThisRow' : ''}>
                                                <label>
                                                    <button
                                                        className={state[uuidShow].vac ? 'pushOrdersCheckBoxActive' : 'pushOrdersCheckBoxInactive'}
                                                        onClick={() => {
                                                            state[uuidShow].vac = !state[uuidShow].vac
                                                            setState(state);
                                                            setKeysUuid(Object.keys(state));
                                                        }}
                                                    >
                                                        {state[uuidShow].vac ?
                                                            (<a>S</a>) :
                                                            (<a>N</a>)}
                                                    </button>
                                                </label>
                                            </td>
                                            <td title={operationsPrice === 2 ? toBRL(state[uuidShow]?.value * state[uuidShow]?.quantity) : state[uuidShow].value !== undefined ? toBRL(state[uuidShow]?.value * state[uuidShow]?.quantity) : '0'} style={{ textOverflow: 'ellipsis', maxWidth: '5rem', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <label>
                                                    <a>{operationsPrice === 2 ? toBRL(state[uuidShow]?.value * state[uuidShow]?.quantity) : state[uuidShow].value !== undefined ? toBRL(state[uuidShow]?.value * state[uuidShow]?.quantity) : '0'}</a>
                                                </label>
                                            </td>
                                            {(indexRow === 0 && keysUuid.length === 1) || indexRow + 1 == keysUuid.length ?
                                                (
                                                    <td
                                                        className='pushOrdersButtonTd'
                                                        tabIndex='0'
                                                        onKeyDown={(e) => {
                                                            if (e.code === 'NumpadEnter' || e.code === 'Enter' || e.code === 'Space') {
                                                                setGetChild(true)
                                                                newLine(uuidShow, indexRow)
                                                            }
                                                        }}
                                                        onClick={() => { setGetChild(true); newLine(uuidShow, indexRow) }}>
                                                        <label id='ordensPushLastTd'>
                                                            <a>+</a>
                                                        </label>
                                                    </td>
                                                ) :
                                                (<td></td>)}
                                            {indexRow === 0 ?
                                                (<td></td>) :
                                                (<td
                                                    className='pushOrdersButtonTd'
                                                    tabIndex='0'
                                                    onKeyDown={(e) => {
                                                        if (e.code === 'NumpadEnter' || e.code === 'Enter' || e.code === 'Space') {
                                                            delete state[uuidShow];
                                                            setState(state)
                                                            setKeysUuid(Object.keys(state))
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        delete state[uuidShow];
                                                        setState(state)
                                                        setKeysUuid(Object.keys(state))
                                                    }}
                                                >
                                                    <label id='ordensPushLastTd'>
                                                        <a>-</a>
                                                    </label>
                                                </td>)}
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <footer
                    id='pushOrderFooter'
                    style={{
                        cursor: 'default',
                        opacity: notification !== '' ? '1' : '0',
                        display: notification !== '' ? 'flex' : 'none'
                    }}>
                    <div className='notificationAlertFooter'>
                        {notification}
                    </div>
                </footer>
            </>
    )
}