import React, { useState, Component, useEffect } from "react"
import { api } from '../../../../services/api';
import { useAuth } from '../../../../contexts/useAuth'
import { useModal } from '../../../../contexts/useModal';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Link, useLocation, useHistory } from "react-router-dom";

import { AiFillCloseCircle } from 'react-icons/ai';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

export function SelectFormStructure(){
  const [filtroTipoPortfolio, setFiltroTipoPortfolio] = useState("0")
  const [showDetails, setShowDetails] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const {setType, setShow} = useModal()
  const { tokenKey, userType, user } = useAuth()

  const [jsonForms, setJsonForms] = useState()
  const [jsonFormsDetails, setJsonFormsDetails] = useState()
  const [specificForm, setSpecificForm] = useState()
  const [specificFormSectionsLength, setSpecificFormSectionsLength] = useState()
  const [specificFormQuestionsLength, setSpecificFormQuestionsLength] = useState()

  const [formTypeFilter, setFormTypeFilter] = useState("0")
  const [formNameFilter, setFormNameFilter] = useState("")

  const [atualizacao, setAtualizacao] = useState(0)
  
  useEffect(() => {
    getForms()
    getFormsDetails()
    sessionStorage.removeItem('idPortfolio')
    sessionStorage.removeItem('idRespostas')
  }, [tokenKey])

  async function getForms() {
    setLoading(true)
    try {
      const formsTemp = await api.get(`/api/v1/forms`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setJsonForms(formsTemp?.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os portfólios' } })
      setShow(true)
    }
  }

  async function getFormsDetails() {
    setLoading(true)
    try {
      const formsDetailsTemp = await api.get(`/api/v1/forms/detailed`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setJsonFormsDetails(formsDetailsTemp?.data?.data)
      // console.log(formsDetailsTemp?.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os detalhes dos portfólios' } })
      setShow(true)
    }
  }

  async function getSpecificFormsDetails(id) {
    setLoadingOverlay(true)
    setShowDetails(true)
    
    let questionsSum = 0
    const specificForm = jsonFormsDetails.filter((f) => f.id === id)[0];
    specificForm.sections.forEach((secao) => {
      questionsSum += secao.questions.length
      secao.aberto = false
    })
    setSpecificForm(specificForm)
    setSpecificFormSectionsLength(specificForm.sections?.length)
    setSpecificFormQuestionsLength(questionsSum)
    setLoadingOverlay(false)
    
  }

  function jsonFiltrado(){
    let jsonFiltrado = jsonForms
    if(formTypeFilter != "0"){
      jsonFiltrado = jsonFiltrado.filter((portfolio) => portfolio.form_profile == formTypeFilter)
    }
    if(formNameFilter != null && formNameFilter != ""){
      jsonFiltrado = jsonFiltrado.filter((portfolio) => portfolio.form_title.toUpperCase().includes(formNameFilter.toUpperCase()))
    }
    return jsonFiltrado
  }

  function resetSpecificForm(){
    setSpecificForm(null)
    setSpecificFormSectionsLength(null)
    setSpecificFormQuestionsLength(null)
  }

  return (
    <div>
      {/* <button onClick={() => {console.log(formTypeFilter,formNameFilter)}}>categoria nome</button> */}
      <div className="containerHeaderSelectPortfolio">
        <div className="tituloSelectPortfolio">Selecione um portfolio</div>
        <div className="containerFiltroSelectPortfolio">
          <select className="selectTipoPortfolio" onChange={(e) => {setFormTypeFilter(e.target.value)}} value={formTypeFilter}>
            <option value="0" selected>Filtrar categoria</option>
            <option value="Moderado">Moderado</option>
            <option value="Conservador">Conservador</option>
            <option value="Agressivo">Agressivo</option>
            <option value="Geral">Geral</option>
          </select>
          <input placeholder="Filtrar nome" onChange={(e) => setFormNameFilter(e.target.value)} value={formNameFilter} type="text" className="inputNameFilter"/>
          <button className="resetFilter" onClick={() => {setFormTypeFilter("0");setFormNameFilter("")}}>Limpar</button>
        </div>
      </div>
      {loading ? 
        <div className="loaderAllForms">
          <ArrowLoader style={{marginBottom: "5rem"}} />
        </div>
        :
        <div className="containerPortfoliosExistentes">
          {jsonFiltrado()?.map((form) => {
            if(form.form_profile == "Conservador"){
              return (
                <div className="itemPortfolioConservador" title={form.form_description} onClick={() => {getSpecificFormsDetails(form.form_id)}}>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioConservador"></div>
                  <div className="divDescricao"><div className="descricaoItemPortfolio">{form.form_description}</div></div>
                  <div className="separacaoItemPortfolioConservador"></div>
                  <div className="tipoPerfilPortfolioConservador">Perfil conservador</div>
                </div>
                )
            }
            if(form.form_profile == "Moderado"){
              return (
                <div className="itemPortfolioModerado" title={form.form_description} onClick={() => {getSpecificFormsDetails(form.form_id)}}>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioModerado"></div>
                  <div className="divDescricao"><div className="descricaoItemPortfolio">{form.form_description}</div></div>
                  <div className="separacaoItemPortfolioModerado"></div>
                  <div className="tipoPerfilPortfolioModerado">Perfil moderado</div>
                </div>
              )
            }
            if(form.form_profile == "Agressivo"){
              return (
                <div className="itemPortfolioAgressivo" title={form.form_description} onClick={() => {getSpecificFormsDetails(form.form_id)}}>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioAgressivo"></div>
                  <div className="divDescricao"><div className="descricaoItemPortfolio">{form.form_description}</div></div>
                  <div className="separacaoItemPortfolioAgressivo"></div>
                  <div className="tipoPerfilPortfolioAgressivo">Perfil agressivo</div>
                </div>
              )
            }
            if(form.form_profile == "Geral"){
              return (
                <div className="itemPortfolioGeral" title={form.form_description} onClick={() => {getSpecificFormsDetails(form.form_id)}}>
                  <div className="tituloPortfolio">{form.form_title}</div>
                  <div className="separacaoItemPortfolioGeral"></div>
                  <div className="divDescricao"><div className="descricaoItemPortfolio">{form.form_description}</div></div>
                  <div className="separacaoItemPortfolioGeral"></div>
                  <div className="tipoPerfilPortfolioGeral">Perfil geral</div>
                </div>
              )
            }
          })}
        </div>
      }
      {showDetails && specificForm != null ? 
        <div className="containerOverlayPreviaPortfolio">
          <div className="overlayPreviaPortfolio" onClick={() => {resetSpecificForm();setShowDetails(false);}}></div>
          {specificForm?.profile == "Conservador" ? 
              <div className="containerDetalhesPortfolioConservador">
                {loadingOverlay ? 
                  <div className="loaderSpecificForm">
                    <ArrowLoader style={{marginBottom: "5rem"}} />
                  </div>
                  :
                  <>
                    <div className="containerHeaderDetalhesPortfolio">
                      <button className="closeDetailButton" onClick={() => {resetSpecificForm();setShowDetails(false);}}><AiFillCloseCircle size={40}/></button>
                      <div className="titleHeaderDetail">{specificForm.title}</div>
                      {/* <div hidden className="showInfoDetail"><AiOutlineInfoCircle size={40}/></div> */}
                    </div>
                    <div className="containerSubHeaderDetalhesPortfolio">
                      <div className="infoSubHeaderL">{specificFormSectionsLength} Seções</div>
                      <div className="divisaoSubHeaderConservador"></div>
                      <div className="infoSubHeaderR">{specificFormQuestionsLength} Perguntas</div>
                    </div>
                    <div className="containerSecoesPortfolio">
                      {specificForm?.sections?.map((secao, indexS) => {
                        return (
                          <>
                            <div className="secaoDetalhePortfolioConservador" onClick={() => {secao.aberto = !secao.aberto;setAtualizacao(atualizacao+1)}}>{secao.aberto ? <MdExpandLess size={26}/>:<MdExpandMore size={26}/>} {parseInt(indexS + 1) + ' ' + secao?.title}</div>
                            {secao?.aberto == true && secao?.questions?.map((pergunta, indexP) => {
                              return (<div className="perguntaDetalhePortfolioConservador">{parseInt(indexS + 1) + '.' + parseInt(indexP  + 1) + ' ' + pergunta?.description}</div>)
                            })}
                          </>
                        )
                      })}
                    </div>
                    <Link to={{pathname: "/formReply", state: {id:specificForm.id, pending_id: undefined}}} className="selectPortfolioAtual">Utilizar portfólio</Link>
                  </>
                }
              </div>
            : 
            (
            specificForm?.profile == "Moderado" ?
                <div className="containerDetalhesPortfolioModerado">
                  {loadingOverlay ? 
                    <div className="loaderSpecificForm">
                      <ArrowLoader style={{marginBottom: "5rem"}} />
                    </div>
                    :
                    <>
                      <div className="containerHeaderDetalhesPortfolio">
                        <button className="closeDetailButton" onClick={() => {setShowDetails(false)}}><AiFillCloseCircle size={40}/></button>
                        <div className="titleHeaderDetail">{specificForm.title}</div>
                        {/* <div hidden className="showInfoDetail"><AiOutlineInfoCircle size={40}/></div> */}
                      </div>
                      <div className="containerSubHeaderDetalhesPortfolio">
                        <div className="infoSubHeaderL">{specificFormSectionsLength} Seções</div>
                        <div className="divisaoSubHeaderModerado"></div>
                        <div className="infoSubHeaderR">{specificFormQuestionsLength} Perguntas</div>
                      </div>
                      <div className="containerSecoesPortfolio">
                        {specificForm?.sections?.map((secao, indexS) => {
                          return (
                            <>
                              <div className="secaoDetalhePortfolioModerado" onClick={() => {secao.aberto = !secao.aberto;setAtualizacao(atualizacao+1)}}>{secao.aberto ? <MdExpandLess size={26}/>:<MdExpandMore size={26}/>} {parseInt(indexS + 1) + ' ' + secao?.title}</div>
                              {secao?.aberto == true && secao?.questions?.map((pergunta, indexP) => {
                                return (<div className="perguntaDetalhePortfolioModerado">{ parseInt(indexS + 1) + '.' + parseInt(indexP  + 1) + ' ' + pergunta?.description}</div>)
                              })}
                            </>
                          )
                        })}
                      </div>
                      <Link to={{pathname: "/formReply",state: {id:specificForm.id, pending_id: undefined}}} className="selectPortfolioAtual">Utilizar portfólio</Link>
                    </>
                  }
                </div>
              :
              (specificForm?.profile == "Agressivo" ?
                    <div className="containerDetalhesPortfolioAgressivo">
                      {loadingOverlay ? 
                        <div className="loaderSpecificForm">
                          <ArrowLoader style={{marginBottom: "5rem"}} />
                        </div>
                        :
                        <>
                          <div className="containerHeaderDetalhesPortfolio">
                            <button className="closeDetailButton" onClick={() => {setShowDetails(false)}}><AiFillCloseCircle size={40}/></button>
                            <div className="titleHeaderDetail">{specificForm.title}</div>
                            {/* <div hidden className="showInfoDetail"><AiOutlineInfoCircle size={40}/></div> */}
                          </div>
                          <div className="containerSubHeaderDetalhesPortfolio">
                            <div className="infoSubHeaderL">{specificFormSectionsLength} Seções</div>
                            <div className="divisaoSubHeaderAgressivo"></div>
                            <div className="infoSubHeaderR">{specificFormQuestionsLength} Perguntas</div>
                          </div>
                          <div className="containerSecoesPortfolio">
                            {specificForm?.sections?.map((secao, indexS) => {
                              return (
                                <>
                                  <div className="secaoDetalhePortfolioAgressivo" onClick={() => {secao.aberto = !secao.aberto;setAtualizacao(atualizacao+1)}}>{secao.aberto ? <MdExpandLess size={26}/>:<MdExpandMore size={26}/>} {parseInt(indexS + 1) + ' ' + secao?.title}</div>
                                  {secao?.aberto == true && secao?.questions?.map((pergunta, indexP) => {
                                    return (<div className="perguntaDetalhePortfolioAgressivo">{parseInt(indexS + 1) + '.' + parseInt(indexP  + 1) + ' ' + pergunta?.description}</div>)
                                  })}
                                </>
                              )
                            })}
                          </div>
                          <Link to={{pathname: "/formReply", state: {id:specificForm.id, pending_id: undefined}}} className="selectPortfolioAtual">Utilizar portfólio</Link>
                        </>
                      }
                    </div>
                  :
                  (specificForm?.profile == "Geral" ?
                    <div className="containerDetalhesPortfolioGeral">
                      {loadingOverlay ? 
                        <div className="loaderSpecificForm">
                          <ArrowLoader style={{marginBottom: "5rem"}} />
                        </div>
                        :
                        <>
                          <div className="containerHeaderDetalhesPortfolio">
                            <button className="closeDetailButton" onClick={() => {setShowDetails(false)}}><AiFillCloseCircle size={40}/></button>
                            <div className="titleHeaderDetail">{specificForm.title}</div>
                            {/* <div hidden className="showInfoDetail"><AiOutlineInfoCircle size={40}/></div> */}
                          </div>
                          <div className="containerSubHeaderDetalhesPortfolio">
                            <div className="infoSubHeaderL">{specificFormSectionsLength} Seções</div>
                            <div className="divisaoSubHeaderGeral"></div>
                            <div className="infoSubHeaderR">{specificFormQuestionsLength} Perguntas</div>
                          </div>
                          <div className="containerSecoesPortfolio">
                            {specificForm?.sections?.map((secao, indexS) => {
                              return (
                                <>
                                  <div className="secaoDetalhePortfolioGeral" onClick={() => {secao.aberto = !secao.aberto;setAtualizacao(atualizacao+1)}}>{secao.aberto ? <MdExpandLess size={26}/>:<MdExpandMore size={26}/>} {parseInt(indexS + 1) + ' ' + secao?.title}</div>
                                  {secao?.aberto == true && secao?.questions?.map((pergunta, indexP) => {
                                    return (<div className="perguntaDetalhePortfolioGeral">{parseInt(indexS + 1) + '.' + parseInt(indexP  + 1) + ' ' + pergunta?.description}</div>)
                                  })}
                                </>
                              )
                            })}
                          </div>
                          <Link to={{pathname: "/formReply", state: {id:specificForm.id, pending_id: undefined}}} className="selectPortfolioAtual">Utilizar portfólio</Link>
                        </>
                      }
                    </div>
                  :
                  <></>
              )
              )
               
            )
          }
        </div>
        :
        <></>
      }
    </div> 
  )
}