import React, { useEffect, useState, useRef } from "react"
/*Contexts*/
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Services*/
import { FiArrowRight } from "react-icons/fi"

import { api } from "../../../services/api"
/*Components*/
// import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { Link } from "react-router-dom"
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox, MdContentCopy, MdSearch, MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md"
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs"
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai"
import { FaCheck, FaArrowUp } from "react-icons/fa"
import { BsSend } from "react-icons/bs"
import { updateABCStatus } from "../../../services/asyncFunctions"
import { makeStyles } from "@material-ui/core/styles"
import { Tooltip, Button } from "@material-ui/core"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { BsFillSquareFill } from "react-icons/bs"
import Select from "react-select"
import { TextField, Checkbox } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { AiFillCloseCircle } from "react-icons/ai"

import { FormControlLabel, FormGroup, Radio, RadioGroup } from "@mui/material"
import { styleClientsInput } from "../../../components/styles/autocompletField"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"

export function DashboardAdvisoClientProductsModal(props) {

    const classes = styleClientsInput()
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [operationId, setOperationId] = useState(null)
    const [categoryInfos, setCategoryInfos] = useState(null)
    const [filters, setFilters] = useState([])
    const [items, setItems] = useState([])
    const [allItems, setAllItems] = useState([])
    const [filteredItems, setFilteredItems] = useState([])
    const [itemQty, setItemQty] = useState(30)
    const [loading, setLoading] = useState(true)
    const [loadingTimeline, setLoadingTimeline] = useState(false)
    const [copyingDescription, setCopyingDescription] = useState(false)
    const [copyingLink, setCopyingLink] = useState([])
    const [client_speech, setClientSpeech] = useState("")
    const [newStatus, setNewStatus] = useState(-1)
    const selectedProduct = useRef()
    const [refuseReason, setRefusalReason] = useState("")
    const [currentStatus, setCurretnStatus] = useState("")
    const [closeMessage, setCloseMessage] = useState(true)
    const [link, setLink] = useState({
        boletar: "#/",
        acompanhamento: "#/",
    })
    const [typeAbc, setTypeAbc] = useState(0) //tipo de produto pode ser RV ou de asssessor (por hora)
    const [contactType, setContactType] = useState(5)
    const [comentTimeline, setComentTimeline] = useState("")
    const [defaultComments, setDefaultComments] = useState({
        2: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct?.current?.description}. Cliente ficou de avaliar a sugestão.` },
        6: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct?.current?.description}. Cliente não teve interesse na oferta e recusou o produto.` },
        7: { comment: `Cliente não possui perfil para o produto ${selectedProduct?.current?.description}.` },
    });
    const [activeRefusalReasonBox, setActiveRefusalReasonBox] = useState(false)
    const [selectedReason, setSelectedReason] = useState("")
    const filterProduct = React.useRef(true)
    const product = React.useRef(null)
    const [refusalReasons, setRefusalResasons] = useState([
        {
            id: "recurso",
            label: "Falta de recurso p/ alocar",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
        },
        { id: "prazo", label: "Prazo do produto", comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.` },
        {
            id: "cenario",
            label: "Cenário Adverso",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
        },
        {
            id: "preferencia",
            label: "Prefere outro produto",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
        },
        {
            id: "taxa",
            label: "Taxa não atrativa",
            comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
        },
        { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
    ])

    const [searchActive, setSearchActive] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [clients, setClients] = useState([])
    const [filterCategory, setFilterCategory] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [geralCategory, setGeralCategory] = useState(null)
    const [categories, setCategories] = useState({})
    const [description, setDescription] = useState("")
    const [currentPosition, setCurrentPosition] = useState(0)
    const menuItemsRef = React.createRef()
    const [loadingLiquidez, setLoadingLiquidez] = useState(false)
    const [liquidez, setLiquidez] = useState()
    const [netFundos, setNetFundos] = useState()

    const [boletar, setBoletar] = useState(false)
    const [boletarValue, setBoletarValue] = useState(0)
    const [tabActive, setTabActive] = useState(0)

    const useStyles = makeStyles((theme) => ({
        timelineNewCommentContactTypeActive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#c49e17",
            // backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: "white",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineNewCommentContactTypeInactive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            color: "white",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterType1Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType2Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType3Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType4Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType5Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType0Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterType6Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeInactive: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterTypeProduct: {
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#c49e17",
            color: "white",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            cursor: "pointer",
        },
        timelineFilterTypeProductInactive: {
            color: "white",
            width: "fit-content",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.2rem",
            marginRight: "0.2rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineNewComentWriteArea: {
            paddingRight: "3rem",
            resize: "none",
            borderRadius: "10px",
            border: "1px solid #dadada",
            backgroundColor: "#f8f8f8",
            padding: "0.5rem",
            fontSize: "1rem",
            // width: "calc(100% - 2rem)",
            "&:focus": {
                outline: "none",
                border: "#c49e17 2px solid",
                // contactType == 5
                //     ? "2px solid #412500"
                //     : contactType == 1
                //     ? "2px solid #165102"
                //     : contactType == 2
                //     ? "2px solid #001D82"
                //     : contactType == 3
                //     ? "2px solid #A90000"
                //     : "2px solid #BA9C00",
            },
        },
        timelineNewCommentSendActive: {
            width: "3rem",
            height: "3rem",
            // backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            // color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            backgroundColor: "transparent",
            color: "black",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            // transform: 'rotate(-55deg)',
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            "&:hover": {
                backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
                boxShadow:
                    contactType == 5
                        ? "0px 0px 4px #b8b8b8"
                        : contactType == 1
                        ? "0px 0px 4px #165102"
                        : contactType == 2
                        ? "0px 0px 4px #001D82"
                        : contactType == 3
                        ? "0px 0px 4px #A90000"
                        : "0px 0px 4px #BA9C00",
            },
        },
        timelineNewCommentSendInative: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "transparent",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            // transform: 'rotate(-55deg)',
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginLeft: "-3.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
        timelineComentBoxUserComentName: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
    }))

    const classesContactType = useStyles()

    function getOperationId() {
        // const prod_name = props?.prop?.product?.name;
        // console.log(props?.prop)
        var operation_id = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__operation_id`]) {
            operation_id = props?.prop?.client[`c${selectedProduct?.current?.id}__operation_id`]
        }
        setOperationId(operation_id)
    }

    useEffect(() => {
        if (selectedProduct?.current?.description) {
          setDefaultComments({
            2: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct.current.description}. Cliente ficou de avaliar a sugestão.` },
            3: { comment: `Feito o envio de ordem do produto ${selectedProduct.current.description} para o cliente.` },
            6: { comment: `Feito contato com o cliente sobre o produto ${selectedProduct.current.description}. Cliente não teve interesse na oferta e recusou o produto.` },
            7: { comment: `Cliente não possui perfil para o produto ${selectedProduct.current.description}.` },
          });
      
          setRefusalResasons([
            {
                id: "recurso",
                label: "Falta de recurso p/ alocar",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
            },
            {
                id: "prazo",
                label: "Prazo do produto",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.`,
            },
            {
                id: "cenario",
                label: "Cenário Adverso",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
            },
            {
                id: "preferencia",
                label: "Prefere outro produto",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
            },
            {
                id: "taxa",
                label: "Taxa não atrativa",
                comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
            },
            { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
        ]);
        setNewStatus(-1)
        }
        if(selectedProduct?.current?.data_liquidacao || selectedProduct?.current?.finish )
            getLiquidez()   
      }, [selectedProduct.current]);

    function getCategory() {
        // const prod_name = props.prop.product.name;
        var cat_id = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__category_id`]) {
            cat_id = props?.prop?.client[`c${selectedProduct?.current?.id}__category_id`]
        }
        const cat = selectedProduct?.current?.categories?.find((x) => x.id == cat_id)
        setCategoryInfos(cat)
        var aux = []
        let linkSplited = cat?.link?.split(" ")
        linkSplited?.forEach(() => {
            aux.push(false)
        })
        setCopyingLink(aux)
    }

    async function submitBoleta() {
        setLoading(true)
        try {
            const res = await api.put(
                `/api/v1/alocacao/value/${props?.prop?.client?.client_id}`,
                {
                    operation_id: operationId,
                    boletarValue: boletarValue,
                    abc_products_id: selectedProduct?.current?.id,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )
            // console.log(res?.data?.data);
            // setType({ name: "alertMsg", type: "information", value: { message: `Boleta enviada com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso! Lembre-se que a boleta deve ser enviada via HUB")
            setCurretnStatus("Aceito")
            props.prop.client[`c${selectedProduct.current?.id}__status`] = "Aceito"
            props.prop.client[`c${selectedProduct.current?.id}__value`] = boletarValue
            if(res?.data?.data?.[0]?.id){
                props.prop.client[`c${selectedProduct.current?.id}__operation_id`] = res?.data?.data[0]?.id
                setOperationId(res?.data?.data[0]?.id)
            }
        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao enviar a boleta" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }

    function getSpeech() {
        var client_speech = null
        if (props?.prop?.client && props?.prop?.client[`c${selectedProduct?.current?.id}__speech`]) {
            client_speech = props?.prop?.client[`c${selectedProduct?.current?.id}__speech`]
        }
        setClientSpeech(client_speech != null ? client_speech : "")
    }

    function getLink() {
        const prod_type = selectedProduct?.current?.type.id
        if (prod_type == 1 || prod_type == 3) {
            // ESTRUTURADAS E IMPORTADAS
            setLink({
                boletar: "#/books",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            })
        } else if (prod_type == 2) {
            // OFERTA PÚBLICA
            setLink({
                boletar: "#/fiis",
                acompanhamento: user.type < 3 ? "#/clients" : "#/clientesMesa",
            })
        }
    }

    async function updateStatus(status_id) {
        setLoading(true)
        setNewStatus(status_id)
        setComentTimeline(defaultComments[status_id].comment)
    }

    async function sendNewStatus() {
        if (newStatus < 0) {
            return
        }
        try {
            const res = await updateABCStatus(tokenKey, operationId, newStatus, selectedProduct?.current?.id, props?.prop?.client?.client_id)
            // console.log(res?.data?.data);
            // setType({ name: 'alertMsg', type: 'information', value: { message: `Status alterado com sucesso.` } })
            // setEdited(true)
            alert("Status alterado com sucesso!")

            if (newStatus == 6) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Recusado"
                props.prop.client[`c${selectedProduct?.current?.id}__receita_estimada`] = '-'
            } else if (newStatus == 7) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Não aderente"
                props.prop.client[`c${selectedProduct?.current?.id}__receita_estimada`] = '-'
            } else if (newStatus == 2) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Abordado"
            } else if (newStatus == 3) {
                props.prop.client[`c${selectedProduct?.current?.id}__status`] = "Aceito"
            }
            setNewStatus(-1)
            props.prop?.doSum()
        } catch (error) {
            setLoading(false)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message == undefined ? "Erro interno ao atualizar o status do cliente" : error?.response?.data?.message },
            })
            setShow(true)
        }
        setLoading(false)
    }


    async function getData() {
        if (show) {
            selectedProduct.current = props.prop.product
            setTypeAbc(selectedProduct.current.type.id)
            setItems([])
            setFilteredItems([])
            getOperationId()
            getCategory()
            getSpeech()
            getLink()
            setDefaultComments({
                2: { comment: `Feito contato com o cliente sobre o produto ` + selectedProduct?.current?.description + `. Cliente ficou de avaliar a sugestão.` },
                3: { comment: `Feito o envio de ordem do produto ` + selectedProduct?.current?.description + ` para o cliente.` },
                6: { comment: `Feito contato com o cliente sobre o produto ` + selectedProduct?.current?.description + `. Cliente não teve interesse na oferta e recusou o produto.` },
                7: { comment: `Cliente não possui perfil para o produto ` + selectedProduct?.current?.description + `.` },
            })
            setRefusalResasons([
                {
                    id: "recurso",
                    label: "Falta de recurso p/ alocar",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente está sem recurso disponível.`,
                },
                {
                    id: "prazo",
                    label: "Prazo do produto",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o prazo do produto.`,
                },
                {
                    id: "cenario",
                    label: "Cenário Adverso",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por receio com o cenário macro atual.`,
                },
                {
                    id: "preferencia",
                    label: "Prefere outro produto",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por preferir outras classes de ativos no momento.`,
                },
                {
                    id: "taxa",
                    label: "Taxa não atrativa",
                    comment: `Abordado o investidor sobre a oportunidade ${selectedProduct?.current?.description}, porém o cliente não aceitou o produto por não achar atrativa a taxa do ativo.`,
                },
                { id: "outros", label: "Outros", comment: `Escreva o motivo!` },
            ])

            //separa os produtos que o cliente tem e nao tem oportunidade > que 0. Um campo novo é inserido no objeto do produto
            // console.log("props.prop.client", props.prop.client)
            props.prop.products.map((p) => {
                if (props.prop.client[`c${p.id}__objective`] > 0) {
                    p.hasOpportunity = true
                } else {
                    p.hasOpportunity = false
                }
            })
        }
    }

    useEffect(() => {
        if (show) {
            getData()
            getLiquidez()
        }
        else clear()
    }, [show])

    useEffect(() => {
        if (selectedProduct.current) {
            getOperationId()
            getCategory()
            getSpeech()
            getLink()
            getTimeline()
            setComentTimeline("")
            setNewStatus(-1)
        }
    }, [selectedProduct.current])

    useEffect(() => {
        var aux = {}
        selectedProduct.current?.categories?.map((x, index) => {
            x.displayId = index + 1
            aux[x.id] = x
        })
        setCategories(aux)

        const geral = selectedProduct.current?.categories?.filter((x) => x.geral_reference == true)
        if (geral?.length > 0) {
            setSelectedCategory(geral[0].id)
            setGeralCategory(geral[0].id)
        }

        if (show) selectedProduct.current = props.prop.product
    }, [show])

    function disableCopying() {
        setCopyingDescription(false)
        var aux = copyingLink.map((l) => false)
        setCopyingLink(aux)
    }

    function clear() {
        setLink({
            boletar: "#/",
            acompanhamento: "#/",
        })
        setComentTimeline("")
        setContactType(5)
        setFilters([])
        setItems([])
        setFilteredItems([])
        setClientSpeech("")
        setNewStatus(-1)
        setActiveRefusalReasonBox(false)
        setSelectedReason("")
        setRefusalReason("")
        setBoletar(false)
        setBoletarValue(0)
        setOperationId(null)
        setCategoryInfos(null)
        setCopyingDescription(false)
        setCopyingLink([])

        selectedProduct.current = null
        // selectedClient.current = null
        // setOperationId(null)
        // setCategoryInfos(null)
        // setCopyingDescription(false)
        // setCopyingLink([])
        // setRefusalReason("")
    }

    async function handleSubmitComment() {
        if (comentTimeline !== null && comentTimeline != "") {
            //se status recusado add o motivo da recusa antes do texto
            var newMessage = ""
            if (newStatus == 6) {
                const reason = refusalReasons.filter((r) => r.id == selectedReason)
                newMessage = `Feito contato com o cliente sobre o produto ${selectedProduct.current?.description} porém o cliente recusou. Motivo: ${reason[0].label}. ${comentTimeline} `
            }
            try {
                const clientUpdate = await api.post(
                    `/api/v1/vba/sendComment/${props?.prop?.client?.client_id}?abc_products_id=${selectedProduct.current?.id}`,
                    {
                        comment: newStatus == 6 ? newMessage.replaceAll("'", "''") : comentTimeline.replaceAll("'", "''"),
                        commentType: contactType,
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                            // abc_products_id: props.prop.product.id,
                        },
                    }
                )
                setComentTimeline("")
                setContactType(5)
                getTimeline()
                if (newStatus >= 0) {
                    sendNewStatus()
                }
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        } else {
            alert("O comentário nao pode ser vazio")
        }
    }

    async function filterTimeline(all) {
        var todosItems = all ? all : structuredClone(allItems ? allItems : [])
        var filteredItemsAux = []
        if (filters?.length > 0) {
            filteredItemsAux = todosItems?.filter((e) => filters?.indexOf(parseInt(e.type)) >= 0)
        } else {
            filteredItemsAux = todosItems
        }
        if (filterProduct.current) {
            filteredItemsAux = filteredItemsAux?.filter((e) => e?.abc_products_id == selectedProduct?.current?.id)
        }

        await setFilteredItems(filteredItemsAux)
        await setItems(filteredItemsAux?.length - 30 < 0 ? filteredItemsAux : filteredItemsAux?.slice(filteredItemsAux?.length - 30))
        if (show) scrollBottom()
    }

    async function getLiquidez(){
        setLoadingLiquidez(true)
        var data_liquidacao = selectedProduct?.current?.data_liquidacao == null ?  (selectedProduct?.current?.finish) : (selectedProduct?.current?.data_liquidacao)
        try {
            const data = await api.get(`/api/v1/alocacao/liquidez/${props?.prop?.client?.client_id}/${data_liquidacao}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
                body: {
                    client_id: props?.prop?.client?.client_id,
                    dateLiquidation: data_liquidacao ,
                },
            })
            setLiquidez(data?.data?.data[0]?.net_vencimento)
            setNetFundos(data?.data?.data[0]?.net_fundos)
            setLoadingLiquidez(false)
        } catch (error) {
            alert(error?.response?.data?.message)
            // await setLoadingLiquidez(false)
        }

        setLoadingLiquidez(false)
    }

    async function getTimeline() {
        await setLoadingTimeline(true)
        try {
            const timeline = await api.get(`/api/v1/vba/clientTimeline/${props?.prop?.client?.client_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            })

            await setAllItems(timeline?.data?.data)

            await filterTimeline(timeline?.data?.data)

            await setLoadingTimeline(false)
            if (show) scrollBottom()
        } catch (error) {
            alert(error?.response?.data?.message)
            await setLoadingTimeline(false)
        }
    }

    async function updateItemsList() {
        var auxAllItems = structuredClone(filteredItems)
        var objDiv = document.getElementById("timelineCommentBoxAbc")
        var scrollNext = objDiv?.scrollHeight
        if (itemQty != auxAllItems.length) {
            if (auxAllItems.length - (itemQty + 30) <= 0) {
                await setItems(auxAllItems)
                await setItemQty(auxAllItems.length)
            } else {
                await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)))
                await setItemQty(itemQty + 30)
            }
            if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight - scrollNext
        }
    }

    async function scrollBottom() {
        var objDiv = document.getElementById("timelineCommentBoxAbc")
        if (objDiv) {
            objDiv.scrollTop = objDiv?.scrollHeight
        }
    }

    return (
        <div className="containerModalDashDetails" style={{ zoom: `${window.innerHeight < 575 ? 0.95 : 1}` }}>
            <div className="clientControlHeader" style={{ zoom: `${window.innerHeight > 760 ? 1 : window.innerHeight > 575 ? 0.9 : 0.8}` }}>
                <div className="editBrancheBoxHeaderDash">
                    <div className="containerProductNameDash">
                        <p>
                            {props.prop?.client?.client_id} - {props.prop?.client?.client}:
                        </p>
                        <p>{selectedProduct.current?.description} </p>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                        <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                        <button
                            onClick={() => {
                                // setEdited(true)
                                clear()
                                setShow(false)
                            }}
                            className="modalCloseButtonDashboard"
                            style={{ marginRight: "12px", height: "36px", width: "36px" }}
                        >
                            <AiFillCloseCircle />
                        </button>
                    </div>
                </div>
            </div>
            <div
                className="containerClientMenuDashActions"
                style={{
                    zoom: `${window.innerHeight > 760 ? 1 : window.innerHeight > 575 ? 0.9 : window.innerHeight > 500 ? 0.8 : 0.65}`,
                    height: `${window.innerHeight > 760 ? "calc(100vh - 10rem)" : window.innerHeight > 575 ? "calc(100vh - 4rem)" : "calc(100vh - 4rem)"}`,
                    marginTop: "0.5rem",
                }}
            >
                {/* lista de produtos */}
                <div>
                    <div className="listClientsModalAbcDash" style={{height:'calc(100% - 4rem)'}}>
                        <div
                            style={{
                                color: "#D4AC00",
                                fontWeight: "bold",
                                paddingLeft: "1rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            Nome do produto
                        </div>

                        <>
                            <div
                                style={{
                                    color: "#D4AC00",
                                    // fontWeight: "bold",
                                    paddingLeft: "1rem",
                                    paddingBottom: "1rem",
                                }}
                            >
                                Campanhas para o cliente
                            </div>
                            <div className="listClientsBodyModalAbcAbrir">
                                {props.prop?.products?.map((p, index) => {
                                    if (!p.hasOpportunity) return null
                                    const client_cat = props.prop.client["c" + p.id + "__category_id"]
                                    var statusClient = String(props.prop.client["c" + p.id + "__status"]).toUpperCase()
                                    var statusColor =
                                        statusClient == "PENDENTE"
                                            ? "#D4AC00"
                                            : statusClient == "ABORDADO"
                                            ? "#6B94DE"
                                            : statusClient == "ACEITO"
                                            ? "#c084fc"
                                            : statusClient == "BOLETADO"
                                            ? "#58B935"
                                            : statusClient == "CANCELADO"
                                            ? "#FF83FE"
                                            : statusClient == "RECUSADO"
                                            ? "#f76a6a"
                                            : statusClient == "NÃO ADERENTE"
                                            ? "#000000"
                                            : statusClient == "ANÁLISE DO BACKOFFICE"
                                            ? "#2BC3BB"
                                            : "#8C34BA"

                                    return (
                                        <button
                                            key={index}
                                            className={selectedProduct.current && p.id == selectedProduct.current.id ? "listClientsItemActiveAbcAbrirDash" : "listClientsItemInativeAbcAbrirDash"}
                                            title={titleize(p.description)}
                                            onClick={() => {
                                                // c.categoryName = id_display.title
                                                // c.categoryLink = id_display?.link?.split(" ")
                                                selectedProduct.current = p
                                                var aux = [false]
                                                // p.categoryLink?.forEach(() => {
                                                //     aux.push(false)
                                                // })
                                                setCopyingLink(aux)
                                            }}
                                        >
                                            <div className="statusColorDivAbc" title={titleize(String(props.prop?.client["c" + selectedProduct.current?.id + "__status"]))}>
                                                <BsFillSquareFill
                                                    color={statusColor}
                                                    className="statusColorDivAbc"
                                                    title={titleize(String(props.prop?.client["c" + p.id + "__status"] ? props.prop?.client["c" + p.id + "__status"] : "Indefinido"))}
                                                />
                                            </div>
                                            {titleize(p.description)}
                                        </button>
                                    )
                                })}
                            </div>

                            <div
                                style={{
                                    color: "#D4AC00",
                                    // fontWeight: "bold",
                                    paddingLeft: "1rem",
                                    paddingBottom: "1rem",
                                }}
                            >
                                Outras Campanhas
                            </div>
                            <div className="listClientsBodyModalAbcAbrir">
                                {props.prop?.products?.map((p, index) => {
                                    if (p.hasOpportunity) return null
                                    const client_cat = props.prop.client["c" + p.id + "__category_id"]
                                    var statusClient = String(props.prop.client["c" + p.id + "__status"]).toUpperCase()
                                    var statusColor =
                                        statusClient == "PENDENTE"
                                            ? "#D4AC00"
                                            : statusClient == "ABORDADO"
                                            ? "#6B94DE"
                                            : statusClient == "ACEITO"
                                            ? "#c084fc"
                                            : statusClient == "BOLETADO"
                                            ? "#58B935"
                                            : statusClient == "CANCELADO"
                                            ? "#FF83FE"
                                            : statusClient == "RECUSADO"
                                            ? "#f76a6a"
                                            : statusClient == "NÃO ADERENTE"
                                            ? "#000000"
                                            : statusClient == "ANÁLISE DO BACKOFFICE"
                                            ? "#2BC3BB"
                                            : "#8C34BA"

                                    return (
                                        <button
                                            key={index}
                                            className={selectedProduct.current && p.id == selectedProduct.current.id ? "listClientsItemActiveAbcAbrirDash" : "listClientsItemInativeAbcAbrirDash"}
                                            title={titleize(p.description)}
                                            onClick={() => {
                                                // c.categoryName = id_display.title
                                                // c.categoryLink = id_display?.link?.split(" ")
                                                selectedProduct.current = p
                                                var aux = [false]
                                                // p.categoryLink?.forEach(() => {
                                                //     aux.push(false)
                                                // })
                                                setCopyingLink(aux)
                                            }}
                                        >
                                            <div className="statusColorDivAbc" title={titleize(String(props.prop?.client["c" + selectedProduct.current?.id + "__status"]))}>
                                                <BsFillSquareFill
                                                    color={statusColor}
                                                    className="statusColorDivAbc"
                                                    title={titleize(String(props.prop?.client["c" + p.id + "__status"] ? props.prop?.client["c" + p.id + "__status"] : "Indefinido"))}
                                                />
                                            </div>
                                            {titleize(p.description)}
                                        </button>
                                    )
                                })}
                            </div>
                        </>
                    </div>
                </div>
                {/* <div className="divisionNameProductSubcategory"></div> */}
                <div></div>

                {/* speech do cliente */}
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "auto auto 3fr",
                        margin: "1rem",
                        marginTop: "-1rem",
                    }}
                >
                    <div style={{marginTop:'0.5rem'}}>
                        <div style={{marginBottom: '0.5rem'}}>
                            <span 
                                onClick={() => {
                                    setTabActive(0)
                                }}
                                style={{ 
                                    background: tabActive == 0 ? '#C49E17' : '#868686', 
                                    color: 'white' , 
                                    fontWeight:'bold' , 
                                    padding: '0.5rem' , 
                                    borderRadius: '0.5rem 0.5rem 0px 0px',
                                    cursor: 'pointer',
                                    fontSize: '0.85rem'
                                 }} >
                                    Liquidez do Cliente
                                </span>
                            <span 
                                onClick={() => {
                                    setTabActive(1)
                                }}
                                style={{ 
                                    background: tabActive == 1 ? '#C49E17' : '#868686', 
                                    color: 'white' , 
                                    fontWeight:'bold' , 
                                    padding: '0.5rem' , 
                                    borderRadius: '0.5rem 0.5rem 0px 0px',
                                    cursor: 'pointer',
                                    fontSize: '0.85rem'
                                 }}>
                                    Informações do Produto
                                </span>
                        </div>
                            <div className="containerInfosUserDetailsModal" >
                                { 

                                tabActive == 0 ? (
                                    loadingLiquidez ? <ArrowLoader /> :
                                    <>
                                        <div style={{ marginLeft: "26px", overflowX: "auto", height: "9rem", padding:'0.25rem' }}>
                                            <span>
                                                <span style={{ color: "#D4AC00", fontWeight: "bold", fontSize: "1.2rem" }}>Liquidez do Cliente</span> <br />
                                            </span>
                                            <span>
                                                <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Vencimentos de RF até liquidação da campanha: </span>
                                                {liquidez ? toBRL(liquidez) : '-'}
                                            </span>
                                            <br />
                                            <span style={{cursor:'pointer',marginLeft:'-0.5rem'}}>
                                                <Link   
                                                    to={{
                                                        pathname: `#/investimentos/fundos/${props.prop.client.client_id}/2`,
                                                    }}
                                                    style={{ color: "#D4AC00", fontWeight: "bold", textDecoration: 'underline' }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(`#/investimentos/fundos/${props.prop.client.client_id}/2`, '_blank');
                                                    }}
                                                    >
                                                        <Tooltip title="Acessar Fundos em Liquidez" arrow>
                                                        <Button style={{ color: '#D4AC00', fontWeight:'bold', textTransform: "capitalize", fontSize:'1rem'}}>Fundos em Liquidez: <span style={{color:'black', textDecoration:'none', fontWeight:'300'}}>{netFundos ?  ` ${toBRL(netFundos)}` : ' -'}</span> </Button>
                                                        {/* <Button ></Button> */}
                                                    </Tooltip>
                                                </Link>
                                                
                                                {/* <FaArrowUp /> */}
                                            </span>
                                            <br />

                                            <span>
                                                <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Liquidez Necessária(Parametrizada): </span>
                                                {props.prop.client.necessary_liq || props.prop.client.net_total ? toBRL(props.prop.client.necessary_liq * (props.prop.client.net_total / 100)) : "-"}
                                            </span>
                                            <br />

                                            <span>
                                                <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Liquidez Excedente: </span>
                                                {netFundos && liquidez ? toBRL(netFundos - (props.prop.client.necessary_liq * (props.prop.client.net_total / 100))) : "-"}
                                            </span>
                                        </div>
                                    </> ) : tabActive == 1 ? (
                                        <div className="containerInfosUserDetailsModal" style={{padding:'0.25rem'}} >
                                            <div style={{ marginLeft: "26px", overflowX: "auto", maxHeight: "10rem" , height: "9rem" }}>
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold", fontSize: "1.2rem" }}>Informações do produto</span> <br />
                                                </span>
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Oportunidade: </span>
                                                    {props.prop.client[`c${selectedProduct.current?.id}__objective`] ? toBRL(props.prop.client[`c${selectedProduct.current?.id}__objective`]) : "-"}
                                                </span>
                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Oportunidade planejada: </span>
                                                    {props.prop.client[`c${selectedProduct.current?.id}__planned_value`] ? toBRL(props.prop.client[`c${selectedProduct.current?.id}__planned_value`]) : "-"}
                                                </span>
                                                <br />

                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Realizado: </span>
                                                    {props.prop.client[`c${selectedProduct.current?.id}__value`] ? toBRL(props.prop.client[`c${selectedProduct.current?.id}__value`]) : "-"}
                                                </span>
                                                <br />

                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Receita estimada: </span>
                                                    {props.prop.client
                                                        ? props.prop.client["c" +selectedProduct.current?.id + "__value"] > 0 && props.prop.client["c" +selectedProduct.current?.id + "__value"] != null
                                                            ? toBRL(props.prop.client["c" +selectedProduct.current?.id + "__value"] * selectedProduct.current?.fee)
                                                            : props.prop.client["c" +selectedProduct.current?.id + "__objective"] > 0 && props.prop.client["c" +selectedProduct.current?.id + "__objective"] != null
                                                            ? toBRL(props.prop.client["c" +selectedProduct.current?.id + "__objective"] * selectedProduct.current?.fee)
                                                            : '-'
                                                        : "-"}
                                                </span>

                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>FEE: </span>
                                                    {selectedProduct.current?.fee ? (selectedProduct.current?.fee * 100).toFixed(2) + "%" : "N/C"}
                                                </span>
                                                <br />

                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Descrição: </span>
                                                    {selectedProduct.current?.card_description}
                                                </span>

                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Prioridade: </span>
                                                    {props.prop?.prioritys.find((p) => p.id == selectedProduct.current?.priority)?.value}
                                                </span>
                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Produto: </span>
                                                    {selectedProduct.current?.type?.description}
                                                </span>
                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Inicío: </span>
                                                    {selectedProduct.current?.start ? datetimeToDatePtBR(selectedProduct.current?.start) : null}
                                                </span>

                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Fim: </span>
                                                    {selectedProduct.current?.finish ? datetimeToDatePtBR(selectedProduct.current?.finish) : null}
                                                </span>

                                                <br />
                                                <span>
                                                    <span style={{ color: "#D4AC00", fontWeight: "bold" }}>Data Liquidação: </span>
                                                    {selectedProduct.current?.data_liquidacao ? datetimeToDatePtBR(selectedProduct.current?.data_liquidacao) : null}
                                                </span>
                                            </div>
                                        </div>) : 
                                    <></>
                                    
                                
                                    
                                }
                            </div>
                        </div>
                        {typeAbc != 1 ? (
                            <div style={{margin:'0.5rem 0'}}>
                                {/* <div className="divisionNameProductSubcategory"></div> */}
                                <div></div>
                                <div className="containerClientMenuOptionsAbcActionsDash" style={{ minWidth: "16rem", justifyItems:'center', borderRadius:'0.5rem', margin:'0.5rem 0', minHeight:'10rem', height: boletar ? '250px' : 'inherit'}}>
                                    <div className="containerNameAbcActionsDash" style={{margin:'0'}} >
                                        <div className="clientInfoAbcActionsDash">
                                            <div
                                                style={{
                                                    color: "#D4AC00",
                                                    fontWeight: "bold",
                                                    paddingTop: "1rem",
                                                    fontSize: "1.2rem",
                                                }}
                                            >
                                                Alterar Status{" "}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                color: "#BCBCBC",
                                            }}
                                        >
                                            Status Atual:{" "}
                                            {titleize(
                                                props.prop.client && props.prop.client[`c${selectedProduct.current?.id}__status`] ? props.prop.client[`c${selectedProduct.current?.id}__status`] : "Indefinido"
                                            )}
                                        </span>
                                    </div>
                                    <div className="containerGeneralOptionsAbcActions">
                                        {/* <div className="containerOptionsTitleAbcActions">
                                        <div className="optionsTitleAbcActions">{props?.prop?.product?.description}</div>
                                    </div>
                                    <div className="containerOptionsAbcActions">
                                        {props.prop.product.type.id != 3 ? <button
                                            className="buttonAbcActions"
                                            
                                            onClick={() => {
                                            //   const win = window.open(link.boletar, "_blank");
                                            //   win.focus();
                                            setType({ name: props.prop.product.type.id == 1 ? "boletarEstruturada" : 'boletarOfertaPublica', props: {product : props.prop.product, client: props.prop.client, clients: props.prop.clients, returnTo: 'menu'} });
                                            
                                            }}>
                                            Boletar
                                        </button> : 
                                        <button
                                        className="buttonAbcActionsSolicit"
                                        onClick={() => {
                                            updateStatus(3)
                                        }}>
                                        Solicitar
                                    </button>}
                                    </div> */}
                                    </div>

                                    <div className="containerGeneralChangeStatusAbcActions" style={{height:'0'}}>
                                        {/* <div className="containerChangeStatusTitleAbcActions">
                                            <div className="ChangeStatusTitleAbcActions">Alterar Status</div>
                                        </div> */}
                                        <div className="containerChangeStatusAbcActionsDash" 
                                            style={{display:"flex", flexDirection:'row'}}
                                        >
                                            <button
                                                style={{ background: newStatus === 2 ? "#C49E18" : "#868686" }}
                                                className="buttomGray360"
                                                onClick={() => {
                                                    setActiveRefusalReasonBox(false)
                                                    setBoletar(false)
                                                    setCloseMessage(false)
                                                    if (newStatus === 2) {
                                                        setCloseMessage(true)
                                                        setNewStatus(-1)
                                                    } else updateStatus(2)
                                                }}
                                            >
                                                <p>Abordado</p>
                                            </button>
                                            <button
                                                style={{ background: activeRefusalReasonBox ? "#C49E18" : "#868686" }}
                                                className="buttomGray360"
                                                onClick={() => {
                                                    setActiveRefusalReasonBox(!activeRefusalReasonBox)
                                                    setBoletar(false)
                                                    setNewStatus(-1)
                                                }}
                                            >
                                                <p>Recusado</p>
                                            </button>
                                            <button
                                                className="buttomGray360"
                                                style={{ background: newStatus === 7 ? "#C49E18" : "#868686" }}
                                                onClick={() => {
                                                    setActiveRefusalReasonBox(false)
                                                    setBoletar(false)
                                                    setCloseMessage(false)
                                                    if (newStatus === 7) {
                                                        setCloseMessage(true)
                                                        setNewStatus(-1)
                                                    } else updateStatus(7)
                                                }}
                                            >
                                                <p>Não Aderente</p>
                                            </button>

                                            <button
                                                className="buttomGray360"
                                                style={{ background: boletar ? "#C49E18" : "#868686", }}
                                                onClick={() => {
                                                    setActiveRefusalReasonBox(false)
                                                    setBoletar(!boletar)
                                                    setNewStatus(-1)
                                                    setCloseMessage(true)
                                                }}
                                            >
                                                Aceito
                                            </button>

                                        </div>
                                            {boletar ? (
                                                <div style={{padding:'0 2rem'}}>
                                                    <div style={{display:"flex", flexDirection: "row", gap:'1rem'}}>
                                                        <CurrencyTextField
                                                            style={{ height: "2.4rem", width: '80%'}}
                                                            variant="outlined"
                                                            value={boletarValue}
                                                            currencySymbol="R$"
                                                            className={classes.styleForClients}
                                                            outputFormat="number"
                                                            decimalCharacter=","
                                                            digitGroupSeparator="."
                                                            onChange={(event, value) => {
                                                                setBoletarValue(value)
                                                            }}
                                                        />

                                                        <button
                                                            className="buttomGray360"
                                                            style={{ background: boletar ? "#C49E18" : "#868686", width: '30%' }}
                                                            onClick={() => {
                                                                setActiveRefusalReasonBox(false)
                                                                setBoletar(!boletar)
                                                                submitBoleta()
                                                            }}
                                                        >
                                                            Enviar
                                                        </button>
                                                    </div>
                                                    <span style={{ color: "red", fontSize: "0.9rem" }}>* A boleta deve ser enviada via HUB e será registarda aqui automaticamente no próximo dia útil</span>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                    </div>

                                    { closeMessage == false ? (
                                        <div className="suggestionMessageDash alertMessageAbc" style={{marginTop:'60px'}}>
                                            <div style={{display:'flex', alignItems:'center', justifyContent:'space-evenly'}}>
                                                <div className="suggestionMessageHeader">A mensagem ao lado será registrada.</div>
                                                <span style={{position:'relative', left:'1rem',paddingTop:'0.6rem', cursor:'pointer'}} onClick={() => { setCloseMessage(true)  }} ><AiFillCloseCircle size={24} /></span>
                                            </div>
                                            <div
                                                className="suggestionMessageBody"
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "auto 2rem",
                                                    bottom: "0px",
                                                    paddingTop: '0px'
                                                }}
                                            >
                                                <div>Adicione uma descrição mais completa se necessário.</div>
                                                <div>
                                                    <FiArrowRight color size="40" />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                {/* <div className="divisionNameProductSubcategory"></div> */}
                                <div></div>
                            </div>
                        ) : (
                            <>
                                <div></div>
                                <div></div>
                                <div></div>

                                {/* <div className="divisionNameProductSubcategory"></div> */}
                            </>
                        )} 
                    <div
                        className="containerClientMenuSubcategoryAbcActionsDash"
                        style={{
                            height:  newStatus != -1 && closeMessage == false || boletar || loadingLiquidez ? `${
                                window.innerHeight > 760
                                    ? "calc(100vh - 41rem)"
                                    : window.innerHeight > 575
                                    ? "calc(100vh - 35rem)"
                                    : window.innerHeight > 480
                                    ? "calc(100vh - 31.3rem)"
                                    : "calc(100vh - 26rem)"
                            }`: newStatus == -1 ||!boletar ? `${
                                window.innerHeight > 760
                                    ? "calc(100vh - 35.5rem)"
                                    : window.innerHeight > 575
                                    ? "calc(100vh - 29.5rem)"
                                    : window.innerHeight > 480
                                    ? "calc(100vh - 25.8rem)"
                                    : "calc(100vh - 20.5rem)"
                            }` : '' ,
                        }}
                    >
                          
                        <div className="headerSubcategoryAbcActions" style={{}}>
                            <div className="headerSubcategoryTitleAbcActions" style={{paddingTop:'0'}}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        fontWeight: "bold",
                                        paddingLeft: "1rem",
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span style={{color: "#D4AC00",}}>
                                        Discurso
                                    </span> 
                                    {(client_speech != null && client_speech != "") || 
                                    (selectedProduct.current?.vendor_speech != null && selectedProduct.current?.vendor_speech != "") ||
                                    (selectedProduct.current?.general_speech != null && selectedProduct.current?.general_speech != "") ? (
                                        <div
                                            className="containerIconCopyContentAbcActions"
                                            style={{ marginRight: "1rem" }}
                                            onClick={(e) => {
                                                setCopyingDescription(true)
                                                navigator.clipboard.writeText(client_speech != null && client_speech != "" ? client_speech : (selectedProduct.current?.vendor_speech ? selectedProduct.current?.vendor_speech : selectedProduct.current?.general_speech) )
                                                setTimeout(disableCopying, 1000)
                                            }}
                                        >
                                            {!copyingDescription ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                            <div></div>
                            <span
                                style={{
                                    fontWeight: "bold",
                                    paddingLeft: "2rem",
                                    // marginTop: '1rem'
                                    color: "#BCBCBC",
                                }}
                            >
                                {/* {categoryInfos?.title} */}
                                {client_speech != null && client_speech != "" ? categoryInfos?.title : "Discurso comercial do produto"}
                            </span>
                        </div>
                        <div
                            className="containerSubcategoryDescriptionAbcActionsDash"
                            style={{
                                display: "grid",
                                // gridTemplateColumns: "auto 2.5rem",
                                color: "black",
                            }}
                        >
                            <pre className="textAreaSpeechAbcDash">{
                                (client_speech != null && client_speech != "") ? client_speech : (selectedProduct.current?.vendor_speech ? selectedProduct.current?.vendor_speech : selectedProduct.current?.general_speech)
                            }</pre>
                            
                            {/* <p className="descriptionTextAbc">{client_speech}</p> */}
                        </div>
                        <div className="linksBoxAbc">
                            {categoryInfos?.link ? (
                                categoryInfos?.link.split(" ").map((l, index) => {
                                    return (
                                        <>
                                            <div key={index} className="containerClientMenuSubcategoryAbcActionsDash">
                                                {l}
                                            </div>
                                            <div
                                                className="containerIconCopyContentAbcActions"
                                                onClick={async () => {
                                                    let aux = copyingLink.map((v, i, a) => (i == index ? true : v))
                                                    await setCopyingLink(aux)
                                                    navigator.clipboard.writeText(l)
                                                    setTimeout(disableCopying, 1000)
                                                }}
                                            >
                                                <div className="linkMenuModalBoxAbc">
                                                    {!copyingLink[index] ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" />}
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            ) : (
                                <div className="headerSubcategoryTitleAbcActions" style={{paddingTop:'0'}}>Link Indisponível</div>
                            )}
                        </div>
                                                 
                    </div>
                </div>
                {/* Botões só aparacem se o produto nao for re RV */}
                

                {/* timeline do cliente */}
                <div
                // style={{height: `${window.innerHeight > 760 ? 'calc(100vh - 10rem)' : window.innerHeight > 530 ? 'calc(100vh - 4rem)' : 'calc(100vh - 4rem)'}`}}
                >
                    {activeRefusalReasonBox ? (
                        <div className={"refusalReasonBox"} style={{top:'28rem', left: '34rem'}}>
                            <>
                                <div className="refuseReasonHeaderAbc">
                                    <div></div>
                                    <div className="refuseReasontitleAbc">Motivo da recusa</div>
                                    <button onClick={() => setActiveRefusalReasonBox(false)} className="modalCloseButton">
                                        X
                                    </button>
                                </div>
                                <FormGroup className="reasonsRefusalList">
                                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                        {refusalReasons.map((e, i) => {
                                            return (
                                                <FormControlLabel
                                                    value={e.id}
                                                    control={<Radio />}
                                                    label={e.label}
                                                    onClick={(e) => {
                                                        setSelectedReason(e.target.value)
                                                        const refusalReasonAux = refusalReasons.filter((r) => r.id == e.target.value)
                                                        setComentTimeline(refusalReasonAux[0]?.comment)
                                                    }}
                                                />
                                            )
                                        })}
                                    </RadioGroup>
                                </FormGroup>
                                <button
                                    className="buttomGray360"
                                    onClick={() => {
                                        setActiveRefusalReasonBox(false)
                                        setNewStatus(6)
                                    }}
                                >
                                    Ok
                                </button>
                            </>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        className="containerTimelineAbcDash"
                        style={{
                            height: `${window.innerHeight > 760 ? "calc(100vh - 12rem)" : window.innerHeight > 575 ? "calc(100vh - 6rem)" : window.innerHeight > 480 ? "calc(100vh - 3rem)" : "110vh"}`,
                        }}
                    >
                        <>
                            <div className="timelineTitleAbcAbrirDash">
                                <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                    <div style={{ marginTop: "0.2rem", color: "rgb(212, 172, 0)", fontWeight: "bold", fontSize: "1.2rem" }}>Comentários</div>
                                    <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto", fontSize: "1rem", alignItems: "center" }}
                                            className={filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Apenas da Campanha"
                                        >
                                            Apenas da Campanha
                                        </div>
                                        <div
                                            style={{ display: "grid", gridTemplateColumns: "auto auto", marginLeft: "auto", fontSize: "1rem", alignItems: "center" }}
                                            className={!filterProduct.current ? classesContactType.timelineFilterTypeProduct : classesContactType.timelineFilterTypeProductInactive}
                                            onClick={() => {
                                                filterProduct.current = !filterProduct.current
                                                filterTimeline()
                                            }}
                                            title="Geral"
                                        >
                                            Geral
                                        </div>
                                    </div>
                                </div>
                                <div className="timelineFiltersDash">
                                    Filtrar:
                                    <div
                                        className={filters.indexOf(1) >= 0 ? classesContactType.timelineFilterType1Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(1) >= 0) {
                                                filters.splice(filters.indexOf(1), 1)
                                            } else {
                                                filters.push(1)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(2) >= 0 ? classesContactType.timelineFilterType2Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(2) >= 0) {
                                                filters.splice(filters.indexOf(2), 1)
                                            } else {
                                                filters.push(2)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(3) >= 0 ? classesContactType.timelineFilterType3Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(3) >= 0) {
                                                filters.splice(filters.indexOf(3), 1)
                                            } else {
                                                filters.push(3)
                                            }
                                            filterTimeline()
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(4) >= 0 ? classesContactType.timelineFilterType4Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(4) >= 0) {
                                                filters.splice(filters.indexOf(4), 1)
                                            } else {
                                                filters.push(4)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(5) >= 0 ? classesContactType.timelineFilterType5Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(5) >= 0) {
                                                filters.splice(filters.indexOf(5), 1)
                                            } else {
                                                filters.push(5)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Apenas Comentário"
                                    >
                                        <BsChatDots style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(0) >= 0 ? classesContactType.timelineFilterType0Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(0) >= 0) {
                                                filters.splice(filters.indexOf(0), 1)
                                            } else {
                                                filters.push(0)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Sistema"
                                    >
                                        <BsCpu style={{ margin: "auto" }} size={18} />
                                    </div>
                                    <div
                                        className={filters.indexOf(6) >= 0 ? classesContactType.timelineFilterType6Active : classesContactType.timelineFilterTypeInactive}
                                        onClick={() => {
                                            if (filters.indexOf(6) >= 0) {
                                                filters.splice(filters.indexOf(6), 1)
                                            } else {
                                                filters.push(6)
                                            }
                                            filterTimeline()
                                        }}
                                        title="Formulário Ativação"
                                    >
                                        <BsCardList style={{ margin: "auto" }} size={18} />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="timelineCommentBoxAbcDash"
                                id="timelineCommentBoxAbc"
                                onScroll={(e) => {
                                    if (e.target.scrollTop < 10) updateItemsList()
                                }}
                            >
                                {loadingTimeline ? (
                                    <ArrowLoader />
                                ) : items.length > 0 ? (
                                    items.map((item) => {
                                        return item.user.id == user.external_id ? (
                                            <div className="timelineComentBoxUserComentRight">
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                            : "timelineComentBoxUserComentHeaderRight commentMessageDash"
                                                    }
                                                >
                                                    <div className="timelineComentBoxUserComentIconRight">
                                                        {item.type == 1 ? (
                                                            <BsWhatsapp title="Contato por WhatsApp" />
                                                        ) : item.type == 2 ? (
                                                            <BsTelephone title="Contato por ligação" />
                                                        ) : item.type == 3 ? (
                                                            <AiOutlineMail title="Contato por e-mail" />
                                                        ) : item.type == 4 ? (
                                                            <BsPeople title="Contato por reunião" />
                                                        ) : item.type == 5 ? (
                                                            <BsChatDots title="Mensagem sem classificação" />
                                                        ) : item.type == 0 ? (
                                                            <BsCpu title="Mensagem do sistema" />
                                                        ) : (
                                                            <BsPersonPlus title="Indicação de cliente" />
                                                        )}
                                                    </div>
                                                    <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                            : "timelineComentBoxUserComentMessageRight commentMessageDash"
                                                    }
                                                >
                                                    {item.text}
                                                    <>
                                                        {item?.informations?.map((line) => {
                                                            return (
                                                                <div>
                                                                    {line.key} <b>: </b> {line.value}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentDateRight commentMessageDash"
                                                            : "timelineComentBoxUserComentDateRight commentMessageDash"
                                                    }
                                                >
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        ) : item.type == "6" ? (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div className={"timelineComentBoxUserComentHeaderLeft formHeaderColor"}>
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        <BsCardList title="Formulário respondido antes da ativação" />
                                                    </div>
                                                </div>
                                                <div className={"timelineComentBoxUserComentMessageLeft formMessageColor"}>
                                                    <button className="buttonShowFinishedForm" onClick={() => {}}>
                                                        Formulário de primeira reunião
                                                        <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                    </button>
                                                </div>
                                                <div className={"timelineComentBoxUserComentDateLeft formDateColor"}>
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="timelineComentBoxUserComentLeft">
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentHeaderLeft commentMessageDash"
                                                    }
                                                >
                                                    <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                    <div className="timelineComentBoxUserComentIconLeft">
                                                        {item.type == 1 ? (
                                                            <BsWhatsapp title="Contato por WhatsApp" />
                                                        ) : item.type == 2 ? (
                                                            <BsTelephone title="Contato por ligação" />
                                                        ) : item.type == 3 ? (
                                                            <AiOutlineMail title="Contato por e-mail" />
                                                        ) : item.type == 4 ? (
                                                            <BsPeople title="Contato por reunião" />
                                                        ) : item.type == 5 ? (
                                                            <BsChatDots title="Mensagem sem classificação" />
                                                        ) : item.type == 0 ? (
                                                            <BsCpu title="Mensagem do sistema" />
                                                        ) : (
                                                            <BsPersonPlus title="Indicação de cliente" />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentMessageLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.text}
                                                    <>
                                                        {item?.informations?.map((line) => {
                                                            return (
                                                                <div>
                                                                    {line.key} <b>: </b> {line.value}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                </div>
                                                <div
                                                    className={
                                                        item.type == 1
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 2
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 3
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 4
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 5
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : item.type == 0
                                                            ? "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                            : "timelineComentBoxUserComentDateLeft commentMessageDash"
                                                    }
                                                >
                                                    {item.created_at.split("T")[0].split("-")[2] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[1] +
                                                        "/" +
                                                        item.created_at.split("T")[0].split("-")[0] +
                                                        " - " +
                                                        item.created_at.split("T")[1].split(".")[0]}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="emptyTimelineMessage">Não há mensagens...</div>
                                )}
                            </div>
                            <div className="timelineNewCommentBox">
                                <div className="timelineNewCommentContactType">
                                    <div className="timelineNewCommentContactTypeTitle">Tipo de contato</div>
                                    <div
                                        className={contactType == 1 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(1)
                                            if (contactType == 1) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Whatsapp"
                                    >
                                        <BsWhatsapp style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 2 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(2)
                                            if (contactType == 2) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Ligação"
                                    >
                                        <BsTelephone style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 3 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(3)
                                            if (contactType == 3) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="E-mail"
                                    >
                                        <AiOutlineMail style={{ margin: "auto" }} size={24} />
                                    </div>
                                    <div
                                        className={contactType == 4 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                        onClick={() => {
                                            setContactType(4)
                                            if (contactType == 4) {
                                                setContactType(5)
                                            }
                                        }}
                                        title="Reunião"
                                    >
                                        <BsPeople style={{ margin: "auto" }} size={24} />
                                    </div>
                                </div>
                                <div className="timelineNewCommentWriteSendDash">
                                    <textarea
                                        className={classesContactType.timelineNewComentWriteArea}
                                        value={comentTimeline}
                                        label="Novo comentário"
                                        onChange={(e) => {
                                            setComentTimeline(e.target.value)
                                        }}
                                    ></textarea>
                                    <button
                                        className={
                                            comentTimeline !== "" && comentTimeline !== null ? classesContactType.timelineNewCommentSendActive : classesContactType.timelineNewCommentSendInative
                                        }
                                        onClick={() => {
                                            if (comentTimeline !== "" && comentTimeline !== null) {
                                                handleSubmitComment()
                                            }
                                        }}
                                    >
                                        <BsSend size={26} />
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}
