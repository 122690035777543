/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Tech RV
 */

import React, { useState, useEffect, useRef } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "../../../../contexts/useAuth";
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../styles/import.css';
/*Utils*/
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import XLSX from 'xlsx';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
/*Services*/
import { api } from '../../../../services/api';
import { validyFiles } from '../../../../utils/validyTypesOfFiles';
import { TextField, TextareaAutosize } from '@material-ui/core'
import { Autocomplete, Select } from '@mui/material';
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency';
import { getMyClients } from '../../../../services/asyncFunctions'

const useStyles = makeStyles((theme) => ({
root: {
    "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
        background: 'red'
    },
    fontFamily: 'Roboto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '400'
},

stepperItems: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
},

'& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
backButton: {
    marginRight: theme.spacing(1),
},
instructions: {
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto',
    fontSize: '0.9rem !important'
},
}));

export function AddClient(props) {
    console.log(props)
    const { user, tokenKey } = useAuth();
    const { show, setShow, edited, setEdited, type, setType } = useModal();
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listClients, setListClients] = useState([])
    const [successEdit, setSuccessEdit] = useState(false);
    const [showConfirmButton, setShowConfirmButton] = useState(false);

    async function getMyClientsInactive() {
        try {
            const notClients = await api.get(`/api/v1/wisir2/${props.value}/notClients`, {
              headers: {
                authorization: `Bearer ${tokenKey}`,
              },
            });
            console.log(notClients)
            setListClients(notClients.data?.data)
          } catch (error) {
            setLoading(false);
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                message:
                    error?.response?.data?.message == undefined
                    ? "Erro interno ao carregar as abas"
                    : error?.response?.data?.message,
                },
            });
            setShow(true);
        }
    }

    function clear() {
        setSuccessEdit(false);
        setSelectedClient(null);
        setShowConfirmButton(false);
        setShow(false);
    }

    async function handleSubmit() {
        // console.log(selectedClient.external_id)
        // console.log(user.external_id)

        if (selectedClient) {
            setLoading(true);
            try {
                const clientUpdate = await api.post(
                    `/api/v1/wisir2/${props.value}/add`, {
                        client_id: selectedClient.client_id,
                        value: selectedValue
                    },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`,
                        },
                    }
                );
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: { message: `${clientUpdate.data.message}` + " - " + ` ${titleize(selectedClient.client)}` },
                });
                props.reload()
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        getMyClientsInactive()
    }, [])

    return (
        <div className="clientSetToBaseContainer">
            {loading ? (
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
            ) : successEdit ? (
                <div className="clientDetailsSuccessEdit">
                    <a>Sucesso ao Trazer Cliente</a>
                    <button
                        onClick={() => {
                            setSuccessEdit(false);
                            setType({ name: "successUpdateClient" });
                            setShow(false);
                        }}
                    >
                        Ok
                    </button>
                </div>
            ) : (
                <>
                    <div className="clientsDetailsHeader">
                        <div>
                            <p>Inserir cliente na estratégia</p>
                        </div>
                        <button onClick={() => clear()}>x</button>
                    </div>

                    <span className="subTitleSetToBase">Cliente:</span>
                    <div className="clientDetailsBox2">
                        <div className="titleSelectClient">
                            <p className="titleSetToBase">Selecione um cliente: </p>
                        </div>

                        <Autocomplete
                            options={listClients}
                            variant="outlined"
                            onFocus={(e) => e.target.click()}
                            onChange={(event, newValue) => {
                                setSelectedClient(newValue ? newValue : null);
                            }}
                            className={"selectClientSetToBase"}
                            getOptionLabel={(option) => option.client_id + " - " + titleize(option.client)}
                            id="client"
                            renderInput={(params) => (
                                <TextField
                                    label="Selecione:"
                                    size="small"
                                    required={false}
                                    placeholder="Selecione:"
                                    {...params}
                                    onChange={(e) => {
                                        // handleChange(e)
                                    }}
                                />
                            )}
                        />

                        <div className="titleSelectClient">
                            <p className="titleSetToBase">Selecione um financeiro: </p>
                        </div>

                        <CurrencyTextField
                            variant="standard"
                            className={"selectClientSetToBase"}
                            value={selectedValue}
                            currencySymbol="R$"
                            outputFormat="number"
                            decimalCharacter=","
                            // id={`value${rows}`}
                            digitGroupSeparator="."
                            onChange={(event, value) => {
                                setSelectedValue(value);
                            }}
                        />
                    </div>
                    {selectedClient !== null ? (
                        <>
                            {showConfirmButton ? (
                                <>
                                    <span className="itemButton">
                                        <button className="buttonSetToBaseConfirm" onClick={() => handleSubmit()}>
                                            Confirmar
                                        </button>
                                        <button className="buttonSetToBaseConfirm" onClick={() => setShowConfirmButton(false)}>
                                            Cancelar
                                        </button>
                                    </span>
                                </>
                            ) : (
                                <span className="itemButton">
                                    <button className="buttonSetToBase" onClick={() => setShowConfirmButton(true)}>
                                        Inserir
                                    </button>
                                </span>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    );
}