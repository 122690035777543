import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton, TableCell } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/cubastar.funds.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";


export function CubastarRentabilityTable(data) {
    const [loading, setLoading] = useState(false);

    const tableDataRiskReturn = data?.data?.risk[0];
    const tableDataSupport = data?.data?.data;
    let dataMainTable = [];
    let dataSecondaryTable = [];
    let dataSupportTable = [];

    if (tableDataRiskReturn != null) {
    dataMainTable = [
        ["1D", tableDataRiskReturn.carteira_1d, tableDataRiskReturn.cdi_1d, ((tableDataRiskReturn.carteira_1d - tableDataRiskReturn.cdi_1d))],
        ["1M", tableDataRiskReturn.carteira_1m, tableDataRiskReturn.cdi_1m, ((tableDataRiskReturn.carteira_1m - tableDataRiskReturn.cdi_1m))],
        ["6M", tableDataRiskReturn.carteira_6m, tableDataRiskReturn.cdi_6m, ((tableDataRiskReturn.carteira_6m - tableDataRiskReturn.cdi_6m))],
        ["12M", tableDataRiskReturn.carteira_12m, tableDataRiskReturn.cdi_12m, ((tableDataRiskReturn.carteira_12m - tableDataRiskReturn.cdi_12m))],
        ["24M", tableDataRiskReturn.carteira_24m, tableDataRiskReturn.cdi_24m, ((tableDataRiskReturn.carteira_24m - tableDataRiskReturn.cdi_24m))],
        ["36M", tableDataRiskReturn.carteira_36m, tableDataRiskReturn.cdi_36m, ((tableDataRiskReturn.carteira_36m - tableDataRiskReturn.cdi_36m))],
        ["YTD", tableDataRiskReturn.ytd_carteira, tableDataRiskReturn.ytd_cdi, ((tableDataRiskReturn.ytd_carteira - tableDataRiskReturn.ytd_cdi))],
        ["MTD", tableDataRiskReturn.mtd_carteira, tableDataRiskReturn.mtd_cdi, ((tableDataRiskReturn.mtd_carteira - tableDataRiskReturn.mtd_cdi))],
        ["Desde o Início", tableDataRiskReturn.mes_atual_carteira, tableDataRiskReturn.mes_atual_cdi, ((tableDataRiskReturn.mes_atual_carteira - tableDataRiskReturn.mes_atual_cdi))],
        ["Média Anual", tableDataRiskReturn.med_carteira, tableDataRiskReturn.med_cdi, ((tableDataRiskReturn.med_carteira - tableDataRiskReturn.med_cdi))],
    ]}

    if (tableDataRiskReturn != null) {
        dataSecondaryTable = [
        ["Volatilidade (ano)", parseFloat(tableDataRiskReturn.vol_12m).toFixed(2) + '%', parseFloat(tableDataRiskReturn.vol_24m).toFixed(2) + '%'],
        ["Índice de Sharpe (ano)", parseFloat(tableDataRiskReturn.sharpe_12m).toFixed(2), parseFloat(tableDataRiskReturn.sharpe_24m).toFixed(2)],
        ["Retorno Máximo (mês)", parseFloat(tableDataRiskReturn.rmax_12m).toFixed(2) + '%', parseFloat(tableDataRiskReturn.rmax_24m).toFixed(2) + '%'],
        ["Retorno Mínimo (mês)", parseFloat(tableDataRiskReturn.rmin_12m).toFixed(2) + '%', parseFloat(tableDataRiskReturn.rmin_24m).toFixed(2) + '%']
    ]}

    if (tableDataSupport != null) {
        dataSupportTable = [
        ["DrawDown", (tableDataSupport.drawdown).toFixed(2) + ('%')],
        ["ROA", (tableDataSupport.roa).toFixed(2) + ('%')],
        ["Volatilidade Esperada",( tableDataSupport.volatility).toFixed(2) + ('%')],
    ]}

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Categoria",
            "Produto",
            "Alocação"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "nomeProduto",
            "secao",
            "alocacao"
        ];

        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    const columnsMainTable = [
        {
            // label: "Período",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            label: "Carteira",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 1rem 0px 1rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? value.toFixed(2) + '%' : "-"
                    }</span>,
                customHeadRender: ({ index, label, ...column }) => <TableCell style={{ padding: "0px 1rem 0px 1rem", fontWeight: "bold"}}>{label}</TableCell>
            },
        },
        {
            label: "CDI Acumulado",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 1rem 0px 1rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? value.toFixed(2) + '%' : "-"
                    }</span>,
                customHeadRender: ({ index, label, ...column }) => <TableCell style={{ padding: "0px 1rem 0px 1rem", fontWeight: "bold"}}>{label}</TableCell>
            },
        },
        {
            label: "Δ CDI (p.p.)",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 1rem 0px 1rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? value.toFixed(2) + '%' : "-"
                    }</span>,
                customHeadRender: ({ index, label, ...column }) => <TableCell style={{ padding: "0px 1rem 0px 1rem", fontWeight: "bold"}}>{label}</TableCell>
            },
        }
    ];

    const columnsSecondaryTable = [
        {
            // label: "Período",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            label: "12M",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => (

                    <span>
                        { value !== null ? value : "-"}
                    </span>
                ),
                
            },
        },
        {
            label: "24M",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => (
                    <span>
                        { value !== null ? value : "-"}
                    </span>
                ),
                
            },
        }
    ];

    const columnsSupportTable = [
        {
            label: "Indicador",
            options: {

                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customHeadRender: ({ index, label, ...column }) => <TableCell style={{ padding: "0px 0.6rem 0px 0.6rem", fontWeight: "bold", textAlign:"center" }}>{label}</TableCell>
            },
        },
        {
            label: "%",
            options: {
                filter: false,
                sort: false,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customBodyRender: (value) => <span>{
                //     value !== null ? /* parseFloat(value).toFixed(2) */ value : "-"
                //     }</span>
                customHeadRender: ({ index, label, ...column }) => <TableCell style={{ padding: "0px 0.6rem 0px 0.6rem", fontWeight: "bold", textAlign: "center"}}>{label}</TableCell>
            },
        }
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const optionsMainTable = {
        filterType: "multiselect",
        search: false,
        filter: false,
        toolbar: false,
        download: false,
        print: false,
        pagination: false,
        responsive: "vertical",
        // tableBodyHeight: '20rem',
        draggableColumns: {
            enabled: false,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Informações ainda a serem preenchidas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            // filter: {
            //     all: "Todos",
            //     title: "Filtros",
            //     reset: "Limpar",
            // },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            // assetnotExecutedExport();
            return false;
        },
        sortOrder: {
            name: 'alocacao',
            direction: 'desc'
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        // rowsPerPage: rowsPerPage,
        // rowsPerPageOptions: [30, 50, 100],
    };

    const optionsSecondaryTable = {
        filterType: "multiselect",
        search: false,
        filter: false,
        toolbar: false,
        download: false,
        print: false,
        sort: false,
        pagination: false,
        responsive: "vertical",
        // tableBodyHeight: '10rem',
        draggableColumns: {
            enabled: false,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Informações ainda a serem preenchidas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            // filter: {
            //     all: "Todos",
            //     title: "Filtros",
            //     reset: "Limpar",
            // },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            // assetnotExecutedExport();
            return false;
        },
        sortOrder: {
            name: 'alocacao',
            direction: 'desc'
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        // rowsPerPage: rowsPerPage,
        // rowsPerPageOptions: [30, 50, 100],
    };

    const optionsSupportTable = {
        filterType: "multiselect",
        search: false,
        filter: false,
        toolbar: false,
        download: false,
        print: false,
        pagination: false,
        responsive: "vertical",
        // tableBodyHeight: '5rem',
        draggableColumns: {
            enabled: false,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Informações ainda a serem preenchidas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            // filter: {
            //     all: "Todos",
            //     title: "Filtros",
            //     reset: "Limpar",
            // },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "operacoes_nao_executadas.csv",
            separator: ";",
        },
        onDownload: () => {
            // assetnotExecutedExport();
            return false;
        },
        sortOrder: {
            name: 'alocacao',
            direction: 'desc'
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
        // rowsPerPage: rowsPerPage,
        // rowsPerPageOptions: [30, 50, 100],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center'
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                    padding: "0px 0.6rem 0px 0.6rem"
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0',
                    height: 'inherit',
                    overflow: 'auto'
        }
      },
    }
    }
    })

    const getMuiSecondaryTableTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center'
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0',
                    height: 'inherit',
                    overflow: 'auto'
        }
      },
    }
    }
    })
    
    const getMuiThemeSupportTable = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                    textAlign: 'center',
                    }
                },
            },
        MUIDataTableHeadCell: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
                
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
                root: {
                    '&:nth-child(odd)': { 
                        backgroundColor: '#dbdbdb'
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0',
                    height: 'inherit',
                    overflow: 'auto',
                }
            },
        }
    }
    })

    return (
        <div className='cubastar-rentability-wrapper'>
            <div className='cubastar-table' style={{marginBottom:'0.5rem', display: dataMainTable.length == 0 ? 'none' : "block"}}>
                <div className='cubastar-table-label'>Retorno</div>
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={dataMainTable}
                        columns={columnsMainTable}
                        options={optionsMainTable}
                        ></MUIDataTable>
                </ThemeProvider>
            </div>
            {
                <div>
                    <div style={{}} className='cubastar-table-label'>Perfil de Risco</div>
                    <div className='cubastar-table' style={{marginBottom:'1rem', display: dataSecondaryTable.length == 0 ? 'none' : "block" }}  >
                        <ThemeProvider theme={getMuiSecondaryTableTheme()}>
                            <MUIDataTable
                                data={dataSecondaryTable}
                                columns={columnsSecondaryTable}
                                options={optionsSecondaryTable}
                                ></MUIDataTable>
                        </ThemeProvider>
                    </div>
                    <div className='cubastar-table'>
                        <ThemeProvider theme={getMuiThemeSupportTable()}>
                            <MUIDataTable
                                data={dataSupportTable}
                                columns={columnsSupportTable}
                                options={optionsSupportTable}
                                ></MUIDataTable>
                        </ThemeProvider>
                    </div>
                </div>}
        </div>
    );
}

