/*Componente Tabela de books
/*Descrição: Exibe os Books cadastrados em sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from 'react';
import { FiTrash2, FiSlash, FiDownload, FiEdit, FiCheck, FiX, FiArrowDown,FiCopy } from 'react-icons/fi';
/*Styles*/
import './styles/tableBooksRegistered.css';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
/*Services*/
import { api } from '../../../../services/api';
import { getBooks } from '../../../../services/asyncFunctions';
/*Util Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils*/
import { closeMenu } from '../../../../utils/closeMenu';

export function TableBooksRegistered() {

    const { tokenKey } = useAuth();
    const { setShow, setType, edited, setEdited } = useModal();
    const [bookList, setBooklist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inEditOrder, setInEditOrder] = useState(false)
    const [filtered, setFiltered] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('Código');
    const [inEditThisId, setInEditThisId] = useState(0)
    const [selectedNewPosition, setSelectedNewPostion] = useState(0)

    useEffect(async () => {

        const booksGet = await getBooks(1, tokenKey)
        if (booksGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: booksGet?.error
                }
            })
            setShow(true);
        } else {
            setBooklist(booksGet);
        }

        if (edited || bookList?.length < 1) {
            setLoading(true);
            const newBooksGet = await getBooks(1, tokenKey)
            if (newBooksGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: newBooksGet?.error
                    }
                })
                setShow(true);
            } else {
                setBooklist(newBooksGet);
            }
            setEdited(false);
        }

        setLoading(false);
    }, [edited])

    async function handleDelete(bookData) {

        setLoading(true);
        try {
            await api.delete(`/api/v1/books/${bookData?.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: "Book excluído com sucesso!" } })
            setShow(true);
            setEdited(true);
        } catch (err) {
            console.log(err.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setShow(true);
            setLoading(false)
        }
    }

    async function booksExport() {
        setLoading(true);
        try {
            const exportAssetsResult = await api.get(`/api/v1/books/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })
            console.log(exportAssetsResult)

            const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_books.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    async function handleDisable(bookData) {
        setLoading(true);
        try {

            await api.put(`/api/v1/books/${bookData}/status`, {}, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: "Book Desativado com sucesso!" } })
            setShow(true);
            setEdited(true);

        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setShow(true);
        }
    }

    async function handleChangeOrder(bookId) {

        setLoading(true)
        try {
            const changeOrder = await api.put(`/api/v1/books/${bookId}/position`, {
                "position": parseInt(selectedNewPosition)
            },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })    
        setLoading(false)
        setInEditOrder(false)
        setType({name: 'alertMsg', type: 'information', value: {message: 'Posição atualizada com sucesso!'}})
        setShow(true)
        setEdited(true)
        } catch (error) {

            console.log(error.response)
            setInEditOrder(false)
            setLoading(false)


        }
    }

    async function getBookLayout(bookData) {
        setLoading(true);
        try {
            const bookLayout = await api.get(`/api/v1/books/${bookData.id}/getWorksheet`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const url = URL.createObjectURL(new Blob([bookLayout.data]))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${bookData?.name}_layout.xlsx`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (err) {
            setType({ name: 'alertMsg', type: 'information', value: { message: err?.response?.data?.message } })
            setLoading(false);
        }
    }

    function listFilter() {

        if (filtered == null || filtered == null || filtered == undefined || filtered === '') {
            return bookList;
        }

        return bookList.filter((bookTofilter) => {
            if (selectedFilter === 'Código' && bookTofilter?.id?.toString()?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return bookTofilter
            }

            if (selectedFilter === 'Nome' && bookTofilter?.name?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return bookTofilter
            }

            if (selectedFilter === 'Criado por' && bookTofilter?.users_external_id?.toLowerCase()?.indexOf(filtered?.toLowerCase()) > -1) {
                return bookTofilter
            }

        })
    }
    return (
        <div className='booksConfigurationTableBooksContainer'>
            <table id='tableBooksRegistered'>
                <thead>
                    <tr>
                        <th colSpan='8'>
                            <div>
                                <a>Lista de Books registrados</a>
                                <div className='substitutoLabelDisplay'>
                                    <select className='selectFilterTable' onChange={(e) => setSelectedFilter(e.target.value)}>
                                        <option selected>Código</option>
                                        <option>Nome</option>
                                        <option>Criado por</option>
                                    </select>
                                    <input
                                        className='tableAllInputThead'
                                        placeholder='Filtrar'
                                        onChange={(e) => setFiltered(e.target.value)}
                                    ></input>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            const output = [];
                                            const titles = ['Código', 'Nome', 'Criado por', 'Criado em', 'Status Book']
                                            output.push(titles.join('\t'))
                                            const keys = ['id', 'name', 'users_external_id', 'created_at', 'active'];
                                            bookList.forEach((x) => {
                                                const tmp = keys.map((y) => {return y==='active'?  x[y] === true ? 'Ativo' : 'Inativo' 
                                                : y==='created_at' ? x[y].split("T")[0] 
                                                : x[y]});
                                                output.push(tmp.join('\t'))
                                            })
                                            const table = output.join('\n')
                                            navigator.clipboard.writeText(table)
                                            alert('Conteúdo da tabela copiado para a área de transferência')
                                        }}
                                    ><FiCopy />Copiar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            booksExport();
                                    }}><FiArrowDown />Exportar</button>
                                    <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            setType({ name: 'showBooksRegister' })
                                            setShow(true);
                                            closeMenu()
                                        }}>+ Novo</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Ordem</th>
                        <th>Criado por</th>
                        <th>Criado em</th>
                        <th>Layout Importação</th>
                        <th>Excluir</th>
                        <th>Desativar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            (
                                <td colSpan='8' className='tableBookRegisteredLoader'>
                                    <ArrowLoader />
                                </td>
                            ) :
                            bookList?.length > 0 ?
                                (
                                    listFilter().map((booksRegistered) => {
                                        const date_created = new Date(Date.parse(booksRegistered?.created_at)).toLocaleString('pt-BR', { timeZone: 'UTC' });
                                        return (
                                            <tr>
                                                <td onClick={() => { closeMenu(); setType({ name: 'bookEditAction', value: booksRegistered }); setShow(true) }}>{booksRegistered?.id}<a id='bookLegend'>Editar Book</a></td>
                                                <td onClick={() => { closeMenu(); setType({ name: 'bookEditAction', value: booksRegistered }); setShow(true) }}>{booksRegistered?.name}<a id='bookLegend'>Editar Book</a></td>
                                                <td style={{ width: '12rem' }}>
                                                    <div className='tableBooksRegisteredOrderBookDiv'>
                                                        {

                                                            inEditOrder && booksRegistered.id === inEditThisId ?
                                                                <>
                                                                    <input id='booksRegisteredAlterOrder' type='text' onChange={(e)=> setSelectedNewPostion(e.target.value)}></input>
                                                                    <FiCheck size='18' onClick={() => handleChangeOrder(booksRegistered.id)} />
                                                                    <FiX size='18' onClick={() => { setInEditOrder(false); setInEditThisId(0) }} />
                                                                </>
                                                                :

                                                                <>
                                                                    <a>{booksRegistered.position}</a>
                                                                    <FiEdit size='18' onClick={() => { setInEditOrder(true); setInEditThisId(booksRegistered.id) }} />
                                                                </>
                                                        }

                                                    </div>

                                                </td>
                                                <td onClick={() => { closeMenu(); setType({ name: 'bookEditAction', value: booksRegistered }); setShow(true) }}>{booksRegistered?.users_external_id}<a id='bookLegend'>Editar Book</a></td>
                                                <td onClick={() => { closeMenu(); setType({ name: 'bookEditAction', value: booksRegistered }); setShow(true) }}>{date_created}<a id='bookLegend'>Editar Book</a></td>
                                                <td onClick={() => { getBookLayout(booksRegistered) }}><FiDownload /></td>
                                                <td onClick={() => { handleDelete(booksRegistered) }}><FiTrash2 /></td>
                                                <td onClick={() => { handleDisable(booksRegistered?.id) }}><FiSlash />
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) :
                                (
                                    <td colSpan='9'>
                                        <div id='booksEmptyRow'>
                                            <a>-- Lista vazia --</a>
                                        </div>
                                    </td>
                                )
                    }
                </tbody>
            </table>
        </div>
    )
}