/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from "../../../contexts/useModal";
import { useAuth } from "../../../contexts/useAuth";
//services
import { api } from "../../../services/api";
import { getAllClients } from "../../../services/asyncFunctions";
import { getBrokersAdvisor } from "../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../components/loaders/arrowLoader";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent";
//utils
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/brokers.advisor.table.css";

export function BrokersAdvisors() {
    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user,tokenKey } = useAuth();
    const [brokersAdvisor, setBrokersAdvisor] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("@auth:token");
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const [clientsRV ,setclientsRV] = useState(0)
    const [clientsExRV ,setclientsExRV] = useState(0)
    const [rateRV ,setrateRV] = useState(0)

    useEffect(() => {
        async function searchAdvisorBrokers() {
            try {
                const brokersAdvisorResult = await getBrokersAdvisor(tokenKey,false);
                setBrokersAdvisor(brokersAdvisorResult);
                // setTotalClients(brokersAdvisorResult?.data?.data?.length + 1);
                setLoading(false);
            } catch (error) {
                alert(error?.response?.data?.message);
            }
        }
        searchAdvisorBrokers();
    }, [show, edited]);
    
    useEffect(() => {
        // console.log(listProjected);
        var clientsRV = 0;
        var clientsExRV = 0;
        var rateRV = 0;
        brokersAdvisor.forEach((e) => {
            clientsRV += e.netStockAbove10k;
            clientsExRV += e.potencialClients;
        })

        // var avgRoa = revTbl * 100 / netRvTbl
        
        setclientsRV(clientsRV)
        setclientsExRV(clientsExRV)
        setrateRV((clientsRV*100/(clientsRV + clientsExRV)).toFixed(1))
    }, [brokersAdvisor]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Nome do Assessor",
            "Id do Assessor",
            "Nome Líder",
            "Id do Líder",
            "Filial",
            "Broker ",
            "Id Broker",
            "Segmento RV",
            "Clientes Mesa RV",
            "Clientes Mesa RV acima de 10k",
            "Clientes RV Fora Mesa acima de 10k",
            "Índice Penetração RV (acima de 10k)",
        ];
        output.push(titles.join("\t"));
        const keys = [
            "full_name",
            "advisor_id",
            'full_name_leader',
            'leader_id',
            "filial",
            "name_broker",
            "id_broker",
            "profile_broker",
            "stockClients",
            "netStockAbove10k",
            "potencialClients",
            "penetration",
        ];
        brokersAdvisor.forEach((x) => {
            const tmp = keys.map((y) => {return ( 
                // y === 'potencialClients' ? x[y]+x['stockClients'] :
                y === 'penetration' ?  x['potencialClients'] > 0 ? (100 * x['netStockAbove10k'] / (x['netStockAbove10k'] + x['potencialClients'])).toFixed(1) + '%' : '100.0%' :
                x[y] )});
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function advisorBrokersExport() {
        setLoading(true);
        try {
            const myExport = await getBrokersAdvisor(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "brokers_por_assessor.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "full_name",
            label: "Nome do Assessor",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "advisor_id",
            label: "Id do Assessor",
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: "leader_id",
            label: "Id Líder",
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: "full_name_leader",
            label: "Nome Líder",
            options: {
                filter: true,
                sort: true,
                display: true,
            },
        },
        {
            name: "filial",
            label: "Filial",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "name_broker",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "id_broker",
            label: "Id Broker",
            options: {
                filter: true,
                sort: true,
                display: false,
            },
        },
        {
            name: "profile_broker",
            label: "Segmento RV",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "netStockAbove10k",
            label: "CLIENTES MESA RV",
            options: {
                filter: false,
                sort: true,
                // customBodyRender: (value, tableMeta) => { 
                //     console.log(value, tableMeta.rowData[11]);
                //     return (tableMeta.rowData[12] > 0 ? <span>{value} ( { (100 * value / (value + tableMeta.rowData[12])).toFixed(1)}%)</span> : <span>{value} (100.0%)</span> )},
                    customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{(clientsRV)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "potencialClients",
            label: "CLIENTES RV FORA MESA",
            options: {
                filter: false,
                sort: true,
                // customBodyRender: (value, tableMeta) => { 
                //     return (<span>{parseInt(value) + parseInt( tableMeta.rowData[11])} </span>)},
                    customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{(clientsExRV)}</p>
                        </div>
                    </TableCell>
                ),
            }
        },
        {
            name: "penetration",
            label: "ÍNDICE PENETRAÇÃO MESA",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value,tableMeta) => {
                    return (tableMeta.rowData[9] > 0 ? <span>  { (100 * tableMeta.rowData[8] / (tableMeta.rowData[8] + tableMeta.rowData[9])).toFixed(1)}%</span> : <span> 100.0%</span> )
                },
                                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{(toPercent(rateRV))}</p>
                        </div>
                    </TableCell>
                ),
                },
            
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "72vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [50,200,1000],
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            advisorBrokersExport();
            return false;
        },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            if (user.type === 1){
                setType({ name: "advisorBrokersEdit", data: rowData });
                setShow(true);
            }
        },
        
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{marginTop: '3rem', marginLeft: '2rem', marginRight:'2rem'}}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={"Brokers por Assessor"} data={brokersAdvisor} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}
