//libraries
import React, { useState, useEffect } from "react";
import AntSwitch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
//services
import { api } from "../../../../services/api";
import { getBranches } from "../../../../services/asyncFunctions";
import { getBrokerBySegment } from "../../../../services/asyncFunctions";
import { getBrokerProfiles } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/users.editBox.css";
import { styleClientsInput } from "../../../../components/styles/autocompletField";

export function BrokersAdvisorEditBox(props) {
    // console.log(props)
    const advisorName = props.userData.data.data[0];
    const advisorId = props.userData.data.data[1];
    const brokerName = props.userData.data.data[5];
    const brokerId = props.userData.data.data[6];
    // const homeBrokerName = props.userData.data.data[5];
    // const homeBrokerId = props.userData.data.data[6];
    // const digitalBrokerName = props.userData.data.data[7];
    // const digitalBrokerId = props.userData.data.data[8];
    // const privateBrokerName = props.userData.data.data[9];
    // const privateBrokerId = props.userData.data.data[10];

    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const classes = styleClientsInput();
    const [brokersList, setBrokersList] = useState([]);

    // const [homeBrokersList, setHomeBrokersList] = useState([]);
    // const [digitalList, setDigitalList] = useState([]);
    // const [privateList, setPrivateList] = useState([]);

    const [selectedBroker, setSelectedBroker] = useState("");
    // const [selectedHomeBroker, setSelectedHomeBroker] = useState("");
    // const [selectedDigital, setSelectedDigital] = useState("");
    // const [selectedPrivate, setSelectedPrivate] = useState("");

    const [profilesList, setProfilesList] = useState([]);
    const [selectedUserFullName, setSelectedUserFullName] = useState("");
    const [selectedUserEmail, setSelectedUserEmail] = useState("");
    const [selectedBranch, setSelectedBranch] = useState("");
    const [selectedUserType, setSelectedUserType] = useState("");
    const [selectedProfileUser, setSelectedProfileUser] = useState("1");
    const [listTypes, setListTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [brokerEdited, setBrokerEdited] = useState(false);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey, setTokenKey } = useAuth();

    useEffect(() => {
        async function getData() {
            const brokersGet = await getBrokerBySegment(tokenKey);
            if (brokersGet?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: brokersGet?.error,
                    },
                });
                setShow(true);
            } else {
                // const homeBroker = brokersGet.filter((x) => x.profile_id === 1);
                // const digitalBroker = brokersGet.filter((x) => x.profile_id === 2);
                // const privateBroker = brokersGet.filter((x) => x.profile_id === 3);

                setBrokersList(brokersGet);
                // setHomeBrokersList(homeBroker);
                // setDigitalList(digitalBroker);
                // setPrivateList(privateBroker);
            }
        }
        // console.log(homeBrokerId !== null)

        setSelectedBroker(brokerId !== null ? brokerId + " - " + brokerName : "");
        // setSelectedHomeBroker(homeBrokerId !== null ? homeBrokerId + " - " + homeBrokerName : '');
        // setSelectedDigital(digitalBrokerId !== null ? digitalBrokerId + " - " + digitalBrokerName : '');
        // setSelectedPrivate(privateBrokerId !== null ? privateBrokerId + " - " + privateBrokerName : '');

        getData();
        setBrokerEdited(false);
        setLoading(false);
    }, [show]);

    

    async function handleSubmit() {
        setLoading(true);
        console.log('advisorId',advisorId)
        let errors = false;

        setLoading(true);
        try {

            // console.log(selectedHomeBroker)
            // console.log(selectedDigital)
            // console.log(selectedPrivate)
            
            
            
            const userChangeResult = await api.put(
                `/api/v1/users/brokersadvisorUpdate`,
                {
                    advisorId: advisorId,
                    brokerId: selectedBroker === "" || selectedBroker === null ? 'null' : selectedBroker.split(" ")[0]
                    // brokerHomeBroker: selectedHomeBroker === '' || selectedHomeBroker === null ? 'null' : selectedHomeBroker.split(" ")[0],
                    // brokerDigital: selectedDigital === '' || selectedDigital === null ? 'null' : selectedDigital.split(" ")[0],
                    // brokerPrivate: selectedPrivate === '' || selectedPrivate === null ? 'null' : selectedPrivate.split(" ")[0],
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setLoading(false);

            setType({ name: "alertMsg", type: "information", value: { message: "Sucesso ao alterar brokers do assessor" } });
            setEdited(!edited);
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data.message === undefined
                            ? "Erro ao realizar requisição"
                            : error?.response?.data.message,
                },
            });
            setShow(true);
        }
    }

    // console.log(selectedHomeBroker)
    // console.log(selectedDigital)
    return (
        <div
            className="userEditBox"
            style={{
                width: "60vw",
                height: "70vh",
            }}
        >
            {loading ? (
                <div className="userBoxArrow">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="headerBoxUser">
                        <div>
                            <h1>Assessor: </h1>
                            <h1>
                                {titleize(advisorName)} - {advisorId}
                            </h1>
                        </div>
                    </div>
                    <div className="formBoxUser ">
                        <div className="selectBrokers">
                            <br />
                            <Autocomplete
                                options={brokersList.map((x) => String(x.external_id) + " - " + x.full_name)}
                                value={selectedBroker}
                                onChange={(event, newValue) => {
                                    setSelectedBroker(newValue);
                                    setBrokerEdited(true);
                                }}
                                id="broker"
                                renderInput={(params) => (
                                    <TextField
                                        label="Broker"
                                        className={classes.autocompleteEditUser}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault();
                                        }}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <br />

                            {/* <Autocomplete
                                options={digitalList.map((x) => String(x.external_id) + " - " + x.full_name)}
                                value={selectedDigital}
                                onChange={(event, newValue) => {
                                    setSelectedDigital(newValue);
                                    setBrokerEdited(true);
                                }}
                                id="homeBroker"
                                renderInput={(params) => (
                                    <TextField
                                        label="Broker Digital"
                                        className={classes.autocompleteEditUser}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault();
                                        }}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <br />

                            <Autocomplete
                                options={privateList.map((x) => String(x.external_id) + " - " + x.full_name)}
                                value={selectedPrivate}
                                onChange={(event, newValue) => {
                                    setSelectedPrivate(newValue);
                                    setBrokerEdited(true);
                                }}
                                id="homeBroker"
                                renderInput={(params) => (
                                    <TextField
                                        label="Broker Private"
                                        className={classes.autocompleteEditUser}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault();
                                        }}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            /> */}
                        </div>
                        <div style={{ display: "flex" }}>
                            {brokerEdited && (
                                <button
                                    style={{ margin: "2rem" }}
                                    onClick={() => handleSubmit()}
                                    id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                                >
                                    Confirmar
                                </button>
                            )}
                            <button
                                style={{ margin: "2rem" }}
                                onClick={() => setShow(false)}
                                id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
