//libraries
import React, { useEffect, useState, useRef, useReducer } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
/*Styles*/
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md";
//services
import { api } from "../../../../services/api";
/*Components*/
import Modal from "../../../../components/modal/modal";
import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox";
/*Contexts*/
import { useModal } from "../../../../contexts/useModal";
import { LongShortTbl } from "./longShort.table"
import { LongShortResponseModal } from './longShort.responseModal';
import { LongShortCioModal } from './longShort.cioModal'
import { LongShortValidationModal } from './longShort.validationModal'
import { LongShortModificationsImport } from "../longShortOperationsListUpdate";
import { LongShortInfosModal } from "./longShortInfosModal";
import { LongShortHistoricoGeral } from "./longShort.historico";

import { ModalEditCosts } from './longShort.editCosts'

export function LongShortTbls() {
    const { type } = useModal();
  
    return (
      <>
      
        
        <Modal>
          {type.name === "alertMsg" ? (
            <ConfirmationBox msgData={type} />
          ) :
          type.name == 'showLongShortResponse' ?
          (
              <LongShortResponseModal prop={type}/>
          ) :
          type.name == 'longShortCio' ?
          (
              <LongShortCioModal prop={type}/>
          ) :
          type.name == 'showValidationModal' ? 
          (
            <LongShortValidationModal prop={type} />
          ):
          type.name === 'showImportModifications' ?
          (
            <LongShortModificationsImport prop={type}/>
          ) :
          type.name === 'showLModalEditCosts' ?
            <ModalEditCosts prop={type}/>
          :
          type.name === 'showLongShortInfos' ?
          (
            <LongShortInfosModal prop={type}/>
          ) :
          type.name === 'showHistoricoGeralLongShort' ?
          (
            <LongShortHistoricoGeral prop={type}/>
          ) :
          (
            <></>
          )}
        </Modal>
        <div style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
            <LongShortTbl prop={type} />
        </div>
      </>
    );
  }