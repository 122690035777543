import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/brokerPayroll.table.css";
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { api } from "../../../../services/api";
import { getBrokerPayroll } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
/*Utils*/
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

/*Styles*/
import "../styles/brokerPayroll.table.css";

export function BrokerPayrollTbl() {
    const [loading, setLoading] = useState(false);
    const [listPayroll, setListPayroll] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [comissao, setComissao] = useState(0.0);
    const [rtl, setRtl] = useState(0.0);
    const [rml, setRml] = useState(0.0);
    const [rbl, setRbl] = useState(0.0);

    async function getPayroll() {
        const listPayroll = await getBrokerPayroll(tokenKey);
        if (getBrokerPayroll?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getBrokerPayroll?.error,
                },
            });
            setShow(true);
        } else {
            setListPayroll(listPayroll);
            // setListPayroll(listPayroll.slice(0, 20));
            setLoading(false);
        }
    }

    async function sumColumns(displayData) {
        var comissaoAux = 0.0,
            rtlAux = 0.0,
            rmlAux = 0.0,
            rblAux = 0.0;
        displayData.forEach((item) => {
            comissaoAux += parseFloat(item.data[10].props.children.replace("R$", "").replace(".", "").replace(",", "."));
            rtlAux += parseFloat(item.data[14].props.children.replace("R$", "").replace(".", "").replace(",", "."));
            rmlAux += parseFloat(item.data[15].props.children.replace("R$", "").replace(".", "").replace(",", "."));
            rblAux += parseFloat(item.data[16].props.children.replace("R$", "").replace(".", "").replace(",", "."));
        });
        setComissao(comissaoAux);
        setRtl(rtlAux);
        setRml(rmlAux);
        setRbl(rblAux);
    }

    useEffect(() => {
        if (edited || listPayroll.length === 0) {
            setEdited(false);
            setLoading(true);
            getPayroll();
        }
    }, [edited]);

    async function payRollExport() {
        setLoading(true);
        try {
            const exportIndicationsExport = await api.get(`/api/v1/payroll/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
                responseType: "blob",
            });
            const responseURL = URL.createObjectURL(new Blob([exportIndicationsExport.data]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "extrato_mensal_previa.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Data",
            "Código Cliente",
            "Nome Assessor",
            "Código Broker",
            "Nome Broker",
            "Produto/Categoria",
            "Nível 1",
            "Nível 2",
            "Nível 3",
            "Produto Mesa RV",
            "Comissão (R$) Escritório",
            "Categoria Mesa RV",
            "Canal Mesa",
            "Alíquota IR",
            "Receita Total Líquido",
            "Repasse Mesa Líquido",
            "Repasse Broker Líquido",
        ];
        output.push(titles.join("\t"));
        const keys = [
            "payroll_date",
            "client_id",
            "advisor_name",
            "broker_id",
            "broker_name",
            "category",
            "level_1",
            "level_2",
            "level_3",
            "stock_product",
            "payout",
            "stock_category",
            "stock_access",
            "tax_rate",
            "liquid_income",
            "broker_funding",
            "broker_liquid",
        ];
        listPayroll.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "payout" ||
                    y === "tax_rate" ||
                    y === "liquid_income" ||
                    y === "broker_funding" ||
                    y === "broker_liquid"
                    ? String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    const columns = [
        {
            name: "payroll_date",
            label: "Data",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "client_id",
            label: "Código Cliente",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "advisor_name",
            label: "Nome Assessor",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "broker_id",
            label: "Código Broker",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "broker_name",
            label: "Nome Broker",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "category",
            label: "Produto/Categoria",
            options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "level_1",
            label: "Nível 1",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "level_2",
            label: "Nível 2",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "level_3",
            label: "Nível 3",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "stock_product",
            label: "Produto Mesa RV",
            options: {
                filter: false,
                sort: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "payout",
            label: "Comissão (R$) Escritório",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMui">{toBRL(comissao)}</TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "stock_category",
            label: "Categoria Mesa RV",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "stock_access",
            label: "Canal Mesa",
            options: {
                filter: false,
                sort: false,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "tax_rate",
            label: "Alíquota IR",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => <span>{value}%</span>,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMuiEmpty"></TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "liquid_income",
            label: "Receita Total Líquido",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMui">{toBRL(rtl)}</TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "broker_funding",
            label: "Repasse Mesa Líquido",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMui">{toBRL(rml)}</TableCell>
                        </TableRow>
                    </TableCell>
                ),
            },
        },
        {
            name: "broker_liquid",
            label: "Repasse Broker Líquido",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                        {label}
                        <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                        <TableRow>
                            <TableCell className="tdSumMui">{toBRL(rbl)}</TableCell>
                        </TableRow>
                    </TableCell>
                ),
                hint: "Uma explicação sobre a coluna",
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "72vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            payRollExport();
            return false;
        },
        onTableChange: (string, object) => {
            sumColumns(object.displayData);
        },
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ maxWidth: "95%", marginTop: "2rem" }}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={"Extrato Mensal - Prévia"} data={listPayroll} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}
