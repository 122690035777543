export function validPassword(password) {

    if (password.length < 8) {
        alert("Senha precisa ter mais do que 7 caracteres");
        return false

    }

    let regex = /\W|_/;
    var hasUpperCase = /[A-Z]/.test(password); // maiusculas
    var hasLowerCase = /[a-z]/.test(password); // minusculas
    var hasNumbers = /\d/.test(password); // números
    var hasNonalphas = regex.test(password); // alfanumerico
    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 4) {
        alert("Senha não atende os requisitos minimos de segurança");
        return false
    }

    return true

}