//Descrição: centraliza as chamadas Get da API em unico local,
//caso seja necessário alterar algum metodo, alterando aqui, irá refletir em todo codigo
//Desenvolvido por: Equipe Rafael Cezário

import { api } from "./api";

//users
export async function getUsers(statusUser, tokenKey) {

    try {
        const usersResult = await api.get(`/api/v1/users?status=${statusUser}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return usersResult?.data?.data

    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Usuarios não localizados' :
                'Erro ao realizar a busca de Usuários' :
                error?.response?.data?.message
        })
    }
}

//Brokers
export async function getBrokers(status, tokenKey) {

    try {
        const brokersResult = await api.get(`api/v1/users?status=${status}&type_access=3`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return brokersResult.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Brokers não cadastrados' :
                'Erro ao realizar a busca de brokers' :
                error?.response?.data?.message
        })
    }

}

//Advisors
export async function getAdvisors(status, tokenKey) {

    try {
        const advisorsResult = await api.get(`api/v1/users?status=${status}&type_access=4`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return advisorsResult.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Assessores não cadastrados' :
                'Erro ao realizar a busca de assessores' :
                error?.response?.data?.message
        })
    }
}

// Brokers Advisor
export async function getBrokersAdvisor(tokenKey, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const brokersAdvisorResult = await api.get(`api/v1/users/brokersadvisor`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return brokersAdvisorResult?.data?.data
        else{
            return brokersAdvisorResult?.data
        }
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Assessores não cadastrados' :
                'Erro ao realizar a busca de brokers por assessor' :
                error?.response?.data?.message
        })
    }
}

// Brokers por segmento
export async function getBrokerBySegment(tokenKey) {

    try {
        const brokersBySegmentResult = await api.get(`api/v1/users/brokersBySegment`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return brokersBySegmentResult.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Assessores não cadastrados' :
                'Erro ao realizar a busca de brokers por assessor' :
                error?.response?.data?.message
        })
    }
}

//broker Profiles
export async function getBrokerProfiles(tokenKey) {

    try {
        const resultProfile = await api.get('api/v1/profiles', {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return resultProfile.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Perfis de brokers não cadastrados' :
                'Erro ao realizar a busca de perfis de brokers' :
                error?.response?.data?.message
        })
    }
}

// Brokers Leaders
export async function getBrokerLeader(tokenKey, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const brokersLeaderResult = await api.get(`api/v1/users/brokerleader`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return brokersLeaderResult?.data?.data
        else{
            return brokersLeaderResult?.data
        }
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Assessores não cadastrados' :
                'Erro ao realizar a busca de brokers por assessor' :
                error?.response?.data?.message
        })
    }
}

// Retorna todos os possiveis líderes de Brokers
export async function getBrokerLeaders(tokenKey) {

    try {
        const brokersLeadersResult = await api.get(`api/v1/users/brokersLeaders`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        // console.log(brokersLeadersResult)
        return brokersLeadersResult.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Assessores não cadastrados' :
                'Erro ao realizar a busca de brokers por assessor' :
                error?.response?.data?.message
        })
    }
}

//types users
export async function getTypesUsers(tokenKey) {
    try {
        const typeAccessResult = await api.get('/api/v1/types', {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return typeAccessResult.data.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Tipos *usuários* não cadastrados' :
                'Erro ao realizar a busca de tipos de usuários' :
                error?.response?.data?.message
        })
    }
}

//companys and Branches
export async function getBranches(tokenKey) {

    try {
        const branchesResult = await api.get('/api/v1/branches', {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return branchesResult?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não existem filiais cadastradas' :
                'Erro ao realizar a busca de filiais' :
                error?.response?.data?.message
        })
    }
}

//books
export async function getBooks(statusBook, tokenKey,) {
    try {
        const booksResult = await api.get(`/api/v1/books?status=${statusBook}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return booksResult?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Books não encontrados em sistema' :
                'Erro ao realizar a busca de books' :
                error?.response?.data?.message
        })
    }
}

//structuredOperations
export async function getEstructuredOperations(bookID, year, month, tokenKey) {

    try {
        const getBooksOrdersList = await api.get(`/api/v1/booksOrders/${bookID}/year/${year}/month/${month}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return getBooksOrdersList?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este book' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//structuredOperationsAll
export async function getEstructuredOperationsAll(userId, tokenKey, month, year, today, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const getBooksOrdersAll = await api.get(`/api/v1/booksOrders/${userId}/month/${month}/year/${year}/today/${today == true ? 1 : 0}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return getBooksOrdersAll?.data?.data
        else{
            return getBooksOrdersAll?.data
        }
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este usuário' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//structuredOperationsPeriods
export async function getEstructuredOperationsPeriods(userId, tokenKey) {

    try {
        const getStructuredPeriods = await api.get(`/api/v1/booksOrders/getPeriods/${userId}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return getStructuredPeriods?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este usuário' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//ordersPush
export async function getPushOrdersOperationsAll(userId, tokenKey, month, year) {
    
    try {
        const getPushOrdersAll = await api.get(`/api/v1/ordersPush/user/${userId}/month/${month}/year/${year}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return getPushOrdersAll?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este usuário' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

export async function getUnsuitedStructured(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const unsuitedStructuredResults = await api.get(`api/v1/structuredUnsuited`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return unsuitedStructuredResults?.data?.data
        else{
            return unsuitedStructuredResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Estruturadas desenquadradas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getExecutedStructured(tokenKey, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const executedStructuredResults = await api.get(`api/v1/executedStructured`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return executedStructuredResults?.data?.data
        else{
            return executedStructuredResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Estruturadas desenquadradas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getBrokenStructured(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const brokenStructuredResults = await api.get(`api/v1/brokenStructured`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return brokenStructuredResults?.data?.data
        else{
            return brokenStructuredResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Estruturadas desenquadradas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

// dueStructured
export async function getDueStructured(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const dueStructuredResults = await api.get(`api/v1/dueStructured`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return dueStructuredResults?.data?.data
        else{
            return dueStructuredResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Vencimentos de Estruturadas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

// estruturadas Wisir (Executadas)
export async function getWES(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const wisirExecutedStructuredResults = await api.get(`api/v1/wisirExecutedStructured`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return wisirExecutedStructuredResults?.data?.data
        else{
            return wisirExecutedStructuredResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Estrutudas Wisir Executadas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

//ordersPushPeriods
export async function getPushOrdersOperationsPeriods(userId, tokenKey) {

    try {
        const getPushOrdersAll = await api.get(`/api/v1/ordersPushPeriods/user/${userId}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return getPushOrdersAll?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este usuário' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//reservation
export async function getReservationOrdersOperationsAll(userId, tokenKey, open, year, month, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const getReservationOrdersAll = await api.get(`/api/v1/offersOrders/year/${year}/month/${month}/open/${open}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return getReservationOrdersAll?.data?.data
        else{
            return getReservationOrdersAll?.data
        }
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//reservationExcel
export async function getReservationOrdersOperationsAllExport(userId, tokenKey, open, year, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const getReservationOrdersAll = await api.get(`/api/v1/offersOrders/year/${year}/open/${open}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return getReservationOrdersAll?.data?.data
        else{
            return getReservationOrdersAll?.data
        }
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//ordersReservationPeriods
export async function getReservationOrdersOperationsPeriods(tokenKey) {

    try {
        const getPushOrdersAll = await api.get(`/api/v1/offersOrders/periods`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return getPushOrdersAll?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações Não encontradas para este usuário' :
                'Erro ao realizar a busca de operações' :
                error?.response?.data?.message
        })
    }
}

//Clients
export async function getAllClients(tokenKey, status, search) {

    let filterStatus = status === undefined ? '' : `?status=${status}`
    try {
        const clientsResult = await api.get(`/api/v1/clients${filterStatus}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                filter : search,
            }
        })
        return clientsResult.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontradas cadastro de clientes no sistema' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}
//getMyClients
export async function getMyClients(idUser, tokenKey) {

    try {
        const myClientsResult = await api.get(`api/v1/users/${idUser}/myClients`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return myClientsResult?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados clientes para este usuário' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}

//getMyClientsWithDetatched
export async function getMyClientsWithDetatched(idUser, tokenKey, search) {
    try {
        const myClientsResult = await api.get(`api/v1/users/${idUser}/myClients?detatched=1`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                filter : search,
            }
        })
        return myClientsResult?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados clientes para este usuário' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}

export async function getMyClientsIndication(tokenKey) {
    try {
        const myClientsResult = await api.get(`api/v1/clientsIndications/toIndicate`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return myClientsResult?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados clientes para este usuário' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}

//getMyClientsToTransfer
export async function getMyClientsToTransfer(idUser, tokenKey) {

    try {
        const myClientsResult = await api.get(`api/v1/users/${idUser}/myClients?transfer=1&&client_status=0,1,2,3,4,5`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return myClientsResult?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados clientes para este usuário' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}

//getMyAdvisorsClients
export async function myAdvisorsClients(user, tokenKey, downloadExcel) {
    // console.log('token', tokenKey)
    // console.log('idUser', idUser)
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const myAdvisorsClientsResult = await api.get(`api/v1/users/${user}/myAdvisorsClients`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel

            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return myAdvisorsClientsResult?.data?.data
        else{
            return myAdvisorsClientsResult?.data
        }
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados clientes para este usuário' :
                'Erro ao realizar a busca de clientes' :
                error?.response?.data?.message
        })
    }
}

//clients MyIndications
export async function getMyIndications(tokenKey) {
    // console.log('chegou')

    try {
        const myIndicationsResult = await api.get(`/api/v1/clientsIndications`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return myIndicationsResult?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não há indicações de clientes para este usuário' :
                'Erro ao realizar a busca de indicações de clientes' :
                error?.response?.data?.message
        })
    }
}

//getAssets
export async function getAssetsList(tokenKey) {
    try {
        const assetsList = await api.get(`/api/v1/assets`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return assetsList?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não foram localizados ativos cadastrados' :
                'Erro ao realizar a busca de ativos de clientes' :
                error?.response?.data?.message
        })
    }
}

//assetsDetails
export async function getAssetsDetails(tokenKey, asset, bookId) {
    
    try {
        const assetDetails = await api.get(`api/v1/booksAssetsDetails/books/${bookId}/asset/${asset}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return assetDetails?.data?.data

    } catch (error) {
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Detalhes de oferta não localizada' :
                'Erro ao realizar a busca do texto' :
                error?.response?.data?.message
        })
    }
}

//getAssetClass
export async function getAssetClass(tokenKey) {
    try {
        const assetClassList = await api.get(`/api/v1/assetClass`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return assetClassList?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não foram localizados classes de ativos cadastrados' :
                'Erro ao realizar a busca de classe de ativos' :
                error?.response?.data?.message
        })
    }
}

//getAssetSectors
export async function getAssetSectors(tokenKey) {
    try {
        const assetSectorsList = await api.get(`/api/v1/assetSector`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return assetSectorsList?.data.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não foram localizados setores de ativos cadastrados' :
                'Erro ao realizar a busca de setor de ativos' :
                error?.response?.data?.message
        })
    }
}

export async function getOrdersPushPrice(tokenKey) {

    try {
        const pushPriceList = await api.get(`/api/v1/ordersPushPrice`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return pushPriceList?.data?.data
    } catch (error) {
        {
            return ({
                isError: true,
                error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                    'Erro ao listar dados' :
                    'Erro ao listar dados' :
                    error?.response?.data?.message
            })
        }
    }
}

export async function getOrdersPushFormat(tokenKey) {

    try {
        const pushOrdersFormat = await api.get(`/api/v1/ordersPushFormat`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return pushOrdersFormat?.data?.data
    } catch (error) {
        {
            return ({
                isError: true,
                error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                    'Erro ao listar dados' :
                    'Erro ao listar dados' :
                    error?.response?.data?.message
            })
        }
    }
}


//getAssetsRecommendations
export async function getAssetsRecommendations(tokenKey){
    try {
        const assetsReommendations = await api.get(`/api/v1/assetsRecommendations`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return assetsReommendations?.data?.data
    } catch (error) {
        {
            return ({
                isError: true,
                error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                    'Erro ao listar dados' :
                    'Erro ao listar dados' :
                    error?.response?.data?.message
            })
        }
    }
}

//getOffers
export async function getOffers(tokenKey) {

    try {
        const stocksOffers = await api.get(`/api/v1/offers?status=1`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return stocksOffers?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Ofertas não localizadas' :
                'Erro ao realizar a busca de Ofertas' :
                error?.response?.data?.message
        })
    }
}

//getOffersTypes
export async function getOffersTypes(tokenKey) {

    try {
        const offersTypes = await api.get(`/api/v1/offersTypes`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return offersTypes?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Erro ao localizar dados' :
                'Erro ao localizar dados' :
                error?.response?.data?.message
        })
    }
}

//getOffersDetails
export async function getOffersDetails(tokenKey, asset) {
    
    try {
        const offersDetails = await api.get(`/api/v1/offers/${asset}/details`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return offersDetails?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 400 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Detalhes de oferta não localizada' :
                'Erro ao realizar a busca do texto' :
                error?.response?.data?.message
        })
    }
}

//postOffersDetails
export async function postOffersDetails(tokenKey, asset, textToAPI) {

    try {
        const stocksOffersDetails = await api.post(`/api/v1/offers/${asset}/details`,{
            details: textToAPI
        }, 
        {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return stocksOffersDetails?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Erro ao realizar cadastro do texto' :
                'Erro ao realizar cadastro do texto' :
                error?.response?.data?.message
        })
    }
}

//getTradeIdeas
export async function getTradeIdeas(tokenKey) {
    
    try {
        const tradeIdeasGet = await api.get(`/api/v1/tradeIdeas`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return tradeIdeasGet?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.data?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Trade ideas não localizados' :
                'Erro ao realizar a busca do texto' :
                error?.response?.data?.message
        })
    }
}

//dashboardDetails
export async function getDashboardDetails(tokenKey) {
    
    try {
        const dashboardDetails = await api.get(`api/v1/dashboard`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return dashboardDetails?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Detalhes não localizados' :
                'Erro ao realizar a busca do texto' :
                error?.response?.data?.message
        })
    }
}

//brokerPanelLeftLinks
export async function getBrokerPanelLeftLinks(tokenKey) {
    
    try {
        const brokerPanelLeftLinks = await api.get(`api/v1/brokerPanel`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return brokerPanelLeftLinks?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Links laterais não localizados' :
                'Erro ao realizar a busca dos links' :
                error?.response?.data?.message
        })
    }
}

//brokerPanelGoals
export async function getBrokerPanelGoals(tokenKey) {
    
    try {
        const brokerPanelGoals = await api.get(`api/v1/brokerPanelGoals`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return brokerPanelGoals?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Objetivos do Broker não localizados' :
                'Erro ao realizar a busca dos objetivos' :
                error?.response?.data?.message
        })
    }
}

//brokerPanelResults
export async function getBrokerPanelResults(tokenKey, user_external_id, month, year) {
    
    try {
        const brokerPanelResults = await api.get(`api/v1/brokerPanelGoalsResults/user/${user_external_id}/month/${month}/year/${year}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        // console.log(brokerPanelResults)
        return brokerPanelResults?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Resultados do Broker não localizados' :
                'Erro ao realizar a busca dos resultados do broker' :
                error?.response?.data?.message
        })
    }
}

//brokerPanelResults
export async function getBrokerPayroll(tokenKey) {
    
    try {
        const brokerPanelResults = await api.get(`api/v1/payroll`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return brokerPanelResults?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Extratos do Broker não localizados' :
                'Erro ao realizar a busca dos resultados do broker' :
                error?.response?.data?.message
        })
    }
}

// wisir advisor panel
export async function getAdvisorWisirPanel(tokenKey) {
    try {
        const advisorWisir = await api.get(`api/v1/wisir/compiledData`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return advisorWisir?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Dados do Assessor não localizados' :
                'Erro ao realizar a busca dos objetivos' :
                error?.response?.data?.message
        })
    }
}

//getWisirAcoes
export async function getWisirAcoes(tokenKey) {
    // console.log(tokenKey)
    try {
        const wisirStatus = await api.get(`/api/v1/wisir/operational`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return wisirStatus?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Ofertas não localizadas' :
                'Erro ao realizar a busca de Ofertas' :
                error?.response?.data?.message
        })
    }
}

//Brokers
export async function getRoaDashboard(tokenKey,downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const roaDashboard = await api.get(`api/v1/roaDashboard`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return roaDashboard?.data?.data
        else{
            return roaDashboard?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Extratos do Broker não localizados' :
                'Erro ao realizar a busca dos resultados do broker' :
                error?.response?.data?.message
        })
    }
}

// wisir advisor panel
export async function getB3GuaranteesFollowUp(tokenKey) {
    try {
        const b3GuaranteesFollowup = await api.get(`api/v1/b3guarantees/followUp`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return b3GuaranteesFollowup?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Dados de garantias da B3 não localizados.' :
                'Erro ao realizar a busca dos clientes.' :
                error?.response?.data?.message
        })
    }
}

//retorna as operações de termo caixa
export async function getCashForwards(idUser, tokenKey,downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const result = await api.get(`api/v1/cashForward`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return result?.data?.data
        else{
            return result?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados operações para este usuário' :
                'Erro ao realizar a busca de operações termo caixa' :
                error?.response?.data?.message
        })
    }
}

//retorna os possiveis status de operações de termo caixa
export async function getStatusCashForwards(idUser, tokenKey) {
    try {
        const result = await api.get(`api/v1/cashForward/status`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return result?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados os dados de status das operações' :
                'Erro ao realizar a busca de status das operações termo caixa' :
                error?.response?.data?.message
        })
    }
}

export async function getDailyInformations(idUser, tokenKey) {
    try {
        const result = await api.get(`api/v1/cashForward/dailyInformations`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return result?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados os dados de status das operações' :
                'Erro ao realizar a busca de status das operações termo caixa' :
                error?.response?.data?.message
        })
    }
}

export async function getLayoutCashForwards(idUser, tokenKey) {
    try {
        const result = await api.get(`api/v1/cashForward/downloadLayout`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
            responseType: 'blob'
        })
            return result?.data
        

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Não encontrados o arquivo solicitado' :
                'Erro ao realizar o download do layout de operações termo caixa' :
                error?.response?.data?.message
        })
    }
}

export async function getNegativeBalance(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeResults = await api.get(`api/v1/balance/indexNegative`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeResults?.data?.data
        else{
            return indexNegativeResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Saldo D0 e Projetado negativos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getOpportunityBalance(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexOpportunityResults = await api.get(`api/v1/balance/indexOpportunity`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexOpportunityResults?.data?.data
        else{
            return indexOpportunityResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Saldo D0 e Projetado negativos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getNegativeBTC(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeBTCResults = await api.get(`api/v1/balance/indexNegativeBTC`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeBTCResults?.data?.data
        else{
            return indexNegativeBTCResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Dash Vendidos não localizado.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getAssetForward(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexAssetForward = await api.get(`api/v1/balance/indexAssetForward`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexAssetForward?.data?.data
        else{
            return indexAssetForward?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Dash Termos não localizado.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getNotExecuted(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNotExecuted = await api.get(`api/v1/balance/indexNotExecuted`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        console.log(indexNotExecuted?.data);
        if (downloadExcel === false)
            return indexNotExecuted?.data?.data
        else{
            return indexNotExecuted?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações não executadas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getClientsROA(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexClientsROA = await api.get(`api/v1/roaDashboard`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexClientsROA?.data?.data
        else{
            return indexClientsROA?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'ROA por cliente não localizado.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getBrokerProjected(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeResults = await api.get(`api/v1/roaDashboard/indexBrokerProjected`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeResults?.data?.data
        else{
            return indexNegativeResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Receita projetada por broker não localizada.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getDatabaseUpdateTime(tokenKey, tables) {
    
    try {
        const dbUpdateTimes = await api.get(`api/v1/databaseUpdate/${tables}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return dbUpdateTimes?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Datas de atualizações não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getDatabaseReferenceTime(tokenKey, tables) {
    
    try {
        const dbUpdateTimes = await api.get(`api/v1/databaseUpdate/tables/${tables}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })

        return dbUpdateTimes?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Datas de atualizações não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getABCAvailableOperations(tokenKey, operation_id) {
    try {
        const dbGetAvailableOperations = await api.get(`api/v1/abc/${operation_id}/operations`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return dbGetAvailableOperations;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operações disponíveis não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getABCReferenceOperation(tokenKey, operation_id) {
    try {
        const dbGetABCReference = await api.get(`api/v1/abc/${operation_id}/reference`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return dbGetABCReference;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Operação não localizada.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        }) 
    }
}

export async function updateABCStatus(tokenKey, operation_id, status_id,abc_products_id, client_id) {
    try {
        const dbUpdateStatus = await api.put(`api/v1/abc/${operation_id}/status/${status_id}`, {
            abc_products_id: abc_products_id,
            client_id: client_id
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return dbUpdateStatus;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Atualização não realizada.' :
                'Erro ao realizar a atualização do Status.' :
                error?.response?.data?.message
        }) 
    }
}

export async function updateABCClientCategory(tokenKey, operation_id, category_id) {
    try {
        const dbUpdateStatus = await api.put(`api/v1/abc/${operation_id}/category/${category_id}`, {}, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return dbUpdateStatus;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Atualização não realizada.' :
                'Erro ao realizar a atualização da Categoria.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getABCProducts(tokenKey, area) {
    try {
        const dbABCProducts = await api.get(`api/v1/abc/config/products`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                // area_id: area // !TIP DO GD: AREA 1 REPRESENTA RENDA VARIÁVEL, SE QUISER TRAZER TODOS OS PRODUTOS DE TODAS AS ÁREAS, BASTA REMOVER ESSA LINHA
            }
        })
        return dbABCProducts;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Produtos não localizados.' :
                'Erro ao buscar os produtos.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getABCClients(tokenKey, product_id) {
    try {
        const dbABCClients = await api.get(`api/v1/abc/config/products/${product_id}/clients`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        return dbABCClients?.data;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Clientes não localizados.' :
                'Erro ao buscar os clientes.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getABCDashboard(tokenKey, downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const dbABCDash = await api.get(`api/v1/abc/dashboard`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                download: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return dbABCDash
        else{
            return dbABCDash?.data
        }
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Produtos não localizados.' :
                'Erro ao buscar os resultados dos produtos.' :
                error?.response?.data?.message
        }) 
    }
}

export async function updateListOnly(tokenKey, product_id, state) {
    try {
        const ABCListOnlyUpdate = await api.put(`api/v1/abc/config/products/${product_id}/listOnly`, {
            state: state
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return ABCListOnlyUpdate;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Produto não localizado.' :
                'Erro ao atualizar.' :
                error?.response?.data?.message
        }) 
    }
}

export async function updateActive(tokenKey, product_id, state) {
    try {
        const ABCListActive = await api.put(`api/v1/abc/config/products/${product_id}/active`, {
            state: state
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return ABCListActive;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Produto não localizado.' :
                'Erro ao atualizar.' :
                error?.response?.data?.message
        }) 
    }
}

export async function deleteProduct(tokenKey, product_id) {
    try {
        const ABCListActive = await api.delete(`api/v1/abc/config/products/${product_id}`,{
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return ABCListActive;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Produto não localizado.' :
                'Erro ao excluir.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getOperationsStatus(tokenKey) {
    try {
        const operationsStatus = await api.get(`api/v1/operationsStatus`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
        })
        return operationsStatus?.data;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Status de operações não localizados.' :
                'Erro ao buscar os Status de operações.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getLongShorts(tokenKey) {
    try {
        const longShorts = await api.get(`api/v1/longShort/operations`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
        })
        console.log(longShorts?.data)
        return longShorts?.data;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Long&Shorts não localizados.' :
                'Erro ao buscar os Long&Shorts.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getLongShortsHistory(tokenKey, client_id, dtInicio, dtFinal, downloadExcel) {
    try {
        console.log(dtInicio, dtFinal);
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeResults = await api.get(`api/v1/longShort/history/${client_id}/${dtInicio}/${dtFinal}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeResults?.data?.data
        else{
            return indexNegativeResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Receita projetada por broker não localizada.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function updateMyReservationStatus(tokenKey, offers_orders_id, state) {
    try {
        const offerResult = await api.put(`api/v1/offersOrders/`, {
            status: state,
            orders: offers_orders_id
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return offerResult;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Oferta não localizada.' :
                'Erro ao atualizar.' :
                error?.response?.data?.message
        }) 
    }
}

export async function updateMyStruturedsStatus(tokenKey, offers_orders_id, state) {
    try {
        const offerResult = await api.put(`api/v1/booksOrders`, {
            status: state,
            orders: offers_orders_id
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return offerResult;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Oferta não localizada.' :
                'Erro ao atualizar.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getLongShortIndex(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeResults = await api.get(`api/v1/longShortIndex`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeResults?.data?.data
        else{
            return indexNegativeResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Receita projetada por broker não localizada.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function updateActiveLongShort(tokenKey, product_id, state) {
    console.log(product_id, state)
    try {
        const longShortActive = await api.put(`api/v1/longShort/${product_id}/active`, {
            state: state
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return longShortActive;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Long Short não localizado.' :
                'Erro ao atualizar.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getDashAlocacao(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const indexNegativeResults = await api.get(`api/v1/alocacao`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return indexNegativeResults?.data?.data
        else{
            return indexNegativeResults?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getCampaignsRV(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/table/campains/rv`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? 
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDashAlocacaoTableCampains(tokenKey) {
    try {
        const essencialsDash = await api.get(`api/v1/alocacao/table/campains`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return essencialsDash?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDashAlocacaoTableConfiguration(tokenKey) {    
    try {
        const configurationDash = await api.get(`api/v1/alocacao/table/configuration`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return configurationDash?.data?.data

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDashAlocacaoTableOptions(tokenKey) {
    try {
        const optionsDash = await api.get('api/v1/alocacao/table/configuration/options', {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return optionsDash?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Opções da dashboard não localizada.' :
                'Erro ao realizar a busca das opções da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDashAlocacaoEvents(tokenKey) {
    try {
        const eventsDash = await api.get('api/v1/alocacao/calendar/events', {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return eventsDash?.data?.data
    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Opções da dashboard não localizada.' :
                'Erro ao realizar a busca das opções da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDefaultProfiles(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/config/permissions/default`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getPermissionsAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/permissions`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getCenariosAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/cenarios`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Cenários não localizados.' :
                'Erro ao realizar a busca dos cenários.' :
                error?.response?.data?.message
        })
    }
}

export async function getCampainBasesAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/campain_bases`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getCustody(tokenKey,downloadExcel) {
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const custody = await api.get(`api/v1/custody`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return custody?.data
        else{
            return custody?.data
        }
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
            'Custódias não localizados.' :
                'Erro ao buscar custódias.' :
                error?.response?.data?.message
        }) 
    }
}
            

export async function getPrazosAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/prazos`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
            'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getReturnObjectivesAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/return_objectives`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getProfilesAlocacao(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/profiles`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da dashboard não localizada.' :
                'Erro ao realizar a busca das informações da dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getAlocacaoCalendarProducts(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/calendar/products`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações do calendário não localizadas.' :
                'Erro ao realizar a busca das informações da Dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getSettlementCalendar(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/calendar/settlements`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações do calendário não localizadas.' :
                'Erro ao realizar a busca das informações da Dashboard.' :
                error?.response?.data?.message
        })
    }
}

export async function getDashAlocacaoCampains(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/campains`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Campanhas não localizadas.' :
                'Erro ao realizar a busca das campanhas.' :
                error?.response?.data?.message
        })
    }
}

export async function getCubastar(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/cubastar`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da Cubastar não localizadas.' :
                'Erro ao realizar a busca das informações da Cubastar.' :
                error?.response?.data?.message
        })
    }
}

export async function getCubastarCarteiras(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/cubastar/carteiras`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações das carteiras Cubastar não localizadas.' :
                'Erro ao realizar a busca das informações das carteiras Cubastar.' :
                error?.response?.data?.message
        })
    }
}

export async function getCubastarFundos(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/cubastar/fundos`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações dos Fundos Cubastar não localizadas.' :
                'Erro ao realizar a busca das informações dos Fundos Cubastar.' :
                error?.response?.data?.message
        })
    }
}

export async function getCubastarTacticalTable(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/cubastar/alocacaoTatica`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da tabela de alocação tática Cubastar não localizadas.' :
                'Erro ao realizar a busca das informações dos Fundos Cubastar.' :
                error?.response?.data?.message
        })
    }
}

export async function getCubastarRentabilityCurve(tokenKey, id_carteira, portfolio) {
    try {
        const results = await api.get(`api/v1/alocacao/cubastar/carteiras/${id_carteira}/curve?portfolio=${encodeURIComponent(portfolio)}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            },
        })
        return results?.data
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informações da tabela de alocação tática Cubastar não localizadas.' :
                'Erro ao realizar a busca das informações dos Fundos Cubastar.' :
                error?.response?.data?.message
        })
    }
}

export async function getFixedIncomeView(tokenKey, downloadExcel, type) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const results = await api.get(`api/v1/alocacao/products/rendaFixa/`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel,
                type: type
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return results?.data?.data
        else{
            return results?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Vencimentos de Estruturadas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getFixedIncomeDuesView(tokenKey, downloadExcel) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const results = await api.get(`api/v1/alocacao/products/rendaFixaDues`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel,
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return results?.data?.data
        else{
            return results?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Vencimentos de Estruturadas não localizadas.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

// export async function getFundsView(tokenKey, downloadExcel) {
    
//     try {
//         const responseType = downloadExcel === true ? 'blob' : ''
//         const results = await api.get(`api/v1/alocacao/products/fundos/`, {
//             headers: {
//                 authorization: `Bearer ${tokenKey}`,
//                 donwload: downloadExcel
//             },
//             responseType: responseType
//         })
//         if (downloadExcel === false)
//             return results?.data?.data
//         else{
//             return results?.data
//         }

//     } catch (error) {
//         console.log(error?.response)
//         return ({
//             isError: true,
//             notExist: error?.response?.status === 404 ? true : false,
//             error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
//                 'Lista de fundos não localizados.' :
//                 'Erro ao realizar a busca dos resultados.' :
//                 error?.response?.data?.message
//         })
//     }
// }

export async function getFundos(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/products/fundos`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            }
        })
        return results?.data?.data;
        
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Lista de fundos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getFundosClients(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/products/fundos/clients`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            }
        })
        return results?.data?.data;

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Lista de fundos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getFixedIncomeInformation(tokenKey, downloadExcel, product_type) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const results = await api.get(`api/v1/alocacao/products/informatives/${product_type}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return results?.data?.data
        else{
            return results?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Informativos de produtos de investimentos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function getOffshoreEducational(tokenKey) {
    try {
        const results = await api.get(`api/v1/alocacao/products/offshore`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
            }
        })
        return results;
        
    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Lista de materiais educacionais de offshore não encontrada.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

export async function postOffshoreEducational(tokenKey, id, data) {

    try {
        const educationalMaterial = await api.post(`/api/v1/alocacao/products/offshore/${id}`,{
            data: data
        }, 
        {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return educationalMaterial?.data?.data

    } catch (error) {
        return ({
            isError: true,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Erro ao realizar cadastro de material educacional de Offshore.' :
                'Erro ao realizar cadastro de material educacional de Offshore.' :
                error?.response?.data?.message
        })
    }
}

export async function updateOffshoreEducational(tokenKey, id, source, description, link) {
    try {
        console.log(`async:`, id, source, description, link)
        const dbUpdateStatus = await api.put(`api/v1/alocacao/products/offshore/${id}`, {
            source: source,
            description: description,
            link: link
        }, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return dbUpdateStatus;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Atualização não realizada.' :
                'Erro ao realizar a atualização do Status.' :
                error?.response?.data?.message
        }) 
    }
}

export async function removeOffshoreEducational(tokenKey, id) {
    try {
        const educationalMaterial = await api.delete(`api/v1/alocacao/products/offshore/${id}`,{
            headers: {
                authorization: `Bearer ${tokenKey}`
            }
        })
        return educationalMaterial;
    } catch (error) {
        console.log(error)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Material não localizado.' :
                'Erro ao excluir.' :
                error?.response?.data?.message
        }) 
    }
}

export async function getCampaignsArea(tokenKey, downloadExcel, area_id) {
    
    try {
        const responseType = downloadExcel === true ? 'blob' : ''
        const results = await api.get(`api/v1/alocacao/campains/${area_id}`, {
            headers: {
                authorization: `Bearer ${tokenKey}`,
                donwload: downloadExcel
            },
            responseType: responseType
        })
        if (downloadExcel === false)
            return results?.data?.data
        else{
            return results?.data
        }

    } catch (error) {
        console.log(error?.response)
        return ({
            isError: true,
            notExist: error?.response?.status === 404 ? true : false,
            error: error?.response?.data?.message === undefined ? error?.response?.status === 404 ?
                'Cards de campanhas em andamentos não localizados.' :
                'Erro ao realizar a busca dos resultados.' :
                error?.response?.data?.message
        })
    }
}

