/*Styles*/
import './styles/pushOrders.css';
import React, { useState } from 'react';
/*Contexts*/
import { useModal } from '../../../contexts/useModal';
/*Util Components*/
import Modal from '../../../components/modal/modal';
import { ConfirmationBox } from '../../../components/confirmationBox/confirmationBox';
import { PushOrdersTable } from './tables/pushOrders.table';

export function PushOrders() {

    const { type, setType, show, setShow } = useModal();

    return (
        <div className='pushOrdersContainer'>
            <Modal>
                {
                    type.name === 'alertMsg' &&
                    (
                        <ConfirmationBox msgData={type} />
                    )
                }

            </Modal>
            <PushOrdersTable />
        </div>
    )
}