import { useModal } from "../../../contexts/useModal";
import Modal from '../../../components/modal/modal';
import { SelectFormStructure } from "./structures/selectFormStructure";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import { FormToReply } from "./structures/formToReply";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./styles/global.css";

export function FormReply(){
  const {type} = useModal()

  const location = useLocation()
  const id = location.state

  return (
    <div className="containerCreateForm">
      <Modal>{type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : <></>}</Modal>
      <FormToReply id={id}/>
    </div>
  )
}