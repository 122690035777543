import React, { useEffect, useState } from 'react'

import './styles/myReservations.details.css'
import { api } from '../../../../services/api'
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
import { BiEditAlt } from 'react-icons/bi'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'

export function MyReservationDetails(id) {

    const { type, show, setShow, setType } = useModal()
    const [observation, setObservation] = useState(false)
    const [observationBackoffice, setObservationBackoffice] = useState(true)
    const { user, tokenKey } = useAuth()
    const [loading, setLoading] = useState(true)

    const offerId = id?.data?.order

    async function getOfferDetails() {
        try {
            const offerDetails = await api.get(`/api/v1/offersOrders/${offerId}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setLoading(false)
            setObservation(offerDetails?.data?.data[0].anotations ?? 'Sem anotações cadastradas.')
    
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados da ordem' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true)
        getOfferDetails(id)
        // type.data.order.observation === undefined || type.data.order.observation === 'undefined' ||
        //     type.data.order.observation === '' || type.data.order.observation === '0' ? setObservation(false) : setObservation(true);
        // type.data.order.anotations === undefined || type.data.order.anotations === 'undefined' ||
        //     type.data.order.anotations === '' || type.data.order.anotations === '0' ? setObservationBackoffice(false) : setObservationBackoffice(true);
    }, [show])

    async function saveBackofficeObs(){
        let message = document.getElementById("textFieldBackofficeObservation").innerHTML
        console.log(message)
        try {
            const updateBackofficeObs = await api.put(`/api/v1/offersOrders/${offerId}/anotation`, {
                'anotation': message
            },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Anotação gerada com sucesso!' } })
            setLoading(false);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Ocorreu um erro ao atualizar a observação backoffice' : error?.response?.data?.message } })
            setShow(true)
        }
    }

    return (
        <>
            <div className='myReservationDetails'>
                {
                    !loading ? 
                    ( observation ?
                        (
                            <>
                                <div className='myReservationsHeader'>
                                    <p>Observações</p>
                                    <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                                </div>
                                <div className='myReservationsBody'>
                                    <p>{observation}</p>
                                </div>
                            </>
                        ) :
                        (
                            <>
                                <div className='myReservationsHeader'>
                                    <p>Observações</p>
                                    <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                                </div>
                                <div className='myReservationEmptyBody'>
                                    <h4>Nenhuma observação cadastrada para esta oferta</h4>
                                </div>
                            </>

                        )
                    ) : <ArrowLoader />
                }
            </div>
            {user?.type <= 2 ?
                <div className='containerBackofficeObservation'>
                    <div className='myReservationsHeader'>
                        <p>Observações Backoffice</p>
                        <label for="textFieldBackofficeObservation" style={{marginRight: "2rem"}}>Clique na caixa de texto para editar <BiEditAlt size={16}/></label>
                    </div>
                    <div className='containerBackofficeObservationText'>
                        <div className='textFieldBackofficeObservation' id="textFieldBackofficeObservation" contentEditable>
                            {observationBackoffice ?
                                // <div dangerouslySetInnerHTML={{__html: observation}}></div>
                                observationBackoffice
                            :
                                <></>
                            }
                        </div>
                        <button className='saveBackofficeObservation' onClick={(e) => saveBackofficeObs()}>Salvar</button>
                    </div>
                </div>
                :
                <></>
            }
            
        </>
    )

}