import React, { useState,useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MdDone from "@mui/icons-material/Done";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Block from "@mui/icons-material/Block";
import { withStyles } from "tss-react/mui";
import { Autocomplete } from '@material-ui/lab'
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import { height } from '@mui/system';

const defaultToolbarSelectStyles = {

  iconContainer: {
    display: "flex",
    marginRight: "3rem"
  },
  statusArea : {
    width: "15rem",
    marginRight: "3rem",
    height: "1.5rem"
  }
};

class CustomToolbarSelect extends React.Component {

handleSelectedStatus = () => {
  const selectedsIds = [];
  (this.props.selectedRows.data).forEach((val) => {
    selectedsIds.push(this.props.displayData[val.index].data[18])
  })
  this.props.setSelectedRows([])
  this.props.setSelectedLines(selectedsIds)
  this.props.handleUpdateStatus(selectedsIds)
};

render() {
    const { classes } = this.props;
    return (
      <div className={classes.iconContainer}>
        <Tooltip title={"Selecione um status"}>
            <Autocomplete
                options={this?.props?.statusCashForwards}
                className={classes.statusArea}                
                onChange={(event, newValue) => {
                    this.props.setSelectedStatus(newValue);
                }}
                getOptionLabel={(option) => titleize(option.description)}
                id='status'
                renderInput={(params) =>
                    <TextField
                        onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                        label='Status'
                        className={classes.statusArea}
                        {...params} 
                        variant="outlined"
                        size="small"
                    />
                }
            />
        </Tooltip>
        <Tooltip title={"Aplicar"}>
          <IconButton onClick={this.handleSelectedStatus}>
            <MdDone/>
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(CustomToolbarSelect, defaultToolbarSelectStyles, { name: "CustomToolbarSelect" });
