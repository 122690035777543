import React, { useState,useEffect } from 'react';
/*Styles*/
import '../styles/table.css';
/*Contexts*/
import { useAuth } from '../../../../../contexts/useAuth';
import { useModal } from '../../../../../contexts/useModal';
import { TextField, useScrollTrigger,TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import { ThemeProvider,createTheme } from '@mui/material/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { toBRL } from "../../../../../utils/formatNumberAndText/toBRLCurrency";
import { getCashForwards,getStatusCashForwards } from '../../../../../services/asyncFunctions'
import { Autocomplete } from '@material-ui/lab'
import { styledForOfferings } from '../../../../../components/styles/autocompletField'
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { RiContactsBookLine } from 'react-icons/ri';
import { api } from '../../../../../services/api'
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomToolbarSelect from "./CustomToolbarSelect";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

//components
import { ArrowLoader } from "../../../../../components/loaders/arrowLoader";

export function TermoCaixaStructure(){
  const { tokenKey,user } = useAuth()
  const { show, setShow, type, setType } = useModal()
  const [searchClientValue, setSearchClientValue] = useState('')
  const classes = styledForOfferings()
  const [errorFields, setErrorFields] = useState({selectedClient: false, selectedValue: false})
  const [handleSearch, setHandleSearch] = useState(false)
  const [confirmSubmit, setConfirmSubmit] = useState(false)
  const [loading, setLoading] = useState(true);
  const [myCashForwards, setMyCashForwards] = useState([])
  const [statusCashForwards, setStatusCashForwards] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedLines, setSelectedLines] = useState([])

  useEffect(() => {
    myCashForwardsGet()
    statusCashForwardGet()
  },[show])


  async function myCashForwardsGet() {
    const cashForwardsResult = await getCashForwards(user.external_id, tokenKey, false)
    if (cashForwardsResult?.isError) {
      setType({
        name: 'alertMsg',
        type: 'information',
        value: {
                message: cashForwardsResult?.error
            }
        })
        setShow(true);
    } else {
        if (cashForwardsResult){
          setMyCashForwards(cashForwardsResult)
        }
    }
    setLoading(false)
  }

  async function statusCashForwardGet() {
    const statusCashForwardsResult = await getStatusCashForwards(user.external_id, tokenKey)
    if (statusCashForwardsResult?.isError) {
      setType({
        name: 'alertMsg',
        type: 'information',
        value: {
                message: statusCashForwardsResult?.error
            }
        })
        setShow(true);
    } else {
        if (statusCashForwardsResult){
          setStatusCashForwards(statusCashForwardsResult)
        }
    }
    setLoading(false)
  }

async function handleUpdateStatus(ids){
    try {
      await api.put(`/api/v1/cashForward/updateStatus`, {
        ids : ids,
        status : selectedStatus.id,
      }, {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setType({ name: 'alertMsg', type: 'information', value: { message: "Status atualizado(s) com sucesso!" } })
      setShow(true)
      } catch (error) {
        console.log(error?.response)
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar o(s) status.' : error.response.data.message } })
        setShow(true)
      }
}

function copyToClipboard() {
  const output = [];
  const titles = [
    "Cód. operador",
    "Operado por",
    "Cód. cliente",
    "Cliente",
    "Cód. broker",
    "Broker",
    "Cód. assessor",
    "Assessor",
    "Ativo",
    "Volume",
    "Data solicitação",
    "Dias Corridos",
    "Vencimento",
    "Taxa BTC",
    "Taxa Termo",
    "Corretagem BTC",
    "Corretagem Termo",
    "Status",
  ];
  output.push(titles.join("\t"));
  const keys = [
    "users_external_id",
    "operator",
    "client_id",
    "client_name",
    "broker_id",
    "broker_name",
    "advisor_id",
    "advisor_name",
    "asset",
    "approximate_volume",
    "create_at",
    "expiry_days",
    "due_date",
    "fee_btc",
    "fee_term",
    "brokerage_btc",
    "brokerage_term",
    "status_description",
  ];
  myCashForwards.forEach((x) => {
      const tmp = keys.map((y) => {return (
        y === 'due_date' || y === 'create_at' ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") :
        y === 'fee_btc' || y === 'fee_term' || y === 'brokerage_btc' || y === 'brokerage_term'?  ((x[y]*100).toFixed(2)).replace('.',',') +'%': 
        x[y] )});
      output.push(tmp.join("\t"));
  });
  const table = output.join("\n");
  navigator.clipboard.writeText(table);
  alert("Conteúdo da tabela copiado para a área de transferência");
}

async function cashForwardsExport() {
  setLoading(true);
  try {
      const myExport = await getCashForwards(user.external_id, tokenKey, true);
      const responseURL = URL.createObjectURL(new Blob([myExport]));
      const link = document.createElement("a");
      link.href = responseURL;
      link.setAttribute("download", "operações termo caixa.xlsx");
      document.body.appendChild(link);
      link.click();
      setLoading(false);
  } catch (error) {
      setType({
          name: "alertMsg",
          type: "information",
          value: {
              message:
                  error?.response?.data?.message === undefined
                      ? "Erro ao buscar dados para exportação"
                      : error?.response?.data?.message,
          },
      });
      setShow(true);
      setLoading(false);
  }
}

const columns = [
  {
    name: "users_external_id",
    label: "Cód. operador",
    options: {
        filter: true,
        sort: true,
        display: false,
    },
  },
  {
    name: "operator",
    label: "Operado por",
    
    options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
            return (
              <Tooltip title={value}>
                  <p>
                  {value.length > 30 ? value.substr(0,27) + '...' : value}
                  </p>
              </Tooltip>
              ) 
        }
    },
  },
  {
    name: "client_id",
    label: "Cód. cliente",
    options: {
        filter: true,
        sort: true,
        display: true
    },
  },
  {
      name: "client_name",
      label: "Cliente",
      options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
              return (
                <Tooltip title={value}>
                    <p>
                    {value.length > 30 ? value.substr(0,27) + '...' : value}
                    </p>
                </Tooltip>
                ) 
          }
      },
  },
  {
    name: "broker_id",
    label: "Cód. broker",
    options: {
        filter: true,
        sort: true,
        display: false
    },
  },
  {
      name: "broker_name",
      label: "Broker",
      options: {
          filter: true,
          sort: true,
      },
      sort: true,
      customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
                <p>
                {value.length > 20 ? value.substr(0,17) + '...' : value}
                </p>
            </Tooltip>
            ) 
      }
  },
  {
    name: "advisor_id",
    label: "Cód. assessor",
    options: {
        filter: true,
        sort: true,
        display: false
    },
  },
  {
      name: "advisor_name",
      label: "Assessor",
      options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
              return (
                <Tooltip title={value}>
                    <p>
                    {value.length > 30 ? value.substr(0,27) + '...' : value}
                    </p>
                </Tooltip>
                ) 
          }
      },
  },
  {
      name: "asset",
      label: "Ativo",
      options: {
          filter: true,
          sort: true,
      },
  },
  {
      name: "approximate_volume",
      label: "Volume",
      options: {
          filter: false,
          sort: true,
          customBodyRender: (val) => {
            return toBRL(val);
          },
      },
  },
  {
    name: "create_at",
    label: "Data solicitação",
    options: {
        filter: true,
        sort: true,
        sortDirection: 'asc',
        customBodyRender: (val) => {
          return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
        },
    },
},
  {
      name: "expiry_days",
      label: "Dias Corridos",
      options: {
          filter: false,
          sort: true,
      },
  },
  {
      name: "due_date",
      label: "Vencimento",
      options: {
          filter: true,
          sort: true,
          customBodyRender: (val) => {
            return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
          },
      },
  },
  {
      name: "fee_btc",
      label: "BTC",
      options: {
          filter: false,
          sort: false,
          customBodyRender: (val, ...row) => {
            return(<>
                <TableRow className="tr2Taxas">
                    <TableCell className="tdTaxas">{String((val*100).toFixed(2)).replace('.',',') +'%'}</TableCell>
                    <TableCell className="tdTaxas">{String((row[0].rowData[14]*100).toFixed(2)).replace('.',',') +'%'}</TableCell>
                </TableRow>
                </>)
                
          },
          customHeadRender: ({ index, label, ...column }, tableMeta) => (
            <TableCell key={index} className="thTaxas">
                TAXAS
                <TableRow className="tr2Taxas">
                    <TableCell className="th2Taxas">{label}</TableCell>
                    <TableCell className="th2Taxas">TERMO</TableCell>
                </TableRow>
            </TableCell>
        ),
      },
  },
  {
      name: "fee_term",
      label: "t Termo",
      options: {
          filter: false,
          sort: false,
          display: false
          
      },
  },
  {
      name: "brokerage_btc",
      label: "BTC",
      options: {
          filter: false,
          sort: false,
          customBodyRender: (val, ...row) => {
            return(<>
                <TableRow className="tr2Taxas">
                    <TableCell className="tdTaxas">{String((val*100).toFixed(2)).replace('.',',') +'%'}</TableCell>
                    <TableCell className="tdTaxas">{String((row[0].rowData[16]*100).toFixed(2)).replace('.',',') +'%'}</TableCell>
                </TableRow>
                </>)
          },
          customHeadRender: ({ index, label, ...column }, tableMeta) => (
            <TableCell key={index} className="thTaxas">
                CORRETAGENS
                <TableRow className="tr2Taxas">
                    <TableCell className="th2Taxas">{label}</TableCell>
                    <TableCell className="th2Taxas">TERMO</TableCell>
                </TableRow>
            </TableCell>
        ),
      },
  },
  {
      name: "brokerage_term",
      label: "Termo",
      options: {
          filter: false,
          sort: false,
          display: false
        },
  },
  {
      name: "status_description",
      label: "Status",
      options: {
          filter: true,
          sort: true,
      },
  },
  {
    name: "id",
    label: "id",
    options: {
        filter: false,
        sort: false,
        display: false
    },
},
];

// Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
const AddButton = () => (
  <Tooltip disableFocusListener title="Copiar para a área de transferência">
      <IconButton onClick={copyToClipboard}>
          <ContentCopyIcon />
      </IconButton>
  </Tooltip>
);

const options = {
  filterType: "multiselect",
  download: true,
  print: false,
  responsive: "vertical",
  tableBodyHeight: "72vh",
  selectableRowsHideCheckboxes: user.type > 2 ? true : false ,
  selectableRows: 'multiple',
  viewColumns: false,
  textLabels: {
      body: {
          noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
          next: "Próxima",
          previous: "Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
      },
      toolbar: {
          search: "Pesquisar",
          downloadCsv: "Download XLSX",
          print: "Imprimir",
          viewColumns: "Ver colunas",
          filterTable: "Filtrar",
      },
      filter: {
          all: "Todos",
          title: "Filtros",
          reset: "Limpar",
      },
      viewColumns: {
          title: "Mostrar Colunas",
          titleAria: "Mostrar/Ocultar colunas",
      },
      selectedRows: {
          text: "linha(s) selecionadas",
          delete: "Excluir",
          deleteAria: "Excluir linhas Selecionadas",
      },
  },
  downloadOptions: {
      filename: "previa_receita.csv",
      separator: ";",
  },
  onDownload: () => {
      cashForwardsExport();
      return false;
  },
  customToolbar: AddButton,
  onRowClick: (rowData, rowMeta) => {
    //   if (user.type === 1){
    //       setType({ name: "cashForwardEdit", data: rowData });
    //       setShow(true);
    //   }
  },
  setTableProps: () => {
    return {
        padding: 'none',
        // material ui v4 only
        size: 'small'
    };
  },
  customToolbarSelect: (selectedRows, displayData, setSelectedRows, ) => (
    <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} statusCashForwards={statusCashForwards}  setSelectedStatus={setSelectedStatus} setSelectedLines={setSelectedLines} handleUpdateStatus={handleUpdateStatus} />
  ),
};

const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

return (
    <div style={{ maxWidth: "95%", marginTop: "2rem", marginLeft: "2.5rem"}}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable title={"Operações Termo Caixa"} data={myCashForwards} columns={columns} options={options}></MUIDataTable>
      </ThemeProvider>
    </div>
);
    
}