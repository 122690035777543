import React, { useEffect, useState } from 'react'
import { ArrowLoader } from '../components/loaders/arrowLoader'
import { useAuth } from '../contexts/useAuth'
import AppRoutes from './app.routes'


export function Preloader() {
    
    const [timeLoading, setTimeLoading] = useState(4)
    const { tokenKey } = useAuth()

    async function sleep() {
        await new Promise(resolve => setTimeout(resolve, 1000))
        setTimeLoading(0)
    }
    
    useEffect(()=> {

      sleep()

    },[])

    if (timeLoading > 0 || (tokenKey === undefined || tokenKey === '' || !tokenKey)) {
        return (
            <div className='preloaderHome'>
                <ArrowLoader />
            </div>
        )
    }else {
        return (
            <AppRoutes />
        )
    }

}