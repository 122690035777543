export function validyFiles(file, accept){
    
    let error = false    
    if((!accept) && (file?.type === 'application/x-zip-compressed' || file?.type?.indexOf('video') > -1 || file?.type === 'application/x-msdownload')){

        alert('Tipo de arquivo não aceito, tipos aceitos são: excel, PDF, texto ou imagem')
        error = true
    }
    if(accept === 'excel' && file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file?.type !== 'application/vnd.ms-excel'
    && file?.type !== 'application/wps-office.xls'
    && file?.type !== 'application/wps-office.xlsx'){
        alert('Tipo de arquivo selecionado não é válido, selecione um arquivo Excel.')
        error = true
    }

    return error
}