import React, { useEffect, useState } from 'react'

import { api } from '../../../services/api'
import { useAuth } from '../../../contexts/useAuth'
import { useModal } from '../../../contexts/useModal'
import { getAlocacaoCalendarProducts } from '../../../services/asyncFunctions'
import { datetimeToDatePtBR } from '../../../utils/formatDate/dateTimeToPTBR'
import { MdPlace } from 'react-icons/md'

import './styles/dashboardAdvisorProductsCalendar.css'
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
import { AiFillCloseCircle, AiOutlineCloseCircle } from 'react-icons/ai'

import { SerialDateToJSDate } from '../../../utils/formatDate/dateSerialToISO_EnUs'

export function CalendarProductsResponseModal(props) {

    const [loading, setLoading] = useState(false)
    const {tokenKey} = useAuth();
    const { type, show, setShow, setType } = useModal()
    const [listProductsCalendar, setListProductsCalendar] = useState([])
    const [response, setResponse] = useState(false)
    const token = localStorage.getItem('@auth:token');

    const [calendarCells, setCalendarCells] = useState([]);
    const [calendarList, setCalendarList] = useState([]);
    const [calendarBuild, setCalendarBuild] = useState([]);

    async function getDashboardProductsCalendar() {
        setLoading(true)
        const listCalendar = await getAlocacaoCalendarProducts(tokenKey, false);
        if (getAlocacaoCalendarProducts?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getAlocacaoCalendarProducts?.error,
                },
            });
            setShow(true);
            setListProductsCalendar([]);
            setLoading(false)
        } else {
            setListProductsCalendar(listCalendar.data);
            setLoading(false)
        }
    }

    useEffect(() => {
        getDashboardProductsCalendar();
    }, []);

    useEffect(() => {
        if (listProductsCalendar.length > 0) {
            setupCalendar();
        }
    }, [listProductsCalendar]);

    function getWeekdaysInMonth(year, month) {
        const days = [];
    
        let date = new Date(year, month, 1);
        while (date.getMonth() === month) {
            if (![0, 6].includes(date.getDay())) days.push(date.getDate());
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function getWeek(date) {
        let monthStart = new Date(date);
        monthStart.setDate(0);
        let offset = (monthStart.getDay() + 1) % 7 - 1;
        return Math.ceil((date.getDate() + offset) / 7);
    }

    // JSON
    // 1. Índices: Semana -> Dia -> Produto

    function setupCalendar() {
        var curDate = new Date();

        function getBusinessDays(year, month) {
            const startDate = new Date(year, month, 1);
            const endDate = new Date(year, month + 1, 0);
            const daysInMonth = endDate.getDate();
            const businessDays = [];
          
            let currentWeek = [];
            let dayOfWeek = startDate.getDay();
          
            for (let day = 1; day <= daysInMonth; day++) {
              if (dayOfWeek > 0 && dayOfWeek < 6) { // Verifica se é um dia útil (de segunda a sexta-feira)
                currentWeek.push(day);
              }
          
              if (dayOfWeek === 5 || day === daysInMonth) { // Verifica se é sexta-feira ou o último dia do mês
                businessDays.push(currentWeek);
                currentWeek = [];
              }
          
              dayOfWeek = (dayOfWeek + 1) % 7; // Incrementa o dia da semana
            }
          
            return businessDays;
        }
        var businessDays = getBusinessDays(new Date().getFullYear(), new Date().getMonth());

          // setando os itens de cada dia

        var getDaysArray = function(start, end) {
            for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt).getDate());
            }
            return arr;
        };

        listProductsCalendar.forEach(produto => {
            // Detalha os dias úteis do produto
            var productInterval = getDaysArray(SerialDateToJSDate(produto['start']), SerialDateToJSDate(produto['end'])); // dias do produto X
            
            productInterval.forEach(productWeek => {
                console.log(productInterval, productWeek);
                    if(productInterval.includes(productWeek)){
                        console.log('inclui produto ' + produto.description);
                    }
                });
            });        
    }

    const dadosTeste = [
        {
        monthDay: 1,
        products: {
            l1: 'Produto 1',
            l2: 'Produto 2',
            l3: 'Produto 3',
            l4: 'Produto 4'
            }
        },
        {
        monthDay: 2,
        products: {
            l1: 'Produto 1',
            l2: 'Produto 2',
            l3: 'Produto 3',
            l4: 'Produto 4'
            }
        },
        {
        monthDay: 3,
        products: {
            l1: 'Produto 1',
            l2: 'Produto 2',
            l3: 'Produto 3',
            l4: 'Produto 4'
            }
        }
];

    let dadosTesteList = [];

    dadosTeste.forEach((data, index) =>{
        dadosTesteList.push(
        <tr>
            <th key={index}>{data.monthDay}</th>
            <th key={index} style={{backgroundColor: 'orange'}}>{data.products.l1}</th>
            <th key={index} style={{backgroundColor: 'orange'}}>{data.products.l2}</th>
            <th key={index} style={{backgroundColor: '#8ED1FC'}}>{data.products.l3}</th>
            <th key={index} style={{backgroundColor: '#8BC34A'}}>{data.products.l4}</th>
        </tr>
        )
    })
    
    
    useEffect(() => {
        setupCalendar();
        setResponse(true);
    }, [show])

    return (
        <div className='dashboardAdvisorProductsCalendar'>
            {
                loading ?
                    (
                            <ArrowLoader />
                    ) : (
                        <>
                            <div className='dashboardAdvisorProductsCalendarTable'>
                            <div className='titleModalProductsCalendar'>
                                <div></div>
                                <div style={{fontSize: '32px'}}>Calendário de Produtos - Junho 2023</div>
                                <button
                                onClick={() => setShow(false)}
                                className="modalCloseButtonDashboard" style={{marginRight: '12px'}}>
                                    <AiFillCloseCircle />
                                </button>
                            </div>
                                <tr className='weekDayProductsCalendar'>
                                    <th></th>
                                    <th>Segunda-feira</th>
                                    <th>Terça-feira</th>
                                    <th>Quarta-feira</th>
                                    <th>Quinta-feira</th>
                                    <th>Sexta-feira</th>
                                </tr>
                                    <div className='headProductsCalendar'>
                                    {/* Até o próximo checkpoint: conteúdo que deve ser iterado */}
                                    <div className='weekCountProductsCalendar'> SEMANA 1 </div>
                                    <table className='weekProductsCalendarTable'>
                                        <tr> 
                                            <th>&nbsp;</th>
                                            <th style={{backgroundColor: 'orange'}}>ASSESSORES</th>
                                            <th style={{backgroundColor: 'orange'}}>ASSESSORES</th>
                                            <th style={{backgroundColor: '#8ED1FC'}}>MESA RV</th>
                                            <th style={{backgroundColor: '#8BC34A'}}>OFFSHORE</th>
                                        </tr>
                                        {dadosTesteList}
                                        <tr>
                                            <th>CÉLULA DIA</th>
                                            <th>LINHA 1</th>
                                            <th>LINHA 2</th>
                                            <th>LINHA 3</th>
                                            <th>LINHA 4</th>
                                        </tr>
                                        <tr>
                                            <th>CÉLULA DIA</th>
                                            <th>LINHA 1</th>
                                            <th>LINHA 2</th>
                                            <th>LINHA 3</th>
                                            <th>LINHA 4</th>
                                        </tr>
                                    </table>
                                    {/* Fim checkpoint */}
                                </div>
                            
                            { 
                            // listCalendar.map((x) => {
                            //     let newTime = new Date(x.time).toTimeString().split(' ');
                            //     return (
                            //         <tr>
                            //         <tr className='listProductsCalendar'>
                            //         <th>{datetimeToDatePtBR(x.time)} - {newTime[0].slice(0, 5)}h</th>
                            //         <th>{x.description}</th>
                            //         <th>{x.local !== 'Online' ? <MdPlace/> : ''}{x.local}</th>
                            //         <th><a href={x.link}>Acessar</a></th>
                            //         </tr>
                            //         <hr></hr>
                            //         </tr>
                            //     )
                            // })
                            }
                            </div>
                        </>
                    )
            }
        </div>
    )

}