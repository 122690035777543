/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe TECH RV
 */

 import React, { useState, useEffect } from 'react';
 /*Styles*/
 import { makeStyles } from '@material-ui/core/styles';
 import Step from '@material-ui/core/Step';
 import StepLabel from '@material-ui/core/StepLabel';
 import Button from '@material-ui/core/Button';
 import Typography from '@material-ui/core/Typography';
//  import './abcModal.css';
 /*Utils*/
 import XLSX from 'xlsx';
 import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
 import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
 import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
 /*Components*/
 import DatePicker from 'react-date-picker';
 import Stepper from '@material-ui/core/Stepper';
 import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
 /*Contexts*/
 import { useModal } from '../../../../contexts/useModal';
 /*Services*/
 import { api } from '../../../../services/api';
 import { validyFiles } from '../../../../utils/validyTypesOfFiles';
 import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
 import { FiArrowDown } from 'react-icons/fi';
 
 const useStyles = makeStyles((theme) => ({
 root: {
     "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
         background: 'red'
     },
     fontFamily: 'Roboto',
     width: '100%',
     display: 'flex',
     flexDirection: 'column',
     justifyContent: 'center',
     alignItems: 'center',
     fontWeight: '400'
 },
 
 stepperItems: {
     display: 'flex',
     padding: '0.5rem',
     flexDirection: 'row',
     alignItems: 'center',
     justifyContent: 'center',
     width: '100%',
 },
 
 '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center'
 },
 backButton: {
     marginRight: theme.spacing(1),
 },
 instructions: {
     // marginTop: theme.spacing(1),
     marginBottom: theme.spacing(1),
     fontFamily: 'Roboto',
     fontSize: '0.9rem !important'
 },
 }));
 
 function getStepContent(stepIndex) {
     switch (stepIndex) {
         case 0:
             return 'Selecione a planilha no botão acima';
         default:
             return '';
     }
 }
 
  export function BooksOrdersImport(props) {
     const classes = useStyles();
     const [activeStep, setActiveStep] = React.useState(0);
     const [fileName, setFilename] = useState('');
     const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
     const [dataExcelAPI, setDataExcelAPI] = useState([]);
     const [excelRows, setExcelRows] = useState([]);
     const [errorList, setErrorsList] = useState([]);
     const [successImport, setSuccessImport] = useState(false);
     const [hideButtons, setHideButtons] = useState(false);
     const [loading, setLoading] = useState(false);
     const { show, setShow, setType, setEdited } = useModal();
     const token = localStorage.getItem('@auth:token');
     const [errorsImport, setErrorsImport] = useState(false);
     const [showErrors, setShowErrors] = useState(false);
     const [showFileButton, setShowFileButton] = useState(true);
     const [importPlainMessage, setImportPlainMessage] = useState('');
     const [dateExec, setDateExec] = useState('');
     const fixedDate = new Date();
     const { screenX, screenY } = ModalSizeFitToScreen();
 
     useEffect(() => {
 
         setShowFileButton(true);
         setShowErrors(false);
         setActiveStep(0);
         setExcelRows([]);
         setFilename('');
         setSuccessImport(false);
         setErrorsImport(false);
 
     }, [show])
 
     const handleNext = async (propsNext) => {
 
         setActiveStep((prevActiveStep) => prevActiveStep + 1);
         if (activeStep === 1) {
             importIndications(dataExcelAPI)
         }
     }
 
     async function importIndications(dataFields) {
         setLoading(true);
         try {
             const importAPI = new Promise(async (resolve, reject) => {
                 try {
                     const importResults = await api.post(`/api/v1/booksOrdersTemp/import`, {
                         'data' : dataFields
                     }, {
                         headers: {
                             authorization: `Bearer ${token}`
                         }
                     })
                     resolve(importResults)
                 } catch (error) {
                     reject(error)
                 }
             })
 
             let inProcessLoading = true;
             let timeCount = 0
 
             do {
                 setHideButtons(true);
                 if (timeCount == 5) {
                     setImportPlainMessage(`A planilha está em processo de importação.`)
                 } else if (timeCount < 10) {
                     setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                 } else if (timeCount < 15) {
                     setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                 }
 
                 importAPI.then((value) => {
                     props.prop.forceUpdate()
                     inProcessLoading = false;
                     setHideButtons(false);
                     setLoading(false);
                     setSuccessImport(true);
                     if (value?.data?.errors_details?.length > 0) {
                         setErrorsImport(true);
                         setErrorsList(value?.data?.errors_details);
                     } else {
                         setType({ name: 'alertMsg', type: 'information', value: { message: 'Lista da boleta importada com sucesso!' } })
                     }
                     setEdited(true)
                 }).catch((error) => {
                     inProcessLoading = false;
                     if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                         setErrorsList([{ error: error?.response?.data?.message }]);
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsImport(true);
                     } else {
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsList(error?.response?.data?.errors_details);
                         setErrorsImport(true);
                     }
                 })
                 await new Promise(resolve => {
                     setTimeout(() => {
                         timeCount += 1
                         resolve()
                         if (timeCount == 20) timeCount = 0
                     }, 1000)
                 })
             } while (inProcessLoading == true);
         } catch (error) {
             alert('Erro inesperado.')
             setLoading(false);
         }
     }
 
     async function readExcelFile(file) {
 
         setLoading(true);
 
         const promise = new Promise((resolve, reject) => {
             const fileReader = new FileReader();
             fileReader.readAsArrayBuffer(file);
 
             fileReader.onload = (e) => {
                 const bufferArray = e.target.result;
                 const wb = XLSX.read(bufferArray, { type: 'buffer' });
                 const wsname = wb.SheetNames[0];
                 const ws = wb.Sheets[wsname];
                 const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                 const schemaToValidate = [
                     { excelValue: 'Código', bdValue: 'Codigo' },
                     { excelValue: 'Usuário', bdValue: 'Usuario' },
                     { excelValue: 'ID Book', bdValue: 'IdBook' },
                     { excelValue: 'Book', bdValue: 'Book' },
                     { excelValue: 'ID Import Book', bdValue: 'IdImport' },
                     { excelValue: 'Ativo', bdValue: 'Ativo' },
                     { excelValue: 'Qtd', bdValue: 'Qtd' },
                     { excelValue: 'Valor', bdValue: 'Valor' },
                     { excelValue: 'Comissão', bdValue: 'Comissao' },
                     { excelValue: 'Compra?', bdValue: 'Compra' },
                     { excelValue: 'Vencimento', bdValue: 'Vencimento' },
                     { excelValue: 'ID Referência Ativo Import', bdValue: 'IdReferencia' }
                 ];
                 let indexIgnored = 0;
                 console.log(fieldsInExcel[0])
                 const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                    if(fieldToValidate != "" && fieldToValidate != ""){
                        if (fieldToValidate !== schemaToValidate[index-indexIgnored]?.excelValue) {
                            console.log(fieldToValidate, 'WRONG')
                            return fieldToValidate
                        }
                    }
                    else{
                        indexIgnored++
                    }
                 })
 
                 if (errors?.length > 0) {
                     console.log(errors)
                     alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                     setLoading(false);
                     return
                 }
                 else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['Codigo', 'Usuario', 'IdBook', 'Book', 'IdImport', 'Ativo', 'Qtd', 'Valor', 'Comissao', 'Compra', 'Vencimento', 'IdReferencia'],
                        cellDates: false
                    });
                    resolve(data)
                 }
             }
             fileReader.onerror = ((error) => {
                reject(error);
            })
         })
 
         var data = await promise
         data.splice(0, 1)
         
         setExcelRows(data);
 
         let newFormattedData = []
         
         data.map((data) => {
             
            newFormattedData.push({
                 'books_id': parseInt(data.IdBook),
                 'books_import_id': parseInt(data.IdImport),
                 'client_id': parseInt(data.Codigo),
                 'user_id': parseInt(data.Usuario),
                 'qty': parseInt(data.Qtd),
                 'value': parseFloat(data.Valor) / parseInt(data.Qtd),
                 'comission': parseFloat(data.Comissao),
                 'operation_type_id': data.Compra?.toUpperCase() == "SIM" ? 1 : 0,
                 'asset': data.Ativo,
                 'reference': parseInt(data.IdReferencia),
                 'expiration': data.Vencimento
             });
         });
         //  setExcelRows(newFormattedData);
         setDataExcelAPI(newFormattedData);
         console.log(newFormattedData)
         return setLoading(false);
     }
 
     const handleBack = () => {
         setActiveStep((prevActiveStep) => prevActiveStep - 1);
         setSuccessImport(false)
         setErrorsImport(false)
         setErrorsList([])
         setShowErrors(false)
         setHideButtons(false)
         setShowFileButton(true)
         setFilename('')
         setDataExcelAPI([])
         setExcelRows([])
     };
 
     const handleReset = () => {
         setActiveStep(0)
         setSuccessImport(false)
         setErrorsImport(false)
         setErrorsList([])
         setShowErrors(false)
         setHideButtons(false)
         setShowFileButton(true)
         setFilename('')
         setDataExcelAPI([])
         setExcelRows([])
     };
 
     function handleFileCheck() {
         if (excelRows.length > 0) {
         // if(dateExec != ''){
             handleNext()
             setShowFileButton(false);
         // }
         // else{
         //     alert("Insira uma data de execução \n")
         // }
         }
         else {
         let msg = ''
         msg = msg + 'Carregue a planilha antes \n'
         // if(dateExec == ''){
         //     msg = msg + 'Insira uma data de execução \n'
         // }
             alert(msg)
         }
     }
 
     function fileClick() {
 
         document.getElementById('getFile').value = '';
         document.getElementById('getFile').click()
         setSuccessImport(false);
         setErrorsImport(false);
         setShowErrors(false)
     }
 
     function tableForImportReccords() {
         return (
             excelRows?.length > 0 ?
                 (<table id='tableForImportReccords'>
                     <thead id='bodyImport'>
                         <tr>
                             <th>Código</th>
                             <th>Usuário</th>
                             <th>Book</th>
                             <th>Ativo</th>
                             <th>Qtd</th>
                             <th>Valor</th>
                             <th>Comissão</th>
                             <th>Compra?</th>
                             <th>Vencimento</th>
                         </tr>
                     </thead>
                     <tbody id='bodyImport'>
                        {excelRows?.map((excelLine, indexLine) => {
                            const fieldKeys = Object.keys(excelLine)
                            return (
                                <tr>
                                    <td>{excelLine.Codigo}</td>
                                    <td>{excelLine.Usuario}</td>
                                    <td>{excelLine.Book}</td>
                                    <td>{excelLine.Ativo}</td>
                                    <td>{excelLine.Qtd}</td>
                                    <td>{excelLine.Valor ? toBRL(excelLine.Valor) : '-'}</td>
                                    <td>{excelLine.Comissao ? toBRL(excelLine.Comissao) : '-'}</td>
                                    <td>{excelLine.Compra}</td>
                                    <td>{excelLine.Vencimento}</td>
                                </tr>
                            )
                        })}
                     </tbody>
                 </table>) :
                 (<></>)
 
         )
     }
 
     function errorsTable() {
         if (errorList?.length > 0 && errorList[0].error !== undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     <td>{errorDetail.error}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
         if (errorList?.length > 0 && errorList[0].error_message === undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     <td>{errorDetail}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
 
     }
 
     async function getLayout() {
 
         try {
             const getLayouts = await api.get(`/api/v1/booksOrdersTemp/template`, {
                 headers: {
                     authorization: `Bearer ${token}`
                 },
                 responseType: 'blob'
             })
     
             const url = URL.createObjectURL(new Blob([getLayouts.data]))
             const link = document.createElement('a')
             link.href = url
             link.setAttribute('download', 'layout_importação_books_orders.xlsx')
             document.body.appendChild(link)
             link.click()
         } catch (error) {
             console.log(error?.response)
             alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
         }
     }
 
     return (
         <div className='stepMainContainer' style={{
             width: screenX,
             height: screenY
         }}>
             <div className={classes.root}>
                 <div className='stepperDiv'>
                     <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                     <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                         {steps.map((label) => (
                             <Step key={label}>
                                 <StepLabel>{label}</StepLabel>
                             </Step>
                         ))}
                     </Stepper>
                 </div>
                 <div className='fileInput'>
                     {successImport || hideButtons ?
                         (<></>) :
                         (
                             <>
                                 {showFileButton ?
                                     (
                                         <>
                                         <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                         <button className='customButton' onClick={() => getLayout()}>Arquivo Layout <FiArrowDown /> </button>
                                         <a>{fileName.substring(12, fileName.length)}</a>
                                         </>
                                     ) :
                                     (<></>)}
 
                                 <input
                                     type='file'
                                     accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                     id="getFile"
                                     onChange={(e) => {
                                         if (validyFiles(e.target.files[0], 'excel')) {
                                             return
                                         }
                                         const fileToRead = e.target.files[0];
                                         readExcelFile(fileToRead);
                                         setFilename(e.target.value)
                                     }}
                                 >
                                 </input>
                             </>
                         )}
 
                 </div>
             </div>
             <div className='fileInput'>
                 <a>Importando ordens</a>
                 {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                     successImport && !errorsImport ?
                         (
                             <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                 <a>Sucesso ao importar a lista</a>
                             </div>
                         ) :
 
                         activeStep === 1 ? (
                             (<>
                                 <p id='recommendationsImportConfirm'>Confira os dados</p>
                                 <div id='customScroll' className='tableForImportSection'>
                                     {
                                         tableForImportReccords()
                                     } </div>
                             </>)
                         ) :
                             successImport && errorsImport ? (
                                 <div id='flexRowDiv' className='stepperImportWithErrors'>
                                     <a>Sucesso ao importar arquivos!</a>
                                     <a>Mas, ocorreram erros na importação</a>
                                     <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                 </div>
                             ) : !successImport && errorsImport ?
                                 (
                                     <div id='flexRowDiv' className='stepperImportWithErrors'>
                                         <a>ocorreram erros na importação</a>
                                         <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                     </div>
                                 ) :
                                 showErrors ?
                                     (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                     :
                                     (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                 <div>
                     {activeStep === steps.length && successImport ?
                         (
                             <div id='flexRowDiv'>
                                 <Typography className={classes.instructions}>Importado com sucesso!</Typography>
                                 <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                 <Button onClick={() => setShow(false)}>Fechar</Button>
                             </div>
                         ) :
                         activeStep === steps.length && !successImport ?
                             (
                                 <div id='flexRowDiv'>
                                     <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                     <Button onClick={() => setShow(false)}>Fechar</Button>
                                 </div>
                             ) :
 
                             hideButtons ? (<></>) :
                                 (
                                     <div>
                                         <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                         <div id='flexRowDiv'>
                                             <Button
                                                 disabled={activeStep === 0}
                                                 onClick={handleBack}
                                                 className={classes.backButton}
                                             >
                                                 Voltar
                                             </Button>
                                             <Button
                                                 id='buttonImport'
                                                 variant="contained"
                                                 className='customButton'
                                                 onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                 {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                             </Button>
                                         </div>
                                     </div>
                                 )}
                 </div>
             </div>
         </div>
     );
  }