import React, { useState, useEffect } from 'react'
/*Styles*/
import './styles/tradeIdeas.css'
/*Utils*/
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
/*Contexts*/
import { type, setType, show, setShow, useModal } from '../../../contexts/useModal'
import { api } from '../../../services/api';
import { useAuth } from '../../../contexts/useAuth';
/*Components*/
import { ArrowLoader } from '../../../components/loaders/arrowLoader';

export function TradeIdeasText() {

    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const { screenX, screenY } = ModalSizeFitToScreen()
    const { show, setShow, type, setType, setEdited } = useModal()
    const { tokenKey } = useAuth()
    const [previewText, setPreviewText] = useState('')
    const [title, setTitle] = useState('')

    useEffect(() => {

        setTitle('')
        const sampleMarkup = type.textMessage === undefined ? '<a></a>' : type.textMessage
        
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
            );
            
        setEditorState(() => EditorState.createWithContent(state))
    }, [show])

    useEffect(() => {
        setPreviewText(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [editorState])

    async function handleSubmitNewText() {

        if (title === '') {
            return alert('Digite o Titulo para concluir o cadastro')
        }

        let newText = previewText

        while (newText.indexOf(`"`) > -1) {

            newText = newText.replace(/"/, '!!!!')

        }

        try {
            setLoading(true)
            const textSend = await api.post(`/api/v1/tradeIdeas`, {
                title: type.textName !== undefined ? type.textName : title,
                details: newText
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto cadastrado com sucesso!' } });
            setShow(true);
            setEdited(true)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setType({
                name: 'alertMsg', type: 'information', value: {
                    message: error?.response?.data?.message === undefined ?
                        'Erro ao enviar novo texto' :
                        error?.response?.data?.message
                }
            })
            setShow(true)
        }
    }

    async function updateIdea() {

        setLoading(true)
        let newText = previewText

        while (newText.indexOf(`"`) > -1) {

            newText = newText.replace(/"/, '!!!!')

        }
        console.log(
            {
                title: type.textName,
                details: newText
            }
        )
        try {
            const updateText = await api.put(`/api/v1/tradeIdeas/${type.tradeIdeaId}`, {
                title: type.textName,
                details: newText
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })

            setType({ name: 'alertMsg', type: 'information', value: { message: 'Texto atualizado com sucesso!' } })
            setShow(true)
            setLoading(false)
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({
                name: 'alertMsg', type: 'information', value: {
                    message: error?.response?.data?.message === undefined ?
                        'Erro ao atualizar texto' : error?.response?.data?.mesage
                }
            })
            setShow(true)
            setLoading(false)
        }
    }

    return (
        loading ?
            <div id='tradeIdeaLoading' style={{ width: screenX, height: screenY }}>
                <ArrowLoader />
            </div>
            :
            <div id='tradeIdeasTextContainer' style={{ width: screenX, height: screenY }}>
                <div id='tradeIdeasTextHeader'>
                    <p>{type.textMessage !== undefined ? `Cadastre ou edite o texto para ${type.textName}` : 'Cadastre o texto'}</p>
                    <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                </div>
                <div id='tradeIdeasBody'>
                    <div id='tradeIdeasTitleHead' style={{ display: type.textName !== undefined ? 'none' : 'flex' }}>
                        {
                            type.textName === undefined &&
                            <>
                                <p>Digite o título que irá aparecer na tela</p>
                                <input onChange={(e) => setTitle(e.target.value)} type='text'></input>
                            </>
                        }
                    </div>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'colorPicker', 'link', 'emoji', 'list', 'textAlign',
                                'remove', 'history'],
                        }}
                    />
                </div>
                <div id='tradeIdeasFooter'>
                    <button onClick={() => {
                        type.textName === undefined ? handleSubmitNewText() : updateIdea()
                    }}>Confirmar</button>
                </div>
            </div>
    )
}