import React, { useEffect, useState } from 'react'
import '../styles/longShortInfos.css'

import { useModal } from '../../../../contexts/useModal'
import { AiFillCloseCircle } from 'react-icons/ai'
import { toBRL } from '../../../../utils/formatNumberAndText/toBRLCurrency'
import { toPercent } from '../../../../utils/formatNumberAndText/formatNumberPercent'
import { datetimeToDatePtBR } from '../../../../utils/formatDate/dateTimeToPTBR'
import { AiFillDollarCircle, AiFillMessage, AiOutlineWhatsApp, AiOutlineCheckSquare, AiOutlineWarning } from 'react-icons/ai'

export function LongShortInfosModal(props) {
    let dataInfos = props?.prop?.data?.dataInfos[0]
    let operationStop = '';
    let tableData = props.prop.list

    if (dataInfos.operation_stop == null) {
        operationStop = 'Em Andamento';
    }
    if(dataInfos.operation_stop == true){
        operationStop = 'STOP GAIN';
    } 
    if (dataInfos.operation_stop == false){
        operationStop = 'STOP LOSS';
    } 

    const { type, show, setShow,setType } = useModal()
    const [response, setResponse] = useState(false)

    useEffect(() => {
        setResponse(true)
    }, [show])

    return (
        <div className='mainLongShortInfos'>
            {
                response ?
                    (
                        <>
                        <div>
                                <div className='titleModalLongShortInfos'>
                                    <div></div> 
                                    Detalhes da Operação: {dataInfos.asset_long} x {dataInfos.asset_short} - Cliente {dataInfos.clients_external_id} {dataInfos.name} 
                                    <button
                                    onClick={() => setShow(false)}
                                    className="modalCloseButtonDashboard" style={{marginRight: '12px'}}>
                                    <AiFillCloseCircle />
                                </button>
                                </div>
                                <div className='longShortInfoAcompanhamento'> 
                                    Acompanhamento da Operação 
                                </div>
                                <div>
                                    <table className='longShortTable1'>
                                        <tr>
                                            <th style={{fontWeight: 600}}>ATIVO LONG</th>
                                            <th style={{fontWeight: 600}}>COTAÇÃO LONG</th>
                                            <th style={{fontWeight: 600}}>RATIO ATUAL</th>
                                            <th style={{fontWeight: 600}}>COTAÇÃO SHORT</th>
                                            <th style={{fontWeight: 600}}>ATIVO SHORT</th>
                                        </tr>
                                        <tr>
                                            <th>{dataInfos.asset_long}</th>
                                            <th>{toBRL(dataInfos.current_long)}</th>
                                            {
                                                (dataInfos.current_ratio/dataInfos.ratio_in-1)*100 > 0 ?
                                                <th>{dataInfos.current_ratio.toFixed(3)} ({toPercent((dataInfos.current_ratio/dataInfos.ratio_in-1)*100)})</th> :
                                                <th style={{color: 'red', fontWeight: 600}}>{dataInfos.current_ratio.toFixed(3)} ({toPercent((dataInfos.current_ratio/dataInfos.ratio_in-1)*100)})</th>
                                            }
                                            <th>{toBRL(dataInfos.current_short)}</th>
                                            <th>{dataInfos.asset_short}</th>
                                        </tr>
                                    </table>
                                </div>
                                <hr style={{marginTop: '2rem'}}></hr>
                                <div className='longShortInfoAcompanhamento'> 
                                    Parâmetros da Operação
                                </div>
                                <div className='longShortInfoParameters'>
                                <div style={{placeSelf: 'center'}}>
                                    <table className='longShortTable2'>
                                        <tr>
                                            <th className='longShortTable2Th1'>DATA ENTRADA</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{datetimeToDatePtBR(dataInfos.created_at)}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>STATUS OPERAÇÃO</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.description}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>STATUS RESULTADO</th>
                                            <th style={{width: '20px'}}></th>
                                            {
                                                dataInfos.operation_stop == null ? 
                                                <th style={{ backgroundColor: 'white' }}  className='longShortTable2Th2'>{operationStop}</th> : 
                                                dataInfos.operation_stop == true ? 
                                                <th style={{ backgroundColor: 'green', fontWeight: 'bold' }}  className='longShortTable2Th2'>{operationStop}</th> :
                                                <th style={{ backgroundColor: '#CC0000', fontWeight: 'bold' }}  className='longShortTable2Th2'>{operationStop}</th>
                                            }
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>CASA DE ANÁLISE</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.research}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>ASSESSOR</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.advisor_name}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>BROKER</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.broker_name}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>RATIO ENTRADA</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.ratio_in}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>RATIO GAIN</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.ratio_gain} ({toPercent((dataInfos.ratio_in/dataInfos.ratio_gain-1)*-100)})</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>RATIO LOSS</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{dataInfos.ratio_loss} ({toPercent((dataInfos.ratio_in/dataInfos.ratio_loss-1)*-100)})</th>
                                        </tr>
                                    </table>
                                    <div style={{marginTop: '2rem'}}></div>
                                    <div className='longShortParamsOperations'>
                                        <div><AiFillMessage className="btnLongShortInfosModal" style={{
                                            width: '3rem',
                                            height: '3rem'
                                        }} onClick={() => {
                                            const order = dataInfos.observation ? dataInfos.observation : ''
                                            setType({ name: 'showLongShortResponse', data: {order} });
                                            setShow(true);
                                        }}></AiFillMessage></div>
                                        <div className="btnLongShortInfosModal" ><AiOutlineWhatsApp className="btnLongShortInfosModal" style={{
                                            width: '3rem',
                                            height: '3rem'
                                        }}
                                        onClick={() => {
                                            let atualizacaoWpp = ''
                                            let dataEncerramentoWpp = ''
                                            let ativoLongWpp = dataInfos.asset_long;
                                            let ativoShortWpp = dataInfos.asset_short
                                            let volumeWpp = toBRL(dataInfos.short_vol);
                                            let resultadoBrutoWpp = dataInfos.var_ratio*100;

                                                
                                            dataEncerramentoWpp = dataInfos.updated_at.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                                            ativoLongWpp = dataInfos.asset_long;
                                            ativoShortWpp =  dataInfos.asset_short
                                            volumeWpp = toBRL(dataInfos.short_vol);
                                            resultadoBrutoWpp = (dataInfos.var_ratio*100).toFixed(2);

                                            if(dataInfos.description === 'Encerrado'){
                                                atualizacaoWpp = 'Encerrada';
                                            } else {
                                                atualizacaoWpp = 'em andamento'
                                            }
                                            var wppText = `Segue o resumo da operação Long Short ${atualizacaoWpp} no dia ${dataEncerramentoWpp}:

    Ativo Long: ${ativoLongWpp}
    Ativo Short: ${ativoShortWpp}
    Volume: ${toBRL(volumeWpp)}
    Resultado bruto: ${resultadoBrutoWpp}%`
    navigator.clipboard.writeText(wppText)
    alert('Mensagem copiada para a área de transferência.')
                                        }}
                                        ></AiOutlineWhatsApp></div>
                                        {
                                            dataInfos.allow_over_longshort = true ?
                                        <div className="btnLongShortInfosModal" ><AiOutlineCheckSquare className="btnLongShortInfosModal" style={{
                                            width: '3rem',
                                            height: '3rem',
                                            color: 'green'
                                        }} onClick={() => {
                                            const total = tableData.filter((l) => (l.clients_external_id == dataInfos.clients_external_id) && (['Em Andamento', 'Basket Gerada'].includes(l.description))).reduce((prev, curr) => prev + curr.long_vol, 0);
                        
                                            setType({ name: 'showValidationModal', client_id: dataInfos.clients_external_id, order: dataInfos.id, limite: (dataInfos.vol_limit < dataInfos.long_vol), margem: ((dataInfos.b3guarantee - total) < dataInfos.margin), read: true });
                                            setShow(true);
                                        }}></AiOutlineCheckSquare></div> :
                                        <div className="btnLongShortInfosModal" ><AiOutlineWarning className="btnLongShortInfosModal" style={{
                                            width: '3rem',
                                            height: '3rem',
                                            color: 'yellow'
                                        }}></AiOutlineWarning></div>
                                        }
                                        <div>Observações</div>
                                        <div>Gerar Texto</div>
                                        <div>Validação</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{marginRight: '2rem'}}>
                                    <table className='longShortTable1'>
                                    <tr>
                                        <th></th>
                                        <th style={{fontWeight: 600}}>{dataInfos.asset_long}</th>
                                        <th style={{fontWeight: 600}}>{dataInfos.asset_short}</th>
                                    </tr>
                                    <tr>
                                        <th className='longShortTable2Th1'>PREÇO ENTRADA</th>
                                        <th>{toBRL(dataInfos.value_long)}</th>
                                        <th>{toBRL(dataInfos.value_short)}</th>
                                    </tr>
                                    <tr>
                                        <th className='longShortTable2Th1'>QUANTIDADE</th>
                                        <th>{dataInfos.qtd_long}</th>
                                        <th>{dataInfos.qtd_short}</th>
                                    </tr>
                                    <tr>
                                        <th className='longShortTable2Th1'>VOLUME</th>
                                        {
                                            dataInfos.long_vol > dataInfos.vol_limit ?
                                            <th style={{color: 'red', backgroundColor: '#CCCC00'}} title={"Volume da operação acima do limite de exposição do cliente!"}>{toBRL(dataInfos.long_vol)}</th> :
                                            <th>{toBRL(dataInfos.long_vol)}</th>
                                        }
                                        <th>{toBRL(dataInfos.short_vol)}</th>
                                    </tr>
                                    </table>
                                </div>
                                <hr style={{marginTop: '2rem'}}></hr>
                                <div style={{marginRight: '2rem'}}>
                                    <table className='longShortTable1'>
                                        <tr>
                                            <th className='longShortTable2Th1'>GARANTIAS DISPONÍVEIS</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{toBRL(dataInfos.b3guarantee)}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>MARGEM EXIGIDA</th>
                                            <th style={{width: '20px'}}></th>
                                            {
                                                dataInfos.margin > dataInfos.b3guarantee ?
                                                <th className='longShortTable2Th2' title={"Garantias insuficientes para a margem mínima da operação!"} style={{color: 'red', backgroundColor: '#CCCC00'}}>{toBRL(dataInfos.margin)}</th> :
                                                <th className='longShortTable2Th2'>{toBRL(dataInfos.margin)}</th>
                                            }
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>LIMITE DE EXPOSIÇÃO</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{toBRL(dataInfos.vol_limit)}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>CAIXA MONTAGEM</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{toBRL(dataInfos.operation_vol)}</th>
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>CUSTOS MONTAGEM</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{
                                            <button className="buttonEditCostLs2" onClick={() => {
                                                setType({ name: 'showLModalEditCosts', data: dataInfos, tipo: 'in'});
                                                                    setShow(true);
                                            }}><span style={{fontSize: '1rem'}}>{dataInfos.operation_costs_total_in ? toBRL(dataInfos.operation_costs_total_in)  : toBRL(0)}</span> <AiFillDollarCircle size={'18'} color={'#2D7200'}/></button>
                                            
                                            }</th> {/* lógica de custos de montagem */}
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>CUSTOS DESMONTAGEM</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{
                                            <button className="buttonEditCostLs2" onClick={() => {
                                                setType({ name: 'showLModalEditCosts', data: dataInfos, tipo: 'out'});
                                                                    setShow(true);
                                            }}><span style={{fontSize: '1rem'}}>{dataInfos.operation_costs_out ? toBRL(dataInfos.operation_costs_out)  : toBRL(0)}</span> <AiFillDollarCircle size={'18'} color={'#2D7200'}/></button>
                                            
                                            }</th> {/* lógica de custos de montagem */}
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>CUSTOS TOTAIS</th>
                                            <th style={{width: '20px'}}></th>
                                            <th className='longShortTable2Th2'>{toBRL(dataInfos.operation_costs_total_in + dataInfos.operation_costs_out)}</th> {/* lógica de custos de montagem */}
                                        </tr>
                                        <tr>
                                            <th className='longShortTable2Th1'>RESULTADO LÍQUIDO</th>
                                            <th style={{width: '20px'}}></th>
                                            {dataInfos.liq_result_total >= 0 ?
                                                <th className='longShortTable2Th2'>{toBRL(dataInfos.liq_result_total)}</th> :
                                                <th className='longShortTable2Th2' style={{color: 'red'}}>{toBRL(dataInfos.liq_result_total)}</th>
                                            }
                                        </tr>
                                    </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </>
                    ) :
                    (
                        <>
                            <div className='myReservationsHeader'>
                                <p>Resposta</p>
                                <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                            </div>
                            <div className='myReservationEmptyBody'>
                                <h4>Nenhuma resposta cadastrada</h4>
                            </div>
                        </>

                    ) 
            }
        </div>
    )

}