import React, { useState, useEffect } from 'react'

import { GraphNormal } from './graficoNormal';
import { GraphPie } from './graficoPie';

export function Graph(){
  return (
    <div style={{width: "500px",height: "500px"}}>
      <GraphPie/>
    </div>
  );
}