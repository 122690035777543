/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe Rafael Cezário
 */

 import React, { useState, useEffect } from 'react';
 /*Styles*/
 import { makeStyles } from '@material-ui/core/styles';
 import Step from '@material-ui/core/Step';
 import StepLabel from '@material-ui/core/StepLabel';
 import Button from '@material-ui/core/Button';
 import Typography from '@material-ui/core/Typography';
 import './styles/recommendationsImport.css';
 import './styles/myReservations.imports.css';
 /*Utils*/
 import XLSX from 'xlsx';
 import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
 import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
 import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
 import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
 /*Components*/
 import DatePicker from 'react-date-picker';
 import Stepper from '@material-ui/core/Stepper';
 import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
 /*Contexts*/
 import { useModal } from '../../../../contexts/useModal';
 /*Services*/
 import { api } from '../../../../services/api';
 import { validyFiles } from '../../../../utils/validyTypesOfFiles';
 import { styleClientsInput } from '../../../../components/styles/autocompletField';
 import { Switch } from '@mui/material';
 
 const useStyles = makeStyles((theme) => ({
     root: {
         "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
             background: 'red'
         },
         fontFamily: 'Roboto',
         width: '100%',
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'center',
         alignItems: 'center',
         fontWeight: '400'
     },
 
     stepperItems: {
         display: 'flex',
         padding: '0.5rem',
         flexDirection: 'row',
         alignItems: 'center',
         justifyContent: 'center',
         width: '100%',
     },
 
     '& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center'
     },
     backButton: {
         marginRight: theme.spacing(1),
     },
     instructions: {
         // marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1),
         fontFamily: 'Roboto',
         fontSize: '0.9rem !important'
     },
 }));
 
 function getStepContent(stepIndex) {
     switch (stepIndex) {
        case 0:
             return '';
         case 1:
             return 'Selecione a planilha no botão acima';
         default:
             return '';
     }
 }
 
 export function ImportValidation(props) {
    const classes2 = styleClientsInput()
     const classes = useStyles();
     const [activeStep, setActiveStep] = useState(0);
     const [fileName, setFilename] = useState('');
     const steps = ['Preencha as configurações iniciais', 'Selecione o arquivo para ser importado', 'Confira as informações'];
     const [dataExcelAPI, setDataExcelAPI] = useState([]);
     const [excelRows, setExcelRows] = useState([]);
     const [errorList, setErrorsList] = useState([]);
     const [successImport, setSuccessImport] = useState(false);
     const [successImportFirst, setSuccessImportFirst] = useState(false);
     const [hideButtons, setHideButtons] = useState(false);
     const [loading, setLoading] = useState(false);
     const { show, setShow, setType, setEdited } = useModal();
     const token = localStorage.getItem('@auth:token');
     const [errorsImport, setErrorsImport] = useState(false);
     const [showErrors, setShowErrors] = useState(false);
     const [showFileButton, setShowFileButton] = useState(true);
     const [importPlainMessage, setImportPlainMessage] = useState('');
     const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('');
     const [hubConnect, setHubConnect] = useState(false);
     const [inputFileTicker, setInputFileTicker] = useState("");
     const [inputFileTypeId, setInputFileTypeId] = useState(-1);
     const fixedDate = new Date();
     const { screenX, screenY } = ModalSizeFitToScreen();
     const [offersTickers, setOffersTickers] = useState();
     const [offersTypeNames, setOffersTypeNames] = useState();
     const [offersTypeIds, setOffersTypeIds] = useState();
 
     useEffect(() => {
 
         setShowFileButton(true);
         setShowErrors(false);
         setActiveStep(0);
         setExcelRows([]);
         setFilename('');
         setSuccessImport(false);
         setSuccessImportFirst(false);
         setErrorsImport(false);
 
     }, [show])
 
     const handleNext = async (propsNext) => {
         if(activeStep === 0){
            if(inputFileTicker != "" && inputFileTicker != null){

            }
            else{
                alert("Selecione um ativo antes de passar para a próxima etapa.")
                return 
            }
            
         }
         if (activeStep === 1) {
            if(!successImportFirst){
                alert("Importe o arquivo antes de prosseguir!")
                return
            }
         }
         if(activeStep === 2){
            if (selectedEffectiveDate !== '') {
                importBook(selectedEffectiveDate.toISOString().slice(0, 10), dataExcelAPI)
            } else {
                let yourDate = fixedDate
                let newDate = new Date(yourDate.getTime() - (yourDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0]
                importBook(newDate, dataExcelAPI)
            }
         }
         setActiveStep(activeStep+1);
     }
 
     async function importBook(dateSelected, dataFields) {
         setLoading(true);
         try {
             const importAPI = new Promise(async (resolve, reject) => {
                 try {
                    if(hubConnect){
                        // OPERAÇÃO PARA O NOVO MODELO DO HUB (RIP CONNECT)
                        // console.log('HUB NOVO', {
                        //     'offer_type': parseInt(inputFileTypeId),
                        //     'type': 'hub ipo',
                        //     'asset': inputFileTicker,
                        //     'data':
                        //         excelRows?.map((data) => {
                        //             return {
                        //                 'client_id': parseInt(data.Cliente),
                        //                 'status': data['StatusReserva'],
                        //                 'qty': parseInt(parseFloat(data['ValorQtdeReservada']) / parseFloat(data['PrecoSolicitado'])),
                        //                 'vinculo': data.Modalidade
                        //             }
                        //         })
                        // });

                        const importResult = await api.post(`/api/v1/offersOrders/validate`, {
                            'offer_type': parseInt(inputFileTypeId),
                            'type': 'hub ipo',
                            'asset': inputFileTicker,
                            'data':
                                excelRows?.map((data) => {
                                    return {
                                        'client_id': parseInt(data.Cliente),
                                        'status': data['StatusReserva'],
                                        'qty': parseInt(parseFloat(data['ValorQtdeReservada']) / parseFloat(data['PrecoSolicitado'])),
                                        'vinculo': data.Modalidade
                                    }
                                })
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            }
                        )
                        resolve(importResult);
                    }
                    else{
                        //OPERAÇÃO PARA O HUB
                        // console.log('HUB', {
                        //     'offer_type': parseInt(inputFileTypeId),
                        //     'type': 'hub',
                        //     'asset': inputFileTicker,
                        //     'data':
                        //         excelRows?.map((data) => {
                        //             return {
                        //                 'client_id': parseInt(data.CodigoCliente),
                        //                 'qty': parseInt(data.QuantidadeSolicitada),
                        //                 'vinculo': undefined,
                        //                 'status': data.Status,
                        //             }
                        //         })
                        // });
                        const importResult = await api.post(`/api/v1/offersOrders/validate`, {
                            'offer_type': parseInt(inputFileTypeId),
                            'type': 'hub dp',
                            'asset': inputFileTicker,
                            'data':
                                excelRows?.map((data) => {
                                    return {
                                        'client_id': parseInt(data.CodigoCliente),
                                        'status': data.Status,
                                        'qty': parseInt(data.QuantidadeSolicitada),
                                        'vinculo': null
                                    }
                                })
                        },
                            {
                                headers: {
                                    authorization: `Bearer ${token}`
                                }
                            }
                        )
                        resolve(importResult);
                        // console.log("AQUIIIIIIIIIIIIIIIIIII", importResult)
                    }
                 } catch (error) {
                     reject(error)
                 }
             })
 
             let inProcessLoading = true;
             let timeCount = 0
 
             do {
                 setHideButtons(true);
                 if (timeCount == 5) {
                     setImportPlainMessage(`A planilha está em processo de importação.`)
                 } else if (timeCount < 10) {
                     setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                 } else if (timeCount < 15) {
                     setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                 }
 
                 importAPI.then((value) => {
                     inProcessLoading = false;
                     setHideButtons(false);
                     setLoading(false);
                     setSuccessImport(true);
                     if (value?.data?.errors_details?.length > 0) {
                         setErrorsImport(true);
                         setErrorsList(value?.data?.errors_details);
                     } else {
                         setType({ name: 'alertMsg', type: 'information', value: { message: 'Registros importados com sucesso!' } })
                     }
                     setEdited(true)
                 }).catch((error) => {
 
                     inProcessLoading = false;
                     if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                         setErrorsList([{ error_message: error?.response?.data?.message }]);
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsImport(true);
                     } else {
                         setHideButtons(false);
                         setLoading(false);
                         setErrorsList(error?.response?.data?.errors_details);
                         setErrorsImport(true);
                     }
                 })
                 await new Promise(resolve => {
                     setTimeout(() => {
                         timeCount += 1
                         resolve()
                         if (timeCount == 20) timeCount = 0
                     }, 1000)
                 })
             } while (inProcessLoading == true);
         } catch (error) {
             alert('Erro inesperado.')
             setLoading(false);
         }
     }
 
     async function readExcelFileOfertaPublica(file) {
 
         setLoading(true);
 
         const promise = new Promise((resolve, reject) => {
             const fileReader = new FileReader();
             fileReader.readAsArrayBuffer(file);
 
             fileReader.onload = (e) => {
                 const bufferArray = e.target.result;
                 const wb = XLSX.read(bufferArray, { type: 'buffer' });
                 const wsname = wb.SheetNames[0];
                 const ws = wb.Sheets[wsname];
                 const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                 const schemaToValidate = [ //bdValue
                    { bdValue: 'Marca', excelValue: 'Marca' },
                    { bdValue: 'CodigoOferta', excelValue: 'Código Oferta' },
                    { bdValue: 'Ativo', excelValue: 'Ativo' },
                    { bdValue: 'NomeOferta', excelValue: 'Nome Oferta' },
                    { bdValue: 'StatusOferta', excelValue: 'Status Oferta' },
                    { bdValue: 'DataLiquidacao', excelValue: 'Data Liquidação' },
                    { bdValue: 'Fee', excelValue: 'Fee' },
                    { bdValue: 'Assessor', excelValue: 'Assessor' },
                    { bdValue: 'Cliente', excelValue: 'Cliente' },
                    { bdValue: 'OfertaPorQuantidade', excelValue: 'Oferta por Quantidade' },
                    { bdValue: 'ValorQtdeReservada', excelValue: 'Valor/Qtde Reserva' },
                    { bdValue: 'PrecoSolicitado', excelValue: 'Preço Solicitado' },
                    { bdValue: 'ValorQtdeExercido', excelValue: 'Valor/Qtde Exercido' },
                    { bdValue: 'Modalidade', excelValue: 'Modalidade' },
                    { bdValue: 'StatusReserva', excelValue: 'Status Reserva' },
                    { bdValue: 'Erros', excelValue: 'Erros' },
                    { bdValue: 'ValidacaoDeGarantia', excelValue: 'Validação de Garantia' }
                 ];

                 const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                     if (fieldToValidate !== schemaToValidate[index]?.excelValue) {
                         return fieldToValidate
                     }
                 })
 
                 if (errors?.length > 0) {
                     alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                     setLoading(false);
                     return
                 }
                 else {
                     const data = XLSX.utils.sheet_to_json(ws, {
                         header: ['Marca','CodigoOferta','Ativo','NomeOferta','StatusOferta','DataLiquidacao','Fee','Assessor','Cliente','OfertaPorQuantidade','ValorQtdeReservada','PrecoSolicitado','ValorQtdeExercido','Modalidade','StatusReserva','Erros','ValidacaoDeGarantia'],
                         cellDates: false
                     });
                     resolve(data)
                     setSuccessImportFirst(true)
                 }
             }
             fileReader.onerror = ((error) => {
                 reject(error);
             })
         })
 
         const data = await promise
         data.splice(0, 1)
         setExcelRows(data);
 
         let newFormattedData = []
 
         data.map((data) => {
 
             let newTargetValue = data.target_value
 
             if (typeof (data.target_value) === 'string') {
                 newTargetValue = parseFloat(data.target_value.replace(',', '.'))
             }
             newFormattedData.push({
                'Marca': data.Marca,
                'CodigoOferta': data.CodigoOferta,
                'Ativo': data.Ativo,
                'NomeOferta': data.NomeOferta,
                'StatusOferta': data.StatusOferta,
                'DataLiquidacao': data.DataLiquidacao,
                'Fee': data.Fee,
                'Assessor': data.Assessor,
                'Cliente': data.Cliente,
                'OfertaPorQuantidade': data.OfertaPorQuantidade,
                'ValorQtdeReservada': data.ValorQtdeReservada,
                'PrecoSolicitado': data.PrecoSolicitado,
                'ValorQtdeExercido': data.ValorQtdeExercido,
                'Modalidade': data.Modalidade,
                'StatusReserva': data.StatusReserva,
                'Erros': data.Erros,
                'ValidacaoDeGarantia': data.ValidacaoDeGarantia
             })
         })
         setDataExcelAPI(newFormattedData);
         return setLoading(false);
 
     }

     async function readExcelFileHUB(file) {
 
        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                const schemaToValidate = [
                    { excelValue: 'CodigoCliente', bdValue: 'CodigoCliente' },
                    { excelValue: 'IdEvento', bdValue: 'IdEvento' },
                    { excelValue: 'DataDebito', bdValue: 'DataDebito' },
                    { excelValue: 'DataEx', bdValue: 'DataEx' },
                    { excelValue: 'DataUltimoDiaNegociacao', bdValue: 'DataUltimoDiaNegociacao' },
                    { excelValue: 'Proporcao', bdValue: 'Proporcao' },
                    { excelValue: 'QuantidadeExercida', bdValue: 'QuantidadeExercida' },
                    { excelValue: 'AEfetivar', bdValue: 'AEfetivar' },
                    { excelValue: 'QuantidadeRequerida', bdValue: 'QuantidadeRequerida' },
                    { excelValue: 'IdSolicitacao', bdValue: 'IdSolicitacao' },
                    { excelValue: 'DataSolicitacao', bdValue: 'DataSolicitacao' },
                    { excelValue: 'UsuarioSolicitante', bdValue: 'UsuarioSolicitante' },
                    { excelValue: 'Status', bdValue: 'Status' },
                    { excelValue: 'QuantidadeSolicitada', bdValue: 'QuantidadeSolicitada' },
                    { excelValue: 'ErroBolsa', bdValue: 'ErroBolsa' },
                    { excelValue: 'QuantidadeDisponivel', bdValue: 'QuantidadeDisponivel' },
                    { excelValue: 'QuantidadeAdicionalSolicitada', bdValue: 'QuantidadeAdicionalSolicitada' },
                    { excelValue: 'QuantidadeAdicionalExercida', bdValue: 'QuantidadeAdicionalExercida' },
                    { excelValue: 'ValorDireito', bdValue: 'ValorDireito' },
                    { excelValue: 'CodNegociacao', bdValue: 'CodNegociacao' },
                    { excelValue: 'Marca', bdValue: 'Marca' },
                    { excelValue: 'NomeFilial', bdValue: 'NomeFilial' },
                    { excelValue: 'CodAssessor', bdValue: 'CodAssessor' }
                ];

                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {

                    if (fieldToValidate !== schemaToValidate[index]?.excelValue) {
                        return fieldToValidate
                    }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: ['CodigoCliente','IdEvento','DataDebito','DataEx','DataUltimoDiaNegociacao','Proporcao','QuantidadeExercida','AEfetivar','QuantidadeRequerida','IdSolicitacao','DataSolicitacao','UsuarioSolicitante','Status','QuantidadeSolicitada','ErroBolsa','QuantidadeDisponivel','QuantidadeAdicionalSolicitada','QuantidadeAdicionalExercida','ValorDireito','CodNegociacao','Marca','NomeFilial','CodAssessor' ],
                        cellDates: false
                    });
                    resolve(data)
                    setSuccessImportFirst(true)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        const data = await promise
        data.splice(0, 1)
        setExcelRows(data);

        let newFormattedData = []

        data.map((data) => {

            let newTargetValue = data.target_value

            if (typeof (data.target_value) === 'string') {
                newTargetValue = parseFloat(data.target_value.replace(',', '.'))
            }
            newFormattedData.push({
                'CodigoCliente': data.CodigoCliente,
                'IdEvento': data.IdEvento,
                'DataDebito': data.DataDebito,
                'DataEx': data.DataEx,
                'DataUltimoDiaNegociacao': data.DataUltimoDiaNegociacao,
                'Proporcao': data.Proporcao,
                'QuantidadeExercida': data.QuantidadeExercida,
                'AEfetivar': data.AEfetivar,
                'QuantidadeRequerida': data.QuantidadeRequerida,
                'IdSolicitacao': data.IdSolicitacao,
                'DataSolicitacao': data.DataSolicitacao,
                'UsuarioSolicitante': data.UsuarioSolicitante,
                'Status': data.Status,
                'QuantidadeSolicitada': data.QuantidadeSolicitada,
                'ErroBolsa': data.ErroBolsa,
                'QuantidadeDisponivel': data.QuantidadeDisponivel,
                'QuantidadeAdicionalSolicitada': data.QuantidadeAdicionalSolicitada,
                'QuantidadeAdicionalExercida': data.QuantidadeAdicionalExercida,
                'ValorDireito': data.ValorDireito,
                'CodNegociacao': data.CodNegociacao,
                'Marca': data.Marca,
                'NomeFilial': data.NomeFilial,
                'CodAssessor': data.CodAssessor
            })
        })
        setDataExcelAPI(newFormattedData);
        return setLoading(false);

    }
 
     const handleBack = () => {
        setShowErrors(false)
        setExcelRows([]);
        setFilename('');
        setShowFileButton(true);
        setSuccessImport(false) 
        setSuccessImportFirst(false) 
        setErrorsImport(false)
        setInputFileTicker("")
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };
 
     const handleReset = () => {
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setExcelRows([]);
        setFilename('');
        setShowFileButton(true);
         setSuccessImport(false);
         setSuccessImportFirst(false);
         setActiveStep(0);
     };
 
     function handleFileCheck() {
         if (excelRows.length > 0) {
             handleNext()
             setShowFileButton(false);
         }
         else {
             alert('Carregue a planilha antes')
         }
     }
 
     function fileClick() {
 
         document.getElementById('getFile').value = '';
         document.getElementById('getFile').click()
         setSuccessImport(false);
         setErrorsImport(false);
         setShowErrors(false)
     }
 
     function formatLineToApi(value, type) {
 
         switch (type) {
             case 'number':
                 return parseFloat(value)
             default:
                 return value
         }
     }
 
     function tableForImportReccordsHUB() {
         return (
             excelRows?.length > 0 ?
                 (<table id='tableForImportReccords'>
                     <thead id='bodyImport'>
                         <tr>
                             <th>CodigoCliente</th>
                             <th>IdEvento</th>
                             <th>DataDebito</th>
                             <th>DataEx</th>
                             <th>DataUltimoDiaNegociacao</th>
                             <th>Proporcao</th>
                             <th>QuantidadeExercida</th>
                             <th>AEfetivar</th>
                             <th>QuantidadeRequerida</th>
                             <th>IdSolicitacao</th>
                             <th>DataSolicitacao</th>
                             <th>UsuarioSolicitante</th>
                             <th>Status</th>
                             <th>QuantidadeSolicitada</th>
                             <th>ErroBolsa</th>
                             <th>QuantidadeDisponivel</th>
                             <th>QuantidadeAdicionalSolicitada</th>
                             <th>QuantidadeAdicionalExercida</th>
                             <th>ValorDireito</th>
                             <th>CodNegociacao</th>
                             <th>Marca</th>
                             <th>NomeFilial</th>
                             <th>CodAssessor</th>
                         </tr>
                     </thead>
                     <tbody id='bodyImport'>
                         {excelRows?.map((excelLine, indexLine) => {
                             const fieldKeys = Object.keys(excelLine)
 
                             return (
                                 <tr>
                                     <td>{excelLine.CodigoCliente}</td>
                                     <td>{excelLine.IdEvento}</td>
                                     <td>{excelLine.DataDebito}</td>
                                     <td>{excelLine.DataEx}</td>
                                     <td>{excelLine.DataUltimoDiaNegociacao}</td>
                                     <td>{excelLine.Proporcao}</td>
                                     <td>{excelLine.QuantidadeExercida}</td>
                                     <td>{excelLine.AEfetivar}</td>
                                     <td>{excelLine.QuantidadeRequerida}</td>
                                     <td>{excelLine.IdSolicitacao}</td>
                                     <td>{excelLine.DataSolicitacao}</td>
                                     <td>{excelLine.UsuarioSolicitante}</td>
                                     <td>{excelLine.Status}</td>
                                     <td>{excelLine.QuantidadeSolicitada}</td>
                                     <td>{excelLine.ErroBolsa}</td>
                                     <td>{excelLine.QuantidadeDisponivel}</td>
                                     <td>{excelLine.QuantidadeAdicionalSolicitada}</td>
                                     <td>{excelLine.QuantidadeAdicionalExercida}</td>
                                     <td>{excelLine.ValorDireito}</td>
                                     <td>{excelLine.CodNegociacao}</td>
                                     <td>{excelLine.Marca}</td>
                                     <td>{excelLine.NomeFilial}</td>
                                     <td>{excelLine.CodAssessor}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>) :
                 (<></>)
 
         )
     }

     function tableForImportOfertaPublica() {
        return (
            excelRows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Marca</th>
                            <th>Código Oferta</th>
                            <th>Ativo</th>
                            <th>Nome Oferta</th>
                            <th>Status Oferta</th>
                            <th>Data Liquidação</th>
                            <th>Fee</th>
                            <th>Assessor</th>
                            <th>Cliente</th>
                            <th>Oferta por Quantidade</th>
                            <th>Valor/Qtde Reserva</th>
                            <th>Preço Solicitado</th>
                            <th>Valor/Qtde Exercido</th>
                            <th>Modalidade</th>
                            <th>Status Reserva</th>
                            <th>Erros</th>
                            <th>Validação de Garantia</th>
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>
                        {excelRows?.map((excelLine, indexLine) => {
                            const fieldKeys = Object.keys(excelLine)
                            return (
                                <tr>
                                    <td>{excelLine.Marca}</td>
                                    <td>{excelLine.CodigoOferta}</td>
                                    <td>{excelLine.Ativo}</td>
                                    <td>{excelLine.NomeOferta}</td>
                                    <td>{excelLine.StatusOferta}</td>
                                    <td>{new Date(Math.round((excelLine.DataLiquidacao - 25569)*86400*1000)).toLocaleDateString()}</td>
                                    <td>{excelLine.Fee}</td>
                                    <td>{excelLine.Assessor}</td>
                                    <td>{excelLine.Cliente}</td>
                                    <td>{excelLine.OfertaPorQuantidade}</td>
                                    <td>{excelLine.ValorQtdeReservada}</td>
                                    <td>{excelLine.PrecoSolicitado}</td>
                                    <td>{excelLine.ValorQtdeExercido}</td>
                                    <td>{excelLine.Modalidade}</td>
                                    <td>{excelLine.StatusReserva}</td>
                                    <td>{excelLine.Erros}</td>
                                    <td>{excelLine.ValidacaoDeGarantia}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }
 
     function errorsTable() {

         if (errorList?.length > 0 && errorList[0].error !== undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     <td>{errorDetail.error}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
         if (errorList?.length > 0 && errorList[0].error === undefined) {
             return (
                 <table id='tableErrorsImportOperation'>
                     <thead>
                         <tr>
                             <th>Mensagem</th>
                         </tr>
                     </thead>
                     <tbody>
                         {errorList?.map((errorDetail) => {
                             return (
                                 <tr>
                                     <td>{errorDetail.error_message}</td>
                                 </tr>
                             )
                         })}
                     </tbody>
                 </table>
             )
         }
 
 
     }
     
     useEffect(() => {
        let tickerNames = []
        props?.value.map((element) => {
            tickerNames.push(element["offers_asset"])
        });
        let distinctTickerNames = tickerNames.filter((v, i, a) => a.indexOf(v) === i);
        setOffersTickers(distinctTickerNames)

        let typeNames = []
        props?.value.map((element) => {
            typeNames.push({ "description": element["offers_types_description"], "id": element["offers_types_id"] })
        });
        let distinctTypeNames = typeNames.filter((v, i, a) => a.map((x) => x.id).indexOf(v.id) === i);
        setOffersTypeIds(distinctTypeNames)
        setOffersTypeNames(distinctTypeNames.map((x) => x.description))
    }, [show])

     return (
         <div className='stepMainContainer' style={{
             width: screenX,
             height: screenY
         }}>
             <div className={classes.root}>
                 <div className='stepperDiv'>
                     <button className='modalCloseButton' onClick={() => {
                        setShow(false)
                        }}>X</button>
                     <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                         {steps.map((label) => (
                             <Step key={label}>
                                 <StepLabel>{label}</StepLabel>
                             </Step>
                         ))}
                     </Stepper>
                     <div style={{width: "2rem"}}></div>
                 </div>

                 <div className='fileInput'>
                     {successImport || hideButtons ?
                         (<></>) :
                         (activeStep === 1 ?
                             <>
                                 {showFileButton?
                                     (
                                         <>
                                             <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                             <a>{successImportFirst ? fileName.substring(12, fileName.length) : ""}</a>
                                         </>
                                     ) :
                                     (<></>)}
                                 <input
                                     type='file'
                                     accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                     id="getFile"
                                     onChange={(e) => {
                                         if (validyFiles(e.target.files[0], 'excel')) {
                                             return
                                         }
                                         const fileToRead = e.target.files[0];
                                         if(hubConnect){
                                            readExcelFileOfertaPublica(fileToRead);
                                         }
                                         else{
                                            readExcelFileHUB(fileToRead);
                                         }
                                         setFilename(e.target.value)
                                     }}
                                 >
                                 </input>
                             </>
                             :
                             <>
                             {activeStep === 0 ?
                                <>
                                    <div className='containerSelectTicker'>
                                        <div style={{paddingTop: "12rem", textAlign: "center"}}>Selecione o ativo</div>
                                        <Autocomplete
                                            options={offersTickers}
                                            id='Ativo'
                                            className={classes2.autocompleteFAQ}
                                            renderInput={(params) => <TextField
                                                label='Selecione um ativo'
                                                onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                                className={classes2.autocompleteFAQ}
                                                {...params} variant="outlined" />}
                                            style={{paddingTop: "1rem"}}
                                            onChange={(event, newValue) => 
                                                setInputFileTicker(newValue)
                                            }
                                        />
                                        <div style={{paddingTop: "2rem", textAlign: "center"}}>Selecione o tipo de operação</div>
                                        <Autocomplete
                                            options={offersTypeNames}
                                            id='Ativo'
                                            className={classes2.autocompleteFAQ}
                                            renderInput={(params) => <TextField
                                                label='Selecione um tipo'
                                                onKeyPress={(e) => {if(e.code === 'Backquote') e.preventDefault()}}
                                                className={classes2.autocompleteFAQ}
                                                {...params} variant="outlined" />}
                                            style={{paddingTop: "1rem"}}
                                            onChange={(event, newValue) => {
                                                    if(newValue != undefined && newValue != null && newValue != ""){
                                                        const id = offersTypeIds.filter((element) => element.description == newValue)[0].id
                                                        setInputFileTypeId(id)
                                                    }
                                                    else{
                                                        setInputFileTypeId(-1)
                                                    }
                                                }
                                            }
                                        />
                                        <div className='containerSwitch'>
                                            <button className={hubConnect ? "inactiveOptionButton" : "activeOptionButton"} onClick={(e) =>
                                                setHubConnect(false)
                                            }>DIREITO DE PREFERÊNCIA</button>
                                            <button className={hubConnect ? "activeOptionButton" : "inactiveOptionButton"} onClick={(e) =>
                                                setHubConnect(true)
                                            }>OFERTA PÚBLICA</button>
                                        </div>
                                        
                                    </div>
                                </>
                                :
                                <></>
                             }
                             </>
                         )}
 
                 </div>
             </div>
             <div className='fileInput'>
                 {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                     successImport && !errorsImport ?
                         (
                             <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                 <a>Sucesso ao importar o Book</a>
                             </div>
                         ) :
 
                         activeStep === 2 ? (
                             (<>
                                 <p id='recommendationsImportConfirm'>Confira os dados</p>
                                 <div id='customScroll' className='tableForImportSection'>
                                     {hubConnect ?
                                         tableForImportOfertaPublica()
                                         :
                                         tableForImportReccordsHUB()
                                     } </div>
                             </>)
                         ) : 
                             successImport && errorsImport ? (
                                 <div id='flexRowDiv' className='stepperImportWithErrors'>
                                     <a>Sucesso ao importar arquivos!</a>
                                     <a>Mas, ocorreram erros na importação</a>
                                     <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                 </div>
                             ) : !successImport && errorsImport ?
                                 (
                                     <div id='flexRowDiv' className='stepperImportWithErrors'>
                                         <a>ocorreram erros na importação</a>
                                         <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                     </div>
                                 ) :
                                 showErrors ?
                                     (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                     :
                                     (<div id='customScroll' className='tableForImportSection'>{activeStep === 1 ?
                                        (hubConnect ?    
                                            tableForImportOfertaPublica()
                                            :
                                            tableForImportReccordsHUB()
                                        )
                                        :
                                        <></>
                                        } </div>)}
                 <div>
                     {activeStep === steps.length && successImport ?
                         (
                             <div id='flexRowDiv'>
                                 <Typography className={classes.instructions}>Importado com sucesso!</Typography>
                                 <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                 <Button onClick={() => setShow(false)}>Fechar</Button>
                             </div>
                         ) :
                         activeStep === steps.length && !successImport ?
                             (
                                 <div id='flexRowDiv'>
                                     <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                     <Button onClick={() => setShow(false)}>Fechar</Button>
                                 </div>
                             ) :
 
                             hideButtons ? (<></>) :
                                 (
                                     <div>
                                         <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                         <div id='flexRowDiv'>
                                             <Button
                                                 disabled={activeStep === 0}
                                                 onClick={handleBack}
                                                 className={classes.backButton}
                                             >
                                                 Voltar
                                             </Button>
                                             <Button
                                                 id='buttonImport'
                                                 variant="contained"
                                                 className='customButton'
                                                 onClick={activeStep === steps.length - 1 ? () => handleFileCheck() : () => handleNext()}>
                                                 {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                             </Button>
                                         </div>
                                     </div>
                                 )}
                 </div>
             </div>
         </div>
     );
 }