
export function validateSchemasAPI(requiredFields){
    
    const fieldsError = [];

    requiredFields.map((field)=>{
        if((field.value === '' || field.value === undefined || field.value === null || field.value === 0)&& field.required === true){
            fieldsError.push(field.name)
        }
    })

    return fieldsError;
}