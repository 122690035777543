import React from 'react'
/*Styles*/
import './styles/assets.classes.css'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
/*Components*/
import Modal from '../../../../components/modal/modal'
import { ConfirmationBox } from '../../../../components/confirmationBox/confirmationBox'
import { AssetClassCadastration } from './asset.class.cadastration'
import { AssetsClassesTable } from './tables/asset.classes.table'
import { AssetClassesEditBox } from './asset.classes.editBox'

export function AssetsClasses() {

    const { show, setShow, type, setType } = useModal()
    const { tokenKey } = useAuth()

    return (
        <>
            <Modal>
                {
                    type.name === 'alertMsg' ?
                        (
                            <ConfirmationBox msgData={type} />
                        ) :

                        type.name === 'assetClassesCadastration' ?
                            (
                                <AssetClassCadastration />
                            ) :

                            type.name === 'handleEditAssetClass' ?

                            (
                                <AssetClassesEditBox data={type.value}/>
                            ) :

                            (<></>)
                }
            </Modal>
            <div className="assetClassesContainer">
                <AssetsClassesTable />
            </div>
        </>

    )
}