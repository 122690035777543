import React, { useEffect, useState, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider, createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getOffshoreEducational, removeOffshoreEducational } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";

export function OffshoreEducacional() {
    const [loading, setLoading] = useState(true);
    const [listOffshoreEducational, setlistOffshoreEducational] = useState([]);
    const { tokenKey, userType } = useAuth();
    const { setType, setShow } = useModal();
    const { edited, setEdited } = useModal();

    async function getMaterial() {
        const response = await getOffshoreEducational(tokenKey);
        if (response?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: response?.error,
                },
            });
            setShow(true);
        } else {
            setlistOffshoreEducational(response?.data?.data);
            setLoading(false);
        }
    }

    async function handleDelete(id) {
        setLoading(true);
        try {
            const response = await removeOffshoreEducational(tokenKey, id);
            if (response?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: response?.error,
                    },
                });
                setShow(true);
            } else {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: "Material removido com sucesso!",
                    },
                });
                setShow(true);
                getMaterial();
            }
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao remover material"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }


    useEffect(() => {
        setLoading(true)
        if (edited) {
            getMaterial();
        }
        getMaterial();
    }, [edited]);


    async function offshoreEducationalExport() {
        setLoading(true);
        try {
            const myExport = await getOffshoreEducational(tokenKey);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "materiais_educacionais_offshore.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Canal de Origem",
            "Material",
            "Link"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "source",
            "description",
            "link"
        ];
        listOffshoreEducational.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "application_curve_value" || y === "sale_gross_value" || y === "agio_outcome" || y === "rentability_mtm"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at" || y === "due_at"
                        ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                        : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência.");
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    },
                }),
            },
        },
        {
            name: "source",
            label: "Canal de Origem",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    },
                }),
            },
        },
        {
            name: "description",
            label: "Material",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "link",
            label: "Link",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (value) => <a target="_blank" rel="noreferrer" href={value}>Acessar</a>
            }
        },
        {
            name: "EDITAR",
            options: {
                filter: false,
                display: userType < 3 ? true : false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <button onClick={() => {
                            setType({
                                name: "editOffshore",
                                type: "information",
                                value: {
                                    data: tableMeta.rowData
                                }
                            })
                            setShow(true)
                            setEdited(true)
                        }}>
                            Editar
                        </button>
                    );
                }
            }
        },
        {
            name: "REMOVER",
            options: {
                filter: false,
                display: userType < 3 ? true : false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <button onClick={() => {
                            setType({
                                name: "alertMsg",
                                value: {
                                    message: "Deseja realmente excluir o material da lista?",
                                    func: handleDelete,
                                    dataForFunc: tableMeta.rowData[0],
                                }
                            })
                            setShow(true)
                        }}>
                            Remover
                        </button>
                    );
                }
            }
        }
    ];

    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            {userType < 3 ?
                <Tooltip disableFocusListener title="Criar Material">
                    <IconButton
                        onClick={() => {
                            setType({
                                name: "createOffshore",
                                type: "information",
                                value: {
                                    data: []
                                }
                            })
                            setShow(true)
                            setEdited(true)
                        }}
                    >
                        <span style={{ fontSize: "1rem", fontWeight: "500" }}>Criar Material</span>
                    </IconButton>
                </Tooltip>
                : null}

        </>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: false,
        print: false,
        responsive: "standard",
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há links de materiais educacionais no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "file.csv",
            separator: ";",
        },
        onDownload: () => {
            offshoreEducationalExport();
            return false;
        },
        setTableProps: () => {
            return {
                padding: '0px 1rem 0px',
                size: 'small'
            };
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: 'center',
                            whiteSpace: "",
                            textAlign: 'center'
                        }
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: 'center',
                            whiteSpace: "pre-line",
                        },
                        fixedHeader: {
                            justifyContent: 'center',
                            whiteSpace: "pre-line",
                            maxWidth: '4rem',
                            padding: "0px 0.6rem 0px 0.6rem"
                        },
                        toolButton: {
                            margin: '0px'
                        }
                    }
                },
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                            '&:nth-child(odd)': {
                                backgroundColor: '#dbdbdb'
                            }
                        }
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: 'none',
                            borderRadius: '0'
                        }
                    },
                }
            }
        })


    return (
        <div>
            {
                loading ? <ArrowLoader /> :
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={""}
                            data={listOffshoreEducational}
                            columns={columns}
                            options={options}
                            className="tableUnsuitedStructured"
                        ></MUIDataTable>
                    </ThemeProvider>
            }
        </div>
    );
}

