/**
 * Component Tabela BookRubi utilizada para listar/efetuar as operações
 * Desenvolvido por: Rafael Cezário
 */

import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import './styles/booksOperation.css';
import { IoIosListBox } from 'react-icons/io';
import { BsPlusCircle } from 'react-icons/bs';
import { RiArrowRightDownFill, RiArrowRightUpFill } from 'react-icons/ri';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiFileText, FiPlus, FiStar } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { styledForBooks } from '../../../../../components/styles/autocompletField'
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader';
import { ArrowLoaderWOText } from '../../../../../components/loaders/loaderWithoutText';
import { ArrowLoaderWhiteWOText } from '../../../../../components/loaders/loaderWhitoutTextWhite';
/*Services*/
import { api } from '../../../../../services/api';
import { getAssetsList } from '../../.../../../../../services/asyncFunctions';
/*Contexts*/
import { useAuth } from '../../../../../contexts/useAuth';
import { useModal } from '../../../../../contexts/useModal';
import { useMenu } from '../../../../../contexts/useMenu';
import { useBooks } from '../../../../../contexts/useBooks';
/*Utils*/
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { toBRL } from '../../../../../utils/formatNumberAndText/toBRLCurrency'
import { ModalSizeFitToScreen } from '../../../../../utils/responsiveFunctions/modalSizeFitToScreen';

export default function OperationTable(props) {

    const [isNotCalculated, setIsNotCalculated] = useState(true);
    const [buySell, setBuySell] = useState(true)
    const [loadingTable, setLoadingTable] = useState(true)
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState(false)
    const [isDownAsset, setIsdownAsset] = useState(false)
    const [sendStatus, setSendStatus] = useState({ status: false, type: '' })
    const [bottomScroll, setBottomScroll] = useState(false)
    const [loadingBuy, setLoadingBuy] = useState(false)
    const [qtdIsFilled, setQtdIsFilled] = useState(false)
    const [valueIsFilled, setValueIsFilled] = useState(false)
    const [showCalculatedValue, setShowCalculatedValue] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('Ativo')
    const [selectedClient, setSelectedClient] = useState({ external_id: '', name: 'Selecione -' })
    const [selectedQtd, setSelectedQtd] = useState(0)
    const [selectedValue, setSelectedValue] = useState('')
    const [comission, setComission] = useState(0)
    const [idActive, setIdActive] = useState('')
    const [thisBookLoading, setThisBookLoading] = useState('')
    const [typeOperation, setTypeOperation] = useState(4)
    const [justRegister, setJustRegister] = useState('')
    const [listFilter, setListFilter] = useState('')
    const [showThisLegend, setShowThisLegend] = useState(0)
    const [showAlert, setShowAlert] = useState('')
    const tbodyRef = useRef(null)
    const [booksList, setBooksList] = useState([])
    const [activeBook, setActiveBook] = useState([])
    const [bookFields, setBookFields] = useState([])
    const [thisBookIsWithAward, setThisBookIsWithAward] = useState(false)
    const [scrollTable, setScrollTable] = useState(null)
    const [scrollTableLines, setScrollTableLines] = useState(20)
    const classes = styledForBooks()
    const { user, tokenKey } = useAuth()
    const { show, setShow, edited, setEdited, type, setType } = useModal()
    const { setLoadOrders } = useBooks()
    const [clientsList, setClientsList] = useState([{ external_id: 'Digite 3 caracteres', name: '' }])
    const [myClientsList, setMyClientsList] = useState([{ external_id: 'Digite 3 caracteres', name: '' }])
    const [assetsList, setAssetsList] = useState([])
    const [assetsVariationList, setAssetsVariationList] = useState([])
    const { setMenuStatus } = useMenu()
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [numberOfBookOrdersTemp,setNumberOfBookOrdersTemp] = useState(0)
    const [searchClientValue, setSearchClientValue] = useState('')
    const [handleSearch, setHandleSearch] = useState(false)

    async function getMyClients(search) {

        try {
            const clientsResult = await api.get(`api/v1/users/${user?.external_id}/myclients?status=1&detatched=1`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    filter : search,
                }
            })
            if (clientsResult?.data?.data !== undefined) {
                // console.log('myClients: ', clientsResult?.data?.data )
                setMyClientsList(
                    clientsResult?.data?.data?.map((client) => {
                        return ({ external_id: client?.client_external_id, name: client?.client_name })
                    })
                )
            } else {
                setMyClientsList(
                    [
                        { external_id: '', name: '' }
                    ]
                )
            }
        } catch (error) {

            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    async function getAllClients(search) {
        
        try {
            const clientsResult = await api.get(`api/v1/clients?status=1,4`, 
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    filter : search,
                }
            }
            
            )

            if (clientsResult?.data?.data !== undefined) {
                setClientsList(clientsResult?.data?.data);
            }
            else {
                setClientsList([
                    {
                        external_id: '', name: ''
                    }
                ])
            }

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    

    useEffect(() => {

        async function getAssetsVariation() {
            const assetsVariatin = await api.get(`/api/v1/assetsVariation`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setAssetsVariationList(assetsVariatin.data.data);
        }
        async function getBooks() {
            setLoadingTable(true)
            const booksResult = await api.get(`/api/v1/books?status=1`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setBooksList(booksResult?.data?.data);
            if (booksResult?.data?.data !== undefined && booksResult?.data?.data !== null
                && booksResult?.data?.data !== '') {
                getActiveBook(booksResult?.data?.data[0]?.id)
                setThisBookIsWithAward(booksResult?.data?.data[0]?.award)
            } else {
                setLoadingTable(false);
            }
        }
        
        async function lookAssets() {

            const getAssets = await getAssetsList(tokenKey);
            if (getAssets?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: getAssets?.error
                    }
                })
                setShow(true);
            } else {
                // console.log('ass', getAssets);
                setAssetsList(getAssets);
            }
        }

        if ((!show && type.name !== 'getOrders' && edited) || booksList?.length === 0) {
            getAssetsVariation()
            lookAssets()
            getBooks()
            setEdited(false)
        }

        // if (user?.type > 2) { getMyClients() }
        // if (user?.type <= 2) { getAllClients() }
        setLoading(false);

    getTempOrders()
    }, [show])

    useEffect(() => {

        if (bottomScroll != false) {

            setTimeout(() => {

                setScrollTableLines(scrollTableLines + 15)
                setBottomScroll(false);
            }, 200)
        } else {
            setBottomScroll(false);
        }

    }, [bottomScroll])

    useEffect(() => {

        if (edited && type?.bookId !== undefined) {
            getActiveBook(type.bookId)
            setEdited(false)
        }
    }, [edited])

    useEffect(() => {
        getTempOrders()
    }, [edited])

    function cleanData() {
        setSelectedClient({ external_id: '', name: 'Selecione -' })
        setQtdIsFilled(false)
        setValueIsFilled(false)
        setSelectedQtd('')
        setTypeOperation(4)
        setSelectedValue('')
        setComission(0)
        setJustRegister('')
    }

    async function handleBuySubmit(selectedRows, selectedValue, selectedCommission) {
        // console.log('BUGA BUGA', selectedValue, selectedCommission, selectedRows)
        // if (typeOperation === 4){
        //     alert('selecione uma das opções: Compra ou Sob custódia')
        //     return;
        // }

        if (selectedClient === '' || selectedClient.name === 'Selecione -') {
            alert('selecione o cliente')
            return
        }
        
        if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
            alert('selecione  uma das opções: Compra ou Sob custódia')
            return
        }  

        if (selectedValue === '' || selectedValue === 0) {
            alert('Digite valor ou quantidade')
            return
        }

        if (selectedQtd === '') {
            alert('Digite a quantidade')
            return
        }
        if (selectedValue === '' || selectedValue === 0) {
            alert('Digite o valor')
            return
        }
        // if(justRegister === ''){
        //     return alert('Informe como deve ser operado pelo backoffice')
        // }

        try {
            setLoadingBuy(true)
            await api.post(`api/v1/booksOrdersTemp/books_id/${activeBook?.book_id}/import_id/${activeBook?.import_id}`, {
                "clients_external_id": parseInt(selectedClient?.external_id),
                "asset": selectedRows?.Ativo?.value,
                "quantity": parseInt(selectedQtd),
                "value": selectedValue / selectedQtd,
                "comission": selectedCommission,
                "operations_types_id": typeOperation,
                "expiration": selectedRows.Vencimento?.value,
                "reference": selectedRows?.Ativo?.reference
                // "justRegister": justRegister
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setLoadOrders(true)
            setEdited(!edited)
            setLoadingBuy(false)
            setSendStatus({ status: true, type: 'success' })
            setShowAlert('Ordem enviada para boleta')
            setActive(false)
            setIdActive('')
            getTempOrders()

        } catch (error) {
            console.log('error', error.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
            setShow(true);
            setActive(false);
            setIdActive('');
            setLoadingBuy(false);
        }
        setSelectedClient({ external_id: '', name: 'selecione -' });
        cleanData()
    }

    // para mostrar aviso de boletas temporarias nao enviadas
    async function getTempOrders() {
        setLoading(true);
        try {
            const tempOrdersResult = await api.get(`api/v1/booksOrdersTemp/users/${user?.external_id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setNumberOfBookOrdersTemp(tempOrdersResult?.data?.data.length);
            setLoading(false);
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar realizar requisição' : error?.response?.data?.message } })
            setLoading(false);
        }
    }

    async function getActiveBook(bookData) {

        if (bookData !== undefined) {
            setLoading(true);
            try {
                const bookToday = await api.get(`api/v1/booksImports/${bookData}/today`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                const bookDetails = await api.get(`api/v1/books/${bookData}`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                });
                // console.log('***', bookToday);
                // console.log(bookDetails);
                setBookFields(bookDetails?.data?.data);
                if (bookToday.data?.data === undefined) {
                    setActiveBook(bookDetails?.data?.data)
                    setLoading(false);
                    setLoadingTable(false);
                } else {
                    setActiveBook(bookToday?.data?.data)
                    setLoading(false);
                    setLoadingTable(false);
                }
            } catch (error) {
                setActiveBook([])
                setLoading(false);
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message == undefined ? 'Erro interno ao tentar buscar Book' : error?.response?.data?.message } })
                setShow(true);
            }
        }
        setLoading(false);
        setLoadingTable(false);
    }

    const handleKeyDown = (event, row) => {
        event.stopPropagation();
        const currentRow = tbodyRef.current?.children.namedItem(row);
        switch (event.key) {
            case 'Tab':
                currentRow?.nextElementSibling?.focus();
                break;

            case 'Enter':
                currentRow.click();
                break;
            case "ArrowUp":
                currentRow?.previousElementSibling?.previousElementSibling?.focus();
                break;
            default:
                break;
        }
    }

    function formatLineToTableOperationPreview(dataValue, type) {

        switch (type) {
            case 'number':
                return parseFloat(dataValue)
            case 'date':
                try {
                    return new Date(dataValue+'T03:00:00').toLocaleDateString('pt-BR')
                } catch (error) {
                    return 'Não foi possível ler campo data.'
                }
            case 'boolean':
                return dataValue == 1 ? 'Sim' : 'Não'
            default:
                return dataValue
        }
    }

    function checkFilteredValue(value) {
        if (value === '') { setScrollTableLines(20) }
    }

    function listFiltered() {
        if (listFilter !== '') {
            return activeBook?.data?.filter((lineFilter) => {
                const columnInThisLine = Object.keys(lineFilter);
                if (columnInThisLine.filter((keyOfThisLine) => {
                    const valueSearch = formatLineToTableOperationPreview(lineFilter[keyOfThisLine]['value'], lineFilter[keyOfThisLine]['books_param_types_description'].toString());
                    if (valueSearch?.toString()?.toLowerCase()?.includes(listFilter?.toLowerCase().toString()) && selectedFilter.toLowerCase() === keyOfThisLine.toLowerCase()) {
                        return keyOfThisLine
                    }
                }).length > 0) { return lineFilter }
            })

        } else {
            return activeBook?.data;
        }
    }

    function calculateValues(rows, typeCalculate) {
        let lot = assetsList.filter((x) => x.asset == rows.Ativo?.value)[0].lot === 'L';
        //typeCalculate 1: Calcula valor
        //typeCalculate 2: Calcula quantidade
        //typeCalculate 3: Calcula valor

        let assetValue = assetsVariationList[rows.Ativo?.value]?.current_value
        if (typeCalculate === 1) {

            if (selectedValue !== '' && selectedValue > 0) {
                setShowCalculatedValue(true)
                let checkAssetQtd = thisBookIsWithAward ? (selectedValue / (rows.Prêmio?.value / 100))  / assetValue : selectedValue / assetValue;
                checkAssetQtd = Math.ceil(checkAssetQtd);
                let thisAcceptedQtd = 0;
                if (lot) {
                    if (checkAssetQtd < 100) {
                        setShowAlert('Lote não pode ser fracionário ou menor que 100');
                        setSendStatus({
                            status: true,
                            type: 'error'
                        });
                        setSelectedQtd(0);
                        setSelectedValue(0);
                        setValueIsFilled(false);
                    } else {
                        let restAssetQtd = checkAssetQtd % 100;
                        thisAcceptedQtd = checkAssetQtd - restAssetQtd;

                    }
                } else {
                    thisAcceptedQtd = checkAssetQtd;
                }
                setSelectedQtd(thisAcceptedQtd);
                setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (rows.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
            } else {
                setSelectedQtd(0)
                setSelectedValue(0);
            }
        }

        if (typeCalculate === 3) {
            if (lot && selectedQtd < 100) {
                setSelectedQtd(0)
                setSelectedValue(0)
                setSendStatus({ status: true, type: 'error' })
                setShowAlert('Lote não pode ser fracionário ou menor que 100')
            } else if (lot && selectedQtd >= 100) {
                let thisAcceptedQtd = 0;
                let restAssetQtd = selectedQtd % 100;
                thisAcceptedQtd = selectedQtd - restAssetQtd;
                setSelectedQtd(thisAcceptedQtd);
                setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (rows.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
            } else {
                let thisAcceptedQtd = selectedQtd;
                setSelectedQtd(thisAcceptedQtd);
                setSelectedValue(thisBookIsWithAward ? thisAcceptedQtd * assetValue * (rows.Prêmio?.value / 100) : thisAcceptedQtd * assetValue);
            }
        }
    }

    function openOperationBox(row) {

        /* REGRA PARA ABRIR E FECHAR A CAIXA DE COMPRA DO ATIVO */
        if (active === false) {
            cleanData();
            setSendStatus({ status: false, type: '' });
            setActive(true);
            setIdActive(row);
        } else if (idActive === row) {
            setActive(false);
            setBuySell(true);
            setSendStatus({ status: false, type: '' });
        } else if (active === true && idActive != row) {
            cleanData();
            setSendStatus({ status: false, type: '' });
            setIdActive(row);
            setActive(false);
            setActive(true);
            setSelectedClient({ external_id: '', name: 'selecione -' });
            setBuySell(true);
            setSendStatus({ status: false, type: '' });
        }
    }

    async function handleChange (event) {
        
        if (event.target.value.length > 2){
            if(
                (user.type < 3 && ((clientsList[0].external_id === 'Carregando') || (clientsList[0].external_id === 'Digite 3 caracteres')) ) || 
                (user.type > 2 && ((myClientsList[0].external_id === 'Carregando') ||  (myClientsList[0].external_id === 'Digite 3 caracteres')))
            ) {
                if (user?.type <= 2) {
                    setClientsList([
                    {
                        external_id: 'Carregando', name: event.target.value
                    }
                ])}
                else{

                    setMyClientsList([
                        {
                            external_id: 'Carregando', name: event.target.value
                        }
                    ])
                }
            }
            if (handleSearch == false){
                setHandleSearch(true)
                setSearchClientValue(event.target.value);
                if (user?.type > 2) { await getMyClients(event.target.value) }
                if (user?.type <= 2) { await getAllClients(event.target.value) }
            }
        }
        else {
            setHandleSearch(false)
            if (user?.type <= 2) {
            setClientsList(
                [
                    { external_id: 'Digite 3 caracteres', name: event.target.value + '...' }
                ]
                )
            }
            if (user?.type > 2) {
                setMyClientsList(
                    [
                        { external_id: 'Digite 3 caracteres', name: event.target.value + '...' }
                    ]
                    )
                }
            }
    };

    return (
        <div key='booksOperationTable' className='booksOperationTable' >
            {loadingTable ?
                (
                    <div key='booksOperationTableLoader' id='booksOperationTableLoader'>
                        <ArrowLoader />
                    </div>
                ) :
                (
                    <>
                        <div key='booksOperationMenuHeader' id='booksOperationMenuHeader'>
                            <div key='booksTabs' className='booksTabs' >
                                {booksList?.map((book) => {
                                    return (
                                        <>
                                            <label key='tabsLabel'
                                                style={{ cursor: 'pointer' }}
                                                title='Clique para operar neste Book'
                                                id={activeBook?.book_id == book?.id ? 'booksTabsLabelActive' : 'booksTabsLabel'}
                                                onClick={
                                                    () => {
                                                        getActiveBook(book?.id)
                                                        setThisBookIsWithAward(book?.award)
                                                        setActive(false);
                                                        setThisBookLoading(book?.name)
                                                    }
                                                }>
                                                {
                                                    loading && thisBookLoading === book?.name ?
                                                        (
                                                            <div id='tabLoading'>
                                                                <ArrowLoaderWOText />
                                                            </div>
                                                        ) :
                                                        (
                                                            <a>{titleize(book?.name)}</a>
                                                        )
                                                }
                                            </label>
                                        </>)
                                })}
                            </div>
                        </div>
                        <div id='booksOperationTableContentScrollable' style={{ maxHeight: screenY - 50 }} className={listFiltered()?.length > 0 ? 'booksOperationTableContent' : 'booksOperationTableEmpty'}
                            onScrollCapture={() => {
                                setScrollTable(document.getElementById('booksOperationTableContentScrollable'))
                                if (scrollTable?.scrollTop + scrollTable?.clientHeight >= scrollTable?.scrollHeight) {
                                    setBottomScroll(true);
                                }
                            }}>
                            <table id='booksOperationTableID'>
                                <thead id='booksThead'>
                                    <tr key='firstTrThead'>
                                        <th colSpan={bookFields[0]?.fields?.length}>
                                            <div id='booksTheadDiv'>
                                                <div className='booksOperationBookName'>
                                                    <p>Operações estruturadas:</p>
                                                    <p>
                                                        {
                                                            activeBook?.book_name?.length > 0 ?
                                                                activeBook?.book_name :
                                                                activeBook[0]?.name
                                                        }
                                                    </p>
                                                </div>
                                                <div id='booksOperationMenuHeaderRightDiv'>
                                                    {
                                                        booksList?.length > 0 &&
                                                        (<>
                                                            {user?.type <= 2 &&
                                                                (
                                                                    <button
                                                                        id='booksOperationTheadButton'
                                                                        onClick={() => {
                                                                            setType({ name: 'booksImportShow', value: { activeBook: bookFields, data: assetsList } })
                                                                            setShow(true);
                                                                            setMenuStatus(0)
                                                                            document.getElementById('checkMenu').checked = false
                                                                        }}
                                                                    >
                                                                        <BsPlusCircle />
                                                                        Importar
                                                                    </button>
                                                                )}
                                                            <div className={'booksOperationMenuHeaderOrderDiv' + (numberOfBookOrdersTemp > 0 ? ' pendingOrders' : '')}
                                                                title='Exibir boleta atual'
                                                                onClick={() => {

                                                                    if (!loadingBuy) {
                                                                        setType({ name: 'showBookOrders', action: 'updateTotalValues' });
                                                                        setShow(true)
                                                                        setSendStatus(false);
                                                                    } else {
                                                                        alert('Aguarde o envio da ordem')
                                                                    }
                                                                }}>
                                                                <IoIosListBox />
                                                                <a>Boleta</a>
                                                            </div>
                                                            {numberOfBookOrdersTemp > 0 ? <span className='notificationOrders'>{numberOfBookOrdersTemp}</span> : <></>}
                                                        </>
                                                        )}
                                                    <select onChange={(e) => setSelectedFilter(e.target.value)} className='selectFilterTable'>
                                                        {
                                                            bookFields[0]?.fields?.map((activeBookColumn) => {
                                                                return <option>{titleize(activeBookColumn?.field)}</option>
                                                            })
                                                        }
                                                    </select>
                                                    <input id='booksOperationInputFilter'
                                                        placeholder='Filtrar'
                                                        onChange={(e) => {
                                                            setListFilter(e.target.value);
                                                            checkFilteredValue(e.target.value);
                                                        }}>
                                                    </input>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr key='secondTrThead'>
                                        {bookFields[0]?.fields?.map((activeBookTab, index) => {
                                            let thisField = ''
                                            if (index === 0) {
                                                return (
                                                    <th>
                                                        <div id='booksOperationActiveTh'>
                                                            <label id='booksOperationActiveThName'>
                                                                {titleize(activeBookTab?.field)}
                                                            </label>
                                                        </div>
                                                    </th>
                                                )
                                            }
                                            if (activeBookTab?.field.indexOf('_') > 1) {

                                                try {

                                                    thisField = activeBookTab?.field.replace(/_/g, ' ')

                                                } catch {
                                                    thisField = activeBookTab?.field
                                                }

                                            } else {
                                                thisField = activeBookTab?.field
                                            }
                                            return activeBookTab?.field === 'FEE' ? (
                                                <th>{titleize(activeBookTab?.field) + ' %'}</th>
                                            ) :
                                                activeBookTab?.field === 'Recomendado' ? (
                                                    <th><FiStar stroke='#c49e17' size='18' /></th>
                                                ) :
                                                    <th>{titleize(thisField)}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody id='BooksOperationTableTbody'>
                                    {
                                        loading ? (
                                            <tr>
                                                <td colSpan={bookFields[0]?.fields?.length} id='booksOperationTdLoading'>
                                                    <ArrowLoader />
                                                </td>
                                            </tr>
                                        ) :
                                            listFiltered()?.length > 0 &&
                                            (
                                                listFiltered().map((rows, indexRows) => {
                                                    // console.log(rows)
                                                    if ((indexRows < scrollTableLines) && (bottomScroll === true) && (indexRows + 1 === scrollTableLines)) {
                                                        return (
                                                            <tr key={indexRows} id={bottomScroll ? 'booksShowLoadingScroll' : 'booksHideLoadingScroll'}>
                                                                <td colSpan={bookFields[0]?.fields?.length}>
                                                                    <div class='ldsellipsis'><div></div><div></div><div></div><div></div></div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    else if (indexRows < scrollTableLines) {
                                                        return (
                                                            <>
                                                                <tr
                                                                    key={indexRows + 'Body'}
                                                                    className={active === true && idActive === indexRows ? 'booksOperationTrActive' : 'booksOperationClosedTr'}
                                                                    onKeyDown={(e) => handleKeyDown(e, indexRows)}
                                                                    id={indexRows}
                                                                >
                                                                    {activeBook?.allFields.map((fieldColumn, indexColumnActive) => {
                                                                        const field = (Object.keys(rows))
                                                                        if (field.indexOf(fieldColumn) >= 0) {
                                                                            if (indexColumnActive === 0) {
                                                                                return (
                                                                                    <td id='booksOperationAssetTd'>
                                                                                        <div className='booksOperationActiveNameAndValue'>
                                                                                            <label id='booksOperationActiveName'>
                                                                                                <div className='booksOperationAssetCardButton' onClick={() => { setType({ name: 'showAssetCard', asset: rows[fieldColumn].value, bookId: activeBook?.book_id, reference: rows[fieldColumn]?.reference }); setShow(true) }} title='Clique para detalhes'>
                                                                                                    {
                                                                                                        rows['Recomendado']?.value === true &&
                                                                                                        <>
                                                                                                            <FiFileText />
                                                                                                            <a>Card</a>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='booksOperationAssetNameDiv' title='Clique para operar' onClick={() => openOperationBox(indexRows)}>
                                                                                                    <a>{formatLineToTableOperationPreview(rows[fieldColumn]?.value, rows[fieldColumn]?.books_param_types_description)}</a>
                                                                                                </div>
                                                                                            </label>
                                                                                            <label id='booksOperationActiveValue' onClick={() => openOperationBox(indexRows)}>
                                                                                                {assetsVariationList[rows.Ativo?.value]?.variation > 1 ?
                                                                                                    (
                                                                                                        <label>
                                                                                                            <a id='downActive'>
                                                                                                                <b>{toBRL(assetsVariationList[rows.Ativo?.value]?.current_value)}</b>
                                                                                                                <i>{assetsVariationList[rows.Ativo?.value]?.variation_percentage}%</i>
                                                                                                            </a>
                                                                                                            <RiArrowRightDownFill color='rgb(160, 4, 4)' />
                                                                                                        </label>

                                                                                                    ) :
                                                                                                    (
                                                                                                        <label>
                                                                                                            <a id='upActive'>
                                                                                                                <b>{toBRL(assetsVariationList[rows.Ativo?.value]?.current_value)}</b>
                                                                                                                <i>{assetsVariationList[rows.Ativo?.value]?.variation_percentage}%</i>
                                                                                                            </a>
                                                                                                            <RiArrowRightUpFill color='rgb(66, 146, 66)' />
                                                                                                        </label>
                                                                                                    )}
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                )
                                                                            } else if (fieldColumn === 'Recomendado') {
                                                                                return (
                                                                                    <td title='Clique para operar' onClick={() => openOperationBox(indexRows)}>
                                                                                        {
                                                                                            rows[fieldColumn]?.value == true &&
                                                                                            <FaStar className='booksOperationStar' />
                                                                                        }
                                                                                    </td>
                                                                                )
                                                                            }
                                                                            return (
                                                                                <td title='Clique para operar' onClick={() => openOperationBox(indexRows)}>
                                                                                    {
                                                                                        formatLineToTableOperationPreview(rows[fieldColumn]?.value, rows[fieldColumn]?.books_param_types_description) === null ? ' - ' :
                                                                                            formatLineToTableOperationPreview(rows[fieldColumn]?.value, rows[fieldColumn]?.books_param_types_description)
                                                                                    }
                                                                                </td>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <td>-</td>
                                                                            )
                                                                        }
                                                                    })}
                                                                </tr>
                                                                <tr id={active === true && idActive === indexRows ? 'booksOperationBuyBoxOpen' : 'booksOperationBuyBoxClose'} key={indexRows}>
                                                                    <th colSpan={bookFields[0]?.fields?.length}>
                                                                        <div className={buySell ? 'booksBuySell' : 'booksBuySellFalse'}>
                                                                            <div className='booksBuySellBtn'>
                                                                                {
                                                                                !thisBookIsWithAward &&
                                                                                <div className='booksBuySellOpt'>
                                                                                    <input
                                                                                        onChange={() => {
                                                                                            setIsdownAsset(!isDownAsset)
                                                                                            typeOperation !== 2 ? setTypeOperation(2) : setTypeOperation(4)
                                                                                            setQtdIsFilled(false)
                                                                                            setSelectedValue(0)
                                                                                            setSelectedQtd(0)
                                                                                        }}
                                                                                        value='Compra'
                                                                                        type="checkbox"
                                                                                        id={`'checkboxCompra'${rows}`}
                                                                                        checked={typeOperation === 2 ? true : false}
                                                                                    />
                                                                                    <a>Compra</a>
                                                                                </div>
                                                                                }
                                                                                {
                                                                                    !thisBookIsWithAward &&
                                                                                    <div className='booksBuySellOpt'>
                                                                                        <input
                                                                                            onChange={() => {
                                                                                                typeOperation !== 3 ? setTypeOperation(3) : setTypeOperation(4)
                                                                                                setValueIsFilled(false)
                                                                                                setSelectedValue(0)
                                                                                                setSelectedQtd(0)
                                                                                            }}
                                                                                            value='Sob Custódia'
                                                                                            type="checkbox"
                                                                                            id={`"checkboxCustodia"${rows}`}
                                                                                            checked={typeOperation === 3 ? true : false}
                                                                                        />
                                                                                        <a>Sob Custódia</a>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className='booksClientData'>
                                                                                <div className='booksAlignItemsClient'>
                                                                                    { //RIGHT HERE
                                                                                        
                                                                                        qtdIsFilled && !showCalculatedValue &&
                                                                                        <div className='booksLabel'>
                                                                                            <div className='booksLabelAndAskIcon'>
                                                                                                <a>Valor</a>
                                                                                                <AiOutlineQuestionCircle
                                                                                                    title='Preencher o campo valor em caso de compra da ação. Exemplo: caso o cliente queira fazer uma operação de R$ 10.000,00 em VALE3,
                                                                                                    preencha nesse campo R$ 10.000,00, será trabalho do backoffice fazer o cálculo
                                                                                                    da quantidade da ações da operação junto com os custos.'
                                                                                                    onMouseEnter={() => setShowThisLegend(1)}
                                                                                                    onMouseLeave={() => setShowThisLegend(0)} />
                                                                                            </div>
                                                                                            <label id='booksOperationLabelCalculatedValue'>
                                                                                                <a>
                                                                                                    {!thisBookIsWithAward ? //PROBREMA
                                                                                                        toBRL(selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value)  : // rows.Prêmio?.value
                                                                                                        toBRL(selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value * (rows.Prêmio?.value / 100))}
                                                                                                </a>
                                                                                            </label>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        !qtdIsFilled &&
                                                                                        <div className='booksLabel'>
                                                                                            <div className='booksLabelAndAskIcon'>
                                                                                                <a>Valor</a>
                                                                                                <AiOutlineQuestionCircle
                                                                                                    title='Preencher o campo valor em caso de compra da ação. Exemplo: caso o cliente queira fazer uma operação de R$ 10.000,00 em VALE3,
                                                                                                    preencha nesse campo R$ 10.000,00, será trabalho do backoffice fazer o cálculo
                                                                                                    da quantidade da ações da operação junto com os custos.'
                                                                                                    onMouseEnter={() => setShowThisLegend(1)}
                                                                                                    onMouseLeave={() => setShowThisLegend(0)} />
                                                                                            </div>
                                                                                            <CurrencyTextField
                                                                                                variant="standard"
                                                                                                value={selectedValue}
                                                                                                disabled={typeOperation===3 ? true : false}
                                                                                                currencySymbol="R$"
                                                                                                className={classes.textFieldForAutoCompletBuySellBox}
                                                                                                outputFormat="number"
                                                                                                decimalCharacter=","
                                                                                                id={`value${rows}`}
                                                                                                digitGroupSeparator="."
                                                                                                onChange={(event, value) => {
                                                                                                    if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
                                                                                                        alert('selecione  uma das opções: Compra ou Sob custódia')
                                                                                                        setSelectedValue('')
                                                                                                        setSelectedQtd(0)
                                                                                                        setValueIsFilled(false)
                                                                                                        return
                                                                                                    }
                                                                                                    
                                                                                                    if (value === '' || value === 0) {
                                                                                                        setValueIsFilled(false)
                                                                                                        setSelectedValue(10)
                                                                                                    } else {
                                                                                                        setSendStatus({ status: false, type: '' })
                                                                                                        setShowCalculatedValue(false);
                                                                                                        setValueIsFilled(true)
                                                                                                        setSelectedValue(value);
                                                                                                    }
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    calculateValues(rows, 1)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        valueIsFilled &&
                                                                                        <div className='booksLabelQtd'>
                                                                                            <div className='booksLabelAndAskIcon'>
                                                                                                <a>Quantidade</a>
                                                                                                <AiOutlineQuestionCircle
                                                                                                    title='Preencher o campo quantidade caso a operação seja Sob Custódia. Exemplo: Insira a quantidade de ações para uma
                                                                                                    determinada operação Sob Custódia nesse campo, o valor em reais será consequência da
                                                                                                    quantidade fornecida pelo assessor x o preço
                                                                                                    que sair a operação.'
                                                                                                    onMouseEnter={() => setShowThisLegend(2)}
                                                                                                    onMouseLeave={() => setShowThisLegend(0)} />
                                                                                            </div>
                                                                                            <label id='booksOperationLabelCalculatedQtd'>
                                                                                                <a>{selectedQtd}</a>
                                                                                            </label>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        !valueIsFilled &&
                                                                                        <div className='booksLabelQtd'>
                                                                                            <div className='booksLabelAndAskIcon'>
                                                                                                <a >Quantidade</a>
                                                                                                <AiOutlineQuestionCircle
                                                                                                    title='Preencher o campo quantidade caso a operação seja Sob Custódia. Exemplo: Insira a quantidade de ações para uma
                                                                                                    determinada operação Sob Custódia nesse campo, o valor em reais será consequência da
                                                                                                    quantidade fornecida pelo assessor x o preço
                                                                                                    que sair a operação.'
                                                                                                    onMouseEnter={() => setShowThisLegend(2)}
                                                                                                    onMouseLeave={() => setShowThisLegend(0)} />
                                                                                            </div>
                                                                                            <input
                                                                                                value={selectedQtd}
                                                                                                disabled={valueIsFilled != 0 || typeOperation===2 ? true : false}
                                                                                                id={`quantity_${rows}`}
                                                                                                className='booksOperationInputQtd'
                                                                                                type="number"
                                                                                                onChange={(e) => {
                                                                                                    if ((!thisBookIsWithAward && typeOperation === 0) || (!thisBookIsWithAward && typeOperation === 4)) {
                                                                                                        alert('selecione  uma das opções: Compra ou Sob custódia')
                                                                                                        setQtdIsFilled(false);
                                                                                                        return
                                                                                                    }
                                                                                                    setSendStatus({ status: false, type: '' })
                                                                                                    setShowCalculatedValue(false);
                                                                                                    setSelectedQtd(e.target.value);

                                                                                                    if (e.target.value === '' || e.target.value == 0) {
                                                                                                        setQtdIsFilled(false);
                                                                                                    }
                                                                                                    else {
                                                                                                        setQtdIsFilled(true);
                                                                                                    }
                                                                                                }}
                                                                                                onBlur={() => calculateValues(rows, 3)}
                                                                                                
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    <div id='booksLabelClientSelection'>
                                                                                        <label>
                                                                                            <a >Cliente</a>
                                                                                        </label>
                                                                                        <Autocomplete
                                                                                            onFocus={(e) => e.target.click()}
                                                                                            value={selectedClient !== '' ? selectedClient : { external_id: '', name: 'Selecione -' }}
                                                                                            options={user?.type > 2 ? myClientsList : clientsList}
                                                                                            onChange={(event, newValue) => {
                                                                                                
                                                                                                setSelectedClient(newValue);
                                                                                            }}
                                                                                            getOptionLabel={(x) =>
                                                                                                x?.external_id + " - " + titleize(x?.name)
                                                                                            }
                                                                                            onBlur = {() => {
                                                                                                setClientsList([
                                                                                                    {
                                                                                                        external_id: 'Digite 3 caracteres', name: ''
                                                                                                    }
                                                                                                ])
                                                                                                setMyClientsList([
                                                                                                    {
                                                                                                        external_id: 'Digite 3 caracteres', name: ''
                                                                                                    }
                                                                                                ])
                                                                                                setHandleSearch(false)
                                                                                                setSearchClientValue('')
                                                                                            }}
                                                                                            style={styledForBooks.default}
                                                                                            renderInput={(params) =>
                                                                                                <TextField
                                                                                                    className={styledForBooks.textFieldForAutoCompletBuySellBox}
                                                                                                    placeholder = "Comece a digitar..."
                                                                                                    {...params} variant="standard"
                                                                                                    value = {searchClientValue} 
                                                                                                    
                                                                                                    onChange={(e) => {
                                                                                                        handleChange(e)
                                                                                                    }}
                                                                                                /> 
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className='booksLabelComission' title='Comissão estimada para esta operação'>
                                                                                        <a >Comissão Estimada</a>
                                                                                        <a>
                                                                                            {
                                                                                                assetsVariationList[rows.Ativo?.value]?.current_value !== undefined &&
                                                                                                    assetsVariationList[rows.Ativo?.value]?.current_value !== 0 ?
                                                                                                    toBRL((selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value) * (rows['FEE']?.value / 100)) : '0'
                                                                                            }
                                                                                        </a>
                                                                                    </div>
                                                                                    {/* <div className='booksBuySellBtnOptions'>
                                                                                        <div className='booksBuySellOptOptions'>
                                                                                            <input
                                                                                                onChange={() => {
                                                                                                    setJustRegister(true)
                                                                                                }}
                                                                                                value='justRegisterTrue'
                                                                                                type="checkbox"
                                                                                                id={`'checkBoxJustRegisterTrue'${rows}`}
                                                                                                checked={justRegister ? true : false}
                                                                                            />
                                                                                            <a>Enviado apenas para registro</a>
                                                                                        </div>
                                                                                        {
                                                                                            !thisBookIsWithAward &&
                                                                                            <div className='booksBuySellOptOptions'>
                                                                                                <input
                                                                                                    onChange={() => {
                                                                                                        setJustRegister(false)
                                                                                                    }}
                                                                                                    value='justRegisterFalse'
                                                                                                    type="checkbox"
                                                                                                    id={`"checkboxJustRegisterFalse"${rows}`}
                                                                                                    checked={justRegister || justRegister === '' ? false : true}
                                                                                                />
                                                                                                <a>Solicito envio da ordem</a>
                                                                                            </div>
                                                                                        }
                                                                                    </div> */}
                                                                                    <div className='booksLabelButton' title='Enviar ordem para boleta'>
                                                                                        <button
                                                                                            className='booksOperationButtonOrderSend'
                                                                                            id={`buttonSend${indexRows}`}
                                                                                            onClick={() => {
                                                                                                if (assetsVariationList[rows.Ativo?.value]?.current_value === undefined ||
                                                                                                    assetsVariationList[rows.Ativo?.value]?.current_value === 0) {
                                                                                                    return alert('Verifique pois não existe cotação para o ativo')
                                                                                                }
                                                                                                let value = (thisBookIsWithAward) ? selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value * (rows.Prêmio?.value / 100) : selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value;
                                                                                                let commission = selectedQtd * assetsVariationList[rows.Ativo?.value]?.current_value * (rows['FEE']?.value / 100);
                                                                                                handleBuySubmit(rows, value, commission)
                                                                                            }
                                                                                            }>
                                                                                            {loadingBuy ? (
                                                                                                <ArrowLoaderWhiteWOText />
                                                                                            ) :
                                                                                                (
                                                                                                    'Enviar para Boleta'
                                                                                                )}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </>
                                                        )
                                                    }
                                                })
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {activeBook?.data?.length === undefined && !loadingTable && !loading &&
                            (
                                <div key='booksOperationTableEmptyTable' id='booksOperationTableEmptyTable'>
                                    <a>Não existem ativos para hoje</a>
                                </div>
                            )
                        }
                        <label
                            id={sendStatus.status ? 'showAlert' : 'hideAlert'}
                            style={{
                                background: sendStatus.type === 'error' ? '#7b0000' : 'var(--topBarBg)',
                                display: sendStatus.status ? 'flex' : 'none'

                            }}>
                            <div className='notificationAlertFooter'>
                                <a>{showAlert}</a>
                                <button id='successButonClose' onClick={() => {setSendStatus({ status: false, type: '' });}}>x</button>
                            </div>
                        </label>
                    </>
                )
            }
        </div>
    )
}
