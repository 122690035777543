import React, { useEffect, useState } from 'react'
import '../../monitoringPanel/myReservations/styles/myReservations.details.css'
import '../styles/modalEditCosts.css'
import { useAuth } from "../../../../contexts/useAuth";
import { api } from "../../../../services/api";
import { useModal } from '../../../../contexts/useModal'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

export function ModalEditCosts(props) {
    const [loading, setLoading] = useState(true);
    const { tokenKey, user } = useAuth();
    const { type, show, setShow, setType, edited, setEdited } = useModal()
    const [response, setResponse] = useState(false)
    const [tipo, setTipo] = useState('');
    const [infos, setInfos] = useState();
    const [corretagem, setCorretagem] = useState(0)
    const [btc, setBtc] = useState(0)
    const classes = styleClientsInput()

    useEffect(() => {
        // type.data.order === undefined || type.data.order === 'undefined' ||
        //     type.data.order === '' || type.data.order === '0' ? setResponse(false) : setResponse(true)
        setTipo(props?.prop?.tipo)
        setInfos(props?.prop?.data)
        if (props?.prop?.tipo == 'in'){
            setCorretagem(props?.prop?.data?.cost_corr_in)
            setBtc(props?.prop?.data?.cost_btc_in)
        } else{
            setCorretagem(props?.prop?.data?.cost_corr_out)
            setBtc(props?.prop?.data?.cost_btc_out)
        }
    }, [show])

    async function handleCostSubmit(){
        try {

            const postOffersOrders = await api.put(`/api/v1/longShort/updateLongShortOperationCost`, 
            {
              tipo: tipo,
              cost_corr: parseFloat(corretagem),
              cost_btc: parseFloat(btc),
              id: parseInt(infos.id)
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: {message : 'Custos da operação atualizados com sucesso!'}})
            setShow(true)
            setCorretagem(0)
            setBtc(0)
            setTipo('')
            setInfos()
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar custos' : error.response.data.message } })
            setShow(true)
            setLoading(false)
        }
    }

    return (
        <div className='myReservationDetails'>
            {
                <>
                    <div className='myReservationsHeader'>
                        <p>Editar custos de{props?.prop?.tipo == 'in' ? ' montagem' : ' desmontagem'}</p>
                        <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                    </div>
                    <div className='myReservationEmptyBody'>
                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <CurrencyTextField
                                label="Corretagem"
                                variant="outlined"
                                value={corretagem}
                                currencySymbol="R$"
                                className={classes.styleForClients}
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) => {
                                    if (value === '' || value === 0) {
                                        setCorretagem(0)
                                    } else {
                                        setCorretagem(value);
                                    }
                                }}
                            />
                        </div>

                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <CurrencyTextField
                                label="Btc"
                                variant="outlined"
                                value={btc}
                                currencySymbol="R$"
                                className={classes.styleForClients}
                                outputFormat="number"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                onChange={(event, value) => {
                                    if (value === '' || value === 0) {
                                        setBtc(0)
                                    } else {
                                        setBtc(value);
                                    }
                                }}
                            />
                        </div>

                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            Total: {toBRL(corretagem+btc)}
                        </div>

                        <div className="itemBodyOperateProductAbc fieldsOperateAbc">
                            <button className="buttonOperateSubmitABC" onClick={() => handleCostSubmit()}>Enviar</button>
                        </div>

                        
                    </div>
                </>
            }
        </div>
    )

}