import React from "react";
/*Styles*/
// import "./styles/recomendations.css";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
// import { WisirTrackingTable } from "./tables/wisirTracking.table";
// import { ClientRecord } from "./modalWisir/componentsModalWisir/clientRecord";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";
// import {ImportMovimentation} from './modalWisir/componentsModalWisir/importMovimentation';
import { MappingTable } from './tables/wisir.map.table'
import { DetalhesMapeamento } from './modal/mapping.details'

export function WisirMapping() {
  const { type } = useModal();

  return (
    <div className="recomendationsContainer">
      <Modal>
        {
            type.name === "alertMsg" ? (
                <ConfirmationBox msgData={type} />
            ) : 
            type.name === "edit" ? (
                <DetalhesMapeamento prop={type}/>
            // ) : 
            // type.name === "showImport" ? (
            //     <Import value={type.value} strategy={type.strategy} update={type.update} />
            // ) :
            // type.name === "showImportCarteira" ? (
            //     <ImportCarteira value={type.value} strategy={type.strategy} />
            // ) :
            // type.name === "showAddClient" ? (
            //     <AddClient value={type.value} strategy={type.strategy} reload={type.reloadTable} />
            ) : (
                <></>
            )
        }
      </Modal>
        <MappingTable prop={type} />
    </div>
  );
}
