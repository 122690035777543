import React, { useState, useEffect } from 'react'
import './dashboardTextEditor.css'
import './dashboard'

import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai'

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from 'draftjs-to-html'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { useModal } from '../../../contexts/useModal';
import { api } from '../../../services/api';
import { useAuth } from '../../../contexts/useAuth';

/*Components*/
import { ArrowLoader } from '../../../components/loaders/arrowLoader'

export function InfoBoxDash(props) {
  // console.log(props)
  const [position, setPosition] = useState({x: 0,y: 0});
  const [translateFixed, setTranslateFixed] = useState(false);
  const [preventTranslate, setPreventTranslate] = useState(false);

  useEffect(() => {
    position.x = props?.msgData?.x;
    position.y = props?.msgData?.y;
  }, [props?.msgData?.x,props?.msgData?.y]);

  function openWppChat(phone) {
    if(phone != "sem informação"){
      let sendPhone = phone.replaceAll(" ","").replaceAll("(","").replaceAll(")","").replaceAll("-","")
      var win = window.open('https://wa.me/55'+sendPhone, '_blank');
      win.focus();
    }
    else{
      alert("Não possui informação de telefone.")
    }
  }

  function openEmailMsg(email) {
    if(email == "sem informação"){
      alert("Não possui informação de email.")
    }
    else{
      var win = window.open('mailto:'+email, '_blank');
      win.focus();
    }
  }
  
  return(
    <>
      {props.fixed ?
        <div className="infoBoxContainer" style={{left: `calc(${position.x}px)` , top: `calc(${position.y}px + 2rem)`, transform: props.style.transform}}>
          <div className='titleInfoBoxDash'>Contatos</div>
          <div className='containerContato' onClick={(e) => openWppChat(props?.msgData?.celular ? props?.msgData?.celular : "sem informação" )}>
            <div className='logoWPP'><AiOutlineWhatsApp size={20}/></div>
            <button className='buttonWPP'>Telefone: {props?.msgData?.celular ? props?.msgData?.celular : "sem informação" }</button>
          </div>
          <div className='containerContato' onClick={(e) => openEmailMsg(props?.msgData?.email ? props?.msgData?.email : "sem informação" )}>
            <div className='logoEMAIL'><AiOutlineMail size={20}/></div>
            <button className='buttonEMAIL'>Email: {props?.msgData?.email ? props?.msgData?.email : "sem informação" }</button>
          </div>
          <div className='textInfoBoxDash'>Filial: {props?.msgData?.filial ? props?.msgData?.filial : "sem informação" }</div>
        </div>
        :
        <div className="infoBoxContainer" style={{left: `calc(${position.x}px)` , top: `calc(${position.y}px + 2rem)`}}>
          <div className='titleInfoBoxDash'>Contatos</div>
          <div className='containerContato'>
            <div className='logoWPP'><AiOutlineWhatsApp size={20}/></div>
            <button className='buttonWPP'>Telefone: {props?.msgData?.celular ? props?.msgData?.celular : "sem informação" }</button>
          </div>
          <div className='containerContato'>
            <div className='logoEMAIL'><AiOutlineMail size={20}/></div>
            <button className='buttonEMAIL'>Email: {props?.msgData?.email ? props?.msgData?.email : "sem informação" }</button>
          </div>
          <div className='textInfoBoxDash'>Filial: {props?.msgData?.filial ? props?.msgData?.filial : "sem informação" }</div>
        </div>
      }
    </>
  )
}