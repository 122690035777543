/*Componente transferencia de Clientes
/*Descrição: Efetua a transferencia de clientes de um assessor para outro/e ou de um 
/*Broker para outro
/*Desenvolvio por: Equipe Rafael Cezário
*/

//libraries
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
//services
import { api } from '../../../../services/api';
import { getAdvisors, getBrokers, getMyClients, getMyClientsToTransfer } from '../../../../services/asyncFunctions';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';
//hooks
import { useModal } from '../../../../contexts/useModal';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
//styles
import './styles/users.toForClients.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
//Utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

export function ToForClients(props) {
    
    const { tokenKey } = useAuth();
    const classes = styleClientsInput();
    const [listBrokers, setListBrokers] = useState([]);
    const [listAdvisors, setListAdvisors] = useState([]);
    const [myClientsList, setMyClientsList] = useState([]);
    const [selectedUser, setselectedUser] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [labelFor, setLabelFor] = useState('');
    const { setShow, edited, setEdited, setType } = useModal();
    const userToChangeName = props.userData.value.full_name;
    const userToChangeId = props.userData.value.external_id;
    const userToChangeTypeID = props.userData.value.type_access;
    const userToChangeProfileID = props.userData.value.profile_id;
    const userToChangeStatus = props.userData.value.active;
    const userToChangeAction = props.userData.value.action;
    const typeOfLink = props.userData.value.linkType;
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false)
    const { screenY } = ModalSizeFitToScreen()

    useEffect(async() => {

        setLoading(true);
        typeOfLink === 'linkNewBroker' ? setLabelFor('Selecione o broker') : setLabelFor('Selecione o assessor');

        const brokersGet = await getBrokers(1, tokenKey)
        if(brokersGet?.isError){
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: brokersGet?.error
                }
            })
            setShow(true);
        }else {
            setListBrokers(brokersGet);
        }

        const advisorsGet = await getAdvisors(1, tokenKey)
        if(advisorsGet?.isError){
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: advisorsGet?.error
                }
            })
            setShow(true);
        }else {
            setListAdvisors(advisorsGet);
        }

        const myClientsGet = await getMyClientsToTransfer(userToChangeId, tokenKey)
        if(myClientsGet?.isError){
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: myClientsGet?.error
                }
            })
            setShow(true);
        }else {
            setMyClientsList(myClientsGet);
        }
        setLoading(false);
    }, [])

    async function handleSubmit() {
        let confirmationMessage = '';

        if (selectedClients?.length === 0) {
            alert('Selecione algum cliente')
            return
        }

        if (typeOfLink === 'linkNewBroker') { confirmationMessage = `Ao efetuar a transferência, todos os clientes selecionados do Broker ${userToChangeId + ' - ' + userToChangeName} serão transferidos para o broker ${selectedUser.external_id + ' - ' + selectedUser.full_name}, deseja continuar?` }
        if (typeOfLink === 'linkNewAdvisor') { confirmationMessage = `Ao efetuar a transferência, todos os clientes selecionados do assessor ${userToChangeId + ' - ' + userToChangeName} serão transferidos para o assessor ${selectedUser.external_id + ' - ' + selectedUser.full_name}, deseja continuar?` }

        if (window.confirm(confirmationMessage)) {
            setLoading(true);
            try {
                const userUpdate = await api.post('/api/v1/users/transferClients', {
                    "from_user_external_id": parseInt(userToChangeId),
                    "to_user_external_id": parseInt(selectedUser.external_id),
                    "users_type_id": parseInt(userToChangeTypeID),
                    "users_profile_id": userToChangeProfileID > 0 ? parseInt(userToChangeProfileID) : undefined,
                    "clients_external_ids": selectedClients

                },
                    {
                        headers: {
                            authorization: `Bearer ${tokenKey}`
                        }
                    })

                const myClientsResult = await api.get(`api/v1/users/${userToChangeId}/myClients?transfer=1&&client_status=0,1,2,3,4`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })

                setMyClientsList(myClientsResult?.data?.data);

                if (userUpdate.data.status === 201 ) {

                    if (userToChangeAction === 'disableUser' && myClientsResult?.data?.data?.length === 0) {
                        setLoading(true);
                        try {
                            await api.put(`/api/v1/users/${userToChangeId}/status`,
                                {
                                    "active": userToChangeStatus === true ? 0 : 1
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer ${tokenKey}`
                                    }
                                })
                            setEdited(!edited);
                            setSuccessMessage(`${userUpdate.data.message} Desativado o usuário: ${userToChangeName}`)
                            setLoading(false);
                        } catch (err) {
                            alert(err?.response?.data?.message)
                            setLoading(false);
                        }
                    } else {
                        setSelectedClients([]);
                        setSuccessMessage(userUpdate.data.message)
                        setEdited(!edited);
                        setLoading(false);
                    }

                }
            } catch (error) {
                console.log(error?.response)
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            }

        }
    }

    function filterListBrokers() {

        return listBrokers.filter((broker) => {
            if (broker.type_access === userToChangeTypeID && broker.profile_id === userToChangeProfileID && broker.external_id != userToChangeId) {
                return broker
            }
        })
    }

    function filterListAdvisors() {

        return listAdvisors.filter((advisor) => {
            if (advisor.type_access === userToChangeTypeID && advisor.external_id != userToChangeId) {
                return advisor
            }
        })
    }

    function checkAll(check) {
        const checkBoxes = document.querySelectorAll('input[name="usersToForCheckBox"]');
        setChecked(check);
        let listToInsert = [];

        myClientsList?.map((client) => {
            listToInsert.push(parseInt(client?.client_external_id))
        })
        if (check === true) {
            setSelectedClients(listToInsert)
        }
        else {
            setSelectedClients([]);
        }
        checkBoxes.forEach((checkBox) => {
            checkBox.checked = check;
        })
    }

    return (

        <div className='usersToForContainer' style={{maxHeight: screenY}}>
            {loading ?
                (
                    <div className='usersToForLoader'>
                        <ArrowLoader />
                    </div>
                ) :
                (<>
                    <div className='usersToForHead'>
                        <p>Transferência de clientes</p>
                        <button onClick={() => setShow(false)}>x</button>
                    </div>
                    <div className='usersToForBody'>
                        {successMessage?.length > 0 && myClientsList?.length === 0 ?
                            (
                                <div className='usersToForBodySuccessMsg'>
                                    <a>{successMessage}</a>
                                </div>

                            ) :
                            (
                                <>
                                    <div className='usersToForOptions'>
                                        <a>Transferir de:  </a>
                                        <a>{userToChangeId + ' - ' + userToChangeName}</a>
                                        <a>Para: </a>
                                        <label >
                                            <Autocomplete
                                                options={typeOfLink === 'linkNewBroker' ? filterListBrokers() : typeOfLink === 'linkNewAdvisor' ? filterListAdvisors() : { full_name: 'Lista vazia' }}
                                                onChange={(event, newValue) => {
                                                    setselectedUser(newValue);
                                                }}
                                                getOptionLabel={(x) => x.external_id + ' - ' + x.full_name}
                                                getOptionSelected={(option, val) => option.value === val.value}
                                                renderInput={(params) =>
                                                    <TextField
                                                        label={labelFor}
                                                        className={classes.root}
                                                        {...params} variant="filled" />}
                                            />
                                        </label>
                                    </div>
                                    <div id='usersToForTableContainer'>
                                        <table id='usersToForTable'>
                                            <thead>
                                                <tr>
                                                    <th><input type='checkbox'
                                                        id='userToForClientsSelectionAllInput'
                                                        onClick={() => {
                                                            checkAll(!checked)
                                                        }}></input>Selecionar</th>
                                                    <th>Cliente</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    myClientsList?.map((clientToTransfer) => {
                                                        return <tr>
                                                            <td><input name='usersToForCheckBox' type='checkbox' onChange={
                                                                (e) => {
                                                                    if (e.target.checked) {
                                                                        setSelectedClients([...selectedClients, parseInt(clientToTransfer?.client_external_id)])
                                                                    } else {
                                                                        selectedClients.splice(selectedClients.indexOf(parseInt(clientToTransfer?.client_external_id)), 1)
                                                                        setSelectedClients(selectedClients)
                                                                    }
                                                                }
                                                            }></input></td>
                                                            <td>{clientToTransfer?.client_external_id + " - " + titleize(clientToTransfer?.client_name)}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )
                        }
                    </div >
                    {successMessage?.length > 0 && myClientsList?.length === 0 ?
                        (<button type='submit' onClick={() => { setShow(false); setSuccessMessage('') }}>Fechar</button>) :
                        (<button type='submit' onClick={() => handleSubmit()}>Transferir</button>)
                    }
                </>)
            }
        </div >
    )
}