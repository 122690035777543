/**
 * Densenvolvido por: Equipe Tiago Murilo
 * Descrição: Tela com listagem dos FAQ cadastrados e mais modal para cadastramento
 **/
//libraries
import React, { useState, useEffect } from 'react'
import { FiPlus, FiTrash, FiBookOpen} from 'react-icons/fi'
//hooks
import { useModal } from '../../../contexts/useModal'
import { useAuth } from '../../../contexts/useAuth';
//services
import { api } from '../../../services/api';
//styles
import styles from './styles/faq.module.css'
//components
import Modal from '../../../components/modal/modal';
import { ArrowLoader } from '../../../components/loaders/arrowLoader';
import { FaqCadastration } from './faqEducation.cadastration';

export function FaqEducation(){
    const { show, setShow, edited, setEdited } = useModal()
    const [indexAccordion, setIndexAccordion] = useState(null)
    const [faqs, setFaqs] = useState([])
    const [formatedFaqs, setFormatedFaqs] = useState([])
    const [reloading, setReloading] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token')
    const { userType } = useAuth()

    useEffect(() => {
        async function getData(){
            const faqsTag = []
            const faqsFormated = []
            setLoading(true)
            setReloading(false)
            setEdited(false)
            const faqs = await api.get(`/api/v1/faq`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            setFaqs(faqs.data.data)

            faqs.data.data.map(faq =>{
                if(faqsTag.indexOf(faq.tag) == -1){
                    faqsTag.push(faq.tag)
                }
                return faq
            }).map(faq => {
                const index = faqsTag.indexOf(faq.tag);

                if(faqsFormated[index] == undefined){
                    faqsFormated[index] = {
                        tag: faq.tag,
                        list: [faq]
                    }
                }else if(faqsFormated[index] != undefined){
                    faqsFormated[index].list.push(faq)
                }
                
            })
            setFormatedFaqs(faqsFormated)

            setLoading(false)
        }

        getData();

    }, [reloading, edited])

    async function openPathLink(pathLink, title, isFile) {

        if(pathLink == undefined || pathLink==''){
            return alert('Não foi atrelado nenhum Link ou Arquivo a este FAQ.')
        }
        if(isFile == 0){
            if(new RegExp("^(https?://)", "i").test(pathLink)){
                return window.open(pathLink)
            }       
            return window.open("http://"+pathLink) 
        }else{
            setLoading(true)
            try{
                const fileExtensio = ['.xls', '.xlsx', '.png', '.jpeg', '.gif', '.pjpeg', '.pdf', 'text/plain', '.zip','.pptx', '.ppt', '.doc', '.docx'] 
                const fileContentTypes = [
                    'application/vnd.ms-excel', 
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
                    'image/png',
                    'image/jpeg',
                    'image/gif',
                    'image/pjpeg',
                    'application/pdf',
                    'text/plain',
                    'application/zip',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.ms-powerpoint',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

                ] 
                const responseFile = await api.get('/api/v1/uploads/faq/'+pathLink, {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
                
                const url = URL.createObjectURL(new Blob([responseFile.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title}${fileExtensio[fileContentTypes.indexOf(responseFile.data.type)]}`)
                document.body.appendChild(link)
                link.click()
                setLoading(false)
                return;
            }catch(error){
                setLoading(false)
                return alert(error.response.data.message)
            }
            
        }
    }

    async function handleDelete(id){
        try{
            setLoading(true)
            await api.delete(`api/v1/faq/${id}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })   
            setReloading(true)
        }catch(error){
            setLoading(false)
            return alert(`Erro ao realizar exclusão de FAQ`)
        }
       
    }

    return (
        <div className={styles.containerFAQ} style={{minWidth: !show ? '1000px' : '0'}}>
            <Modal>
                <FaqCadastration tagsData={formatedFaqs}/>
            </Modal>
            <div className={styles.faqHeader}>
                <p>Educacional</p>
                {
                    userType < 3 ?
                    (
                        <div>
                            <button onClick={() => setShow(true)}>
                                <FiPlus/>Novo
                            </button>
                        </div>
                    ) : (<></>)
                }
            </div>
            <div className={styles.faqContainer}>
                <div className={styles.accordion}>
                    {formatedFaqs.length > 0 ? 
                        (
                            formatedFaqs.map((faq, index) => {
                            return (
                                <div className={styles.tab}>
                                    <input id={'tab-' + index} type="checkbox" onClick={() => setIndexAccordion(index)} checked={indexAccordion == index ? true : false}/>
                                    <label for={'tab-' + index}>{faq.tag}</label>
                                    {faq.list.map(items => {
                                        return (
                                            <>
                                                <div className={styles.tabContent}>
                                                    <a onClick={() => openPathLink(items.path_link, items.title, items.is_file)}><p>{items.title} - {items.subject}</p></a>
                                                    {
                                                        userType < 3 ?
                                                        (
                                                            <div>
                                                                {
                                                                    loading == false ? 
                                                                    (
                                                                        <>
                                                                            <a></a>
                                                                            <a></a>
                                                                            <FiBookOpen className={styles.faqIconsEffect} onClick={() => openPathLink(items.path_link, items.title, items.is_file)}/>
                                                                            <FiTrash className={styles.faqIconsEffect} onClick={() => handleDelete(items.id)}/>
                                                                        </>
                                                                    ) :
                                                                    (
                                                                        <div class={styles.ldsellipsis}><div></div><div></div><div></div><div></div></div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : 
                                                        (
                                                            <div>
                                                                {
                                                                    loading == false ?
                                                                    (
                                                                        <FiBookOpen className={styles.faqIconsEffect} onClick={() => openPathLink(items.path_link, items.title, items.is_file)}/>
                                                                    ) :
                                                                    (
                                                                        <div class={styles.ldsellipsis}><div></div><div></div><div></div><div></div></div>
                                                                    )
                                                                }
                                                                
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    })}
                                    
                                </div>
                            )
                        })

                        
                        
                        ) : (formatedFaqs.length === 0) & loading === true ?
                        (
                            <div>
                                <ArrowLoader />
                            </div>
                        ) : 
                        (
                            <p className={styles.FaqAlignCenter}>Nenhum FAQ encontrado. <br/> Talvez seja necessário solicitar liberação do acesso aos FAQ's para algum administrador do sistema.</p>
                        )
                    }
                   
                </div>
                    
            </div>
            
        </div>
    )
}