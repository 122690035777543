import React from 'react';
import { useAuth } from '../contexts/useAuth';
import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';
import { ArrowLoader } from '../components/loaders/arrowLoader';
import { Preloader } from './preloadeing.routes';

const Routes = () => {

    const { signed, loading, userType } = useAuth();

    // loading até redirecionar;
    if (loading) {
        return (
            <div className='containerLoading'>
                <ArrowLoader/>
            </div>)
    }
    // console.log('signed', signed)
    // Verifica se usuário está logado e envia para rota que deve acessar;
    if (signed === true) {
        return <Preloader />
    } else {
        return <AuthRoutes />
    }

}

export default Routes;