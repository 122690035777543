import React, { useState, useEffect } from "react";
/*Styles*/
import { styledForOfferings } from "../../../../../components/styles/autocompletField";
// import "../../styles/recomendationWisir.css";
/*Components*/

import Modal from "../../../../../components/modal/modal";
import { useAuth } from "../../../../../contexts/useAuth";
import { Autocomplete } from "@material-ui/lab";
import { titleize } from "../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { useModal } from "../../../../../contexts/useModal";
import { TextField } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { getMyClients } from "../../../../../services/asyncFunctions";
import { api } from "../../../../../services/api";
import { ConfirmationBox } from "../../../../../components/confirmationBox/confirmationBox";

export function WisirEditRow(props) {
  console.log(props)
  const { tokenKey, user } = useAuth();

  const [modalityiesForStocks, setModalityiesForStocks] = useState([]);

  const { show, setShow, type, setType, edited, setEdited } = useModal();
  const [loading, setLoading] = useState(true);
  const [clientList, setClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedValue, setSelectedValue] = useState("000");
  const [selectedModality, setSelectedModality] = useState("");
  //   const [selectedTypeStrategie, setSelectedType] = useState("");
  const [selectedObservation, setSelectedObservation] = useState("");
  const classes = styledForOfferings();
  const requiredFields = [
    { field: "selectedClient", value: selectedClient?.external_id },
    { field: "selectedValue", value: selectedValue },
  ];
  const [errorFields, setErrorFields] = useState({
    selectedClient: false,
    selectedValue: false,
  });

  const [ativo, setAtivo] = useState("")
  const [peso, setPeso] = useState(props?.value?.weight)
  const [precoAtual, setPrecoAtual] = useState(props?.value?.value)

  const [assets, setAssets] = useState(null)
  const [indexAtivo, setIndexAtivo] = useState(null)

  function clear() {
    setSelectedClient([]);
    setSelectedValue("");
    setSelectedObservation("");
  }

  useEffect(() => {
    setIndexAtivo(props?.value?.index)
    setAssets(props?.value?.assets)
  }, [show])

  useEffect(() => {
    if(assets != null && indexAtivo != null)
    setAtivo(assets[indexAtivo].asset)
  }, [assets,indexAtivo])

  async function handleEdit(){
    try {
      const editAsset = await api.put(`/api/v1/wisir/controle/${props?.value?.id}/edit`, {
        'asset': ativo,
        'weight': peso,
        'value': precoAtual,
      },
      {
        headers: {
          authorization: `Bearer ${tokenKey}`
        }
      })
      setType({
        name: 'alertMsg',
        type: 'information',
        value: {
            message: "Ativo modificado com sucesso!"
        },
      })
      props.func()
      setAtivo()
   } catch (error) {
    console.log(error)
    alert("Erro ao editar o ativo, entre em contato com o suporte!")
   }
  }

  async function myClientsGet() {
    const clientsResult = await getMyClients(user.external_id, tokenKey);
    if (clientsResult?.isError) {
      setType({
        name: "alertMsg",
        type: "information",
        value: {
          message: clientsResult?.error,
        },
      });
      setShow(true);
    } else {
      const clientsFormatted = [];
      clientsResult.forEach((client) => {
        clientsFormatted.push({
          external_id: client.client_external_id,
          name: client.client_name,
        });
      });
      setClientsList(clientsFormatted);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (selectedClient && clientList.length === 0) {
      setLoading(true);

      myClientsGet();

      setEdited(false);
      setLoading(false);
    }
  }, [loading]);

  function validFields() {
    let errors = false;
    requiredFields.map((field) => {
      if (field.value === "" || field.value === undefined) {
        errorFields[field.field] = true;
        errors = true;
      }
    });
    return errors;
  }

  return (
    <>
      {type.name === "alertMsg" && show ? (
        <ConfirmationBox msgData={type} />
      ) : (
        <>
          <div className="containerClientControl" style={{width: "38rem"}}>
            <div className="clientControlHeader">
              <div className="editBrancheBoxHeader">
                <p>Editar ativo</p>
                <button
                  onClick={() => {setAtivo(null);setAssets(null);setIndexAtivo(null);setShow(false)}}
                  className="modalCloseButton"
                >
                  X
                </button>
              </div>
            </div>
            <div className="assetsOfferingsCardBodySelected noBorderTop" style={{marginTop: "2rem", padding: "0"}}>
              <form className="formEditRow">
                <div style={{display: "grid"}}>
                  <div className='inputTextTitleEdit'>Ativo</div>
                  {assets == null?
                   <></> 
                   :
                   <>
                   {/* {setAtivo(assets[indexAtivo].asset)} */}
                   <Autocomplete
                   options={assets}
                   onChange={(event, newValue) => {
                     setAtivo(newValue?.asset);
                   }}
                   value={assets[indexAtivo]}
                   defaultValue={assets[indexAtivo]}
                   getOptionLabel={(option) => option.asset}
                   id='assetOptions'
                   className='selectAsset'
                   renderInput={(params) => <TextField
                       onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                       {...params} variant="outlined" size="small" />}
                   />
                   </>
                   }
                </div>
                {/* <div style={{display: "grid"}}>
                  <div className='inputTextTitleEdit'>Setor</div>
                  <input type='text' value={setor} placeholder='Insira o setor' className='inputInfoEdit'/>
                </div> */}
                <div style={{display: "grid", width: "94%", margin: "auto"}}>
                  <div className='inputTextTitleEdit'>Peso</div>
                  <TextField type='number' inputProps={{ min: 0, max: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} value={peso} className="currencyTextFieldCustom" variant='outlined' onChange={(e) => {
                    if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                        setPeso(e.target.value)
                    }
                  }} />
                </div>
                <div style={{display: "grid", width: "94%", margin: "auto"}}>
                  <div className='inputTextTitleEdit'>Preço atual</div>
                  <label style={{textAlign: "center"}}>
                  <CurrencyTextField
                      variant="outlined"
                      value={precoAtual}
                      className='currencyTextFieldCustom'
                      currencySymbol="R$"
                      outputFormat="number"
                      decimalCharacter=","
                      required={true}
                      digitGroupSeparator="."
                      onChange={(event, value) => {
                          if (value === '' || value === 0) {
                              setPrecoAtual(0)
                          } else {
                              setPrecoAtual(value);
                          }
                      }}
                  />
                  </label>
                </div>
                <div style={{gridColumnStart: "1", gridColumnEnd: "4", display: "flex", marginTop: "1.6rem", marginBottom: "1rem"}}> 
                  <button
                    type="submit"
                    className="assetsOfferingsReservConfirmation"
                    onClick={(e) => handleEdit()}
                  >
                    Salvar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}
