/*Descrição: tela para efetuar indicação de novos clientes a serem atendidos
/*pela mesa RV
/*Desenvolvido por: Equipe Rafael Cezário
*/
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
/*Styles*/
import './styles/clientsIndicationBox.css';
import { TextField, Checkbox, FormControl, FormLabel, Radio, FormControlLabel } from '@material-ui/core';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
/*Services*/
import { api } from '../../../../services/api';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Utils Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
// import { validateEmail } from '../../../../utils/validatorSchemas/emailValidador';
// import { requeridFields } from '../../../../utils/validatorSchemas/requiredFieldsToAPI';
// import { phoneValidator } from '../../../../utils/validatorSchemas/phoneValidator';
// import { phoneTypeValidator } from '../../../../utils/validatorSchemas/phoneTypeValidator';
// import { phoneFormatMask } from '../../../../utils/formatNumberAndText/phoneMask';
import { getMyClientsIndication } from '../../../../services/asyncFunctions';
import { Autocomplete } from '@material-ui/lab';
import { HiPlus } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { styledForOfferings } from "../../../../components/styles/autocompletField";
import { ClientsIndication } from './clientsIndication';

export function ClientIndicationSuccess(props) {
    const { tokenKey, user } = useAuth()
    const [tabActive, setTabActive] = useState( user?.type == 4 ? 'toIndicate': 'pendingIndicationToApproval');
    const { setShow, show, type, setType } = useModal();
    const [loading, setLoading] = useState(true);
    const [clientsList, setClientsList] = useState([]);
    const [clienteAtual, setClienteAtual] = useState({})
    const history = useHistory();

    useEffect(() => {
        setLoading(false);
        setClienteAtual({})
    }, [show])

    return (
      <div className='clientsIndicationMainContainerBox'>
        <div className=''>
            {loading ?
                (
                    <div className='clientsIndicationLoader'>
                        <ArrowLoader />
                    </div>
                ) :
                (
                    <div className='clientsIndicationSucessBoxModal'>
                        <div className='tituloSucessoIndicacao'>Cliente indicado com sucesso!</div>
                        <div className='containerClienteAporte'>
                          <div className='infoClienteSucesso'>{props.id} - {props.name}</div>
                          <div className='containerOpcoesSucessoIndicacao'>
                            <div className='indicarClientesContainer'>
                              <div className='textoOpcaoSucesso'>Gostaria de indicar<br/>mais clientes?</div>
                              <button className='assetsOfferingsReservConfirmation' onClick={() => setShow(false)} style={{width: "30%", marginTop: "1rem"}}>Clique aqui</button>
                            </div>
                          </div>
                          <div style={{width: "100%", textAlign: "center"}}><button className='closeIndicationSuccess' onClick={() => setShow(false)}>Fechar</button></div>
                        </div>
                    </div >
                )
            }
        </div >
    </div >
    )

}