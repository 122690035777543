import React, { useEffect, useState } from "react";

import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"

import Modal from "../../../../components/modal/modal"
import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox"
import { getDatabaseUpdateTime } from "../../../../services/asyncFunctions"

import { CubastarRentabilityImport } from "./importCubastarRentabilityTable";
import { CubastarRentabilityCurveImport } from "./importCubastarRentabilityCurve";
import { EventsCalendarAlocationImport } from "./importEventsCalendarAlocation";
import { FundsSuggestionsImport } from "./importFundsSuggestions";

export function AlocacaoConfig() {
    const { type } = useModal()
    const { user, tokenKey } = useAuth()
    const { setType, setShow, edited, setEdited } = useModal();
    const [loading, setLoading] = useState(true);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    

    async function getDatabaseUpdateTimeValues() {
        const bases = 'negativeBTC';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                // console.log(dateFormatted);
                setLoading(false);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (databaseUpdateTime.length === 0) {
            getDatabaseUpdateTimeValues();
        }
    }, []);

    return (
        <>
            <Modal>
                {type.name === "alertMsg" ? (
                    <ConfirmationBox msgData={type} />
                ) : 
                type.name === "importCubastarRentabilityTable" ? (
                    <CubastarRentabilityImport prop={type} />
                ) : 
                type.name === "importCubastarCurvesTable" ? (
                    <CubastarRentabilityCurveImport prop={type} />
                ) : 
                type.name === "importFundsSuggestions" ? (
                    <FundsSuggestionsImport prop={type} />
                ) : 
                type.name === "importEventsCalendarAlocation" ? (
                    <EventsCalendarAlocationImport prop={type} />
                ) : 
                    <></>
                }
            </Modal>
            <div style={{
                margin: '1rem'
            }}>

                {/* <div style={{backgroundColor: '#dbdbdb'}}>
                Hora de atualização {databaseUpdateTime} 
                </div> */}
                <h3>Arquivos Alocação - Importações</h3>

                <div>
                    <div style={{ display: 'flex', columnGap: '2rem', marginTop: '2rem' }}>
                    <button
                        className="buttonTableThead"
                        onClick={() => {
                        setType({ name: "importCubastarRentabilityTable" });
                        setShow(true);
                        }}
                        >
                        + Importar
                    </button>
                    <div>Tabelas de Rentabilidade - Carteiras Cubastar</div>    
                    </div>

                    <div style={{ display: 'flex', columnGap: '2rem', marginTop: '2rem' }}>
                    <button
                        className="buttonTableThead"
                        onClick={() => {
                            setType({ name: "importCubastarCurvesTable" });
                            setShow(true);
                        }}
                        >
                        + Importar
                    </button>
                    <div>Curvas de Rentabilidade - Carteiras Cubastar</div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '2rem', marginTop: '2rem' }}>
                    <button
                            className="buttonTableThead"
                            onClick={() => {
                              setType({ name: "importFundsSuggestions" });
                              setShow(true);
                            }}
                            >
                            + Importar
                          </button>
                    <div>Sugestões de Troca de Fundos e Call de Saída</div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '2rem', marginTop: '2rem' }}>
                    <button
                            className="buttonTableThead"
                            onClick={() => {
                              setType({ name: "importEventsCalendarAlocation" });
                              setShow(true);
                            }}
                            >
                            + Importar
                          </button>
                    <div>Calendário de Eventos</div>
                    </div>
                </div>

            </div>
            {/* {(user.type === 4 && [25245, 20412, 22403, 72269, 21203, 'admin'].includes(user.external_id)) ? <DashboardAdvisor prop={type} /> : <Dashboard prop={type} />} */}
        </>
    )
}
