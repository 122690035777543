/*Componente cadastro de clientes
/*Descrição: Efetua o cadastro de novos clientes no sistema e chama o componente de importação
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
//styles
import '../styles/details.css';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
import { makeStyles } from "@material-ui/core/styles";
import { BsWhatsapp, BsTelephone, BsPeople, BsChatSquareText, BsCpu, BsChatDots, BsCardList, BsBoxArrowUpLeft, BsPersonPlus } from "react-icons/bs";
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai";

//services
import { api } from '../../../../services/api';
//hooks
import { useModal } from '../../../../contexts/useModal';
//Contexts
import { useAuth } from '../../../../contexts/useAuth';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//utils
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';
import { FiMinus, FiPlus } from 'react-icons/fi';

export function WisirDetails(props) {
    console.log('pROPS', props)
    const { user, tokenKey } = useAuth();
    const [selectedProductNetAderence, setSelectedProductNetAderence] = useState('')
    const [selectedProductName, setSelectedProductName] = useState('')
    const [selectedProductStart, setSelectedProductStart] = useState('')
    const [selectedProductFinish, setSelectedProductFinish] = useState('')
    const [selectedProductAderence, setSelectedProductAderence] = useState('')
    const [selectedProductType, setSelectedProductType] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const [custody, setCustody] = useState([])
    const [filters, setFilters] = useState([]);
    const [items, setItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [itemQty, setItemQty] = useState(30);
    const [loadingTimeline, setLoadingTimeline] = useState(false);

    const [comentTimeline, setComentTimeline] = useState("");

    const [types, setTypes] = useState([])
    const [estruturadas, setEstruturadas] = useState([])
    const [offers, setOffers] = useState([])
    const classes = styleClientsInput()
    const token = localStorage.getItem('@auth:token')
    const { show, setShow, setType } = useModal()
    const [loading, setLoading] = useState(true)
    const { screenY } = ModalSizeFitToScreen()
    const [categories, setCategories] = useState([])
    var auxCategories = useRef({
        categories: []
    });
    const [contactType, setContactType] = useState(5);


    const requiredFields = [
        { field: 'selectedProductName', value: selectedProductName },
        { field: 'selectedProductType', value: selectedProductType },
        { field: 'selectedProductAderence', value: selectedProductAderence },
        { field: 'selectedProductNetAderence', value: selectedProductNetAderence },
        { field: 'selectedProductStart', value: selectedProductStart },
        { field: 'selectedProductFinish', value: selectedProductFinish },
    ]
    const [errorFields, setErrorFields] = useState({
        selectedProductName: false,
        selectedProductType: false,
        selectedProduct: false,
        selectedProductAderence: false,
        selectedProductNetAderence: false,
        selectedProductStart: false,
        selectedProductFinish: false,
        selectedCategories: false
    })

    async function filterTimeline(all) {
        var todosItems = all ? all : structuredClone(allItems)
        var filteredItemsAux = []
        if(filters.length > 0){
            filteredItemsAux = todosItems.filter((e) => filters.indexOf(parseInt(e.type)) >= 0)
        }
        else{
            filteredItemsAux = todosItems
        }
        await setFilteredItems(filteredItemsAux)
        await setItems(filteredItemsAux.length - 30 < 0 ? filteredItemsAux : filteredItemsAux.slice(filteredItemsAux.length - 30))
        scrollBottom()
    }
    
    async function getTimeline() {
      await setLoadingTimeline(true);
      try {
          const timeline = await api.get(`/api/v1/vba/clientTimeline/${props?.value?.id}`, {
              headers: {
                  authorization: `Bearer ${tokenKey}`,
              },
          });
          
          await setAllItems(timeline?.data?.data);
          // await setFilteredItems(timeline?.data?.data);
          await filterTimeline(timeline?.data?.data)
          // await setItems(timeline?.data?.data.length - itemQty < 0 ? timeline?.data?.data : timeline?.data?.data.slice(timeline?.data?.data.length - itemQty));
          
          await setLoadingTimeline(false);
          scrollBottom();
      } catch (error) {
          alert(error?.response?.data?.message);
      }
    }
    
    async function updateItemsList() {
      var auxAllItems = structuredClone(filteredItems);
      var objDiv = document.getElementById("timelineCommentBoxWisir");
      var scrollNext = objDiv?.scrollHeight;
      if (itemQty != auxAllItems.length) {
          if (auxAllItems.length - (itemQty + 30) <= 0) {
              await setItems(auxAllItems);
              await setItemQty(auxAllItems.length);
          } else {
              await setItems(auxAllItems.slice(auxAllItems.length - (itemQty + 30)));
              await setItemQty(itemQty + 30);
          }
          if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight - scrollNext;
      }
    }

    async function scrollBottom() {
        if (props?.value?.client_status != "prospect" && props?.value?.client_status != "transferido") {
            var objDiv = document.getElementById("timelineCommentBoxWisir");
            objDiv.scrollTop = objDiv?.scrollHeight;
        }
      }

async function handleSubmitComment() {
    if (comentTimeline !== null && comentTimeline != "") {
        try {
        
        const clientUpdate = await api.post(
            `/api/v1/vba/sendComment/${props.value.id}`,
            {
                comment: comentTimeline.replaceAll("'", "''"),
                commentType: contactType,
            },
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            }
        );
        setComentTimeline("");
        setContactType(5);
        getTimeline();
        // setType({ name: 'alertMsg', type: 'information', value: { message: `Comentário cadastrado.` } })
        } catch (error) {
            console.log(error)
            alert(error?.response?.data?.message);
        }
    }
}

    useEffect(() => {
        setLoading(true)
        clear()
        getClientDetails()
        getTypes()
        getOperations()
        getTimeline()
        setLoading(false);
    }, [show])

    const useStyles = makeStyles((theme) => ({
        timelineNewCommentContactTypeActive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: contactType == 5 ? "#EADDCA" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineNewCommentContactTypeInactive: {
            width: "2rem",
            height: "2rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineFilterType1Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#A4F097",
            color: "#165102",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType2Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#CBE6FF",
            color: "#001D82",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType3Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#FFC8C8",
            color: "#A90000",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType4Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#FEFFBC",
            color: "#BA9C00",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType5Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#f3ece1",
            color: "#2b1800",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType0Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#a8a8a8",
            color: "#0e0e0e",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterType6Active: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#cee4e0",
            color: "#003a2f",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            cursor: "pointer",
        },
        timelineFilterTypeInactive: {
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: "#d3d3d3",
            display: "grid",
            padding: "0.2rem",
            paddingTop: "0.15rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            cursor: "pointer",
            marginLeft: "0.4rem",
            marginRight: "0.4rem",
            "&:hover": {
                backgroundColor: "#b8b8b8",
            },
        },
        timelineNewComentWriteArea: {
            resize: "none",
            borderRadius: "10px",
            border: "1px solid #dadada",
            backgroundColor: "#f8f8f8",
            padding: "0.5rem",
            fontSize: "1rem",
            width: "calc(100% - 2rem)",
            "&:focus": {
                outline: "none",
                border: contactType == 5 ? "2px solid #412500" : contactType == 1 ? "2px solid #165102" : contactType == 2 ? "2px solid #001D82" : contactType == 3 ? "2px solid #A90000" : "2px solid #BA9C00",
            },
        },
        timelineNewCommentSendActive: {
            width: "3rem",
            height: "3rem",
            backgroundColor: contactType == 5 ? "#d3d3d3" : contactType == 1 ? "#A4F097" : contactType == 2 ? "#CBE6FF" : contactType == 3 ? "#FFC8C8" : "#FEFFBC",
            color: contactType == 5 ? "#412500" : contactType == 1 ? "#165102" : contactType == 2 ? "#001D82" : contactType == 3 ? "#A90000" : "#BA9C00",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            "&:hover": {
                backgroundColor: contactType == 5 ? "#b8b8b8" : contactType == 1 ? "#c5ffbb" : contactType == 2 ? "#e9f4ff" : contactType == 3 ? "#ffdbdb" : "#feffd3",
                boxShadow: contactType == 5 ? "0px 0px 4px #b8b8b8" : contactType == 1 ? "0px 0px 4px #165102" : contactType == 2 ? "0px 0px 4px #001D82" : contactType == 3 ? "0px 0px 4px #A90000" : "0px 0px 4px #BA9C00",
            },
        },
        timelineNewCommentSendInative: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
        timelineComentBoxUserComentName: {
            width: "3rem",
            height: "3rem",
            backgroundColor: "#d3d3d3",
            color: "#b8b8b8",
            display: "grid",
            padding: "0.55rem",
            paddingLeft: "0.65rem",
            borderRadius: "8px",
            transition: "all ease-in-out 0.2s",
            marginTop: "auto",
            marginRight: "0.4rem",
            marginBottom: "0.15rem",
            border: "none",
            cursor: "default",
        },
    }));

    const classesContactType = useStyles();

    async function alterCategoryTitle(id, title) {
        auxCategories.current.categories[id].title = title
    }

    async function alterCategoryLink(id, link) {
        auxCategories.current.categories[id].link = link
    }

    async function addCategory() {
        setLoading(true)
        auxCategories.current.categories.push({
            title: null,
            link: null
        })
        var aux = categories
        aux.push(aux.length)
        await setCategories(aux)
        setLoading(false)
        
    }

    async function removeCategory(id) {
        setLoading(true)
        auxCategories.current.categories.splice(id, 1)
        var aux = categories
        aux.splice(id, 1)
        await setCategories(aux)
        setLoading(false)
    }

    async function handleSubmit(e) {
        // console.log("GOTCHA")
        // console.log({
        //     products: [{
        //         description: selectedProductName,
        //         aderence: parseFloat(selectedProductAderence),
        //         net_aderence: parseFloat(selectedProductNetAderence),
        //         start: selectedProductStart,
        //         finish: selectedProductFinish,
        //         type: selectedProductType.id,
        //         reference: selectedProduct?.id ? selectedProduct.id : null,
        //         categories: auxCategories.current.categories
        //     }]
        // })
        // setLoading(true)
        let errors = false;
        const aux = errorFields

        if (categories.length == 0 ) {
            aux['selectedCategories'] = true;
            errors = true;
        } else {
            aux['selectedCategories'] = false;
            auxCategories.current.categories.map((x) => {
                console.log('verificando', x)
                if (x.title == null || x.title == undefined || x.title == '') {
                    aux['selectedCategories'] = true;
                    errors = true;
                } if (x.link != null && x.link != undefined && !x.link?.trim().length) {
                    aux['selectedCategories'] = true;
                    errors = true;
                }
            })

        }

        
        requiredFields.map((required) => {
            if (typeof (required.value) !== 'object' && !required?.value?.toString()?.trim()?.length) {
                aux[required.field] = true
                errors = true
            } else {
                aux[required.field] = false
            }
        })
        setErrorFields(aux)

        if (errors) {

            await new Promise(resolve => setTimeout(resolve, 1000))
            alert('Campos obrigatórios não preenchidos')
            setLoading(false)
            return
        }

        e.preventDefault();

        if (selectedProductName === '' || selectedProductType === ''
            || selectedProductAderence === '' || selectedProductNetAderence === ''
            || selectedProductStart === '' || selectedProductFinish === '') {
            alert('Preencha todos os campos')
            return
        }

        auxCategories.current.categories.map((x) => {
            if (x.title === '') {
                alert('Preencha todos os campos');
                return
            }
        })

        try {
            setLoading(true)
            await api.post('/api/v1/abc', {
                    products: [{
                        description: selectedProductName,
                        aderence: parseFloat(selectedProductAderence),
                        net_aderence: parseFloat(selectedProductNetAderence),
                        start: selectedProductStart,
                        finish: selectedProductFinish,
                        type: selectedProductType.id,
                        reference: selectedProduct?.id ? parseInt(selectedProduct.id) : null,
                        categories: auxCategories.current.categories
                    }]
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
            setLoading(false);
            setType({ name: 'alertMsg', type: 'information', value: { message: `Sucesso ao cadastrar o produto ${selectedProductName}` } })
        } catch (error) {
            console.log(error.response)
            alert(error?.response?.data?.message)
            setShow(false)
        }

    }

    async function getClientDetails() {
        try {
            const client_details = await api.get(`/api/v1/wisir2/${props.value.strategy}/custody/${props.value.id}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            console.log(client_details?.data?.data)
            setCustody(client_details?.data?.data);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    async function getOperations() {
        const operations = await api.get(`/api/v1/abc/operations`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        // console.log('OPERAÇÕES', operations?.data?.data)
        setOffers(operations?.data?.data?.ofertas_publicas != null ? operations?.data?.data?.ofertas_publicas : []);
        setEstruturadas(operations?.data?.data?.estruturadas != null ? operations?.data?.data?.estruturadas : []);
        setLoading(false)
    }

    async function getTypes() {
        const types = await api.get(`/api/v1/abc/infos/types`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
        console.log(types?.data?.data)
        setTypes(types?.data?.data)

        setLoading(false)
    }

    function clear() {
        setComentTimeline("");
        setContactType(5)
        setFilters([])
        setItems([])
        setFilteredItems([])
        setEstruturadas([])
        setOffers([])
        setCategories([])
        setCustody([])
        auxCategories.current.categories = []
        setSelectedProductName('')
        setSelectedProduct(null)
        setSelectedProductType(null)
        setSelectedProductAderence('')
        setSelectedProductNetAderence('')
        setSelectedProductStart('')
        setSelectedProductFinish('')
        setErrorFields({
            selectedProductName: false,
            selectedProductType: false,
            selectedProductAderence: false,
            selectedProductNetAderence: false,
            selectedProductStart: false,
            selectedProductFinish: false,
            selectedCategories: false
        })
    }

    return (
        
        <div className='productCadastrationContainerAbc' style={{
            maxHeight: screenY
        }}>
            {loading ?
                (
                    <div className='clientsCadastrationLoading'>
                        <ArrowLoader />
                    </div>
                ) :
                (
                    <>
                        <div className='productCadastrationHeaderAbc'>
                            <span>{props.value.id} - {titleize(props.value.name)}</span>
                            <button onClick={() => {
                                clear()
                                setShow(false)
                            } }>x</button>
                        </div>
                        <div className="wisir2DetailsContainer">
                            <div className="wisir2DetailsSide">
                                <span className="subTitle">Custódia</span>
                                <div className="wisir2DetailsSideBody">
                                {custody.map((c) => {
                                    return (
                                    <div className="wisir2CustodyItem">
                                        <div id={c.asset} className="wisir2CustodyItemLine">
                                            <span className="wisir2CustodyItemLineLabel">Ativo: </span>
                                            <a className="wisir2CustodyItemLineValue">{c.asset}</a>
                                        </div>
                                        <div id={c.asset + 'qty'} className="wisir2CustodyItemLine">
                                            <span className="wisir2CustodyItemLineLabel">Qty. Atual: </span>
                                            <a className="wisir2CustodyItemLineValue">{c.current_qty}</a>
                                        </div>
                                        <div id={c.asset + 'value'} className="wisir2CustodyItemLine">
                                            <span className="wisir2CustodyItemLineLabel">Valor Atual: </span>
                                            <a className="wisir2CustodyItemLineValue">{c.value}</a>
                                        </div>
                                        <div id={c.asset + 'qty_correta'} className="wisir2CustodyItemLine">
                                            <span className="wisir2CustodyItemLineLabel">Qty. Ideal: </span>
                                            <a className="wisir2CustodyItemLineValue">{c.teoretical_qty}</a>
                                        </div>
                                    </div>
                                    )
                                })}
                                </div>
                            </div>
                            <div className="verticalBarVbaClients"></div>
                            <div className="containerTimeline">
                                <div className="timelineTitleWisir">
                                    <div>Linha do tempo</div>
                                    <div className="timelineFilters">
                                        Filtrar:
                                        <div
                                            className={filters.indexOf(1) >= 0 ? classesContactType.timelineFilterType1Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(1) >= 0) {
                                                    filters.splice(filters.indexOf(1),1)
                                                }
                                                else{
                                                    filters.push(1)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Whatsapp">
                                            <BsWhatsapp style={{ margin: "auto" }} size={18} />
                                        </div>
                                        <div
                                            className={filters.indexOf(2) >= 0 ? classesContactType.timelineFilterType2Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(2) >= 0) {
                                                    filters.splice(filters.indexOf(2),1)
                                                }
                                                else{
                                                    filters.push(2)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Ligação">
                                            <BsTelephone style={{ margin: "auto" }} size={18} />
                                        </div>
                                        <div
                                            className={filters.indexOf(3) >= 0 ? classesContactType.timelineFilterType3Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(3) >= 0) {
                                                    filters.splice(filters.indexOf(3),1)
                                                }
                                                else{
                                                    filters.push(3)
                                                }
                                                filterTimeline()
                                            }}
                                            title="E-mail">
                                            <AiOutlineMail style={{ margin: "auto" }} size={18} />
                                        </div>
                                        <div
                                            className={filters.indexOf(4) >= 0 ? classesContactType.timelineFilterType4Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(4) >= 0) {
                                                    filters.splice(filters.indexOf(4),1)
                                                }
                                                else{
                                                    filters.push(4)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Reunião">
                                            <BsPeople style={{ margin: "auto" }} size={18} />
                                        </div>
                                        <div
                                            className={filters.indexOf(5) >= 0 ? classesContactType.timelineFilterType5Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(5) >= 0) {
                                                    filters.splice(filters.indexOf(5),1)
                                                    
                                                }
                                                else{
                                                    filters.push(5)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Apenas Comentário">
                                            <BsChatDots style={{ margin: "auto" }} size={18} />
                                        </div>
                                        
                                        <div
                                            className={filters.indexOf(0) >= 0 ? classesContactType.timelineFilterType0Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(0) >= 0) {
                                                    filters.splice(filters.indexOf(0),1)
                                                }
                                                else{
                                                    filters.push(0)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Sistema">
                                            <BsCpu style={{ margin: "auto" }} size={18} />
                                        </div>
                                        <div
                                            className={filters.indexOf(6) >= 0 ? classesContactType.timelineFilterType6Active : classesContactType.timelineFilterTypeInactive}
                                            onClick={() => {
                                                if (filters.indexOf(6) >= 0) {
                                                    filters.splice(filters.indexOf(6),1)
                                                }
                                                else{
                                                    filters.push(6)
                                                }
                                                filterTimeline()
                                            }}
                                            title="Formulário Ativação">
                                            <BsCardList style={{ margin: "auto" }} size={18} />
                                        </div>
                                    </div>
                                </div>

                                <div 
                                    className="timelineCommentBoxWisir" 
                                    id="timelineCommentBoxWisir"
                                    onScroll={(e) => {
                                        if (e.target.scrollTop < 10) updateItemsList();
                                    }}
                                >
                                    {items.length > 0 ? (
                                        items.map((item) => {
                                            return item.user.id == user.external_id ? (
                                                item.type == "6" ? (
                                                    <div className="timelineComentBoxUserComentRight">
                                                        <div className={"timelineComentBoxUserComentHeaderRight formHeaderColor"}>
                                                            <div className="timelineComentBoxUserComentIconRight">
                                                                <BsCardList title="Formulário respondido antes da ativação" />
                                                            </div>
                                                            <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                        </div>
                                                        <div className={"timelineComentBoxUserComentMessageRight formMessageColor"}>
                                                            {/* <button
                                                                className="buttonShowFinishedForm"
                                                                onClick={() => {
                                                                    showClientActivation(2);
                                                                }}>
                                                                Formulário de primeira reunião
                                                                <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                            </button> */}
                                                        </div>
                                                        <div className={"timelineComentBoxUserComentDateRight formDateColor"}>{item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}</div>
                                                    </div>
                                                ) : (
                                                    <div className="timelineComentBoxUserComentRight">
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentHeaderRight whatsAppHeaderColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentHeaderRight phoneHeaderColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentHeaderRight emailHeaderColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentHeaderRight meetHeaderColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentHeaderRight commentHeaderColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentHeaderRight systemHeaderColor"
                                                                    : "timelineComentBoxUserComentHeaderRight indicationHeaderColor"
                                                            }>
                                                            <div className="timelineComentBoxUserComentIconRight">
                                                                {item.type == 1 ? <BsWhatsapp title="Contato por WhatsApp" /> : item.type == 2 ? <BsTelephone title="Contato por ligação" /> : item.type == 3 ? <AiOutlineMail title="Contato por e-mail" /> : item.type == 4 ? <BsPeople title="Contato por reunião" /> : item.type == 5 ? <BsChatDots title="Mensagem sem classificação" /> : item.type == 0 ? <BsCpu title="Mensagem do sistema" /> : <BsPersonPlus title="Indicação de cliente" />}
                                                            </div>
                                                            <div className="timelineComentBoxUserComentNameRight">{item.user.name}</div>
                                                        </div>
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentMessageRight whatsAppMessageColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentMessageRight phoneMessageColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentMessageRight emailMessageColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentMessageRight meetMessageColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentMessageRight commentMessageColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentMessageRight systemMessageColor"
                                                                    : "timelineComentBoxUserComentMessageRight indicationMessageColor"
                                                            }>
                                                            {item.text}
                                                            <>
                                                                {item?.informations?.map((line) => {
                                                                    return (
                                                                        <div>
                                                                            {line.key} <b>: </b> {line.value}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </>
                                                        </div>
                                                        <div
                                                            className={
                                                                item.type == 1
                                                                    ? "timelineComentBoxUserComentDateRight whatsAppDateColor"
                                                                    : item.type == 2
                                                                    ? "timelineComentBoxUserComentDateRight phoneDateColor"
                                                                    : item.type == 3
                                                                    ? "timelineComentBoxUserComentDateRight emailDateColor"
                                                                    : item.type == 4
                                                                    ? "timelineComentBoxUserComentDateRight meetDateColor"
                                                                    : item.type == 5
                                                                    ? "timelineComentBoxUserComentDateRight commentDateColor"
                                                                    : item.type == 0
                                                                    ? "timelineComentBoxUserComentDateRight systemDateColor"
                                                                    : "timelineComentBoxUserComentDateRight indicationDateColor"
                                                            }>
                                                            {item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}
                                                        </div>
                                                    </div>
                                                )
                                            ) : item.type == "6" ? (
                                                <div className="timelineComentBoxUserComentLeft">
                                                    <div className={"timelineComentBoxUserComentHeaderLeft formHeaderColor"}>
                                                        <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                        <div className="timelineComentBoxUserComentIconLeft">
                                                            <BsCardList title="Formulário respondido antes da ativação" />
                                                        </div>
                                                    </div>
                                                    <div className={"timelineComentBoxUserComentMessageLeft formMessageColor"}>
                                                        {/* <button
                                                            className="buttonShowFinishedForm"
                                                            onClick={() => {
                                                                showClientActivation(2);
                                                            }}>
                                                            Formulário de primeira reunião
                                                            <BsBoxArrowUpLeft style={{ marginLeft: "0.4rem" }} size={14} />
                                                        </button> */}
                                                    </div>
                                                    <div className={"timelineComentBoxUserComentDateLeft formDateColor"}>{item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}</div>
                                                </div>
                                            ) : (
                                                <div className="timelineComentBoxUserComentLeft">
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentHeaderLeft whatsAppHeaderColor"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentHeaderLeft phoneHeaderColor"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentHeaderLeft emailHeaderColor"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentHeaderLeft meetHeaderColor"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentHeaderLeft commentHeaderColor"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentHeaderLeft systemHeaderColor"
                                                                : "timelineComentBoxUserComentHeaderLeft indicationHeaderColor"
                                                        }>
                                                        <div className="timelineComentBoxUserComentNameLeft">{item.user.name}</div>
                                                        <div className="timelineComentBoxUserComentIconLeft">
                                                            {item.type == 1 ? <BsWhatsapp title="Contato por WhatsApp" /> : item.type == 2 ? <BsTelephone title="Contato por ligação" /> : item.type == 3 ? <AiOutlineMail title="Contato por e-mail" /> : item.type == 4 ? <BsPeople title="Contato por reunião" /> : item.type == 5 ? <BsChatDots title="Mensagem sem classificação" /> : item.type == 0 ? <BsCpu title="Mensagem do sistema" /> : <BsPersonPlus title="Indicação de cliente" />}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentMessageLeft whatsAppMessageColor"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentMessageLeft phoneMessageColor"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentMessageLeft emailMessageColor"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentMessageLeft meetMessageColor"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentMessageLeft commentMessageColor"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentMessageLeft systemMessageColor"
                                                                : "timelineComentBoxUserComentMessageLeft indicationMessageColor"
                                                        }>
                                                        {item.text}
                                                        <>
                                                            {item?.informations?.map((line) => {
                                                                return (
                                                                    <div>
                                                                        {line.key} <b>: </b> {line.value}
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    </div>
                                                    <div
                                                        className={
                                                            item.type == 1
                                                                ? "timelineComentBoxUserComentDateLeft whatsAppDateColor"
                                                                : item.type == 2
                                                                ? "timelineComentBoxUserComentDateLeft phoneDateColor"
                                                                : item.type == 3
                                                                ? "timelineComentBoxUserComentDateLeft emailDateColor"
                                                                : item.type == 4
                                                                ? "timelineComentBoxUserComentDateLeft meetDateColor"
                                                                : item.type == 5
                                                                ? "timelineComentBoxUserComentDateLeft commentDateColor"
                                                                : item.type == 0
                                                                ? "timelineComentBoxUserComentDateLeft systemDateColor"
                                                                : "timelineComentBoxUserComentDateLeft indicationDateColor"
                                                        }>
                                                        {item.created_at.split("T")[0].split("-")[2] + "/" + item.created_at.split("T")[0].split("-")[1] + "/" + item.created_at.split("T")[0].split("-")[0] + " - " + item.created_at.split("T")[1].split(".")[0]}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : loadingTimeline ? (
                                        <ArrowLoader />
                                    ) : (
                                        <div className="emptyTimelineMessage">Não há mensagens...</div>
                                    )}
                                </div>
                                <div className="timelineNewCommentBox">
                                    <div className="timelineNewCommentContactType">
                                        <div className="timelineNewCommentContactTypeTitle">Tipo de contato</div>
                                        <div
                                            className={contactType == 1 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                            onClick={() => {
                                                setContactType(1);
                                                if (contactType == 1) {
                                                    setContactType(5);
                                                }
                                            }}
                                            title="Whatsapp">
                                            <BsWhatsapp style={{ margin: "auto" }} size={24} />
                                        </div>
                                        <div
                                            className={contactType == 2 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                            onClick={() => {
                                                setContactType(2);
                                                if (contactType == 2) {
                                                    setContactType(5);
                                                }
                                            }}
                                            title="Ligação">
                                            <BsTelephone style={{ margin: "auto" }} size={24} />
                                        </div>
                                        <div
                                            className={contactType == 3 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                            onClick={() => {
                                                setContactType(3);
                                                if (contactType == 3) {
                                                    setContactType(5);
                                                }
                                            }}
                                            title="E-mail">
                                            <AiOutlineMail style={{ margin: "auto" }} size={24} />
                                        </div>
                                        <div
                                            className={contactType == 4 ? classesContactType.timelineNewCommentContactTypeActive : classesContactType.timelineNewCommentContactTypeInactive}
                                            onClick={() => {
                                                setContactType(4);
                                                if (contactType == 4) {
                                                    setContactType(5);
                                                }
                                            }}
                                            title="Reunião">
                                            <BsPeople style={{ margin: "auto" }} size={24} />
                                        </div>
                                    </div>
                                    <div className="timelineNewCommentWriteSend">
                                        <textarea
                                            className={classesContactType.timelineNewComentWriteArea}
                                            value={comentTimeline}
                                            onChange={(e) => {
                                                setComentTimeline(e.target.value);
                                            }}></textarea>
                                        <button
                                            className={comentTimeline !== "" && comentTimeline !== null ? classesContactType.timelineNewCommentSendActive : classesContactType.timelineNewCommentSendInative}
                                            onClick={() => {
                                                if (comentTimeline !== "" && comentTimeline !== null) {
                                                    handleSubmitComment();
                                                }
                                            }}>
                                            <AiOutlineSend size={30} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)}
        </div>
    )
}