import React, { useState, useEffect } from 'react'
import '../styles/assetsOfferingsNew.table.css'
import { styledForOfferings } from '../../../../../components/styles/autocompletField'
import { FiTrash2 } from 'react-icons/fi'
/*Contexts*/
import { useAuth } from '../../../../../contexts/useAuth'
import { useModal } from '../../../../../contexts/useModal'
/*Components*/
import { ArrowLoader } from '../../../../../components/loaders/arrowLoader'
import Modal from '../../../../../components/modal/modal'
import { Autocomplete } from '@material-ui/lab'
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { getAllClients, getMyClientsWithDetatched } from '../../../../../services/asyncFunctions'
import { getMyClients } from '../../../../../services/asyncFunctions'
import { getAssetClass } from '../../../../../services/asyncFunctions'
import { getOffers } from '../../../../../services/asyncFunctions'
import { getOffersTypes } from '../../../../../services/asyncFunctions'
import { titleize } from '../../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { toBRL } from '../../../../../utils/formatNumberAndText/toBRLCurrency'
import { api } from '../../../../../services/api'
import { height } from '@mui/system'
import { FaEdit } from "react-icons/fa";

export function AssetsOfferingsNewTable() {

    const { screenX, screenY } = ModalSizeFitToScreen()
    const [modalityiesForStocks, setModalityiesForStocks] = useState([])
    const [stockOffers, setStockOffers] = useState([])
    const [offersTypes, setOffersTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const { tokenKey, user } = useAuth()
    const [clientList, setClientsList] = useState([{ external_id: 'Digite 3 caracteres', name: '' }])
    const [selectedClient, setSelectedClient] = useState([])
    const [selectedValue, setSelectedValue] = useState('')
    const [selectedModality, setSelectedModality] = useState('')
    const [selectedObservation, setSelectedObservation] = useState('')
    const [vinculated, setVinculated] = useState(false)
    const [justRegister, setJustRegister] = useState(false)
    const [showReservationLine, setShowReservationLine] = useState(10)
    const [showLine, setShowLine] = useState('')
    const [showThisLine, setShowThisLine] = useState(false)
    const classes = styledForOfferings()
    const modalityForSubscription = ['FEE para reserva']
    const [emailCliente, setEmailCliente] = useState("")
    const requiredFields = [{ field: 'selectedClient', value: selectedClient?.external_id }, { field: 'selectedValue', value: selectedValue }, { field: 'emailClient', value: emailCliente }]
    const [errorFields, setErrorFields] = useState({
        selectedClient: false,
        selectedValue: false
    })
    const [searchClientValue, setSearchClientValue] = useState('')
    const [handleSearch, setHandleSearch] = useState(false)

    async function myClientsGet(search) {

        const clientsResult = await getMyClientsWithDetatched(user.external_id, tokenKey, search)
        if (clientsResult?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: clientsResult?.error
                }
            })
            setShow(true);
        } else {
            
            if (clientsResult){
                const clientsFormatted = []
                clientsResult.forEach((client) => {
                    clientsFormatted.push({
                        external_id: client.client_external_id,
                        name: client.client_name,
                        email: client.email
                    })
                })
                // console.log(clientsFormatted)
                setClientsList(clientsFormatted)
            }
            else {
                setClientsList(
                    [
                        { external_id: '', name: '' }
                    ]
                    )
                }
            setLoading(false)

        }
    }

    async function clienstGet(search) {

        // setLoading(true)
        const clientsResult = await getAllClients(tokenKey, 1, search)
        if (clientsResult?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: clientsResult?.error
                }
            })
            setShow(true);
        } else {
            if (clientsResult){

                setClientsList(clientsResult)
                setLoading(false)
            }
            else{
                setClientsList(
                    [
                        { external_id: '', name: '' }
                    ]
                )
            }
        }
    }

    async function getOffersTypesAPI() {

        const offersTypesGet = await getOffersTypes(tokenKey)
        if (offersTypesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: offersTypesGet?.error
                }
            })
            setShow(true);
        } else {
            setOffersTypes(offersTypesGet)
        }
    }

    async function offersStocksGet() {

        setLoading(true)
        const offersGet = await getOffers(tokenKey)
        if (offersGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: offersGet?.error
                }
            })
            setShow(true);
        } else {

            const stockOffersFiltered = offersGet.filter((offer) => {

                if (offer.assets_class_id === 1) {
                    return offer
                }
            })
            setStockOffers(stockOffersFiltered)
        }
        setLoading(false)
    }

    function validFields() {

        let errors = false
        requiredFields.map((field) => {
            if (justRegister && field.field == 'emailClient') {
            }
            else if (field.value === '' || field.value === undefined || field.value === null) {
                errorFields[field.field] = true
                errors = true
            }
        })
        return errors
    }

    async function handleSubmitOffersOrder(e) {

        e.preventDefault()

        if (validFields()) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
                setSelectedClient([])
                setSelectedValue('')
                return alert('Campos obrigatórios não preenchidos, verifique')
            }, 300)
        } else {
            setLoading(true)
            try {
                
                const postOffersOrders = await api.post(`/api/v1/offersOrders`, {
                    "offers_id": showLine.id,
                    "clients_external_id": parseInt(selectedClient.external_id),
                    "value": selectedValue,
                    "modality": selectedModality !== '' ? selectedModality : undefined,
                    "observation": selectedObservation !== '' ? selectedObservation : undefined,
                    "vinculated": vinculated,
                    "justRegister": justRegister,
                    "email": emailCliente !== '' ? emailCliente : undefined
                }, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }
                })
                console.log(postOffersOrders);
                setType({ name: 'alertMsg', type: 'information', value: { message: "Oferta enviada com sucesso!" } })
                setShow(true)
                setLoading(false)
                clear()
            } catch (error) {
                console.log(error?.response)
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao solicitar reserva' : error.response.data.message } })
                setShow(true)
            }
        }
        setEmailCliente('')
    }

    const handleDelete = async (offerData) => {
        setShow(false)
        setLoading(true)
        try {
            const offerRemove = await api.delete(`/api/v1/offers/${offerData.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Sucesso ao remover oferta' } })
            setShow(true)
            setEdited(true)
            setLoading(false)
        } catch (error) {
            console.log(error?.response)
            if (error?.response?.data?.status === 409) {
                setType({ name: 'alertMsg', type: 'information', value: { message: 'Não foi possível remover essa oferta, pois a mesma ja foi reservada' } })
                setShow(true)
                setLoading(false)
            } else {
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao remover oferta' : error?.response?.data?.message } })
                setShow(true)
                setLoading(false)
            }
        }

    }

    useEffect(() => {

        if (edited || stockOffers.length === 0) {

            setLoading(true)
            offersStocksGet()
            getOffersTypesAPI()
            // if (user.type < 3) {
            //     clienstGet()
            // } else {
            //     myClientsGet()
            // }
            setEdited(false)
        }

    }, [edited])

    useEffect(() => {

        if (showLine !== '') {

            const modalitiesOptions = []
            const options = Object.keys(showLine)
            const validyOptions = options.filter((fees) => {

                if (fees === 'fee_retail_with_lockup') {
                    if (showLine[fees] !== null && showLine[fees] !== undefined) {
                        modalitiesOptions.push('FEE Varejo com Lockup')
                    }
                }

                if (fees === 'fee_retail_without_lockup') {
                    if (showLine[fees] !== null && showLine[fees] !== undefined) {
                        modalitiesOptions.push('FEE Varejo sem Lockup')
                    }
                }

                if (fees === 'fee_institution_with_lockup') {
                    if (showLine[fees] !== null && showLine[fees] !== undefined) {
                        modalitiesOptions.push('FEE Inst com Lockup')
                    }
                }

                if (fees === 'fee_institution_without_lockup') {
                    if (showLine[fees] !== null && showLine[fees] !== undefined) {
                        modalitiesOptions.push('FEE Inst sem Lockup')
                    }
                }
            })
            setSelectedValue(showLine['minimum'])
            modalitiesOptions.length > 0 ? setModalityiesForStocks(modalitiesOptions) : setModalityiesForStocks([])

        }

    }, [showLine])

    function clear() {

        setSelectedClient([])
        setSelectedValue('')
        setSelectedModality('')
        setSelectedObservation('')
        setShowLine('')
        setVinculated(false)

    }

    async function handleChange (event) {
        if (event.target.value.length > 2){
            if((clientList[0].external_id === 'Carregando') || (clientList[0].external_id === 'Digite 3 caracteres')) {
                setClientsList([{external_id: 'Carregando', name: event.target.value}])
            }
            if (handleSearch == false){
                setHandleSearch(true)
                setSearchClientValue(event.target.value);
                if (user.type < 3) {
                    clienstGet(event.target.value)
                } else {
                    myClientsGet(event.target.value)
                }
            }
        }
        else {
            setHandleSearch(false)
            setClientsList([{ external_id: 'Digite 3 caracteres', name: event.target.value + '...' }])
        }
    };

    return (
        <div className="assetsOfferingsNewTableContainer">
            <div className="assetsOfferingsFilterHeader">
                
                <p>Existem ({stockOffers?.length > 0 ? stockOffers.length : 0}) Ofertas - Ações</p>
                {
                    user?.type < 3 &&
                    <button className='assetsOfferingsBlueButton' onClick={() => { setType({ name: 'offeringsRegistration', class: '1' }); setShow(true) }}>Cadastrar nova oferta</button>
                }
            </div>
            {
                loading ?
                    (
                        <div className='assetsOfferingsLoader'>
                            <ArrowLoader />
                        </div>
                    ) :
                    (
                        <div className="assetsOfferingsCardBodyContainer">
                            {
                                stockOffers?.length > 0 ?
                                    offersTypes.map((offerType, indexType) => {

                                        return stockOffers.map((offer, indexOffer) => {

                                            const startNegotiation = offer?.start_negotiation?.toString().slice(0, 10)
                                            const thisDateStartNegotiation = new Date(startNegotiation + 'T03:00:00.000Z')

                                            const finishNegotiation = offer?.finish_negotiation?.toString().slice(0, 10)
                                            const thisDateFinishNegotiation = new Date(finishNegotiation + 'T03:00:00.000Z')

                                            const finishVNC = offer?.finish_vnc?.toString().slice(0, 10)
                                            const thisDatefinishVNC = new Date(finishVNC + 'T03:00:00.000Z')

                                            const bookbuilding = offer?.bookbuilding?.toString().slice(0, 10)
                                            const thisDatebookbuilding = new Date(bookbuilding + 'T03:00:00.000Z')

                                            const startReserve = offer?.start_of_reserve?.toString().slice(0, 10)
                                            const thisDateStartReserve = new Date(startReserve + 'T03:00:00.000Z')

                                            const finishReserve = offer?.finish_of_reserve?.toString().slice(0, 10)
                                            const thisFinishReserve = new Date(finishReserve + 'T03:00:00.000Z')

                                            const liquidation = offer?.liquidation?.toString().slice(0, 10)
                                            const thisDateLiquidation = new Date(liquidation + 'T03:00:00.000Z')

                                            const minimum = offer?.minimum;

                                            if (offer.offers_types_id === offerType.id) {



                                                return showLine.asset === offer.asset ? (
                                                    <div className='assetsOfferingsCard' style={{
                                                        minHeight: '26rem',
                                                        background: 'white',
                                                        boxShadow: '0px 0px 26px -8px rgba(0,0,0, 1)'
                                                    }}>
                                                        <div className="assetsOfferingsCardHeader">
                                                            <label style={{ color: '#414141' }}>
                                                                <a>{titleize(offer.company_name)}</a>
                                                                <a>{offer.asset}</a>
                                                            </label>
                                                            <div className='offeringsRequestBlueButtonDiv'>
                                                                <button className='assetsOfferingsBlueButton' onClick={() => setShowLine(0)}>Cancelar</button>
                                                            </div>
                                                        </div>
                                                        <div className="assetsOfferingsCardBodySelected">
                                                            <form onSubmit={() => handleSubmitOffersOrder()} className="assetsOfferingsCardHeaderOptions" style={{ gridTemplateColumns: modalityiesForStocks.length > 0 ? 'repeat(4,1fr)' : 'repeat(2,1fr)' }}>
                                                                <label >
                                                                    <Autocomplete
                                                                        options={clientList}
                                                                        className={classes.root}
                                                                        onChange={(event, newValue) => {
                                                                            setEmailCliente("")
                                                                            setSelectedClient(newValue);
                                                                            if(newValue != null){
                                                                                if(newValue.email != null){
                                                                                    setEmailCliente(newValue.email);
                                                                                }
                                                                            }
                                                                            
                                                                        }}
                                                                        onBlur = {() => {
                                                                            setClientsList([
                                                                                {
                                                                                    external_id: 'Digite 3 caracteres', name: ''
                                                                                }
                                                                            ])
                                                                            setHandleSearch(false)
                                                                            setSearchClientValue('')
                                                                        }}
                                                                        getOptionLabel={(option) => option?.external_id + ' - ' + titleize(option.name)}
                                                                        id='client'
                                                                        renderInput={(params) =>
                                                                            <TextField
                                                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                required={true}
                                                                                label='Cliente'
                                                                                className={errorFields['selectedClient'] && selectedClient?.external_id === undefined ? classes.rootWError : classes.root}
                                                                                {...params} variant="outlined"
                                                                                onChange={(e) => {
                                                                                    handleChange(e)
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                    
                                                                </label>
                                                                {justRegister ?
                                                                <div style={{textAlign: 'center', color: 'black'}}>Não haverá disparo de email</div> :
                                                                selectedClient == null || selectedClient.length == 0 ?
                                                                <div style={{textAlign: 'center', color: 'black'}}>Selecione um cliente</div> : 
                                                                (selectedClient.email == null ?
                                                                    <label>
                                                                        <TextField
                                                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                            label={'insira um email'}
                                                                            variant='outlined'
                                                                            onChange={(e) => {
                                                                                setEmailCliente(e.target.value)
                                                                            }}
                                                                            value={emailCliente}
                                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                                            className={classes.rootWError}
                                                                        />
                                                                    </label>
                                                                    :
                                                                    <label>
                                                                        <TextField
                                                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                            label={'Email'}
                                                                            variant='outlined'
                                                                            onChange={(e) => {
                                                                                setEmailCliente(e.target.value)
                                                                            }}
                                                                            value={emailCliente}
                                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                                            className={classes.root}
                                                                        />
                                                                    </label>
                                                                )
                                                                }
                                                                <label>
                                                                    <TextField
                                                                        onKeyPress={(e) => { if (e.code === 'Backquote' || e.key === 'e') e.preventDefault() }}
                                                                        label='Quantidade'
                                                                        variant='outlined'
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length <= 255) {
                                                                                setSelectedValue(parseInt(e.target.value))
                                                                            }
                                                                        }}
                                                                        value={selectedValue}
                                                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                                        className={errorFields['selectedValue'] && selectedValue === '' ? classes.rootWError : classes.root}
                                                                    />
                                                                </label>
                                                                {
                                                                    modalityiesForStocks?.length > 0
                                                                    &&
                                                                    <label>
                                                                        <Autocomplete
                                                                            options={offer.offers_types_id === 3 ? modalityForSubscription : modalityiesForStocks}
                                                                            onChange={(event, newValue) => {
                                                                                setSelectedModality(newValue);
                                                                            }}
                                                                            getOptionLabel={(option) => option}
                                                                            id='modality'
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                    label='Modalidade'
                                                                                    className={classes.root}
                                                                                    {...params} variant="outlined"
                                                                                />
                                                                            }
                                                                        />
                                                                    </label>
                                                                }
                                                            </form>
                                                            {justRegister ? <div style={{color: "red", height: '1rem'}}></div> :
                                                            <div style={{color: "red", height: '1rem'}}>&#x26A0; Utilizar o email de cliente do hub para fins de auditoria &#x26A0;</div>
                                                        }
                                                        
                                                            <label id='assetsOfferingsReservationLabel'>
                                                                <TextField
                                                                    className={classes.textArea}
                                                                    variant='outlined'
                                                                    inputProps={{ maxLength: '200' }}
                                                                    placeholder='Observações'
                                                                    onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                    onChange={(e) => setSelectedObservation(e.target.value)}
                                                                />
                                                            </label>
                                                            <label for='isVinculated'>
                                                                <FormControlLabel 
                                                                    control={
                                                                        <Checkbox
                                                                            checked={vinculated}
                                                                            onChange={(e) => setVinculated(!vinculated)}
                                                                            name="isVinculated"
                                                                            color='primary'
                                                                        />
                                                                    }
                                                                    label={'Vínculo com a XP?'}
                                                                />
                                                                <FormControlLabel 
                                                                    control={
                                                                        <Checkbox
                                                                            checked={justRegister}
                                                                            onChange={(e) => setJustRegister(!justRegister)}
                                                                            name="isJustRegister"
                                                                            color='primary'
                                                                        />
                                                                    }
                                                                    label={'Apenas registro (sem disparo de email)'}
                                                                />
                                                            </label>
                                                            
                                                        </div>
                                                        <div className='assetsOfferingsReservDiv'>
                                                            <button type='submit' className='assetsOfferingsReservConfirmation' style={{marginBottom: '2rem'}} onClick={(e) => handleSubmitOffersOrder(e)}>Confirmar reserva</button>
                                                        </div>
                                                    </div>
                                                ) :
                                                    (
                                                        <div className="assetsOfferingsCard" style={{ minHeight: '22rem' }}>
                                                            <div className="assetsOfferingsCardHeader">
                                                                <label title='Clique para detalhes' className='assetsOfferingsCompanyName' onClick={() => { setType({ name: 'assetOfferingsShowDetails', data: 'assetAPI', assetData: offer, clientList: clientList, modality: modalityiesForStocks }); setShow(true) }}>
                                                                    <a>{offer?.company_name?.toUpperCase()}</a>
                                                                    <a>{offer?.asset?.toUpperCase()}</a>
                                                                </label>
                                                                <div className='assetsOfferingsButtonLabels'>
                                                                    <div id='assetsOfferingsButtonLabelsRecomendation'>
                                                                        <label>
                                                                            <a>Recomendação:</a>
                                                                            <a className='assetsOfferingsRecommendationLabel' style={{ fontWeight: '500', background: offer?.recommendation ? 'rgb(1, 102, 48)' : '#890C00' }}>{offer?.recommendation ? 'Entrar' : 'Não entrar'}</a>
                                                                        </label>
                                                                        <label>
                                                                            <a>{offerType.description}</a>
                                                                            <a>-</a>
                                                                        </label>
                                                                    </div>
                                                                    <div id='assetsOfferingsButtonsRequest'>
                                                                        {user.type < 3 &&
                                                                        <>
                                                                            <button
                                                                                title='Excluir reserva do sistema'
                                                                                className='assetsOfferingsBlueButtonTrash'
                                                                                onClick={() => {
                                                                                    setType({ name: 'alertMsg', value: { message: 'Deseja realmente excluir a oferta selecionada?', func: handleDelete, dataForFunc: offer } });
                                                                                    setShow(true)
                                                                                }}><FiTrash2 />
                                                                            </button>
                                                                            <button title='Editar oferta' className='fiisOfferingsBlueButtonTrash' onClick={() => {setType({ name: 'editOfferingsRegistrationStocks', class: '2', data: offer }); setShow(true) }}><FaEdit /></button>
                                                                        </>
                                                                            }
                                                                        <button title='Solicitar reserva a um cliente' className='assetsOfferingsBlueButton' onClick={() => { setErrorFields([]); clear(); setShowLine(offer) }}>Solicitar reserva</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="assetsOfferingsCardBody">
                                                                <div className="assetsOfferingsCardBodyLeftGrid">
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Setor</a>
                                                                        <a>{offer.assets_sector_description}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Início da reserva</a>
                                                                        <a>{startReserve === undefined ? '' : thisDateStartReserve.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Término VINC</a>
                                                                        <a>{finishVNC === undefined ? '' : thisDatefinishVNC.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Término da reserva</a>
                                                                        <a>{finishReserve === undefined ? '' : thisFinishReserve.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Bookbuilding</a>
                                                                        <a>{bookbuilding === undefined ? '' : thisDatebookbuilding.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Início das negociações</a>
                                                                        <a>{startNegotiation === undefined ? '' : thisDateStartNegotiation.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Liquidação</a>
                                                                        <a>{liquidation === undefined ? '' : thisDateLiquidation.toLocaleString('pt-BR').slice(0, 10)}</a>
                                                                    </div>
                                                                    <div className="assetsOfferingsCardBodyDetails">
                                                                        <a>Quantia mínima</a>
                                                                        <a>{minimum === undefined ? 1 : minimum}</a>
                                                                    </div>
                                                                    <div className="fiisOfferingsCardBodyDetails">
                                                                        <div className='offersValueTag'>
                                                                            <a >Valor</a>
                                                                        </div>
                                                                        <a>
                                                                            {
                                                                                (offer.assets_class_id === 1) ?
                                                                                    offer?.value > 0 ? toBRL(offer.value) : 'N/D'
                                                                                    :
                                                                                    'N/D'
                                                                            }
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className="assetsOfferingsCardBodyRightGrid">
                                                                    {
                                                                        offer.offers_types_id === 3 ?
                                                                            <div className="assetsOfferingsCardBodyDetails">
                                                                                <a>FEE para reserva</a>
                                                                                <a>{offer.fee_retail_without_lockup}%</a>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <div className="assetsOfferingsCardBodyDetails">
                                                                                    <a>FEE varejo sem lockup</a>
                                                                                    <a>{offer.fee_retail_without_lockup}%</a>
                                                                                </div>
                                                                                <div className="assetsOfferingsCardBodyDetails">
                                                                                    <a>FEE varejo com lockup</a>
                                                                                    <a>{offer.fee_retail_with_lockup}%</a>
                                                                                </div>
                                                                                <div className="assetsOfferingsCardBodyDetails">
                                                                                    <a>FEE inst sem lockup</a>
                                                                                    <a>{offer.fee_institution_without_lockup}%</a>
                                                                                </div>
                                                                                <div className="assetsOfferingsCardBodyDetails">
                                                                                    <a>FEE inst com lockup</a>
                                                                                    <a>{offer.fee_institution_with_lockup}%</a>
                                                                                </div>
                                                                                <div className="fiisOfferingsCardBodyDetails">
                                                                                    <a>Prospecto</a>
                                                                                    <a href={offer.prospecto} target="_blank">aqui</a>
                                                                                </div>
                                                                                <div className="assetsOfferingsCardBodyDetailsText">
                                                                                    <a>Expectativa de rateio</a>
                                                                                    <a>{offer.apportionment_expectation}</a>
                                                                                </div>
                                                                            </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                            }
                                        })
                                    })
                                    :
                                    (
                                        <div className='assetsOfferingsEmptyOffers'>
                                            <p>Não existem ofertas cadastradas</p>
                                        </div>
                                    )
                            }
                        </div>
                    )
            }
        </div>
    )
}