/**
 * Componente para importação de dados via excel
 * Desenvolvido por: Equipe TECH RV
 */

import React, { useState, useEffect } from 'react';
/*Styles*/
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './abcModal.css';
/*Utils*/
import XLSX from 'xlsx';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import { SerialDateToJSDate } from '../../../../utils/formatDate/dateSerialToISO_EnUs';
import { formatLineToTablePreview } from '../../../../utils/formatNumberAndText/formatExcelBooksToPreview';
/*Components*/
import DatePicker from 'react-date-picker';
import Stepper from '@material-ui/core/Stepper';
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal';
/*Services*/
import { api } from '../../../../services/api';
import { validyFiles } from '../../../../utils/validyTypesOfFiles';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { FiArrowDown } from 'react-icons/fi';

const useStyles = makeStyles((theme) => ({
root: {
    "& .MuiStepLabel-iconContainer .MuiStepLabel-alternativeLabel": {
        background: 'red'
    },
    fontFamily: 'Roboto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '400'
},

stepperItems: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
},

'& MuiTypography-root .makeStyles-instructions-11 .MuiTypography-body1': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
},
backButton: {
    marginRight: theme.spacing(1),
},
instructions: {
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontFamily: 'Roboto',
    fontSize: '0.9rem !important'
},
}));

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Selecione a planilha no botão acima';
        default:
            return '';
    }
}

 export function AbcManagementImportModal(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [fileName, setFilename] = useState('');
    const steps = ['Selecione o arquivo para ser importado', 'Confira as informações'];
    const [dataExcelAPI, setDataExcelAPI] = useState([]);
    const [excelRows, setExcelRows] = useState([]);
    const [errorList, setErrorsList] = useState([]);
    const [successImport, setSuccessImport] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);
    const [loading, setLoading] = useState(false);
    const { show, setShow, setType, setEdited } = useModal();
    const token = localStorage.getItem('@auth:token');
    const [errorsImport, setErrorsImport] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showFileButton, setShowFileButton] = useState(true);
    const [importPlainMessage, setImportPlainMessage] = useState('');
    const [dateExec, setDateExec] = useState('');
    const fixedDate = new Date();
    const { screenX, screenY } = ModalSizeFitToScreen();

    useEffect(() => {

        setShowFileButton(true);
        setShowErrors(false);
        setActiveStep(0);
        setExcelRows([]);
        setFilename('');
        setSuccessImport(false);
        setErrorsImport(false);

    }, [show])

    const handleNext = async (propsNext) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 1) {
            importIndications(dataExcelAPI)
        }
    }

    async function importIndications(dataFields) {
        setLoading(true);
        try {
            const importAPI = new Promise(async (resolve, reject) => {
                try {
                    const importResults = await api.post(`/api/v1/abc/config/products/${props.prop.product}/clients`, {
                        'clients' : dataFields
                    }, {
                        headers: {
                            authorization: `Bearer ${token}`
                        }
                    })
                    resolve(importResults)
                } catch (error) {
                    reject(error)
                }
            })

            let inProcessLoading = true;
            let timeCount = 0

            do {
                setHideButtons(true);
                if (timeCount == 5) {
                    setImportPlainMessage(`A planilha está em processo de importação.`)
                } else if (timeCount < 10) {
                    setImportPlainMessage(`Este processo pode levar alguns minutos.`)
                } else if (timeCount < 15) {
                    setImportPlainMessage(`Aguarde, o processo está quase acabando.`)
                }

                importAPI.then((value) => {
                    inProcessLoading = false;
                    setHideButtons(false);
                    setLoading(false);
                    setSuccessImport(true);
                    if (value?.data?.errors_details?.length > 0) {
                        setErrorsImport(true);
                        setErrorsList(value?.data?.errors_details);
                    } else {
                        setType({ name: 'alertMsg', type: 'information', value: { message: 'Lista de clientes importada com sucesso!' } })
                    }
                    setEdited(true)
                }).catch((error) => {

                    inProcessLoading = false;
                    if (error?.response?.data?.status === 500 || error?.response?.data?.errors_details === undefined) {
                        setErrorsList([{ error_message: error?.response?.data?.message }]);
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsImport(true);
                    } else {
                        setHideButtons(false);
                        setLoading(false);
                        setErrorsList(error?.response?.data?.errors_details);
                        setErrorsImport(true);
                    }
                })
                await new Promise(resolve => {
                    setTimeout(() => {
                        timeCount += 1
                        resolve()
                        if (timeCount == 20) timeCount = 0
                    }, 1000)
                })
            } while (inProcessLoading == true);
        } catch (error) {
            alert('Erro inesperado.')
            setLoading(false);
        }
    }

    async function readExcelFile(file) {

        setLoading(true);

        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const wb = XLSX.read(bufferArray, { type: 'buffer' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const fieldsInExcel = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: true })
                var schemaToValidate = [
                    { excelValue: 'Código', bdValue: 'Codigo' },
                    { excelValue: 'ID Categoria', bdValue: 'IdCategory' },
                    { excelValue: 'Categoria', bdValue: 'Category' },
                    { excelValue: 'Objetivo', bdValue: 'Objetivo' },
                    { excelValue: 'Financeiro', bdValue: 'Financeiro' },
                    { excelValue: 'Discurso', bdValue: 'Discurso' },
                    // { excelValue: 'Data Reserva', bdValue: 'Data Reserva' },
                ];
                if (props.prop.isPlataforma) schemaToValidate.push({ excelValue: 'Data Reserva', bdValue: 'liquidation_date' })

                let indexIgnored = 0;
                if (fieldsInExcel[0].length < schemaToValidate.length) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                const errors = fieldsInExcel[0].filter((fieldToValidate, index) => {
                if(fieldToValidate != "" && fieldToValidate != ""){
                    if (fieldToValidate !== schemaToValidate[index-indexIgnored]?.excelValue) {
                        return fieldToValidate
                    }
                }
                else{
                    indexIgnored++
                }
                })

                if (errors?.length > 0) {
                    alert('Erro no Layout da planilha, verifique os nomes e a ordem das colunas!')
                    setLoading(false);
                    return
                }
                else {
                    var header = ['Codigo', 'IdCategory', 'Category', 'Objetivo', 'Financeiro', 'Discurso']
                    if (props.prop.isPlataforma) header.push('liquidation_date')
                    const data = XLSX.utils.sheet_to_json(ws, {
                        header: header,
                        cellDates: false
                    });
                    resolve(data)
                }
            }
            fileReader.onerror = ((error) => {
                reject(error);
            })
        })

        var data = await promise
        data.splice(0, 1)
        
        setExcelRows(data);

        let newFormattedData = []
        
        data.map((data) => {
            var obj = {
                'client_id': data.Codigo,
                'category_id': data.IdCategory,
                'objective': data.Objetivo,
                'value': data.Financeiro,
                'speech': data.Discurso != null || data.Discurso != undefined ? data.Discurso : null

            }

            if (props.prop.isPlataforma) {
                // obj['reservation_date'] = data['Data Reserva'] != null || data['Data Reserva'] != undefined ? SerialDateToJSDate(data['Data Reserva']) : null
                obj['liquidation_date'] = data['liquidation_date'] != null || data['liquidation_date'] != undefined ? timestampToDateStringAmerican(data['liquidation_date']) : null
            }
            
            newFormattedData.push(obj);
        });
        
        if (props.prop.isPlataforma) {
            let liquidationDate = newFormattedData.map((data) => {
                return data.liquidation_date
            })
            let liquidationDateFiltered = liquidationDate.filter((data) => {
                return data != null
            })
            if (liquidationDateFiltered.length == 0) {
                alert('A data de liquidação é obrigatória')
                setLoading(false);
            }
        }

        //  setExcelRows(newFormattedData);
        setDataExcelAPI(newFormattedData);
        return setLoading(false);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
    };

    const handleReset = () => {
        setActiveStep(0)
        setSuccessImport(false)
        setErrorsImport(false)
        setErrorsList([])
        setShowErrors(false)
        setHideButtons(false)
        setShowFileButton(true)
        setFilename('')
        setDataExcelAPI([])
        setExcelRows([])
    };

    function handleFileCheck() {
        if (excelRows.length > 0) {
        // if(dateExec != ''){
            if (props.prop.isPlataforma) {
                let liquidationDate = dataExcelAPI.map((data) => {
                    return data.liquidation_date
                })
                let liquidationDateFiltered = liquidationDate.filter((data) => {
                    return data != null
                })
                if (liquidationDateFiltered.length == 0) {
                    alert('A data de liquidação é obrigatória')
                    setLoading(false);
                    return
                } else {
                    handleNext()
                    setShowFileButton(false);
                }
            } else {
                    handleNext()
                    setShowFileButton(false);
            }
        // }
        // else{
        //     alert("Insira uma data de execução \n")
        // }
        }
        else {
        let msg = ''
        msg = msg + 'Carregue a planilha antes \n'
        // if(dateExec == ''){
        //     msg = msg + 'Insira uma data de execução \n'
        // }
            alert(msg)
        }
    }

    function fileClick() {

        document.getElementById('getFile').value = '';
        document.getElementById('getFile').click()
        setSuccessImport(false);
        setErrorsImport(false);
        setShowErrors(false)
    }
    //timestamp começa em 1970 e o excel começa em 1900
    function timestampToDateString(timestamp) {
        // Converter o timestamp para 
        const milliseconds = timestamp * 1000 *60 * 60 * 24;
    
        // Criar um objeto de data
        const date = new Date(milliseconds);
    
        // Extrair dia, mês e ano
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
        const year = date.getFullYear();
    
        // Formatar a data como "dd/mm/yyyy"
        return `${day}/${month}/${year - 70}`;
    }

    //timestamp começa em 1970 e o excel começa em 1900
    function timestampToDateStringAmerican(timestamp) {
        // Converter o timestamp para 
        const milliseconds = timestamp * 1000 *60 * 60 * 24;
    
        // Criar um objeto de data
        const date = new Date(milliseconds);
    
        // Extrair dia, mês e ano
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa do zero
        const year = date.getFullYear();

        return `${year - 70}-${month}-${day}`;
    }
    
    

    function tableForImportReccords() {
        return (
            excelRows?.length > 0 ?
                (<table id='tableForImportReccords'>
                    <thead id='bodyImport'>
                        <tr>
                            <th>Código</th>
                            <th>Categoria</th>
                            <th>Objetivo</th>
                            <th>Financeiro</th>
                            <th>Discurso</th>
                            {props.prop.isPlataforma ? <th>Data Reserva</th> : <></>}
                        </tr>
                    </thead>
                    <tbody id='bodyImport'>

                        {excelRows?.map((excelLine, indexLine) => {
                            const fieldKeys = Object.keys(excelLine)
                            
                            return (
                                <tr>
                                    <td>{excelLine.Codigo}</td>
                                    <td>{excelLine.Category}</td>
                                    <td>{excelLine.Objetivo ? toBRL(excelLine.Objetivo) : '-'}</td>
                                    <td>{excelLine.Financeiro ? toBRL(excelLine.Financeiro) : '-'}</td>
                                    <td>{excelLine.Discurso}</td>
                                    {props.prop.isPlataforma ? <td>{timestampToDateString(excelLine['liquidation_date'])}</td> : <></>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>) :
                (<></>)

        )
    }

    function errorsTable() {
        if (errorList?.length > 0 && errorList[0].error !== undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail.error}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }

        if (errorList?.length > 0 && errorList[0].error_message === undefined) {
            return (
                <table id='tableErrorsImportOperation'>
                    <thead>
                        <tr>
                            <th>Mensagem</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errorList?.map((errorDetail) => {
                            return (
                                <tr>
                                    <td>{errorDetail}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            )
        }


    }

    async function getLayout() {

        try {
            const getLayouts = await api.get(`/api/v1/abc/config/products/${props.prop.product}/clients`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                responseType: 'blob'
            })
    
            const url = URL.createObjectURL(new Blob([getLayouts.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'layout_importação.xlsx')
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.log(error?.response)
            alert(error?.response?.data?.message === undefined ? 'Erro ao buscar Layout' : error?.response?.data?.message)
        }
    }

    return (
        <div className='stepMainContainer' style={{
            width: screenX,
            height: screenY
        }}>
            <div className={classes.root}>
                <div className='stepperDiv'>
                    <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
                    <Stepper className={classes.stepperItems} activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div className='fileInput'>
                    {successImport || hideButtons ?
                        (<></>) :
                        (
                            <>
                                {showFileButton ?
                                    (
                                        <>
                                        <button className='customButton' onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false); fileClick() }}>Selecionar Arquivo</button>
                                        <button className='customButton' onClick={() => getLayout()}>Arquivo Layout <FiArrowDown /> </button>
                                        <a>{fileName.substring(12, fileName.length)}</a>
                                        </>
                                    ) :
                                    (<></>)}

                                <input
                                    type='file'
                                    accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet '
                                    id="getFile"
                                    onChange={(e) => {
                                        if (validyFiles(e.target.files[0], 'excel')) {
                                            return
                                        }
                                        const fileToRead = e.target.files[0];
                                        readExcelFile(fileToRead);
                                        setFilename(e.target.value)
                                    }}
                                >
                                </input>
                            </>
                        )}

                </div>
            </div>
            <div className='fileInput'>
                <a>Importando clientes para o produto</a>
                {loading ? (<div className='stepperLoader'><ArrowLoader />{importPlainMessage}</div>) :
                    successImport && !errorsImport ?
                        (
                            <div id='flexRowDiv' className='stepperImportWithoutErrors'>
                                <a>Sucesso ao importar a lista</a>
                            </div>
                        ) :

                        activeStep === 1 ? (
                            (<>
                                <p id='recommendationsImportConfirm'>Confira os dados</p>
                                <div id='customScroll' className='tableForImportSection'>
                                    {
                                        tableForImportReccords()
                                    } </div>
                            </>)
                        ) :
                            successImport && errorsImport ? (
                                <div id='flexRowDiv' className='stepperImportWithErrors'>
                                    <a>Sucesso ao importar arquivos!</a>
                                    <a>Mas, ocorreram erros na importação</a>
                                    <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                </div>
                            ) : !successImport && errorsImport ?
                                (
                                    <div id='flexRowDiv' className='stepperImportWithErrors'>
                                        <a>ocorreram erros na importação</a>
                                        <button onClick={() => { setShowErrors(true); setSuccessImport(false); setErrorsImport(false) }}>Verificar erros</button>
                                    </div>
                                ) :
                                showErrors ?
                                    (<div id='customScroll' className='tableErrorsImportOperation'>{errorsTable()}</div>)
                                    :
                                    (<div id='customScroll' className='tableForImportSection'>{tableForImportReccords()} </div>)}
                <div>
                    {activeStep === steps.length && successImport ?
                        (
                            <div id='flexRowDiv'>
                                <Typography className={classes.instructions}>Importado com sucesso!</Typography>
                                <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                <Button onClick={() => setShow(false)}>Fechar</Button>
                            </div>
                        ) :
                        activeStep === steps.length && !successImport ?
                            (
                                <div id='flexRowDiv'>
                                    <Button className='customButton' onClick={handleReset}>Reimportar</Button>
                                    <Button onClick={() => setShow(false)}>Fechar</Button>
                                </div>
                            ) :

                            hideButtons ? (<></>) :
                                (
                                    <div>
                                        <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                        <div id='flexRowDiv'>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                            </Button>
                                            <Button
                                                id='buttonImport'
                                                variant="contained"
                                                className='customButton'
                                                onClick={activeStep === steps.length - 1 ? () => handleNext() : () => handleFileCheck()}>
                                                {activeStep === steps.length - 1 ? 'Importar' : 'Próximo'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                </div>
            </div>
        </div>
    );
 }