//libraries
import React, { useEffect, useState, useRef, useReducer } from "react";
import { FiPlus, FiSearch, FiArrowDown, FiCopy } from "react-icons/fi";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem } from "@mui/material";
/*Styles*/
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md";
//services
import { api } from "../../../services/api";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";
import { LongShortMain } from "./longShortMain"
import { LongShortResponseModal } from './tables/longShort.responseModal';
import { LongShortOperationsImport } from "./longShortOperationsImport";
import { LongShortModificationsImport } from "./longShortOperationsListUpdate";
import { ModalEditLongShort } from "./tables/longShort.editModal";

export function LongShort() {
    const { type } = useModal();   
    // console.log(type) 
  
    return (
      <>
      <div style={{ paddingTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
        
        <Modal>
          {type.name === "alertMsg" ? (
            <ConfirmationBox msgData={type} />
          ) :
          type.name === 'showLongShortResponse' ?
          (
              <LongShortResponseModal prop={type}/>
          ) :
          type.name === 'showImportLongShortOperations' ?
          (
            <LongShortOperationsImport prop={type}/>
          ) :
          type.name === 'showUpdateLongShortOperation' ?
          (
            <ModalEditLongShort id={type.id}/>
          ) :
          (
            <></>
          )}
        </Modal>
            <LongShortMain prop={type} />
      </div>
      </>
    );
  }