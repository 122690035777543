import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getBrokerProjected, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { TableCell } from "@material-ui/core";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";


export function IndexProjectedTbl() {
    const [loading, setLoading] = useState(false);
    const [listProjected, setlistProjected] = useState([]);
    const [listProjectedFormatted, setlistProjectedFormatted] = useState([]);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const { tokenKey } = useAuth();
    // const { user, tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [recParc ,setRecParc] = useState(0)
    const [recProj ,setRecProj] = useState(0)
    const [oporRec ,setOporRec] = useState(0)
    const [avgRoa ,setAvgRoa] = useState(0)
    const [avgRoaProj ,setAvgRoaProj] = useState(0)
    const [baseClients ,setBaseClients] = useState(0)
    const [recClients ,setRecClients] = useState(0)
    const [zeroClients ,setZeroClients] = useState(0)
    const [netTotal , setNetTotal] = useState(0)



    async function getProjected() {
        const listProjected = await getBrokerProjected(tokenKey, false);
        // console.log(listProjected);
        if (getBrokerProjected?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getBrokerProjected?.error,
                },
            });
            setShow(true);
        } else {
            setlistProjected(listProjected);
            setLoading(false);
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'roa_dashboard';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                // console.log(dateFormatted);
                setLoading(false);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
                setLoading(false);
            }
        }
    }

    function formateAssets() {
        let projectedFormatted = [];

        listProjected.map((balance) => {
            projectedFormatted.push({
                broker_id: balance.broker_id,
                broker_name: balance.broker_name,
                revenue: balance.revenue,
                revenue_projection: balance.revenue_projection,
                roa: balance.roa,
                roa_projection: balance.roa_projection,
                client_list: balance.client_list,
                client_active: balance.client_active,
                broker_leader: balance.broker_leader
            });
        });

        setlistProjectedFormatted(projectedFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listProjected.length === 0) {
            setEdited(false);
            setLoading(true);
            getProjected();
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    useEffect(() => {
        if (listProjected.length > 0) {
            formateAssets();
        }
    }, [listProjected]);

    useEffect(() => {
        // console.log(listProjected);
        var recParc = 0;
        var recProj = 0;
        var oporRec = 0;
        var roa = 0;
        var roaSum = 0;
        var roaProj = 0;
        var roaProjSum = 0;
        var baseClients = 0;
        var recClients = 0;
        var zeroClients = 0;
        var netTotal = 0;
        listProjected.forEach((e) => {
            recParc += e.revenue;
            recProj += e.revenue_projection;
            oporRec += e.net_opportunity;
            roa += e.roa;
            roaProj += e.roa_projection;
            roaSum += 1;
            roaProjSum += 1;
            baseClients += e.client_list;
            recClients += e.client_active;
            zeroClients += e.client_zeroes;
            netTotal += e.net_total;
            // console.log(recParc);
        })
        var avgRoa = recParc * 100 / netTotal
        var avgRoaProj = roaProj / roaProjSum
        
        setRecParc(recParc)
        setRecProj(recProj)
        setOporRec(oporRec)
        setAvgRoa(avgRoa)
        setAvgRoaProj(avgRoaProj)
        setBaseClients(baseClients)
        setRecClients(recClients)
        setZeroClients(zeroClients)
    }, [listProjected]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Broker",
            "Broker",
            "Líder",
            "Receita Parcial",
            "Receita Projetada",
            "Oportunidade de Receita",
            "ROA",
            "ROA Projetado",
            "Clientes na Base",
            "Clientes com Receita",
            "Clientes sem Receita"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "broker_id",
            "broker_name",
            "broker_leader",
            "revenue",
            "revenue_projection",
            "net_opportunity",
            "roa",
            "roa_projection",
            "client_list",
            "client_active",
            "client_zeroes"
        ];
        listProjected.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "revenue" || "revenue_projection" || "roa" || "roa_projection" || "net_opportunity"
                    ? String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function projectedExport() {
        setLoading(true);
        try {
            const myExport = await getBrokerProjected(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "Receita por Broker.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "broker_leader",
            label: "Líder",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                })
            }
        },
        {
            name: "revenue",
            label: "Receita Parcial",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(recParc)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "revenue_projection",
            label: "Receita Projetada",
            options: {
                filter: false,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(recProj)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "net_opportunity",
            label: "Oportunidade de Receita",
            options: {
                filter: false,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <Link to={{pathname: '/roaDashboard', orderRec : "asc"}}>{toBRL(value)}</Link>,
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{toBRL(oporRec)}</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "roa",
            label: "ROA",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{toPercent(value)}</span>,
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{avgRoa.toFixed(2)}%</p>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "roa_projection",
            label: "ROA Projetado",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{toPercent(value)}</span>,
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{avgRoaProj.toFixed(2)}%</p>
                        </div>
                    </TableCell>
                ),
            }
        },
        {
            name: "client_list",
            label: "Clientes na Base",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{baseClients}</p>
                        </div>
                    </TableCell>
                ),
            }
        },
        {
            name: "client_active",
            label: "Clientes com Receita",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                        onClick={() => {
                            sortColumn(index);
                        }}>
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{recClients} ({(recClients*100/baseClients).toFixed(2)}%)</p>
                        </div>
                    </TableCell>
                ),
            }
        },
        {
            name: "client_zeroes",
            label: "Clientes sem Receita",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                    <TableCell 
                    className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                    onClick={() => {
                        sortColumn(index);
                    }}
                        >
                        <div className="textSumCell">
                            <div>
                                {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                                {column.label} {tableMeta.name === name ? tableMeta.direction === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                            </div>
                            <p>{zeroClients} ({(zeroClients*100/baseClients).toFixed(2)}%)</p>
                        </div>
                    </TableCell>
                ),
            }
        }
        
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "60vh",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "A busca não trouxe retornos para o filtro aplicado.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "receita_por_broker.csv",
            separator: ";",
        },
        onDownload: () => {
            projectedExport();
            return false;
        },
        customToolbar: AddButton,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [15, 30, 50],
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div className='tableReceita'>
            <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                title={`Receita Parcial ${databaseUpdateTime}`}
                data={listProjected}
                columns={columns}
                options={options}
                ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

