/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Conteudo com detalhamento de clientes para consulta do broker e assessor
 **/

import React, { useState, useEffect } from 'react'
//hooks
import { useModal } from '../../../../contexts/useModal'
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import formatCurrency from '../../../../utils/formatNumberAndText/formatCurrency';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'

export function MyClientsDetail(props) {

    const { type, setType, setShow } = useModal()
    const [loading, setLoading] = useState(true);
    const [clientData, setClientData] = useState('')
    const [listBrokers, setListBrokers] = useState('')
    const [listAdvisor, setListAdvisor] = useState('')
    const [selectedAdvisor, setSelectedAdvisor] = useState('')
    const [selectedBroker, setSelectedBrokers] = useState('')
    const [selectedClientName, setSelectedClientName] = useState('')
    const [selectedClientProfile, setSelectedClientProfile] = useState('')
    const [selectedOperatesAlone, setSelectedClientOperatesAlone] = useState('')
    const [selectedNetStock, setSelectedNetStock] = useState(0)
    const [selectedNetTotal, setSelectedNetTotal] = useState(0)
    const { screenX, screenY} = ModalSizeFitToScreen()

    useEffect(() => {

        setLoading(true);
        if (type?.value?.broker !== undefined && type?.value?.client !== undefined) {

            setClientData(type?.value?.client);
            setListBrokers(type?.value?.broker)
            setListAdvisor(type?.value?.client);
            setSelectedAdvisor({ external_id: type?.value?.client[0].advisor_external_id, full_name: type?.value?.client[0].advisor_name });
            setSelectedBrokers(type?.value?.broker)
            setSelectedClientName(type?.value.client[0]?.name)
            setSelectedClientProfile(type?.value.client[0]?.client_profile)
            setSelectedClientOperatesAlone(type?.value.client[0]?.operates_alone)
            setSelectedNetStock(numberBRLFormat(type?.value.client[0]?.net_stock))
            setSelectedNetTotal(numberBRLFormat(type?.value.client[0]?.net_total))
            setLoading(false)
        }
    }, [type])

    return (
        <div className='clientDetailsContainer' style={{height: screenY, maxHeight: screenY, minHeight: 500}}>
            {loading ?
                (<div id='clientDetailsLoader'>
                    <ArrowLoader />
                </div>
                ) :
                (
                    <>
                        <div className='clientsDetailsHeader'>
                            <div>
                                <p>Detalhes do Cliente:</p>
                                <p>{clientData[0]?.external_id} - {titleize(clientData[0]?.name)}</p>
                            </div>
                            <button onClick={() => setShow(false)}>x</button>
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className='clientDetailsBody'>
                            <div className='clientsDetailDualDetail'>
                                <div id='relative' className='clientsInformationDiv'>
                                    <a id='clientDetailFirstAElement'>Nome: </a>
                                    <label id='clientsDetailLabelElipsis' title={titleize(clientData[0]?.name)}>
                                        <a>{titleize(clientData[0]?.name)}</a>
                                    </label>
                                </div>
                                <div id='relative' className='clientsBoxHideFields'>
                                    <input className='dinamic' value={selectedClientName?.length > 0 ? selectedClientName : ''} onChange={(e) => setSelectedClientName(e.target.value)}></input>
                                    <label id='labelDinamic'>Nome</label>
                                </div>
                                <div id='relative' className='clientsInformationDiv'>
                                    <a id='clientDetailFirstAElement'>Perfil: </a><a>{titleize(clientData[0]?.client_profile)}</a>
                                </div>
                                <div id='relative' className='clientsBoxHideFields'>
                                    <input className='dinamic' value={selectedClientProfile?.length > 0 ? titleize(selectedClientProfile) : ''} onChange={(e) => setSelectedClientProfile(e.target.value)}></input>
                                    <label id='labelDinamic'>Perfil</label>
                                </div>
                                <div id='relative' className='clientsInformationDiv'>
                                    <a id='clientDetailFirstAElement'>Opera sozinho: </a><a>{titleize(clientData[0]?.operates_alone)}</a>
                                </div>
                                <div id='relative' className='clientsBoxHideFields'>
                                    <input className='dinamic' value={selectedOperatesAlone?.length > 0 ? titleize(selectedOperatesAlone) : ''} onChange={(e) => setSelectedClientOperatesAlone(e.target.value)}></input>
                                    <label id='labelDinamic'>Opera sozinho</label>
                                </div>
                            </div>
                            <div className='clientsDetailDualDetail'>
                                <div id='relative' className='clientsInformationDiv'>
                                    <a id='clientDetailFirstAElement'>Net Modalidades: </a><a>{numberBRLFormat(clientData[0]?.net_stock)}</a>
                                </div>
                                <div id='relative' className='clientsBoxHideFields'>
                                    <input className='dinamic' id='netStocks'
                                        onInput={(e) => { formatCurrency(e.target.id); setSelectedNetStock(e.target.value) }}
                                        value={selectedNetStock !== '' ? selectedNetStock : ''}
                                    ></input>
                                    <label id='labelDinamic'>Net Modalidades</label>
                                </div>
                                <div id='relative' className='clientsInformationDiv'>
                                    <a id='clientDetailFirstAElement'>Net Total: </a><a>{numberBRLFormat(clientData[0]?.net_total)}</a>
                                </div>
                                <div id='relative' className='clientsBoxHideFields'>
                                    <input className='dinamic' id='netTotal'
                                        value={selectedNetTotal !== '' ? selectedNetTotal : ''}
                                        onInput={(e) => { formatCurrency(e.target.id); setSelectedNetTotal(e.target.value) }}
                                    ></input>
                                    <label id='labelDinamic'>Net total</label>
                                </div>
                            </div>
                            <div className='clientDetailsTableDetails'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Assessor</th>
                                        </tr>
                                        <tr>
                                            <th>Código</th>
                                            <th>Assessor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            clientData?.map((advisorData) => {
                                                return (
                                                    <tr>
                                                        <td>{advisorData?.advisor_external_id}</td>
                                                        <td>{titleize(advisorData?.advisor_name)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan='4'>Lista de Brokers</th>
                                        </tr>
                                        <tr>
                                            <th>Código</th>
                                            <th>Broker</th>
                                            <th>Perfil</th>
                                            <th>Indicado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listBrokers?.map((brokerRow) => {
                                                return (
                                                    <tr>
                                                        <td>{brokerRow?.users_external_id}</td>
                                                        <td>{titleize(brokerRow?.user_full_name)}</td>
                                                        <td>{titleize(brokerRow?.profile_description)}</td>
                                                        <td>Sim</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )

}