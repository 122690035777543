import React from "react"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { withStyles } from "@material-ui/core/styles"
import MuiTablePagination from "@mui/material/TablePagination"
import { MdOutlineMonetizationOn, MdOutlineCheckCircleOutline, MdOutbox } from "react-icons/md"
import { ThemeProvider, createTheme } from "@mui/material/styles"

const defaultFooterStyles = {}

const getMuiTheme = () =>
    createTheme({
        // overrides: {
        //     MuiTableRow: {
        //       root: {
        //         '&:hover': {
        //           backgroundColor: 'yellow !important'
        //         }
        //       }
        //     },
        //   },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        justifyContent: "center",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        backgrounColor: "white",
                        padding: "0px !important",
                    },
                    footer: {
                        padding: "0px !important",
                        color: "red",
                    },
                },
            },
            // MuiTableFooter: {
            //     styleOverrides: {
            //         root: {
            //             "& .MuiTableCell-root": {
            //                 padding: "0px !important",
            //             },
            //         },
            //     },
            // },

            MUIDataTableHeadCell: {
                styleOverrides: {
                    contentWrapper: {
                        justifyContent: "center",
                        whiteSpace: "pre-wrap",
                    },
                },
            },

            MUIDataTableFilter: {
                styleOverrides: {
                    root: {
                        minWidth: "40vw",
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        "&:hover ": {
                            backgroundColor: "#e7e7e7 !important",
                            //   borderColor: 'black !important',
                            //   borderRightColor: 'black !important',
                        },
                        "&: hover td": {
                            backgroundColor: "#e7e7e7 !important",
                        },
                        // '&: hover div': {
                        //     backgroundColor: '#e7e7e7 !important'
                        // }
                    },
                },
            },
        },
        root: {
            "&::-webkit-scrollbar": {
                width: 7,
            },
            "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px #bea032`,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#bea032",
                outline: `1px solid slategrey`,
            },
        },
    })

class CustomFooterConfig extends React.Component {
    render() {
        // const { count, classes, textLabels, changePage, changeRowsPerPage, rowsPerPage, page } = this.props;
        // console.log('this.props',this.props)

        return (
            <ThemeProvider theme={getMuiTheme()}>
                <TableFooter>
                    {/* <TableRow> */}
                    <TableCell style={{ padding: "0px" }}>
                        <div className="legendsFooterAbcBox">
                            {/* <div className="legendFooterAbc">
                        <div className="legendFooterHeaderAbc">
                            Geral
                        </div>
                        <div className="legendFooterItemsGeralAbc">
                            <div className="legendFooterItemsAbc"><div className="statusColorFooterDivAbc" style={{ backgroundColor: "#BBD2E3" }}></div>Cliente Avulso</div>
                            <div className="legendFooterItemsAbc"><div className="statusColorFooterDivAbc" style={{ backgroundColor: "##fff" }}></div>VBA </div>
                        </div>
                    </div> */}
                            <div className="legendFooterAbc">
                                <div className="legendFooterHeaderAbc">Status</div>
                                <div className="legendFooterItemsStatusDashAdvisor">
                                    {this.props.optionsIcons.map((item, index) => {
                                        return (
                                            <div className="legendFooterItemsAbc" key={index}>
                                                {item.label}
                                                <span style={{marginLeft: '0.5rem'}}>{item.title2}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {/* <div className="legendFooterAbc">
                        <div className="legendFooterHeaderAbc">
                            Aderência
                        </div>
                        <div className="legendFooterItemsAderenciaAbc">
                            <div className="legendFooterItemsAbc"><div className="aderenceIconsFooterAbc"><MdOutlineMonetizationOn size={16} className={"aderenceIconsFooterAbcActive IconsAbc"}/></div>Aderência ABC</div>
                            <div className="legendFooterItemsAbc"><div className="aderenceIconsFooterAbc"><MdOutlineCheckCircleOutline size={16} className="aderenceIconsFooterAbcActive IconsAbc"/></div>Aderência NET ABC</div>
                        </div>
                    </div> */}
                        </div>
                    </TableCell>
                    <MuiTablePagination
                        component="div"
                        count={this.props.count}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        labelRowsPerPage={this.props.textLabels.rowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${this.props.textLabels.displayRows} ${count}`}
                        backIconButtonProps={{
                            "aria-label": this.props.textLabels.previous,
                        }}
                        nextIconButtonProps={{
                            "aria-label": this.props.textLabels.next,
                        }}
                        rowsPerPageOptions={[50, 150, 500]}
                        onPageChange={(e, i) => {
                            this.props.changePage(i)
                        }}
                        onRowsPerPageChange={(e) => this.props.changeRowsPerPage(e.target.value)}
                    />
                    {/* </TableRow> */}
                </TableFooter>
            </ThemeProvider>
        )
    }
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(CustomFooterConfig)
