import React, { useEffect, useState, useRef, useReducer } from "react"

import { useModal } from "../../../contexts/useModal"
import { AiFillCloseCircle, AiOutlineConsoleSql } from "react-icons/ai"
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import { AiFillDollarCircle, AiFillMessage, AiOutlineWhatsApp, AiOutlineCheckSquare, AiOutlineWarning } from "react-icons/ai"
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
import { FaCheck, FaTimes, FaStar, FaQuestion, FaUserSlash } from "react-icons/fa"
import Select from "react-select"
import "./styles/scenariosControl.css"
import { useAuth } from "../../../contexts/useAuth"
import { styleClientsInput } from "../../../components/styles/autocompletField"
import { getPermissionsAlocacao, getCenariosAlocacao } from "../../../services/asyncFunctions"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import TextField from "@material-ui/core/TextField"
import { FiSave } from "react-icons/fi"
import { api } from "../../../services/api"
import { FiTrash2, FiEdit } from "react-icons/fi"

export function ScenariosControl(props) {
    const { type, show, setShow, setType, setEdited } = useModal()
    const [loading, setLoading] = useState(false)
    const permissions = useRef([])
    const { tokenKey, user } = useAuth()
    const classes = styleClientsInput()
    const [newCenario, setNewCenario] = useState({
        subject: "",
        vision: "",
        avatar: null,
        avatar_path: null,
        spokeperson: null,
        active: true,
        updated_at: datetimeToDatePtBR(new Date()),
    })

    const [cenarios, setCenarios] = useState([])

    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    async function getData() {
        setLoading(true)

        cenarios.map((cen) => {
            cen.onEdit = false
        })
        setLoading(false)
    }

    useEffect(() => {
        if (show) {
            console.log(props)
            setCenarios(props.msgData.data.cenarios)
            getData()
        } else {
        }
    }, [show])

    async function updateCenario(client) {
        client.updated_at = new Date()
        try {
            const response = await api.put(
                `/api/v1/alocacao/cubastar/visions/${client.id}`,
                {
                    titulo: client.subject,
                    descricao: client.vision,
                    status: client.active,
                    spokeperson: client.spokeperson,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )

            if (client.avatar_file && client.avatar_file !== null) {
                const formData = new FormData()
                formData.append("autor", client.avatar_file)
                const response = await api.post(`/api/v1/alocacao/cubastar/visions/${client.id}/autor`, formData, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
            }
            alert("Cenario atualizado com sucesso")
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao atualizar cenario" : error.response.data.message },
            })
            setShow(true)
        }
    }

    function handleImageUpload(event) {
        if(!event.target.files[0])
            return
        const file = event.target.files[0]
        // Aqui você pode fazer o processamento necessário com a imagem selecionada
        console.log("Imagem selecionada:", file)
        setNewCenario({ ...newCenario, avatar_file: file, avatar: URL.createObjectURL(file) })
    }

    async function submitNewCenario() {
        if (newCenario.subject === "") {
            alert("Titulo não pode ser vazio")
            return
        }
        if (newCenario.vision === "") {
            alert("Descrição não pode ser vazio")
            return
        }
        if (newCenario.avatar_file === null) {
            alert("Selecione uma foto para o autor")
            return
        }
        if (newCenario.spokeperson === null){
            alert('Nome do Autor não pode ser nulo')
            return
        }

        newCenario.updated_at = datetimeToDatePtBR(new Date())
        setCenarios([...cenarios, newCenario])
        //função que envia para o backend as informações e a imagem do autor
        const formData = new FormData()
        formData.append("autor", newCenario.avatar_file)

        try {
            const last_id = await api.post(
                `/api/v1/alocacao/cubastar/visions`,
                {
                    titulo: newCenario.subject,
                    descricao: newCenario.vision,
                    status: newCenario.active,
                    spokeperson: newCenario.spokeperson,
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            )

            const response = await api.post(`/api/v1/alocacao/cubastar/visions/${last_id.data.data.id}/autor`, formData, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            // setType({
            //     name: "alertMsg",
            //     type: "information",
            //     value: { message: "Cenario enviado com sucesso" },
            // })
            // setShow(true)
            setEdited(true)
            setNewCenario({
                subject: "",
                vision: "",
                avatar_file: null,
                avatar: null,
                avatar_path: null,
                spokeperson: null,
                active: true,
            })
            forceUpdate()

            alert("Cenario enviado com sucesso")
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao enviar novo cenario" : error.response.data.message },
            })
            setShow(true)
        }
    }

    async function deleteVision(cenario) {
        // console.log(cenario)
        try {
            const response = await api.delete(`/api/v1/alocacao/cubastar/visions/${cenario.id}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            })
            // setType({
            //     name: "alertMsg",
            //     type: "information",
            //     value: { message: "Cenario deletado com sucesso" },
            // })
            // setShow(true)
            alert("Cenario deletado com sucesso")
            setEdited(true)
        } catch (error) {
            console.log(error?.response)
            setType({
                name: "alertMsg",
                type: "information",
                value: { message: error?.response?.data?.message === undefined ? "Erro ao deletar cenario" : error.response.data.message },
            })
            setShow(true)
        }
    }

    function clear() {
        setNewCenario({
            subject: "",
            vision: "",
            avatar_file: null,
            avatar: null,
            avatar_path: null,
            active: true,
        })
    }

    return (
        <div className="containerModalDashDetails">
            {loading ? (
                <div id="clientDetailsLoader">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="editBrancheBoxHeaderDash">
                        <p style={{ marginLeft: "2rem", color: "#D4AC00" }}>Painel de controle de Cenários</p>
                        {/* <div
                            className="saveAndExitButton"
                            onClick={() => {
                                submitNewValues()
                                // clear()
                                // setShow(false)
                            }}
                            >
                            <FiSave size={"22"} /> Salvar e Sair
                        </div> */}
                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", alignItems: "end" }}>
                            <p style={{ marginRight: "1rem" }}>{user.external_id + " - " + user.full_name} </p>
                            <button
                                onClick={() => {
                                    clear()
                                    setShow(false)
                                }}
                                className="modalCloseButtonDashboard"
                                style={{ marginRight: "12px", height: "36px", width: "36px" }}
                            >
                                <AiFillCloseCircle />
                            </button>
                        </div>
                    </div>
                    <div className="clientConfigEditBodyCenarios">
                        <div className="bodyConfigBlocHeaderCenarios" style={{ color: "#4C4C4C" }}>
                            Adicionar Cenario
                        </div>
                        <div className="bodyConfigBlocCenarios">
                            <div className="boxNewCenario">
                                <div className="newCenarioAlocacao">
                                    <p className="titileNewCenarioALocacao">Titulo</p>
                                    <p className="titileNewCenarioALocacao">Descrição</p>
                                    <p className="titileNewCenarioALocacao">Avatar</p>
                                    <p className="titileNewCenarioALocacao">Autor</p>
                                    <p className="titileNewCenarioALocacao">Status</p>
                                    <div className="itemNewCenarioAlocacao">
                                        <TextField
                                            style={{ height: "2.4rem" }}
                                            variant="outlined"
                                            value={newCenario.subject}
                                            // placeholder="Titulo"
                                            className={classes.styleForClients}
                                            onChange={(e) => {
                                                console.log("e", e.target.value)
                                                setNewCenario({ ...newCenario, subject: e.target.value })
                                            }}
                                        />
                                    </div>
                                    <div className="itemNewCenarioAlocacao">
                                        <textarea
                                            class="custom-textarea-alocacao"
                                            value={newCenario.vision}
                                            onChange={(e) => {
                                                setNewCenario({ ...newCenario, vision: e.target.value })
                                            }}
                                        ></textarea>
                                    </div>
                                    <div className="itemNewCenarioAlocacao">
                                        {/* <div className="buttonTableAlocacaoAdd" style={{ textAlign: "center" }}>
                                            Selecione
                                        </div> */}
                                        <div class="file-input-wrapper">
  <label for="file-input" class="custom-file-input">
    Escolha um arquivo
  </label>
  <input
    className="customFileInputCenarios"
    type="file"
    accept="image/*"
    id="file-input"
    onChange={(e) => e ? handleImageUpload(e) : null}
  />
  <span class="file-name">{
        newCenario.avatar_file ? newCenario.avatar_file.name : "Nenhum arquivo selecionado"
  }</span>
</div>
                                    </div>
                                    <div className="itemNewCenarioAlocacao">
                                        <TextField
                                            style={{ height: "2.4rem" }}
                                            variant="outlined"
                                            value={newCenario.spokeperson}
                                            // placeholder="Titulo"
                                            className={classes.styleForClients}
                                            onChange={(e) => {
                                                setNewCenario({ ...newCenario, spokeperson: e.target.value })
                                            }}
                                        />
                                    </div>
                                    <div className="itemNewCenarioAlocacao">
                                        <input
                                            className="checkboxNewCenario"
                                            type="checkbox"
                                            checked={newCenario.active}
                                            onChange={(e) => {
                                                console.log("e", e.target.value)
                                                setNewCenario({ ...newCenario, active: e.target.checked })
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="itemNewCenarioAlocacao">
                                    <div
                                        className="buttonTableAlocacaoAdd"
                                        onClick={() => {
                                            submitNewCenario()
                                        }}
                                        style={{ textAlign: "center", marginTop: "2rem" }}
                                    >
                                        Adicionar
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bodyConfigBlocHeaderCenarios" style={{ color: "#4C4C4C", marginTop: "1rem" }}>
                            Cenarios
                        </div>
                        <div className="bodyConfigBlocCenarios">
                            <div className="boxCenariosAlocacaoTable">
                                <table className="cenariosAlocacaoTable">
                                    <thead>
                                        <tr>
                                            <th>Titulo</th>
                                            <th>Descrição</th>
                                            <th>Avatar</th>
                                            <th>Autor</th>
                                            <th>Status</th>
                                            <th>Editar</th>
                                            <th>Atualizado em</th>
                                            <th>Excluir</th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ color: "rgb(188, 188, 188)" }}>
                                        {cenarios.map((cen, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center", minWidth: "6rem" }}>
                                                    {cen.onEdit == true ? (
                                                        <TextField
                                                            style={{ height: "2.4rem", minWidth: "6rem" }}
                                                            variant="outlined"
                                                            defaultValue={cen.subject}
                                                            // placeholder="Titulo"
                                                            className={classes.styleForClients}
                                                            onChange={(e) => {
                                                                console.log("e", e.target.value)
                                                                cen.subject = e.target.value
                                                            }}
                                                        />
                                                    ) : (
                                                        cen.subject
                                                    )}
                                                </td>
                                                <td style={{ minWidth: "12rem", maxWidth: "15rem" }}>
                                                    {cen.onEdit == true ? (
                                                        <textarea
                                                            style={{ minWidth: "12rem", maxWidth: "15rem" }}
                                                            class="custom-textarea-alocacao"
                                                            defaultValue={cen.vision}
                                                            onChange={(e) => {
                                                                // console.log("e", e.target.value)
                                                                cen.vision = e.target.value
                                                            }}
                                                        ></textarea>
                                                    ) : (
                                                        cen.vision
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center", height: `${cen.onEdit ? "10rem" : ""}`, width: `${cen.onEdit ? "10rem" : ""}` }}>
                                                    <div className="boxAvatarAutorCenario">
                                                        <img srcSet={cen.avatar} alt="Avatar" width={64} height={64} />
                                                        {cen.onEdit ? (
                                                            <input
                                                                className=""
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(e) => {
                                                                    // handleImageUpload(e)
                                                                    cen.avatar = URL.createObjectURL(e.target.files[0])
                                                                    cen.avatar_file = e.target.files[0]
                                                                    forceUpdate()
                                                                }}
                                                                style={{ color: "transparent", marginLeft: "-1.5rem" }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: "center", minWidth: "6rem" }}>
                                                    {cen.onEdit == true ? (
                                                        <TextField
                                                            style={{ height: "2.4rem", minWidth: "6rem" }}
                                                            variant="outlined"
                                                            defaultValue={cen.spokeperson}
                                                            // placeholder="Titulo"
                                                            className={classes.styleForClients}
                                                            onChange={(e) => {
                                                                cen.spokeperson = e.target.value
                                                            }}
                                                        />
                                                    ) : (
                                                        cen.spokeperson
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center", color: `${cen.active ? "#00580c" : "#b81313"}` }}>
                                                    {cen.onEdit ? (
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={cen.active}
                                                            onChange={(e) => {
                                                                console.log("e", e.target.value)
                                                                cen.active = e.target.checked
                                                            }}
                                                        ></input>
                                                    ) : cen.active ? (
                                                        "Ativo"
                                                    ) : (
                                                        "Inativo"
                                                    )}
                                                </td>
                                                <td
                                                    className="buttonTableAlocacaoEdit"
                                                    style={{ textAlign: "center" }}
                                                    onClick={() => {
                                                        if (cen.onEdit == true) {
                                                            updateCenario(cen)
                                                        }
                                                        cen.onEdit = !cen.onEdit
                                                        forceUpdate()
                                                    }}
                                                >
                                                    {cen.onEdit == true ? <FiSave size={20} /> : <FiEdit size={20} />}
                                                </td>
                                                <td style={{ textAlign: "center" }}>{cen.updated_at ? datetimeToDatePtBR(cen.updated_at) : "-"}</td>

                                                <td
                                                    className="buttonTableAlocacaoTrash"
                                                    style={{ textAlign: "center", cursor: "pointer" }}
                                                    onClick={() => {
                                                        deleteVision(cen)
                                                        setCenarios(cenarios.filter((item) => item.id !== cen.id))
                                                    }}
                                                >
                                                    <FiTrash2 size={20} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
