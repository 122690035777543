import React, { useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi'
import { useAuth } from '../../contexts/useAuth'
import { ArrowLoader } from '../../components/loaders/arrowLoader';
import './logon.css'
import logo from '../../assets/logo.png'
import logoSoftjoin from '../../assets/logoS.png'
import { ModalSizeFitToScreen } from '../../utils/responsiveFunctions/modalSizeFitToScreen'
import { api } from '../../services/api';

export default function Logon() {
    const [loading, setLoading] = useState(false);
    const [loadingShow, setLoadingShow] = useState(false);
    const [forgetPassword, setForgetPassword] = useState(false)
    const [selectedUserForget, setSelectedUserForget] = useState('')
    const [selectedEmailForget, setSelectedEmailForget] = useState('')
    const [passwordChanged, setPasswordChanged] = useState(false)
    const { signIn, tokenKey } = useAuth();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [statusLogon, setStatusLogon] = useState(false);
    const { screenX } = ModalSizeFitToScreen()

    async function handleLogin() {
        return await signIn(login, password);
    }

    async function startLogin(e) {
        e.preventDefault()
        setLoading(true);
        setLoadingShow(true);
        setStatusLogon(true)

        setTimeout(async () => {
            
            setLoadingShow(false)

            const status = handleLogin();
            if (await status !== true) {
                setStatusLogon(false);
                setLoading(false)
            }
        }, 1000)
    }

    async function handleForgetPassSubmit() {

        try {
            const newPass = await api.post(`/auth/users/forgetPassword`, {
                user_login: selectedUserForget,
                email: selectedEmailForget
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setPasswordChanged(true)
        } catch (error) {
            console.log(error?.response)
            alert(error?.response?.data?.message !== undefined ? error?.response?.data?.message : 'Erro contate um administrador')
        }
    }

    return (
        <>
            <div id="containerLogon">
                {statusLogon && !loadingShow ?
                    (
                        <div className='loginLoaderShow'>
                            <ArrowLoader gold={true} />
                        </div>
                    ) :
                    !forgetPassword &&
                    (
                        <div className='logoContainer'
                            style={{
                                width: screenX > 1100 ? (screenX / 2) - 230 : (screenX / 2),
                                maxWidth: (screenX + 98) / 2,
                                overflow: 'hidden',
                                transition: loading ? '1.8s' : '',
                                marginTop: loading ? screenX : 0
                            }}
                        >
                            <img src={logo} alt="" width='200' />
                            <a>PORTAL MANCHESTER</a>
                        </div>
                    )
                }
                {
                    passwordChanged ?
                        (
                            <div className='forgetPassUser'>
                                <div className='logoContainerForgetPass'>
                                    <img src={logo} alt="" width='200' />
                                    <a>PORTAL MANCHESTER</a>
                                    <h2>Dados enviados para seu e-mail</h2>
                                    <button id='loginEnterButton' onClick={() => { setPasswordChanged(false); setForgetPassword(false); }}>Login</button>
                                </div>
                            </div>
                        ) :
                        forgetPassword ? (
                            <div className='forgetPassUser'>
                                <div id='arrowLeft'>
                                    <FiArrowLeft title='Voltar a tela de login' onClick={() => setForgetPassword(false)} />
                                </div>
                                <div className='logoContainerForgetPass'>
                                    <img src={logo} alt="" width='200' />
                                    <a>PORTAL MANCHESTER</a>
                                </div>
                                <input type='text' placeholder='Digite seu usuário' onChange={(e) => setSelectedUserForget(e.target.value)}></input>
                                <input type='text' placeholder='Digite o seu e-mail' onChange={(e) => setSelectedEmailForget(e.target.value)}></input>
                                <button onClick={() => handleForgetPassSubmit()} id='forgetPasswordButton'>Redefinir senha</button>
                            </div>
                        ) :
                            <>
                                <div className='loginSeparator' style={{
                                    width: '1px'
                                }}>
                                </div>
                                <div className='inputContainer' style={{
                                    width: (screenX + 98) / 2,
                                    maxWidth: (screenX + 98) / 2,
                                }} >
                                    {statusLogon && !loading &&
                                        (
                                            <div className='loginLoaderShow'>
                                                <ArrowLoader gold={true} />
                                            </div>
                                        )}
                                    <form action="post" onSubmit={startLogin}
                                        style={{
                                            marginLeft: screenX > 900 ? - screenX / 4 : '0',
                                            marginBottom: loading ? screenX : 0,
                                            transition: loading ? '1.8s' : '',
                                        }}>
                                        <input
                                            type="login"
                                            placeholder="Login"
                                            onChange={(e) => setLogin(e.target.value)}
                                        />
                                        <input
                                            type="password"
                                            placeholder="Senha"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <button id='loginEnterButton' type='submit'> Acessar </button>
                                        <div className='loginForgottenPassword'>
                                            <a title='Clique para reenviar senha' onClick={() => setForgetPassword(true)}>Esqueceu a senha ?</a>
                                        </div>
                                    </form>
                                </div>
                                <div className='logoId'>
                                    <a>Solicite seu acesso ao Portal: 
                                        </a><a 
                                        style={{color:'yellow'}} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://app.pipefy.com/public/form/AeFrBJj1">
                                            Clique Aqui!
                                    </a>
                                    {/* <a href='https://www.softjoin.com.br' target="_blank"><img id='logoPrimeIMG' src={logoSoftjoin}></img></a> */}
                                </div>
                            </>
                }
            </div>
        </>
    )
}