/*Componente para efetuar edição dos Books
/*Descrição: Efetua edição dos books já cadastrados em sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
/*Styles*/
import './styles/booksRegister.css';
import { FiPlus, FiTrash, FiArrowDown, FiEdit, FiCheck } from 'react-icons/fi';
/*Services*/
import { api } from '../../../../services/api';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Util Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils*/
import { v4 as uuid } from 'uuid';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

export function BookEdit(props) {
    const bookId = props?.bookData?.value?.id
    const bookAward = props?.bookData?.value?.award
    const [listTypesFields, setListTypesFields] = useState([])
    const [newField, setNewField] = useState([])
    const { tokenKey } = useAuth()
    const { show, setShow, edited, setEdited, setType } = useModal()
    const [showPreviewTable, setShowPreviewTable] = useState(false)
    const [inEditName, setInEditName] = useState(false)
    const [loading, setLoading] = useState(false)
    const [bookNameHasChanged, setBookNameHasChanged] = useState(false)
    /*Variaveis Selecionadas*/
    const [selectedNewField, setSelectedNewField] = useState([])
    const [selectedTypeField, setSelectedTypeField] = useState([])
    const [selectedRequiredField, setSelectedRequiredField] = useState([])
    const [selectedBookName, setSelectedBookName] = useState(props?.bookData?.value?.name)
    const [newBookName, setNewBookName] = useState('')
    const [booksList, setBookList] = useState({})
    const [showFieldId, setShowField] = useState({})
    const { screenX, screenY } = ModalSizeFitToScreen()
    const [getChild, setGetChild] = useState(false)
    const [feeField, setFeeField] = useState([])

    useEffect(() => {
        setBookList({})
        setLoading(true);
        async function getTypesOfFields() {
            const fieldsResults = await api.get(`/api/v1/booksParamTypes`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setListTypesFields(fieldsResults?.data?.data)
        }
        async function getBookParam() {

            const bookParamResult = await api.get(`/api/v1/books/${bookId}`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            const fieldsInBook = [];
            const fieldsNamesInBook = {};
            const fieldsTypeInBook = {};
            const fieldsRequiredInBook = {};

            bookParamResult?.data?.data[0]?.fields?.map((ExistingField, index) => {

                const thisFieldId = uuid()
                if (
                    ExistingField?.field !== 'FEE'
                ) {
                    booksList[thisFieldId] = {
                        id: ExistingField.id,
                        books_id: ExistingField.books_id,
                        field: ExistingField.field,
                        books_param_types_id: ExistingField.books_param_types_id,
                        books_param_types_description: ExistingField.books_param_types_description,
                        required: ExistingField.required
                    }
                    setBookList(booksList);
                    setShowField(Object.keys(booksList));

                } else {
                    setFeeField([{
                        id: ExistingField.id,
                        books_id: ExistingField.books_id,
                        field: 'Fee %',
                        books_param_types_id: ExistingField.books_param_types_id,
                        books_param_types_description: ExistingField.books_param_types_description,
                        required: ExistingField.required
                    }])
                }
            })
            setSelectedNewField(fieldsNamesInBook)
            setSelectedTypeField(fieldsTypeInBook)
            setSelectedRequiredField(fieldsRequiredInBook)
            setNewField(fieldsInBook);
            setLoading(false);
        }
        if (show) {
            getBookParam();
            getTypesOfFields();
        }

    }, [show])

    useEffect(() => {
        setSelectedBookName(props?.bookData?.value?.name)
    }, [show])

    useEffect(() => {
        setSelectedNewField([]);
        setSelectedTypeField([]);
        setSelectedRequiredField([]);
        setInEditName(false)
        setShowPreviewTable(false)
    }, [edited, show])

    useEffect(() => {

        getLastChild()

    }, [showFieldId])

    async function handleSubmit(dataFields) {

        setLoading(true);
        try {
            const updateThisBook = await api.put(`/api/v1/books/${bookId}`, {
                name: selectedBookName,
                award: bookAward,
                fields: dataFields
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setEdited(true);
            setLoading(false);
            setType({ name: 'successBookCreation', type: 'information', value: { message: `Sucesso ao editar ${selectedBookName}` } });
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setLoading(false);
        }
    }

    function getLastChild() {

        if (getChild) {

            const inputChild = document?.getElementById('bookRegisterTable')?.childNodes[1].childNodes.length - 1
            document?.getElementById('bookRegisterTable')?.childNodes[1]?.childNodes[inputChild]?.childNodes[1]?.childNodes[0].childNodes[0]?.focus()
            setGetChild(false)
        }
    }

    function deleteRow(rowId) {

        delete booksList[rowId]
        setBookList(booksList)
        setShowField(Object.keys(booksList))
    }

    return (
        <div className='booksRegisterTableContainer' style={{
            width: screenX,
            height: screenY,
            overflowY: 'hidden'
        }}>
            <div className='booksRegisterHead'>
                <button className='modalCloseButton' onClick={() => setShow(false)}>X</button>
            </div>
            <div className='bookRegisterTableContent'>
                {
                    loading ?
                        (<div id='bookEditLoaderDiv'>
                            <ArrowLoader />
                        </div>) :
                        showPreviewTable ?
                            (
                                <>
                                    <label>
                                        <p id='bookRegisterPreviewText'>Previsão da Tabela</p>
                                    </label>
                                    <div className='booksRegisterTablePreview'>
                                        <table id='booksRegisterTableToPreview'>
                                            <thead>
                                                <tr>
                                                    <th colSpan={bookAward ? showFieldId.length + 3 : showFieldId.length + 2}>
                                                        <div id='bookRegisterPreviewTableTitle'>
                                                            <p>{selectedBookName}</p>
                                                        </div>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    {
                                                        showFieldId.map((newTh) => {
                                                            return (
                                                                <th>{booksList[newTh].field}</th>
                                                            )
                                                        })
                                                    }
                                                    <th>{feeField[0]['field']}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {
                                                        showFieldId?.map((newTh) => {

                                                            return (
                                                                <td>
                                                                    {booksList[newTh].required ?
                                                                        booksList[newTh].books_param_types_id === 1 ?
                                                                            'Numérico' + ' * ' :
                                                                            booksList[newTh].books_param_types_id === 2 ?
                                                                                'Texto' + ' * ' :
                                                                                booksList[newTh].books_param_types_id === 3 ?
                                                                                    'Data' + ' * ' :
                                                                                    'Verdadeiro/Falso'
                                                                                    + ' * '
                                                                        : booksList[newTh].books_param_types_id === 1 ?
                                                                            'Numérico' :
                                                                            booksList[newTh].books_param_types_id === 2 ?
                                                                                'Texto' :
                                                                                booksList[newTh].books_param_types_id === 3 ?
                                                                                    'Data' :
                                                                                    'Verdadeiro/Falso'}
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                    <td>Numérico</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <label id='bookRegisterLabelInstructions'>
                                        <div id='booksRegisterEditNameDiv'>
                                            {
                                                inEditName ?
                                                    <>
                                                        <input value={selectedBookName} onChange={(e) => setSelectedBookName(e.target.value)}></input>
                                                        <button id='bookRegisterEditNameConfirmButton' onClick={() => { setBookNameHasChanged(true); setInEditName(false) }} style={{ width: '10rem', height: '1.8rem' }}>Confirmar</button>
                                                        <button id='bookRegisterEditNameConfirmButton' style={{ width: '10rem', height: '1.8rem' }} onClick={() => setInEditName(false)}>Cancelar</button>
                                                    </>
                                                    :
                                                    <>
                                                        <a id='bookRegisterEditName'>{bookNameHasChanged ? selectedBookName : props?.bookData?.value?.name}</a>
                                                        <FiEdit id='bookRegisterEditNameButton' title='Editar nome' onClick={() => { setNewBookName(props?.bookData?.value?.name); setInEditName(true) }} />
                                                    </>
                                            }
                                        </div>

                                        <p >Edite e crie novas colunas utilizando o controle abaixo, campos obrigatórios já estão preenchidos</p>
                                    </label>
                                    <div id='bookRegisterNewFieldsTable'>
                                        <table id='bookRegisterTable'>
                                            <thead id='bookRegisterTableThead'>
                                                <td>Coluna</td>
                                                <td>Campo</td>
                                                <td>Tipo</td>
                                                <td>Obrigatório</td>
                                                <td>Remover</td>
                                                <td>Incluir</td>
                                            </thead>
                                            <tbody id='bookRegisterTableBody' style={{
                                                maxHeight: screenX / 6,
                                            }}>
                                                {loading ?
                                                    (
                                                        <div className='bookRegisterLoader'>
                                                            <ArrowLoader />
                                                        </div>
                                                    ) :
                                                    (
                                                        <>

                                                            {
                                                                showFieldId.length > 0 &&
                                                                showFieldId?.map((thisBookFieldUuid, index) => {
                                                                    return booksList[thisBookFieldUuid]['field'] === 'Recomendado' ?

                                                                        <tr className='bookRegisterFixedFields'>
                                                                            <td>{index + 1}</td>
                                                                            <td>Recomendado</td>
                                                                            <td>Verdadeiro/Falso</td>
                                                                            <td>Padrão</td>
                                                                            <td>Não removível</td>
                                                                            <td></td>
                                                                        </tr>
                                                                        :
                                                                        booksList[thisBookFieldUuid]['field'] === 'Ativo' ?

                                                                            <tr className='bookRegisterFixedFields'>
                                                                                <td>{index + 1}</td>
                                                                                <td>Ativo</td>
                                                                                <td>Numérico</td>
                                                                                <td>Padrão</td>
                                                                                <td>Não removível</td>
                                                                                <td></td>
                                                                            </tr>
                                                                            :
                                                                            booksList[thisBookFieldUuid]['field'] === 'Prêmio' ?

                                                                                <tr className='bookRegisterFixedFields'>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>Prêmio</td>
                                                                                    <td>Numérico</td>
                                                                                    <td>Padrão</td>
                                                                                    <td>Não removível</td>
                                                                                    <td></td>
                                                                                </tr>

                                                                                :
                                                                                booksList[thisBookFieldUuid]['field'] === 'Empresa' ?

                                                                                    <tr className='bookRegisterFixedFields'>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>Empresa</td>
                                                                                        <td>Texto</td>
                                                                                        <td>Padrão</td>
                                                                                        <td>Não removível</td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                    :
                                                                                    booksList[thisBookFieldUuid]['field'] === 'Vencimento' ?

                                                                                        <tr className='bookRegisterFixedFields'>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>Vencimento</td>
                                                                                            <td>Data</td>
                                                                                            <td>Padrão</td>
                                                                                            <td>Não removível</td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        :
                                                                                        booksList[thisBookFieldUuid]['field'] !== 'FEE' &&
                                                                                        <>
                                                                                            <tr>
                                                                                                <td>{index + 1}</td>
                                                                                                <td>
                                                                                                    <div id='bookRegisterFixedFields'>
                                                                                                        <input
                                                                                                            value={booksList[thisBookFieldUuid]['field']}
                                                                                                            type='text'
                                                                                                            maxLength={30}
                                                                                                            onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                                                                            placeholder='Digite o nome do campo'
                                                                                                            onChange={(e) => {
                                                                                                                booksList[thisBookFieldUuid].field = e.target.value.replace(/\s/g, '_').replace(/[\\]/g, '_').replace(/\/\//g, '')
                                                                                                                setBookList(booksList);
                                                                                                                setShowField(Object.keys(booksList))
                                                                                                            }}>
                                                                                                        </input>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div id='bookRegisterFixedFields'>
                                                                                                        <div id="bookRegisterSelectDiv">
                                                                                                            <FiArrowDown />
                                                                                                            <select
                                                                                                                id="bookRegisterSelectType"
                                                                                                                value={
                                                                                                                    booksList[thisBookFieldUuid].books_param_types_id == 1 ? 'Numérico' :
                                                                                                                        booksList[thisBookFieldUuid].books_param_types_id == 2 ? 'Texto' :
                                                                                                                            booksList[thisBookFieldUuid].books_param_types_id == 3 ? 'Data' :
                                                                                                                                booksList[thisBookFieldUuid].books_param_types_id == 4 ? 'Verdadeiro/Falso' :
                                                                                                                                    'Erro'
                                                                                                                }
                                                                                                                onChange={(e) => {
                                                                                                                    e.target.value === 'Numérico' ? booksList[thisBookFieldUuid].books_param_types_id = 1 :
                                                                                                                        e.target.value === 'Texto' ? booksList[thisBookFieldUuid].books_param_types_id = 2 :
                                                                                                                            e.target.value === 'Data' ? booksList[thisBookFieldUuid].books_param_types_id = 3 :
                                                                                                                                e.target.value === 'Verdadeiro/Falso' ? booksList[thisBookFieldUuid].books_param_types_id = 4 :
                                                                                                                                    booksList[thisBookFieldUuid].books_param_types_id = undefined

                                                                                                                    setBookList(booksList)
                                                                                                                    setShowField(Object.keys(booksList));
                                                                                                                }}
                                                                                                            >
                                                                                                                <option selected disabled>Selecione o tipo</option>
                                                                                                                {listTypesFields.map((TypeFieldOption) => {
                                                                                                                    return (
                                                                                                                        <option>
                                                                                                                            {
                                                                                                                                TypeFieldOption.id == 1 ? 'Numérico' :
                                                                                                                                    TypeFieldOption.id == 2 ? 'Texto' :
                                                                                                                                        TypeFieldOption.id == 3 ? 'Data' :
                                                                                                                                            TypeFieldOption.id == 4 ? 'Verdadeiro/Falso' : 'Erro'
                                                                                                                            }
                                                                                                                        </option>)
                                                                                                                })
                                                                                                                }
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div id='bookRegisterFixedFields'>
                                                                                                        <div id="bookRegisterSelectDiv">
                                                                                                            <FiArrowDown />
                                                                                                            <select
                                                                                                                id="bookRegisterSelectType"
                                                                                                                value={booksList[thisBookFieldUuid].required ? 'Sim' : 'Não'}
                                                                                                                onChange={(e) => {
                                                                                                                    if (e.target.value === 'Sim') {
                                                                                                                        booksList[thisBookFieldUuid].required = true
                                                                                                                    } else {
                                                                                                                        booksList[thisBookFieldUuid].required = false
                                                                                                                    }
                                                                                                                    setBookList(booksList)
                                                                                                                    setShowField(Object.keys(booksList))
                                                                                                                }}
                                                                                                            >
                                                                                                                <option>Sim</option>
                                                                                                                <option>Não</option>
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <div
                                                                                                        tabIndex='0'
                                                                                                        id='bookRegisterNewFieldDiv'
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                                                deleteRow(thisBookFieldUuid)
                                                                                                            }
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            deleteRow(thisBookFieldUuid)
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiTrash />
                                                                                                    </div>
                                                                                                </td>
                                                                                                {showFieldId.length - 1 === index ?
                                                                                                    (<td
                                                                                                        tabIndex='0'
                                                                                                        onClick={() => {
                                                                                                            if (booksList[thisBookFieldUuid].field !== '') {

                                                                                                                booksList[uuid()] = {
                                                                                                                    id: '',
                                                                                                                    books_id: '',
                                                                                                                    field: '',
                                                                                                                    books_param_types_id: 1,
                                                                                                                    books_param_types_description: '',
                                                                                                                    required: false
                                                                                                                }
                                                                                                                setBookList(booksList)
                                                                                                                setShowField(Object.keys(booksList));
                                                                                                                setGetChild(true)

                                                                                                            } else {
                                                                                                                alert("Preencha o nome do campo")
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                                                if (booksList[thisBookFieldUuid].field !== '') {

                                                                                                                    booksList[uuid()] = {
                                                                                                                        id: '',
                                                                                                                        books_id: '',
                                                                                                                        field: '',
                                                                                                                        books_param_types_id: 1,
                                                                                                                        books_param_types_description: '',
                                                                                                                        required: false
                                                                                                                    }
                                                                                                                    setBookList(booksList)
                                                                                                                    setShowField(Object.keys(booksList));
                                                                                                                    setGetChild(true)
                                                                                                                } else {
                                                                                                                    alert("Preencha o nome do campo")
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <FiPlus />
                                                                                                    </td>
                                                                                                    ) :
                                                                                                    (<td></td>)}
                                                                                            </tr>
                                                                                        </>
                                                                })
                                                            }
                                                            {
                                                                showFieldId.length > 0 &&
                                                                feeField?.map((thisBookFieldUuid, index) => {
                                                                    return 1 == 1 &&
                                                                        <tr>
                                                                            <td>Última</td>
                                                                            <td>Fee %</td>
                                                                            <td>Numérico</td>
                                                                            <td>Padrão</td>
                                                                            <td>Não removível</td>
                                                                            {
                                                                                showFieldId?.length === index + 1 ?
                                                                                    (
                                                                                        <td
                                                                                            tabIndex='0'
                                                                                            onClick={() => {
                                                                                                booksList[uuid()] = {
                                                                                                    id: '',
                                                                                                    books_id: '',
                                                                                                    field: '',
                                                                                                    books_param_types_id: 1,
                                                                                                    books_param_types_description: '',
                                                                                                    required: false
                                                                                                }
                                                                                                setBookList(booksList)
                                                                                                console.log(Object.keys(booksList))
                                                                                                setShowField(Object.keys(booksList));
                                                                                                setGetChild(true)
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.code === 'Enter' || e.code === 'Space' || e.code === 'NumpadEnter') {
                                                                                                    booksList[uuid()] = {
                                                                                                        id: '',
                                                                                                        books_id: '',
                                                                                                        field: '',
                                                                                                        books_param_types_id: 1,
                                                                                                        books_param_types_description: '',
                                                                                                        required: false
                                                                                                    }
                                                                                                    setBookList(booksList)
                                                                                                    setShowField(Object.keys(booksList));
                                                                                                    setGetChild(true)
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <FiPlus />
                                                                                        </td>
                                                                                    ) : (<td></td>)
                                                                            }
                                                                        </tr>

                                                                })
                                                            }
                                                        </>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
            </div>
            {showPreviewTable ?
                (
                    <div id='bookRegisterConfirmationBox'>
                        <a>os Campos com (*) são obrigatórios</a>
                        <a>Deseja editar e manter esta tabela?</a>
                        <div id='bookRegisterConfirmationBoxButtons'>
                            <button className='buttonWhiteBg' onClick={() => {
                                const fields = []
                                Object.keys(booksList).forEach(key => {
                                    if (booksList[key].field !== 'FEE'
                                        && booksList[key].field !== 'Ativo'
                                        && booksList[key].field !== 'Recomendado'
                                        && booksList[key].field !== 'Prêmio'
                                        && booksList[key].field !== 'Empresa'
                                        && booksList[key].field !== 'Vencimento'
                                    ) {
                                        fields.push({
                                            field: booksList[key].field,
                                            books_param_types_id: booksList[key].books_param_types_id,
                                            required: booksList[key].required
                                        });
                                    }
                                })
                                handleSubmit(fields)
                            }
                            }>Confirmar</button>
                            <button className='buttonWhiteBg' onClick={() => setShowPreviewTable(false)}>Voltar</button>
                        </div>
                    </div>
                ) :
                (
                    <button id='bookRegisterButtonWhiteBg'
                        onClick={() => {
                            setShowPreviewTable(true);
                        }}
                    >Pre-visualizar tabela</button>
                )
            }

        </div >
    )
}