import alertStyles from './alert.module.css'
import { AiOutlineClose } from 'react-icons/ai'
import { AiOutlineBell } from 'react-icons/ai'

export default function Alert(data){
  return(
    <div className={alertStyles.containerGlobalAlert}>
      <div className={alertStyles.containerHeaderAlert}>
        {data?.data?.title != undefined && data?.data?.title != ""?
          <div className={alertStyles.alertHeaderText}>{data?.data?.title}</div>
        : 
          <div className={alertStyles.alertHeaderText}>PortalRV</div>
        }
        {/* <div className={alertStyles.alertClose} onClick={() => {data?.data?.closeAlertVarFunc(false)}}><AiOutlineClose /></div> */}
        <div className={alertStyles.alertbell}><AiOutlineBell /></div>
      </div>
      <div className={alertStyles.containerAlertMessage}>
        <div className={alertStyles.alertMessageText}>{data?.data?.description}</div>
      </div>
      {data?.data?.type == "confirmationBox" ?
        <div className={alertStyles.containerAlertResponseOptions}>
          <button className={alertStyles.alertCancelButton} onClick={() => {data?.data?.closeAlertVarFunc(false)}}>CANCELAR</button>
          <button className={alertStyles.alertConfirmButton} onClick={() => {data?.data?.onConfirmFunc()}}>CONFIRMAR</button>
        </div>
      :
        <div className={alertStyles.containerAlertResponseOptions}>
          <button className={alertStyles.alertOkButton} onClick={() => {data?.data?.closeAlertVarFunc(false)}}>OK</button>
        </div>
      }
    </div>
  )
}