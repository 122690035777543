import React, { useEffect, useState } from 'react'
import '../../monitoringPanel/myReservations/styles/myReservations.details.css'

import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
import { api } from '../../../../services/api'
import '../styles/modalValidacaoBoleta.css'
import { TextField} from '@material-ui/core'
import { styleClientsInput } from '../../../../components/styles/autocompletField';

export function LongShortValidationModal(props) {
    const { type, show, setShow, setType, setEdited } = useModal()
    const [comment, setComment] = useState(null);
    const { user, tokenKey } = useAuth();
    const classes = styleClientsInput()

    async function submitCommentJustificativa() {
        try {
            const res = await api.post(`/api/v1/longshort/${props.prop.client_id}/justify`, {
                comment: comment
            }, 
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Justificativa cadastrada!' } })
            setEdited(true)
            // setShow(false)
        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro interno, entre em contato com o suporte!' } })
            // setShow(false)
        }
    }

    async function autorizar() {
        try {
            const res = await api.post(`/api/v1/longshort/${props.prop.client_id}/authorize`, {}, 
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            console.log(res)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Cliente autorizado!' } })
            setEdited(true)
            setShow(false)
        } catch (error) {
            console.log(error)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro interno, entre em contato com o suporte!' } })
            setShow(false)
        }
    }

    async function getComment() {
        try {
            const res = await api.get(`/api/v1/longshort/${props.prop.client_id}/justify`, 
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            console.log(res)
            setComment(res.data?.data[0]?.comment)
        } catch (error) {
            console.log(error)
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro interno, entre em contato com o suporte!' } })
            setShow(false)
        }
    }

    useEffect(() => {
        getComment()
    }, [show])

    return (
        <div className='myReservationDetails'>
            {
                <>
                    <div className='myReservationsHeader'>
                        <p>Validação de boleta</p>
                        <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                    </div>
                        <div className="boxAlertLSOperation">
                        {(props.prop.limite || props.prop.margem) ? <div style={{fontWeight: '400', fontSize: '1.2rem', textAlign: 'center'}}>ALERTA OPERAÇÃO LONG SHORT!</div> : <></>}
                        { props.prop.limite ? (<div className='divAlertsLongShorts'>Operação acima do limite de exposição recomendado.</div>): ( <></>)}
                        { props.prop.margem ? (<div className='divAlertsLongShorts'>Chamada de margem estimada acima das garantias disponíveis do cliente.</div>): (<></>)}
                        </div>

                        {
                    props.prop.read ? (
                        <>
                            <div className='boxJustifyBrokerLs'>
                                <span style={{fontWeight: '500'}}>Justificativa Broker:</span>

                                <div className='boxJustifyBrokerLsText'>{comment}</div>
                            </div>
                        </>
                    ): (
                        <>
                        <div className='boxJustifyBrokerLs'>
                            <span style={{fontWeight: '500'}}>Justificativa Broker:</span>
                            
                            <TextField
                              className={classes.textArea}
                              multiline
                              rows={4}
                              rowsMax={4}
                              variant='outlined'
                              onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                              placeholder='Justificativa'
                              inputProps={{ maxLength: '200' }}
                              onChange={(e) => setComment(e.target.value)}
                          />
                            
                            <div className="itemBodyOperateProductAbc ">
                                <button className="buttonOperateLs" onClick={() => {submitCommentJustificativa()}}>Enviar Justificativa</button>
                            </div>

                            {user?.is_leader > 0 ? (
                                    <div className="itemBodyOperateProductAbc">
                                        <button className="buttonOperateLs" onClick={() => {autorizar()}}>Autorizar</button>
                                    </div>
                            ) : ( 
                                <></>
                                )
                            }
                        </div>
                    </>
                    )
                }
                        
                </>
            }
        </div>
    )

    // return (
    //     <div className='myReservationDetails'>
    //         {
    //             props.prop.limite || props.prop.margem ? (
    //                 <>
    //                     <div>
    //                         <span>ALERTA OPERAÇÃO LONG SHORT!</span>
    //                     </div>
    //                     { props.prop.limite ? (
    //                         <>
    //                             <div>
    //                                 <span>Operação acima do limite de exposição redomendado</span>
    //                             </div>
    //                         </>
    //                     ): (
    //                         <></>
    //                     )}
    //                     { props.prop.margem ? (
    //                         <>
    //                             <div>
    //                                 <span>Chamada de margem estimada acima das garantias disponíveis do cliente</span>
    //                             </div>
    //                         </>
    //                     ): (
    //                         <></>
    //                     )}
    //                 </>
    //             ) : (
    //                 <></>
    //             )
    //         }
    //         {
    //             props.prop.read ? (
    //                 <>
    //                     <div>
    //                         <span>Justificativa Broker</span>
    //                     </div>
    //                     <span>{comment}</span>
    //                 </>
    //             ): (
    //                 <>
    //                 <div>
    //                     <span>Justificativa Broker</span>
    //                     <textarea
    //                         value={comment}
    //                         onChange={(e) => {
    //                             setComment(e.target.value)
    //                         }} 
    //                         cols={75}
    //                         rows={20}
    //                     />
    //                     <div
    //                         onClick={() => {
    //                             submitCommentJustificativa()
    //                         }}
    //                     >
    //                         Enviar Justificativa
    //                     </div>

    //                     {user.is_leader ? (
    //                         <>
    //                             <div
    //                                 onClick={() => {
    //                                     autorizar()
    //                                 }}
    //                             >
    //                                 Autorizar
    //                             </div>
    //                         </>
    //                     ) : ( 
    //                         <></>
    //                         )
    //                     }
    //                 </div>
    //             </>
    //             )
    //         }

    //         {/* {
    //             user.is_leader ?
    //                 (
    //                     <>
    //                         <div className='myReservationsHeader'>
    //                             <p>Resposta</p>
    //                             <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
    //                         </div>
    //                         <div className='myReservationsBody'>

    //                             <p>{type.data.order}</p>
    //                         </div>
    //                     </>
    //                 ) :
    //                 (
    //                     <>
    //                         <div className='myReservationsHeader'>
    //                             <p>Resposta</p>
    //                             <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
    //                         </div>
    //                         <div className='myReservationEmptyBody'>
    //                             <h4>Nenhuma resposta cadastrada</h4>
    //                         </div>
    //                     </>

    //                 )
    //         } */}
    //     </div>
    // )
}