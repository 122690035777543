/*Função que recebe uma data serial (Excel) e retorna no formato americano (yyyy-mm-dd)*/

export function SerialDateToJSDate(serialDate, offsetUTC) {

    try{
        const date = new Date(Date.UTC(0, 0, serialDate, offsetUTC));
        return date.toISOString().slice(0, 10)
    }catch(error){
        return serialDate
    }
}
