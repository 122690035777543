import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../../myReservations/styles/myReservation.table.css";
/*Contexts*/
import { useAuth } from "../../../../../contexts/useAuth";
import { useModal } from "../../../../../contexts/useModal";
/*Services*/
import { getDueStructured } from "../../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../../utils/formatNumberAndText/formatNumberPercent";

export function DueStructuredTbl() {
    const [loading, setLoading] = useState(false);
    const [listDueStructured, setlistDueStructured] = useState([]);
    const [listDueStructuredFormatted, setlistDueStructuredFormatted] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();

    async function getDue() {
        const listDueStructured = await getDueStructured(tokenKey, false);
        // console.log(listDueStructured);
        if (getDueStructured?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDueStructured?.error,
                },
            });
            setShow(true);
        } else {
            setlistDueStructured(listDueStructured);
            setLoading(false);
        }
    }

    function formateAssets() {
        let dueStructuredFormatted = [];

        listDueStructured.map((dueStructured) => {
            dueStructuredFormatted.push({
                advisor_name: dueStructured.advisor_name,
                broker_name: dueStructured.broker_name,
                clients_external_id: dueStructured.clients_external_id,
                operation: dueStructured.operation,
                ticker: dueStructured.ticker,
                qty: dueStructured.qty,
                sell_auto: dueStructured.sell_auto,
                barrier_status: dueStructured.barrier_status,
                date_break: dueStructured.date_break,
                date_in: dueStructured.date_in,
                date_due: dueStructured.date_due,
                value_in: dueStructured.value_in,
                value_out: dueStructured.value_out,
                variation: dueStructured.variation,
                barrier_pct: dueStructured.barrier_pct,
                net_in: dueStructured.net_in,
                net_out: dueStructured.net_out,
                net_income: dueStructured.net_income,
                opt_adjust: dueStructured.opt_adjust,
                result_val: dueStructured.result_val,
                result_pct: dueStructured.result_pct
            });
        });

        setlistDueStructuredFormatted(dueStructuredFormatted);
        setLoading(false);
    }

    useEffect(() => {
        if (edited || listDueStructured.length === 0) {
            setEdited(false);
            setLoading(true);
            getDue();
        }
    }, [edited]);

    useEffect(() => {
        if (listDueStructured.length > 0) {
            formateAssets();
        }
    }, [listDueStructured]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Assessor",
            "Broker",
            "Código Cliente",
            "Cliente",
            "Operação",
            "Ativo",
            "Quantidade",
            "Venda Automática",
            "Status Barreira",
            "Data de Rompimento",
            "Contratação",
            "Data de Vencimento",
            "Preço de Entrada",
            "Preço de Saída",
            "Variação do Ativo (Ajustado c/ Proventos)",
            "Barreira Contratada",
            "Financeiro de Entrada",
            "Financeiro de Saída",
            "Proventos",
            "Ajuste de Opções",
            "Resultado da Operação (Considerando Desempenho do Ativo)",
            "Resultado (%)"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "advisor_name",
            "broker_name",
            "clients_external_id",
            "client_name",
            "operation",
            "ticker",
            "qty",
            "sell_auto",
            "barrier_status",
            "date_break",
            "date_in",
            "date_due",
            "value_in",
            "value_out",
            "variation",
            'barrier_pct',
            "net_in",
            "net_out",
            "net_income",
            "opt_adjust",
            "result_val",
            "result_pct"
        ];
        listDueStructured.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "result_val" || y === "end_val"
                    ? String(x[y]).replace(".", ",")
                    : y === "date_in" || y === "date_break" || y === "date_due"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function dueStructuredExport(open, year) {
        setLoading(true);
        try {
            const myExport = await getDueStructured(tokenKey, true);
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "vencimentos_estruturadas.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textAlign: "center"
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "broker_name",
            label: "Broker",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "clients_external_id",
            label: "Código do Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "name",
            label: "Nome do Cliente",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "operation",
            label: "Operação",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "ticker",
            label: "Ativo",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "qty",
            label: "Quantidade",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "sell_auto",
            label: "Venda Automática",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "barrier_status",
            label: "Status Barreira",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "date_break",
            label: "Data de Rompimento",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (val) => {
                    if(val === '1900-01-01T00:00:00.000Z') {
                        return '-';
                    } else {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                    }
                },
            },
        },
        {
            name: "date_in",
            label: "Contratação",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "date_due",
            label: "Data de Vencimento",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "value_in",
            label: "Financeiro de Entrada",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "variation",
            label: "Variação do Ativo (Ajustado c/ Proventos)",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toPercent(value)}</span>
            },
        },
        {
            name: "barrier_pct",
            label: "Barreira Contratada",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toPercent(value)}</span>
            },
        },
        {
            name: "net_in",
            label: "Financeiro de Entrada",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "net_out",
            label: "Financeiro de Saída",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "net_income",
            label: "Proventos",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "opt_adjust",
            label: "Ajuste de Opções",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "result_val",
            label: "Resultado da Operação (Considerando Desempenho do Ativo)",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toBRL(value)}</span>
            },
        },
        {
            name: "result_pct",
            label: "Resultado (%)",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
                customBodyRender: (value) => <span>{toPercent(value)}</span>
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        tableBodyHeight: "70vh",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há vencimentos de estruturadas no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            dueStructuredExport();
            return false;
        },
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Vencimentos Estruturadas"}
                    data={listDueStructured}
                    columns={columns}
                    options={options}
                    className="tableUnsuitedStructured"
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

