// componente para gerar o carrousel de campanhas
// recebe como parametro um array de campanhas
// retorna um carrousel com as campanhas

import React, { useEffect, useState, useRef, useCallback, useReducer } from "react"
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../../../../contexts/useAuth"
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md"
import { FaStar } from "react-icons/fa"
import { BsFire } from "react-icons/bs"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { useModal } from "../../../../contexts/useModal"
import { timeout } from "react-documents"
//RECEBE COMO PARAMETRO
//selectedCampaign: campanha selecionada (mande a unica caso só exista uma)
//setSelectedCampaign: função para setar a campanha selecionada
//listProducts: lista de produtos
//campaignPriority: lista de prioridades que existem (para icones)
//productPage: utilize "true" se for uma página de produtos

export default function CampainsCarrousel(props) {
    const history = useHistory()
    const [carouselButtonLeft, setCarouselButtonLeft] = useState(false)
    const [carouselButtonRight, setCarouselButtonRight] = useState(true)
    const [larguraTela, setLarguraTela] = useState(window.innerWidth)
    const menuItemsRef = React.createRef()
    const [currentPosition, setCurrentPosition] = useState(0)
    const { user, tokenKey } = useAuth()
    const { setShow, setType } = useModal()
    const [onHoverCarrousel, setOnHoverCarrousel] = useState(false)
    const [campanhasEncerradas, setCampanhasEncerradas] = useState([])
    const [campanhasExibidas, setCampanhasExibidas] = useState([])
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    const sum = useRef({})
    
    function obterData(){
        const dataAtual = new Date();
        return dataAtual;
    }

    useEffect(() => {
        forceUpdate()
    }, [props.listProducts])

    const dataAtual =  new Date();
    dataAtual.setDate(dataAtual.getDate() - 1);


    function compareDatesUsingTimestamp(date1, date2) {
        const timestamp1 = new Date(date1).getTime();
        const timestamp2 = new Date(date2).getTime();
    
        if (timestamp1 > timestamp2) return 1;
        if (timestamp1 < timestamp2) return -1;

        return 0;
    }
    

    
    // function compareDate(currentDate, compare) {
    //     currentDate = currentDate.split("/");
    //     date2 = date2.split("/");

    //     if (currentDate > date2) return 1;
    //     if (currentDate < date2) return -1;

    //     return 0;
    // }

    useEffect(() => {
        // Função para atualizar o estado com a largura da tela
        const atualizarLarguraTela = () => {
            setLarguraTela(window.innerWidth)
        }

        // Adiciona um ouvinte de evento para o redimensionamento da janela
        window.addEventListener("resize", atualizarLarguraTela)

        // Remove o ouvinte de evento quando o componente for desmontado
        return () => {
            window.removeEventListener("resize", atualizarLarguraTela)
        }
    }, [])

    useEffect(() => {
        const menuItems = menuItemsRef.current
        if (menuItems && props.listProducts?.current?.length > 0) {
            const menuWidth = menuItems.offsetWidth
            const containerWidth = menuItems.parentElement.offsetWidth
            const maxPosition = containerWidth - menuWidth
            maxPosition > 0 ? setCarouselButtonRight(false) : setCarouselButtonRight(true)
        }
    }, [props.listProducts.current, larguraTela])

    const slideLeft = () => {        
        setCarouselButtonRight(true)
        setCurrentPosition((prevPosition) => {
            let newPosition = prevPosition + 800
            if (newPosition >= 0) {
                newPosition = 0
                setCarouselButtonLeft(false)
            }
            return newPosition
        })
    }

    

    const slideRight = () => {
        const menuItems = menuItemsRef.current;
        setCarouselButtonLeft(true);    
        if (menuItems) {
          const menuWidth = menuItems.offsetWidth;
          const containerWidth = menuItems.parentElement.offsetWidth;
          const maxPosition = containerWidth - menuWidth;
      
          setCurrentPosition((prevPosition) => {
            let newPosition = prevPosition - 470;
            if ( newPosition <= maxPosition - 470) {
              newPosition = 0; // Reset para o início
            }
            return newPosition;
          });
        }
      };

      const forceSpin = (e) => {
        const intervalSpin = setInterval(() => {
          e++
        }, 5000);
      }
      
      useEffect(() => {
        const handleResize = () => {
            slideRight();
          };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        }, []);


        useEffect(() => {
            if(!onHoverCarrousel && props?.selectedCampaign == null){
            
                const interval = setInterval(() => {
                slideRight();
                }, 5000);
            
                setCarouselButtonRight(true);
                return () => {
                clearInterval(interval);
                }
            };
      }, [forceSpin]);

      useEffect(() => {
        // Verifica se props.listProducts e props.listProducts.current existem
        if (props?.listProducts?.current) {
            const encerradas = [];
            const exibidas = [];
    
            props?.listProducts?.current.forEach((c, index) => {
                if (compareDatesUsingTimestamp(dataAtual, c.finish) == 1) {
                    encerradas.push(c);
                } else {
                    exibidas.push(c);
                }
            });
    
            setCampanhasEncerradas(encerradas);
            setCampanhasExibidas(exibidas);
            forceUpdate();
        }
    }, [props?.listProducts?.current]);

    // useEffect(() => {
    //     if (props?.searchArea?.length > 0) {
    //         const searchTerm = props?.searchArea?.toLowerCase();
    //         const filteredProducts = props?.listProducts?.current?.filter(product =>
    //             product?.description?.toLowerCase().includes(searchTerm)
    //         );
    //         const [encerradas, exibidas] = filteredProducts?.reduce(
    //             (result, product) => {
    //                 const category = compareDatesUsingTimestamp(dataAtual, product?.finish) === 1 ?
    //                     0 : 1; // 0 para encerradas, 1 para exibidas
    //                 result[category].push(product);
    //                 return result;
    //             },
    //             [[], []]
    //         );
    //         setCampanhasEncerradas(encerradas);
    //         setCampanhasExibidas(exibidas);
    //     } else {
    //         const [encerradas, exibidas] = props?.listProducts?.current?.reduce(
    //             (result, product) => {
    //                 const category = compareDatesUsingTimestamp(dataAtual, product.finish) === 1 ?
    //                     0 : 1; // 0 para encerradas, 1 para exibidas
    //                 result[category].push(product);
    //                 return result;
    //             },
    //             [[], []]
    //         );
    //         setCampanhasEncerradas(encerradas);
    //         setCampanhasExibidas(exibidas);
    //     }
    // }, [props?.searchArea]);
    
    const campanhas = campanhasExibidas.concat(campanhasEncerradas);

    // juntar os dois vetores
    
      
    return (
        <div className="carousel-container-dash" onMouseEnter={() => setOnHoverCarrousel(true)} onMouseLeave={() => setOnHoverCarrousel(false)} >
            {carouselButtonLeft ? (
                <button onClick={slideLeft} className="carousel-button-dash">
                    <MdOutlineArrowBackIosNew size={24} />
                </button>
            ) : (
                <div></div>
            )}
            <div id="menu-dash" className="carousel-menu-dash">
                <ul id="menu-items-dash" ref={menuItemsRef} style={{ transform: `translateX(${currentPosition}px)` }}>
                   
                    {campanhas?.map((c, index) => {
                        var keys = Object.keys(c)
                        
                        keys  = keys.filter((k) => k !== "id" && k !== "title" && k !== "type" && k !== "material" && k !== "campaign") 
                        //id da prioridade
                        const priorityAux = (props?.campaignPriority?.current?.find((p) => p.id == c.priority) ) 
                        const dataUpdateCards = c?.updated_at
                        const dataObjeto = new Date(dataUpdateCards);
                        const now = new Date();
                        const dataFormatada = dataObjeto.toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',  
                        });
                        const dataFormatadaPubStruc = now.toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',  
                        });

                            return (
                                <li
                                    className="itemCarrouselDash"
                                    onClick={() => {
                                        if (props?.productPage === true) {
                                            if (user.type < 3) {
                                                history.push("/visaoAssessor/dashboard")
                                            } else {
                                                history.push("/")
                                            }
                                        } else {
                                            if (props?.selectedCampaign?.id == c.id) {
                                                props?.setSelectedCampaign(null)
                                            } else {
                                                props?.setSelectedCampaign(c)
                                            }
                                        }
                                    }}
                                    style={{
                                        background: props.selectedCampaign?.id == null ? "#F7F7F7" : props.selectedCampaign?.id === c.id ? "#414141" : "#F3F3F3",
                                        color: props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#D4AC00" : "#C6C6C6",
                                        fontWeight: "600px",
                                        border: props.selectedCampaign?.id == c.id ? "5px solid white" : "5px solid white",
                                        outline: props.selectedCampaign?.id == c.id ? "1px solid #bababa" : "none",
                                        cursor: "pointer",
                                        opacity: props.selectedCampaign?.id == null ? "100%" : props.selectedCampaign?.id === c.id ? "100%" : "100%",
                                        padding: props.selectedCampaign?.id == c.id ? "inherit * 5%" : "inherit",
                                        transition: props.selectedCampaign?.id == c.id ? "0.2s" : "0.2s",
                                        boxShadow: props.selectedCampaign?.id == c.id ? "0px 0px 10px 1px #bababa" : "none",
                                    }}
                                >
                                    <div className="boxInfosItemCarrousel">
                                        <span 
                                            style={{
                                                position:'absolute',
                                                color: props.selectedCampaign?.id == null ? "#FFFFFF" : props.selectedCampaign?.id === c.id ? "#D4AC00" : "#FFFFFF", 
                                                background: props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#525252" : "#B7B7B7", 
                                                padding: '0.3rem 0.5rem', 
                                                borderRadius:'10px', top:'-9px', 
                                                position: 'relative', 
                                                margin:'auto', 
                                                fontSize:'0.72rem'}}>  

                                                Última atualização: {
                                                (c?.type?.description.includes('Ofertas Públicas') || 
                                                c?.type?.description.includes('Estruturadas'))
                                                    ? dataFormatadaPubStruc 
                                                    : (c?.updated_at == null ? datetimeToDatePtBR(c.start) : dataFormatada + 'h')
                                                }
                                        </span>
                                        <div className="boxInfosItemCarrouselHeader" title={c?.description}>
                                            <div></div>
                                            {c?.description}
                                            <div style={{float:'right'}} title={priorityAux?.value}>
                                                {c.priority == 1 ? <FaStar style={{color: props.selectedCampaign?.id == null ? "#ffc300" : props.selectedCampaign?.id === c.id ? "#D4AC00" : "#C6C6C6"}} size={24} /> : c.priority == 2 ? <BsFire color="red" size={24} /> : null}
                                            </div>
                                        </div>
                                        <div
                                            className="boxInfosItemCarrouselSubheader"
                                            style={{ color: props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#E2E2E2" : "#D9D9D9 " }}
                                            title={c.liquidation_type === false ? c?.card_description + ' - Oferta' : c?.card_description + ' - Plataforma'}
                                            
                                        >
                                            {c.liquidation_type === false ? c?.card_description + ' - Oferta' : c?.card_description + ' - Plataforma'}
                                        </div>
                                        <div className="boxInfosItemCarrouselBody">
                                            <div
                                                className={"containerCardsData"}
                                                style={{
                                                    color: props.selectedCampaign?.id == c.id ? "#8E8E8E" : "#D9D9D9",
                                                    background: compareDatesUsingTimestamp(dataAtual, c.finish) == 1 ? '#ba240a' : (props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#525252" : "#B7B7B7"),
                                                }}
                                            >
                                                <div>
                                                {c.start ? (
                                                    <div style={{display:'inline-block', fontSize: "0.8rem", overflowWrap: "break-word", color: "white", borderRight: "1px solid #B7B7B7", paddingRight: "0.8rem" }}>
                                                        <span style={{ fontWeight: "400" }}>Data Início: </span> <br />
                                                        <span style={{ whiteSpace: "break-spaces" }}>{datetimeToDatePtBR(c.start)}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                                {c.finish ? (
                                                    <div style={{display:'inline-block', fontSize: "0.8rem", overflowWrap: "break-word", color: "white", paddingLeft: "0.8rem", borderRight: c.data_liquidacao ? "1px solid #B7B7B7" : '', paddingRight: c.data_liquidacao ? "0.8rem" : '' }}>
                                                        <span style={{ fontWeight: "400" }}>Data Fim: </span> <br />
                                                        <span style={{ whiteSpace: "break-spaces" }}>{datetimeToDatePtBR(c.finish)}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                {c.data_liquidacao || c.liquidacao_plataforma ? (
                                                    <div style={{display:'inline-block', fontSize: "0.8rem", overflowWrap: "break-word", color: "white", paddingLeft: "0.8rem" }}>
                                                        <span title='Data de liquidação' style={{ fontWeight: "400" }}>Data Liq.: </span> <br />
                                                        <span style={{ whiteSpace: "break-spaces" }}>{c.liquidation_type == false ? datetimeToDatePtBR(c.data_liquidacao) : ("D+" + c.liquidacao_plataforma)}</span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                </div>
                                                <span style={{display: 'flex', justifyContent: 'space-around'  }} >

                                                    <span title="Taxa de aderência a campanha. O valor mostra a relação entre a oportunidade total e realizado"
                                                        style={{ color: props.selectedCampaign?.id == c.id ? "#FFFFFF" : "#FAFFFA", fontSize:'0.8rem'}}    
                                                        >
                                                    Aderência: {(c.sumValue && c.sumObjective) ? (c.sumValue/c.sumObjective*100).toFixed(2)+"%" : '0,00%'}
                                                    </span>
                                                    <span title="FEE da campanha."
                                                        style={{ color: props.selectedCampaign?.id == c.id ? "#FFFFFF" : "#FAFFFA", fontSize:'0.8rem'}}    
                                                        >
                                                    Fee: {(c.fee) ? (c.fee*100).toFixed(2)+"%" : 'N/C'}
                                                    </span>
                                                </span>
                                                    {compareDatesUsingTimestamp(dataAtual, c.finish) == 1 ? <span>Encerrado</span> : <></> }
                                                
                                            </div>
                                        </div>
                                        <span style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                            
                                            <span
                                                className="boxInfosItemCarrouselMaterial"
                                                disabled={!c.card_link ? 'disabled' : ''}
                                                style={{
                                                    background: props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#C8AA21" : "#B7B7B7",
                                                    color: "white",
                                                    borderRadius: "10px",
                                                    position: "relative",
                                                    display: "flex",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    padding: "0px 20px",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "14.5rem",
                                                    top: "-10px",
                                                    color: "white",
                                                    fontSize:'1.2rem',
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {c.card_link ? 
                                                    <a href={c.card_link} target="_blank"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}>
                                                        Material
                                                    </a>
                                                :
                                                    <p>
                                                        Material
                                                    </p>
                                                }
                                            </span>

                                            <span
                                                className="boxInfosItemCarrouselMaterial"
                                                style={{
                                                    background: props.selectedCampaign?.id == null ? "#525252" : props.selectedCampaign?.id === c.id ? "#C8AA21" : "#B7B7B7",
                                                    color: "white",
                                                    borderRadius: "10px",
                                                    position: "relative",
                                                    display: "flex",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    padding: "0px 20px",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "14.5rem",
                                                    top: "-10px",
                                                    color: "white",
                                                    fontWeight: "bold",
                                                }}
                                                onClick={(e) => {
                                                    setType({ name: "speechModal", data: { c } })
                                                    setShow(true)
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <p>Discurso</p>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            )
                        })}
                </ul>
            
            </div>

            {carouselButtonRight ? (
                <button onClick={slideRight} className="carousel-button-dash">
                    <MdOutlineArrowForwardIos size={24} />
                </button>
            ) : (
                <div></div>
            )}
        </div>
    )
} 
