import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
/*Styles*/
import "../styles/cubastar.funds.table.css"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth";
import { useModal } from "../../../../contexts/useModal";
/*Services*/
import { getNotExecuted, getDatabaseUpdateTime } from "../../../../services/asyncFunctions";
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";
import { TableCell } from "@material-ui/core";
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

export function CubastarFundsTable(data) {
    data?.data.elements.forEach((x) => x.max_suggested_allocation = parseFloat(x.max_suggested_allocation))
    // console.log('data->', data?.data.elements)
    const [loading, setLoading] = useState(false);
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState([]);
    const { tokenKey } = useAuth();
    const { setType, setShow, edited, setEdited } = useModal();
    const [rowsPerPage, setRowsPerPage] = useState(30);
    // console.log('data ', data)

    const tableData = data?.data?.elements;


    async function getDatabaseUpdateTimeValues() {
        const bases = 'notExecuted';
        const databaseUpdateTime = await getDatabaseUpdateTime(tokenKey, bases);
        if (getDatabaseUpdateTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseUpdateTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].updated_at;
                const dateObj = new Date(dateFormat)
                const dateFormatted = String(` - Atualizado em: ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}h`)
                setDatabaseUpdateTime(dateFormatted);
                setLoading(false);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (edited || tableData === 0) {
            setEdited(false);
            setLoading(true);
            getDatabaseUpdateTimeValues();
        }
    }, [edited]);

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Fundo",
            "Top Picks",
            "Fee Fixo",
            "Público",
            "Categoria",
            "Vol. Esperada",
            "Objetivo",
            "Alocação Máx. Sugerida",
            "Resgate (Dias)",
            "Aplicação Inicial",
            "ROA Taxa Adm a.a.",
            "Mês",
            "12 Meses",
            "24 Meses",
            "36 Meses"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "description",
            "top_pick",
            "fee_fixo",
            "target_investor",
            "category",
            "expected_vol",
            "objective",
            "max_suggested_allocation",
            "days_to_redeem",
            "initial_application",
            "roa_tax_a_a",
            "rentability_month",
            "rentability_12_months",
            "rentability_24_months",
            "rentability_36_months"
        ];
        tableData.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net"
                    ? String(x[y]).replace(".", ",") : y === "due_date"
                    ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") : x[y]
            });
            output.push(tmp.join("\t"));

        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    // async function assetnotExecutedExport() {
    //     setLoading(true);
    //     try {
    //         const myExport = await getNotExecuted(tokenKey, true);
    //         const responseURL = URL.createObjectURL(new Blob([myExport]));
    //         const link = document.createElement("a");
    //         link.href = responseURL;
    //         link.setAttribute("download", `Fundos - ${data.data.description}.xlsx`);
    //         document.body.appendChild(link);
    //         link.click();
    //         setLoading(false);
    //     } catch (error) {
    //         setType({
    //             name: "alertMsg",
    //             type: "information",
    //             value: {
    //                 message:
    //                     error?.response?.data?.message === undefined
    //                         ? "Erro ao buscar dados para exportação"
    //                         : error?.response?.data?.message,
    //             },
    //         });
    //         setShow(true);
    //         setLoading(false);
    //     }
    // }

    const columnCustomStyle = {
        maxWidth: '10rem', 
        width: '10rem', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        padding: '0px', 
        textAlign: '-webkit-center'
    }
    

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "description",
            label: "Fundo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "top_pick",
            label: "Top Picks",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return(
                        <>
                        { value !== '' ? <p style={{color: 'green'}}>&#x2714;</p> : '' }
                        </>
                    );
                  },
                
            },
        },
        {
            name: "fee_fixo",
            label: "Fee Fixo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return(
                        <>
                        { value !== '' ? <p style={{color: 'red'}}>&#x2714;</p> : '' }
                        </>
                    );
                  },
                
            },
        },
        {
            name: "target_investor",
            label: "Público",
            options: {
                filter: true,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val) => (
                    <TableCell style={ columnCustomStyle } title={val} >
                        {val}
                    </TableCell>
                ),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "category",
            label: "Categoria",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val) => (
                    <TableCell style={ columnCustomStyle } title={val} >
                        {val}
                    </TableCell>
                ),
            },
        },
        {
            name: "expected_vol",
            label: "Vol. Esperada",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
        },
        {
            name: "objective",
            label: "Objetivo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                // customBodyRender: (val) => (
                //     <TableCell style={ columnCustomStyle } title={val} >
                //         {val}
                //     </TableCell>
                // )
            },
        },
        {
            name: "max_suggested_allocation",
            label: "Alocação Máx. Sugerida",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
            // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
            //         <TableCell onClick={() => sortColumn(index)} key={index} style={{ maxWidth: '2rem' }}>
            //             {label}
            //             <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
            //         </TableCell>
            //     ),
        },
        {
            name: "days_to_redeem",
            label: "Resgate (Dias)",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "initial_application",
            label: "Aplicação Inicial",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? toBRL(value) : "-"
                    }</span>
            },
        },
        {
            name: "roa_tax_a_a",
            label: "ROA Taxa Adm a.a.",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
        
                    }</span>
            },
        },
        {
            name: "rentability_month",
            label: "Mês",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
        },
        {
            name: "rentability_12_months",
            label: "12 Meses",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
        },
        {
            name: "rentability_24_months",
            label: "24 Meses",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
        },
        {
            name: "rentability_36_months",
            label: "36 Meses",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? parseFloat((value)).toFixed(2) + '%' : "-"
                    }</span>
            },
        },
        // {
        //     name: "link",
        //     label: "Link XP",
        //     options: {
        //         filter: false,
        //         sort: true,

        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //                 padding: '0px 0.6rem 0px 0.6rem',
        //             },
        //         }),
        //         customBodyRender: (value) => <a target="_blank" rel="noreferrer" href={value}>Acessar</a>
        //     },
        // },
    ];

    // caso for Offshore
    const offshore = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "description",
            label: "Fundo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "top_pick",
            label: "Top Picks",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return(
                        <>
                        { value !== '' ? <p>&#9989;</p> : '' }
                        </>
                    );
                  },
                
            },
        },
        {
            name: "target_investor",
            label: "Público",
            options: {
                filter: true,
                sort: true,
                // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
                // display: tokenKey.type > 3 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val) => (
                    <TableCell style={ columnCustomStyle } title={val} >
                        {val}
                    </TableCell>
                ),
                // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
                //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
                //         {label}
                //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
                //     </TableCell>
                // ),
            },
        },
        {
            name: "max_suggested_allocation",
            label: "Alocação Máx. Sugerida",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => <span>{
                    value !== null ? toPercent(value) : "-"
                    }</span>
            },
        },
        {
            name: "redeem",
            label: "Liquidez",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: 'roa',
            label: 'ROA',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => 
                    value ? value : '-'
            }
        }
        
        
        
    ]

    // caso for coe
    const coe = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "description",
            label: "Fundo",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "top_pick",
            label: "Top Picks",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return(
                        <>
                        { value !== '' ? <p>&#9989;</p> : '' }
                        </>
                    );
                  },
                
            },
        },
        {
            name: 'section',
            label: 'Tipo de Coe',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                })
            }
        },
        {
            name: 'ltv',
            label: 'LTV',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => 
                    value ? value : '-'
            }
        },
        {
            name: 'roa',
            label: 'ROA',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (value) => 
                    value ? value : '-'
            }
        },
        {
            name: "redeem",
            label: "Prazo",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        
        {
            name: 'publicitary_link',
            label: 'Material Publicitário',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        maxWidth: '10rem', 
                        width: '10rem', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        padding: '0px', 
                        textAlign: '-webkit-center'
                    },
                }),
                customBodyRender: (value) => {
                  return value ? <a href={value} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>Acessar</a> : '-'
                }

            }
        },
        {
            name: 'asset_reference',
            label: 'Ativo Referência',
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        maxWidth: '10rem', 
                        width: '10rem', 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        padding: '0px', 
                        textAlign: '-webkit-center'
                    },
                })
            }
        }

        
        
        
        
    ]

    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        search: false,
        filter: true,
        toolbar: false,
        download: true,
        print: false,
        pagination: false,
        overflow: true,
        responsive: "vertical",
        tableBodyWidth: "100%",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: true,
        toolButton: {
            justifyContent: "center"
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há operações não executadas.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Baixar Planilha",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
      
            // console.log(values.forEach(val => console.log(val)));
      
            const json = values.reduce((result, val) => {
              const temp = {};
              val.data.forEach((v, idx) => {
                temp[columns[idx].label] = v;
              });
              result.push(temp);
              return result;
            }, []);
      
            const fileName = `Cubastar - Fundos`;
            const ws = utils.json_to_sheet(json);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, fileName + fileExtension);
            return false;
          },
        sortOrder: {
            name: 'broker_name',
            direction: 'asc'
        },
        // customToolbar: AddButton,
        setTableProps: () => {
            return {
                padding: 'none',
                size: 'small'
            };
          },
          onRowClick: (rowData, rowMeta) => {
            const data = tableData.find((x) => x.id === rowData[0]);
            setType({ name: "tacticalSpeech", data: { data, type: "fundos" } })
            setShow(true)
            
          }

    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "",
                        textAlign: 'center'
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                },
                fixedHeader: {
                    justifyContent: 'center',
                    whiteSpace: "pre-line",
                    maxWidth: '4rem',
                    padding: "0px 0.6rem 0px 0.6rem"
                },
                toolButton: {
                    margin: '0px'
                }
            }
        },
        MUIDataTableBodyRow: {
            styleOverrides: {
            root: {
              '&:nth-child(odd)': { 
                backgroundColor: '#dbdbdb'
              }
            }
        }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0'
        }
      },
    }
    }
    })

    return (
        <div className='cubastar-table'>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={tableData}
                    columns={data?.data?.description.split(' ')[0] == 'OFFSHORE:' ? offshore : data?.data?.description.split(' ')[0] == 'COE:' ? coe : columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
    );
}

