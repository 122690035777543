/**
 * ANIMACAO DE CARREGAMENTO FUNDO BRANCO
 * DESENVOLVIVDO POR: RAFAEL CEZARIO
 * DATA: 25/05/2021
**/

import React from "react"
import './loaderWithoutText.css'

export function ArrowLoaderWOText(props) {
  return (
    <div className='containerWoTxt'>
    <span className={props.gold ? 'loadingGoldWOTxt' : 'loadingWOTxt'}></span>
    </div>
  )
}