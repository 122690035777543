import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "tss-react/mui";
import { display } from "@mui/system";

const useStyles = makeStyles({ name: "MUIDataTableViewCol" })((theme) => ({
    root: {
        padding: "16px 24px 16px 24px",
        fontFamily: "Roboto",
    },
    title: {
        marginLeft: "-7px",
        marginRight: "24px",
        fontSize: "14px",
        color: theme.palette.text.secondary,
        textAlign: "left",
        fontWeight: 500,
    },
    formGroup: {
        marginTop: "8px",
    },
    columnsItems: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: 'grid',
        gridTemplateColumns: '1fr',
        maxHeight: '70vh',
        overflowX: 'auto',
    },
    formControl: {
    },
    checkbox: {
        padding: "0px",
        width: "32px",
        height: "32px",
    },
    checkboxRoot: {},
    checked: {},
    label: {
        fontSize: "15px",
        marginLeft: "8px",
        color: theme.palette.text.primary,
    },
    subHeaderButtons: {
        display: "grid",
        gridTemplateColumns: "auto auto auto",
    },
}));

const TableViewCol = ({ columns, options, components = {}, onColumnUpdate, updateColumns}) => {
    const columsnProductsToView = []
    console.log(columns)

    const { classes } = useStyles();
    const textLabels = options.textLabels.viewColumns;
    const CheckboxComponent = components.Checkbox || Checkbox;

    const handleColChange = (index) => {
        onColumnUpdate(index);
    };

    function applyColumnsViewer(){
        columnsToChange.forEach((c,i) => {
            if(c==true) {
                handleColChange(i)
                columnsToChange[i] = false
            }
        })
    }

    const selectAll = (view) => {
        columns.map((column, index) => {
            if(column.display === `${!view}`){
                handleColChange(index)
            }
        })
    };

    var columnsToChange = [];
    for (let i = 0; i < columns.length; i++) {
            columnsToChange.push(false)
    }

    return (
        <FormControl component={"fieldset"} className={classes.root} aria-label={textLabels.titleAria} style={{padding: '1rem',width: 'calc(100% - 2rem)'}}>
            <Typography variant="caption" className={classes.title} style={{}}>
                {textLabels.title}
            </Typography>
            <FormGroup className={classes.formGroup}>
                <div className={classes.subHeader}>
                    <div className={classes.subHeaderButtons}>
                        <Button onClick={() => selectAll(true)}>Marcar todas</Button>
                        <Button onClick={() => selectAll(false)}>Desmarcar todas</Button>
                        <Button onClick={() => applyColumnsViewer()}>Aplicar</Button>
                    </div>
                </div>
                <div className={classes.columnsItems}>
                    {columns.map((column, index) => {
                        return (
                            column.display !== "excluded" &&
                            column.viewColumns !== false && (
                                <FormControlLabel
                                key={index}
                                classes={{
                                    root: classes.formControl,
                                    label: classes.label,
                                }}
                                control={
                                    <CheckboxComponent
                                    color="primary"
                                    className={classes.checkbox}
                                    classes={{
                                        root: classes.checkboxRoot,
                                        checked: classes.checked,
                                    }}
                                    onChange={(e) => {
                                        columnsToChange[index] = !columnsToChange[index]
                                    }}
                                    defaultChecked={column.display === 'true'}
                                    value={column.label}
                                    />
                                }
                                    label={column.label}
                                />
                            )
                        );     
                    })}
                </div>
            </FormGroup>
        </FormControl>
        // <></>
    );
};

TableViewCol.propTypes = {
    /** Columns used to describe table */
    columns: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger View column update */
    onColumnUpdate: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
};

export default TableViewCol;
