import React from 'react';
/*Styles*/
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
import Modal from '../../../../components/modal/modal';
import { TermoCaixaStructure } from './tables/termoCaixa.table';
import { ConfirmationBox } from "../../../../components/confirmationBox/confirmationBox";

export function MonitorTermoCaixa(){

  const { tokenKey } = useAuth()
  const { show, setShow, type, setType } = useModal()

  return (<>
    <Modal>{type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : <></>}</Modal>
    <TermoCaixaStructure />
  </>)
}