import React, { useEffect, useState } from 'react'

import { api } from '../../../services/api'
import { useAuth } from '../../../contexts/useAuth'
import { useModal } from '../../../contexts/useModal'
import { getDashAlocacao } from '../../../services/asyncFunctions'
import { datetimeToDatePtBR } from '../../../utils/formatDate/dateTimeToPTBR'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MdPlace } from 'react-icons/md'

import './styles/dashboardAdvisorCalendar.css'
import { ArrowLoader } from '../../../components/loaders/arrowLoader'

export function CalendarResponseModal(props) {

    const [loading, setLoading] = useState(false)
    const {tokenKey} = useAuth();
    const { type, show, setShow, setType } = useModal()
    const [response, setResponse] = useState(false)
    const token = localStorage.getItem('@auth:token');
    const [listCalendar, setListCalendar] = useState([])

    async function getDashboardCalendar() {
        // setLoading(true)
        const listCalendar = props.prop.events;
        // if (getDashAlocacao?.isError) {
        //     setType({
        //         name: "alertMsg",
        //         type: "information",
        //         value: {
        //             message: getDashAlocacao?.error,
        //         },
        //     });
        //     setShow(true);
        //     setListCalendar([]);
        //     setLoading(false)
        // } else {
            // console.log('listCalendar', listCalendar)
            setListCalendar(listCalendar);
            // setLoading(false)
        // }
    }


    useEffect(() => {
        getDashboardCalendar();
        setResponse(true)
    }, [show])

    return (
        <div className='dashboardAdvisorCalendar'>
            {
                loading ?
                    (
                            <ArrowLoader />
                    ) : (
                        <>
                            <div className='dashboardCalendarTable'>
                            <div className='editBrancheBoxHeaderDash'>
                                <div></div>
                                <div style={{fontSize: '32px'}}>Calendário de Eventos</div>
                                <button
                                    onClick={() => { setShow(false)}}
                                    className="modalCloseButtonDashboard" style={{marginRight: '12px', height: '36px', width: '36px'}}>
                                    <AiFillCloseCircle />
                                </button>
                            </div>
                            </div>
                            <div className='headProdutos'>
                            { 
                            listCalendar?.map((x) => {
                                let newTime = new Date(new Date(x.time).setHours(new Date(x.time).getHours() + 3)).toTimeString().split(' ');
                                // console.log(new Date(new Date(x.time).setHours(new Date(x.time).getHours() + 3)));
                                return (
                                    <tr>
                                    <tr className='listCalendar'>
                                    <th>{datetimeToDatePtBR(x.time)} - {newTime[0].slice(0, 5)}h</th>
                                    <th>{x.description}</th>
                                    <th>{x.local !== 'Online' ? <MdPlace/> : ''}{x.local}</th>
                                    <th><a href={x.link} target='_blank'>Acessar</a></th>
                                    </tr>
                                    <hr></hr>
                                    </tr>
                                )
                            })
                            }
                            </div>
                        </>
                    )
                    // (
                    //     <>
                    //         <div className='myReservationsHeader'>
                    //             <p>Calendário de Eventos</p>
                    //             <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
                    //         </div>
                    //         <div className='myReservationEmptyBody'>
                    //             <h2>Não há eventos próximos no calendário!</h2>
                    //         </div>
                    //     </>

                    // )
            }
        </div>
    )

}