import React, { useRef, useEffect } from 'react'
import { useModal } from '../../contexts/useModal'
import styles from './modal.module.css'

export default function Modal({ children }) {

    const { show, setShow, type } = useModal()
    const modalRef = useRef();
    
    if(children === false){
        setShow(false)
    }

    /* Função para fechar a modal ao clicar fora dela -> solicitada desativação pelo cliente*/

    // const closeModal = (e) =>{
    //     if(modalRef.current === e.target){
    //         setShow(false)
    //     }
    // }

    const eventListener = (event) => {
        if(event.key == 'Escape'){
            setShow(false)
        }
    }

    if(show === true){
        window.addEventListener('keydown', eventListener)
    }else{
        window.removeEventListener('keydown', eventListener)
    }

    return (
        <div className={show === true ? styles.displayAlignModal : styles.displayAlignModalNone}>
            <div ref={modalRef} className={show === true ? styles.modalShow : styles.modalHidden}>
                <div className={show == true ? styles.modalContent : styles.modalContentNone}>               
                    {children}
                </div>
            </div>
            <div  className={show === false ? styles.moldaBackgroundHide : styles.modalBackgroundShow} ></div>
        </div>
    )
}