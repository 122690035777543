import React, { useEffect, useState, useRef, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton, TableCell } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
import { useSocket } from "../../../../contexts/useSocket";

import { FiPlus, FiTrash2 } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import { BsBasket } from "react-icons/bs";

import { ArrowLoader } from "../../../../components/loaders/arrowLoader";

import { api } from "../../../../services/api";

import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";

import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent";

import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import { FiArrowDown, FiArrowUp, FiCopy } from "react-icons/fi";
import { Autocomplete } from "@material-ui/lab";
import { TextField, TextareaAutosize } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

// import CustomToolbarSelect from "../toolbar/customSelectedToolbarWisirTracking";
// import "../styles/tracking.css";


export function WisirTable() {
  const { user, tokenKey } = useAuth();
  const { show, setShow, edited, setEdited, type, setType } = useModal();

  const [loadingTable, setLoadingTable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activeTable, setActiveTable] = useState([]);
  const [activeTableId, setActiveTableId] =
    useState(1);
  const [activeTableName, setActiveTableName] =
    useState("DINÂMICA TOTAL RETURN");

  const [filter, setFilter] = useState("");
  const [placeHolderPerformanceTable, setPlaceHolderPerformanceTable] =
    useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [orderingColumnName, setOrderingColumnName] = useState("");
  const [orderDescending, setOrderDescending] = useState(false);

  const [tabs, setTabs] = useState();
  const [assets, setAssets] = useState();

  const [ativo, setAtivo] = useState(null);
  const [nome, setNome] = useState(null);
  const [setor, setSetor] = useState(null);
  const [peso, setPeso] = useState(null);
  const [precoAtual, setPrecoAtual] = useState(null);
  const [optionsStatusWisir, setOptionsStatusWisir] = useState(null);
  const [optionsStatusOperation, setOptionsStatusOperation] = useState(null);
  const [startValueSum, setStartValueSum] = useState(0)
  const [currentValueSum, setCurrentValueSum] = useState(0)
  const [rentabilitySum, setRentabilitySum] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const [statusEstrategia, setStatusEstrategia] = useState(-1)
  const [statusOperacao, setStatusOperacao] = useState(-1)
  const [statusAutomatizada, setStatusAutomatizada] = useState(-1)


  useEffect(
    () => {
      setLoading(true)
      getTabs();
      getAssets();
      getTab(activeTableId);
      returnOptionsStatusWisir();
      returnOptionsStatusOperation();
    },
    [tokenKey],
    [show],
    [edited]
  );

  const reloadTable = () => {
    getTab(activeTableId);
  }
  // REQUISIÇÃO DE ATIVOS DISPONÍVEIS ++++++++++++++++++++++++++++++++++++++++++
  async function getAssets() {
    // try {
    //   const assets = await api.get(`/api/v1/assets`, {
    //     headers: {
    //       authorization: `Bearer ${tokenKey}`,
    //     },
    //   });
    //   let distinctAssets = assets?.data?.data?.filter(
    //     (v, i, a) => a.indexOf(v) === i
    //   );
    //   setAssets(distinctAssets);
    // } catch (error) {
    //   setLoading(false);
    //   setType({
    //     name: "alertMsg",
    //     type: "information",
    //     value: {
    //       message:
    //         error?.response?.data?.message == undefined
    //           ? "Erro interno ao carregar as abas"
    //           : error?.response?.data?.message,
    //     },
    //   });
    //   setShow(true);
    // }
  }
  // ---------------------------------------------------------------------------
  // REQUISIÇÕES DE ABA DA TABELA ++++++++++++++++++++++++++++++++++++++++++++++
  async function getTabs() {
    try {
      const tabs = await api.get(`/api/v1/wisir/strategies`, {
        headers: {
          authorization: `Bearer ${tokenKey}`,
        },
      });
      let distinctTabs = tabs?.data?.data?.filter(
        (v, i, a) => a.indexOf(v) === i
      );
      setTabs(distinctTabs);
      setActiveTableId(distinctTabs[0]?.id)
      setActiveTableName(distinctTabs[0]?.description)
    } catch (error) {
      setLoading(false);
      setType({
        name: "alertMsg",
        type: "information",
        value: {
          message:
            error?.response?.data?.message == undefined
              ? "Erro interno ao carregar as abas"
              : error?.response?.data?.message,
        },
      });
      setShow(true);
    }
  }

 const getTab = async (id) => {
    setLoading(true)
    try {
        const auxTableContent = await api.get(
            `/api/v1/wisir2/${id}`,
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            }
        );
        console.log(auxTableContent);
        setActiveTable(auxTableContent?.data?.data);
        let sSum = 0;
        let cSum = 0;
        let rSum = 0;
        if (auxTableContent?.data?.data?.length > 0 ) {
            sSum = auxTableContent?.data?.data.reduce((prev, curr) => prev + curr.start_value, 0)
            cSum = auxTableContent?.data?.data.reduce((prev, curr) => prev + curr.current_value, 0)
            rSum = ((cSum / sSum) - 1) * 100
        }
        setStartValueSum(sSum);
        setCurrentValueSum(cSum);
        setRentabilitySum(rSum)
    } catch (error) {
        console.log(error)
        setLoading(false);
        setType({
            name: "alertMsg",
            type: "information",
            value: {
            message:
                error?.response?.data?.message == undefined
                ? "Erro interno ao carregar os dados"
                : error?.response?.data?.message,
            },
        });
        setShow(true);
    }
    setLoading(false);
}

  // REQUISIÇÕES DE ABA DA TABELA ----------------------------------------------
  // FILTRO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//   function filteredTable() {
//     if (filter != undefined && filter != "" && filter != null) {
//       return activeTable.filter((row) => {
//         const rowKeys = Object.keys(row);
//         if (
//           rowKeys.filter((key) => {
//             return (
//               String(row?.[key]).toLowerCase()?.indexOf(filter?.toLowerCase()) >
//               -1
//             );
//           }).length > 0
//         ) {
//           return row;
//         }
//       });
//     } else {
//       return activeTable;
//     }
//   }

  async function returnOptionsStatusWisir() {
    try {
      const options = await api.get(`/api/v1/wisir/status`, {
        headers: {
          authorization: `Bearer ${tokenKey}`,
        },
        responseType: "text",
      });
      console.log(options);
      setOptionsStatusWisir(options?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function returnOptionsStatusOperation() {
    try {
      const options = await api.get(`/api/v1/wisir/steps`, {
        headers: {
          authorization: `Bearer ${tokenKey}`,
        },
        responseType: "text",
      });
      console.log(options);
      setOptionsStatusOperation(options?.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  // FILTRO --------------------------------------------------------------------

  async function getExportJson() {
    setLoading(true);
    try {
        const exportTrackingWisir = await api.get(`/api/v1/wisir/operational/exportOperational`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        const responseURL = URL.createObjectURL(new Blob([exportTrackingWisir.data]));
        const link = document.createElement('a');
        link.href = responseURL;
        link.setAttribute('download', 'acompanhamento_wisir.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false)
    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
  }

  async function generateBasket() {
    getTab(activeTableId)
    setLoading(true);
    try {
        const basket = await api.get(`/api/v1/wisir/operational/${activeTableId}/basket`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        const responseURL = URL.createObjectURL(new Blob([basket.data]));
        const link = document.createElement('a');
        link.href = responseURL;
        link.setAttribute('download', 'basket.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false)
        setShow(false);
    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
  }

  async function generateEmail() {
    getTab(activeTableId)
    setLoading(true);
    try {
        const email = await api.get(`/api/v1/wisir/operational/${activeTableId}/email`, {
            headers: {
                authorization: `Bearer ${tokenKey}`
            },
            responseType: 'blob'
        })
        const responseURL = URL.createObjectURL(new Blob([email.data]));
        const link = document.createElement('a');
        link.href = responseURL;
        link.setAttribute('download', 'email.xlsx');
        document.body.appendChild(link);
        link.click();
        setLoading(false)
        setShow(false);
    } catch (error) {
        setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
        setShow(true);
        setLoading(false);
    }
  }

  async function changeWisirStep(row,step_id){
    setShow(false);
    try {
      const changeStep = await api.post(
        `/api/v1/wisir/operational/${row.id}/updateStep`,
        {
          wisir_step_id: parseInt(step_id),
        },
        {
          headers: {
            authorization: `Bearer ${tokenKey}`,
          },
        }
      );
      getTab(activeTableId)
    } catch (error) {
      setLoading(false);
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message },
      });
      setShow(true);
    }
  }

  async function changeAutomatized(row) {
    setShow(false);
    try{
       const changeAutomatizedStatus = await api.post(
        `/api/v1/wisir/operational/${row.id}/updateAutomatized`, {},
        {
          headers: {
            authorization: `Bearer ${tokenKey}`,
          },
        }
       );
       getTab(activeTableId)
    } catch (error) {
      setLoading(false);
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message },
      });
      setShow(true);
    }
  }

  async function changeWisirStatus(row,status_id){
    setShow(false);
    try {
      const changeStep = await api.post(
        `/api/v1/wisir/operational/${row.id}/updateStatus`,
        {
          wisir_status_id: parseInt(status_id),
        },
        {
          headers: {
            authorization: `Bearer ${tokenKey}`,
          },
        }
      );
      getTab(activeTableId)
    } catch (error) {
      setLoading(false);
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message },
      });
      setShow(true);
    }
  }


  async function requestFile() {
    setLoading(true);
    try {
        const myExport = await api.get(
          `/api/v1/wisir2/${activeTableId}`,
          {
              headers: {
                  download: true,
                  authorization: `Bearer ${tokenKey}`,
              },
              responseType: 'blob'
          }
      );
        const responseURL = URL.createObjectURL(new Blob([myExport?.data]));
        const link = document.createElement("a");
        link.href = responseURL;
        link.setAttribute("download", `${activeTableName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
    } catch (error) {
        setType({
            name: "alertMsg",
            type: "information",
            value: {
                message:
                    error?.response?.data?.message === undefined
                        ? "Erro ao buscar dados para exportação"
                        : error?.response?.data?.message,
            },
        });
        setShow(true);
        setLoading(false);
    }
}

async function copyToClipboard() {
  const output = [];
  const titles = [
    "CÓD. CLIENTE",
    "CLIENTE",
    "CÓD. ASSESSOR",
    "ASSESSOR",
    "CÓD. BROKER",
    "BROKER",
    "ESTRATÉGIA",
    "STATUS",
    "FINANCEIRO ENTRADA",
    "FINANCEIRO ATUAL",
    "RENTABILIDADE",
    "ENTRADA",
    "ÚLTIMA ATUALIZAÇÃO",
    "PRÓXIMA ATUALIZAÇÃO"
  ];
  output.push(titles.join("\t"));

  const keys = [
    "client_id",
    "client",
    "advisor_id",
    "advisor",
    "broker_id",
    "broker",
    "strategy",
    "status",
    "start_value",
    "current_value",
    "rentability",
    "entry",
    "last_change",
    "next_change"
  ];

  activeTable.forEach((x) => {
    const tmp = keys.map((y) => {
        return  y === "start_value" | y === "current_value" ?
                toBRL(x[y]) :
                y === "rentability" ? 
                String(x[y]).replace(".", ",") + ' %' 
                : x[y]
    });
    output.push(tmp.join("\t"));

  });
  const table = output.join("\n");
  navigator.clipboard.writeText(table);

  // console.log(listStructuredFormatted)
  alert("Conteúdo da tabela copiado para a área de transferência");
}



// HEADER FINANCEIRO

const updateSumHeader = (e) => {
    let sSum = 0;
    let cSum = 0;
    let rSum = 0;
    if (e.length > 0) {
        e.forEach(x => {
            sSum += parseFloat(activeTable[x.dataIndex].start_value)
            cSum += parseFloat(activeTable[x.dataIndex].current_value)
        });
        rSum = ((cSum / sSum) - 1) * 100
    }
    setStartValueSum(sSum);
    setCurrentValueSum(cSum);
    setRentabilitySum(rSum);
}

const updateSumHeaderSearch = (s) => {
    let sSum = 0;
    let cSum = 0;
    let rSum = 0;
    if (s == null) {
    if (activeTable.length > 0) {
        sSum = activeTable.reduce((prev, curr) => prev + curr.start_value, 0);
        cSum = activeTable.reduce((prev, curr) => prev + curr.current_value, 0);
        rSum = ((cSum / sSum) - 1) * 100
    }
    } else {
        const filteredTable = activeTable.filter((x) => Object.values(x).join("|").toLowerCase().includes(s.toLowerCase()));
        if (filteredTable.length > 0) {
            sSum = filteredTable.reduce((prev, curr) => prev + curr.start_value, 0);
            cSum = filteredTable.reduce((prev, curr) => prev + curr.current_value, 0);
            rSum = ((cSum / sSum) - 1) * 100
        }
    }
    // console.log(s.toLowerCase(), Object.values(activeTable[0]).join("|").toLowerCase())
    setStartValueSum(sSum)
    setCurrentValueSum(cSum)
    setRentabilitySum(rSum)
}

const showClientDetails = async (c) => {
    setShow(true);
    setType({ name: "showDetails", value: c });
}

// ROWS SELECIONADAS

// useEffect(() => {
//   console.log(activeTable.filter((v, i, a) => selectedRows.includes(i)))
// }, [selectedRows])

var columns = [
    {
        name: "client_id",
        label: "CÓD. CLIENTE",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
            //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
            //         {label}
            //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
            //     </TableCell>
            // ),
        },
    },
    {
        name: "client",
        label: "CLIENTE",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
            //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
            //         {label}
            //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
            //     </TableCell>
            // ),
        },
    },
    {
        name: "client_status",
        label: "STATUS",
        options: {
            filter: false,
            sort: false,
            display: false
        },
    },
    // {
    //   name: "advisor_id",
    //   label: "CÓD. ASSESSOR",
    //   options: {
    //       filter: true,
    //       sort: true,
    //       // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
    //       // display: tokenKey.type > 3 ? true : false,
    //       setCellProps: () => ({
    //           style: {
    //               whiteSpace: "nowrap",
    //               padding: '0px 0.6rem 0px 0.6rem',
    //           },
    //       }),
    //       customBodyRender: (value) => {
    //         return (
    //           <span>
    //             {value != undefined && value != null ? value : "-"}
    //           </span>
    //         );
    //     },
    //       // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
    //       //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
    //       //         {label}
    //       //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
    //       //     </TableCell>
    //       // ),
    //   },
    // },
    {
        name: "advisor",
        label: "ASSESSOR",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            customBodyRender: (value) => {
              return (
                <span>
                  {value != undefined && value != null ? value : "-"}
                </span>
              );
          },
            // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
            //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
            //         {label}
            //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
            //     </TableCell>
            // ),
        },
    },
    // {
    //     name: "broker_id",
    //     label: "CÓD. BROKER",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         // caso não seja para mostrar a coluna baseado no tipo de acesso, usar a linha display
    //         // display: tokenKey.type > 3 ? true : false,
    //         setCellProps: () => ({
    //             style: {
    //                 whiteSpace: "nowrap",
    //                 padding: '0px 0.6rem 0px 0.6rem',
    //             },
    //         }),
    //         customBodyRender: (value) => {
    //           return (
    //             <span>
    //               {value != undefined && value != null ? value : "-"}
    //             </span>
    //           );
    //       },
    //         // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
    //         //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
    //         //         {label}
    //         //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
    //         //     </TableCell>
    //         // ),
    //     },
    // },
    {
        name: "broker",
        label: "BROKER",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            customBodyRender: (value) => {
              return (
                <span>
                  {value != undefined && value != null ? value : "-"}
                </span>
              );
          },
            // customHeadRender: ({ index, label, ...column }, sortColumn, tableMeta) => (
            //     <TableCell onClick={() => sortColumn(index)} key={index} className="white_space">
            //         {label}
            //         <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} />
            //     </TableCell>
            // ),
        },
    },
    {
        name: "status",
        label: "STATUS",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
        },
    },
    {
        name: "start_value",
        label: "FINANCEIRO ENTRADA",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            customBodyRender: (value) => {
                return (
                  <span>
                    {toBRL(value)}
                    {/* {tableMeta} */}
                  </span>
                );
            },
        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
            <TableCell
                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                onClick={() => {
                    sortColumn(index);
                }}>
                <div className="textSumCell" style={{ marginTop : 0 }}>
                    <div>
                        {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                        {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                    </div>
                    <p>{toBRL(parseFloat(startValueSum))}</p>
                </div>
            </TableCell>
        ),
    }
    },
    {
        name: "current_value",
        label: "FINANCEIRO ATUAL",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            customBodyRender: (value) => {
                return (
                  <span>
                    {toBRL(value)}
                    {/* {tableMeta} */}
                  </span>
                );
            },
        customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
            <TableCell
                className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                onClick={() => {
                    sortColumn(index);
                }}>
                <div className="textSumCell" style={{ marginTop : 0 }}>
                    <div>
                        {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                        {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                    </div>
                    <p>{toBRL(parseFloat(currentValueSum))}</p>
                </div>
            </TableCell>
        ),
    }
    },
    {
        name: "rentability",
        label: "RENTABILIDADE",
        options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            customBodyRender: (value) => {
                return (
                  <span>
                    {toPercent(value)}
                    {/* {tableMeta} */}
                  </span>
                );
            },
            customHeadRender: ({ index, name, ...column }, sortColumn, tableMeta) => (
                <TableCell
                    className="containerSelectContactTypeHeader tss-gm6zfk-MUIDataTableHeadCell-fixedHeader"
                    onClick={() => {
                        sortColumn(index);
                    }}>
                    <div className="textSumCell" style={{ marginTop : 0 }}>
                        <div>
                            {/* <TableSortLabel active={tableMeta.name === column.name} direction={tableMeta.direction || "asc"} /> */}
                            {column.label} {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown /> : <></>}
                        </div>
                        <p>{toPercent(parseFloat(rentabilitySum))}</p>
                    </div>
                </TableCell>
            ),
        },
    },
    {
        name: "entry",
        label: "ENTRADA",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            // customBodyRender: (value) => {
            //     return (
            //       <span>
            //         {new Date(value).toLocaleDateString("pt-BR")}
            //         {/* {tableMeta} */}
            //       </span>
            //     );
            // },
        }
    },
    {
        name: "last_change",
        label: "ÚLTIMA ATUALIZAÇÃO",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            // customBodyRender: (value) => {
            //     return (
            //       <span>
            //         {new Date(value).toLocaleDateString("pt-BR")}
            //         {/* {tableMeta} */}
            //       </span>
            //     );
            // },
        }
    },
    {
        name: "next_change",
        label: "PRÓXIMA ATUALIZAÇÃO",
        options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                    padding: '0px 0.6rem 0px 0.6rem',
                },
            }),
            // customBodyRender: (value) => {
            //     return (
            //       <span>
            //         {new Date(value).toLocaleDateString("pt-BR")}
            //         {/* {tableMeta} */}
            //       </span>
            //     );
            // },
        }
    }
    // {
    //     name: "step_wisir",
    //     label: "STATUS GIRO",
    //     options: {
    //         filter: true,
    //         sort: true,
    //         setCellProps: () => ({
    //             style: {
    //                 whiteSpace: "nowrap",
    //                 padding: '0px 0.6rem 0px 0.6rem',
    //             },
    //         }),
    //     },
    // },
    // {
    //   name: "id",
    //   label: "HISTÓRICO",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         padding: '0px 0.6rem 0px 0.6rem'
    //       }
    //     }),
    //     customBodyRender: (value, table) => {
    //       return (
    //         <div className="historyBtn"
    //           onClick={(e) => {
    //             e.preventDefault();
    //             setShow(true);
    //               setType({
    //                 name: "showRecord",
    //                 value: activeTable[table.rowIndex],
    //               });
    //         }}
    //       >
    //         <BiSearch />
              
    //         </div>
    //       );
    //   },
    //   }
    // }
];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
const AddButton = () => (
    <>
        {/* <Tooltip disableFocusListener title="Mostrar Colunas">
            <IconButton onClick={() => { showModalShowColumns()}}>
                <span style={{fontSize: "1.4rem"}}>Personalizar Colunas</span>
            </IconButton>
        </Tooltip> */}

        {/* <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip> */}
        {/* <Tooltip disableFocusListener title="Selecione o período de operações desejado.">
            <IconButton
                onClick={() => {
                    showModalOffersHistory();
                }}>
                <span style={{ fontSize: "1.4rem" }}>Buscar Histórico</span>
            </IconButton>
        </Tooltip> */}
        {/* <Tooltip disableFocusListener title="Visualizar as ordens de ofertas ativas.">
            <IconButton
                onClick={() => {
                    setSelectedOpen(1);
                    // console.log(selectedOpen, "do dia");
                    getOrdersReccordsNow(1, now.getFullYear());
                }}>
                <span style={{ fontSize: "1.4rem" }}>Ofertas em Andamento</span>
            </IconButton>
        </Tooltip> */}
        {/* { selectedRows.length > 0 ? */}
        {/* <Tooltip title="">
          <IconButton
            disabled="True"
          ></IconButton>
        </Tooltip> */}
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
          <IconButton onClick={copyToClipboard}>
              <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        {user.type < 3 ? (
          <>
            <Tooltip title="Atualização via Excel.">
              <IconButton
                  onClick={() => {
                      setType({ name: 'showImport', value: activeTableId, strategy: activeTableName, update: getTab  });
                      setShow(true);
                  }}>
                  <span style={{ fontSize: "1.4rem" }}><FiArrowUp size={14}/> Importar</span>
              </IconButton>
            </Tooltip>
            <Tooltip title="Atualização via Excel.">
              <IconButton
                  onClick={() => {
                      setType({ name: 'showImportCarteira', value: activeTableId, strategy: activeTableName  });
                      setShow(true);
                  }}>
                  <span style={{ fontSize: "1.4rem" }}><FiArrowUp size={14}/> Carteira</span>
              </IconButton>
            </Tooltip>
          </>
        ) : (<></>)}
        <Tooltip title="Atualização via Excel.">
            <IconButton
                onClick={() => {
                    setType({ name: 'showAddClient', value: activeTableId, strategy: activeTableName, reloadTable: reloadTable  });
                    setShow(true);
                }}>
                <span style={{ fontSize: "1.4rem" }}><FiPlus size={14}/> Cliente</span>
            </IconButton>
        </Tooltip>
        
        {/* } */}
    </>
);

const sendUpdate = async (rows, strategy, operation, automatized) => {
  const rowsI = Object.keys(rows.lookup).map((x) => parseInt(x))
  const rowsToSend = rowsI.map((i) => activeTable[i].id)
  setShow(false);
  try{
      const updateWisir = await api.put(
      `/api/v1/wisir/operational/updateStatus`, {
        ids: rowsToSend,
        strategy_status_id: strategy,
        operation_status_id: operation,
        automatized: automatized
      },
      {
        headers: {
          authorization: `Bearer ${tokenKey}`,
        },
      }
      );
      getTab(activeTableId)
      setType({
        name: "alertMsg",
        type: "information",
        value: { message: 'Atualização realizada com sucesso.' },
      });
      setShow(true);
  } catch (error) {
    setLoading(false);
    setType({
      name: "alertMsg",
      type: "information",
      value: { message: error?.response?.data?.message },
    });
    setShow(true);
  }
}

const sendInactivate = async () => {
    const rowsToSend = selectedRows.map((s) => activeTable[s].id)
    setShow(false)
    try{
        const updateWisir = await api.put(
            `/api/v1/wisir2/inactivate`, {
                ids: rowsToSend
            },
            {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                },
            }
        );
        getTab(activeTableId)
        setType({
            name: "alertMsg",
            type: "information",
            value: { message: 'Atualização realizada com sucesso.' },
        });
        setShow(true);
    } catch (error) {
        setLoading(false);
        setType({
        name: "alertMsg",
        type: "information",
        value: { message: error?.response?.data?.message },
        });
        setShow(true);
    }
}


const options = {
  filterType: "multiselect",
  selectableRows: user.type < 3 ? "multiple" : false,
  selectableRowsOnClick: false,
  filter: true,
  download: true,
  print: false,
  responsive: "vertical",
  tableBodyHeight: "60vh",
  onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setSelectedRows(rowsSelected);
  },
  draggableColumns: {
      enabled: true,
  },
  selectableRowsHideCheckboxes: ~(user.type < 3),
  viewColumns: false,
  toolButton: {
      justifyContent: "center"
  },
  textLabels: {
      body: {
          noMatch: loading ? <ArrowLoader /> : "Não há operações no histórico.",
          toolTip: "Ordenar",
          columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
          next: "Próxima",
          previous: "Anterior",
          rowsPerPage: "Linhas por página:",
          displayRows: "de",
      },
      toolbar: {
          search: "Pesquisar",
          downloadCsv: "Download XLSX",
          print: "Imprimir",
          viewColumns: "Ver colunas",
          filterTable: "Filtrar",
      },
      filter: {
          all: "Todos",
          title: "Filtros",
          reset: "Limpar",
      },
      viewColumns: {
          title: "Mostrar Colunas",
          titleAria: "Mostrar/Ocultar colunas",
      },
      selectedRows: {
          text: "linha(s) selecionadas",
          delete: "Excluir",
          deleteAria: "Excluir linhas Selecionadas",
      },
  },
  downloadOptions: {
      filename: "wisir.csv",
      separator: ";",
  },
  onDownload: () => {
    requestFile();
      // assetStructuredExport();
      return false;
  },
  onFilterChange: (column, list, e, i, l) => {
    updateSumHeader(l);
  },
  onSearchChange: (search) => {
    updateSumHeaderSearch(search);
  },
  onRowClick: (rowData, rowMeta) => {
    showClientDetails({id: rowData[0], name: rowData[1], strategy: activeTableId, client_status: rowData[2] });
    },
  sortOrder: {
      name: 'current_value',
      direction: 'desc'
  },
  customToolbar: AddButton,
  setTableProps: () => {
      return {
          padding: 'none',
          size: 'small'
      };
},
  onRowsDelete: sendInactivate,
  rowsPerPage: rowsPerPage,
  rowsPerPageOptions: [30, 100, 1000],
};

const getMuiTheme = () =>
createTheme({
components: {
  MuiTableCell: {
      styleOverrides: {
          root:{
                  justifyContent: 'center',
                  whiteSpace: "nowrap",
                  textAlign: 'center',
          }
      },
    },
  MUIDataTableHeadCell: {
      styleOverrides: {
          contentWrapper: {
              justifyContent: 'center',
              whiteSpace: "nowrap",
              // textAlign: 'center'
          }
      }
  },
},
})

return (
    <div key="performanceTabsTable" className="performanceTabsTable">
        <>
          {/* ABAS +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
          <div key="performanceTabsMenuHeader" id="performanceTabsMenuHeader">
            <div key="performanceTabs" className="performanceTabs">
              <>
                {tabs?.map((tab) => {
                  return (
                    <label
                      key={"tabLabel_" + tab.id}
                      style={{ cursor: "pointer", width: 'auto', maxWidth: '15rem'}}
                      title="Clique para visualizar esta tabela"
                      id={"tabId_" + tab.id}
                      className={
                        activeTableId == tab.id
                          ? "performanceTabsLabelActive"
                          : "performanceTabsLabel"
                      }
                      onClick={() => {
                        setLoading(true);
                        setActiveTableId(tab.id);
                        setActiveTableName(tab.description);
                        getTab(tab.id);
                      }}
                    >
                      <a>{tab.description}</a>
                    </label>
                  );
                })}
              </>
            </div>
          </div>
          { loading ? (
            <div className="divLoading">
              <ArrowLoader />
          </div>
          ) : (

            <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                      title={`Operações Wisir`}
                      data={activeTable}
                      columns={columns}
                      options={options}
                      ></MUIDataTable>
              </ThemeProvider>

          )}
        </>
    </div>
  );
}
