/*Função que recebe os dados da planilha
/*e formata para o tipo de dado correto para exibição em tela*/

import { SerialDateToJSDatePtBR } from '../formatDate/dateSerialToPTBR'

export function formatLineToTablePreview(value, type) {
    switch (type) {
        case 'number':
            if (typeof (value) === 'number') {
                return parseFloat(value)
            } else if (typeof (value) === 'string') {
                try {
                    let newQuantity = 0
                    value.replace('.', '')
                    newQuantity = value.replace('.', '')
                    newQuantity = parseFloat(newQuantity)
                    if (!isNaN(newQuantity)) {
                        return newQuantity
                    }
                } catch (error) {
                    alert('Erro para ler a planilha, verifique')
                }
            }
            return 'Não foi possível ler campo numérico'
        case 'date':
            if (typeof (value) === 'number') {
                try {
                    return SerialDateToJSDatePtBR(value, -3)
                } catch (error) {
                    return 'Não foi possível ler campo data.'
                }
            } else {
                return value
            }

        case 'boolean':
            return value == 1
                || value === 'Sim'
                || value === 'SIM'
                || value === 'sim'
                || value === 'S'
                || value === 's' ? 'Sim' : 'Não'
        default:
            return value
    }
}
