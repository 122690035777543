/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Faz o controle de todos os sockets.
 **/
import React, { createContext, useContext, useState } from 'react'

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {

    const [ size, setSize ] = useState('M');
    const [ show, setShow ] = useState(false)
    const [ type, setType ] = useState({})
    const [ answer, setAnswer ] = useState(false)
    const [ edited, setEdited ] = useState(false)

    return (
        <ModalContext.Provider value={{
            setSize,
            size,
            setShow,
            show, 
            setType,
            type,
            setAnswer,
            answer,
            setEdited,
            edited
        }}>
            { children }
        </ModalContext.Provider>
    )
}

export function useModal(){
    return useContext(ModalContext);
}