import React, { useRef } from 'react';
import { useMenu } from '../../contexts/useMenu';
import { useSocket } from '../../contexts/useSocket';
import { Link, useLocation, useHistory } from 'react-router-dom';

const Body = ({ children }) => {

    const { menuStatus, setMenuStatus, showUser, setShowUser, showNotificationCenter, setShowNotificationCenter } = useMenu();
    const bodyRef = useRef();
    const { socketEmit } = useSocket();
    const location = useLocation();
    const closeUserBoxAndNotificationCenter = (e) => {

        if (showUser) setShowUser(false);
        if (showNotificationCenter) {
            setShowNotificationCenter(false)
            // socketEmit('readNotifications', location.pathname);
        };
    }

    return (
        <div ref={bodyRef} onClick={(e) => {
            closeUserBoxAndNotificationCenter(e); 
            if (document.getElementById('checkMenu').checked === true) {
                document.getElementById('checkMenu').click();
            };
            setMenuStatus(0)
            }} 
            className={`body ${menuStatus == 1 ? 'indentationRigth' : 'indentationNormal'}`}>
            {children}
        </div>
    )
}

export default Body