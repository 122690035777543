import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/offerings.registration.css'
import { styledForOfferings } from '../../../../components/styles/autocompletField'
/*Components*/
import { Autocomplete } from '@material-ui/lab'
import { TextField, TextareaAutosize } from '@material-ui/core'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
/*Utils*/
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
import { api } from '../../../../services/api'
/*Services*/
import { getAssetClass } from '../../../../services/asyncFunctions'
import { getAssetSectors } from '../../../../services/asyncFunctions'
import { getOffersTypes } from '../../../../services/asyncFunctions'

export function OfferingsRegistration() {

    const [asset, setAsset] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [assetClassId, setAssetClassId] = useState('')
    const [assetSectorId, setAssetSectorId] = useState('')
    const [assetOfferType, setAssetOfferType] = useState('')
    const [startReservation, setStartReservation] = useState('')
    const [finishReservation, setFinishReservation] = useState('')
    const [minimum, setMinimum] = useState(1)
    const [finishVnc, setFinishVnc] = useState('')
    const [offer, setOffer] = useState('')
    const [prospecto, setProspecto] = useState('')
    const [bookBuilding, setBookBuilding] = useState('')
    const [startNegotiation, setStartNegotiation] = useState('')
    const [liquidation, setLiquidation] = useState('')
    const [feeRetailWithoutLockup, setFeeRetailWithoutLockup] = useState(0)
    const [feeRetailWithLockup, setFeeRetailWithLockup] = useState(0)
    const [feeInstituitionWithoutLockup, setFeeInstituitionWithoutLockup] = useState(0)
    const [feeInstituitionWithLockup, setFeeInstituitionWithLockup] = useState(0)
    const [apportionmentExpectation, setApportionmentExpectation] = useState('')
    const [recommendation, setRecommendation] = useState('')
    const [selectedValue, setSelectedValue] = useState(0)
    const [requiredFields, setRequiredFields] = useState([])

    const classes = styledForOfferings()
    const [offersTypes, setOffersTypes] = useState([])
    const recommendations = [{ description: 'Entrar', id: 1 }, { description: 'Não entrar', id: 2 }]
    const [loading, setLoading] = useState(true)
    const { screenX, screenY } = ModalSizeFitToScreen()
    const { show, setShow, type, setType, setEdited } = useModal()
    const { tokenKey, user } = useAuth()
    const [notification, setNotification] = useState('')

    const [assetClassList, setAssetClassList] = useState([])
    const [assetSectorsList, setAssetSectorsList] = useState([])

    const [checkboxDireitoSobra, setCheckboxDireitoSobra] = useState(false)
    const [ativoDireitoSobra, setAtivoDireitoSobra] = useState('')

    useEffect(() => {

        getOffersTypesAPI()
        getAssetsClasses()
        getSectors()
        clear()

    }, [show])

    function validDates() {

        if (finishReservation !== '' && (startReservation > finishReservation)) {
            setNotification('Data fim reserva menor do que início da reserva, verifique')
            return 1
        }
    }

    function validFields() {

        setLoading(true)
        const requiredFieldsToCheck =
            [
                { field: 'offer', value: offer },
                { field: 'asset', value: asset },
                { field: 'finishReservation', value: finishReservation }, // + o inicio de reserva
                { field: 'company_name', value: companyName },
                { field: 'offers_types_id', value: assetOfferType },
                { field: 'assets_sector_id', value: assetSectorId },
                { field: 'minimum', value: minimum }
            ]

        let missingFields = 0
        requiredFieldsToCheck.filter((field) => {
            if (typeof (field.value) !== 'number' && !field.value.trim().length) {

                missingFields = 1
                requiredFields[field.field] = true
                setRequiredFields(requiredFields)
            }
        })

        return missingFields
    }

    async function getAssetsClasses() {

        setLoading(true);
        const assetsClassGet = await getAssetClass(tokenKey)
        if (assetsClassGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: assetsClassGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {

            const assetsToSet = assetsClassGet.filter((assetClasses) => {
                if (assetClasses.id === 1 || assetClasses.id === 2) {
                    return assetClasses
                }
            })
            setAssetClassList(assetsToSet)
        }
        setLoading(false)
    }

    async function getSectors() {

        const sectorsGet = await getAssetSectors(tokenKey)
        if (sectorsGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: sectorsGet?.error
                }
            })
            setShow(true);
            setLoading(false)
        } else {
            setAssetSectorsList(sectorsGet)
        }
    }

    async function handleSubmit() {

        setLoading(true)
        try {
            console.log('testando',{
                "offer": offer,
                "asset": asset,
                "company_name": companyName,
                "offers_types_id": assetOfferType,
                "assets_class_id": parseInt(type.class) === 1 ? 1 : 3,
                "assets_sector_id": assetSectorId,
                "start_of_reserve": startReservation !== '' ? startReservation : undefined,
                "finish_of_reserve": finishReservation !== '' ? finishReservation : undefined,
                "finish_vnc": finishVnc !== '' ? finishVnc : undefined,
                "bookbuilding": bookBuilding !== '' ? bookBuilding : undefined,
                "start_negotiation": startNegotiation !== '' ? startNegotiation : undefined,
                "liquidation": liquidation !== '' ? liquidation : undefined,
                "fee_retail_without_lockup": feeRetailWithoutLockup !== '' ? parseFloat(feeRetailWithoutLockup) : undefined,
                "fee_retail_with_lockup": feeRetailWithLockup !== '' ? parseFloat(feeRetailWithLockup) : undefined,
                "fee_institution_without_lockup": feeInstituitionWithoutLockup !== '' ? parseFloat(feeInstituitionWithoutLockup) : undefined,
                "fee_institution_with_lockup": feeInstituitionWithLockup !== '' ? parseFloat(feeInstituitionWithLockup) : undefined,
                "apportionment_expectation": apportionmentExpectation !== '' && parseInt(type.class) === 1 ? apportionmentExpectation : undefined,
                "value": selectedValue > 0 ? selectedValue : undefined,
                "recommendation": recommendation === "Entrar" ? true : false,
                "prospecto": prospecto != '' ? prospecto : undefined,
                "minimum": minimum,
                "direitoSobra": ativoDireitoSobra != '' ? ativoDireitoSobra : undefined
            })
            await api.post(`/api/v1/offers`, {
                "offer": offer,
                "asset": asset,
                "company_name": companyName,
                "offers_types_id": assetOfferType,
                "assets_class_id": parseInt(type.class) === 1 ? 1 : 3,
                "assets_sector_id": assetSectorId,
                "start_of_reserve": startReservation !== '' ? startReservation : undefined,
                "finish_of_reserve": finishReservation !== '' ? finishReservation : undefined,
                "finish_vnc": finishVnc !== '' ? finishVnc : undefined,
                "bookbuilding": bookBuilding !== '' ? bookBuilding : undefined,
                "start_negotiation": startNegotiation !== '' ? startNegotiation : undefined,
                "liquidation": liquidation !== '' ? liquidation : undefined,
                "fee_retail_without_lockup": feeRetailWithoutLockup !== '' ? parseFloat(feeRetailWithoutLockup) : undefined,
                "fee_retail_with_lockup": feeRetailWithLockup !== '' ? parseFloat(feeRetailWithLockup) : undefined,
                "fee_institution_without_lockup": feeInstituitionWithoutLockup !== '' ? parseFloat(feeInstituitionWithoutLockup) : undefined,
                "fee_institution_with_lockup": feeInstituitionWithLockup !== '' ? parseFloat(feeInstituitionWithLockup) : undefined,
                "apportionment_expectation": apportionmentExpectation !== '' && parseInt(type.class) === 1 ? apportionmentExpectation : undefined,
                "value": selectedValue > 0 ? selectedValue : undefined,
                "recommendation": recommendation === "Entrar" ? true : false,
                "prospecto": prospecto != '' ? prospecto : undefined,
                "minimum": parseInt(minimum),
                "direitoSobra": ativoDireitoSobra != '' ? ativoDireitoSobra : undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg', type: 'information', value: { message: 'Oferta cadastrada com sucesso!' } })
            setEdited(true)
            setLoading(false)
        } catch (error) {
            setNotification(error?.response?.data?.message === undefined ? 'Erro ao enviar requisição' : error?.response?.data?.message)
            setLoading(false)
        }
    }

    async function getOffersTypesAPI() {

        const offersTypesGet = await getOffersTypes(tokenKey)
        if (offersTypesGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: offersTypesGet?.error
                }
            })
            setShow(true);
        } else {
            setOffersTypes(offersTypesGet)
        }
    }

    function clear() {
        setOffer('')
        setAsset('')
        setAssetClassId('')
        setAssetSectorId('')
        setBookBuilding('')
        setCompanyName('')
        setFeeInstituitionWithLockup('')
        setFeeInstituitionWithoutLockup('')
        setFeeRetailWithLockup('')
        setFeeRetailWithoutLockup('')
        setFinishVnc('')
        setLiquidation('')
        setStartNegotiation('')
        setStartReservation('')
        setRecommendation('')
        setSelectedValue('')
        setRequiredFields({})
        setAssetOfferType('')
        setNotification('')
        setProspecto('')
        setMinimum(1)
    }

    return (
        <div className="offeringsRegistrationContainer" style={{ width: screenX > 1400 ? screenX - 100 : screenX, minHeight: 550 }}>
            <div className="offeringsRegistrationHeader">
                <p>Cadastro de Ofertas</p>
                <button onClick={() => setShow(false)} className='modalCloseButton'>X</button>
            </div>
            {
                loading ?
                    (
                        <div className='offeringsRegistrationLoaderContainer'>
                            <ArrowLoader />
                        </div>
                    ) :
                    (
                        <form>
                            <div className='offeringsCardsRegistrationRow'>
                                <label for='offer'>
                                    Título da Oferta
                                    <TextField className={requiredFields['offer'] && !asset.trim().length ? classes.boxUIWError : classes.boxUI} onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' value={offer} inputProps={{ maxLength: '255' }} onChange={(e) => setOffer(e.target.value)} id='offer' />
                                </label>
                            </div>
                            
                            <div className='offeringsCardsRegistrationRow'>
                                <label for='sector'>
                                    Setor
                                    <Autocomplete
                                        title='Caso não exista o setor, efetue o cadastro na tela de cadastro de setores'
                                        options={assetSectorsList}
                                        onChange={(event, newValue) => {
                                            setAssetSectorId(newValue.id);
                                        }}
                                        value={assetSectorId?.description}
                                        getOptionLabel={(option) => option?.description}
                                        id='sector'
                                        renderInput={(params) =>
                                            <TextField
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                {...params}
                                                className={requiredFields['assets_sector_id'] ? classes.boxUIWError : classes.boxUI}
                                                variant='outlined'
                                            />
                                        }
                                    />
                                </label>
                                <label for='offersTypes'>
                                    Tipo
                                    <Autocomplete
                                        title='Caso não exista o setor, efetue o cadastro na tela de cadastro de setores'
                                        options={offersTypes}
                                        onChange={(event, newValue) => {
                                            setAssetOfferType(newValue?.id);
                                        }}
                                        getOptionLabel={(option) => option?.description}
                                        id='offersTypes'
                                        renderInput={(params) =>
                                            <TextField className={requiredFields['offers_types_id'] ? classes.boxUIWError : classes.boxUI}
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                variant='outlined'
                                                {...params}
                                            />
                                        }
                                    />
                                </label>
                                <label for='recommendation'>
                                    Recomendação
                                    <Autocomplete
                                        id='recommendation'
                                        title='Caso não exista a classe, efetue o cadastro na tela de cadastro de classes'
                                        options={recommendations}
                                        onChange={(event, newValue) => {
                                            setRecommendation(newValue.description);
                                        }}
                                        getOptionLabel={(option) => option?.description}
                                        renderInput={(params) =>
                                            <TextField className={classes.boxUI}
                                                onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }}
                                                variant='outlined'
                                                {...params}
                                            />
                                        }
                                    />
                                </label>
                                <label>
                                    Valor
                                    <CurrencyTextField
                                        variant="outlined"
                                        value={selectedValue}
                                        currencySymbol="R$"
                                        className={requiredFields['selectedValue'] && (selectedValue === '' || selectedValue === 0) ? classes.rootWError : classes.boxUI}
                                        outputFormat="number"
                                        decimalCharacter=","
                                        id={`value`}
                                        required={true}
                                        digitGroupSeparator="."
                                        onChange={(event, value) => {

                                            if (value === '' || value === 0) {
                                                setSelectedValue(0)
                                            } else {
                                                setSelectedValue(value);
                                            }
                                        }}
                                    />
                                </label>
                                <label className='offeringsDateLabel' for='minimum'>
                                    Quantia mínima
                                    <TextField className={requiredFields['minimum'] && !minimum > 0 ? classes.boxUIWError : classes.boxUI} type="number" onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' value={minimum} inputProps={{ min: 1 }} step='1.00' onChange={(e) => setMinimum(parseInt(e.target.value))} id='minimum' />
                                </label>
                            </div>
                            <div id='offeringsRegistrationFlexDiv'>
                                <div className='offeringsCardsRegistration'>
                                    <div className='offeringsCardsRegistrationDualDiv'>
                                        <label className='offeringsDateLabel' for='asset'>
                                            Ativo
                                            <TextField className={requiredFields['asset'] && !asset.trim().length ? classes.boxUIWError : classes.boxUI} onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' value={asset.toUpperCase()} inputProps={{ maxLength: '255' }} onChange={(e) => setAsset(e.target.value)} id='asset' />
                                        </label>
                                        {
                                            (parseInt(type.class) === 1 || parseInt(type.class) === 2) && // class 1 é ações
                                            <label className='offeringsDateLabel' for='companyName'>
                                                Empresa
                                                <TextField onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} value={companyName} inputProps={{ maxLength: '30' }} className={requiredFields['company_name'] && !companyName.trim().length ? classes.boxUIWError : classes.boxUI} variant='outlined' onChange={(e) => setCompanyName(e.target.value)} id='companyName' />
                                            </label>
                                        }

                                    </div>
                                    {/* {
                                        parseInt(type.class) === 2 && // class 2 é fiis
                                        <div className='offeringsCardsRegistrationDualDiv'>
                                            <label className='offeringsDateLabel' for='companyName'>
                                                Empresa
                                                <TextField onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} value={companyName} inputProps={{ maxLength: '30' }} className={requiredFields['company_name'] && companyName == '' ? classes.boxUIWError : classes.boxUI} variant='outlined' onChange={(e) => setCompanyName(e.target.value)} id='companyName' />
                                            </label>
                                        </div>
                                    } */}

                                    {
                                        (assetOfferType === 2 || assetOfferType === 3 || assetOfferType === 4) ?
                                            <div className='offeringsCardsRegistrationDualDiv'>
                                                <label className='offeringsDateLabel' for='feeReatilWithoutLockup'>
                                                    FEE para reserva
                                                    <TextField value={feeRetailWithoutLockup} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type='number' inputProps={{ min: 0, max: 100 }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} className={classes.boxUI} variant='outlined' id='feeReatilWithoutLockup' onChange={(e) => {
                                                        if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                                                            setFeeRetailWithoutLockup(e.target.value)
                                                        }
                                                    }} />
                                                </label>
                                            </div>
                                            :
                                            <>
                                                <div className='offeringsCardsRegistrationDualDiv'>
                                                    <label className='offeringsDateLabel' for='feeReatilWithoutLockup'>
                                                        {parseInt(type.class) === 2 ? 'FEE Direito de preferência %' : 'Fee varejo sem lockup %'}
                                                        <TextField value={feeRetailWithoutLockup} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} type='number' inputProps={{ min: 0, max: 100 }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} className={classes.boxUI} variant='outlined' id='feeReatilWithoutLockup' onChange={(e) => {
                                                            if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                                                                setFeeRetailWithoutLockup(e.target.value)
                                                            }
                                                        }} />
                                                    </label>
                                                    <label className='offeringsDateLabel' for='feeReatailWithLockup'>
                                                        {parseInt(type.class) === 2 ? 'FEE Sobras %' : 'Fee varejo com lockup %'}
                                                        <TextField type='number' inputProps={{ min: 0, max: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} value={feeRetailWithLockup} className={classes.boxUI} variant='outlined' onChange={(e) => {
                                                            if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                                                                setFeeRetailWithLockup(e.target.value)
                                                            }
                                                        }} />
                                                    </label>
                                                </div>

                                                <div className='offeringsCardsRegistrationDualDiv'>
                                                    <label className='offeringsDateLabel' for='feeinstlWithoutLockup'>
                                                        {parseInt(type.class) === 2 ? 'FEE Lote adicional %' : 'Fee inst sem lockup %'}
                                                        <TextField type='number' inputProps={{ min: 0, max: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} className={classes.boxUI} variant='outlined' value={feeInstituitionWithoutLockup} id='feeinstlWithoutLockup' onChange={(e) => {
                                                            if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                                                                setFeeInstituitionWithoutLockup(e.target.value)
                                                            }
                                                        }} />
                                                    </label>
                                                    <label className='offeringsDateLabel' for='feeInstWithLockup'>
                                                        {parseInt(type.class) === 2 ? 'FEE Publica %' : 'Fee inst com lockup %'}
                                                        <TextField type='number' inputProps={{ min: 0, max: 100 }} onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} step='0.00' onKeyDown={(e) => { if (e.key === 'e' || e.key === 'E') { e.preventDefault() } }} className={classes.boxUI} value={feeInstituitionWithLockup} variant='outlined' id='feeInstWithLockup' onChange={(e) => {
                                                            if (parseInt(e.target.value) <= 100 || e.target.value == 0) {
                                                                setFeeInstituitionWithLockup(e.target.value)
                                                            }
                                                        }} />
                                                    </label>
                                                </div>
                                            </>
                                    }

                                    {
                                        <div className='offeringsCardsRegistrationDualDiv'>
                                            <label className='offeringsDateLabel' for='prospectoLink'>
                                                    Link do Prospecto
                                                    <TextField className={classes.boxUI} onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' value={prospecto} inputProps={{ maxLength: '255' }} onChange={(e) => setProspecto(e.target.value)} id='prospectoLink' />
                                                </label>
                                        </div>
                                    }


                                    {
                                        parseInt(type.class) === 1 && <label className='offeringsDateLabel' for='apportionmentExpectation'>Expectativa de rateio<TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} id='apportionmentExpectation' inputProps={{ maxLength: 255 }} onChange={(e) => setApportionmentExpectation(e.target.value)} /></label>
                                    }
                                </div>
                                <div className='offeringsCardsRegistration'>
                                    <div className='offeringsCardsRegistrationDualDiv'>
                                        <label className='offeringsDateLabel' for='bookbuilding'>Bookbuilding
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' type='date' id='bookbuilding' value={bookBuilding} placeholder='Bookbuilding' onChange={(e) => setBookBuilding(e.target.value)} />
                                        </label>
                                        <label className='offeringsDateLabel' for='startReservation'>Início reserva
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' type='date' id='startReservation' value={startReservation} placeholder='Início reserva' onChange={(e) => setStartReservation(e.target.value)} />
                                        </label>
                                    </div>
                                    <div className='offeringsCardsRegistrationDualDiv'>
                                        <label className='offeringsDateLabel' for='finishReservation'>Fim da reserva
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={requiredFields['finishReservation'] && finishReservation == '' ? classes.boxUIWError : classes.boxUI} variant='outlined' id='finishReservation' type='date' value={finishReservation} placeholder='Término Reserva' onChange={(e) => setFinishReservation(e.target.value)} />
                                        </label>
                                        <label className='offeringsDateLabel' for='finishVnc'>Fim VINC
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' id='finishVnc' type='date' value={finishVnc} placeholder='Término VINC' onChange={(e) => setFinishVnc(e.target.value)} />
                                        </label>
                                    </div>
                                    <div className='offeringsCardsRegistrationDualDiv'>
                                        <label className='offeringsDateLabel' for='startNegotiation'>Início negociação
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' id='startNegotiation' type='date' value={startNegotiation} placeholder='Início negociações' onChange={(e) => setStartNegotiation(e.target.value)} />
                                        </label>
                                    </div>
                                    <div className='offeringsCardsRegistrationDualDiv'>
                                        <label className='offeringsDateLabel' for='liquidation'>Liquidação
                                            <TextField onKeyPress={(e) => { if (e.code === 'Backquote') e.preventDefault() }} className={classes.boxUI} variant='outlined' type='date' id='liquidation' value={liquidation} onChange={(e) => setLiquidation(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='containerDireitoSobras'>
                                <div>Direito de preferência ou sobras? <input type="checkbox" id="archive" className='checkBoxDireitoSobras' onClick={(e) => {
                                    setCheckboxDireitoSobra(!checkboxDireitoSobra)
                                    setAtivoDireitoSobra('')
                                }}/></div>
                                    <label className='offeringsDateLabel' for='asset' style={{display: checkboxDireitoSobra ? 'inline' : 'none'}}>
                                        Código do ativo
                                        <TextField className={classes.boxUI} onKeyDown={(e) => { if (e.code === 'Backquote') { e.preventDefault() } }} variant='outlined' id='asset' style={{padding: "0.2rem"}} onChange={(e) => setAtivoDireitoSobra(e.target.value)}/>
                                    </label>
                            </div>
                            <div className='offeringsCadastrationButtonDiv'>
                                <button id='offeringsCadastrationButton' className='buttonWhiteBg' onClick={() => {
                                    if (validDates() > 0) {
                                        return
                                    } else {
                                        if (validFields() === 0) {
                                            handleSubmit()
                                        } else {
                                            setTimeout(() => {
                                                setNotification('Campos obrigátorios não preenchidos')
                                                setLoading(false)
                                            }, [300])
                                        }
                                    }

                                }}> Cadastrar </button>
                            </div>
                        </form>
                    )
            }
            {notification.length > 0 &&
                <footer id='offersRegistrationFooter'>
                    <p>{notification}</p>
                    <button title='Fechar aviso' onClick={() => setNotification('')}>x</button>
                </footer>
            }
        </div >
    )
}