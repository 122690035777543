import React from "react";
/*Styles*/
import "./styles/recomendations.css";
/*Components*/
import Modal from "../../../components/modal/modal";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import { WisirControleTable } from "./tables/wisirControle.table";
import { WisirEditRow } from "./modalWisir/componentsModalWisir/wisirEditRow";
/*Contexts*/
import { useModal } from "../../../contexts/useModal";

export function WisirControle() {
  const { type } = useModal();

  return (
    <div className="recomendationsContainer">
      <Modal>
        {type.name === "alertMsg" ? (
          <ConfirmationBox msgData={type} />
        ) : type.name === "editRow" ? (
          <WisirEditRow value={type.value} func={type.func} />
        ) : (
          <></>
        )}
      </Modal>
      <WisirControleTable prop={type} />
    </div>
  );
}
