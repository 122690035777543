
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import Cropper from 'react-easy-crop'
import './avatarEdit.css';
import { getCroppedImg } from '../../../utils/formatImages/canvasUtils'
import { useModal } from '../../../contexts/useModal';
import { useAuth } from '../../../contexts/useAuth';
import { api } from '../../../services/api';
import { ArrowLoader } from '../../loaders/arrowLoader';

export function AvatarEdit() {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [successAvatarUpload, setSuccessAvatarUpload] = useState(false);
    const [picturePreview, setPicturePreview] = useState('');
    const [sendFile, setSendFile] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const { type, setType } = useModal();
    const [loading, setLoading] = useState(false);
    const { userAvatar, setUserAvatar } = useAuth();
    const token = localStorage.getItem('@auth:token');
    const history = useHistory();

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageReady = await getCroppedImg(
                imageSrc,
                croppedAreaPixels
            )

            const mimeTypes = ['image/png', 'image/jpeg']
            const extension = ['.png', '.jpeg']

            try {

                const blob = await (await fetch(croppedImageReady)).blob();


                if (mimeTypes.indexOf(blob.type) > -1) {
                    const file = new File([blob], `image${extension[mimeTypes.indexOf(blob.type)]}`, {
                        type: blob.type
                    })
                    setCroppedImage(URL.createObjectURL(file));
                    setSendFile(file);


                }
                else {
                    throw new Error('Esse tipo de arquivo não pode ser colocado como avatar')
                }

            } catch (error) {
                if (error.response?.data?.message) {
                    return alert(error.response.data.message)
                }
                return alert(error.message)
            }
        } catch (e) {
            return alert('Erro ao tentar salvar imagem redimensionada.')
        }

    }, [imageSrc, croppedAreaPixels])

    useEffect(() => {
        setImageSrc(type?.value?.imgSrc);
        setPicturePreview(type?.value?.picPreview)
    }, [])

    async function handleSendImgToServer() {
        setLoading(true);
        try {

            const formData = new FormData();
            formData.append('avatar', sendFile);

            console.log(sendFile)

            const avatarPostResult = await api.post(`/api/v1/users/${type?.user?.external_id}/upload`, formData, {
                headers: {
                    authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                }
            })

            setUserAvatar(avatarPostResult.data.avatar);
            setSuccessAvatarUpload(true);
            setLoading(false);
        }
        catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    return (

        picturePreview !== '' ? (
            <>
                <div className='cropArea'>
                    {
                        loading ? (
                            <div id='userEdittedImageLoader'>
                                <ArrowLoader />
                            </div>
                        ) :
                            successAvatarUpload ?
                                (
                                    <div id='uploadSuccessMsg'>
                                        <a>Sucesso ao alterar foto de perfil</a>
                                        <button onClick={() => history.push('/')}>Fechar</button>
                                    </div>
                                ) :
                                croppedImage !== null ? (
                                    <>
                                        <div id='croppedImageConfirmation'>
                                            <a>Resultado</a>
                                            <div>
                                                <button onClick={() => handleSendImgToServer()}>Confirmar</button>
                                                <button onClick={() => setCroppedImage(null)}>Cancelar</button>
                                            </div>
                                        </div>
                                        <img id='croppedImage' srcSet={croppedImage} />
                                    </>) :
                                    (
                                        <>
                                            <div id='croppedImageEdition'>
                                                <button onClick={showCroppedImage}>Cortar</button>
                                                <button onClick={() => history.goBack()}>Cancelar</button>
                                            </div>
                                            <Cropper
                                                image={picturePreview}
                                                crop={crop}
                                                cropSize={{ width: 450, height: 450 }}
                                                zoom={zoom}
                                                aspect={4 / 3}
                                                onCropChange={setCrop}
                                                onCropComplete={onCropComplete}
                                                onZoomChange={setZoom}
                                            />
                                        </>
                                    )}

                </div>

            </>

        ) : (<></>)
    )
}