/*Componente Brokers
/*Descrição: Exibe os brokers vinculados ao cliente
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useState, useEffect } from 'react';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//hooks
import { useModal } from '../../../../contexts/useModal';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
//styles
import './styles/clients.brokers.table.css';

export function BrokersTable(props) {

    const clientName = props?.brokerData?.value?.client?.name;
    const [listBrokers, setListBrokers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { type, setShow } = useModal();

    useEffect(() => {
        setLoading(true);
        if (type?.value?.broker !== undefined) {

            setListBrokers(type?.value?.broker);
            setLoading(false)
        }

    }, [type])

    return (
        <div className='brokersTableContainer'>
            {loading ?
                (<div className='brokersTableLoader'><ArrowLoader /></div>)
                :
                (
                    <>
                        <div className='brokersTableHead'>
                            <div>
                                <p>Lista de Brokers vinculados ao cliente:</p>
                                <p>{titleize(clientName)}</p>
                            </div>
                            <button onClick={()=>setShow(false)}>x</button>
                        </div>
                        <div className='brokersTableDiv'>
                            <div className='brokersTableBody'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Nome</th>
                                            <th>Perfil</th>
                                            <th>Indicado</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listBrokers?.length > 0 ?
                                        listBrokers.map((broker) => {
                                            return (
                                                <tr>
                                                    <td>{broker?.users_external_id}</td>
                                                    <td>{titleize(broker?.user_full_name)}</td>
                                                    <td>{titleize(broker?.profile_description)}</td>
                                                    <td>Sim</td>
                                                </tr>
                                            )
                                        }) :
                                        (<tr><td colsPan='4'>Não há brokers vinculados</td></tr>)}
                                        
                                    </tbody>
                                </table>
                            </div>
                            <button onClick={()=>setShow(false)}>Fechar</button>
                        </div>
                    </>
                )}
        </div>
    )
}