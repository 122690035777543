import React, { useEffect, useState } from 'react'
/*Styles*/
import './styles/assets.edit.css'
import { styleClientsInput } from '../../../../components/styles/autocompletField'
/*Components*/
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
/*Contexts*/
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
/*Services*/
import { api } from '../../../../services/api'
/*Utils*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'

export function AssetsEdit(props) {

    const { tokenKey } = useAuth()
    const [loading, setLoading] = useState(false)
    const { show, setShow, setType, setEdited } = useModal()
    const classes = styleClientsInput()
    const [listAssetsClass, setListAssetsClass] = useState([])
    const [selectedAsset, setSelectedAsset] = useState(props?.data?.asset)
    const [selectedAssetValue, setSelectedAssetValue] = useState(0)
    const [selectedAssetClass, setSelectedAssetClass] = useState(props?.data?.asset_class_description)
    const [selectedAssetSectorId, setSelectedAssetSectorId] = useState(props?.data?.asset_sector_id)
    const [selectedNewCompanyName, setSelectedNewCompanyName] = useState(props?.data?.company_name)
    const { screenX, screenY } = ModalSizeFitToScreen()

    useEffect(() => {
        setSelectedAsset(props?.data?.asset)
        setSelectedNewCompanyName(props?.data?.company_name)
        setSelectedAssetClass({ id: props?.data?.asset_class_id, description: props?.data?.asset_class_description })
        setSelectedAssetSectorId(props.data?.asset_sector_id)
        setSelectedAssetValue(props?.data?.value)

        async function getAssetsClasses() {
            setLoading(true)
            try {
                const assetClasses = await api.get(`/api/v1/assetClass`, {
                    headers: {
                        authorization: `Bearer ${tokenKey}`
                    }

                })
                setListAssetsClass(assetClasses?.data?.data)
                setLoading(false)
            } catch (error) {
                setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao listar classes de ativos' : error?.response?.data?.message } })
                setShow(true)
            }
        }
        getAssetsClasses()

    }, [show])

    async function handleEdit() {

        setLoading(true)
        try {
            const updateAsset = await api.put(`/api/v1/assets/${selectedAsset}`, {
                "company_name": selectedNewCompanyName,
                "asset_class_id": selectedAssetClass?.id,
                "asset_sector_id": selectedAssetSectorId,
                "value": selectedAssetValue
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'alertMsg',type: 'information', value: { message: 'Ativo editado com sucesso!'} })
            setEdited(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao atualizar ativo' : error?.response?.data?.message } })
        }
    }

    return (
        <div className="assetsEditContainer" style={{
            width: screenX > 1300 ? screenX - 750 : screenX - 350,
            height: screenY > 700 ? screenY - 250 : screenY - 100
        }}>
            <div className="assetsEditHeader">
                <p>Edição de ativo</p>
                <button className='modalCloseButton' onClick={()=> setShow(false)}>X</button>
            </div>
            <div className="assetsEditBody">
                {
                    loading ?
                        (
                            <ArrowLoader />
                        ) :
                        (
                            <>
                                <div className='assetEditCentralComponent'>
                                    <label className='assetEditDualLabel'>
                                        <label className='assetEditNameLabel'>
                                            <a id='assetEditNameFirst'>Ativo:</a>
                                            <a>{selectedAsset}</a>
                                        </label>
                                        <TextField
                                            value={titleize(selectedNewCompanyName)}
                                            onChange={(e) => setSelectedNewCompanyName(e.target.value)}
                                            label='Nome da empresa'
                                            className={classes.styleForClients}
                                            variant='outlined'
                                        />
                                    </label>
                                    <label className="assetEditDualLabel">
                                        <Autocomplete
                                            title='Escolha a classe do ativo'
                                            options={listAssetsClass}
                                            value={selectedAssetClass}
                                            onChange={
                                                (event, newValue) => {
                                                    setSelectedAssetClass(newValue)
                                                }
                                            }
                                            getOptionLabel={(x) => titleize(x.description)}
                                            renderInput={(params) =>
                                                <TextField
                                                    label='Classe'
                                                    className={classes.styleForClients}
                                                    {...params} variant="outlined" />
                                            }
                                        />
                                        <CurrencyTextField
                                            variant="outlined"
                                            value={selectedAssetValue}
                                            currencySymbol="R$"
                                            onChange={(event, value) => {
                                                setSelectedAssetValue(value)
                                            }}
                                            className={classes.styleForClients}
                                            outputFormat="number"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                        />
                                    </label>
                                </div>
                                <footer>
                                    <button
                                        className='buttonWhiteBg'
                                        onClick={() => handleEdit()}
                                    >Confirmar</button>
                                </footer>
                            </>
                        )
                }
            </div>
        </div>
    )
}