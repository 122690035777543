/*Componente tabela de empresas
/*Descrição: Exibe todas as empresas cadastradas no sistema
/*Desenvolvido por: Equipe Rafael Cezário
*/

import React, { useEffect, useState } from 'react';
import { FiPlus, FiTrash2, FiArrowDown, FiCopy } from 'react-icons/fi'
//Contexts
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//services
import { api } from '../../../../services/api';
import { getBranches } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader'
//styles
import './styles/branches.table.css';
//Utils//
import { cnpjMask } from '../../../../utils/formatNumberAndText/cnpjMask';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'

export function BranchesTable() {

    const { show, setShow, edited, setEdited, setType } = useModal()
    const { tokenKey } = useAuth();
    const [filtered, setFiltered] = useState('');
    const [listBranches, setListBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token');
    const { screenY } = ModalSizeFitToScreen();

    useEffect(async () => {

        async function exec() {

            setLoading(true)
            const branchesGet = await getBranches(tokenKey);
            if (branchesGet?.isError) {
                setType({
                    name: 'alertMsg',
                    type: 'information',
                    value: {
                        message: branchesGet?.error
                    }
                })
                setShow(true);
                setLoading(false);
            } else {
                setListBranches(branchesGet);
                setLoading(false);
            }
        }

        exec()

    }, [edited])


    const handleDelete = async (branchCompany) => {

        setLoading(true);
        setShow(false)

        try {
            await api.delete(`/api/v1/branches/${branchCompany.id}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setEdited(!edited);
            setType({name: 'alertMsg', type: 'information', value: {message: "Registro removido com sucesso!"}})
            setShow(true)
        } catch (error) {
            setLoading(false)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
            setShow(true)
        }

    }

    function listFilter() {
        if (filtered === null || filtered === undefined || filtered === '') {
            return listBranches;
        } else {
            return listBranches.filter((filterRow) => {

                if (filterRow.city.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                    filterRow.city_state.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                    filterRow.cnpj.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                    filterRow.name.toLowerCase().indexOf(filtered.toLowerCase()) > -1) {
                    return filterRow;
                }
            })
        }
    }

    function editBranche(brancheData) {

        setType({ name: 'editBranche', value: { brancheData } });
        setShow(true);
    }

    async function branchesExport() {
        setLoading(true);
        try {
            const exportAssetsResult = await api.get(`/api/v1/branches/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportAssetsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_empresas.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    return (
        <div className='companiesTableContainer' style={{
            maxHeight: screenY

        }}>
            <table>
                <thead>
                    <tr>
                        <th colSpan='6'>
                            <div className='companiesTheadFirst'>
                                <a>Lista de Empresas</a>
                                <div className='substitutoLabelDisplay'>               
                                <button
                                    className='buttonTableThead'
                                    onClick={() => {
                                        const output = [];
                                        const titles = ['Nome', 'CNPJ', 'Cidade', 'Estado', 'Tipo de Estabelecimento']
                                        output.push(titles.join('\t'))
                                        const keys = ['name', 'cnpj','city', 'city_state', 'parent_company'];
                                        listBranches.forEach((x) => {
                                            const tmp = keys.map((y) => {return y==='parent_company'? x[y] === false ? 'Filial' : 'Matriz' : x[y]});
                                            output.push(tmp.join('\t'))
                                        })
                                        const table = output.join('\n')
                                        navigator.clipboard.writeText(table)
                                        alert('Conteúdo da tabela copiado para a área de transferência')
                                    }}
                                ><FiCopy />Copiar</button>

                                <button
                                        className='buttonTableThead'
                                        onClick={() => {
                                            branchesExport();
                                    }}><FiArrowDown />Exportar</button>

                                <button id='companiesCadastration' onClick={() => { setType({ name: 'companiesCadastration' }); setShow(!show) }}><FiPlus />Novo</button>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Nome</th>
                        <th>CNPJ</th>
                        <th>Cidade</th>
                        <th>Estado</th>
                        <th>Tipo de Estabelecimento</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ?
                            (
                                <td colSpan='6' id='companiesArrowLoader'>
                                    <ArrowLoader />
                                </td>
                            ) :
                            listBranches?.length > 0 ?
                                (
                                    listFilter()?.map((branch) => {

                                        return (
                                            <tr>
                                                <td title='Alterar empresa' onClick={() => editBranche(branch)}>{branch.name}</td>
                                                <td title='Alterar empresa' onClick={() => editBranche(branch)}>{cnpjMask(branch?.cnpj)}</td>
                                                <td title='Alterar empresa' onClick={() => editBranche(branch)}>{branch.city}</td>
                                                <td title='Alterar empresa' onClick={() => editBranche(branch)}>{branch.city_state}</td>
                                                <td title='Alterar empresa' onClick={() => editBranche(branch)}>{branch.parent_company ? 'Matriz' : 'Filial'}</td>
                                                <td><FiTrash2 size='20' cursor='pointer' onClick={() => {

                                                    setType({ 
                                                        name: 'alertMsg', 
                                                        value: { message: 'Deseja realmente excluir a empresa selecionada?', 
                                                        func: handleDelete, 
                                                        dataForFunc: branch 
                                                    } });
                                                    setShow(true);

                                                }} /></td>
                                            </tr>
                                        )
                                    })
                                ) :
                                (
                                    <tr id='companiesTableEmptyRow'>
                                        <th colspan='6'>-- Lista vazia --</th>
                                    </tr>
                                )
                    }

                </tbody>
            </table>
        </div>
    )
}