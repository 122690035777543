/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Componente de tabelas que lista relatórios
 **/

import React, { useState, useEffect } from 'react'
import { FiPlus, FiDownload, FiTrash } from 'react-icons/fi'

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import { toBRL } from "../../../utils/formatNumberAndText/toBRLCurrency";
import { toPercent } from "../../../utils/formatNumberAndText/formatNumberPercent";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { TableCell } from "@material-ui/core";
//hooks
import { useAuth } from '../../../contexts/useAuth'
import { useModal } from '../../../contexts/useModal'
//components
import { ArrowLoader } from '../../../components/loaders/arrowLoader'
//services
import { api } from '../../../services/api'
//styles
import './styles/reports.table.css'
//Utils
import { ModalSizeFitToScreen } from '../../../utils/responsiveFunctions/modalSizeFitToScreen'

export function ReportsTable() {

    const token = localStorage.getItem('@auth:token')
    const { userType } = useAuth()
    const [reports, setReports] = useState([])
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const { setShow, edited, setEdited, setType, type,} = useModal()
    const { screenY } = ModalSizeFitToScreen()
    const accessType = JSON.parse(localStorage.getItem("@auth:user")).type;

    useEffect(() => {

        async function getData() {
            setLoading(true)
            try {
                const getReports = await api.get('/api/v1/reports', {
                    headers: {
                        authorization: 'Bearer ' + token
                    }
                })
                setReports(getReports.data.data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                return alert(`Erro ao realizar busca de relatórios.`)
            }
        }

        if (reports.length === 0 || edited) {

            setEdited(false)
            getData()
            
        }
    }, [edited])

    function showReportDetail(id_report,is_file){
        console.log(id_report,is_file)
        const props = {id_report: id_report, is_file: is_file}
        if(accessType == 1 || accessType == 2){
            setShow(true);
            setType({ name: 'detalheRelatorio', value: { props }});
        }
    }

    function showInsertReport(){
        setShow(true);
        setType({ name: 'insertReport'});
    }

    function filteredReports() {
        if (filter != undefined && filter != '' && filter != null) {
            return reports.filter(report => {
                return report?.source?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1 ||
                    report?.title?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1 ||
                    report?.asset?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1 ||
                    report?.asset_company?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1 ||
                    report?.segment?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1
            })
        } else {
            return reports
        }
    }

    async function handleDelete(id, title) {
        console.log(id, title)
        if (window.confirm('Você realmente deseja remover o relatório ' + title + '?')) {
            setLoading(true)
            try {

                await api.delete(`/api/v1/reports/${id}`, {
                    headers: {
                        authorization: 'Bearer ' + token
                    }
                })
                setEdited(true)
            } catch (error) {
                if (error?.response?.data?.message != undefined) {
                    return alert(error?.response?.data?.message)
                }
                return alert('Erro ao realizar exclusão de relatório, se o problema persistir entre em contato com o suporte.')
            }
        }
    }

    async function getReport(pathLink, title, isFile) {

        if (pathLink == undefined || pathLink == '') {
            return alert('Não foi atrelado nenhum Link ou Arquivo a este FAQ.')
        }
        if (isFile == 0) {
            if (new RegExp("^(https?://)", "i").test(pathLink)) {
                return window.open(pathLink)
            }
            return window.open("http://" + pathLink)
        } else {
            setLoading(true)
            try {
                const fileExtensio = ['.xls', '.xlsx', '.png', '.jpeg', '.gif', '.pjpeg', '.pdf', 'text/plain', '.zip']
                const fileContentTypes = [
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'image/png',
                    'image/jpeg',
                    'image/gif',
                    'image/pjpeg',
                    'application/pdf',
                    'text/plain',
                    'application/zip'
                ]
                const responseFile = await api.get('/api/v1/uploads/reports/' + pathLink, {
                    headers: {
                        authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })

                const url = URL.createObjectURL(new Blob([responseFile.data]))
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${title}${fileExtensio[fileContentTypes.indexOf(responseFile.data.type)]}`)
                document.body.appendChild(link)
                link.click()
                setLoading(false)
                return;
            } catch (error) {
                setLoading(false)
                if (error.response?.data?.message == undefined) {
                    return alert('Erro ao tetnar buscar relatório. Se o problema persistir entre em contato com algum Backoffice ou Administrador do sistema.')
                }
                return alert(error.response?.data?.message)
            }
        }
    }

    const columns = [
        {
            name: "updated_at",
            label: "Atualização",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "source",
            label: "Fonte",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "is_file",
            label: "Tipo",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val) => {
                    return val ? "Arquivo" : "Link";
                },
            },
        },
        {
            name: "title",
            label: "Relatório",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "asset_company",
            label: "Empresa",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "asset",
            label: "Ativo",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            }
        },
        {
            name: "segment",
            label: "Segmento",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
            },
        },
        {
            name: "path_link",
            label: "Baixar MAterial",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val,a) => {
                    return <div className='buttonMuiDatatable' title='Clique para Acesso ao Material' onClick={() => getReport(val, a.rowData[3], a.rowData[2])}><FiDownload /></div>;
                },
            },
            
        },
        {
            name: "id",
            label: "Remover",
            options: {
                filter: false,
                sort: true,
                display: userType == 1 || userType == 2 ? true : false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        padding: '0px 0.6rem 0px 0.6rem',
                    },
                }),
                customBodyRender: (val,a) => {
                    return <div className='buttonMuiDatatable' title='Excluir' onClick={() => handleDelete(val, a.rowData[3])}><FiTrash /></div>;
                },
            },
            
        },
    ];

    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Nome do Cliente",
            "Código Assessor",
            "Nome Assessor",
            "Código Broker",
            "Nome Broker",
            "NET RV",
            "ROA",
            "Receita",
            "Corretagens",
            "Estruturadas",
            "IPO",
            "BTC"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "client_id",
            "client_name",
            "advisor_id",
            "advisor_name",
            "broker_id",
            "broker_name",
            "net_rv",
            "roa",
            "revenue",
            "commission",
            "structured",
            "offers",
            "btc"
        ];
        reports.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net_rv" ||
                    y === "roa" ||
                    y === "revenue" ||
                    y === "commission" ||
                    y === "offers" ||
                    y === "structured" ||
                    y === "btc"
                    ? String(x[y]).replace(".", ",")
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    // async function executedStructuredExport(open, year) {
    //     // setLoading(true);
    //     // try {
    //     //     const myExport = await getClientsROA(tokenKey, true);
    //     //     const responseURL = URL.createObjectURL(new Blob([myExport]));
    //     //     const link = document.createElement("a");
    //     //     link.href = responseURL;
    //     //     link.setAttribute("download", "roaPorCliente.xlsx");
    //     //     document.body.appendChild(link);
    //     //     link.click();
    //     //     setLoading(false);
    //     // } catch (error) {
    //     //     setType({
    //     //         name: "alertMsg",
    //     //         type: "information",
    //     //         value: {
    //     //             message:
    //     //                 error?.response?.data?.message === undefined
    //     //                     ? "Erro ao buscar dados para exportação"
    //     //                     : error?.response?.data?.message,
    //     //         },
    //     //     });
    //     //     setShow(true);
    //     //     setLoading(false);
    //     // }
    // }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard} disabled>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
        
        {userType == 1 || userType == 2 ?
        <>       
        <Tooltip disableFocusListener title="Inserir relatório">
                    <IconButton
                        onClick={() => {
                            showInsertReport();
                        }} >
                        <FiPlus />
                        <span style={{ fontSize: "1.4rem" }}>Inserir relatório</span>
                    </IconButton>
        </Tooltip>
        </>
        : <></>}
        </>

    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: 'disabled',
        print: false,
        responsive: "standard",
        tableBodyHeight: "72vh",
        draggableColumns: {
            enabled: true,
        },
        pagination: true,
        rowsPerPage: 30,
        rowsPerPageOptions: [30, 300, 3000],
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        // sortOrder: {
        //     name: 'revenue',
        //     direction: orderRec
        // },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações até o momento para projeção de receita.",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        // downloadOptions: {
        //     filename: "relatorios.csv",
        //     separator: ";",
        // },
        // onDownload: () => {
        //     executedStructuredExport();
        //     return false;
        // },
        customToolbar: AddButton
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ maxWidth: "95%", marginTop: "2rem" }}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable title={`Relatórios`} data={reports} columns={columns} options={options}></MUIDataTable>
            </ThemeProvider>
        </div>
    );

}

{/* <div className='reportsTableContainer'>
            <div className='reportsTableContent' style={{ maxHeight: screenY }}>
                <table>
                    <thead id='reportsTableTbody'>
                        <tr id='reportsTableBody'>
                            <th colSpan={userType == 1 || userType == 2 ? 9 : 8}>
                                <div className='theadFirst'>
                                    <a>Relatórios</a>

                                    {userType == 1 || userType == 2 ?
                                        (
                                            <label>
                                                <input placeholder='Filtrar' onChange={(e) => setFilter(e.target.value)} type="text" />
                                                <button id='userCadastration' onClick={() => showInsertReport()}><FiPlus />Inserir Relatório</button>
                                            </label>
                                        ) :
                                        (
                                            <label>
                                                <input placeholder='Filtrar' onChange={(e) => setFilter(e.target.value)} type="text" />
                                            </label>
                                        )
                                    }

                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th>Atualização</th>
                            <th>Fonte</th>
                            <th>Tipo</th>
                            <th>Relatório</th>
                            <th>Empresa</th>
                            <th>Ativo</th>
                            <th>Segmento</th>
                            <th><FiDownload /></th>
                            {userType == 1 || userType == 2 ? <th>Remover</th> : ''}
                        </tr>
                    </thead>
                    <tbody id='reportsTableTbody'>
                        {!loading && reports.length == 0 ?
                            <tr>
                                <td colSpan={userType == 1 || userType == 2 ? 9 : 8}> Nenhum relatório cadastrado</td>
                            </tr>
                            : loading ?
                                <tr>
                                    <td id='reportLoadingTd' colSpan='9'><ArrowLoader /></td>
                                </tr>
                                : filteredReports()?.map(report => {
                                    return (
                                        <tr>
                                            <td title={report.updated_at} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.updated_at}
                                            </td>
                                            <td title={report.source} onClick={() => {showReportDetail(report.id,report.is_file) }}>
                                                {report.source}
                                            </td>
                                            <td title={report.is_file} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.is_file ? 'Arquivo' : 'Link'}
                                            </td>
                                            <td title={report.title} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.title}
                                            </td>
                                            <td title={report.asset_company} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.asset_company}
                                            </td>
                                            <td title={report.asset} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.asset}
                                            </td>
                                            <td title={report.segment} onClick={() => { showReportDetail(report.id,report.is_file) }}>
                                                {report.segment}
                                            </td>
                                            <td title='Clique para Acesso ao Material' onClick={() => getReport(report.path_link, report.title, report.is_file)}><FiDownload /></td>
                                            {userType == 1 || userType == 2 ?
                                                <td>
                                                    <FiTrash cursor='pointer' onClick={() => handleDelete(report.id, report.title)} />
                                                </td>
                                                : ''
                                            }
                                        </tr>
                                    )
                                })}
                    </tbody>
                </table>
            </div>
        </div> */}