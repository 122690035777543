/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from 'react';
import { FiPlus, FiSearch, FiArrowDown,FiCopy } from 'react-icons/fi'
import { FormGroup, FormLabel, FormControl, ListItemText, TextField, Checkbox, FormControlLabel, Grid, Select, InputLabel, MenuItem} from '@mui/material';
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//services
import { api } from '../../../../services/api';
import { getAllClients } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import './styles/clients.table.css'
import { MdTexture } from 'react-icons/md';

export function ClientsTable() {

    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { tokenKey } = useAuth();
    const [filtered, setFiltered] = useState('');
    const [selectedFilter, setSelectedFilter] = useState('Código');
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [listFilteredClients, setListFilteredClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [filtersList, setFiltersList] = useState({});
    const [search, setSearch] = useState('');
    const [profilesList, setProfilesList] = useState([]);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isloaded, setIsloaded] = useState(false);
    const token = localStorage.getItem('@auth:token');
    const currentUser = JSON.parse(localStorage.getItem('@auth:user'));
    const { screenX, screenY } = ModalSizeFitToScreen();

    async function searchClients() {
        try {
            const clientsResult = await api.get('/api/v1/clients', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setListClients(clientsResult.data.data.slice(0, rowsPerPage+101));
            setListAllClients(clientsResult.data.data);
            setListFilteredClients(clientsResult.data.data)
            setTotalClients(clientsResult?.data?.data?.length);
            setLoading(false)
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    useEffect(() => {
        async function getProfiles() {
            const resultProfile = await api.get('api/v1/profiles', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setProfilesList(resultProfile.data.data);
        }

        if (type.name === 'showImportClients' && type.value === 'successImport' || type.name === 'successClientCadastration' ||
            type.name === 'successUpdateClient' || type.name === 'successClientEdit' || edited === true) {
            setLoading(true)
            searchClients();
            getProfiles();
            setEdited(false);
        } else if (!isloaded) {
            searchClients();
            getProfiles();
            setIsloaded(true);
        }
    }, [show, edited])

    async function clientsExport() {
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/clients/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_clientes.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function copyToClipboard() {
        const output = [];
        const titles = ['Código do Cliente', 'Nome', 'Perfil', 'Status', 'Código do Assessor','Assessor', 'Filial']
        output.push(titles.join('\t'))
        const keys = ['external_id', 'name','client_profile', 'active', 'advisor_external_id', 'advisor_name', 'branche'];
        listClients.forEach((x) => {
            const tmp = keys.map((y) => {return y==='active'? x[y] === 0 ? 'Inativo' : x[y] === 1 ? 'Ativo' : x[y] === 2 ? 'Evasão' : x[y] === 3 ? 'Prospect' : x[y] === 4 ? 'Fora': 'Desacordo' : x[y]});
            output.push(tmp.join('\t'))
        })
        const table = output.join('\n')
        navigator.clipboard.writeText(table)
        alert('Conteúdo da tabela copiado para a área de transferência')
    }

    async function showClientDetails(client) {
        setShow(true);
        // setType({ name: 'showClientDetails', value: { client: client } });

        try {

            const getClients = await api.get(`/api/v1/clients/${client[0]}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            const getBrokers = await api.get(`/api/v1/clients/${client[0]}/brokers?status=1`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            let brokerToShowList = [];

            getBrokers?.data?.data.map((brokerToShow) => {
                brokerToShowList.push({
                    broker_id: parseInt(brokerToShow?.users_external_id),
                    full_name: brokerToShow?.user_full_name,
                    profile_id: brokerToShow?.profile_id,
                    profile_description: brokerToShow?.profile_description
                })
            })
            setType({ name: 'showClientDetails', value: { client: getClients?.data?.data, broker: brokerToShowList } });

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message } });
        }
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            
            {currentUser.type < 2 ?
                <>
                <Tooltip disableFocusListener title="Novo">
                    <IconButton onClick={() => { setType('registration'); setShow(!show) }}>
                        <FiPlus /><span style={{fontSize: "1.4rem"}}>Novo</span>
                    </IconButton>
                </Tooltip>
                <Tooltip disableFocusListener title="Importar">
                    <IconButton onClick={() => {
                        setType({ name: 'showImportClients' });
                        setShow(!show);
                        if (document.getElementById('checkMenu').checked === true) {
                            document.getElementById('checkMenu').click();
                        };
                    }}>
                        <FiPlus /><span style={{fontSize: "1.4rem"}}>Importar</span>
                    </IconButton>
                </Tooltip>
                </>
                :
                (<></>)
            }
        </>
    );

    const columns = [
        {
            name: "external_id",
            label: "Código do Cliente",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            }
        },
        {
            name: "name",
            label: "Nome",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    
                    
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "client_profile",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    
                    
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "advisor_external_id",
            label: "Código do Assessor",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    
                    
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    
                    
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        },
        {
            name: "branche",
            label: "Filial",
            options: {
                filter: true,
                sort: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => v,
                    update: (filterList, filterPos, index) => {
                    filterList[index].splice(filterPos, 1);
                    return filterList;
                    }
                },
                filterOptions: {
                    logic: (location, filters, row) => {
                    if (filters.length) return !filters.includes(location);
                    return false;
                    },
                    display: (filterList, onChange, index, column) => {
                    const optionValues = listFilteredClients.map((x) => x[column.name]).filter((v, i, a) => i === a.indexOf(v))

                    return (
                        <FormControl>
                        <InputLabel htmlFor='select-multiple-chip' variant='standard'>
                            {column.label}
                        </InputLabel>
                        <Select
                            multiple
                            variant='standard'
                            value={filterList[index]}
                            renderValue={selected => selected.join(', ')}
                            onChange={event => {
                            filterList[index] = event.target.value;
                            onChange(filterList[index], index, column);
                            }}
                        >
                            {optionValues.map(item => (
                            <MenuItem key={item} value={item}>
                                <Checkbox
                                color='primary'
                                checked={filterList[index].indexOf(item) > -1}
                                />
                                <ListItemText primary={item} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    );
                    }
                }
            },
        }
    ];

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        rowsPerPage: rowsPerPage,
        page: page,
        count: listFilteredClients.length,
        rowsPerPageOptions: [30, 50, 100],
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            clientsExport();
            return false;
        },
        customToolbar: AddButton,
        onRowClick: (rowData, rowMeta) => {
            showClientDetails(rowData)
            
        },
        setTableProps: () => {
          return {
              padding: 'none',
              size: 'small'
          };
        },
        onChangePage: (atualPage) => {
            setPage(atualPage)
            if (atualPage > lastPage){
                setListClients(listClients.concat(listFilteredClients.slice(listClients.length,(listClients.length + rowsPerPage))))
                setLastPage(atualPage)
            }
        },
        onChangeRowsPerPage : (rows) => {
            setRowsPerPage(rows)
            setLastPage(0)
            setPage(0)
            setListClients(listFilteredClients.slice(0,rows+1))
        },
        onSearchChange : (searchText) => {
            searchText = searchText === null ? '' : searchText
            setSearch(searchText)
            var filterListTemp = filtersList
            filterListTemp['search'] = searchText
            setFiltersList(filterListTemp)
            var filteredClientsTemp = []
            if (searchText != '' && searchText != undefined) {
                filteredClientsTemp = listAllClients.filter((row) => Object.values(row).join('|').toLowerCase().indexOf(String(searchText)) > -1)
                setListFilteredClients(filteredClientsTemp)
                setLastPage(0)
                setPage(0)
            } else {
                setListFilteredClients(listAllClients)
                setLastPage(0)
                setPage(0)
                filteredClientsTemp = listAllClients
            }
            var aux = filteredClientsTemp.filter((row) => {
                var exists = true;
                for (const property in filtersList) {
                    if (property !== 'search')
                        exists = filtersList[property].length === 0 ? exists :
                        filtersList[property].includes(row[property]) ? exists : false; 
                    }
                    return exists
                })
            setListClients(aux.slice(0,rowsPerPage+101))
            
        },
        onFilterChange : (column, list, e, i) => {
            var filterListTemp = filtersList
            if (search !== '')  filterListTemp['search'] = search
            if (i !== null) {
                if (!filterListTemp.hasOwnProperty(column)) {
                    filterListTemp[column] = [];
                }
                filterListTemp[column] = list[i];

            } else {
                for (const property in filterListTemp) {
                    filterListTemp[property] = []
                }
            }
            var aux = listFilteredClients.filter((row) => {
                var exists = true;
                for (const property in filterListTemp) {
                    exists = filterListTemp[property].length === 0 ? exists :
                    (property === 'search' && filterListTemp['search'] !== '') ? ((Object.values(row).join('|').toLowerCase().indexOf(String(filterListTemp[property])) > -1) ? exists : false ) :
                    filterListTemp[property].includes(row[property]) ? exists : false; 
                }
                return exists
            })
            setFiltersList(filterListTemp)
            setLastPage(0)
            setPage(0)
            setListClients(aux.slice(0, rowsPerPage + 101))
        },
        onColumnSortChange: (column, order) => {
            const orderedList = listFilteredClients.sort(order === 'asc' ? 
            ((a, b) => (a[column] ? (isNaN(parseInt(a[column])) ? a[column].toLowerCase() : parseInt(a[column])) : -2) > (b[column] ? isNaN(parseInt(b[column])) ? b[column].toLowerCase() : parseInt(b[column]) : -1) ? 1 : -1) : 
            ((a, b) => (a[column] ? (isNaN(parseInt(a[column])) ? a[column].toLowerCase() : parseInt(a[column])) : -2) > (b[column] ? isNaN(parseInt(b[column])) ? b[column].toLowerCase() : parseInt(b[column]) : -1) ? -1 : 1))
            setListFilteredClients(orderedList)
            setLastPage(0)
            setPage(0)
            setListClients(orderedList.slice(0,rowsPerPage+101))
        }
    };

    const title = 
        <div>
        <div style={{fontSize: "1.6rem", fontWeight: '400'}}>Lista de Clientes</div>
        <div>Total {totalClients}</div>
        </div>
    
    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        // <div style={{ maxWidth: "95%"}}>
        // </div>
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable title={title} data={listClients} columns={columns} options={options}></MUIDataTable>
        </ThemeProvider>
    );
}
