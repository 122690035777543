import React, { useEffect, useState, useRef, useReducer } from "react"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
/*Contexts*/
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"
/*Utils*/
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"

import "./dashboard.css"

export function DashboardAdvisorStatusFilter(props) {
    console.log("STATUS", props)
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true)
    const [isTrue, setIsTrue] = useState(false)
    const [filtersStatus, setFiltersStatus] = useState()
    const [cont, setCont] = useState(0)

    let handleChange = async (el, i) => {
        var aux = filtersStatus
        aux[i].visible = el.target.checked
        await setFiltersStatus(aux)
        forceUpdate()
    }

    useEffect(() => {
        const aux = [
            { label: "pendente", color: "#FAF5C7", visible: false, obs: false },
            { label: "abordado", color: "#7893DB", visible: false, obs: false },
            { label: "aceito", color: "#F0B357", visible: false, obs: false },
            { label: "boletado", color: "#CBF0BE", visible: false, obs: false },
            { label: "cancelado", color: "#E2BBE3", visible: false, obs: true },
            { label: "recusado", color: "#E87F7F", visible: false, obs: false },
            { label: "não aderente", color: "#000000", visible: false, obs: false },
            { label: "outros", color: "#9013FE", visible: false, obs: true },
        ]
        aux.forEach((e) => {
            if (props.prop.listFilterStatusProducts.current[props.prop.data]?.includes(e?.label?.toUpperCase())) e.visible = true
        }
        )
        setFiltersStatus(aux)

    }, [show])


    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    let selectAll = async (status) => {
        var aux = filtersStatus
        aux.forEach((e) => {
            e.visible = status
        })
        await setFiltersStatus(aux)
        forceUpdate()
    }

    return (
        <div className="containerStatusAbc-Dash">
            <div className="containerStatusConfirm-Dash">
                <div className="containerStatusName"></div>
                <div className="statusConfirm-Dash">
                    <p className="h2status-Dash">FILTRO DE STATUS</p>
                </div>
            </div>
            <div className="containerStatusList">
                <h3 style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10 }}>{props.prop.description}</h3>
                <div className="sliceStatusList"></div>
                <div className="containerCheckbox-Dash">
                    <FormGroup className="statusList">
                        {filtersStatus?.map((e, i) => {
                            // if (props.prop.listFilterStatusProducts?.current[props?.prop?.data]?.includes(e?.label?.toUpperCase()))  e.visible = true
                            return (
                                <>
                                    <FormControlLabel
                                        control={<Checkbox style={{ color: "#BEBEBE", borderRadius: "BorderRadius.all(Radius.circular(90.0))" }} />}
                                        value={e.label}
                                        checked={e.visible}
                                        id={i}
                                        name={e.label}
                                        onChange={(el) => handleChange(el, i)}
                                        label={titleize(e.label) + (e.obs ? "*" : "")}
                                    ></FormControlLabel>
                                </>
                            )
                        })}
                    </FormGroup>
                </div>
                <div className="observationFilterAbc">* Apenas Backoffice Altera</div>
                <div className="containerButtons-Dash">
                    <div className="containerSelectAll-Dash">
                        <div></div>
                        <button className="btn buttomGray360 flex-inline-dash" style={{ width: "200px", marginRight: "0px" }} onClick={() => selectAll(true)}>
                            Marcar Todos
                        </button>
                        <div></div>
                        <button className="btn buttomGray360 flex-inlinda-dash" style={{ width: "200px", marginRight: "0px" }} onClick={() => selectAll(false)}>
                            Desmarcar Todos
                        </button>
                        <div></div>
                    </div>
                    <button
                        className="btn filterDashboardButtonOk"
                        style={{ width: "200px", marginTop: "3rem" }}
                        onClick={() => {
                            props.prop.listFilterStatusProducts.current[props.prop.data] = filtersStatus.filter((x) => x.visible == true).map((x) => x.label.toUpperCase())
                            props.prop.filtersList.current[props.prop.data] = filtersStatus.filter((x) => x.visible == true).map((x) => x.label.toUpperCase())
                            props.prop.listFilteredClients.current = props.prop.listAllClients.filter((row) => {
                                var exists = true
                                for (const property in props.prop.filtersList.current) {
                                    exists =
                                        props.prop.filtersList.current[property].length === 0
                                            ? exists
                                            : property === "search" && props.prop.filtersList.current["search"] !== ""
                                            ? Object.values(row).join("|").toLowerCase().indexOf(String(props.prop.filtersList.current[property])) > -1
                                                ? exists
                                                : false
                                            : props.prop.filtersList.current[property].includes(row[property])
                                            ? exists
                                            : false
                                }
                                return exists
                            })
                            props.prop.listClients.current = props.prop.listFilteredClients.current.slice(0, 501)
                            // props.prop.listFilteredClients.current = props.prop.listFilteredClients.current
                            props.prop.atualizador()
                            props.prop.linesCount.current = props.prop.listFilteredClients.current.length
                            props.prop.positions.current.currentPage = 0
                            props.prop.doSum()
                            setType({ prop: props.prop })
                            setShow(false)
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    )
}
