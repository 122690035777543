import React from 'react';
import Routes from './routes/redirect.routes';
import { AuthProvider } from './contexts/useAuth';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './components/styles/autocompletField'
import './global.css';
// import LogRocket from 'logrocket';


export default function App() {
  // LogRocket.init('bunn5z/manch');

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </AuthProvider >
  );
}
