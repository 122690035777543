/**
 * Desenvolvido por equipe: Tiago Murilo
 * Descrição: Lista clientes de brokers e assessores e reenderiza component table
 **/

//libraries
import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Tooltip, IconButton } from "@material-ui/core";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ThemeProvider,createTheme } from '@mui/material/styles';
//contexts
import { useModal } from '../../../../contexts/useModal'
import { useAuth } from '../../../../contexts/useAuth'
//services
import { api } from '../../../../services/api';
import { myAdvisorsClients } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import Modal from '../../../../components/modal/modal'
import { MyClientsDetail } from './myClientsDetail'
//utils
import { numberBRLFormat } from '../../../../utils/formatNumberAndText/formatNumbertoBRL';
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen'
//styles
import './styles/myAdvisorsClients.table.css'
import { FiArrowDown, FiCopy } from 'react-icons/fi';

import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";

export function MyAdvisorsClientsTable() {

    const { setShow, setType, type } = useModal();
    const { screenX, screenY } = ModalSizeFitToScreen();
    const { tokenKey } = useAuth();
    const { user } = useAuth()
    const [filtered, setFiltered] = useState('');
    const [listClients, setListClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [scrollTable, setScrollTable] = useState(null);
    const [scrollTableLines, setScrollTableLines] = useState(50);
    const [bottomScroll, setBottomScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token');
    const [selectedFilter, setSelectedFilter] = useState('Código');

    useEffect(async () => {
        setLoading(true)

        const clientsGet = await myAdvisorsClients(user.external_id, tokenKey, false)

        if (clientsGet?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: clientsGet?.error
                }
            })
            setShow(true);
        } else {
            // console.log('clientes: ', clientsGet.my_clients_advisor)
            setListClients(clientsGet.my_clients_advisor);
            

        }
        setLoading(false);
    }, [])


    function copyToClipboard() {
        const output = [];
        const titles = [
            "Código Cliente",
            "Cliente",
            "Perfil",
            "Net Total",
            "Net RV",
            "Código Assessor",
            "Assessor"
        ];
        output.push(titles.join("\t"));
        const keys = [
            "external_id",
            "name",
            "client_profile",
            "net_total",
            "net_stock",
            "external_id",
            "advisor_name"
        ];
        listClients.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "net_total" || y === "net_stock" ? String(x[y]).replace('.', ',')
                    : x[y];
            });
            output.push(tmp.join("\t"));
        });
        const table = output.join("\n");
        navigator.clipboard.writeText(table);
        alert("Conteúdo da tabela copiado para a área de transferência");
    }

    async function unsuitedStructuredExport() {
        setLoading(true);
        try {
            const myExport = await myAdvisorsClients(user.external_id, tokenKey, true)
            const responseURL = URL.createObjectURL(new Blob([myExport]));
            const link = document.createElement("a");
            link.href = responseURL;
            link.setAttribute("download", "clientes_fora_da_mesa.xlsx");
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data?.message === undefined
                            ? "Erro ao buscar dados para exportação"
                            : error?.response?.data?.message,
                },
            });
            setShow(true);
            setLoading(false);
        }
    }

    const columns = [
        {
            name: "external_id",
            label: "Código Cliente",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "name",
            label: "Cliente",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "client_profile",
            label: "Perfil",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "net_total",
            label: "Net total",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (val) => {
                    return toBRL(val);
                },
            
            },
        },
        {
            name: "net_stock",
            label: "Net RV",
            options: {
                filter: false,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
                customBodyRender: (val) => {
                    return toBRL(val);
                },
            },
        },
        {
            name: "external_id_a",
            label: "Código Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
        {
            name: "advisor_name",
            label: "Assessor",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                    },
                }),
            },
        },
    ];

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    );

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        tableBodyHeight: "72vh",
        draggableColumns: {
            enabled: true,
        },
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há clientes fora da mesa para esse usuário!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            unsuitedStructuredExport();
            return false;
        },
        customToolbar: AddButton,
    };

    const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
          },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
      },
    })

    return (
        <div style={{ width: "95%", marginTop: "2rem", margin: 'auto' }}>
            <ThemeProvider theme={getMuiTheme()}>  
                <MUIDataTable
                    title={"Clientes fora da mesa"}
                    data={listClients}
                    columns={columns}
                    options={options}
                    ></MUIDataTable>
            </ThemeProvider>
        </div>
        // <></>
    );
}