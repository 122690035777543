import React, { useEffect, useState } from "react"
import "../styles/longShortHistorico.css"

import { useModal } from "../../../../contexts/useModal"
import { useAuth } from "../../../../contexts/useAuth"
import { AiFillCloseCircle } from "react-icons/ai"
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent"

import { getAllClients } from "../../../../services/asyncFunctions"
import { getMyClients } from "../../../../services/asyncFunctions"
import { getMyClientsWithDetatched } from "../../../../services/asyncFunctions"
import { TextField, TextareaAutosize, Checkbox, FormControlLabel } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { styledForOfferings } from "../../../../components/styles/autocompletField"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { AiFillDollarCircle, AiFillMessage, AiOutlineWhatsApp, AiOutlineCheckSquare, AiOutlineWarning } from "react-icons/ai"

export function LongShortHistoricoGeral(props) {
    const { user, tokenKey } = useAuth()
    const [loading, setLoading] = useState(true)
    const { type, show, setShow, setType } = useModal()
    const [response, setResponse] = useState(false)
    const [clientList, setClientsList] = useState([{ external_id: "Digite 3 caracteres", name: "" }])
    const [searchClientValue, setSearchClientValue] = useState("")
    const [handleSearch, setHandleSearch] = useState(false)
    const [selectedClient, setSelectedClient] = useState([])
    const classes = styledForOfferings()
    const [errorFields, setErrorFields] = useState({
        selectedClient: false,
    })
    const [initialDate, setInitialDate] = useState()
    const [finalDate, setFinalDate] = useState()
    const requiredFields = [{ field: "selectedClient", value: selectedClient?.external_id }, { field: "initialDate", value: initialDate }, { field: "finalDate", value: finalDate }]

    async function myClientsGet(search) {
        const clientsResult = await getMyClientsWithDetatched(user.external_id, tokenKey, search)
        if (clientsResult?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: clientsResult?.error,
                },
            })
            setShow(true)
        } else {
            if (clientsResult) {
                const clientsFormatted = []

                clientsResult.forEach((client) => {
                    clientsFormatted.push({
                        external_id: client.client_external_id,
                        name: client.client_name,
                        email: client.email,
                    })
                })
                setClientsList(clientsFormatted)
            } else {
                setClientsList([{ external_id: "", name: "" }])
            }
            setLoading(false)
        }
    }

    async function clienstGet(search) {
        // setLoading(true)
        const clientsResult = await getAllClients(tokenKey, 1, search)
        if (clientsResult?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: clientsResult?.error,
                },
            })
            setShow(true)
        } else {
            if (clientsResult) {
                setClientsList(clientsResult)
                setLoading(false)
            } else {
                setClientsList([{ external_id: "", name: "" }])
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        setResponse(true)
        if(!show)clear()
        
    }, [show])

    async function handleChange(event) {
        if (event.target.value.length > 2) {
            if (clientList[0].external_id === "Carregando" || clientList[0].external_id === "Digite 3 caracteres") {
                setClientsList([{ external_id: "Carregando", name: event.target.value }])
            }
            if (handleSearch == false) {
                setHandleSearch(true)
                setSearchClientValue(event.target.value)
                if (user.type < 3) {
                    clienstGet(event.target.value)
                } else {
                    myClientsGet(event.target.value)
                }
            }
        } else {
            setHandleSearch(false)
            setClientsList([{ external_id: "Digite 3 caracteres", name: event.target.value + "..." }])
        }
    }

    function clear() {
        setSelectedClient([])
        setSearchClientValue("")
        setClientsList({ external_id: "Digite 3 caracteres", name: "" })
        setInitialDate("")
        setFinalDate("")
    }

    function validFields() {
        let errors = false
        requiredFields.map((field) => {
            if (field.value === "" || field.value === undefined || field.value === null) {
                errorFields[field.field] = true
                errors = true
            }
        })
        return errors
    }

    async function handleSubmitExternalId(e) {
        if (validFields()) {
            setTimeout(() => {
                setSelectedClient([])
                return alert("Para obter o histórico, preencha todos os campos.")
            }, 100)
        } else {
            props.prop.searchId(selectedClient, initialDate, finalDate)
            setShow(false)
        }
    }

    return (
        <div className="mainLongShortHistorico">
            {response ? (
                <>
                    <div className="mainLongShortHistorico">
                        <div className="mainLongShortHistoricoTitle">Selecione o cliente, as datas e clique OK para obter o histórico de operações encerradas.</div>
                        <div className="mainLongShortHistoricoBody">
                            <Autocomplete
                                
                                options={clientList}
                                className={classes.root}
                                onChange={(event, newValue) => {
                                    setSelectedClient(newValue)
                                }}
                                getOptionLabel={(option) => option?.external_id + " - " + titleize(option.name)}
                                renderInput={(params) => (
                                    <TextField
                                        defaultValue={{ external_id: "Digite 3 caracteres", name: "" }}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault()
                                        }}
                                        label="Cliente"
                                        className={errorFields["selectedClient"] && selectedClient?.external_id === undefined ? classes.rootWError : classes.root}
                                        {...params}
                                        variant="outlined"
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />
                                )}
                            />
                            <div className="dataHistoricoLS">
                                <TextField
                                    label="Data Início"
                                    value={initialDate}
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setInitialDate(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </div>

                            <div className="dataHistoricoLS">
                                <TextField
                                    label="Data Fim"
                                    value={finalDate}
                                    InputLabelProps={{ shrink: true }}
                                    type="date"
                                    className={classes.styleForClients}
                                    onChange={(e) => {
                                        setFinalDate(e.target.value)
                                    }}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="mainLongShortHistoricoSubmit">
                            <button className="fiisOfferingsReservConfirmation" onClick={() => {handleSubmitExternalId()}}>
                                OK
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="myReservationsHeader">
                        <p>Resposta</p>
                        <button onClick={() => setShow(false)} className="modalCloseButton">
                            x
                        </button>
                    </div>
                    <div className="myReservationEmptyBody">
                        <h4>Nenhuma resposta cadastrada</h4>
                    </div>
                </>
            )}
        </div>
    )
}
