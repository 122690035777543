import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';


export const styleAutoComplete = {
    default: {
        width: '10rem',
        height: '2rem',
        padding: '0',
        color: 'rgb(255,255,255)',
        margin: '0',
        backgroundColor: 'rgb(83, 83, 85)'
    },
    textField: {
        height: '100%',
        padding: '0',
        margin: '0',
        minHeight: '1rem',
        backgroundColor: 'rgb(83, 83, 85)'
    }
}

export const styleClientsInput = makeStyles((theme) => ({

    '@global': {
        '.MuiAutocomplete-option[data-focus="true"]': {
            background: 'rgba(230, 232, 234, 1)'
        }
    },

    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.4rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },

    rootWithError: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ab0000",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#ab0000'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#ab0000"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#ab0000'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.4rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },

    textArea: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black"
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    autocompleteFAQ: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        "& .MuiFormLabel-root": {
            height: '2rem',
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },

        '& .MuiInputBase-root': {
            width: '20rem',
            height: '2.4rem',
            borderColor: "rgb(195, 158, 22)",
        },

        "& .PrivateNotchedOutline-root": {
            height: '2rem',
            borderColor: "rgb(195, 158, 22)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(5px,10px) scale(1)',
            pointerEvents: 'none'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)'
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },

            "& .MuiFormLabel-root": {
                height: '2rem'
            },

            '& .MuiInputBase-root': {
                width: '20rem',
                height: '2.4rem',
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    autocompleteAbc: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "#c39e16",
        },
        "& .MuiFormLabel-root": {
            height: '2rem',
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },

        '& .MuiInputBase-root': {
            width: '16rem',
            height: '2rem',
            borderColor: "rgb(195, 158, 22)",
        },

        "& .PrivateNotchedOutline-root": {
            height: '2rem',
            borderColor: "rgb(195, 158, 22)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(5px,10px) scale(1)',
            pointerEvents: 'none'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)'
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "#c39e16",
            },

            "& .MuiFormLabel-root": {
                height: '2rem'
            },

            '& .MuiInputBase-root': {
                width: '16rem',
                height: '2rem',
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },

    autocompleteAbcDash: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#ABABAB",
            borderRadius: '10px'
            // border: '2px solid #ABABAB'
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#ABABAB',
            borderRadius: '10px'
            // border: '2px solid #ABABAB'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "#ABABAB",
        },
        "& .MuiFormLabel-root": {
            height: '2rem',
            borderColor: "#ABABAB",
            borderRadius: '10px'
            // border: '2px solid #ABABAB'
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ABABAB",
            borderRadius: '10px'
            
            // border: '2px solid #ABABAB'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            minWidth: '12rem',
            maxWidth: '16rem',
            height: '2rem',
            borderColor: "#ABABAB",
            // border: '2px solid #ABABAB'
        },

        "& .PrivateNotchedOutline-root": {
            height: '2rem',
            borderColor: "#ABABAB",
            // border: '2px solid #ABABAB'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(5px,10px) scale(1)',
            pointerEvents: 'none'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)'
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#ABABAB"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#ABABAB'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "#c39e16s",
            },

            "& .MuiFormLabel-root": {
                height: '2rem'
            },

            '& .MuiInputBase-root': {
                width: '10rem',
                height: '2rem',
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    autocompleteEditUser: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '400',
            fontFamily: 'Roboto',
            fontSize: '0.9rem',
            color: '#3C3C3C',
            height: '2.8rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child ': {
            fontWeight: '300'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            height: '2.8rem',
        },
        '& .MuiGrid-root MuiGrid-item': {
            fontSize: '1.2rem',
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },

            "& .MuiFormLabel-root": {
                height: '2rem'
            },

            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.4rem',
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,10px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    textFieldCalendar: {

            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgb(195, 158, 22)",
            },
            "& .MuiFormLabel-root": {
                fontWeight: '500',
                fontFamily: 'Roboto',
                fontSize: '1rem'
            },
    
            '& .MuiInputBase-root': {
                width: '100%',
                height: '2rem',
                background: 'white'
    
            },
    
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },
    
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },
    
            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            width: '100%',
            justifyContent: 'center',
            padding: '0',
    
            ['@media (max-width:1368px)']: {
    
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#C39E16"
                },
    
                option: {
                    '&:hover': {
                        backgroundColor: "cyan !important"
                    }
                },
    
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#c39e16'
                },
    
                "&:hover .MuiInputLabel-outlined": {
                    color: "black",
                },
                "& .MuiFormLabel-root": {
                    height: '2rem'
                },
    
                '& .MuiInputBase-root': {
                    width: '100%',
                    height: '2rem',
                    background: 'white'
    
                },
    
                "& .PrivateNotchedOutline-root": {
                    height: '2rem'
                },
    
                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    transform: 'translate(14px, -6px) scale(0.75)'
                },
    
                "& .MuiInputLabel-outlined": {
                    zIndex: '1',
                    transform: 'translate(5px,12px) scale(1)',
                    pointerEvents: 'none'
                },
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                    transform: 'translate(0px, -10px)'
                },
                '& .MuiInputLabel-filled': {
                    transform: 'translate(10px, 10px)'
                },
                '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                    transform: 'translate(10px, -15px)',
                    fontSize: '0.6rem'
                },
                '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                    transform: 'translate(0px, -20px)'
                }
    
            },
        },

    styleForClients: {

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(195, 158, 22)",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '500',
            fontFamily: 'Roboto',
            fontSize: '1rem'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            height: '3.4rem',
            background: 'white'

        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            option: {
                '&:hover': {
                    backgroundColor: "cyan !important"
                }
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2.4rem'
            },

            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.58rem',
                background: 'white'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,12px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    styleForClientsWithError: {

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#eb6157",
            border: '2px solid #eb6157'
        },
        "& .MuiFormLabel-root": {
            fontWeight: '500',
            fontFamily: 'Roboto',
            fontSize: '1rem'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            height: '3.4rem',
            background: 'white'

        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"
            },

            option: {
                '&:hover': {
                    backgroundColor: "cyan !important"
                }
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2.4rem'
            },

            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.58rem',
                background: 'white'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,12px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },


    switch: {
        '& .MuiSwitch-root': {
            height: '39px',
            width: '70px'
        },

        '& .MuiSwitch-thumb': {
            background: 'rgb(195, 157, 22)'
        },

        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            background: 'rgb(195, 157, 22)'
        },
        '& .MuiSwitch-colorSecondary .Mui-checked': {
            background: 'rgb(195, 157, 22)'
        },
        "& .MuiSwitch-switchBase.Mui-checked": {

        }
    },

    textField: {

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"

        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "white",
        },
        "& .MuiFormLabel-root": {
            height: '2.8rem'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            height: '2.8rem',
            background: '#414141'

        },

        "& .MuiInputBase-input": {
            color: 'white',
            fontSize: '1rem'
        },

        "& .PrivateNotchedOutline-root": {
            height: '2rem'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -7px) scale(0.75)',
            color: '#C39E16',
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(10px,10px) scale(1)',
            pointerEvents: 'none',
            fontSize: '1rem',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)'
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },
        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"

            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "white",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },

            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.4rem',
                background: '#414141'

            },

            "& .MuiInputBase-input": {
                color: 'white',
                fontSize: '1rem'
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -7px) scale(0.75)',
                color: '#C39E16',
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(10px,10px) scale(1)',
                pointerEvents: 'none',
                fontSize: '1rem',
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    radioGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    radio: {
        width: '10px',
    },

    rootForProfile: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            color: '#FFF',
            border: '1px solid rgb(77, 77, 77)'
        },


        "& .MuiInputBase-root": {
            width: '100%',
            paddingLeft: '1rem',
            background: 'rgb(41, 41, 41);'
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat'
        },
        "& .MuiInputBase-input .MuiOutlinedInput-input": {
            color: 'rgb(195, 158, 22)',
            width: '100%'
        },

        width: '100%',
        padding: '0',
    },
    inpuTextProfile: {
        color: '#FFF',
        fontSize: '0.9rem'
    },
    '@global': {
        '.MuiAutocomplete-option': {
            background: '#FFF'
        }
    }
}))

export const styledForBooks = makeStyles((booksTheme) => ({
    textFieldForAutoCompletBuySellBox: {

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"

        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "white",
        },
        "& .MuiFormLabel-root": {
            height: '2rem'
        },

        '& .MuiInputBase-root': {
            width: '100%',
            height: '2.2rem',
            background: '#535355'
        },

        "& .MuiInputBase-input": {
            color: '#FFF',
            fontWeight: '300',
            fontSize: '1rem'
        },

        "& .PrivateNotchedOutline-root": {
            height: '2rem'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -7px) scale(0.75)',
            color: '#C39E16',
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(10px,10px) scale(1)',
            pointerEvents: 'none',
            fontSize: '1rem',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)'
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },
        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {

            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#C39E16"

            },

            '& .MuiAutocomplete-root': {
                width: '15rem',
            },

            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#c39e16'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "white",
            },
            "& .MuiFormLabel-root": {
                height: '2rem',
            },
            "& .MuiAutocomplete-inputRoot": {
                background: '#FFF'
            },

            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.2rem',
                background: '#535355'
            },

            "& .MuiInputBase-input": {
                color: '#FFF',
                fontWeight: '300',
                fontSize: '1rem'
            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -7px) scale(0.75)',
                color: '#C39E16',
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(10px,10px) scale(1)',
                pointerEvents: 'none',
                fontSize: '1rem',
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            }

        },
    },
    textArea: {
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
            height: '2.8rem',
            background: '#F9F9F9',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(37, 64, 109, 1)"

        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(37, 64, 109, 1)'
        },
        "&:hover .MuiInputLabel-outlined": {
            fontWeight: "600",
        },
        "& .MuiInputBase-input": {
            fontWeight: '300',
            fontSize: '1rem'
        },
    }
}))

export const styledForSelectUsers = makeStyles((theme) => ({
    root: {
        "& .MuiAutocomplete-root": {
            width: '4rem'
        },
        width: '100%',
        padding: '0',
    },
    textField: {
        borderRadius: theme.shape.borderRadius,
        border: '1px solid rgb(195, 158, 22)',
    },
    radioGroup: {
        width: '100%',
        background: 'rgb(195, 158, 22)',
        display: 'flex',
        flexDirection: 'row'
    },
}))

export const theme = createMuiTheme({
    palette: {
        primary: { main: '#1976d2' },
    },
}, ptBR);

export const styledForPushOrders = makeStyles((theme) => ({

    '@global': {
        '.MuiAutocomplete-option[data-focus="true"]': {
            background: 'rgba(230, 232, 234, 1)'
        }
    },
    currency: {

        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none',
            zIndex: '1'
        },

        '& .MuiInput:hover': {
            borderBottom: 'none',
        },

        '& .MuiAutocomplete-option[data-focus="true"]': {
            background: 'blue'
        },

        "& .MuiInput-root": {
            zIndex: '1',
            height: '1.8rem'
        },

        "& .MuiOutlinedInput-input": {
            padding: '0 !important'
        },
        "& .MuiAutocomplete-popper": {
            background: 'red'
        },
    },
    textField: {
        borderRadius: theme.shape.borderRadius,

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },
        '&  .MuiInputLabel-outlined': {
            color: 'rgb(209, 209, 209)'
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(8px,11px) scale(1)',
            pointerEvents: 'none'
        },

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            width: '12rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            fontSize: '1rem',
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            color: '#FFF',
            fontSize: '0.9rem',
            border: '1px solid rgba(37, 64, 109, 1)'

        },
        '& .MuiAutocomplete-root': {
            color: '#FFF'
        },

        "& .MuiFormLabel-root": {
            height: '2rem'
        },


        "& .PrivateNotchedOutline-root": {
            height: '2rem'
        },

        "& .MuiInputBase-root": {
            color: '#FFF',
            fontFamily: 'Roboto',
            fontSize: '1.2rem',
            width: '20rem',
            height: '2.4rem'
        },
        "& .MuiIconButton-root": {
            color: '#FFF'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiInput-underline:hover:not(.MuiDisable):before': {
            borderBottom: '1px solid #FFF'
        }

    }
}))


//MuiInputBase-root MuiOutlinedInput-root MuiAutocomplete-inputRoot MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd
export const styleForDate = makeStyles((theme) => ({
    textField: {
        borderRadius: theme.shape.borderRadius,

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#C39E16"
        },
        '&  .MuiInputLabel-outlined': {
            color: '#C39E16'
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#c39e16'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(8px,9px) scale(1)',
            pointerEvents: 'none'
        },

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            width: '12rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
            transform: 'translate(0px, -10px)',
            fontSize: '1rem',
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            color: '#C39E16',
            fontSize: '0.9rem',
            border: '1px solid #C39E16'

        },
        '& .MuiAutocomplete-root': {
            color: '#C39E16'
        },

        "& .MuiFormLabel-root": {
            height: '2rem'
        },


        "& .PrivateNotchedOutline-root": {
            height: '2rem'
        },

        "& .MuiInputBase-root": {
            color: '#C39E16',
            fontFamily: 'Roboto',
            fontSize: '1.2rem',
            width: '20rem',
            height: '2rem'
        },
        "& .MuiIconButton-root": {
            color: '#FFF'
        },
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiInput-underline:hover:not(.MuiDisable):before': {
            borderBottom: '1px solid #FFF'
        }

    }
}))

export const styledForOfferings = makeStyles((booksTheme) => ({

    root: {

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child' : {
            transform: 'translate(10px, -10px)'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(10px,12px) scale(1)',
            pointerEvents: 'none'
        },
        
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CDCACA",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },

        '& .MuiInputBase-input': {
            color: 'gray',
            width: '100%'
        },

        '&:hover .MuiInputBase-input': {
            color: 'black'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#25406D"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#25406D'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },
        '& .MuiInputBase-root': {
           
            height: '2.4rem'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#25406D"
            },

            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#25406D'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.8rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,15px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -0px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -12px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            },
        },
        
    },

    rootWError: {

        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child' : {
            transform: 'translate(10px, -10px)'
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        },

        "& .MuiInputLabel-outlined": {
            zIndex: '1',
            transform: 'translate(10px,12px) scale(1)',
            pointerEvents: 'none'
        },
        
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ab0000",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },

        '& .MuiInputBase-input': {
            color: 'gray',
            width: '100%'
        },

        '&:hover .MuiInputBase-input': {
            color: 'black'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#de0000"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#de0000'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "#de0000",
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(10px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(0px, -20px)'
        },
        '& .MuiInputBase-root': {
           
            height: '2.4rem'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#de0000"
            },

            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#de0000'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "#de0000",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.8rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,15px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -0px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(10px, 10px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -12px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            },
        },
        
    },
    textArea: {
        width: '100%',
        '& .MuiInputBase-root': {
            width: '100%',
            height: '2.8rem',
            background: '#F9F9F9',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "rgba(37, 64, 109, 1)"

        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: 'rgba(37, 64, 109, 1)'
        },
        "&:hover .MuiInputLabel-outlined": {
            fontWeight: "600",
        },
        "& .MuiInputBase-input": {
            fontWeight: '300',
            fontSize: '1rem'
        },
    },

    standardUI: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CDCACA",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },

        '& .MuiInputBase-input': {
            color: 'gray',
            transform:'translate(10px, -10px)'
        },

        '&:hover .MuiInputBase-input': {
            color: 'black'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#25406D"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#25406D'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(-5px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(10px, -20px)'
        },
        '& .MuiInputBase-root': {
            width: '20rem',
            height: '2.4rem'

        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#25406D"
            },

            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#25406D'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.8rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,0px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(10px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(-10px, 15px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(-10px, -10px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            },
        },
        
    },

    boxUI: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CDCACA",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },


        '& .MuiInputBase-root': {
            width: '100%',
            height: '3.4rem'

        },

        '&:hover .MuiInputBase-input': {
            color: 'black'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#25406D"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#25406D'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(-5px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(10px, -20px)'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#25406D"
            },

            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#25406D'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.2rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,0px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(-10px, 15px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(-10px, -10px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            },
        },
        
    },

    boxUIWError: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ab0000",
        },
        "& .MuiFormLabel-root": {
            fontWeight: '600',
            fontFamily: 'Montserrat',
            fontSize: '0.9rem'
        },


        '& .MuiInputBase-root': {
            width: '100%',
            height: '3.4rem'

        },

        '&:hover .MuiInputBase-input': {
            color: 'black'
        },

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#25406D"
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: '#25406D'
        },

        "&:hover .MuiInputLabel-outlined": {
            color: "black",
        },
        '& .MuiInputLabel-filled': {
            transform: 'translate(-5px, 10px)'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(10px, -15px)',
            fontSize: '0.6rem'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
            transform: 'translate(10px, -20px)'
        },

        width: '100%',
        justifyContent: 'center',
        padding: '0',

        ['@media (max-width:1368px)']: {
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: "#25406D"
            },

            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(10px, -15px)',
                fontSize: '0.6rem'
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: '#25406D'
            },

            "&:hover .MuiInputLabel-outlined": {
                color: "black",
            },
            "& .MuiFormLabel-root": {
                height: '2rem'
            },


            '& .MuiInputBase-root': {
                width: '100%',
                height: '2.2rem'

            },

            "& .PrivateNotchedOutline-root": {
                height: '2rem'
            },

            "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                transform: 'translate(14px, -6px) scale(0.75)'
            },

            "& .MuiInputLabel-outlined": {
                zIndex: '1',
                transform: 'translate(5px,0px) scale(1)',
                pointerEvents: 'none'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                transform: 'translate(0px, -10px)'
            },
            '& .MuiInputLabel-filled': {
                transform: 'translate(-10px, 15px)'
            },
            '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                transform: 'translate(-10px, -10px)',
                fontSize: '0.6rem'
            },
            '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
                transform: 'translate(0px, -20px)'
            },
        },
        
    },

}))

export const styleForSelecion = makeStyles((theme) => ({
    select: {
       '& .MuiInputBase-root': {
           color: '#FFF'
       }
    }
}))
