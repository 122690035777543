/*Componente Tabela de usuários inativos
/*Descrição: Exibe a listagem de usuários inativados do sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

import React, { useEffect, useState } from 'react';
import { FiUserCheck, FiArrowDown, FiCopy } from 'react-icons/fi'
//services
import { api } from '../../../../services/api';
import { getUsers } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
//Context
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
//styles
import './styles/users.inactive.table.css'

export function UsersInactiveTable() {

    const [usersList, setUsersList] = useState([]);
    const [edited, setEdited] = useState(false);
    const [filtered, setFiltered] = useState('');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('@auth:token');
    const { tokenKey } = useAuth();
    const { setType, setShow } = useModal();

    useEffect(async () => {

        const usersGetInactvie = await getUsers(0, tokenKey)
        if (usersGetInactvie?.isError) {
            setType({
                name: 'alertMsg',
                type: 'information',
                value: {
                    message: usersGetInactvie?.error
                }
            })
            setShow(true);
        } else {
            setUsersList(usersGetInactvie);
        }
        setLoading(false);

    }, [edited])

    async function handleChange(user) {

        setLoading(true);
        try {
            await api.put(`/api/v1/users/${user.external_id}/status`,
                {
                    "active": user.active === false ? 1 : 0
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            setEdited(true)
        } catch (err) {
            alert(err.response.data.message)
            setLoading(false);
        }

    }

    function listFilter() {

        if (filtered == null || filtered == null || filtered == undefined) {
            return usersList
        }
        return usersList.filter((user) => {

            if (user.full_name.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user.user_login.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user.email.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user.type_access == 1 && 'Administrador'.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user.type_access == 2 && 'Broker'.toLowerCase().indexOf(filtered.toLowerCase()) > -1 ||
                user.type_access == 3 && 'Assessor'.toLowerCase().indexOf(filtered.toLowerCase()) > -1
            ) {
                return user
            }
        })
    }

    async function getExportUsers() {

        try {
            setLoading(true);
            const exportUsers = await api.get(`api/v1/users/export`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportUsers.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'lista_de_usuarios.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);

        } catch (error) {
            
            setType({name: 'alertMsg', type: 'information', value:{message: error?.response?.data?.message === undefined ? 'Erro ao realizar consulta para exportação' : error?.response?.data?.message}})
            setShow(true);
            setLoading(false);
        }
    }

    return (
        <div className='usersInactiveTableContainer'>
            <table>
                <thead >
                    <tr>
                        <th colSpan='7'>
                            <div className='usersInactiveTheadFirst'>
                                <a>Lista de Usuários</a>
                                <div className='substitutoLabelDisplay'>
                                <button
                                    className='buttonTableThead'
                                    onClick={() => {
                                        const output = [];
                                        const titles = ['Código', 'Nome', 'E-mail', 'Filial', 'Tipo de Assesso', 'Perfil', 'Nº Clientes', 'Ativo']
                                        output.push(titles.join('\t'))
                                        const keys = ['external_id', 'full_name','email', 'branche', 'type_description', 'profile-description', 'total_clients', 'active'];
                                        usersList.forEach((x) => {
                                            const tmp = keys.map((y) => {return y==='branche'? x[y].city + ' - ' + x[y].city_state :  y==='active'?  x[y] === true ? 'Ativo' : 'Inativo' : x[y]});
                                            output.push(tmp.join('\t'))
                                        })
                                        const table = output.join('\n')
                                        navigator.clipboard.writeText(table)
                                        alert('Conteúdo da tabela copiado para a área de transferência')
                                    }}
                                ><FiCopy />Copiar</button>
                                    <button
                                        onClick={() => getExportUsers()}><FiArrowDown />Exportar</button>

                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Código</th>
                        <th>Usuario</th>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Tipo de Acesso</th>
                        <th>Reativar</th>
                    </tr>
                </thead>
                <tbody id='usersInactiveTableTbody'>
                    {loading ? (<th colspan='6' className='usersInactiveTrLoader'><ArrowLoader /></th>) :
                        listFilter().length > 0 ? (

                            listFilter()?.map((user, index) => {
                                return (
                                    <tr>
                                        <td id='1'><a>{user?.external_id}</a></td>
                                        <td id='2'><a>{user?.user_login}</a></td>
                                        <td id='3'>
                                            <div className='usersInactiveEditDiv'>
                                                <a>{titleize(user?.full_name)}</a>
                                            </div>
                                        </td>
                                        <td id='4'>
                                            <div className='usersInactiveEditDiv'>
                                                <a>{titleize(user?.email)}</a>
                                            </div>
                                        </td>
                                        <td id='5'>
                                            <div className='usersInactiveEditDiv'>
                                                <a>{user.type_access == 1 ? 'Administrador' : user.type_access == 2 ? 'Broker' : 'Assessor'}</a>
                                            </div>
                                        </td>
                                        <td id='6'>
                                            <FiUserCheck
                                                onClick={() => handleChange(user)}
                                                cursor='pointer'
                                                size='20'
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <th colspan='6' className='usersInactiveEmptyList'><a>-- Lista vazia --</a></th>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}