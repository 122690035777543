import { useModal } from "../../../contexts/useModal";
import { api } from "../../../services/api";
import { useAuth } from "../../../contexts/useAuth";
import { Switch, Grid } from "@material-ui/core";
import Modal from '../../../components/modal/modal';
import { MyFormsStructureList } from "./structures/myFormsStructureList";
import { MyFormsStructure } from "./structures/myFormsStructure";
import { ConfirmationBox } from "../../../components/confirmationBox/confirmationBox";
import { FormToReply } from "./structures/formToReply";
import "./styles/global.css";
import { useEffect, useState } from "react";


export function MyForms(){
  const {setType, setShow, type} = useModal()
  const [loading, setLoading] = useState(false)
  const { tokenKey, userType, user } = useAuth()
  const [view, setView] = useState(true)

  
  const [jsonMyForms, setJsonMyForms] = useState()

  async function getForms() {
    setLoading(true)
    try {
      const formsTemp = await api.get(`/api/v1/forms/myForms`, {
          headers: {
              authorization: `Bearer ${tokenKey}`
          }
      })
      setJsonMyForms(formsTemp?.data?.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setType({ name: 'alertMsg', type: 'information', value: { message: 'Erro ao buscar os portfólios' } })
      setShow(true)
    }
  }

  useEffect(() => {
        setLoading(true)
        getForms()
}, [])

  const handleChange = (check) => {
    if (check) {
      setView(false)
    } else {
      setView(true)
    }
  }

  useEffect(() => {
    setView(true)
  }, [])

  return (
    <>
    <div className="containerCreateForm">
    <div className="containerHeaderSelectPortfolio">
      <div className="tituloSelectPortfolio">Portfólios em andamento ou já respondidos
      </div>
      <span>
      <Grid component="label" container alignItems="center" spacing={1} style={{placeContent:'center', margin:'-8px', paddingBottom:'2rem'}}>
        <Grid item>
          Cards
        </Grid>
        <Grid item>
        <Switch label='test' color="" onChange={(e) => handleChange(e.target.checked)} />
        </Grid>
        <Grid item>
          Lista
        </Grid>
      </Grid>
      </span>
      {
        view === true ? <MyFormsStructure /> : <MyFormsStructureList />
      }
      </div>
      <Modal>{type.name === "alertMsg" ? (<ConfirmationBox msgData={type} />) : <></>}</Modal>
    </div>
    </>
  )
}