import React, { useEffect, useState, useReducer, useRef } from "react"
/*Contexts*/
import { useAuth } from "../../../contexts/useAuth"
import { useModal } from "../../../contexts/useModal"

/* Table Resources */
import { Table } from "@mui/material"
import { Divider } from "@mui/material"
import { TableHead } from "@mui/material"
import { TableContainer } from "@mui/material"
import { TableRow } from "@mui/material"
import { TableCell } from "@mui/material"
import { TableBody } from "@mui/material"
import { Grid } from "@mui/material"
import { withStyles } from "@material-ui/core"
import { BsPencilSquare } from "react-icons/bs"

import { AiOutlineMessage } from "react-icons/ai"

/*Services*/
import { api } from "../../../services/api"
import { getCubastar, getCubastarCarteiras, getCubastarFundos, getCubastarTacticalTable, getCubastarRentabilityCurve, getDashAlocacaoCampains, getDatabaseReferenceTime } from "../../../services/asyncFunctions"
/*Components*/
import { ArrowLoader } from "../../../components/loaders/arrowLoader"
import { FiChevronsDown, FiChevronsUp } from "react-icons/fi"
import { FiPlusCircle } from "react-icons/fi"
import { MdContentCopy } from "react-icons/md"
import { FaCheck } from "react-icons/fa"
import { BsArrowRightShort } from "react-icons/bs"
/*Utils*/

import { GraphCubastarAlocation } from "./cubastarAlocation"
import { GraphCubastarFundsCompare } from "./cubastarFundsCompare"
import { CubastarFundsTable } from "./tables/cubastar.funds.table"
import { CubastarExamplesTable } from "./tables/cubastar.examples.table"
import { CubastarRentabilityTable } from "./tables/cubastar.rentability.table"
import { GraphCubastarRentabilityCurve } from "./cubastar.rentability.curve"
import { AiOutlineArrowRight, AiOutlineUp, AiOutlineDown } from "react-icons/ai"
import { FiSettings } from "react-icons/fi"
import { AiFillCloseCircle } from "react-icons/ai"

import "./styles/cubastarMain.css"
import { datetimeToDatePtBR } from "../../../utils/formatDate/dateTimeToPTBR"
import transitions from "@material-ui/core/styles/transitions"
import { keys } from "@material-ui/core/styles/createBreakpoints"
import MuiTableCell from "@material-ui/core/TableCell"
import { titleize } from "../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { green } from "@mui/material/colors"

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: "#FFFEF3",
            borderTop: "none",
        },
        
    },
}))(TableRow)
/* const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);
 */
export function CubastarMain(props) {
    const token = localStorage.getItem("@auth:token")
    const { tokenKey, user } = useAuth()
    const { show, setShow, type, setType, edited, setEdited } = useModal()
    const [loading, setLoading] = useState(true)
    const [infosCubastar, setInfosCubastar] = useState([])
    const [dataCubastarTactical, setDataTableCubastarTactical] = useState([])
    const [dataTableCubastar, setDataTableCubastar] = useState({})
    const [dataRentabilityCurve, setDataRentabilityCurve] = useState([])
    const [campains, setCampains] = useState([])
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState('')

    const [selectedCurve, setSelectedCurve] = useState('1')
    const [selectedPortfolio, setSelectedPortfolio] = useState('Superar CDI Mensalmente (retenção)')
    const [cenarioIsOpen, setCenarioIsOpen] = useState(false)
    const [taticasIsOpen, setTaticasIsOpen] = useState(false)
    const [selectedItemMenuLeft, setSelectedItemMenuLeft] = useState()
    const [selectedItemMenuRight, setSelectedItemMenuRight] = useState({})
    const [selectedItemMenuHeaderLeft, setSelectedItemMenuHeaderLeft] = useState("carteiras")
    const [selectedItemMenuHeaderRight, setSelectedItemMenuHeaderRight] = useState("Nacionais")
    const [cenarios, setCenarios] = useState([])
    const [copyingLink, setCopyingLink] = useState([])
    const [copyingLinkAll, setCopyingLinkAll] = useState(false)
    const [currentSelectedFundo, setCurrentSelectedFundo] = useState(null)
    const [isEditing, setIsEditing] = useState(false);

    const forceUpdate = useReducer((bool) => !bool)[1]
    
    const handleEditClick = () => {
        setIsEditing(true);
      };
     
      const handleSaveClick = async () => {
        // Logica
        try {
            // console.log('selectedItemMenuLeft', dataTableCubastar[selectedItemMenuHeaderLeft].find(x => x.description === currentSelectedFundo)?.id)
            // console.log('selectedItemMenuRight', selectedItemMenuRight)
            // console.log('selectedItemMenuLeft', selectedItemMenuLeft)
            // console.log('currentSelectedFundo', currentSelectedFundo)
            const idMacro = dataTableCubastar[selectedItemMenuHeaderLeft].find(x => x.description === currentSelectedFundo)?.id
            const data = await api.put(`api/v1/alocacao/cubastar/carteiras/disclaimer/${idMacro}`, 
            { disclaimer: selectedItemMenuLeft?.disclaimerAux, portfolio: selectedItemMenuLeft?.description },

            { headers: { Authorization: `Bearer ${tokenKey}` } }
            )
            setSelectedItemMenuLeft({...selectedItemMenuLeft, disclaimer: selectedItemMenuLeft.disclaimerAux})
            //salvar no datatableCubastar
            dataTableCubastar[selectedItemMenuHeaderLeft].find(x => x.description === currentSelectedFundo).secoes.find(x => x.description === selectedItemMenuLeft.description).disclaimer = selectedItemMenuLeft.disclaimerAux
            
            ;
        } catch (error) {
            console.error('Error updating data:', error);
        }
        setIsEditing(false);
      };
     
      const handleCancelClick = () => {
        // Logica para voltar ao texto inicial
        setSelectedItemMenuRight({...selectedItemMenuRight, disclaimerAux: selectedItemMenuRight.disclaimer})
        setIsEditing(false);
      };

    function disableCopying() {
        var aux = copyingLink.map((l) => false)
        setCopyingLink(aux)

        setCopyingLinkAll(false)
    }

    async function alocacaoCampainsGet() {
        try {
            const data = await getDashAlocacaoCampains(tokenKey)
            await setCampains(data)
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de informações de alocação tática Cubastar.`)
        }
    }

    async function cubastarGet() {
        try {
            const data = await getCubastar(tokenKey)
            setInfosCubastar(data.data)

            //busca de apenas um avatar
            // const avatar = await api.get(`api/v1/alocacao/cubastar/visions/${String(data.data.visions[0].id)}`, { headers: { Authorization: `Bearer ${tokenKey}` } })
            // data.data.visions[0].avatar = avatar.data

            //busca os avatares de cada um dos cenarios
            const avatares = await Promise.all(
                data.data.visions.map(async (cenario) => {
                    const avatar = await api.get(`api/v1/alocacao/cubastar/visions/${String(cenario.id)}`, { headers: { Authorization: `Bearer ${tokenKey}` }, responseType: "blob" })
                    return URL.createObjectURL(avatar.data)
                })
            )

            var aux = []

            //adiciona os avatares aos cenarios
            data.data.visions.forEach((cenario, index) => {
                cenario.avatar = avatares[index]
                aux.push(false)
            })
            setCopyingLink(aux)

            setCenarios(data.data.visions)
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de informações Cubastar.`)
        }
    }

    async function cubastarTacticalGet() {
        try {
            const data = await getCubastarTacticalTable(tokenKey)
            setDataTableCubastarTactical(data.data)
            forceUpdate()
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de informações de alocação tática Cubastar.`)
        }
    }

    async function carteirasCubastarGet() {
        try {
            const data = await getCubastarCarteiras(tokenKey)
            //fazer copia disclaimer
            data.data.forEach((carteira, index) => {
                carteira.secoes.forEach((secao, index) => {
                    secao.disclaimerAux = secao.disclaimer
                })
            })
            dataTableCubastar.carteiras = data.data
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de informações Cubastar.`)
        }
    }

    async function fundosCubastarGet() {
        try {
            const data = await getCubastarFundos(tokenKey)
            dataTableCubastar.fundos = data.data
        } catch (error) {
            setLoading(false)
            return alert(`Erro ao realizar busca de informações Cubastar.`)
        }
    }

    async function cubastarRentabilityCurve(id, portfolio) {
        if (id === undefined || id === null) 
            return
        try {
            const data = await getCubastarRentabilityCurve(tokenKey, id, portfolio)
            setDataRentabilityCurve(data.data, data.data['portfolio'] = portfolio)
        } catch (error) {
            return alert(`Erro ao realizar busca de informações de curva de rentabilidade Cubastar.`)
        }
    }

        async function getDatabaseUpdateTimeValues() {
        const bases = 'cubastar';
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases);
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].file_reference_time;
                setDatabaseUpdateTime(dateFormat);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
            }
        }
    }

    async function getData() {
        setLoading(true)
        await cubastarGet()
        await cubastarTacticalGet()
        await carteirasCubastarGet()
        await fundosCubastarGet()
        await alocacaoCampainsGet()
        await getDatabaseUpdateTimeValues()
        // setSelectedItemMenuHeaderLeft("carteiras")
        if (dataTableCubastar?.carteiras) {

            setSelectedItemMenuHeaderRight(dataTableCubastar?.carteiras[0]?.secoes[0])

            if (dataTableCubastar?.carteiras[0])
                await setSelectedCurve(dataTableCubastar?.carteiras[0]?.secoes[0]?.id)
            // setSelectedItemMenuLeft(dataTableCubastar?.carteiras[0]?)
            setSelectedItemMenuLeft(dataTableCubastar?.carteiras[0]?.secoes[0])
            setCurrentSelectedFundo(dataTableCubastar?.carteiras[0]?.description)
            await cubastarRentabilityCurve(selectedCurve, selectedPortfolio)

        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    // useEffect(() => {
    //     cubastarGet()
    //     getDatabaseUpdateTimeValues()
    // }, [edited])

    // forceUpdate() no GraphCubastarFundsCompare
    useEffect(() => {}, [dataTableCubastar])

    const contentCenarioRef = useRef(null)
    const [contentCenarioHeight, setContentCenarioHeight] = useState(0)

    const contentTaticasRef = useRef(null)
    const [contentTaticasHeight, setContentTaticasHeight] = useState(0)

    // Controle de informações por tabela Alocação Tática
    const rowsFromJson1 = []
    const rowsFromJson2 = []
    const [primeiroInicio, setPrimeiroInicio] = useState(true)

    function createData(id, classes, veiculos, investidor, recomendado, maximo, speech, vendor_speech) {
        return { id, classes, veiculos, investidor, recomendado, maximo, speech, vendor_speech }
    }

    for (let index = 0; index < dataCubastarTactical.length; index++) {
        let row = createData(
            dataCubastarTactical[index].id,
            dataCubastarTactical[index].class,
            dataCubastarTactical[index].outlet,
            dataCubastarTactical[index].investor,
            dataCubastarTactical[index].recommended,
            dataCubastarTactical[index].maximum,
            dataCubastarTactical[index].speech,
            dataCubastarTactical[index].vendor_speech
        )
        dataCubastarTactical[index].category === 1 ? rowsFromJson1.push(row) : rowsFromJson2.push(row)
    }
    // Fim controle de informações por tabela Alocação Tática

    useEffect(() => {
        if (cenarioIsOpen && contentCenarioRef.current) {
            setContentCenarioHeight(contentCenarioRef.current.offsetHeight)
        }
    }, [cenarioIsOpen])

    useEffect(() => {
        if (taticasIsOpen && contentTaticasRef.current) {
            setContentTaticasHeight(contentTaticasRef.current.offsetHeight)
        }
    }, [taticasIsOpen])


    function copyAll() {
        var allText = ""
        cenarios.forEach((cenario, index) => {
            if (cenario.active) {
                allText += cenario.subject + "\n"
                allText += cenario.vision + "\n"
                allText += "\n"
                allText += "\n"
            }
        })
        navigator.clipboard.writeText(allText)
    }

    const [isMenuVisible, setMenuVisible] = useState(false)
    const [itemMenuVisible, setItemMenuVisible] = useState(null)

    const showMenu = () => {
        setMenuVisible(true)
    }

    const hideMenu = () => {
        setMenuVisible(false)
    }
   

        return (
        <div className="boxCSGeral">
            {loading ? (
                <ArrowLoader />
            ) : (
                <>
                <span style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <button className={"buttonExpandableDiv"} onClick={() => setCenarioIsOpen(!cenarioIsOpen)}>
                            <p style={{ fontSize: "1.7rem", fontWeight: "500", color: "#505050", marginRight: "0.5rem" }}>Cenário</p>
                            {!cenarioIsOpen ? <AiOutlineUp size={25} color="#D8B739" strokeWidth={50} /> : <AiOutlineDown size={22} color="#D8B739" strokeWidth={50} />}
                        </button>
                        <div style={{ marginLeft: "auto", display: "grid", gridTemplateColumns: "auto auto" }}>
                            {cenarioIsOpen ? (
                                <button
                                    className="buttonCopyAllCS"
                                    style={{ marginRight: "1rem" }}
                                    onClick={async () => {
                                        await setCopyingLinkAll(true)
                                        copyAll()
                                        setTimeout(disableCopying, 800)
                                    }}
                                >
                                    <span style={{ margin: "auto", fontSize: "1rem" }}>Copiar todos os cards</span>
                                    <span style={{ marginLeft: "auto" }}>
                                        {!copyingLinkAll ? <MdContentCopy size={20} /> : <FaCheck size={20} className="iconCopyContentAbcActionsAtive" color="#262624" />}
                                    </span>
                                </button>
                            ) : null}
                            {user.type < 3 ? (
                                <span
                                    style={{ textAlign: "right", cursor: "pointer", margin: "auto" }}
                                    title="Gerenciar Cenários"
                                    onClick={() => {
                                        setType({ name: "scenariosControl", data: { cenarios, setCenarios } })
                                        setShow(true)
                                    }}
                                >
                                    <FiSettings color="#D7B32C" size={22} />
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </span>
                    <div className={`boxCenarioCS ${cenarioIsOpen ? "boxCenarioCSExpand transition" : "transition"}`} 
                         style={{ height: cenarioIsOpen ? `calc(${contentCenarioHeight}px + 1.5rem)` : "" ,
                                  
                        }}
                        /* style={
                            { 
                                height: cenarioIsOpen ? `calc(${contentCenarioHeight}px )` : "", 
                                display: cenarioIsOpen === false? 'none' : '', 
                                padding: cenarioIsOpen === false? 'none' : ' 20px 0px',
                                transition: cenarioIsOpen === false? 'all 0.2s ease 2s' : 'all 0.2s ease 2s',
                            }
                        } */>
                                
                        
                        <div className="boxCenarioCSContent"  ref={contentCenarioRef}>
                            {cenarios.map((infos, index) => {
                                if (infos.active)
                                    return (
                                        <div className="contentCenarioCsItem" key={"cenarios" + index}>
                                            <div className="">
                                                <div className="cenarioItemCSTextHeader" style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ width: "fit-content", color: "#D7B32E" }}>{infos.subject}</span>

                                                    <span
                                                        className="cenarioItemCSTextCopy"
                                                        style={{ fontWeight: "bold", fontSize: "0.8rem", marginLeft: "auto", marginRight: "1rem", color: "#DFC259", cursor: "pointer" }}
                                                        onClick={async () => {
                                                            let aux = copyingLink.map((v, i, a) => (i == index ? true : v))
                                                            await setCopyingLink(aux)
                                                            navigator.clipboard.writeText(infos.subject + '\n\n' + infos.vision)
                                                            setTimeout(disableCopying, 1000)
                                                        }}
                                                    >   Copiar
                                                        {!copyingLink[index] ? <MdContentCopy size={16} color="#262624" /> : <FaCheck size={16} className="iconCopyContentAbcActionsAtive" color="#262624"/>}
                                                    </span>
                                                </div>
                                                <pre className="cenarioItemCSText" style={{ fontWeight: "500" }}>
                                                    {infos.vision}
                                                </pre>
                                            </div>
                                            <div className="cenarioItemCSAvatar">
                                                <div className="csAvatar">
                                                    <img srcSet={infos.avatar} alt="Avatar" width={100} height={100} />
                                                </div>
                                                <div className="csName">
                                                    <p>{infos.spokeperson ? infos.spokeperson : "Autor desconhecido"}</p>
                                                </div>
                                                <div>
                                                    <span style={{ fontSize: "0.8rem", fontWeight:'500',float: 'right', marginRight:'24px',color:'#9B9999' , minHeight:'1rem',padding:'0.5rem 0rem'}}>
                                                        {" Atualizado em: "} <br/>
                                                        {datetimeToDatePtBR(infos.updated_at)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                    {/* <hr style={{ marginBottom: "1rem" }}></hr> */}
                    {/* <span style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "1rem", marginBottom: "1rem", alignItems: "center" }}>
                        <button className={"buttonExpandableDiv"} onClick={() => setTaticasIsOpen(!taticasIsOpen)}>
                            <p style={{ fontSize: "1.7rem", fontWeight: "500", transition:'0.2s ease ', color:'#505050', marginRight:'0.5rem', display:'inline-block'  }}>Alocações Táticas</p>
                            {!taticasIsOpen ? <AiOutlineUp size={25} color="#D8B739" strokeWidth={50} /> : <AiOutlineDown size={25} color="#D8B739" strokeWidth={50} />}
                        </button>
                        {user.type < 3 ? (
                            <span
                                style={{ textAlign: "right", cursor: "pointer" }}
                                title="Gerenciar Alocações Táticas"
                                onClick={() => {
                                    setType({ name: "tacticalAlocationsControl", data: { dataCubastarTactical, campains } })
                                    setShow(true)
                                }}
                            >
                                <FiSettings color="#D7B32C" size={24} />
                            </span>
                        ) : (
                            <></>
                        )}
                    </span> */}
                    <div className={`boxTaticasCS ${taticasIsOpen ? "boxTaticasCSExpand" : ""}`} 
                        style={
                            { 
                                height: taticasIsOpen ? `calc(${contentTaticasHeight}px )` : "", 
                            }}
                        >
                        <div className="boxTaticasCSContent" style={{padding:'10px',borderRadius:'10px', width:'90vw'}} ref={contentTaticasRef}>
                            <Grid container spacing={2} style={{ flexWrap: "nowrap" }}>
                                <Grid item sm={6}>
                                    <TableContainer style={{ background: "#F6F6F6", padding: "30px 10px", maxHeight: "338px", overflow: "auto", borderRadius: "10px" }}>
                                        <h3 style={{ marginBottom: "1.5rem", color: "#D4AC00" }}>Alocações Táticas</h3>
                                        <Table size="small" aria-label="Alocacao Tatica">
                                            <TableHead style={{ position: "sticky" }}>
                                                <TableRow>
                                                    <TableCell align="center">CLASSES</TableCell>
                                                    <TableCell align="center">VEÍCULOS</TableCell>
                                                    {/* <TableCell align="center">DISCURSO DE<br></br>VENDA</TableCell> */}
                                                    <TableCell align="center">
                                                        TIPO DE<br></br>INVESTIDOR
                                                    </TableCell>
                                                    <TableCell align="center">RECOMENDADO (%)</TableCell>
                                                    <TableCell align="center">MÁXIMO (%)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsFromJson1.map((row) => (
                                                    <StyledTableRow
                                                        key={row.classes}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0, cursor: "pointer" } }}
                                                        onClick={() => {
                                                            setType({ name: "tacticalSpeech", data: { row, cubastarTacticalGet } })
                                                            setShow(true)
                                                        }}
                                                    >
                                                        <TableCell align="center" title={row.classes}>
                                                            {row.classes}
                                                        </TableCell>
                                                        <TableCell align="center" title={row.veiculos}>
                                                            {row.veiculos.length > 30 ? row.veiculos.slice(0, 30) + "..." : row.veiculos}
                                                        </TableCell>
                                                        <TableCell align="center" title={row.investidor}>
                                                            {row.investidor}
                                                        </TableCell>
                                                        <TableCell align="center" title={row.recomendado}>
                                                            {row.recomendado}
                                                        </TableCell>
                                                        <TableCell align="center" title={row.maximo}>
                                                            {row.maximo}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                {/* <hr style={{ borderLeft: "1px solid black", margin: "0 10px", marginLeft: "16px" }} /> */}
                                <Grid item sm={6}>
                                    <TableContainer style={{ background: "#F6F6F6", padding: "30px 10px", maxHeight: "338px", overflow: "auto", borderRadius: "10px" }}>
                                        <h3 style={{ marginBottom: "1.5rem", color: "#D4AC00" }}>Produtos Secundários</h3>
                                        <Table size="small" aria-label="Ex Alocacao Tatica">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">CLASSES</TableCell>
                                                    <TableCell align="center">VEÍCULOS</TableCell>
                                                    {/* <TableCell align="center">DISCURSO DE<br></br>VENDA</TableCell> */}
                                                    <TableCell align="center">
                                                        TIPO DE<br></br>INVESTIDOR
                                                    </TableCell>
                                                    <TableCell align="center">RECOMENDADO (%)</TableCell>
                                                    <TableCell align="center">MÁXIMO (%)</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsFromJson2.map((row) => (
                                                    <StyledTableRow
                                                        /* className="teste tacticalTdStyleAll" */
                                                        key={row.classes}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0, cursor: "pointer" } }}
                                                        onClick={() => {
                                                            setType({ name: "tacticalSpeech", data: { row, cubastarTacticalGet } })
                                                            setShow(true)
                                                        }}
                                                    >
                                                        <TableCell /* className="TacticalTdStyleLeft tacticalTdStyleAll"  */ align="center" title={row.classes}>
                                                            {row.classes}
                                                        </TableCell>
                                                        <TableCell /* className="tacticalTdStyleAll" */ align="center" title={row.veiculos}>
                                                            {row.veiculos.length > 30 ? row.veiculos.slice(0, 30) + "..." : row.veiculos}
                                                        </TableCell>
                                                        <TableCell /* className='tacticalTdStyleAll' */ align="center" title={row.investidor}>
                                                            {row.investidor}
                                                        </TableCell>
                                                        <TableCell /* className="tacticalTdStyleAll" */ align="center" title={row.recomendado}>
                                                            {row.recomendado}
                                                        </TableCell>
                                                        <TableCell /* className="TacticalTdStyleRight tacticalTdStyleAll" */ align="center" title={row.maximo}>
                                                            {row.maximo}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <p style={{ fontSize: "1.7rem", fontWeight: "500", color: "#505050", marginRight: "0.5rem", marginBottom: "0.5rem" }}>Cubastar</p>
                    <div className="boxTablesCS" style={{overflowX: 'auto'}}>
                        {/* -----------MENU---------------  */}
                        <div className="boxTablesCSHeader">
                            <div className="boxTablesCSHeaderLeft">
                                <div>
                                    
                                    {Object.keys(dataTableCubastar)?.map((itemMenuHeaderLeft, index) => {
                                        return (
                                            <span className="menuDivParent">
                                                {isMenuVisible && itemMenuHeaderLeft == itemMenuVisible ? (
                                                    <>
                                                        
                                                            <button
                                                                style={{ display: "flex", justifyContent: "space-around", boxShadow: "none" }}
                                                                key={"itemMenuHeaderLeft" + index}
                                                                onClick={() => {
                                                                    setIsEditing(false)
                                                                    setSelectedItemMenuHeaderLeft(itemMenuHeaderLeft)
                                                                    setCurrentSelectedFundo(dataTableCubastar[itemMenuHeaderLeft][0].description)
                                                                    if (dataTableCubastar[itemMenuHeaderLeft]) {
                                                                        setSelectedItemMenuHeaderRight(dataTableCubastar[itemMenuHeaderLeft][0])
                                                                        if (dataTableCubastar[itemMenuHeaderLeft][0]?.secoes) setSelectedItemMenuRight(dataTableCubastar[itemMenuHeaderLeft][0]?.secoes[0])
                                                                    }
                                                                    showMenu()
                                                                    setItemMenuVisible(itemMenuHeaderLeft)
                                                                }}
                                                                className={selectedItemMenuHeaderLeft == itemMenuHeaderLeft ? "buttonYellowBorderWhite360" : "buttonYellowBorderWhiteInactive360"}
                                                            >
                                                                {itemMenuHeaderLeft.toUpperCase()}
                                                            </button>
                                                        <div className="menuDivCS">
                                                            <div 
                                                            style={{ display: "flex",  justifyContent: "space-between" }}
                                                            >
                                                                <button
                                                                    style={{ display: "flex", justifyContent: "space-around", boxShadow: "none" }}
                                                                    key={"itemMenuHeaderLeft" + index}
                                                                    onClick={() => {
                                                                        setIsEditing(false)
                                                                        setSelectedItemMenuHeaderLeft(itemMenuHeaderLeft)
                                                                        setCurrentSelectedFundo(dataTableCubastar[itemMenuHeaderLeft][0].description)
                                                                        if (dataTableCubastar[itemMenuHeaderLeft]) {
                                                                            setSelectedItemMenuHeaderRight(dataTableCubastar[itemMenuHeaderLeft][0])
                                                                            if (dataTableCubastar[itemMenuHeaderLeft][0]?.secoes) setSelectedItemMenuLeft(dataTableCubastar[itemMenuHeaderLeft][0]?.secoes[0])
                                                                        }
                                                                        showMenu()
                                                                        setItemMenuVisible(itemMenuHeaderLeft)
                                                                    }}
                                                                    className={selectedItemMenuHeaderLeft == itemMenuHeaderLeft ? "buttonYellowBorderWhite360" : "buttonYellowBorderWhiteInactive360"}
                                                                >
                                                                    {itemMenuHeaderLeft.toUpperCase()}
                                                                </button>
                                                                <span>
                                                                    <button
                                                                        onClick={() => {
                                                                            setMenuVisible(false)
                                                                        }}
                                                                        className="modalCloseButtonDashboard"
                                                                        style={{ marginRight: "4px",marginTop: "4px", height: "28px", width: "28px" }}
                                                                    >
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </span>
                                                            </div>
                                                            {/* Menu da esquerda */}
                                                            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
                                                                <div style={{ padding: "1rem" }}>
                                                                {
                                                                    dataTableCubastar[selectedItemMenuHeaderLeft]?.map((itemMenuHeaderRight, index) => (
                                                                            <div
                                                                                style={{ transition: "0.2s ease" }}
                                                                                key={"itemMenuHeaderRight" + index}
                                                                                onClick={() => {
                                                                                    setSelectedItemMenuHeaderRight(itemMenuHeaderRight);
                                                                                    setSelectedItemMenuLeft(itemMenuHeaderRight?.secoes[0]);
                                                                                    setCurrentSelectedFundo(itemMenuHeaderRight?.description);
                                                                                }}
                                                                                className={selectedItemMenuHeaderRight == itemMenuHeaderRight ? "itemMenuNewCS" : "itemMenuNewCSInative"}
                                                                            >
                                                                                {(itemMenuHeaderRight.description)}
                                                                            </div>
                                                                        ))
                                                                    
                                                                }

                                                                </div>
                                                                {/* Menu da direita */}
                                                                <div className="menuCSRight2">
                                                                {
                                                                        selectedItemMenuHeaderRight?.secoes?.length > 0 ? (
                                                                            selectedItemMenuHeaderRight?.secoes?.map((itemMenu, index) => (
                                                                                <div
                                                                                    style={{ marginLeft: '1.5rem', marginRight: '1rem', transition: "0.2s ease" }}
                                                                                    key={"itemMenu" + index}
                                                                                    onClick={() => {
                                                                                        setSelectedItemMenuLeft(itemMenu);
                                                                                        setSelectedCurve(selectedItemMenuHeaderRight?.id, itemMenu?.id);
                                                                                        cubastarRentabilityCurve(selectedItemMenuHeaderRight?.id, itemMenu?.description);
                                                                                        setCurrentSelectedFundo(selectedItemMenuHeaderRight?.description);
                                                                                    }}
                                                                                    className={selectedItemMenuLeft == itemMenu ? "itemMenuNewCS2" : "itemMenuNewCSInative2"}
                                                                                >
                                                                                    {(itemMenu.description)}
                                                                                </div>
                                                                            ))
                                                                        ) : ( 
                                                                            <div style={{whiteSpace: 'nowrap', marginLeft: '2rem', marginRight: '1rem', marginTop: '0.5rem'}}>Nenhum tópico encontrado</div>
                                                                        )
                                                                    
                                                                }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <button
                                                        style={{ display: "flex", justifyContent: "space-around" }}
                                                        key={"itemMenuHeaderLeft" + index}
                                                        onClick={() => {
                                                            setIsEditing(false)
                                                            setSelectedItemMenuHeaderLeft(itemMenuHeaderLeft)
                                                            setCurrentSelectedFundo(dataTableCubastar[itemMenuHeaderLeft][0].description)
                                                            if (dataTableCubastar[itemMenuHeaderLeft]) {
                                                                setSelectedItemMenuHeaderRight(dataTableCubastar[itemMenuHeaderLeft][0])
                                                                if (dataTableCubastar[itemMenuHeaderLeft][0]?.secoes) setSelectedItemMenuLeft(dataTableCubastar[itemMenuHeaderLeft][0]?.secoes[0])
                                                            }
                                                            showMenu()
                                                            setItemMenuVisible(itemMenuHeaderLeft)
                                                        }}

                                                        className={selectedItemMenuHeaderLeft == itemMenuHeaderLeft ? "buttonYellowBorderWhite360" : "buttonYellowBorderWhiteInactive360"}
                                                    >
                                                        {itemMenuHeaderLeft.toUpperCase()}
                                                    </button>
                                                )}
                                            </span>
                                            
                                        )
                                    })}
                                    
                                </div> 
                                {/* <div style={
                                        {display:'flex' , 
                                        alignItems:'center', 
                                        gap:'1rem', 
                                        color:'#D4AC00', 
                                        fontWeight:'600',
                                        fontSize:'1rem', 
                                        padding:'1rem 2rem', 
                                        background:'#F6F6F6' , 
                                        borderRadius:'1rem', 
                                        marginRight:'1rem'}}>
                                    <span style={{color:"#505050"}}>Última atualização em: {<span style={{color:"#D4AC00"}}>{databaseUpdateTime}</span>}</span>
                                </div> */}    
                                <div style={
                                        {display:'flex' , 
                                        alignItems:'center', 
                                        gap:'1rem', 
                                        color:'#D4AC00', 
                                        fontWeight:'600',
                                        fontSize:'1rem', 
                                        padding:'1rem 2rem', 
                                        background:'#F6F6F6' , 
                                        borderRadius:'1rem', 
                                        marginRight:'1rem',
                                        }}>
                                    <span style={{color:"#505050"}}>Você está em:</span>
                                    {titleize(selectedItemMenuHeaderLeft) } 
                                    {currentSelectedFundo != null? <BsArrowRightShort color="#505050"/> :<></>} {titleize(currentSelectedFundo) } 
                                    {selectedItemMenuLeft != null? <BsArrowRightShort color="#505050"/> :<></>} {(selectedItemMenuLeft?.description)}
                                </div> 
                            </div>
                        </div>
                        {/* -----------TABELAS---------------  */}
                        <div className="boxTablesCSBody">
                            {selectedItemMenuHeaderLeft == "carteiras" ? 
                                (user.type == 1 ?  
                                    (
                                        <div style={{background: '#f6f6f6', borderRadius: '0.5rem', margin: '0 2rem 0 1rem', padding: '1rem 0'}}>
                                            {/* <span style={{marginLeft:'1.25rem'}}>Disclaimer: { <BsPencilSquare onClick={handleEditClick} size={20} /> } </span> */}
                                            <div style={{display:'grid', gridTemplateColumns: '4fr 0.2fr', margin:'0 0 0 1rem' }}>
                                                    {isEditing ? (
                                                        <div  >
                                                            <span>
                                                                <textarea className="textareaCubastar" defaultValue={selectedItemMenuLeft.disclaimer} onChange={(e) => selectedItemMenuLeft.disclaimerAux = e.target.value} />
                                                                <div style={{display:'flex', alignItems: 'center', margin: 'auto', gap: '1rem', justifyContent:'center'}}> 

                                                                    <button className="saveButtonClass"  onClick={handleSaveClick}>Salvar</button>
                                                                    <button className="cancelButtonClass" onClick={handleCancelClick}>Cancelar</button>
                                                                </div>
                                                            </span>
                                                            
                                                        </div>
                                                                ) : (
                                                            <div>
                                                            <span style={{whiteSpace: 'pre-wrap'}}>{selectedItemMenuLeft?.disclaimer}</span>
                                                        </div>
                                                        )
                                                    }
                                                    <BsPencilSquare style={{cursor:'pointer'}} onClick={handleEditClick} size={20} />
                                                </div>                                        
                                        </div>
                                    )
                                    : <span style={{marginLeft:'1rem', whiteSpace: 'pre-wrap'}}>Disclaimer: {selectedItemMenuLeft.disclaimer} </span>
                                )  
                                : <></>} 
                            <div className="bodyCSContent">
                                {selectedItemMenuHeaderLeft == "carteiras" ? (

                                    <div style={{ width: "99%" }}>
                                        {/* Gráficos */}
                                        <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                                            <div className="bodyCSContentCarteirasGraph">   
                                                {selectedItemMenuLeft?.allocation?.length > 0 ? GraphCubastarAlocation(selectedItemMenuLeft) : <div style={{textAlign:'center'}} > <p className="cubastar-table-label">Alocação Sugerida</p> Não há carteira registrada.</div>}
                                            </div>
                                            <div className="bodyCSContentCarteirasGraph">
                                                <GraphCubastarRentabilityCurve data={dataRentabilityCurve} />
                                            </div>
                                        </div>
                                        {/* Tabelas */}
                                        <div style={{ display: "grid", gridTemplateColumns: "50% 1fr" }}>
                                            <div className="bodyCSContentCarteirasExemplo">
                                                {selectedItemMenuLeft !== undefined ? <CubastarExamplesTable data={selectedItemMenuLeft} /> : <div>Não há carteiras de exemplo registradas.</div>}
                                            </div>
                                            <div className="bodyCSContentRentabilityTabelas">
                                                {selectedItemMenuLeft !== undefined ? <CubastarRentabilityTable data={selectedItemMenuLeft} /> : <div>Não há carteiras de exemplo registradas.</div>}
                                            </div>
                                        </div>
                                    </div>
                                ) : selectedItemMenuHeaderLeft == "fundos" ? (
                                    <div className="bodyCSContentFundos">
                                        <div>
                                            {/* Tabela Fundo */}
                                            {selectedItemMenuLeft !== undefined && selectedItemMenuLeft.elements.length > 0 ? (
                                                <>
                                                    <div className="bodyCSContentFundosTabela">
                                                        <CubastarFundsTable data={selectedItemMenuLeft} />
                                                    </div>
                                                    {selectedItemMenuLeft?.description.split(' ')[0] != 'OFFSHORE:' && selectedItemMenuLeft?.description.split(' ')[0] != 'COE:' ?
                                                    <>
                                                        <div className="bodyCSContentFundosGrafico" style={{ textAlign: "-webkit-center" }}>
                                                            {/* {console.log('atualizou', selectedItemMenuLeft)} */}
                                                            <GraphCubastarFundsCompare data={selectedItemMenuLeft} />
                                                        </div>

                                                        <div style={{ textAlign: "center" }}>
                                                            <p>Disclaimer: O retorno de 24 e 36 meses é anualizado. A Vol. Esperada é baseada na vol dos últimos 12 meses.</p>
                                                        </div> 
                                                    </> 
                                                    : <></>}
                                                </>
                                            ) : (
                                                <div>Não há informações para serem exibidas.</div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div style={
                                        {display:'flex' , 
                                        alignItems:'center', 
                                        gap:'1rem', 
                                        color:'#D4AC00', 
                                        fontWeight:'600',
                                        fontSize:'1rem', 
                                        padding:'1rem 2rem', 
                                        /* background:'#F6F6F6' ,  */
                                        borderRadius:'1rem', 
                                        marginRight:'1rem',
                                        justifyContent:'flex-end',}}>
                                    <span style={{color:"#505050"}}>Última atualização em: {<span style={{color:"#D4AC00"}}>{databaseUpdateTime}</span>}</span>
                                </div>
                    </div>
                </>
            )}
        </div>
    )
}
