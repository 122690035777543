/*Componente tabela de clientes
/*Descrição: Exibe todos os clientes cadastrado em sistema
/*Desenvolvido por: Equipe Rafael Cezario
*/

//libraries
import React, { useEffect, useState } from 'react';
import { FiPlus, FiSearch, FiArrowDown,FiCopy } from 'react-icons/fi'
import { ThemeProvider,createTheme } from '@mui/material/styles';
//hooks
import { useModal } from '../../../../contexts/useModal';
import { useAuth } from '../../../../contexts/useAuth';
//services
import { api } from '../../../../services/api';
import { getAllClients } from '../../../../services/asyncFunctions';
//components
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
import { Tooltip, IconButton } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import CustomToolbarSelectVBATransfer from "./CustomToolbarSelectVBATransfer";
//utils
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter'
import { ModalSizeFitToScreen } from '../../../../utils/responsiveFunctions/modalSizeFitToScreen';

//styles
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import './styles/clientsVBATransfer.css'
import { MdTexture } from 'react-icons/md';

export function MyTransfersVBA() {

    const { show, setShow, setType, type, edited, setEdited } = useModal();
    const { user, tokenKey } = useAuth();
    const [listClients, setListClients] = useState([]);
    const [listAllClients, setListAllClients] = useState([]);
    const [totalClients, setTotalClients] = useState(0);
    const [profilesList, setProfilesList] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [listExceptionClientsIDs, setListExceptionClientsIDs] = useState([]);
    const [viewCheckbox, setViewCheckbox] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isloaded, setIsloaded] = useState(false);
    const token = localStorage.getItem('@auth:token');

    useEffect(() => {
        async function searchClients() {
            try {
                const clientsResult = await api.get('/api/v1/vba/myTransfers', {
                    headers: {
                        authorization: `Bearer ${token}`,
                        donwload: false
                    }
                })
                setListAllClients(clientsResult?.data?.data)
                // const rows = [];
                // (clientsResult?.data?.data).forEach((element, index) => {
                //     if (element['active'] === true) {
                //         rows.push(element);
                //     }
                // });
                setListClients(clientsResult?.data?.data);
                setTotalClients(clientsResult?.data?.data.length);
                setLoading(false)
            } catch (error) {
                alert(error?.response?.data?.message)
            }
        }

        async function getProfiles() {
            const resultProfile = await api.get('api/v1/profiles', {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setProfilesList(resultProfile.data.data);
        }

        if (type.name === 'showImportClients' && type.value === 'successImport' || type.name === 'successClientCadastration' ||
            type.name === 'successUpdateClient' || type.name === 'successClientEdit' || edited === true) {
            setLoading(true)
            searchClients();
            getProfiles();
            setEdited(false);
        } else if (!isloaded) {

            searchClients();
            getProfiles();
            setIsloaded(true);
        }
    }, [show, edited])

    async function clientsExport() {
        setLoading(true);
        try {
            const exportClientsResult = await api.get(`/api/v1/vba/myTransfers`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`,
                    donwload: true
                },
                responseType: 'blob'
            })

            const responseURL = URL.createObjectURL(new Blob([exportClientsResult.data]));
            const link = document.createElement('a');
            link.href = responseURL;
            link.setAttribute('download', 'transferência de clientes.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false)

        } catch (error) {
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao buscar dados para exportação' : error?.response?.data?.message } })
            setShow(true);
            setLoading(false);
        }
    }

    function copyToClipboard() {
        const output = [];
        const titles = ["CÓDIGO", "NOME", "PERFIL", "STATUS CLIENTE", "DATA SOLICITAÇÃO", "CÓDIGO BROKER ATUAL", "BROKER ATUAL", "CÓDIGO BROKER DESTINO", "BROKER DESTINO", "CÓDIGO LÍDER DO BROKER ATUAL", "LÍDER DO BROKER ATUAL", "CÓDIGO LÍDER DO BROKER DESTINO", "LÍDER DO BROKER DESTINO", "APROVAÇÃO LÍDER DO BROKER ATUAL", "APROVAÇÃO LÍDER DO BROKER DESTINO", 'STATUS TRANSFERÊNCIA'];
        output.push(titles.join('\t'))
        const keys = ["external_id", "name", "client_profile", "status", "created_at", "sender_id", "sender", "receiver_id", "receiver", "leader_sender","leader_sender_name", "leader_receiver","leader_receiver_name", "approval_sender", "approval_receiver", 'active']
        listAllClients.forEach((x) => {
            const tmp = keys.map((y) => {return y==='created_at'? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1") :
            y === "approval_sender" || y === "approval_receiver" ? x[y] == 1 ? 'Aprovado' : x[y] == 0 ? 'Recusado' : 'Pendente' :
            y === 'active' ? !x[y] ? 'Concluída' : 'Aguardando aprovação'
            : x[y]});
            output.push(tmp.join('\t'))
        })
        const table = output.join('\n')
        navigator.clipboard.writeText(table)
        alert('Conteúdo da tabela copiado para a área de transferência')
    }

    async function handleAuthorize(clientsIds){
        var idsC = []
        clientsIds.map((e) => {return idsC.push(parseInt(e))})
        try {
            const clientsResult = await api.get(`/api/v1/vba/transfer/${idsC}`,{
                headers: {
                    authorization: `Bearer ${token}`
                }
            })

            const rows = [];
            const ids = [];
            setType({ name: 'successClientEdit', type: 'information', value: { message: `${clientsResult.data.message}` } })
            setShow(true)
            setLoading(false)
        } catch (error) {
            alert(error?.response?.data?.message)
        }
    }

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
            <Tooltip disableFocusListener title="Copiar para a área de transferência">
                <IconButton onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            
        </>
    );

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                filter: false,
                sort: false,
                display: false,
                setCellProps: () => ({
                  style: {
                      whiteSpace: "nowrap",
                          paddingLeft: '0.5rem',
                          paddingRight: '0.5rem'
                  },
              }),
            },
          },
          {
          name: "external_id",
          label: "CÓDIGO",
          options: {
              filter: true,
              sort: true,
              display: true,
              setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem'
                },
            }),
          },
        },
        {
          name: "name",
          label: "NOME",
          options: {
              filter: true,
              sort: true,
              display: true,
              setCellProps: () => ({
                style: {
                    whiteSpace: "nowrap",
                        paddingLeft: '0.5rem',
                        paddingRight: '0.5rem'
                },
            }),
          },
        },
        {
            name: "client_profile",
            label: "PERFIL",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "status",
            label: "STATUS CLIENTE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return titleize(val);
                  },
            },
        },
        {
            name: "created_at",
            label: "DATA SOLICITAÇÃO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1");
                },
            },
        },
        {
            name: "active",
            label: "STATUS TRANSFERÊNCIA",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return !val ? 'Concluída' : 'Aguardando aprovação';
                },
            },
        },
        {
            name: "sender_id",
            label: "CÓDIGO BROKER ATUAL",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "sender",
            label: "BROKER ATUAL",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "receiver_id",
            label: "CÓDIGO BROKER DESTINO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "receiver",
            label: "BROKER DESTINO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "leader_sender",
            label: "CÓDIGO LÍDER DO BROKER ATUAL",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "leader_sender_name",
            label: "LÍDER DO BROKER ATUAL",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "leader_receiver",
            label: "CÓDIGO LÍDER DO BROKER DESTINO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "leader_receiver_name",
            label: "LÍDER DO BROKER DESTINO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
            },
        },
        {
            name: "approval_sender",
            label: "APROVAÇÃO LÍDER DO BROKER ATUAL",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return val == 1 ? 'Aprovado' : val == 0 ? 'Recusado' : 'Pendente' ;
                },
            },
        },
        {
            name: "approval_receiver",
            label: "APROVAÇÃO LÍDER DO BROKER DESTINO",
            options: {
                filter: false,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem'
                    },
                }),
                customBodyRender: (val) => {
                    return val == 1 ? 'Aprovado' : val == 0 ? 'Recusado' : 'Pendente' ;
                },
            },
        },
    ];

    const options = {
        filterType: "multiselect",
        download: true,
        print: false,
        responsive: "vertical",
        tableBodyHeight: "71vh",
        selectableRowsHideCheckboxes: true ,
        rowsSelected: user.type === 1 && viewCheckbox === true ? selectedClients : [],
        selectToolbarPlacement: "replace",
        selectableRows: 'multiple',
        viewColumns: false,
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 50, totalClients],
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não foram encontrados dados",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "vba.csv",
            separator: ";",
        },
        onDownload: () => {
            clientsExport();
            return false;
        },
        customToolbar: AddButton,
        setTableProps: () => {
          return {
              padding: 'none',
              size: 'small'
          };
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <CustomToolbarSelectVBATransfer 
                selectedRows={selectedRows} 
                displayData={displayData} 
                setSelectedRows={setSelectedRows} 
                setSelectedClients={setSelectedClients} 
                selectedClients={selectedClients} 
                setViewCheckbox={setViewCheckbox} 
                viewCheckbox={viewCheckbox}
                handleAuthorize={handleAuthorize}
                listExceptionClientsIDs={listExceptionClientsIDs}
            />
          ),
        
    };

    const title = 
        <div>
            <div style={{fontSize: "1.6rem", fontWeight: '400'}}>Aprovação de transferência de broker do cliente</div>
            <div>Total {totalClients}</div>
        </div>

    const getMuiTheme = () =>
    createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root:{
                        justifyContent: 'center',
                        whiteSpace: "nowrap",
                        textAlign: 'center',
                }
            },
        },
        MUIDataTableHeadCell: {
            styleOverrides: {
                contentWrapper: {
                    justifyContent: 'center',
                    whiteSpace: "nowrap",
                }
            }
        },
    },
    })

    return (
        loading ? 
        <div className='divLoading'>
            <ArrowLoader /> 
        </div>
        : 
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable title={title} data={listClients} columns={columns} options={options}></MUIDataTable>
        </ThemeProvider>
    );
}
