import React, { useEffect, useState, useRef, useReducer } from "react"
import MUIDataTable from "mui-datatables"
import { debounceSearchRender } from "mui-datatables"
import { Tooltip, IconButton } from "@material-ui/core"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import TableCell from "@mui/material/TableCell"
/*Styles*/
import "../../monitoringPanel/myReservations/styles/myReservation.table.css"
import { BsQuestionCircle } from "react-icons/bs"
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"
/*Contexts*/
import { useAuth } from "../../../../contexts/useAuth"
import { useModal } from "../../../../contexts/useModal"
/*Services*/
import { getDatabaseReferenceTime, getFixedIncomeView } from "../../../../services/asyncFunctions"
/*Components*/
import { ArrowLoader } from "../../../../components/loaders/arrowLoader"
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency"
import { toPercent } from "../../../../utils/formatNumberAndText/formatNumberPercent"
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter"
import { green } from "@mui/material/colors"
import { TextField, Select, MenuItem } from "@material-ui/core"
import { datetimeToDatePtBR } from "../../../../utils/formatDate/dateTimeToPTBR"
import { AiFillCloseCircle } from "react-icons/ai"
import { styleClientsInput } from "../../../../components/styles/autocompletField"

export function FixedIncomeAgiosTable() {
    const [loading, setLoading] = useState(false)
    const [listDueStructured, setlistDueStructured] = useState([])
    const [listDueStructuredFormatted, setlistDueStructuredFormatted] = useState([])
    const { user, tokenKey } = useAuth()
    const { setType, setShow, edited, setEdited } = useModal()
    const [databaseUpdateTime, setDatabaseUpdateTime] = useState("")
    const teste = useRef(0)
    const teste2 = useRef(0)
    const [date, setDate] = useState(new Date())
    const [filterByVencimento, setFilterByVencimento] = useState(false)
    const [dateStart, setDateStart] = useState("")
    const [dateFinish, setDateFinish] = useState("")
    const classes = styleClientsInput()
    const [filterClients, setFilterClients] = useState(false)
    const [errorFields, setErrorFields] = useState({
        dateFinish: false,
    })

    async function getDue() {
        const listDueStructured = await getFixedIncomeView(tokenKey, false)
        // console.log(listDueStructured);
        if (getFixedIncomeView?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getFixedIncomeView?.error,
                },
            })
            setShow(true)
        } else {
            setlistDueStructured(listDueStructured)
            setLoading(false)
        }
    }

    async function getDatabaseUpdateTimeValues() {
        const bases = 'relatorioAgioDesagio';
        const databaseUpdateTime = await getDatabaseReferenceTime(tokenKey, bases);
        if (getDatabaseReferenceTime?.isError) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: getDatabaseReferenceTime?.error,
                },
            });
            setShow(true);
        } else {
            try {
                let dateFormat = databaseUpdateTime[0].file_reference_time;
                setDatabaseUpdateTime(dateFormat);
            } catch (e) {
                let dateFormat = ''
                setDatabaseUpdateTime(dateFormat);
            }
        }
    }

    function formateAssets(dateStart, dateFinish) {
        let dueStructuredFormatted = []

        listDueStructured.map((dueStructured) => {
            dueStructuredFormatted.push({
                client_id: parseInt(dueStructured.client_id),
                client_name: dueStructured.client_name,
                advisor: dueStructured.advisor_name,
                advisor_id: dueStructured.advisor_id,
                team: dueStructured.leader_name,
                filial: dueStructured.branch_city,
                class: dueStructured.class,
                issuer: dueStructured.issuer,
                created_at: dueStructured.created_at,
                due_at: dueStructured.due_at,
                med_duration: dueStructured.med_duration,
                applied_value: dueStructured.applied_value,
                application_curve_value: dueStructured.application_curve_value,
                sale_gross_value: dueStructured.sale_gross_value,
                med_agio: dueStructured.med_agio ? dueStructured.med_agio : -100,
                med_aliquota_ir: dueStructured.med_aliquota_ir,
                agio: dueStructured.status_agio,
                // agio: dueStructured.med_agio >= 0 ? 'Ágio' : 'Deságio',
                agio_outcome: dueStructured.sale_gross_value - dueStructured.application_curve_value,
                rentability_mtm: dueStructured.sale_gross_value - dueStructured.applied_value,
                rentability_mtm_pct: (dueStructured.sale_gross_value / dueStructured.applied_value - 1) * 100,
                evp_impact: ((dueStructured.sale_gross_value - dueStructured.application_curve_value) / dueStructured.net_total) * 100,
                net_total: dueStructured.net_total
            })
        })

        if (dateStart && dateFinish) {
            
            // var cloneTableResponseFormatted = tableResponseFormatted.filter((item) => {
            //     const itemDate = new Date(item.due_at);
            //     const startDate = new Date(dateStart);
            //     const finishDate = new Date(dateFinish);

            //     return itemDate >= startDate && itemDate <= finishDate;
            // });
            var cloneTableResponseFormatted = []
            const finishDatePlusOneDay = new Date(dateFinish);
            finishDatePlusOneDay.setDate(finishDatePlusOneDay.getDate() + 1);

            listDueStructured.map((dueStructured) => {
                if (dueStructured.due_at >= dateStart && dueStructured.due_at <= dateFinish) {
                    cloneTableResponseFormatted.push({
                    client_id: parseInt(dueStructured.client_id),
                    client_name: dueStructured.client_name,
                    advisor: dueStructured.advisor_name,
                    advisor_id: dueStructured.advisor_id,
                    team: dueStructured.leader_name,
                    filial: dueStructured.branch_city,
                    class: dueStructured.class,
                    issuer: dueStructured.issuer,
                    created_at: dueStructured.created_at,
                    due_at: dueStructured.due_at,
                    med_duration: dueStructured.med_duration,
                    applied_value: dueStructured.applied_value,
                    application_curve_value: dueStructured.application_curve_value,
                    sale_gross_value: dueStructured.sale_gross_value,
                    med_agio: dueStructured.med_agio ? dueStructured.med_agio : -100,
                    med_aliquota_ir: dueStructured.med_aliquota_ir,
                    agio: dueStructured.status_agio,
                    // agio: dueStructured.med_agio >= 0 ? 'Ágio' : 'Deságio',
                    agio_outcome: dueStructured.sale_gross_value - dueStructured.application_curve_value,
                    rentability_mtm: dueStructured.sale_gross_value - dueStructured.applied_value,
                    rentability_mtm_pct: (dueStructured.sale_gross_value / dueStructured.applied_value - 1) * 100,
                    evp_impact: ((dueStructured.sale_gross_value - dueStructured.application_curve_value) / dueStructured.net_total) * 100,
                    net_total: dueStructured.net_total
                    })
                }
            });
        }

        setlistDueStructuredFormatted( dateStart && dateFinish ? cloneTableResponseFormatted : dueStructuredFormatted)
        setLoading(false)
    }

    useEffect(() => {
        if (edited || listDueStructured.length === 0) {
            setEdited(false)
            setLoading(true)
            getDue()
            getDatabaseUpdateTimeValues();
        }
    }, [edited])

    useEffect(() => {
        if (listDueStructured.length > 0) {
            formateAssets()
        }
    }, [listDueStructured])

    function copyToClipboard() {
        const output = []
        const titles = [
            "Código Cliente",
            "Cliente",
            "Assessor",
            "Código Assessor",
            "Time",
            "Filial",
            // "Filial UF",
            "Classe",
            "Emissor",
            "Data Aplicação",
            "Vencimento",
            "Duration",
            "Financeiro Aplicação",
            "Valor Curva de Aplicação",
            "Alíquota IR Média (se aplicável)",
            "Financeiro Bruto de Venda",
            "Ágio/Deságio",
            "Ágio Médio",
            "Rentabilidade MTM",
            "Rentabilidade MTM (%)",
            "IMPACTO EVP: Rentabilidade MTM (R$)",
            "Impacto EVP: Rentabilidade (%)",
            "NET Total"
        ]
        output.push(titles.join("\t"))
        const keys = [
            "client_id",
            "client_name",
            "advisor",
            "advisor_id",
            "team",
            "filial",
            // "branch_state",
            "class",
            "issuer",
            "created_at",
            "due_at",
            "med_duration",
            "applied_value",
            "application_curve_value",
            "med_aliquota_ir",
            "sale_gross_value",
            "agio",
            "med_agio",
            "rentability_mtm",
            "rentability_mtm_pct",
            "agio_outcome",
            "evp_impact",
            "net_total"
        ]
        listDueStructuredFormatted.forEach((x) => {
            const tmp = keys.map((y) => {
                return y === "application_curve_value" || y === "sale_gross_value" || y === "agio_outcome" || y === "rentability_mtm" || y === "applied_value" || y === "net_total"
                    ? String(x[y]).replace(".", ",")
                    : y === "created_at" || y === "due_at"
                        ? x[y].replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                        : y === "rentability_mtm_pct" || y === "evp_impact"
                            ? toPercent(x[y])
                            : y == "med_duration"
                                ? String(x[y]).replace(".", ",")
                                : x[y]
            })
            output.push(tmp.join("\t"))
        })
        const table = output.join("\n")
        navigator.clipboard.writeText(table)
        alert("Conteúdo da tabela copiado para a área de transferência.")
    }

    async function dueStructuredExport(open, year) {
        setLoading(true)
        try {
            const myExport = await getFixedIncomeView(tokenKey, true, "agios")
            const responseURL = URL.createObjectURL(new Blob([myExport]))
            const link = document.createElement("a")
            link.href = responseURL
            link.setAttribute("download", "agios_renda_fixa.xlsx")
            document.body.appendChild(link)
            link.click()
            setLoading(false)
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message: error?.response?.data?.message === undefined ? "Erro ao buscar dados para exportação" : error?.response?.data?.message,
                },
            })
            setShow(true)
            setLoading(false)
        }
    }

    var columns = [
        {
            name: "client_id",
            label: "Código Cliente",

            options: {
                filter: true,
                sort: true,
                sortDescFirst: true,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    whiteSpace: "nowrap",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },
                setCellProps: () => {
                    teste.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            minWidth: "10rem",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                            position: "sticky",
                            left: "0",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },
            },
        },
        {
            name: "client_name",
            label: "Cliente",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    return titleize(val)
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => {
                    return (
                        <>
                            <TableCell
                                className="TableCell"
                                onClick={() => {
                                    sortColumn(index)
                                }}
                                style={{
                                    minWidth: "9rem",
                                    position: "sticky",
                                    left: "12rem",
                                    zIndex: 121,
                                    backgroundColor: "white",
                                }}
                            >
                                <div
                                    className="containerTableCellButton"
                                // style={{ margin: "0px 30px" }}
                                >
                                    <div>
                                        {label}

                                        <div className="arrowSortAbc">
                                            {tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}
                                        </div>
                                    </div>
                                </div>
                            </TableCell>
                        </>
                    )
                },

                setCellProps: () => {
                    teste2.current += 1
                    //linha par cinza e linha impar branca
                    const color = teste2.current % 2 != 0 ? "#dbdbdb" : "white"
                    return {
                        style: {
                            textAlign: "center",
                            position: "sticky",
                            whiteSpace: "nowrap",
                            left: "12rem",
                            backgroundColor: color,
                            zIndex: 120,
                        },
                    }
                },
            },
        },
    ]

    //se o usuario for lider, entao insere uma coluna de assessor na tabela para saber de qual dos liderados é o cliente
    if (user?.is_leader > 0 || user.type < 3) {
        columns.push(
            {
                name: "advisor",
                label: "ASSESSOR",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "advisor_id",
                label: "Código do Assessor",
                options: {
                    filter: true,
                    sort: false,
                    display: false,
                },
            })
    }
    if (user.type < 3) {
        columns.push(
            {
                name: "team",
                label: "Time",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            {
                name: "filial",
                label: "Filial",
                options: {
                    filter: true,
                    sort: true,
                    display: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                        },
                    }),
                },
            },
            // {
            //     name: "branch_state",
            //     label: "Filial UF",
            //     options: {
            //         filter: true,
            //         sort: true,
            //         display: true,
            //         setCellProps: () => ({
            //             style: {
            //                 whiteSpace: "nowrap",
            //             },
            //         }),
            //     },
            // }

        )
    }

    columns.push(
        // {
        //     name: "class",
        //     label: "Classe",
        //     options: {
        //         filter: true,
        //         sort: true,

        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //     },
        // },
        // {
        //     name: "issuer",
        //     label: "Emissor",
        //     options: {
        //         filter: true,
        //         sort: true,

        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //     },
        // },
        // {
        //     name: "created_at",
        //     label: "Data Aplicação",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             if (val === "1900-01-01T00:00:00.000Z") {
        //                 return "-"
        //             } else {
        //                 return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
        //             }
        //         },
        //     },
        // },
        // {
        //     name: "due_at",
        //     label: "Vencimento",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             if (val === "1900-01-01T00:00:00.000Z") {
        //                 return "-"
        //             } else {
        //                 return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
        //             }
        //         },
        //     },
        // },
        // {
        //     name: "med_duration",
        //     label: "Duration",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //         customBodyRender: (val) => {
        //             return String(val).replace(".", ",")
        //         },
        //     },
        // },

        // {
        //     name: "applied_value",
        //     label: "Financeiro Aplicado",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //         customBodyRender: (value) => <span>{toBRL(value)}</span>,
        //     },
        // },
        // {
        //     name: "application_curve_value",
        //     label: "Valor Curva de Aplicação",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),
        //         customBodyRender: (value) => <span style={{}}>{toBRL(value)}</span>,
        //     },
        // },
        // {
        //     name: "med_aliquota_ir",
        //     label: "Alíquota IR Média (se aplicável)",
        //     options: {
        //         filter: false,
        //         sort: true,
        //         sortDescFirst: true,
        //         setCellProps: () => ({
        //             style: {
        //                 whiteSpace: "nowrap",
        //             },
        //         }),

        //         customBodyRender: (value) => <span>{value.toFixed(2)}</span>,
        //     },
        // },
        {
            name: "class",
            label: "Classe",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                        borderLeft: '5px solid white ',
                    },
                }),
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                borderLeft: '5px solid white',
                                borderTopLeftRadius: '50px',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                outline: '1px',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "issuer",
            label: "Emissor",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "created_at",
            label: "Data Aplicação",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (val) => {
                    if (val === "1900-01-01T00:00:00.000Z") {
                        return "-"
                    } else {
                        return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    }
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "due_at",
            label: "Vencimento",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (val) => {
                    if (val === "1900-01-01T00:00:00.000Z") {
                        return "-"
                    } else {
                        return val.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2})\.\d+Z$/, "$3/$2/$1")
                    }
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 

                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div >
                            <div style={{height:"2.7rem",color:'#D4AC00',fontSize:'1rem'}}>
                                ATIVOS
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "med_duration",
            label: "Duration",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (val) => {
                    return String(val).replace(".", ",")
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },

        {
            name: "applied_value",
            label: "Financeiro Aplicado",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "application_curve_value",
            label: "Valor Curva de Aplicação",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (value) => <span style={{}}>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "med_aliquota_ir",
            label: "Alíquota IR Média (se aplicável)",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                        borderRight: '5px solid white ',
                    },
                }),

                customBodyRender: (value) => <span>{value.toFixed(2)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                borderTopRightRadius: '50px',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '8rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },

        {
            name: "sale_gross_value",
            label: "Financeiro Bruto de Venda",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                        borderLeft: '5px solid white ',

                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                borderLeft: '5px solid white',
                                borderTopLeftRadius: '50px',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                minWidth: '7rem',
                                boxShadow: '0 0 0 25px white', 
                                gap:'1rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div  >
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                    
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                
                                 {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "agio",
            label: "Ágio/Deságio",
            options: {
                filter: true,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (val) => {
                    return val === "ÁGIO" ? <span style={{ color: "green" }}>{titleize(val)}</span> : <span style={{ color: "red" }}>{titleize(val)}</span>
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div
                            style={
                                {
                                    display: 'flex',
                                    flexDirection:'column'
                                }}
                            >
                            <span  style={{height:"2.7rem",color:'#D4AC00',fontSize:'1rem'}}>
                                ÁGIO/DESÁGIO
                            </span>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "med_agio",
            label: "Ágio Médio",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (value) => <span>{value.toFixed(2)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                borderTopRightRadius: '50px',
                                boxShadow: '0 0 0 25px white',
                                minWidth: '7rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div>
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },

        {
            name: "rentability_mtm",
            label: "Rentabilidade R$",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                        borderLeft: '5px solid white ',

                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                borderLeft: '5px solid white',
                                borderTopLeftRadius: '50px',
                                boxShadow: '0 0 0 25px white',
                                minWidth: '7rem',
                            }
                        }>
                        <div>
                            <div style={{height:"2.7rem", paddingLeft:"100px", minWidth:'9rem', position:'relative', left:'1.5rem',color:'#D4AC00',fontSize:'1rem'}}>
                                IMPACTO VALOR
                            </div>
                            <span
                                style={{
                                    marginRight: "0px",
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                    flexDirection:'row'

                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>

                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "rentability_mtm_pct",
            label: "Rentabilidade (%)",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (value) => <span>{value.toFixed(2)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                borderRight: '5px solid white ',
                                borderTopRightRadius: '50px',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',

                                boxShadow: '0 0 0 25px white',
                                minWidth: '7rem',
                            }
                        }
                    >
                        {/* <span className="containerCellProductDash" style={{ borderTopLeftRadius: "10px", marginRight: "0px", height: "1.7rem", backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef" }}>
                            
                        </span> */}
                        <div>
                            <div style={{height:"2.7rem",paddingRight:"100px", minWidth:'7rem', position:'relative', right:'1.5rem' ,color:'#D4AC00',fontSize:'1rem'}}>
                                DE COMPRA
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'inline-flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "agio_outcome",
            label: "Rentabilidade R$",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',

                        borderLeft: '5px solid white ',
                    },
                }),
                customBodyRender: (val) => {
                    return val >= 0 ? <span style={{ color: "green" }}>{toBRL(val)}</span> : <span style={{ color: "red" }}>{toBRL(val)}</span>
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                borderLeft: '5px solid white',
                                borderTopLeftRadius: '50px',

                                boxShadow: '0 0 0 25px white',
                                minWidth: '7rem',
                            }
                        }
                    >
                        <div>
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                style={{
                                    marginRight: "0px",
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "evp_impact",
            label: "Rentabilidade (%)",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (val) => {
                    return <span style={{ color: `${val >= 0 ? 'green' : 'red'}` }}>{(val).toFixed(2)}</span>
                },
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                /* marginLeft:'auto',
                                marginRight:'auto', */
                                backgroundColor: '#fffcef',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',
                                boxShadow: '0 0 0 25px white',
                                minWidth: '7rem',
                            }
                        }
                    >
                        <div>
                            <div style={{height:"2.7rem", fontSize:'1rem', color:'#D4AC00'}}>
                                IMPACTO EVP
                            </div>
                            <span
                                // className="headerProductCellBoxAdvisor"
                                style={{
                                    marginRight: "0px",
                                    // backgroundColor: selectedCampaign?.id == e.id ? "#D4AC00" : "#fffcef"
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        },
        {
            name: "net_total",
            label: "NET Cliente",
            options: {
                filter: false,
                sort: true,
                sortDescFirst: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        backgroundColor: '#FFFCEF',
                    },
                }),
                customBodyRender: (value) => <span>{toBRL(value)}</span>,
                customHeadRender: ({ index, name, label, ...column }, sortColumn, tableMeta) => (
                    <TableCell
                        // className="leftTesteAbc"
                        style={
                            {
                                position: 'relative', overflowX: 'visible',
                                backgroundColor: '#fffcef',
                                borderTopRightRadius: '50px',
                                position: 'sticky',
                                top: '0px',
                                zIndex: '100',

                                boxShadow: '0 0 0 25px white',
                            }
                        }
                    >
                        <div>
                            <div style={{height:"2.7rem"}}>
                            </div>
                            <span
                                style={{
                                    marginRight: "0px",
                                    backgroundColor: "#fffcef",
                                    cursor: "pointer",
                                    display:'flex',
                                }}
                                onClick={() => {
                                    sortColumn(index)
                                }}
                            >
                                {label}
                                <div className="arrowSortAbc">{tableMeta.name === name ? tableMeta.direction == "asc" ? <AiOutlineArrowUp size={18} /> : <AiOutlineArrowDown size={18} /> : <></>}</div>
                            </span>
                        </div>
                    </TableCell>
                ),
            },
        }
    )

    // Adiciona um botão para chamar a função de copiar a tabela para a área de tranferência
    const AddButton = () => (
        <>
        <Tooltip disableFocusListener title="Copiar para a área de transferência">
            <IconButton onClick={copyToClipboard}>
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
        {filterClients == false ? 
            <span style={{cursor:"pointer", marginTop:'0.75rem'}} 
            onClick={() => {
                setFilterClients(false) 
                setFilterByVencimento(true)
            }} > Filtrar por vencimento</span> : 
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>

                <span style={{cursor:"pointer", margin:'auto'}} onClick={() => setFilterByVencimento(true)} > Vencimento filtrado por {datetimeToDatePtBR(dateStart)} - {datetimeToDatePtBR(dateFinish)}</span>
                <AiFillCloseCircle size={24} color="#C39D16" cursor={'pointer'} onClick={() => { setDateStart(""); setDateFinish(""); formateAssets(); setFilterClients(false) }} />
            </div>
            }
            {filterByVencimento ?
                <div style={{padding:'1rem', position: 'absolute', zIndex: 1000, background: 'white',display:'flex', flexDirection:'column', border: '1px solid black', borderRadius:'0.5rem', boxShadow: '2px 2px 5px 0px', transition:'0.5s' }}>
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <span style={{ color:'#C39D16', fontWeight:"bold"}}>Filtrar por vencimento</span>
                        <AiFillCloseCircle size={24} color="#C39D16" cursor={'pointer'} onClick={() => setFilterByVencimento(false)} />
                    </div>
                    <span style={{}} onClick={() => setFilterByVencimento(false)} >
                    </span>
                    <div style={{display:'flex', justifyContent: filterClients ? 'space-between' : 'flex-end', marginTop:'1rem'}}>
                        { filterClients ?
                            <span 
                                style={{background:'#803535', padding:'0.5rem', borderRadius:'0.5rem', color:"white", height:'fit-content', textAlign:'center', cursor:"pointer"}} 
                                onClick={() => {
                                    setDateStart("")
                                    setDateFinish("")
                                    formateAssets()
                                    setFilterByVencimento(false)
                                    setFilterClients(false)
                                }}
                                >Limpar Filtros
                            </span>
                            : <></>
                        }
                        <span 
                            style={{background:'#C39D16', padding:'0.5rem', borderRadius:'0.5rem', color:"white", height:'fit-content', textAlign:'center', cursor:"pointer"}} 
                            onClick={() => {
                                setFilterClients(true)
                                formateAssets(dateStart, dateFinish)
                                setFilterByVencimento(false)
                            }}
                            >Filtrar
                        </span>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', gap:'1rem', marginTop:'1rem'}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <TextField
                                label="Desde"
                                value={dateStart}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["dateStart"] && !dateStart?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                onChange={(e) => {
                                    setDateStart(e.target.value)
                                }}
                                variant="outlined"
                            />
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <TextField
                                label="Até"
                                value={dateFinish}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                className={errorFields["dateFinish"] && !dateFinish?.trim()?.length ? classes.styleForClientsWithError : classes.styleForClients}
                                onChange={(e) => {
                                    setDateFinish(e.target.value)
                                }}
                                variant="outlined"
                            />
                        </div>
                    </div>
                    
                </div>

                : <></>
            }
        </>
    )

    const options = {
        filterType: "multiselect",
        filter: true,
        download: true,
        print: false,
        responsive: "standard",
        customSearchRender: debounceSearchRender(500),
        tableBodyHeight: "70vh",
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        toolButton: {
            justifyContent: "center",
        },
        textLabels: {
            body: {
                noMatch: loading ? <ArrowLoader /> : "Não há informações a apresentar no momento!",
                toolTip: "Ordenar",
                columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
            },
            pagination: {
                next: "Próxima",
                previous: "Anterior",
                rowsPerPage: "Linhas por página:",
                displayRows: "de",
            },
            toolbar: {
                search: "Pesquisar",
                downloadCsv: "Download XLSX",
                print: "Imprimir",
                viewColumns: "Ver colunas",
                filterTable: "Filtrar",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Limpar",
            },
            viewColumns: {
                title: "Mostrar Colunas",
                titleAria: "Mostrar/Ocultar colunas",
            },
            selectedRows: {
                text: "linha(s) selecionadas",
                delete: "Excluir",
                deleteAria: "Excluir linhas Selecionadas",
            },
        },
        downloadOptions: {
            filename: "previa_receita.csv",
            separator: ";",
        },
        onDownload: () => {
            dueStructuredExport()
            return false
        },
        setTableProps: () => {
            return {
                padding: "0px 1rem 0px",
                size: "small",
            }
        },
        rowsPerPage: 20,
        rowsPerPageOptions: [20, 200, 1000],
        customToolbar: AddButton,
    }

    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            justifyContent: "center",
                            whiteSpace: "",
                            textAlign: "center",
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    styleOverrides: {
                        contentWrapper: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                        },
                        fixedHeader: {
                            justifyContent: "center",
                            whiteSpace: "pre-line",
                            maxWidth: "4rem",
                            padding: "0px 0.6rem 0px 0.6rem",
                        },
                        toolButton: {
                            margin: "0px",
                        },
                    },
                },
                MUIDataTableFilter: {
                    styleOverrides: {
                        root: {
                            minWidth: "40vw",
                            maxWidth: "50vw",
                        },
                    },
                },
                MUIDataTableBodyRow: {
                    styleOverrides: {
                        root: {
                            "&:nth-child(odd)": {
                                backgroundColor: "#dbdbdb",
                            },
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: "none",
                            borderRadius: "0",
                        },
                    },
                },
                MuiTableRow: {
                    styleOverrides: {
                        root: {
                            "&:hover ": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            "&: hover td": {
                                backgroundColor: "#e7e7e7 !important",
                            },
                            // '&: hover div': {
                            //     backgroundColor: '#e7e7e7 !important'
                            // }
                        },
                    },
                },
            },
        })

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={
                        <>
                            <div>
                                <div style={{ fontSize: "1.6rem", fontWeight: "400", color: "rgba(0, 0, 0, 0.6)" }}>Ágios e Deságios - Renda Fixa</div>
                                <div>Cotação indicativa com base anbima D-1 da data de atualização: {databaseUpdateTime}</div>
                            </div>
                        </>
                    }
                    data={listDueStructuredFormatted}
                    columns={columns}
                    options={options}
                    className="tableUnsuitedStructured"
                ></MUIDataTable>
            </ThemeProvider>
        </div>
    )
}
