import { DocumentViewer } from 'react-documents';
import React, { useEffect, useState } from 'react'
import { ArrowLoader } from '../../components/loaders/arrowLoader';
import { ModalSizeFitToScreen } from '../responsiveFunctions/modalSizeFitToScreen';
import { useAuth } from '../../contexts/useAuth';
import localFile from '../../assets/teste.pdf'

export function ShowDocumentFile(props) {

    const [selectedFile, setSelectedFile] = useState(null)
    const { screenX, screenY } = ModalSizeFitToScreen()
    const { user } = useAuth()
    const file = props.file
    const [receivedFile, setReceivedFile] = useState(null)

    useEffect(() => {
        setReceivedFile(file)

    }, [])


    return (
        <div id='showDocumentFileContainer' style={{ overflow: 'auto', maxHeight: screenY -30, width: screenX > 1366 ? '1366px' : '1166px', height: '768px' }} >
            {
                receivedFile !== null &&

                <DocumentViewer
                    queryParams="hl=Nl"
                    url={file}
                    viewerUrl={file}
                    viewer={'pdf'}
                >
                </DocumentViewer>
            }
        </div>

    )
}
