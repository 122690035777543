import React, { useState, useReducer, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "tss-react/mui";
import { display } from "@mui/system";

const useStyles = makeStyles({ name: "MUIDataTableViewCol" })((theme) => ({
    root: {
        padding: "16px 24px 16px 24px",
        fontFamily: "Roboto",
    },
    title: {
        marginLeft: "-7px",
        marginRight: "24px",
        fontSize: "14px",
        color: theme.palette.text.secondary,
        textAlign: "left",
        fontWeight: 500,
    },
    formGroup: {
        marginTop: "8px",
    },
    columnsItems: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        display: 'grid',
        gridTemplateColumns: '1fr',
        maxHeight: '70vh',
        overflowX: 'auto',
    },
    formControl: {
    },
    checkbox: {
        padding: "0px",
        width: "32px",
        height: "32px",
    },
    checkboxRoot: {},
    checked: {},
    label: {
        fontSize: "15px",
        marginLeft: "8px",
        color: theme.palette.text.primary,
    },
    subHeaderButtons: {
        display: "grid",
        gridTemplateColumns: "auto auto auto",
    },
}));

const TableViewColDash = ({ columns, options, components = {}, onColumnUpdate, updateColumns, ocultColumnsToView,productsList }) => {
    const [cloneColumns, setCloneColumns] = useState(JSON.parse(JSON.stringify(columns)));
    const columsnProductsToView = []
    const [, forceUpdate] = useReducer((x) => x + 1, 0)

    columns?.forEach((e) => {
        productsList?.forEach((p,i) => {
            if(e.name.includes("c" + p.id + "__objective")) {
                columsnProductsToView.push({name: e.name,  label: e.description})
            }
            if( e.name.includes("c" + p.id + "__value") || e.name.includes("c" + p.id + "__status") || e.name.includes(p + "__receita_estimada") ){
                columsnProductsToView.push(e.name)
            }

        })
    })
    
    const columnsToChange = useRef([]);

    useEffect(() => {
        columns.forEach((c) => {
            columnsToChange.current.push(false)
        })
    }, [])


    const { classes } = useStyles();
    const textLabels = options.textLabels.viewColumns;
    const CheckboxComponent = components.Checkbox || Checkbox;

    const handleColChange = (index) => {
        onColumnUpdate(index);
    };

    function selectAll(view) {
        columns.map((column, index) => {
                columnsToChange.current[index] = columns[index].display == `${!view}` ? true : false;
                cloneColumns[index].display = `${view}`
        });
        forceUpdate();
    }

    function applyColumnsViewer() {
        columnsToChange.current.forEach((c, i) => {
            if( !ocultColumnsToView.includes(columns[i]?.name) && c == true) {
                handleColChange(i);
                columnsToChange.current[i] = false;
            }
        });
        columnsToChange.current.forEach((c) => c = false)
    }    

    return (
        <FormControl component={"fieldset"} className={classes.root} aria-label={textLabels.titleAria} style={{padding: '1rem',width: 'calc(100% - 2rem)'}}>
            <Typography variant="caption" className={classes.title} style={{}}>
                {textLabels.title}
            </Typography>
            <FormGroup className={classes.formGroup}>
                <div className={classes.subHeader}>
                    <div className={classes.subHeaderButtons}>
                        <Button onClick={() => selectAll(true)}>Visualizar Todas</Button>
                        <Button onClick={() => selectAll(false)}>Desmarcar todas</Button>
                        <Button onClick={() => applyColumnsViewer()}>Aplicar Selecionadas</Button>
                    </div>
                </div>
                <div className={classes.columnsItems}>
                    {columns.map((column, index) => {
                        if (!ocultColumnsToView.includes(column.name)) {
                            var labelToView = column.label
                            productsList?.forEach((p) => {
                                if(column.name.includes('c' + p.id + "__objective")) {
                                    labelToView = p.description
                                }
                            })
                            return (
                                column.display !== "excluded" &&
                                column.viewColumns !== false && (
                                    <FormControlLabel
                                    key={index}
                                    classes={{
                                        root: classes.formControl,
                                        label: classes.label,
                                    }}
                                    control={
                                        <CheckboxComponent
                                        color="primary"
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checkboxRoot,
                                            checked: classes.checked,
                                        }}
                                        onChange={(e) => {
                                            columnsToChange.current[index] = !columnsToChange.current[index]
                                            cloneColumns[index].display = cloneColumns[index].display === 'true' ? 'false' : 'true';
                                            forceUpdate();
                                        }}
                                        checked={cloneColumns[index]?.display == 'true'}
                                        // defaultChecked={column.display === 'true' }
                                        value={labelToView}
                                        />
                                    }
                                        label={labelToView}
                                    />
                                    )
                                    );
                                }
                    })}
                </div>
            </FormGroup>
        </FormControl>
    );
};

TableViewColDash.propTypes = {
    /** Columns used to describe table */
    columns: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger View column update */
    onColumnUpdate: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
};

export default TableViewColDash;
