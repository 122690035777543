//libraries
import React, { useState, useEffect } from "react";
import AntSwitch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
//services
import { api } from "../../../../services/api";
import { getBranches } from "../../../../services/asyncFunctions";
import { getBrokerLeaders } from "../../../../services/asyncFunctions";
import { getBrokerProfiles } from "../../../../services/asyncFunctions";
import { getUsers } from "../../../../services/asyncFunctions";
//components
import { ArrowLoader } from "../../../../components/loaders/arrowLoader";
//hooks
import { useModal } from "../../../../contexts/useModal";
import { useAuth } from "../../../../contexts/useAuth";
//utils
import { titleize } from "../../../../utils/formatNumberAndText/formatTextUpperFirstLetter";
import { ModalSizeFitToScreen } from "../../../../utils/responsiveFunctions/modalSizeFitToScreen";
//styles
import "./styles/users.editBox.css";
import { styleClientsInput } from "../../../../components/styles/autocompletField";

export function BrokerLeaderEditBox(props) {
    const brokerName = props.userData.data.data[0];
    const brokerId = props.userData.data.data[1];
    const leaderName = props.userData.data.data[3];
    const leaderId = props.userData.data.data[4];

    const [inEdit, setInEdit] = useState({
        checkedA: false,
        checkedC: false,
    });
    const classes = styleClientsInput();
    const [leadersList, setLeadersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [selectedLeader, setSelectedLeader] = useState("");

    const [loading, setLoading] = useState(true);
    const [leaderEdited, setLeaderEdited] = useState(false);
    const { show, setShow, setType, edited, setEdited } = useModal();
    const { user, tokenKey, setTokenKey } = useAuth();

    useEffect(() => {
        async function getData() {
            const leadersGet = await getBrokerLeaders(tokenKey);
            if (leadersGet?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: leadersGet?.error,
                    },
                });
                setShow(true);
            } else {

                setLeadersList(leadersGet);
            }
        }

        async function getUsersList() {
            const usersGet = await getUsers(1, tokenKey);
            if (usersGet?.isError) {
                setType({
                    name: "alertMsg",
                    type: "information",
                    value: {
                        message: usersGet?.error,
                    },
                });
                setShow(true);
                // setLoading(false);
            } else {
                setUsersList(usersGet);
            }
        }

        setSelectedLeader(leaderId + " - " + leaderName);

        getData();
        getUsersList();
        setLeaderEdited(false);
        setLoading(false);
    }, [show]);

    async function handleSubmit() {
        setLoading(true);

        try {
            const userChangeResult = await api.put(
                `/api/v1/users/brokerLeaderUpdate`,
                {
                    brokerId: brokerId,
                    leaderId: selectedLeader === null ? leaderId : selectedLeader.split(" ")[0],
                },
                {
                    headers: {
                        authorization: `Bearer ${tokenKey}`,
                    },
                }
            );
            setLoading(false);

            setType({ name: "alertMsg", type: "information", value: { message: "Sucesso ao alterar líder do broker" } });
            setEdited(!edited);
            setLoading(false);
        } catch (error) {
            setType({
                name: "alertMsg",
                type: "information",
                value: {
                    message:
                        error?.response?.data.message === undefined
                            ? "Erro ao realizar requisição"
                            : error?.response?.data.message,
                },
            });
            setShow(true);
        }
    }

    return (
        <div
            className="userEditBox"
            style={{
                width: "60vw",
                height: "40vh",
            }}
        >
            {loading ? (
                <div className="userBoxArrow">
                    <ArrowLoader />
                </div>
            ) : (
                <>
                    <div className="headerBoxUser">
                        <div>
                            <h1>Broker: </h1>
                            <h1>
                                {titleize(brokerName)} - {brokerId}
                            </h1>
                        </div>
                    </div>
                    <div className="formBoxUser ">
                        <div className="selectBrokers">
                            <br />
                            <Autocomplete
                                options={usersList.map((x) => String(x.external_id) + " - " + x.full_name)}
                                value={selectedLeader}
                                onChange={(event, newValue) => {
                                    setSelectedLeader(newValue);
                                    setLeaderEdited(true);
                                }}
                                id="homeBroker"
                                renderInput={(params) => (
                                    <TextField
                                        label="Líder do Broker"
                                        className={classes.autocompleteEditUser}
                                        onKeyPress={(e) => {
                                            if (e.code === "Backquote") e.preventDefault();
                                        }}
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            {leaderEdited && (
                                <button
                                    style={{ margin: "2rem" }}
                                    onClick={() => handleSubmit()}
                                    id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                                >
                                    Confirmar
                                </button>
                            )}
                            <button
                                style={{ margin: "2rem", inlineSize: "max-content" }}
                                onClick={() => 
                                    setType({ name: 'brokerLeaderMirroringsEdit', data: props.userData.data.data })
                                }
                                id={"userBoxButtonShow"}
                            >
                                Espelhamento
                            </button>
                            <button
                                style={{ margin: "2rem" }}
                                onClick={() => setShow(false)}
                                id={inEdit.checkedA ? "userBoxButtonHide" : "userBoxButtonShow"}
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
