/*Descrição: tela para efetuar indicação de novos clientes a serem atendidos
/*pela mesa RV
/*Desenvolvido por: Equipe Rafael Cezário
*/
import React, { useState, useEffect } from 'react';
/*Styles*/
import './styles/clientsIndicationBox.css';
import { TextField, Checkbox, FormControl, FormLabel, Radio, FormControlLabel } from '@material-ui/core';
import { styleClientsInput } from '../../../../components/styles/autocompletField';
/*Services*/
import { api } from '../../../../services/api';
/*Contexts*/
import { useAuth } from '../../../../contexts/useAuth';
import { useModal } from '../../../../contexts/useModal';
/*Utils Components*/
import { ArrowLoader } from '../../../../components/loaders/arrowLoader';
/*Utils Functions*/
import { titleize } from '../../../../utils/formatNumberAndText/formatTextUpperFirstLetter';
// import { validateEmail } from '../../../../utils/validatorSchemas/emailValidador';
// import { requeridFields } from '../../../../utils/validatorSchemas/requiredFieldsToAPI';
// import { phoneValidator } from '../../../../utils/validatorSchemas/phoneValidator';
// import { phoneTypeValidator } from '../../../../utils/validatorSchemas/phoneTypeValidator';
// import { phoneFormatMask } from '../../../../utils/formatNumberAndText/phoneMask';
import { getMyClientsIndication } from '../../../../services/asyncFunctions';
import { Autocomplete } from '@material-ui/lab';
import { HiPlus } from 'react-icons/hi';
import { FaArrowRight } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { toBRL } from "../../../../utils/formatNumberAndText/toBRLCurrency";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { styledForOfferings } from "../../../../components/styles/autocompletField";

export function ClientsIndicationBox(props) {
    const { tokenKey, user } = useAuth()

    const { setShow, show, type, setType } = useModal();
    const [loading, setLoading] = useState(true);
    const [selectedObservation, setSelectedObservation] = useState('');
    const classes = styleClientsInput();
    const [errorFieldsMsg, setErrorFieldsMsg] = useState('');
    const classes2 = styledForOfferings();
    const [showInfoBoxIndication, setShowInfoBoxIndication] = useState(false)
    const [showInfoBoxIndicationText, setShowInfoBoxIndicationText] = useState("")
    const [defaultBroker, setDefaultBroker] = useState(false)
    function clear() {
        setSelectedObservation('')
      }

    async function handleSubmit(e) {       
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post(`/api/v1/clientsIndications/${props.id}`, {
                "observation": selectedObservation?.length > 0 ? selectedObservation : undefined
            }, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            setType({ name: 'clientIndicationSuccess', type: 'information', value: {idCliente: props.id, nomeCliente: props.name }});
            setShow(true)
            clear();
            setLoading(false);
        } catch (error) {
            console.log(error?.response)
            setType({ name: 'alertMsg', type: 'information', value: { message: error?.response?.data?.message === undefined ? 'Erro ao indicar cliente' : error.response.data.message } })
            setShow(true);
            clear();
            setLoading(false);
        }
        
    }

    useEffect(() => {
        setErrorFieldsMsg('');
        setSelectedObservation('');
        setLoading(false);
        getRelatedBroker()
    }, [show])

    async function getRelatedBroker() {
        setLoading(true);
        try {
            const allBrokersList = await api.get(`/api/v1//users/${user.external_id}/myBrokers`, {
                headers: {
                    authorization: `Bearer ${tokenKey}`
                }
            })
            if(allBrokersList?.data?.data[0]){
                setDefaultBroker(true)
            }
            setLoading(false);
        } catch (error) {
            alert(error?.response?.data?.message);
            setLoading(false)
        }
    }

    return (
        <div className='clientsIndicationMainContainerBox'>
            <div className='clientsIndicationsHeader'>
                <p>Indicação do cliente: {props.id} - {props.name}</p>
                <button onClick={() => setShow(false)} className='modalCloseButton'>x</button>
            </div>
            <div className='containerClientIndicationForm'>
                {loading ?
                    (
                        <div className='clientsIndicationLoader'>
                            <ArrowLoader />
                        </div>
                    ) :
                    (
                        <form>
                            {errorFieldsMsg?.length > 0 ?
                                (
                                    <p id='clientsIndicationErrorFieldsMsg'>
                                        {errorFieldsMsg}
                                    </p>
                                ) : ('')}
                            <div className='obervationsIndication'>
                                <label id="assetsOfferingsReservationLabel" className='obervationsIndication'>
                                    <TextField
                                     disabled={!defaultBroker} 
                                    className={classes2.textArea}
                                    variant="outlined"
                                    inputProps={{ maxLength: "200" }}
                                    placeholder="Observações"
                                    onKeyPress={(e) => {
                                        if (e.code === "Backquote") e.preventDefault();
                                    }}
                                    onChange={(e) => setSelectedObservation(e.target.value)}
                                    style={{width: "80%", margin: "auto"}}
                                    />
                                </label>
                            </div>
                            <div className='containerSubmitButton'><button type='submit' disabled={!defaultBroker} className="assetsOfferingsReservConfirmation" onClick={(e) => handleSubmit(e)}>Indicar</button></div>
                            {defaultBroker?
                                <div className=''></div>
                                :
                                <div className='warningNoDefaultBroker'>Você ainda não possui um broker de referência na Mesa RV. Faça a solicitação com o RH.</div>
                            }
                            
                        </form >
                    )
                }
            </div >
            {showInfoBoxIndication ? 
                <><div className='infoBoxIndication'><AiOutlineInfoCircle size={24} style={{marginBottom: "0.2rem"}}/><br/>{showInfoBoxIndicationText}</div></>
                :
                <></>
            }
        </div >
    )

}